import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate, useLocation } from "react-router-dom";
import { giftCardImageFetch, giftCreateAction } from '../../../store/slices/accounts/gift/GiftCreateActions';
import useRazorpay from 'react-razorpay';
import { razorpayPayPromise } from '../../../utils/Payment';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import useScreenSize from '../../../utils/ScreenWidthHight';
import { giftSelection } from '../../../store/slices/CommonSlice';

const GiftSend = ({ Gift_Vendor_Id, id }) => {
    const [categories, setCategories] = useState([]);
    const [selectedCard, setSelectedCard] = useState({});
    const [isCardNotSelected, setIsCardNotSelected] = useState(false);
    const navigate = useNavigate();
    const { state } = useLocation();
    const theme = useTheme();
    const [value, setValue] = useState('500');
    const [cAmount, setCAmount] = useState("");
    const [isAmountAdded, setIsAmountAdded] = useState(false);
    const [isNeedToNavigate, setIsNeedToNavigate] = useState(false);
    const auth = useSelector(state => state.auth);
    const userDetails = useSelector(state => state.auth);
    const winSize = useScreenSize();
    const [Razorpay] = useRazorpay();
    const { navigateData } = state;

    useEffect(() => {
        giftCardImageFetchActionCall({ Gift_Vendor_Id: navigateData.Gift_Vendor_Id, Occassion: navigateData.Occassion });
    }, []);

    useEffect(() => {
        if (isNeedToNavigate) {
            navigate("/gifts", { replace: true });
            setIsNeedToNavigate(false)
        }
    }, [isNeedToNavigate]);

    const giftCardImageFetchActionCall = async (formData) => {
        const response = await giftCardImageFetch(formData);
        if (response.status) {
            setCategories(response.data);
        }
    };

    const cardWidth = useMemo(() => {
        let cardWidth = winSize.getWidthForGiveInput(5);
        if (cardWidth <= 314) {
            cardWidth = 314;
        }
        if (winSize.screenSize.width / 2 < cardWidth + 50) {
            cardWidth = winSize.screenSize.width / 2 - 30;
        }
        return cardWidth + "px";
    }, [winSize.screenSize.width]);

    const handleChangee = (event, newValue) => {
        setCAmount("");
        setValue(newValue);
    };

    const validationSchema = Yup.object({
        name: Yup.string()
            .required("Name is required")
            .test('no-consecutive-periods', 'Consecutive dots are not allowed', value => {
                return !/\.\./.test(value);
            })
            .matches(/(?=.*[a-zA-Z])/, 'Name cannot contain only numbers or special characters')
            .matches(/\S/, 'Name cannot contain only spaces'),
        // email: Yup.string().email("Invalid email address").required("Email is required"),
        email: Yup.string().email("Invalid email address")
        .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, 'Invalid email format')
        .required("Email is required"),
        mobileNumber: Yup.string().matches(/^[0-9]{10}$/, "Enter Valid Number").required("Mobile number is required"),
        // message: Yup.string().required("Message is required"),
        message: Yup.string()
            .required("Message is required")
            .matches(/.*[a-zA-Z]+.*/, "Enter a meaningful message with at least one alphabetic character"),
        customAmount: Yup.number()
            .integer("Amount must be a whole number")
            .min(400, "Minimum is 400"),
    });
    if (winSize.screenSize.width > 600)
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} md={5.9}>
                    <Box sx={{ width: "100%" }} >
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography sx={{ marginTop: "25px", marginLeft: "50px", color: theme.palette.primary.main, fontSize: '20px' }}>Choose Gift Card</Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                minWidth: "100%",
                                gap: 2,
                                justifyContent: "center"
                            }}
                        >
                            {categories.map((category, index) => {
                                const isSelected = category === selectedCard;
                                const borderColor = isCardNotSelected && !isSelected ? theme.palette.error.main :
                                    (category.Gift_Image_Id === selectedCard?.Gift_Image_Id ? theme.palette.primary.light : theme.palette.grey[500]);
                                return (
                                    <Box key={index} sx={{ display: "flex", justifyContent: "center", marginTop: "20px", width: cardWidth }}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                border: `1px solid ${borderColor}`,
                                                width: "230px",
                                                height: "181px",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                    cursor: "pointer"
                                                }
                                            }}
                                            onClick={() => { setSelectedCard(category); setIsCardNotSelected(false); }}
                                        >
                                            <img
                                                src={category.Image_Url}
                                                alt="category"
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    height: "70%",
                                                    marginTop: "25px",
                                                    objectFit: "fill"
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                        {isCardNotSelected && (
                            <Box sx={{ width: '100%', justifyContent: 'center', alignItems: "center", display: 'flex', mt: '30px' }}>
                                <Typography color="error" >Please select a card</Typography>
                            </Box>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={5.6}>
                    <Formik
                        initialValues={{
                            name: "",
                            email: "",
                            mobileNumber: "",
                            message: "",
                            customAmount: ""
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values) => {
                            if (!selectedCard || Object.keys(selectedCard).length === 0) {
                                setIsCardNotSelected(true);
                                return;
                            }
                            setIsCardNotSelected(false);

                            const payRes = await razorpayPayPromise(Razorpay, { user: userDetails.user, amount: cAmount.length > 1 ? cAmount : value });

                            const formData = {
                                Currency: "INR",
                                Gift_Card_Vendor_Id: selectedCard.Gift_Vendor_Id,
                                Gift_Image_Id: selectedCard.Gift_Image_Id,
                                Gift_Message: values.message,
                                Is_Approved: "1",
                                Order_By_Email_Id: auth.user.Email_Id,
                                Order_By_Name: auth.user.Full_Name,
                                Order_To_Email_Id: values.email,
                                Order_To_Mobile: "+91 " + values.mobileNumber,
                                Order_To_Name: values.name,
                                Payment_Code: payRes.status ? "SUCCESS" : "FAILURE",
                                Payment_Method: "Online",
                                Payment_Status: payRes.status,
                                Purpose: selectedCard.Occassion,
                                Total_Value: cAmount.length > 1 ? cAmount : value,
                                Transaction_Id: payRes.status ? payRes.response.razorpay_payment_id : "",
                                Transaction_Type: "RAZORPAY",
                                Type: "Card",
                                Voucher_Name: selectedCard.Vendor_Name
                            };
                            if (payRes.status) {
                                const response = await giftCreateAction(formData);
                                if (response.status) {
                                    setIsNeedToNavigate(true);
                                } else {
                                    navigate("/gifts");
                                }
                            }
                        }}
                    >
                        {({ errors, touched, handleBlur, handleChange, values }) => (
                            <Form>
                                <Box sx={{ marginTop: "60px", maxWidth: "660px" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", columnGap: "3px" }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChangee}
                                            TabIndicatorProps={{ style: { display: "none" } }}
                                            sx={{
                                                '& button': { maxWidth: "66px", color: theme.palette.text.primary, borderRadius: "8px", minHeight: "20px" },
                                                '& button.Mui-selected': { backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, },
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Tab value={""} hidden style={{ display: "none" }} />
                                            <Tab value="500" label="500" />
                                            <Tab value="1000" label="1000" />
                                            <Tab value="1500" label="1500" />
                                            <Tab value="2000" label="2000" />
                                            {/* <Tab value="3000" label="3000" /> */}
                                        </Tabs>
                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                            <TextField
                                                name="customAmount"
                                                size="small"
                                                variant="outlined"
                                                label="Custom Amount"
                                                value={cAmount}
                                                onChange={(e) => {
                                                    setCAmount(e.target.value);
                                                    setIsAmountAdded(false);
                                                    if (e.target.value.length === 0) setValue("500");
                                                    else setValue("");
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                                error={touched.customAmount && Boolean(errors.customAmount)}
                                                helperText={touched.customAmount && errors.customAmount}

                                            />
                                            <Button sx={{ marginLeft: '30px', width: "90px" }}
                                                type="button"
                                                variant="contained"
                                                onClick={() => setIsAmountAdded(true)}
                                            >
                                                {isAmountAdded ? "Added" : "Add"}
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                        <TextField
                                            name="name"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            label="Name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                        <Box sx={{ display: "flex", gap: "20px" }}>
                                            <TextField
                                                name="email"
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                                label="E-Mail"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.email && Boolean(errors.email)}
                                                helperText={touched.email && errors.email}
                                                size="large"
                                            />
                                            <TextField
                                                name="mobileNumber"
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                                label="Mobile Number"
                                                value={values.mobileNumber}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                                                helperText={touched.mobileNumber && errors.mobileNumber}
                                                size="large"
                                            />
                                        </Box>
                                        <TextField
                                            name="message"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            label="Message"
                                            value={values.message}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.message && Boolean(errors.message)}
                                            helperText={touched.message && errors.message}
                                        />
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <Button type="submit" variant="contained" sx={{ padding: '10px', width: "100px", color: theme.palette.text.primary }}>
                                            SEND
                                        </Button>
                                    </Box>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        )
    else {
        return (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                <Box sx={{ width: "90%" }} >
                    <Box sx={{ width: "100%" }} >
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography sx={{ marginTop: "25px", color: theme.palette.text.secondary, fontSize: '20px' }}>Choose Card</Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                                justifyContent: "space-between"
                            }}
                        >
                            {categories.map((category, index) => {
                                const isSelected = category === selectedCard;
                                const borderColor = isCardNotSelected && !isSelected ? theme.palette.error.main :
                                    (category.Gift_Image_Id === selectedCard?.Gift_Image_Id ? theme.palette.primary.light : theme.palette.grey[500]);
                                return (
                                    <Box key={index} sx={{ display: "flex", justifyContent: "center", marginTop: "20px", width: "47%" }}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                border: `1px solid ${borderColor}`,
                                                width: "100%",
                                                height: "150px",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                    cursor: "pointer"
                                                }
                                            }}
                                            onClick={() => { setSelectedCard(category); setIsCardNotSelected(false); }}
                                        >
                                            <img
                                                src={category.Image_Url}
                                                alt="category"
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    height: "70%",
                                                    marginTop: "25px",
                                                    objectFit: "fill"
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                );
                            })}

                            {(categories.length % 2 !== 0) &&
                                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px", width: "150px" }}>
                                </Box>}
                        </Box>
                        {isCardNotSelected && (
                            <Box sx={{ width: '100%', justifyContent: 'center', alignItems: "center", display: 'flex', mt: '30px' }}>
                                <Typography color="error" >Please select a card</Typography>
                            </Box>
                        )}
                    </Box>
                    <Formik
                        initialValues={{
                            name: "",
                            email: "",
                            mobileNumber: "",
                            message: "",
                            customAmount: ""
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values) => {
                            if (!selectedCard || Object.keys(selectedCard).length === 0) {
                                setIsCardNotSelected(true);
                                return;
                            }
                            setIsCardNotSelected(false);

                            const payRes = await razorpayPayPromise(Razorpay, { user: userDetails.user, amount: cAmount.length > 1 ? cAmount : value });

                            const formData = {
                                Currency: "INR",
                                Gift_Card_Vendor_Id: selectedCard.Gift_Vendor_Id,
                                Gift_Image_Id: selectedCard.Gift_Image_Id,
                                Gift_Message: values.message,
                                Is_Approved: "1",
                                Order_By_Email_Id: auth.user.Email_Id,
                                Order_By_Name: auth.user.Full_Name,
                                Order_To_Email_Id: values.email,
                                Order_To_Mobile: "+91 " + values.mobileNumber,
                                Order_To_Name: values.name,
                                Payment_Code: payRes.status ? "SUCCESS" : "FAILURE",
                                Payment_Method: "Online",
                                Payment_Status: payRes.status,
                                Purpose: selectedCard.Occassion,
                                Total_Value: cAmount.length > 1 ? cAmount : value,
                                Transaction_Id: payRes.status ? payRes.response.razorpay_payment_id : "",
                                Transaction_Type: "RAZORPAY",
                                Type: "Card",
                                Voucher_Name: selectedCard.Vendor_Name
                            };
                            if (payRes.status) {
                                const response = await giftCreateAction(formData);
                                if (response.status) {
                                    setIsNeedToNavigate(true);
                                } else {
                                    navigate("/gifts");
                                }
                            }
                        }}
                    >
                        {({ errors, touched, handleBlur, handleChange, values }) => (
                            <Form>
                                <Box sx={{ marginTop: "60px", width: "100%" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", rowGap: "10px", width: "100%" }}>
                                        <Box sx={{width:"100%",overflowX:"scroll"}} >
                                        <Tabs
                                            value={value}
                                            onChange={handleChangee}
                                            TabIndicatorProps={{ style: { display: "none" } }}
                                            variant='scrollable'
                                            sx={{
                                                '& button': { width: "40px", color: theme.palette.text.primary, borderRadius: "8px", minHeight: "20px" },
                                                '& button.Mui-selected': { backgroundColor: theme.palette.primary.main, color: "white", },
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Tab value={""} hidden style={{ display: "none",width:"10px" }} />
                                            <Tab value="500" label="500" sx={{ paddingX: 0 }} />
                                            <Tab value="1000" label="1000" sx={{ paddingX: 0 }} />
                                            <Tab value="1500" label="1500" sx={{ paddingX: 0 }} />
                                            <Tab value="2000" label="2000" sx={{ paddingX: 0 }} />
                                            {/* <Tab value="3000" label="3000" /> */}
                                        </Tabs>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row",justifyContent:"space-between",width:"100%" }}>
                                            <TextField
                                                name="customAmount"
                                                size="small"
                                                variant="outlined"
                                                label="Custom Amount"
                                                value={cAmount}
                                                onChange={(e) => {
                                                    setCAmount(e.target.value);
                                                    setIsAmountAdded(false);
                                                    if (e.target.value.length === 0) setValue("500");
                                                    else setValue("");
                                                    handleChange(e);
                                                }}
                                                sx={{width:"80%"}}
                                                onBlur={handleBlur}
                                                error={touched.customAmount && Boolean(errors.customAmount)}
                                                helperText={touched.customAmount && errors.customAmount}

                                            />
                                            <Button sx={{ marginLeft: '30px', width: "90px" }}
                                                type="button"
                                                variant="contained"
                                                onClick={() => setIsAmountAdded(true)}
                                            >
                                                {isAmountAdded ? "Added" : "Add"}
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "0px" }}>
                                        <TextField
                                            name="name"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            label="Name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                        <Box sx={{ display: "flex",flexDirection:"column", gap: "0px" }}>
                                            <TextField
                                                name="email"
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                                label="E-Mail"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.email && Boolean(errors.email)}
                                                helperText={touched.email && errors.email}
                                                size="large"
                                            />
                                            <TextField
                                                name="mobileNumber"
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                                label="Mobile Number"
                                                value={values.mobileNumber}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                                                helperText={touched.mobileNumber && errors.mobileNumber}
                                                size="large"
                                            />
                                        </Box>
                                        <TextField
                                            name="message"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            label="Message"
                                            value={values.message}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.message && Boolean(errors.message)}
                                            helperText={touched.message && errors.message}
                                        />
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <Button type="submit" fullWidth variant="contained" sx={{ padding: '10px',  color: "white" }}>
                                            SEND
                                        </Button>
                                    </Box>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        )
    }
};

export default GiftSend;
