import React, { useContext, useEffect, useRef, useState, } from 'react';
import { Typography, Box, Stack, Pagination as PN } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { groceryFetchCards } from '../../../store/slices/retail/home/TodayDealsSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import TodayDealsCard from '../../components/cards/TodayDealsCard';
import { GlobalContext } from '../../../Context';
import { fetchCoupons } from '../../../store/slices/retail/home/CouponSlice';
import { useTheme } from '@emotion/react';
import useScreenSize from '../../../utils/ScreenWidthHight';
import dealsNotAvailable from '../../../assests/images/dealsNotAvailable.png'


const AllDeals = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { allDeals, Pagination, loading = false } = useSelector(state => state.todayDeals)
    const { pathname } = useLocation();
    const theme = useTheme();
    const componetRef = useRef();
    const [cardWidth, setCardWidth] = useState('220px');
    const [screensEachLineCards, setScreensEachLineCards] = useState();
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);
    const winSize = useScreenSize();
    const [selectedCategories, setSelectedCategories] = useState([]);

    const getCategoryText = () => {
        if (selectedCategories.length === 0) {
            return "DEALS";
        }
        return selectedCategories.join(", ");
    };

    useEffect(() => {

        if (searchParams.get("PageNo")) {
            const status = searchParams.get("status");
            const statusArray = status ? status.split(",") : [];
            const categories = [];
            let formData = { 'Business_Type': 'GROCERY', Expired_Status: 1 };

            if (statusArray.includes("topBrand")) {
                formData.Is_Top_Brand = 1;
                categories.push("Top Brand Deals");
            }
            if (statusArray.includes("exclusive")) {
                formData.Exclusive = 1;
                categories.push("Exclusive Deals");
            }
            if (statusArray.includes("hotDeals")) {
                formData.Whats_Hot = 1;
                categories.push("Hot Deals");
            }
            if (statusArray.includes("topDeals")) {
                formData.Is_Top_Deal = 1;
                categories.push("Top Deals");
            }
            setSelectedCategories(categories);

            dispatch(groceryFetchCards({
                formData: formData,
                Pagination: { PageSize: 20, PageNo: searchParams.get("PageNo") }
            }));
        } else {
            {
                let querys = "";
                searchParams.forEach((value, key) => {
                    querys += "&" + key + "=" + value
                });
                navigate('/grocery/offers/deals?PageNo=1' + querys, { replace: true });
            }
        }

    }, [pathname, searchParams]);




    const handleChangePage = (event, value) => {
        updateQueryParam([{ "PageNo": value }])
    };

    useEffect(() => {

        const screenWidth = componetRef.current.offsetWidth;
        let calculatedWidth = Math.floor(screenWidth / 2) - 20;
        if (calculatedWidth > 220) {
            calculatedWidth = 200;
        }
        setCardWidth(calculatedWidth + 'px');
        setScreensEachLineCards(Math.floor(screenWidth / calculatedWidth))

    }, [winSize.screenSize]);





    return (
        <>
            <Box ref={componetRef} sx={{ marginTop: "30px" }}>
                <Box sx={{ display: "flex", width: "100%", flexDirection: "row" }}>
                    <Typography sx={{ color: theme.palette.text.secondary, fontSize: '22px', display: { xs: "none", sm: "contents" } }}>
                        {getCategoryText()}
                    </Typography>
                </Box>

                <Box sx={{ display: "flex", width: "100%", flexDirection: "row", marginTop: { md: "30px", sm: '30px', xs: '0px' }, justifyContent: { md: "flex-start", sm: 'flex-start', xs: 'center' }, gap: { md: "16px", sm: '16px', xs: '0px' }, ml: { xs: "0px", sm: '3.5px' }, columnGap: { xs: '10px' } }} flexWrap={"wrap"}  >
                    {allDeals?.length === 0 && !loading && (
                        <img src={dealsNotAvailable} alt="No_Deals_Found" style={{ display: "block", width: '77%', height: '77%', margin: 'auto', objectFit: 'contain' }} ></img>
                    )}
                    {!loading && allDeals?.slice(0, (Pagination?.PageSize * 4)).map((item, index) => (
                        <TodayDealsCard
                            card={item}
                            key={index}
                            onClick={() => { navigate(`/grocery/offers/Deals/${item.Deal_Id}`) }}

                            cardWidth={cardWidth}
                        />

                    ))}
                    {
                        loading && Array(12).fill(1).map((item, index) => {
                            return (
                                <TodayDealsCard
                                    key={index}
                                    isSkeleton
                                />
                            )
                        })
                    }
                </Box>

                {(Pagination?.TotalPages > 1 && !loading) && <Box sx={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
                    <Stack spacing={2}>
                        <PN
                            count={Pagination.TotalPages}
                            page={Pagination.PageNo}
                            onChange={handleChangePage}
                            color="primary"
                        />
                    </Stack>
                </Box>}
            </Box>
        </>
    );
};
export default AllDeals;
