import React from "react";
import { Grid } from "@mui/material";
import SideBar from "../../components/cards/SideBar";
import { Outlet } from "react-router-dom";
import Headergrocery from "../Header";
import { Box } from "@mui/system";


const Offers = (props) => {

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                    <SideBar type="grocery" />
                </Grid>
                <Grid item xs={11.7} md={9}>
                    <Box sx={{width:"100%",minHeight:"100vh"}} >
                        <Outlet />
                    </Box>
                </Grid>

            </Grid>

        </>
    );
}
export default Offers;
