import { Box, Slider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AllProductsActions } from '../../../../store/slices/retail/home/AllProductsSlice';

const PriceFilter = ({ name }) => {
    const [value, setValue] = useState([0, 0]);

    const marks = [
        {
            value: 10,
            label: '0',
        },
        {
            value: 100000,
            label: 'Max',
        },
    ];

    const { filters: { Min_Price, Max_Price }, loading } = useSelector((state) => state.allProducts);
    const dispatch = useDispatch();

    useEffect(() => {
        if (Min_Price == 0, Max_Price == 0) {
            setValue([0, 100000])
        } else {
            setValue([Min_Price, Max_Price])
        }
    }, [Min_Price, Max_Price])

    const valuetext = (value) => {
        return `${value}`;
    };

    const handleChange = (event, newValue) => {
        if (newValue[0] == 0 && newValue[1] == 100000) {
            dispatch(AllProductsActions.updateFilter({ Min_Price: 0, Max_Price: 0 }))

        } else
            dispatch(AllProductsActions.updateFilter({ Min_Price: newValue[0], Max_Price: newValue[1] }))
    };

    return (
        <Box sx={{ width: "90%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "20px" }}>
                {/* <Box sx={{ width: 48, height: 48, borderRadius: 100 }}>
                    <img src={require('../../../../assests/images/mee.png')} style={{ width: "100%", height: "100%" }} alt="icon" />
                </Box> */}
                <Typography sx={{fontWeight:"700"}} >{name}</Typography>
            </Box>
            <Box sx={{ width: "90%", alignSelf: "flex-end" }}>
                <Slider
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    onChangeCommitted={valuetext}
                    marks={marks}
                    min={0}
                    max={100000}
                />
            </Box>
        </Box>
    );
}
export default PriceFilter