import React from "react";
import { Box, CardActionArea, CardMedia, Skeleton, useTheme } from "@mui/material";

const BrandCard = ({ image, onClick, title, item, isSkeleton = false, cardWidth, borderColor }) => {
    const theme = useTheme();
    if (!isSkeleton)
        return (
            <Box
                sx={{
                    width: { md: cardWidth, xs: '61px', sm: cardWidth },
                    height: { md: '144px', xs: '42px', sm: '144px', },
                    borderRadius: { xs: "12px", sm: "44px" },
                    overflow: "hidden",
                    border: `0.4px solid ${borderColor}`,
                    "&:hover": {
                        borderColor: theme.palette.primary.main
                    },
                }}
            >
                <CardActionArea onClick={onClick} sx={{ width: "100%", height: "100%" }}>
                    <Box
                        sx={{
                            width: { md: cardWidth, xs: '61px', sm: cardWidth },
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <CardMedia
                            component="img"
                            image={image}
                            alt="brand image"
                            sx={{ width: { md: "100%", xs: "80%", sm: '100%' }, height: { md: "100%", xs: '80%', sm: '100%' }, objectFit: "contain" }}
                        />
                    </Box>
                </CardActionArea>
            </Box>
        )
    else {
        return (
            <Box
                sx={{
                    width: { sm: '206px', xs: '61px' },
                    height: { sm: '144px', xs: '42px', },
                    borderRadius: "44px",
                    overflow: "hidden",
                    border: "0px solid",
                    borderColor: theme.palette.text.secondary,
                    "&:hover": {
                        borderColor: theme.palette.primary.main
                    },
                }}
            >
                <Skeleton variant="rectangular" sx={{ width: "100%", height: "100%" }} />
            </Box>
        )
    }
};

export default BrandCard;
