import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi, urlGenarator } from '../../CommonAxios';

export const fetchGiftVendorData = createAsyncThunk(
    'giftVendor/fetchGiftVendorData',
    async (formData) => {
        try {
            const response = await nodeApi.post(urlGenarator('/Gift_Voucher/Vendor_Image_Get', formData.Pagination), formData.formData);
            const data = response.data;
            if (data) {
                return data;
            } else {
                return [];
            }
        } catch (error) {
            throw error.message;
        }
    }
);

export const getVendorNameFromVendorId = (formData, Pagination = {}) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post(urlGenarator('/Gift_Voucher/Image_Get', Pagination), formData).then((res) => {
            if (res.data.Success_Response.Response_Code == "200") {
                resolve({ status: true, data: res.data.results, Pagination: res.data.Pagination })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

// export const getVendorNameFromVendorId=(formData)=>{
//     return new Promise(async(resolve,reject)=>{
//         await nodeApi.post('/Gift_Voucher/Vendor_Get',formData).then((res)=>{
//             if(res.data.Success_Response.Is_Data_Exist==="1"){
//                 resolve({status:true,data:res.data.results.map((element)=>({Vendor_Name:element.Vendor_Name}))})
//             }
//         }).catch((err)=>{
//             resolve({status:false,error:err.message})
//         })
//     })
// }

const initialState = {
    giftVendorData: [],
    loading: false,
    error: null,
    Pagination: {}
};

const giftVendorSlice = createSlice({
    name: 'giftVendor',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchGiftVendorData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchGiftVendorData.fulfilled, (state, action) => {
                state.loading = false;
                state.giftVendorData = action.payload.results;
                state.Pagination = action.payload.Pagination
            })
            .addCase(fetchGiftVendorData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default giftVendorSlice.reducer;
