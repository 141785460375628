import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBanners, groceryfetchBanners } from '../../../store/slices/retail/home/BannerSlice';
import Carousel from '../../components/cards/banner/Carousel';
import { useNavigate } from 'react-router-dom';

const Banner = () => {
    const dispatch = useDispatch();
    const { banners } = useSelector(state => state.homeBanners);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(groceryfetchBanners({ 'Business_Type': 'GROCERY', "Banner_Status": "Active" }));
    }, []);

    const handleBannerClick = (banner) => {
        const { Banner_Offer_Type, Banner_Offer_Id } = banner;
        switch (Banner_Offer_Type) {
            case 'Partner':
                navigate(`/grocery/partner/${Banner_Offer_Id}`);
                break;
            case 'Coupon':
                navigate(`/grocery/offers/coupons/${Banner_Offer_Id}`);
                break;
            case 'Product':
                navigate(`/grocery/products/${Banner_Offer_Id}`);
                break;
            case 'Deal':
                navigate(`/grocery/offers/deals/${Banner_Offer_Id}`);
                break;
            default:
                navigate(`/pagenotfound`);
                break;
        }
    };

    return (
        <Carousel
            data={banners}
            imgLabel={"Banner_Image"}
            onClick={handleBannerClick}
            imageStyle={{
                width: '100%',
                height: '339px',
                position: 'absolute',
                borderRadius: '32px',
                objectFit: 'fill'
            }}
        />
    );
}

export default Banner;
