import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi, urlGenarator } from '../../CommonAxios';
import axios from 'axios';
import { Pagination } from '@mui/material';

export const fetchBrandReview = createAsyncThunk("brandreview/fetchBrandReview", async (formData) => {
    const response = await nodeApi.post(urlGenarator("/Reviews/Brand_Reviews_Get", formData.Pagination), formData.formData)
    if (response.data.Success_Response.Is_Data_Exist === "0") {
        return []
    } else {

        return response.data
    }
})

// export const fetchBrandReview = createAsyncThunk(
//     'brandreview/fetchBrandReview',
//     async (formData) => {
//         try {
//             const response = await nodeApi.post('/Reviews/Brand_Reviews_Get',formData);
//             const data = response.data;

//             if (data) {
//                 return data.results;
//             } else {
//                 return [];
//             }
//         } catch (error) {
//             throw error.message;
//         }
//     }
// );

const initialState = {
    brandReviewData: [],
    loading: false,
    error: null,
    Pagination: {}
};

const brandReviewSlice = createSlice({
    name: 'brandreview',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchBrandReview.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchBrandReview.fulfilled, (state, action) => {
                state.loading = false;
                state.brandReviewData = action.payload.results;
                state.Pagination = action.payload.Pagination;
            })
            .addCase(fetchBrandReview.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default brandReviewSlice.reducer;
