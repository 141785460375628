import { FormGroup, Typography, Box, FormControlLabel, Checkbox } from '@mui/material'
import React from 'react'

const CustomizedCheckBox = ({ name, checkboxes }) => {
    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }} >
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "20px" }} >
                {/* <Box sx={{ width: 48, height: 48, borderRadius: 100 }} ><img src={require('../../../../assests/images/mee.png')} style={{ width: "100%", height: "100%" }} /></Box> */}
                <Typography sx={{fontWeight:"bold"}} >{name}</Typography>
            </Box>
            <Box sx={{ width: "95%", alignSelf: "flex-end" }} >
                <FormGroup sx={{ width: "100%" }}  >
                    {checkboxes.map((popularFilter, index) => {
                        return <FormControlLabel key={index}  checked={popularFilter.value} onChange={popularFilter.onChange} control={<Checkbox />}
                        label={
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                             {popularFilter.label[0].toUpperCase() + popularFilter.label.slice(1)}
                            </Typography>
                          } />
                    })}
                </FormGroup>
            </Box>
        </Box>

    )
}
export default CustomizedCheckBox
