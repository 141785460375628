import * as React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Container, Link, Button, IconButton, InputAdornment, Grid, Snackbar, LinearProgress, Box, Divider } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useTheme } from '@emotion/react';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { authActions, getUserDetailsAction, signInAction } from '../../store/slices/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GlobalContext } from '../../Context';
import { isNumber } from '../../utils';
import useScreenSize from '../../utils/ScreenWidthHight';

const SignIn = () => {

    const theme = useTheme();
    const winSize = useScreenSize();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState();
    const [deviceModelName, setDeviceModelName] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { toolTipActive } = React.useContext(GlobalContext);


    useEffect(() => {
        // Check if Geolocation is supported by the browser
        const userAgent = navigator.userAgent;

        let browserName;
        if (userAgent.indexOf('Chrome') > -1) {
            browserName = 'Google Chrome';
        } else if (userAgent.indexOf('Firefox') > -1) {
            browserName = 'Mozilla Firefox';
        } else if (userAgent.indexOf('Edge') > -1) {
            browserName = 'Microsoft Edge';
        } else {
            browserName = 'Unknown Browser';
        }
        setDeviceModelName(browserName);
        if (!navigator.geolocation) {
            setError('Geolocation is not supported by your browser');
            return;
        }

        // Get the user's current position
        navigator.geolocation.getCurrentPosition(
            position => {
                const { coords: { latitude, longitude } } = position
                setLocation({ latitude, longitude });
                axios.get(`https://reverse-geocode.meepaisa.com/reverse?format=json&lat=${latitude}&lon=${longitude}`).then(async (response) => {
                    let locationInfo = response.data.display_name;
                    setLocation({ latitude, longitude, locationInfo });
                }).catch(async (reason) => {
                });
            },
            error => {
                setError('Unable to retrieve your location');
            }
        );
    }, []);

    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    const validationSchema = Yup.object().shape({
        Email_Id_or_Mobile: Yup.string()
            .test('is-email-or-mobile', 'Please enter a valid email or mobile number.', (value) => {
                if (!isNaN(value) && /^\d{10}$/.test(value)) {
                    return true; // Mobile number
                } else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    return true; // Email
                } else {
                    return false; // Neither mobile nor email
                }
            })
            .required("Mobile or Email is required."),
        password: Yup.string()
            .max(255)
            .required('Please Enter Password')
    });

    const [showPassword, setShowPassword] = React.useState(false);


    const handleClickShowPassword = () => setShowPassword(show => !show);
    const handleMouseDownPassword = event => event.preventDefault();

    const signInCall = async (input) => {
        setLoading(true);
        const result = await signInAction(input);
        if (result.status) {
            dispatch(authActions.sessionExpireUpdate({ sessionExpiredTime: result.data.Tokens.exp - (5 * 60), sessionExpiredDate: new Date().setSeconds(new Date().getSeconds() + (result.data.Tokens.exp - (5 * 60))) }))
            const userDetails = await getUserDetailsAction(result.data.User_EmailID);
            if (userDetails.status) {
                const user = JSON.parse(JSON.stringify(userDetails.data));
                user.User_Session_Id = result.data.User_Session_Id;
                dispatch(authActions.signInStateUpdate(user))
                toolTipActive("success", 'You have successfully logged in.');
                navigate("/home");
            } else {
                setError(userDetails.data);
                setSeconds(3);
            }
        } else {
            setError(result.data);
            setSeconds(3);
        }
        setLoading(false);

    }

    return (
        <Container sx={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Grid container sx={{ justifyContent: 'space-evenly' }}>
                {/* Left side image */}
                { winSize.screenSize.width> 701 &&
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ display: "flex", justifyContent: "left" }}>
                            <img
                                src={require('../../assests/images/mee.png')}
                                alt=""
                                style={{
                                    width: "85%",
                                    maxWidth: '544px',
                                    marginTop: "60px"
                                }}
                            />
                        </Box>
                    </Grid>
                }

                {/* Right side content */}
                <Grid item xs={12} sm={4} textAlign={"center"}>
                    <Box sx={{
                        marginBottom: '30px', display: "flex",
                        flexDirection: "column",
                        alignItems: "center"

                    }}>
                        <img
                            src={require('../../assests/images/avatarsignup.jpg')}
                            alt=""
                            style={{
                                width: '44px',
                                height: '44px',
                                borderRadius: '50%',
                            }}
                        />
                        <Typography variant="h5" fontWeight="bold">
                            Sign In
                        </Typography>
                    </Box>

                    <Box sx={{}}>
                        {/* Formik form */}
                        <Formik
                            initialValues={{
                                Email_Id_or_Mobile: '',
                                password: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                                let formdata = {
                                    "Device_Identity": deviceModelName,
                                    "Email_ID": !isNumber(values.Email_Id_or_Mobile) ? values.Email_Id_or_Mobile : "",
                                    "Mobile": isNumber(values.Email_Id_or_Mobile) ? "+91 " + values.Email_Id_or_Mobile : "",
                                    "Latitude": location?.latitude,
                                    "Location": location?.locationInfo,
                                    "Longitude": location?.longitude,
                                    "Password": values.password,
                                    "Expo_Push_Notification": ""
                                }
                                signInCall(formdata)

                            }}
                        >
                            {({ values, errors, touched, submitForm }) => (
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Grid sx={{ marginBottom: '30px', flex: '1', maxWidth: '412px' }}>
                                        <Field
                                            as={TextField}
                                            name="Email_Id_or_Mobile"
                                            label="Email or Mobile Number"
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                            error={Boolean(touched.Email_Id_or_Mobile && errors.Email_Id_or_Mobile)}
                                            helperText={touched.Email_Id_or_Mobile && errors.Email_Id_or_Mobile ? errors.Email_Id_or_Mobile : ''}

                                        />


                                    </Grid>

                                    <Grid sx={{ marginBottom: '30px', width: "100%", maxWidth: "412px" }}>
                                        <Field
                                            name="password"
                                            as={TextField}
                                            label="Password"
                                            size="small"
                                            fullWidth
                                            type={showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            sx={{ color: theme.palette.extra.primary }}

                                                        >
                                                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={Boolean(touched.password && errors.password)}
                                            helperText={touched.password && errors.password ? errors.password : ''}
                                        />
                                        <Link href='/auth/forgotpassword'>
                                            <Box sx={{ display: "flex", justifyContent: "end", marginTop: "5px" }}>Forgot Password?</Box>
                                        </Link>
                                    </Grid>
                                    {seconds > 0 &&
                                        <Grid sx={{ marginBottom: '30px', width: "100%", maxWidth: "412px" }}>
                                            <Typography sx={{ color: theme.palette.error.main }} >
                                                {error}
                                            </Typography>
                                            <LinearProgress variant="determinate" color={"error"} value={seconds * 30} />
                                        </Grid>
                                    }
                                    {/* Submit button */}



                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="medium"
                                        fullWidth
                                        disabled={loading}
                                        onClick={submitForm}
                                        sx={{ borderRadius: '22px', marginBottom: '30px', width: '100%', maxWidth: '412px' }}
                                    >
                                        Sign In
                                    </Button>

                                    <Typography variant="body2" sx={{ marginBottom: "30px", display: "flex", alignItems: "center", justifyContent: 'center' }}>
                                        <Divider sx={{ flexGrow: 0.2, marginRight: "10px" }} />
                                        Are You New?
                                        <Divider sx={{ flexGrow: 0.2, marginLeft: "10px" }} />
                                    </Typography>
                                    <Link href="/auth/signup">
                                        <Button
                                            type="button"
                                            variant="contained"
                                            size="medium"
                                            fullWidth
                                            disabled={loading}
                                            sx={{ borderRadius: '22px', marginBottom: '30px', width: '100%', maxWidth: '412px' }}
                                        >
                                            Create an account
                                        </Button>
                                    </Link>
                                    <Link href="/auth/signinotp"
                                        disabled={loading}
                                        sx={{ textDecoration: 'none', }}>
                                        <Typography variant="body2" sx={{ marginBottom: "30px", }}>
                                            Sign In using OTP
                                        </Typography>
                                    </Link>

                                    {/* Copyright */}
                                    <Typography variant="body2" sx={{ marginBottom: "30px", }}>
                                        Copyright @ EasyStepIn 2016 - {new Date().getFullYear()}.
                                    </Typography>
                                </Box>
                            )}
                        </Formik>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}


export default SignIn;