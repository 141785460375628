import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material'
import { nodeApi } from '../../../../store/slices/CommonAxios';
import { useTheme } from '@emotion/react';

const States = (props) => {
    const [state, setState] = useState([]);
    const theme = useTheme();
    useEffect(() => {

        getStates("IN")

    }, [])
    const getStates = (CountryCode) => {
        nodeApi.post(`/Products/country-by-state`, { "CountryCode": CountryCode })
            .then(response => {
                setState(response.data);
            })
            .catch(error => {
            })
    }

    return (
        <FormControl sx={{ width: "100%" }}>
            <InputLabel>Select State</InputLabel>
            <Select
                {...props}
            >
                <MenuItem value="">None</MenuItem>
                {
                    state.map((state, index) => {
                        return (

                            <MenuItem key={index}

                                value={state.isoCode}
                            >{state.name}</MenuItem>
                        )
                    })
                }
            </Select>

        </FormControl>
    )
}
export default States
