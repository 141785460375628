import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchBestSellers } from '../../../store/slices/retail/home/BestSellerSlice';
import ProductGrid from '../../components/cards/BestSellers/ProductGrid';
import { getBrands } from '../../../store/slices/retail/home/BrandSlice';
import { resourcePathWithPaylaodCall } from '../../../store/slices/AuthSlice';
import { isEmpty } from '../../../utils';
import useScreenSize from '../../../utils/ScreenWidthHight';

const BestSellers = ({ input }) => {
    const [item1, item2] = input;
    const dispatch = useDispatch();
    const [gridOneInput, setGridOneInput] = useState([]);
    const [gridTwoInput, setGridTwoInput] = useState([]);
    const winSize = useScreenSize()
    const apiCall = async (url, input, stateFunction) => {
        const res = await resourcePathWithPaylaodCall(url, input);
        if (res.status) {
            stateFunction(res.data)
        }
    }

    useEffect(() => {
        if (!isEmpty(item1))
            apiCall(item1.resourceURL, item1.requestPayload, setGridOneInput)
    }, [item1]);

    useEffect(() => {
        if (!isEmpty(item2))
            apiCall(item2.resourceURL, item2.requestPayload, setGridTwoInput)
    }, [item2]);
    if (winSize.screenSize.width > 600)
        return (
            input.length == 2 &&
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <ProductGrid
                        input={item1}
                        title={item1.title}
                        data={gridOneInput.slice(0, 4)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ProductGrid
                        input={item2}
                        title={item2.title}
                        data={gridTwoInput.slice(0, 4)}
                    />
                </Grid>
            </Grid>

        );
    else{
        return (
            input.length == 2 &&
            <Grid container >
                <Grid item xs={12} sm={6}>
                    <ProductGrid
                        input={item1}
                        title={item1.title}
                        data={gridOneInput.slice(0, 4)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ProductGrid
                        input={item2}
                        title={item2.title}
                        data={gridTwoInput.slice(0, 4)}
                    />
                </Grid>
            </Grid>
    
        );
    }
}

export default BestSellers;
