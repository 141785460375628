import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi, nodeCudApi, urlGenarator } from '../../CommonAxios';

const initialState = {
    wishlistHistory: [],
    loading: false,
    error: '',
    Pagination: {}
};

export const fetchWishlistHistory = createAsyncThunk("wishlist/fetchWishlistHistory", async (formData) => {
    const response = await nodeApi.post(urlGenarator("/User_Wishlist/Get", formData.Pagination), formData.formData)
    if (response.data.Success_Response.Is_Data_Exist === "0") {
        return []
    } else {

        return response.data
    }
})

export const deleteWishlistItem = createAsyncThunk(
    'wishlist/deleteWishlistItem',
    async (wishId, { rejectWithValue }) => {
        try {
            const response = await nodeCudApi.post('/User_Wishlist/Delete', { Wish_Id: wishId });
            if (response.data?.Response_Status === 'Success') {
                return wishId;
            } else {
                return rejectWithValue('Failed to delete item');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const productSearch = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post('/User_Wishlist/Get', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        // Define any additional synchronous reducers here, if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWishlistHistory.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchWishlistHistory.fulfilled, (state, action) => {
                state.loading = false;
                state.wishlistHistory = action.payload.results;
                state.error = '';
                state.Pagination = action.payload.Pagination;
            })
            .addCase(fetchWishlistHistory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
        builder
            .addCase(deleteWishlistItem.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteWishlistItem.fulfilled, (state, action) => {
                state.loading = false;
                state.wishlistHistory = state.wishlistHistory.filter(item => item.Wish_Id !== action.payload);
                state.error = '';
            })
            .addCase(deleteWishlistItem.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default wishlistSlice.reducer;
