import { Grid, Typography, Link } from '@mui/material';
import { Box } from '@mui/system';

import mobileScreens from '../../../assests/images/mobileScreens.png';
import appStoreIcon from '../../../assests/images/appstore.png';
import playStoreIcon from '../../../assests/images/playstore.png';

const Promotion = () => {
    return (
        <Grid container justifyContent="space-around" alignItems="center" sx={{marginTop:"30px"}}>
            <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "right", }}>
                <img src={mobileScreens} alt="Mobile Screens" style={{ maxWidth: '399px', width: "100%" }} />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column" }}>
                <Typography component="h1" variant='h3' sx={{ fontWeight: "bold", fontSize: "calc(10px + 2vw)" }} gutterBottom>
                    Download App
                </Typography>
                <Typography variant='h6' gutterBottom fontWeight={"bold"} >
                    Meepaisa Here you can find all types of Products with more offers and discounts. Meepaisa Here you can find all types of Products with more offers and discounts.
                </Typography>
                <Typography variant='h6' gutterBottom fontWeight={"bold"}>
                    
                </Typography>

                <Box sx={{ display: 'flex', marginTop: 4, justifyContent: "center", gap: "80px" }}>
                    <Link href="https://apps.apple.com/in/app/meepaisa/id1629144663">
                        <img src={appStoreIcon} alt="Download Mobile App" style={{ maxWidth: "206px", width: "100%", height: "auto", }} />
                    </Link>
                    <Link href="https://play.google.com/store/apps/details?id=com.user.meepaisa">
                        <img src={playStoreIcon} alt="Download Mobile App" style={{ maxWidth: "206px", width: "100%", height: "auto", }} />
                    </Link>
                </Box>

            </Grid>
        </Grid>
    );
}

export default Promotion;
