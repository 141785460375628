import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi } from '../../CommonAxios'
 
 
const initialState = {
    UpdateProfile: [],
    loading: false,
    error: null,
};
 
 
export const fetchUpdateProfile = createAsyncThunk(
    'UpdateProfile/fetchUpdateProfile',
    async () => {
        try {
            const response = await nodeApi.post('/User/GetUpdateProfile', {}); // Using nodeApi for the API call
            return response.data.results;
        } catch (error) {
            throw error;
        }
    }
);
 
const UpdateProfileSlice = createSlice({
    name: 'UpdateProfile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUpdateProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUpdateProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.coupons = action.payload;
            })
            .addCase(fetchUpdateProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});
 
export default UpdateProfileSlice.reducer;
 