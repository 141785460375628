import React, { useContext, useEffect, useState } from "react";
import { useDispatch} from "react-redux";
import Pagination from '@mui/material/Pagination';
import { Box,TextField, Checkbox, Typography, InputAdornment, useTheme, Autocomplete, Skeleton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { brandsFetch} from "../../../store/slices/retail/home/BrandSlice";
import BrandPageCard from "../../components/cards/BrandPageCard";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../Context";
import useScreenSize from "../../../utils/ScreenWidthHight";

const BrandPage = () => {
    const theme = useTheme();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [brands, setBrands] = useState([]);
    const [isTopBrandChecked, setIsTopBrandChecked] = useState(false);
    const [homeBrand, setHomeBrand] = useState([])
    const [pageNo, setPageNo] = useState(1)
    const [totalCount, setTotalCount] = useState(1)
    const [brandfiltersData, setBrandFiltersData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const { pathname } = useLocation();
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);
    const winSize = useScreenSize()
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (searchParams.get("PageNo")) {
            if (searchParams.get("status")?.includes("topBrands")) {
                brandsFetchActionCall({ Is_Retail: 1, Top_Brands: 1, Search_Brand_Name: searchQuery }, { PageNo: searchParams.get("PageNo"), PageSize: 6 });
            }
            else
                brandsFetchActionCall({ Is_Retail: 1, Search_Brand_Name: searchQuery }, { PageNo: searchParams.get("PageNo"), PageSize: 6 })
        }
        else {
            let querys = "";
            searchParams.forEach((value, key) => {
                querys += "&" + key + "=" + value
            });
            navigate('/retail/brandpage?PageNo=1' + querys, { replace: true });
        }
    }, [searchParams, pathname, searchQuery])

    const brandsFetchActionCall = async (formData, Pagination) => {
        const response = await brandsFetch(formData, Pagination)
        if (response.status) {
            setBrands(response.data)
            setTotalCount(response.Pagination.TotalPages)
            setPageNo(response.Pagination.PageNo)
            setIsLoading(false);
        }
        else {
            setBrands([]);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const formData = { "Is_Grocery": 0, "Is_Retail": 1 };
            const response = await brandsFetch(formData);
            if (response.status) {
                setBrandFiltersData(response.data);
                // Filter brands based on Is_Top_Brand === 1
                const filteredBrands = response.data.filter(brand => isTopBrandChecked ? brand.Is_Top_Brand === 1 : true);
                setBrands(filteredBrands);
                setIsLoading(false); 
            } else {
            }
        } catch (error) {
            // console.error("Error fetching brands:", error);
        }
    };

    const handleCheckBoxClick = () => {
        setIsTopBrandChecked(!isTopBrandChecked);
        updateQueryParam([{ status: !isTopBrandChecked ? "topBrands" : "", PageNo: 1 }])
        navigate(`?status=${!isTopBrandChecked ? "topBrands" : ""}`);
    };

    const handleSearchChange = async (e) => {
        setSearchQuery(e.target.value);
        updateQueryParam([{ PageNo: 1 }])
    };

    const handleChangePage = (event, newPage) => {
        if (searchParams.get("status")?.includes("topBrands"))
            updateQueryParam([{ PageNo: newPage, status: "topBrands" }])
        else
            updateQueryParam([{ PageNo: newPage, status: "" }])
    };

    if (winSize.screenSize.width > 600)
        return (
            <Box sx={{ width: "100%", minHeight: "100vh", display: "flex", flexDirection: { md: "row", xs: "column", sm: "row" }, justifyContent: "center" }} >
                <Box sx={{ width: { md: "25%", sm: "25%", xs: "100%" }, display: { xs: "flex", sm: "unset" }, flexDirection: { xs: "column", sm: "unset" }, alignItems: { xs: "center", sm: "unset" } }}>
                    <Box sx={{ width: { xs: "90%", sm: "100%" } }} >
                        <Typography variant="h6" gutterBottom sx={{ marginTop: '29px', color: theme.palette.text.secondary, display: { xs: "contents", sm: "none" } }}>
                            Brands
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', height: "12vh" }}>
                            <TextField sx={{ width: { xs: "100%", sm: '88%' } }}
                                fullWidth
                                placeholder="Search Brand"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{ color: theme.palette.primary.main, }} />
                                        </InputAdornment>
                                    ),
                                }}
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', }}>
                            <Checkbox
                                checked={isTopBrandChecked}
                                onChange={handleCheckBoxClick}
                            />

                            <Typography sx={{ mt: 1 }}>TOP BRANDS</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ width: { md: "73%", xs: "100%", sm: "70%" } }}>
                    <Typography variant="h6" gutterBottom sx={{ marginTop: '29px', color: theme.palette.text.secondary, display: { xs: "none", sm: "contents" } }}>
                        Brands
                    </Typography>

                    <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", columnGap: { md: "5%", xs: "0" }, justifyContent: { xs: "center", md: "start" }, rowGap: "3vh" }} >
                        {isLoading ? (
                            [0, 1, 2, 3, 4, 5].map((index) => (
                                <BrandPageCard
                                    key={index}
                                    isSkeleton={true}
                                />
                            ))
                        ) : (
                            brands.map((brand, index) => {
                                const cleanDescription = brand.Brand_Description.replace(/<\/?[^>]+(>|$)/g, '');
                                const shortDescription = cleanDescription.slice(0, 130);

                                return (
                                    <BrandPageCard
                                        key={index}
                                        data={brand}
                                        name={brand.Brand_Name}
                                        logoPath={brand.Brand_Image}
                                        Short_description={shortDescription}
                                        onClick={() => { navigate(`/retail/brand/${brand.Brand_Id}`) }}
                                    />
                                );
                            })
                        )}
                     
                    </Box>

                    {totalCount > 1 ? <Box display="flex" justifyContent="center" marginTop={2}>
                        <Pagination
                            count={totalCount}
                            page={pageNo}
                            onChange={(e, value) => handleChangePage(e, value)}
                            color="primary"
                        />
                    </Box> : null}
                </Box>
            </Box>
        )
    else {
        return (
            <Box sx={{ width: "100%", minHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: { md: "center", sm: 'center', xs: 'unset' }, alignItems: "center", rowGap: "15px" }} >
                <Box sx={{ width: "90%", display: "flex", justifyContent: "flex-start", flexDirection: "column", rowGap: "15px" }} >
                    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "15px" }} >
                        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.secondary, display: { xs: "contents", sm: "none" }, }}>
                            Brands
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', }}>
                            <TextField
                                sx={{ width: "100%", }}
                                fullWidth
                                placeholder="Search Brand"
                                size="medium"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{ color: theme.palette.primary.main, transform: "scale(150%)", width: "40px", alignSelf: "left" }} />
                                        </InputAdornment>
                                    ),
                                    sx: { borderRadius: "16px" }
                                }}
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', }}>
                        <Checkbox
                            checked={isTopBrandChecked}
                            onChange={handleCheckBoxClick}
                        />

                        <Typography sx={{ mt: 1 }}>TOP BRANDS</Typography>
                    </Box>
                </Box>

                <Box sx={{ width: "100%" }}>
                    <Typography variant="h6" gutterBottom sx={{ marginTop: '29px', color: theme.palette.text.secondary, display: { xs: "none", sm: "contents" } }}>
                        Brands
                    </Typography>

                    <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", columnGap: { md: "5%", xs: "0" }, justifyContent: { xs: "center", md: "start" }, rowGap: "1.5vh" }} >
                      {isLoading ? (
                            [0, 1, 2, 3, 4, 5].map((index) => (
                                <BrandPageCard
                                    key={index}
                                    isSkeleton={true}
                                />
                            ))
                        ) : (
                                brands.map((brand, index) => {
                                    const cleanDescription = brand.Brand_Description.replace(/<\/?[^>]+(>|$)/g, '');
                                    const shortDescription = cleanDescription.slice(0, 130);

                                    return (
                                        <BrandPageCard
                                            key={index}
                                            data={brand}
                                            name={brand.Brand_Name}
                                            logoPath={brand.Brand_Image}
                                            Short_description={shortDescription}
                                            onClick={() => { navigate(`/retail/brand/${brand.Brand_Id}`) }}
                                        />
                                );
                            })
                        )}
                    </Box>

                    {totalCount > 1 ? <Box display="flex" justifyContent="center" marginTop={2}>
                        <Pagination
                            count={totalCount}
                            page={pageNo}
                            onChange={(e, value) => handleChangePage(e, value)}
                            color="primary"
                        />
                    </Box> : null}
                </Box>
            </Box>
        )
    }
};

export default BrandPage;
