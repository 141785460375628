import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { boomiApi, nodeApi, nodeCudApi, urlGenarator } from "../CommonAxios";

export const getProfileData = createAsyncThunk('profile/getData', async (formData, thunkApi) => {

    try {
        const response = await nodeApi.post('/User/Profile_Get', formData)
        if (response.data?.Success_Response?.Is_Data_Exist === "1")
            return response.data?.User_Details[0][0]
    } catch (err) {
        return thunkApi.rejectWithValue({ error: err.message })
    }
})

export const updateProfileData = createAsyncThunk('profile/updateData', async (formData, thunkApi) => {
    try {
        const response = await nodeCudApi.post('/User/Profile_Update', formData)
        if (response.data?.Response_Code === "200")
            return response.data
    } catch (err) {
        return thunkApi.rejectWithValue({ error: err.message })
    }
})

export const changepasswordAction = (formData) => {
    return new Promise(async (reslove, reject) => {
        await nodeCudApi.post('/User/Change_Password', formData).then((res) => {
            if (res.data.Response_Status !== "Failure") {
                reslove({ status: true, data: res.data })
            }
            else {
                reslove({ status: false, error: res.data })
            }
        }).catch((err) => {
            reslove({ status: false, error: err.message })
        })
    })

}

export const updateProfileDataAction = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post('/User/Profile_Update', formData).then((res) => {
            if (res.data?.Response_Code === "200") {
                resolve({ status: true, data: res.data })
            }
            else
                resolve({ status: false })
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const uploadProfilePicAction = async (formData, fileData) => {
    return new Promise(async (resolve, reject) => {
        let { data } = await boomiApi.post(
            `/User_Upload/Upload?functionality=User&fileextension=${fileData.ext}&filetype=${fileData.ext}&filename=${fileData.fileName}`,
            formData, {
            transformRequest: (d) => d,
        }
        )
        if (data?.File_URL) {
            resolve({ status: true, data: data })
        } else {
            resolve({ status: false, error: data?.UI_Display_Message })
        }
    })
}

export const ProfileDetails = createSlice({
    name: "profileDetails",
    initialState: {
        profileData: [],
        isLoading: false,
        isError: null,
        updateData: null
    },
    extraReducers: (b) => {
        b.addCase(getProfileData.pending, (state, action) => {
            state.isLoading = true
            state.profileData = {}
            state.isError = null
        }).addCase(getProfileData.fulfilled, (state, action) => {
            state.profileData = [action.payload]
            state.isLoading = false
            state.isError = null
        }).addCase(getProfileData.rejected, (state, action) => {
            state.isError = action.payload
            state.isLoading = false
        }).addCase(updateProfileData.pending, (state, action) => {
            state.isLoading = true
        }).addCase(updateProfileData.fulfilled, (state, action) => {
            state.updateData = action.payload
            if (action.payload?.Response_Code === "200") {

                window.alert(action.payload?.UI_Display_Message)

            }
            window.location.reload()
            state.isLoading = false
        }).addCase(updateProfileData.rejected, (state, action) => {
            state.isLoading = false

        })
    }
})


