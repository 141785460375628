import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReviewComponent from '../../components/cards/ReviewComponent';
import { review } from '../../../store/slices/retail/home/ReviewSlice';
import { isEmpty } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Card, Grid, Skeleton } from '@mui/material';
import { Box, Container } from '@mui/system';

const Reviews = ({ data, itemData }) => {
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.review);
  const navigate = useNavigate()
  const theme = useTheme()
  useEffect(() => {
    dispatch(review());
  }, []);


  const img = reduxData.imgeUri[0]?.Brand_Image;
  if (!isEmpty(data))
    return (
      <ReviewComponent imageLabel={data[itemData.imageField]} rating={data[itemData.ratingField]}
        onClick={() => {
          navigate(itemData.onClickLink + data[itemData.idField])
        }}
        seemore={itemData.SeeMore}
        title={itemData.title}
      />
    )
  else
    return (
      <Card sx={{ maxWidth: "343px" }}>
        <Container sx={{ boxShadow: "0px 9px 30px " + theme.palette.extra.cardShadowColorOne, height: 462 }}>

          {
            <Skeleton variant='text' sx={{ position: 'relative', top: 0, right: 0, padding: '8px', fontSize: '19px' }} />
          }


          <Box style={{ display: 'flex', flexDirection: "row" }}>

            <Grid container spacing={2} style={{ flexGrow: 1, paddingTop: 8 }}>
              <Grid item xs={6}>
                {
                  <Skeleton
                    variant='rectangular'
                    sx={{ width: 211, height: 295, objectFit: 'fill', cursor: 'pointer' }}
                  />
                }

              </Grid>
            </Grid>


            
        </Box>

        {
          <Skeleton
            variant='text'
            sx={{ padding: '10px' }}
          />
        }

        <Box style={{ display: 'flex', flexDirection: 'row', position: 'relative', top: 20 }}>

        </Box>
        {
          <Skeleton
            variant='text'
            sx={{ textAlign: "end", paddingTop: 4 }}
          />
        }
      </Container>
    </Card >)
};

export default Reviews;
