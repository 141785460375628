import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { fetchBrands } from "../../../store/slices/retail/home/exploreMoreSlice";
import { Typography, Grid, Box, ButtonBase, Link, Card, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

const ExploreMore = ({ data, itemData }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    // useEffect(() => {
    //   dispatch(fetchBrands());
    // }, [dispatch]);


    return (
        <>
      {data.length > 0 && (
        <Card
          sx={{
            height:
              data.length === 0
                ? "0"
                : data.length <= 2
                  ? "250px"
                  : "462px",
            width: {xs:"100%",sm:'323px'},
            marginBottom: "10px",
            position: "relative",
            borderRadius: 0,
            display: "flex",
            flexDirection: "column",
            rowGap:"10px"
          }}
        >
          <Typography gutterBottom sx={{ ml: '10px', fontSize: '19px', fontWeight: 'bold' }}>
            {itemData.title}
          </Typography>
          <Grid container>
            {data.map((item, index) => (
              <Grid item xs={6} md={6} key={index}>
                <Button
                  sx={{
                    padding: 0,
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  onClick={() => {
                  navigate(itemData.onClickLink + item[itemData.idField]);
                }} disableRipple>
                  <img
                    src={item[itemData.imageField]}
                    alt={`Brand ${index + 1}`}
                    style={{
                      height: "174px",
                      width: "145px",
                      objectFit: "contain",
                      marginLeft: "10px",
                      marginBottom: '11px',
                    }}
                    loading="lazy"
                  />
                </Button>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ alignSelf: "flex-end", marginBottom: "10px", marginRight: "10px" }}>
            <Link
              href={itemData.viewMoreLink}
              color="inherit"
              underline="hover"
              sx={{
                fontSize: "15px", color: theme.palette.primary.main,
                "&:hover": {
                  textDecoration: 'underline'
                },
                fontWeight: 'bold',
                position: "absolute",
                bottom: 20,
                right: 10
}}
            >
              Explore More
            </Link>
          </Box>
        </Card>
      )}
    </>
    );
};

export default ExploreMore;
