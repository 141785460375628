import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { categoriesGetAction, fetchCategories } from "../../../store/slices/retail/home/CategorySlice";
import CategoryCard from "../../components/cards/category/CategoryCard";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../../../utils/ScreenWidthHight";

const Category = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { screenSize } = useScreenSize();



  const getCardsLength = useMemo(() => {
    const cardWidth = 184;
    const screenWidth = screenSize.width;
    return Math.floor(screenWidth / cardWidth) > 7 ? 7 : Math.floor(screenWidth / cardWidth);
  }, [screenSize.width])

  useEffect(() => {
    categoriesGetActionCall({ "Search_Category_Name": "", "Category_Type": "Retail" })
  }, []);

  const handleCategoryClick = (categoryId) => {
    navigate(`/retail/SubCategory/${categoryId}`);
  };

  const categoriesGetActionCall = async (formData) => {
    setLoading(true)
    const response = await categoriesGetAction(formData, {})
    if (response.status) {
      setCategories(response.data)
      setLoading(false)
    }
    else {
      setCategories([])
      setLoading(false)
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          display: "none",
        },
      }}
    >
      <Box
        sx={{
          width:"100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: {xs:"1%", md: "12px", xl: "30px" },
          padding: {xs:0,sm:"10px"},
          justifyContent: "space-between"
        }}
      >
        {(categories?.length > 0 && !loading) &&
          categories.slice(0, getCardsLength).map((category, index) => (
            <CategoryCard
              key={index}
              label={category.Category_Name.slice(0, 14)}
              image={category.Category_Image_Web}
              onClick={() => handleCategoryClick(category.Category_Id)}
              isViewAll={false}
            />
          ))}
        {loading &&
          Array.from({ length: 7 }).map((_, index) => (
            <CategoryCard key={index} isViewAll={false} sk />
          ))}
        {(categories?.length > 0 && !loading) && (
          <CategoryCard
            label="View All"
            onClick={() => navigate(`/retail/category`)}
            isViewAll={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default Category;