import { Box, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useScreenSize from '../../../utils/ScreenWidthHight';
import { useTheme } from '@emotion/react';

const Giftpage = () => {

  const navigate = useNavigate()
  const winSize = useScreenSize();
  const theme = useTheme();

  const handleNavigate = (path) => {
    navigate(path);
  }

  const images = [
    { src: require('../../../assests/images/giftcaed1 (1).png'), path: '/gifts/giftvendor/festival' },
    { src: require('../../../assests/images/giftcaed1 (2).png'), path: '/gifts/giftvendor/occasion' },
    { src: require('../../../assests/images/giftcaed1 (3).png'), path: '/gifts/giftvendor/birthday' },
    { src: require('../../../assests/images/giftcaed1 (4).png'), path: '/gifts/giftvendor/anniversary' },
  ];


  return (
    <Box style={{ flexGrow: 1 }}>
      <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {winSize.screenSize.width > 600 && (
          <Grid item xs={12} md={6} sm={4}>
            <Box sx={{ marginLeft: '4%', marginTop: '3%' }}>
              <img
                src={require('../../../assests/images/Giftpic.png')}
                alt="Left Image"
                style={{ width: '100%', maxHeight: '100%', objectFit: 'contain', height: '600px' }}
              />
            </Box>
          </Grid>
        )}
        <Box
          sx={{
            width: { sm: '50%', md: '50%', xs: '100%' },
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            rowGap: '20px',
            mt: { xs: '35px', sm: 'unset' },
          }}
        >
          <Box sx={{ width: { md: '80%', sm: '80%', xs: '93%' }, display: 'flex', justifyContent: 'flex-start' }}>
            <Typography variant="h5" sx={{ color: theme.palette.text.secondary, }}>
              Choose Occasion
            </Typography>
          </Box>
          {winSize.screenSize.width > 600 ? (
            <Grid container>
              {images.map((image, index) => (
                <Grid item xs={6} key={index}>
                  <Box sx={{ marginRight: { md: '4%', sm: '4%', xs: 'unset' }, cursor: 'pointer' }}>
                    <Link onClick={() => handleNavigate(image.path)}>
                      <img
                        src={image.src}
                        style={{
                          maxWidth: '100%',
                          objectFit: 'contain',
                          display: 'flex',
                          flexWrap: 'wrap',
                          height: '170px',
                          width: '300px',
                          marginRight: '10px',
                          cursor: 'pointer',
                        }}
                      />
                    </Link>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
              <Grid item xs={12} >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' ,}}>
                  {images.map((image, index) => (
                    <Link onClick={() => handleNavigate(image.path)} key={index} sx={{ marginBottom: 2 }}>
                      <img
                        src={image.src}
                        style={{
                          Width: '100%',
                          objectFit: 'contain',
                          height: '170px',
                          cursor: 'pointer',
                        }}
                      />
                    </Link>
                  ))}
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default Giftpage;
