import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi } from '../../CommonAxios';

export const fetchNewsEvents = createAsyncThunk(
    'newsEvents/fetchNewsEvents',
    async (formData) => {
        try {
            const response = await nodeApi.post('/News_Event/Get',formData);
            const data = response.data;
             if (data.Success_Response.Is_Data_Exist === "0") {
                return [];
            } else {
                return data.results;
            }
        } catch (error) {
            throw error.message;
        }
    }
);

const initialState = {
    newsEventsData: [],
    loading: false,
    error: null,
};

const newsEventsSlice = createSlice({
    name: 'newsEvents',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchNewsEvents.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNewsEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.newsEventsData = action.payload;
            })
            .addCase(fetchNewsEvents.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default newsEventsSlice.reducer;
