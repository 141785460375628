import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { fetchDeleteaccount } from '../../store/slices/retail/home/DeleteAccount';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, signOutStateUpdate } from '../../store/slices/AuthSlice';
import { useTheme } from '@emotion/react';


const DeleteAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [isNavigate, setIsNavigate] = useState(false)
  const auth = useSelector(state => state.auth);
  const theme = useTheme();
  useEffect(() => {
    if (isNavigate) {
      navigate('/');
    } else {
      setIsNavigate(false);
    }
  }, [isNavigate])
  const handleDelete = async (formData) => {

    const result = await fetchDeleteaccount(formData);
    if (result.status) {
      const response = await logout({
        User_Session_Id: auth.user.User_Session_Id,
        User_Email_Id: auth.user.Email_Id,
        Device_Token: window.localStorage.getItem("Token")
      })
      if (response.status) {
        window.localStorage.removeItem("Token")
        dispatch(signOutStateUpdate())
        setIsNavigate(true)
      }
      else {
      }
    } else {
    }

  };
  return (
    <Box display="flex" flexDirection="column" sx={{ marginTop: "6%",minHeight:{xs:"100vh",sm:"auto"},alignItems:"center",justifyContent:{xs:"flex-start",sm:"center"}  }}>
      <Typography sx={{ fontSize: '24px', fontWeight: 'bold', color: theme.palette.text.secondary }}>
        Delete Account
      </Typography>
      <Box style={{ width: 130, height: 138, borderRadius: '50%', overflow: 'hidden', margin: '16px auto' }}>
        <img src={require(`../../assests/images/meepaisa.png`)} alt="profile" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
      </Box>
      <Typography sx={{ width: { md: "40%", xs: '90%' }, textAlign: {xs:"center",sm:"left"}, fontSize: {xs:"16px",sm:'24px'}, fontWeight: 'bold', color: theme.palette.text.secondary }}>
        We are sad to see you go. We will make sure for better performance.
        We will keep your data in our records for security reasons and you can also reactivate your account in the future.
      </Typography>
      <Box sx={{width:"100%",display:"flex",justifyContent:"center"}} >
        <Box sx={{ display: 'flex', width: {xs:"90%",sm:'100%'}, justifyContent: { xs: "space-between", sm: "center" }, gap: { xs: "0", sm: "12%" }, marginTop: "10px" }}>
          <Button variant="contained" color="primary" sx={{ width: {xs:"30%",sm:"14%"} }} onClick={() => {
            handleDelete({ Email_Id: auth?.user?.Email_Id })
          }}>
            Delete
          </Button>
          <Button variant="contained" color="primary" sx={{ width: {xs:"30%",sm:"14%"} }}
            onClick={() => { navigate(-1) }}

          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteAccount;
