import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { nodeApi, urlGenarator } from '../../CommonAxios'

const initialState = {
  brands: [],
  loading: false,
  error: "",
  grocerybrands: [],
}

// export const getBrands = createAsyncThunk("homeBrands/getBrands", async () => {
//     const response = await nodeApi.post("/Home/Brand/Get", {})
//     const data = response?.data
//     if (data.Success_Response.Is_Data_Exist == "0") {
//         return []
//     } else {
//         return data.results
//     }
// })

export const brandsFetch = (formData, Pagination) => {
  return new Promise(async (resolve, reject) => {
    if (Boolean(Pagination))
      await nodeApi.post(urlGenarator('/Home/Brand/Get', Pagination), formData).then((res) => {
        if (res.data.Success_Response.Is_Data_Exist === "1") {
          resolve({ status: true, data: res.data.results, Pagination: res.data.Pagination })
        }
        else {
          resolve({ status: false, data: [] })
        }
      }).catch((err) => {
        resolve({ status: false, error: err.message })
      })
  })
}

// export const brandsFetch = (formData) => {
//   return new Promise(async (resolve, reject) => {
//     await nodeApi.post('/Home/Brand/Get', formData).then((res) => {
//       if (res.data.Success_Response.Response_Code == "200") {
//         resolve({ status: true, data: res.data.results })
//       }
//       else {
//         resolve({ status: false, data: [] })
//       }
//     }).catch((err) => {
//       resolve({ status: false, error: err.message })
//     })
//   })
// }

export const brandSearch = (formData) => {
  return new Promise(async (resolve, reject) => {
    await nodeApi.post('/Home/Brand/Get', formData).then((res) => {
      if (res.data.Success_Response.Is_Data_Exist === "1") {
        resolve({ status: true, data: res.data.results })
      }
      else {
        resolve({ status: false, data: [] })
      }
    }).catch((err) => {
      resolve({ status: false, error: err.message })
    })
  })
}

export const grocerygetBrands = createAsyncThunk("homeBrands/grocerygetBrands", async (formData) => {
  const response = await nodeApi.post("/Home/Brand/Get", formData)
  const data = response?.data
  if (data.Success_Response.Is_Data_Exist == "0") {
    return []
  } else {
    return data.results
  }
})


export const getBrands = createAsyncThunk("homeBrands/getBrands", async (formData) => {
  const response = await nodeApi.post("/Home/Brand/Get", formData)
  const data = response?.data
  if (data.Success_Response.Is_Data_Exist == "0") {
    return []
  } else {
    return data.results
  }
})


const homeBrandSlice = createSlice({
  name: 'homeBrands',
  initialState,
  reducers: {

  },
  extraReducers(builder) {
    builder
      .addCase(getBrands.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBrands.fulfilled, (state, action) => {
        state.loading = false
        state.brands = action.payload;
      })
      .addCase(getBrands.rejected, (state, action) => {
        state.loading = false
        state.error = [];
        state.error = action.error;
      })
      .addCase(grocerygetBrands.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(grocerygetBrands.fulfilled, (state, action) => {
        state.loading = false
        state.grocerybrands = action.payload;
      })
      .addCase(grocerygetBrands.rejected, (state, action) => {
        state.loading = false
        state.error = [];
        state.error = action.error;
      })
  }
})

export default homeBrandSlice.reducer;