import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi, nodeCudApi } from '../../CommonAxios';
import React from 'react'
import axios from 'axios';

const initialState = {
  contactStatus: null,
  loading: false,
  error: null,
};

export const contactSupport = createAsyncThunk(
  'contact/contactSupport',
  async (contactData) => {
    try {
      const response = await nodeCudApi.post('/Contact_US/Create', contactData);
      return response.data; 
    } catch (error) {
      throw error;
    }
  }
);
const ContactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(contactSupport.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(contactSupport.fulfilled, (state, action) => {
        state.loading = false;
        state.contactStatus = action.payload;
      })
      .addCase(contactSupport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default ContactSlice.reducer;


export const contactusAction=async (contactData)=>{
  return new Promise(async (resolve,reject)=>{
      const response=await nodeCudApi.post('/Contact_US/Create',contactData).then((res)=>{
          if(res.data.Response_Status==="Success"){
              resolve({status:true})
          }
          else{
              resolve({status:false})
          }
      }).catch((err)=>{
        resolve({status:false})
      })
  })
}