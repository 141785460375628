import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchGifts, fetchRevGifts } from '../../../store/slices/accounts/gift/GiftSlice';
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { Pagination as PN } from "@mui/material";
import useScreenSize from '../../../utils/ScreenWidthHight';
import { useTheme } from '@emotion/react';
import AutoAdjustText from '../../../utils/AutoAdjustText';

const SentOrReceived = () => {
    const dispatch = useDispatch();
    const [pageNo, setPageNo] = useState(1);
    const { gifts = [], Pagination } = useSelector(state => state.gift);
    const auth = useSelector(state => state.auth);
    const [selectedCheckbox, setSelectedCheckbox] = useState(true);
    const navigate = useNavigate();
    const winSize = useScreenSize();
    const theme = useTheme();

    const cardWidth = useMemo(() => {
        let cardWidth = winSize.getWidthForGiveInput(5);
        if (cardWidth <= 326) {
            cardWidth = 326
        }
        if (winSize.screenSize.width / 2 < cardWidth) {
            cardWidth = winSize.screenSize.width / 2 - 30;
        }
        return cardWidth + "px";
    }, [winSize.screenSize.width]);

    useEffect(() => {
        if (selectedCheckbox) {
            dispatch(fetchGifts({
                formData: { Ordered_By_Email_Id: auth.user.Email_Id },
                Pagination: { PageNo: 1, PageSize: 20, SortBy: "Created_Date", SortOrder: "DESC" }
            }));
        } else {
            dispatch(fetchRevGifts({
                formData: { Ordered_To_Email_Id: auth.user.Email_Id },
                Pagination: { PageNo: 1, PageSize: 20, SortBy: "Created_Date", SortOrder: "DESC" }
            }));
        }
    }, [auth, selectedCheckbox, dispatch]);

    const handleCheckboxChange = (event) => {
        setSelectedCheckbox(event.target.value === 'sent');
    };

    const handlePageChange = (event, page) => {
        setPageNo(page);
        if (selectedCheckbox) {
            dispatch(fetchGifts({
                formData: { Ordered_By_Email_Id: auth.user.Email_Id },
                Pagination: { PageNo: page, PageSize: 20 }
            }));
        } else {
            dispatch(fetchRevGifts({
                formData: { Ordered_To_Email_Id: auth.user.Email_Id },
                Pagination: { PageNo: page, PageSize: 20 }
            }));
        }
    };

    return (
        <>
            <Box sx={{ width: "95%", height: "10vh", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                {winSize.screenSize.width < 600 &&
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center", }} >
                        <Box sx={{ display: "flex", flexDirection: 'row', width: "80%", justifyContent: "space-between" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedCheckbox}
                                        onChange={handleCheckboxChange}
                                        value="sent"
                                    />
                                }
                                label="Sent"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!selectedCheckbox}
                                        onChange={handleCheckboxChange}
                                        value="received"
                                    />
                                }
                                label="Received"
                            />
                        </Box>
                    </Box>}
                <Button variant="outlined" sx={{ width: "10%", display: { xs: "none", sm: "block" } }} onClick={() => navigate('/gifts/home')}>
                    + NEW
                </Button>
            </Box>
            {winSize.screenSize.width < 600 ?
                <Box sx={{ width: "100%",display:"flex",flexDirection:"column",rowGap:"15px" }} >
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                        <Box sx={{ width: "90%", display: "flex", justifyContent: "flex-end" }} >

                            <Button variant="outlined" sx={{width:"30%",borderRadius:"10px",fontWeight:"bold",border: "1.5px solid " + theme.palette.extra.primary,}} onClick={() => navigate('/gifts/home')}>
                                + SEND
                            </Button>
                        </Box>
                    </Box>
                    {gifts.length > 0 ? (
                        <Box sx={{ width: "100%" }}>
                            <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "center", rowGap: "15px" }}>
                                {gifts.map((item, index) => (
                                    <Box sx={{ display: "flex", justifyContent: "center", width: "90%", height: "150px" }} >
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            border: "1.5px solid " + theme.palette.extra.primary,
                                            borderRadius: "20px",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                            width: "100%"
                                        }}>
                                            <img

                                                src={item.Image_Url}
                                                alt="category"
                                                style={{
                                                    width: "120px",
                                                    height: "120px",
                                                    borderRadius: "12px",
                                                    objectFit: "fill",
                                                }}

                                            />
                                            <Box sx={{ display: "flex", justifyContent: "center", width: "50%" }}>
                                                <Box key={index} sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
                                                    <Typography
                                                        sx={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            maxWidth: '100%',
                                                            fontSize: "12px"
                                                        }}
                                                    >
                                                        {item.Order_By_Email_Id}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: "12px" }} >{selectedCheckbox ? "Sent To :" : "Sent By :"} <Typography component="span" sx={{ fontWeight: 'bold', fontSize: "12px" }}>{item.Order_To_Name}</Typography></Typography>

                                                    {/* <AutoAdjustText >Message : {item.Gift_Message}</AutoAdjustText> */}
                                                    <AutoAdjustText style={{ width: '100%', height: 'auto', fontSize: "12px" }} numLines={1}>Message : 
                                                        <Typography component="span" sx={{ fontWeight: 'bold', fontSize: "12px" }}> {item.Gift_Message}</Typography>

                                                    </AutoAdjustText>
                                                    <Typography sx={{ fontSize: "12px" }} >Type : <Typography component="span" sx={{ fontWeight: 'bold', fontSize: "12px" }}>{item.Type}</Typography></Typography>
                                                    <Typography sx={{ fontSize: "12px" }} >Vendor :<Typography component="span" sx={{ fontWeight: 'bold', fontSize: "12px" }}> {item.Voucher_Name}</Typography></Typography>
                                                    <Typography sx={{ fontSize: "12px" }} >Gift Card Amount :<Typography component="span" sx={{ fontWeight: 'bold', fontSize: "12px" }}> ₹ {item.Total_Value}</Typography></Typography>
                                                    <Typography sx={{ fontSize: "12px" }} >Date :<Typography component="span" sx={{ fontWeight: 'bold', fontSize: "12px" }}>  {item.Created_Date?.slice(0, 10)}</Typography></Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                            {Pagination?.TotalPages > 1 && (
                                <Box sx={{ display: 'flex', justifyContent: "center", width: "100%", mt: 3 }}>
                                    <PN page={Pagination?.PageNo} onChange={handlePageChange} count={Pagination?.TotalPages} color='primary' />
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box sx={{ width: "70%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography sx={{ fontSize: 30 }}>Data Not Found</Typography>
                        </Box>
                    )}

                </Box>
                :
                <Grid container spacing={1}>
                    <Grid item xs={12} md={3} sx={{ display: { xs: "none", sm: "block" } }} >
                        <Box sx={{ marginLeft: '20px', display: "flex", flexDirection: 'column' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedCheckbox}
                                        onChange={handleCheckboxChange}
                                        value="sent"
                                    />
                                }
                                label="Sent"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!selectedCheckbox}
                                        onChange={handleCheckboxChange}
                                        value="received"
                                    />
                                }
                                label="Received"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        {gifts.length > 0 ? (
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ display: "flex", flexWrap: "wrap", minWidth: "100%", gap: 2, justifyContent: "center" }}>
                                    {gifts.map((item, index) => (
                                        // <GiftSendOrReceivedCard key={index} index={index} data={item} onClick={() => { }} />
                                        <Box key={index}>

                                            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px", width: cardWidth }} >


                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    border: "1px solid " + theme.palette.extra.primary,
                                                    width: "300px",
                                                    maxWidth: "100%",
                                                    borderRadius: "20px",

                                                }}>

                                                    <img

                                                        src={item.Image_Url}
                                                        alt="category"
                                                        style={{
                                                            width: "294px",
                                                            height: "240px",
                                                            maxHeight: "100%",
                                                            maxWidth: "100%",
                                                            borderRadius: "50px",
                                                            margin: "3px",
                                                            objectFit: "contain",
                                                            display: "flex", flexWrap: "wrap",
                                                        }}

                                                    />

                                                    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                                        <Box key={index} sx={{ display: "flex", flexDirection: "column", width: "90%" }} >
                                                            <Typography
                                                                sx={{
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    maxWidth: '100%',
                                                                }}
                                                            >
                                                                {item.Order_By_Email_Id}
                                                            </Typography>
                                                            <Typography>{selectedCheckbox ? "Sent to:" : "Sent by:"} <Typography component="span" sx={{ fontWeight: 'bold' }}>{item.Order_To_Name}</Typography></Typography>

                                                            {/* <AutoAdjustText >Message : {item.Gift_Message}</AutoAdjustText> */}
                                                            <AutoAdjustText style={{ width: '100%', height: 'auto', }} numLines={1}>Message:
                                                                <Typography component="span" sx={{ fontWeight: 'bold' }}>{item.Gift_Message}</Typography>

                                                            </AutoAdjustText>
                                                            <Typography>Type : <Typography component="span" sx={{ fontWeight: 'bold' }}>{item.Type}</Typography></Typography>
                                                            <Typography>Voucher :<Typography component="span" sx={{ fontWeight: 'bold' }}> {item.Voucher_Name}</Typography></Typography>
                                                            <Typography>Gift Card Amount :<Typography component="span" sx={{ fontWeight: 'bold' }}> ₹ {item.Total_Value}</Typography></Typography>
                                                            <Typography>Date :<Typography component="span" sx={{ fontWeight: 'bold' }}>  {item.Created_Date?.slice(0,10)}</Typography></Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                                {Pagination?.TotalPages > 1 && (
                                    <Box sx={{ display: 'flex', justifyContent: "center", width: "100%", mt: 3 }}>
                                        <PN page={Pagination?.PageNo} onChange={handlePageChange} count={Pagination?.TotalPages} color='primary' />
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Box sx={{ width: "70%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography sx={{ fontSize: 30 }}>Data Not Found</Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default SentOrReceived;
