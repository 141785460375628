import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecentlyViewedProducts } from "../../../store/slices/retail/home/RecentlyViewedSlice";
import RecentlyViewCard from "../../components/cards/RecentlyViewCard";
import { Typography, Box, IconButton, Button } from "@mui/material";
import leftarrow from "../../../assests/images/leftArrow.png";
import rightarrow from "../../../assests/images/rightArrow.png";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import useScreenSize from "../../../utils/ScreenWidthHight";

const RecentlyView = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { products, loading } = useSelector(
    (state) => state.recentlyViewed
  );
  const containerRef = useRef(null);
  const auth = useSelector(state => state.auth);
  const navigate = useNavigate();
  const winSize = useScreenSize();
  const [disable, setDisable] = useState("LEFT")


  useEffect(() => {
    if (auth?.isAuthenticated) {
      dispatch(fetchRecentlyViewedProducts({ Business_Type: "GROCERY", User_Email_Id: auth?.user?.Email_Id, Available_Status: "Active" }));
    }
  }, [auth, dispatch]);

  const disableLessProducts = useMemo(() => {
    if (containerRef.current) {
      if (containerRef.current.scrollWidth > (winSize.screenSize.width || 1536)) {
        return false
      }
      else {
        return true
      }
    }
  }, [winSize, containerRef])



  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= containerRef.current.offsetWidth;
      setDisable("")
      if ((containerRef.current.scrollLeft - containerRef.current.offsetWidth) <= 0) {
        setDisable("LEFT")
      }
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += containerRef.current.offsetWidth;
      setDisable("")
      if (Number(containerRef.current.scrollLeft + (containerRef.current.offsetWidth + containerRef.current.offsetWidth)) >= (containerRef.current.scrollWidth)) {
        setDisable("RIGHT")
      }
    }
  };

  if (!auth?.isAuthenticated) {
    return null;
  }
  if (!loading && products.length > 0)
    return (
      <Box>
        <Typography variant="h5" alignSelf="flex-start" gutterBottom sx={{ fontSize: '24px', fontWeight: 'bold', color: theme.palette.text.secondary, }}>
          {winSize.screenSize.width >= 600 ?
            "Recently Viewed Products " :
            "Recently Viewed"
          }

        </Typography>
        <Box
          sx={{ textAlign: "right", marginTop: "-46px", marginRight: "18px" }}
        >
          <IconButton onClick={scrollLeft} disabled={disable === "LEFT" || disableLessProducts} >
            <img src={disable === "LEFT" ? leftarrow : rightarrow} alt="Left Arrow" style={{ width: "25px", transform: disable === "LEFT" ? "rotate(0deg)" : "rotate(180deg)" }} />
          </IconButton>
          <IconButton onClick={scrollRight} disabled={disable === "RIGHT" || disableLessProducts} >
            <img src={disable === "RIGHT" ? leftarrow : rightarrow} alt="Right Arrow" style={{ width: "25px", transform: disable === "RIGHT" ? "rotate(180deg)" : "rotate(0deg)" }} />
          </IconButton>
        </Box>
        <Box
          ref={containerRef}
          sx={{
            display: "flex",
            overflowX: "hidden",
            gap: "19px",
            padding: "10px",
            scrollBehavior: "smooth",
          }}
        >
          {(products.length > 0 && !loading) && products.map((recentlyview, index) => (
            <RecentlyViewCard
              key={index}
              image={recentlyview.Product_Image}
              brandName={recentlyview.Brand_Name}
              productName={recentlyview.Product_Name.length > 15 ?
                recentlyview.Product_Name.charAt(0).toUpperCase() + recentlyview.Product_Name.toLowerCase().substring(1, 20) + '..' :
                recentlyview.Product_Name.charAt(0).toUpperCase() + recentlyview.Product_Name.toLowerCase().substring(1)}
              shortDescription={recentlyview.Product_Short_Description.length > 45 ?
                recentlyview.Product_Short_Description.charAt(0).toUpperCase() + recentlyview.Product_Short_Description.toLowerCase().substring(1, 50) + '..' :
                recentlyview.Product_Short_Description.charAt(0).toUpperCase() + recentlyview.Product_Short_Description.toLowerCase().substring(1)}
              originalPrice={recentlyview.Original_Price.toString()}
              partnerSellingPrice={recentlyview.Partner_Selling_Price.toString()}
              item={recentlyview}
              handleNavigation={() => {
                if (auth?.isAuthenticated) {
                  props.handleSetModalProductId(recentlyview.Product_Id);
                  props.handleModalOpen();
                } else {
                  navigate('/auth/signin');
                }
              }}
              isActive={recentlyview.Available_Status !== "In-Active"}
            />
          ))

          }


          {/* {loading && 
        } */}

        </Box>
      </Box>
    )
  else if (loading) {
    return (
      <Box
        ref={containerRef}
        sx={{
          display: "flex",
          overflowX: "hidden",
          gap: "19px",
          padding: "10px",
          scrollBehavior: "smooth",
        }}
      >
        {Array(6).fill(1).map((recentlyview, index) => (
          <RecentlyViewCard
            key={index}
            isSkeleton

          />

        ))}
      </Box>
    )
  }
  else {
    return null
  }
};

export default RecentlyView;
