import React, { useEffect } from 'react';
import { Box, Button, Grid, Rating, TextField, Typography } from "@mui/material";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalContext } from '../../../../Context';
import { useNavigate } from 'react-router-dom';
import { partnerReviewCreate, partnerReviewUpdate, partnerReviewsFetch } from '../../../../store/slices/retail/home/partnerReviewSlice';

const PartnerReviewForm = ({ partners, partnerId, ReviewerName, EmailID, partnerReviewId, data, isUpdateForm, partnersReviewsCall }) => {

    const partnerEmailID = partners.length > 0 ? partners[0].Partner_Email_Id : 'No partners'
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const userDetails = useSelector((state) => state.auth);
    const isAuthenticated = userDetails.isAuthenticated;
    const { partnerReviews } = useSelector(state => state.partnerReviews)
    // Set initial values based on whether data is present and if the user's email matches
    const initialValues = {
        comments: data && data.Reviewer_Email_Id === EmailID ? data.Comments : '',
        rating: data && data.Reviewer_Email_Id === EmailID ? data.Rating : 0
    };

    const validationSchema = Yup.object().shape({
        comments: Yup.string().max(255).required('Please enter comments'),
        rating: Yup.number().min(1, 'Rating must be at least 1').max(5, 'Rating must be at most 5').required('Please provide a rating'),
    }); const { toolTipActive } = React.useContext(GlobalContext);

    const handleSubmit = async (values) => {
        if (isAuthenticated === true) {
            try {
                const formData = {
                    Comments: values.comments,
                    User_Email_Id: EmailID,
                    Partner_Details_Id: partnerId,
                    Reviewer_Name: ReviewerName,
                    Partner_Email_Id: partnerEmailID,
                    Rating: values.rating,
                };

                const formDataUpdate = {
                    Comments: values.comments,
                    Rating: values.rating,
                    User_Email_Id: EmailID,
                    Partner_Review_Id: partnerReviewId
                };

                if (isUpdateForm) {
                    const response = await partnerReviewUpdate(formDataUpdate);
                    if (response.status) {
                        toolTipActive("success", "partner review updated successfully.");
                        partnersReviewsCall(partnerId);
                    } else {
                        toolTipActive("error", "Failed to update partner review.");
                    }
                } else {
                    const response = await partnerReviewCreate(formData);
                    if (response.status) {
                        toolTipActive("success", "partner review created successfully.");
                        partnersReviewsCall(partnerId);
                    } else {
                        toolTipActive("error", "The Reviewer has already given a review. Please try with another.");
                    }
                }


            } catch (error) {
                // console.error('Error:', error);
            }
        } else {
            navigate("/auth/signin");
        }
    };

    return (
        <>
            {isUpdateForm ? (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, values, setFieldValue, errors, touched }) => (
                        <Form>
                            <Typography sx={{ marginBottom: "15px", marginLeft: '-15px' }}>Your Review</Typography>
                            <Grid container spacing={2} sx={{ display: "flex", flexDirection: "column", gap: 7 }}>
                                <Box sx={{ width: "100%" }}>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            name="comments"
                                            label="Comment"
                                            fullWidth
                                            variant="outlined"
                                            size="large"
                                            sx={{ mt: 1 }}
                                            error={Boolean(touched.comments && errors.comments)}
                                            helperText={touched.comments && errors.comments ? errors.comments : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1 }}>
                                        <Rating
                                            name="rating"
                                            value={values.rating}
                                            onChange={(event, newValue) => setFieldValue('rating', newValue)}
                                        />
                                        {touched.rating && errors.rating && (
                                            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                                {errors.rating}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                        <Button type="submit" variant="contained" onClick={handleSubmit}>Update</Button>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            ) : (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, values, setFieldValue, errors, touched }) => (
                        <Form>
                            <Typography sx={{ marginBottom: "15px", marginLeft: '-15px' }}>Add Review</Typography>
                            <Grid container spacing={2} sx={{ display: "flex", flexDirection: "column", gap: 7 }}>
                                <Box sx={{ width: "100%" }}>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            name="comments"
                                            label="Comment"
                                            fullWidth
                                            variant="outlined"
                                            size="large"
                                            sx={{ mt: 1 }}
                                            error={Boolean(touched.comments && errors.comments)}
                                            helperText={touched.comments && errors.comments ? errors.comments : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1 }}>
                                        <Rating
                                            name="rating"
                                            value={values.rating}
                                            onChange={(event, newValue) => setFieldValue('rating', newValue)}
                                        />
                                        {touched.rating && errors.rating && (
                                            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                                {errors.rating}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                        <Button type="submit" variant="contained" onClick={handleSubmit}>Submit</Button>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );


};

export default PartnerReviewForm;
