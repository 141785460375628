import React from 'react';
import { Box, Button, Grid, Rating, TextField, Typography } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { brandReviewCreate, brandReviewUpdate, brandReviews } from '../../../../store/slices/retail/home/BrandReviewSlice';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../../Context'
import { useTheme } from '@emotion/react';

const ReviewForm = ({ brandId, brandName, EmailID, BrandReviewId, data, isUpdateForm, brandCommentsCall }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const userDetails = useSelector((state) => state.auth);
    const isAuthenticated = userDetails.isAuthenticated;
    const { toolTipActive } = React.useContext(GlobalContext);
    // Set initial values based on whether data is present and if the user's email matches
    const initialValues = {
        comments: data && data.User_Email_Id === EmailID ? data.Comments : '',
        rating: data && data.User_Email_Id === EmailID ? data.Rating : 0
    };

    const validationSchema = Yup.object().shape({
        comments: Yup.string().max(255).required('Please enter comments'),
        rating: Yup.number().min(1, 'Rating must be at least 1').max(5, 'Rating must be at most 5').required('Please provide a rating'),
    });

    const handleSubmit = async (values, actions) => {
        if (isAuthenticated) {
            try {
                const formData = {
                    Comments: values.comments,
                    Rating: values.rating,
                    User_Email_Id: EmailID,
                    Brand_Id: brandId,
                    Brand_Name: brandName,
                };

                const formDataUpdate = {
                    Comments: values.comments,
                    Rating: values.rating,
                    User_Email_Id: EmailID,
                    Brand_Review_Id: BrandReviewId
                };

                let response;
                if (isUpdateForm) {
                    response = await brandReviewUpdate(formDataUpdate);
                    if (response.status) {
                        toolTipActive("success", "Brand review updated successfully.");
                    } else {
                        toolTipActive("error", "Failed to update brand review.");
                    }
                } else {
                    response = await brandReviewCreate(formData);
                    if (response.status) {
                        toolTipActive("success", "Brand review created successfully.");
                    } else {
                        toolTipActive("error", "The Reviewer has already given Review. Please try with another.");
                    }
                }

                if (response.status) {
                    brandCommentsCall(brandId);
                }
            } catch (error) {
                // console.error('Error:', error);
                toolTipActive("error", isUpdateForm ? "Failed to update brand review." : "Failed to create brand review.");
            }
        } else {
            navigate("/auth/signin");
        }
    };




    return (
        <>
            {isUpdateForm ? (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, values, setFieldValue, touched, errors }) => (
                        <Form>
                            <Typography sx={{ marginBottom: "15px", marginLeft: '-15px',fontWeight:"bold" }}>{"Your Review".toUpperCase()}</Typography>
                            <Grid container spacing={2} sx={{ display: "flex", flexDirection: "column", gap: 7 }}>
                                <Box sx={{ width: "100%" }}>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            name="comments"
                                            label="Comment"
                                            fullWidth
                                            variant="outlined"
                                            size="large"
                                            sx={{ mt: 1 }}
                                            error={Boolean(touched.comments && errors.comments)}
                                            helperText={touched.comments && errors.comments ? errors.comments : ''}
                                        />

                                    </Grid>

                                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1 }}>
                                        <Rating
                                            name="rating"
                                            value={values.rating}
                                            onChange={(event, newValue) => setFieldValue('rating', newValue)}
                                        />
                                        {touched.rating && errors.rating && (
                                            <Typography variant="body2" sx={{ mt: 1, color: theme.palette.error.main }}>
                                                {errors.rating}
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                        <Button type="submit" variant="contained" onClick={handleSubmit}>Update</Button>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            ) : (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, values, setFieldValue, errors, touched, isValid }) => (
                        <Form>
                            <Typography sx={{ marginBottom: "15px", marginLeft: '-15px',fontWeight:"bold" }}>{"Add Review".toUpperCase()}</Typography>
                            <Grid container spacing={2} sx={{ display: "flex", flexDirection: "column", gap: 7 }}>
                                <Box sx={{ width: "100%" }}>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            name="comments"
                                            label="Comment"
                                            fullWidth
                                            variant="outlined"
                                            size="large"
                                            sx={{ mt: 1 }}
                                            error={Boolean(touched.comments && errors.comments)}
                                            helperText={touched.comments && errors.comments ? errors.comments : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1 }}>
                                        <Rating
                                            name="rating"
                                            value={values.rating}
                                            onChange={(event, newValue) => setFieldValue('rating', newValue)}
                                        />
                                        {touched.rating && errors.rating && (
                                            <Typography variant="body2" sx={{ mt: 1, color: theme.palette.error.main }}>
                                                {errors.rating}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                        <Button type="submit" variant="contained" onClick={handleSubmit}>Submit</Button>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );


};

export default ReviewForm;
