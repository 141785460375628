import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AllProductsActions, fetchAllProducts } from "../../../../store/slices/retail/home/AllProductsSlice";
import AllProductsCard from "../../../components/cards/AllProductsCard";
import { Typography, Box, Pagination, Skeleton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import useScreenSize from "../../../../utils/ScreenWidthHight";
import noProductFound from '../../../../assests/images/NoProductFound.png'



const AllProducts = (props) => {
  const dispatch = useDispatch();
  const { products, pagination, filters, loading, error } = useSelector((state) => state.allProducts);
  const [reCall, setReCall] = useState();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth);
  const theme = useTheme();
  const location = useLocation();

  const winSize = useScreenSize();
  const cardWidth = useMemo(() => {
    let cardWidth = winSize.getWidthForGiveInput(8);
    if (cardWidth <= 170) {
      cardWidth = 170;
    }
    if (winSize.screenSize.width / 2 < cardWidth + 50) {
      cardWidth = winSize.screenSize.width / 2 - 20;
    }
    return cardWidth + "px";
  }, [winSize.screenSize.width]);
  useEffect(() => {
    if (!loading) {
      callProductsAction();
    } else {
      setReCall(true);
    }
  }, [filters]);

  useEffect(() => {
    if (!loading && reCall) {
      setReCall(false);
      callProductsAction();
    }
  }, [loading]);

  const callProductsAction = (pageno = 1) => {

    const callFilter = {
      Search: filters.Search,
      Business_Type: "RETAIL",
      Status: filters.Status,
      Brand_Id: filters.Brand_Id.map((item) => item.id),
      Partner_Details_Id: filters.Partner_Details_Id.map((item) => item.id),
      Category_Id: filters.Category_Id.map((item) => item.id),
      Sub_Category_Id: filters.Sub_Category_Id.map((item) => item.id),
      Is_Top_Product: filters.Is_Top_Product,
      Is_Popular: filters.Is_Popular,
      Is_Verified: filters.Is_Verified,
      Whats_Hot: filters.Whats_Hot,
      Is_Upcoming: filters.Is_Upcoming
    };

    if (filters.Min_Discount > 0 || filters.Max_Discount > 0) {
      callFilter.Min_Discount = filters.Min_Discount
      callFilter.Max_Discount = filters.Max_Discount
    }

    if (filters.Min_Price > 0 || filters.Max_Price > 0) {
      callFilter.Min_Price = filters.Min_Price
      callFilter.Max_Price = filters.Max_Price
    }

    dispatch(fetchAllProducts({ filters: callFilter, pagination: { PageNo: pageno, PageSize: 30, SortBy: "Product_Id", SortOrder: "DESC" } }));
  }
  const containerRef = useRef();
  const [pageLoad, setPageLoad] = useState(false);
  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        setPageLoad(state => !state)
      }
    }
  };

  useEffect(() => {
    if (pagination?.TotalPages > pagination?.PageNo)
      callProductsAction((pagination?.PageNo || 0) + 1);
  }, [pageLoad])

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <Box sx={{
      height: '150vh',
      overflowY: 'auto',
    }}
      ref={containerRef}
    >
      <Box sx={{ width: "100%", height: "13px" }} ></Box>
      <Typography sx={{ fontSize: '24px', fontWeight: 'bold', color: theme.palette.text.secondary, }}>All Products</Typography>
      <Box sx={{ width: "100%", height: "20px" }} ></Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: { xs: ("space-around"), sm: (winSize.screenSize.width < 800 ? "space-around" : "flex-start"), md: 'flex-start' },
        }}
        gap={1}
      >
        {products.length > 0 &&

          products.map((item, index) => (
            <AllProductsCard
              key={item.Partner_Product_Id + index}
              image={item.Product_Image}
              brandName={item.Brand_Name}
              productName={item.Product_Name}
              shortDescription={item.Product_Short_Description}
              originalPrice={item.Original_Price.toString()}
              partnerSellingPrice={item.Partner_Selling_Price.toString()}
              item={item}
              handleNavigation={() => {
                if (auth?.isAuthenticated !== null) {
                  props.handleSetModalProductId(item.Product_Id)
                  props.handleModalOpen()
                }
                else {
                  navigate('/auth/signin')
                }
              }}
            />
          ))

        }
        {
          (products.length % 2 !== 0 && winSize.screenSize.width < 500)
          &&
          <Box
            sx={{
              width: { xs: (winSize.screenSize.width > 500 ? "28%" : "44%"), sm: (winSize.screenSize.width < 900 ? (winSize.screenSize.width < 700 ? "45%" : "28%") : "20%"), md: (winSize.screenSize.width < 1200 ? (winSize.screenSize.width < 1000 ? "28%" : "22%") : ("17%")) },
              maxWidth: { xs: "169px", sm: "auto" },
              minWidth: { md: "180px", xs: "unset", sm: "unset" },
              paddingX: { md: "1%", sm: "1.7%", xs: "1.7%" },
              height: "434px",
              borderRadius: "12px",
              overflow: "hidden",
            }}
          ></Box>
        }
        {
          (products.length % 3 !== 0 && ((winSize.screenSize.width < 600 && winSize.screenSize.width > 500) ||  (winSize.screenSize.width < 800 && winSize.screenSize.width > 600)))
          &&
          Array(3 - (products.length % 3)).fill(1).map((e, i) => {
            return (
              <Box
                sx={{
                  width: { xs: (winSize.screenSize.width > 500 ? "28%" : "44%"), sm: (winSize.screenSize.width < 900 ? (winSize.screenSize.width < 700 ? "45%" : "28%") : "20%"), md: (winSize.screenSize.width < 1200 ? (winSize.screenSize.width < 1000 ? "28%" : "22%") : ("17%")) },
                  maxWidth: { xs: "169px", sm: "auto" },
                  minWidth: { md: "180px", xs: "unset", sm: "unset" },
                  paddingX: { md: "1%", sm: "1.7%", xs: "1.7%" },
                  height: "434px",
                  borderRadius: "12px",
                  overflow: "hidden",
                }}
              ></Box>
            )
          })

        }
        
        {
          loading &&
          Array(10).fill(1).map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  maxWidth: "169px",
                  width: cardWidth,
                  height: "434px",
                  borderRadius: "12px",
                  overflow: "hidden",

                }}
              >
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: "100%",
                    height: "100%"
                  }}
                />
              </Box>
            )
          })
        }
      </Box>

      {(products.length == 0 && !loading) &&
        <Box sx={{ width: "100%", height: "100vh", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", }} >

          <img src={noProductFound} style={{ marginTop: '20px', width: '40%', display: 'block', margin: '20px auto' }} />
        </Box>
      }


    </Box>
  );
};

export default AllProducts;