import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { productFetch } from "../../../../store/slices/accounts/orders/OrdersSlice";
import PaymentSummary from '../components/PaymentSummary'
import OrderStepper from "../components/OrderStepper";
import OrderDetailsCard from '../components/OrderDetailsCard'
import { useLocation, useNavigate, useParams, } from "react-router-dom";
import DeliveryAgentReview from "../components/DeliveryAgentReview";
import { deliveryAgentReviewGet } from "../../../../store/slices/accounts/orders/DeliveryAgentReviews";
import DeliveryReviews from "../components/DeliveryReviews";
import { CancelModalComp } from "../components/CancelModal";
import { useTheme } from "@emotion/react";
import { isEmpty } from "../../../../utils";

const OrdersDetailsPage = () => {
    const dispatch = useDispatch();
    const theme = useTheme()
    const userDetails = useSelector(state => state.auth);
    const { orders, products } = useSelector(state => state.orders);
    const { deliveryReviews } = useSelector(state => state.deliveryReviews);
    const navigate = useNavigate()
    const { id } = useParams();
    const { state } = useLocation();
    const { Order_Id } = state
    const EmailID = userDetails?.user?.Email_Id;
    const [product, setProduct] = useState([]);
    const [deliveryReviewsGet, setDeliveryReviewsGet] = useState([]);
    const [yourComments, setYourComments] = useState([]);
    const [productNewdata, setProductNewData] = useState({})
    const auth = useSelector(state => state.auth)
    const [isReturn, setIsReturn] = useState(false)
    const [pageNotFoundStatus, setPageNotFoundStatus] = useState(false)
    const [cancelModal, setCancelModal] = useState(false)

    useEffect(() => {
        if (id && auth.isAuthenticated) {
            productsCall(id);
            deliveryCommentsCall(id);
        }
        else {
            navigate('/auth/signin')
        }
    }, []);

    const productsCall = async (id) => {
        const formData = { Item_Master_Id: [id], User_Email_Id: userDetails.user.Email_Id, Order_Id };
        const response = await productFetch(formData);
        try {
            if (response.status) {
                setProduct(response.data.Product_Details.Product_Id);
                setProductNewData([response.data].map((Product) => ({
                    Product_Name: Product.Product_Details.Product_Name,
                    Partner_Name: Product.Product_Details.Partner_Name,
                    Order_Date: Product.Product_Details.Order_Date,
                    Quantity: Product.Product_Details.Quantity,
                    Product_Id: Product.Product_Details.Product_Id,
                    Order_Id: Product.Orderdetails.Order_Id,
                    Customer_Name: Product.Shipping_Address.Name,
                    customer_DoorNo: Product.Shipping_Address.Door_No,
                    Customer_Address: Product.Shipping_Address.Location,
                    Customer_PinCode: Product.Shipping_Address.Zip,
                    Customer_Mobile: Product.Shipping_Address.Mobile,
                    Order_Status: Product.Product_Details.Line_Item_Order_Status,
                    Line_Item_Order_Status: Product.Product_Details.Line_Item_Order_Status,
                    Is_Item_Cancel_Flag: Boolean(Product.Product_Details.Is_Item_Cancel_Flag === 1),
                    Item_Master_Id: Product?.Product_Details?.Item_Master_Id,
                    Billing_Name: Product.Billing_Address.Name,
                    Billing_DoorNo: Product.Billing_Address.Door_No,
                    Billing_Address: Product.Billing_Address.Location,
                    Billing_Pincode: Product.Billing_Address.Zip,
                    Billing_Mobile: Product.Billing_Address.Mobile,
                    Agent_Mobile: Product.Del_User_Details.Mobile,
                    Agent_Name: Product.Del_User_Details.Full_Name,
                    Agent_Photo: Product.Del_User_Details.Photo,
                    Partner_Selling_Price: Product.Product_Details.Partner_Selling_Price,
                    Igst: Product.Item_Payment_Summary.Igstpercentage,
                    Vat: Product.Item_Payment_Summary.Vatpercentage,
                    IgstAmount: Product.Item_Payment_Summary.Igst,
                    VatAmount: Product.Item_Payment_Summary.Vat,
                    Total_Tax: Product.Item_Payment_Summary.Total_Tax,
                    Total_Net_Price: Product.Item_Payment_Summary.Total_Net_Price,
                    // Coupon_Discount:Product.Item_Payment_Summary.,
                    Gift_Wrap_Amount: Product.Item_Payment_Summary.Gift_Charges,
                    Delivery_Person_Fee: Product.Item_Payment_Summary.Delivery_Charges,
                    Gross_Price: Product.Orderdetails.Order_Total_Amount,
                    Product_Image: Product.Product_Details.Product_Image,
                    Partner_Coupon_Amount: Product.Item_Payment_Summary.Partner_Coupon_Amount,
                    Is_Item_Retutn_Flag: Boolean(Product.Product_Details.Is_Item_Retutn_Flag === 1),
                    Max_Days_To_Return: Product.Product_Details.Max_Days_To_Return



                }))[0]
                )

            } else {
                setPageNotFoundStatus(true)
            }
        } catch (error) {
        }

    };

    const deliveryCommentsCall = async (id) => {
        try {
            const formData = { Order_Line_Item_Id: [id], User_Email_Id :EmailID };
            const response = await deliveryAgentReviewGet(formData);
            if (response.status && response.data) {
                const filteredData = response.data.results.filter(filtr => filtr.User_Email_Id === EmailID);
                setYourComments(filteredData);
                setDeliveryReviewsGet(filteredData);
            } else {
            }
        } catch (error) {
        }
    };


    const handelCancelModal = () => {
        setCancelModal(false)
    }
    const handelCancelModalOpen = () => {
        setCancelModal(true)
    }
    if (!pageNotFoundStatus)
        return (
            <Grid container p={{md:5,xs:2}}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: "10px" }} >
                        <OrderDetailsCard
                            productImage={""}
                            Product_Id={product}
                            Product_Name={productNewdata?.Product_Name}
                            Product_Image={productNewdata?.Product_Image}
                            Order_Id={productNewdata?.Order_Id}
                            Order_Date={productNewdata?.Order_Date}
                            Quantity={productNewdata?.Quantity}
                            Partner_Name={productNewdata?.Partner_Name}

                            customerName={productNewdata?.Customer_Name}
                            cDoorNo={productNewdata?.customer_DoorNo}
                            cAddress={productNewdata?.Customer_Address}
                            // cAddress={productNewdata?.Location}
                            cPinCode={productNewdata?.Customer_PinCode}
                            cPhoneNumber={productNewdata?.Customer_Mobile}
                            Order_Status={productNewdata?.Order_Status}

                            billingName={productNewdata?.Billing_Name}
                            billingDoorNo={productNewdata?.Billing_DoorNo}
                            billingAddress={productNewdata?.Billing_Address}
                            billingPinCode={productNewdata?.Billing_Pincode}
                            billingPhoneNumber={productNewdata?.Billing_Mobile}

                            agentName={productNewdata?.Agent_Name}
                            agentPhoneNumber={productNewdata?.Agent_Mobile}
                            agentProfileImage={productNewdata?.Agent_Photo}

                            item={productNewdata}
                            id={id}
                            EmailID={EmailID}
                            handelCancelModalOpen={handelCancelModalOpen}
                            setIsReturn={setIsReturn}
                            Line_Item_Order_Status={productNewdata?.Line_Item_Order_Status}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{mt:{md:'50px', sm:'50px', xs:'0px'}}}>
                    <OrderStepper status={productNewdata?.Order_Status || ""} Line_Item_Order_Status={productNewdata?.Line_Item_Order_Status} />
                    <Typography sx={{ fontSize: {md:'30px', xs:'16px'}, marginTop: {md:'40px', sm:'40px', xs:'50px'} }}>
                        <strong>Payment Summary</strong>
                    </Typography>
                    <PaymentSummary
                        payment={{}}
                        Order_Id={productNewdata?.Order_Id}
                        Partner_Selling_Price={productNewdata?.Partner_Selling_Price}
                        Order_Type={"RETAIL"}
                        Quantity={productNewdata?.Quantity}
                        id={id}
                        EmailID={EmailID}
                        Igst={productNewdata?.Igst}
                        Total_Tax={productNewdata?.Total_Tax}
                        Gift_Wrap_Amount={productNewdata?.Gift_Wrap_Amount}
                        Delivery_Person_Fee={productNewdata?.Delivery_Person_Fee}
                        Gross_Price={productNewdata?.Gross_Price}
                        Order_Status={productNewdata?.Order_Status || ""}
                        Coupon_Discount={productNewdata?.Partner_Coupon_Amount}
                        Vat={productNewdata?.Vat}
                        VatAmount={productNewdata?.VatAmount}
                        IgstAmount={productNewdata?.IgstAmount}
                        Total_Net_Price={productNewdata?.Total_Net_Price}
                    />
                    {(deliveryReviewsGet.length === 0 && !isEmpty(productNewdata?.Agent_Mobile)) && (
                        <Grid>
                            <DeliveryAgentReview data={productNewdata} EmailID={EmailID} setDeliveryReviewsGet={setDeliveryReviewsGet} deliveryCommentsCall={deliveryCommentsCall} />
                        </Grid>
                    )}

                    <Grid>
                        {Boolean(yourComments[0]) &&
                            <DeliveryReviews
                                rating={yourComments[0]?.Rating}
                                comments={yourComments[0]?.Comments}
                            />}
                    </Grid>
                </Grid>
                <CancelModalComp cancelModal={cancelModal} handelCancelModal={handelCancelModal} id={id} Order_Id={Order_Id} productsCall={productsCall} isReturn={isReturn} />
            </Grid >
        );
    else {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                <Typography sx={{ fontSize: "30px", fontWeight: "bold" }} >Page Not Found</Typography>
            </Box>
        )
    }
};

export default OrdersDetailsPage;

