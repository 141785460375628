import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi, nodeCudApi } from '../../CommonAxios';


export const deleteAccount = createAsyncThunk("account/deleteAccount", async () => {
    try {
        const response = await nodeApi.post("/User_Signup_Flow_New/Account_In-Active");
        return response.data; 
    } catch (error) {
        throw error;
    }
});

const initialState = {
    loading: false,
    success: false,
    error: null
};

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        clearStatus: (state) => {
            state.success = false;
            state.error = null;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(deleteAccount.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteAccount.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(deleteAccount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export const { clearStatus } = accountSlice.actions;

export default accountSlice.reducer;




export const fetchDeleteaccount=async (formData)=>{
    return new Promise(async (resolve,reject)=>{
        const response=await nodeCudApi.post('/User_Signup_Flow_New/Account_In-Active',formData).then((res)=>{
            if(res.data){
                resolve({status:true,data:res.data?.results})
            }
            else{
                resolve({status:false,error:[]})
            }
        }).catch((err)=>{
            resolve({status:false,error:err})
        })
    })
}