import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    email: "",
    password: "",
    cpassword: ""
}

const formSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        addFormData(state, action) {
            return action.payload
        },
        updateFormData(state, action) {
            return { ...state, ...action.payload }
        },
        removeFormData(state, action) {
            return {
                email: "",
                password: "",
                cpassword: ""
            }
        }
    }
})

export const formActions = formSlice.actions

export default formSlice.reducer;