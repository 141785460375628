import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Checkbox, FormControlLabel, Radio, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { getCategoreyAction, getSubCategoriesAction, subToCat } from '../../../../store/slices/retail/home/ProductFiltersSlice';
import { AllProductsActions } from '../../../../store/slices/retail/home/AllProductsSlice';
import { useLocation } from 'react-router-dom';

const CategoreyFilter = () => {
    const dispatch = useDispatch();
    const category = useSelector(state => state.productFilter.category);
    const subcategory = useSelector(state => state.productFilter.subcategory);
    const [state, setState] = useState(null)
    const [Substate, setSubState] = useState(null)
    const pathname = useLocation().pathname

    const { filters: { Category_Id, Sub_Category_Id }, loading } = useSelector((state) => state.allProducts);


    useEffect(() => {
        dispatch(getCategoreyAction(
            {
                formData: { "Category_Type": pathname.includes("retail") ? "RETAIL" : "GROCERY" }, pagination: { PageNo: 1, PageSize: 1000, SortBy: "CATEGORY_NAME", SortOrder: "ASC" }
            }
        ));
        dispatch(getSubCategoriesAction({
            formData: {
                Business_Type: pathname.includes("retail") ? "RETAIL" : "GROCERY"
            },
            pagination: { PageNo: 1, PageSize: 10, SortBy: "SUB_CATEGORY_NAME", SortOrder: "ASC" }
        }));
    }, []);
    const handleChange = (selectedOption) => {

        if (selectedOption) {
            dispatch(AllProductsActions.updateFilter({ Category_Id: selectedOption, Sub_Category_Id: [] }))
            dispatch(getSubCategoriesAction({
                formData: {
                    Business_Type: pathname.includes("retail") ? "RETAIL" : "GROCERY",
                    "Categories": selectedOption.map((item) => item.id)
                },
                pagination: { PageNo: 1, PageSize: 1000, SortBy: "SUB_CATEGORY_NAME", SortOrder: "ASC" }
            }));
        }
        else {
            dispatch(AllProductsActions.updateFilter({ Category_Id: [], Sub_Category_Id: [] }))
        }

    }

    const subhandleChange = (selectedOption) => {
        if (Category_Id.length == 0) {
            dispatch(AllProductsActions.updateFilter({
                Category_Id: [{
                    id: selectedOption[0].cid, title: selectedOption[0].ctitle
                }], Sub_Category_Id: selectedOption
            }))
        } else
            dispatch(AllProductsActions.updateFilter({ Sub_Category_Id: selectedOption }))

    }

    const CATandSub = useMemo(() => {
        return (
            <>
                <Autocomplete
                    disablePortal
                    key={"CI"}
                    options={category || []}
                    multiple
                    limitTags={1}
                    sx={{
                        margin: "10px 0px",
                        "& fieldset": {
                            borderRadius: "12px",

                        },
                        '.MuiAutocomplete-inputRoot': {
                            flexWrap: 'nowrap !important', overflow: "hidden"
                        }
                    }}
                    isOptionEqualToValue={(option, value) => {

                        return option.title === value.title
                    }}
                    value={Category_Id}
                    getOptionLabel={(option) => option.title}
                    onChange={(event, newValue) => handleChange(newValue)}
                    renderInput={(params) => <TextField {...params} label={"Search Category"} InputLabelProps={{
                        style: {
                          fontWeight: 'bold'
                        }
                      }} />}
                    renderOption={(props, option) => (
                        <FormControlLabel
                        {...props}
                        sx={{ width: '98%' }}
                        control={<Checkbox checked={props["aria-selected"]} />}
                        label={
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {option.title}
                          </Typography>
                        }
                      />
                    )}
                />


                <Autocomplete
                    disablePortal
                    multiple
                    limitTags={1}
                    sx={{
                        margin: "10px 0px",
                        "& fieldset": {
                            borderRadius: "12px",

                        },
                        '.MuiAutocomplete-inputRoot': {
                            flexWrap: 'nowrap !important', overflow: "hidden"
                        },
                        
                    }}
                    options={subcategory || []}
                    isOptionEqualToValue={(option, value) => {
                        return option.title === value.title
                    }}
                    value={Sub_Category_Id}
                    getOptionLabel={(option) => option.title}
                    onChange={(event, newValue) => {
                        subhandleChange(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} label={"Search Sub Category"}InputLabelProps={{
                        style: {
                          fontWeight: 'bold'
                        }
                      }} 
                      
                      />}
                    renderOption={(props, option) => (
                     
                        <FormControlLabel
                        {...props}
                        sx={{ width: "98%" }}
                        control={<Checkbox checked={props["aria-selected"]} />}
                        label={
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {option.title}
                          </Typography>
                        }
                      />
                    )}
                />
            </>
        )
    }, [category, subcategory, Category_Id, Sub_Category_Id]);

    return (
        <Box sx={{ width: "100%", }}>
            {CATandSub}
        </Box>
    );
}

export default CategoreyFilter
