import React from "react";
import { CardActionArea, Typography, Box, Link, Skeleton } from "@mui/material";
import { useTheme } from "@emotion/react";

const CategoryCard = ({ label, image, onClick, isViewAll, path, sk = false }) => {
  const theme = useTheme();
  if (!sk)
    return (
      <CardActionArea
        sx={{
          height: { xs: "48px", sm: "58px" },
          justifyContent: { xs: (isViewAll ? "center" : "space-between"), sm: "center" },
          minWidth: { xs: "37%", sm: "unset" },
          width: { xs: "34%", sm: "162px" },
          boxShadow: "0px 9px 9px " + theme.palette.extra.cardShadowColorOne,
          border: "1px solid" + theme.palette.extra.categoryBorder,
          borderRadius: "100px",
          marginBottom: "5px",
          display: "flex",
          padding: isViewAll ? 0 : "10px",
          "&:hover": {
            borderColor: theme.palette.primary.main,
          },
        }}
        onClick={onClick}
      >
        {image && (
          <img
            src={image}
            alt="category"
            style={{
              width: "30px",
              height: "30px",
            }}
          />
        )}
        {!isViewAll && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: { xs: "auto", sm: "100%" },
              maxWidth: { xs: "70%", sm: "unset" },
              justifyContent: "center"
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: ".55rem", sm: "13px" },
                textAlign: "center",
                cursor: "pointer",
                textTransform: "uppercase",
                fontWeight: "bold",
                whiteSpace: "nowrap",
                alignSelf: "center",
                overflow: 'hidden'
              }}
            >
              {label}
            </Typography>
          </Box>
        )}
        <Link href={path} sx={{ textDecoration: "none" }}>
          {isViewAll && (
            <Typography
              sx={{
                height: '40px',
                width: '90px',
                marginTop: '22px',
                fontSize: { xs: ".6rem", sm: "13px" },
                textAlign: "center",
                cursor: "pointer",
                textTransform: "uppercase",
                fontWeight: "bold",
                color: theme.palette.mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.headerbutton.textcolorone
              }}
            >
              {label}
            </Typography>
          )}
        </Link>
      </CardActionArea>
    );
  else {
    return (
      <CardActionArea
        sx={{
          borderRadius: "100px",
          display: "flex",
          "&:hover": {
            borderColor: theme.palette.primary.main,
          },
          minWidth: { xs: "37%", sm: "unset" },
          width: { xs: "34%", sm: "162px" },
          height: { xs: "48px", sm: "58px" },
          overflow: "hidden"
        }}
      >
        <Skeleton
          sx={{ width: "100%", height: "100%", }}
          variant="rectangular"
        />
      </CardActionArea>
    );
  }
};

export default CategoryCard;
