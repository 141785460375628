import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { boomiApi, nodeApi, urlGenarator } from '../CommonAxios';


const initialState = {
    walletHistory: [],
    wallet: {},
    loading: false,
    error: "",
    Pagination: {}
}

export const fetchWalletHistory = createAsyncThunk("wallet/fetchWalletHistory", async (formData) => {
    const response = await nodeApi.post(urlGenarator("/User_Wallet/Wallet_History_Get", formData.Pagination), formData.formData)
    if (response.data.Success_Response.Is_Data_Exist === "0") {
        return { walletHistory: [], Pagination: { TotalPages: 0, PageNo: 1 } };
    } else {
        return response.data;
    }
})

// export const fetchWalletHistory = createAsyncThunk("wallet/fetchWalletHistory", async (formData) => {
//     const response = await nodeApi.post("/User_Wallet/Wallet_History_Get", formData);
//     const data = response?.data;
//     if (data.Success_Response.Is_Data_Exist === "0") {
//         return [];
//     } else {
//         return data.results || [];
//     }
// });

export const fetchWallet = createAsyncThunk("wallet/fetchWallet", async (formData) => {
    const response = await nodeApi.post("/User_Wallet/User_Wallet_Get", formData);
    const data = response?.data;
    if (data.Success_Response.Is_Data_Exist === "0") {
        return [];
    } else {
        return data.results[0] || [];
    }
});

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(fetchWalletHistory.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchWalletHistory.fulfilled, (state, action) => {
                state.loading = false;
                state.walletHistory = action.payload.results || [];
                state.Pagination = action.payload.Pagination || { TotalPages: 0, PageNo: 1 };
                state.error = "";
            })
            .addCase(fetchWalletHistory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchWallet.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchWallet.fulfilled, (state, action) => {
                state.loading = false;
                state.wallet = action.payload;
                state.error = "";
            })
            .addCase(fetchWallet.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export default walletSlice.reducer;

export const walletWithdrawlAction = (formData) => {
    return new Promise(async (resolve, reject) => {
        await boomiApi.post('/User_Wallet/User_Withdraw_Request', formData).then(response => {
            if (response.data.Response_Status === 'Success') {
                resolve({ status: true, data: response.data })
            }
            else {
                resolve({ status: false, data: response.data })
            }

        })
            .catch(error => {

                resolve({ status: false, error: error.message })

            })

    })
}