import { useTheme } from '@emotion/react'
import { Button, CardMedia, Typography, FormHelperText, Rating, IconButton, TextField, Skeleton, } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import useScreenSize from '../../../utils/ScreenWidthHight'
import { useLocation, useNavigate } from 'react-router-dom'
import crossdark from '../../../assests/images/closeIcon.png'
import crosswhite from '../../../assests/images/crosslight.png'
import { useFormik } from 'formik'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as Yup from 'yup'
import { uploadProfilePicAction } from '../../../store/slices/Profile/Profile'
import { uriToBlob } from '../../../utils/uriBlob'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import {
  imageDeleteProductReviewByProductId, productCreateReviewExpression, productGetReviewExpression
  , colorVariantByProductId, imagesGetByProductId, partnersFromProductSize, productDetailByProductId, sizeGetByProductId,
  catergoryNameGetFromProductID,
  recentlyVisitedProducts,
  productReviewImagesGet

} from '../../../store/slices/retail/ProductDetails/ProductDetailsActions'
import { useSelector } from 'react-redux'
import { GlobalContext } from '../../../Context'
import { isEmpty } from '../../../utils'

const ProductDetails = ({ id, setProductSize, productSize, setPartnerDetails, setSelectedChartSize, setProductId, setValue, setNotFoundStatus }) => {
  const theme = useTheme()
  const auth = useSelector(state => state.auth)
  const navigate = useNavigate()
  const pathname = useLocation().pathname
  const dollarIcon = require('../../../assests/images/moneyBag.png')
  const [productImages, setProductImages] = useState([])
  const [productColors, setProductColors] = useState([])
  const [productDetails, setProductDetails] = useState([])
  const [categoryName, setCategoryName] = useState('')
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedChart, setSelectedChart] = useState(0);
  const [loading, setLoading] = useState(false)
  const [prodLoaing, setProdLoading] = useState(true)
  const [isRetail, setIsRetail] = useState(pathname.includes("retail"))
  const winsizes = useScreenSize()
  const imageRef = useRef()

  useEffect(() => {
    if (Boolean(productDetails[0]?.Product_Id) && Boolean(productSize[selectedChart]?.productSize)) {
      partnersFromProductSizeCall({ Product_Id: productDetails[0]?.Product_Id, Prodcut_Size: productSize[selectedChart]?.productSize })
    } else {
      setPartnerDetails([])
    }
    setProductId(productDetails[0]?.Product_Id)
  }, [selectedChart, productDetails, productSize, id])

  useEffect(() => {
    try {
      if (productImages.length > 1) {
        const imageStyles = imageRef.current?.style;
        imageStyles.transitionDuration = '1s';
        const imageSetTime = setTimeout(() => {
          setSelectedImage(state => (state === productImages.length - 1 ? 0 : state + 1));
          imageStyles.transform = 'scale(103%)';
        }, 3000);

        setTimeout(() => {
          imageStyles.transform = 'scale(100%)';
        }, 2000); // Adjust the timing as needed

        return () => clearTimeout(imageSetTime);
      }
    }
    catch {
      
    }
  }, [selectedImage, productImages]);

  const refresh = (id) => {
    if (!isEmpty(id)) {
      imagesGetByProductIdCall({ Product_Id: id })
      colorVariantByProductIdCall({ Product_Id: id })
      sizeGetByProductIdCall({ Product_Id: id })
      productDetailByProductIdCall({ Product_Id: [id] })
      catergoryNameGetFromProductIDCall({ Product_Id: id })
    }
  }


  useEffect(() => {
    if (!isEmpty(id))
      refresh(id)
  }, [id, auth,])

  const handleSelectedChartSize = (size) => {
    setSelectedChart(size)
  }

  const partnersFromProductSizeCall = async (formData) => {
    const response = await partnersFromProductSize(formData)
    if (response.status) {
      setSelectedChartSize(productSize[selectedChart])
      setPartnerDetails(response.data.map((PartnerDetail, index) => ({ image: PartnerDetail.Logo_Path, partnerName: PartnerDetail.Parnter_Name, partnerId: PartnerDetail.Partner_Details_Id, partnerSellingPrice: PartnerDetail.Partner_Selling_Price, Product_Id: PartnerDetail.Product_Id, Partner_Product_Id: PartnerDetail.Partner_Product_Id, Partner_Details_Id: PartnerDetail.Partner_Details_Id, Category_Name: PartnerDetail.Category_Name, Warranty_Period: PartnerDetail.Warranty_Period, Availability_Stock: PartnerDetail?.Availability_Stock })))
    }
    else {
      setPartnerDetails([])
    }
  }



  const imagesGetByProductIdCall = async (formData) => {
    setLoading(true)
    const response = await imagesGetByProductId(formData)
    if (response.status) {
      setProductImages(response.data.Product_Image_Id.map((ProductImage, index) => ({ image: ProductImage.Product_Image, index: index })))
      setLoading(false)
    }
    else {
      setProductImages([])
      setLoading(false)
    }
  }
  const colorVariantByProductIdCall = async (formData) => {
    const response = await colorVariantByProductId(formData)
    if (response.status) {
      setProductColors(response.data.map((ProductColor, index) => ({ title: ProductColor.Color, image: ProductColor.Product_Image, productId: ProductColor.Product_Id, index: index, default: ProductColor.Product_Id === formData.Product_Id })))
    }
    else {
      setProductColors([])
    }
  }
  const sizeGetByProductIdCall = async (formData) => {
    const response = await sizeGetByProductId(formData)
    if (response.status) {
      setProductSize(response.data?.map((ProductSiz, index) => ({ productSize: ProductSiz.Product_Size, productAvailableStock: ProductSiz.Availability_Stock, index: index, Size_Measurement: ProductSiz.Size_Measurement, Size_Type: ProductSiz.Size_Type })))
    }
    else {
      setProductSize([])
    }
  }
  const productDetailByProductIdCall = async (formData) => {
    setProdLoading(true)
    const response = await productDetailByProductId(formData)
    if (response.status) {
      const data = response.data
      if (data.length > 0) {
        setProductDetails(data.map((ProductDetail, index) => ({
          Brand_Id: ProductDetail.Brand_Id,
          Category_Id: ProductDetail.Category_Id,
          Brand_Name: ProductDetail.Brand_Name,
          Product_Name: ProductDetail.Product_Name,
          Product_Short_Description: ProductDetail.Product_Short_Description,
          Specifications: ProductDetail.Specifications,
          Partner_Name: ProductDetail.Partner_Name,
          CashBack_Product_Price: ProductDetail.CashBack_Product_Price,
          Features: ProductDetail.Features,
          Product_Description: ProductDetail.Product_Description,
          Partner_Price: ProductDetail.Partner_Price,
          Original_Price: ProductDetail.Original_Price,
          Partner_Selling_Price: ProductDetail.Partner_Selling_Price,
          Cashback_Label: ProductDetail.Cashback_Label,
          Discount_Label: ProductDetail.Discount_Label,
          Discount: ProductDetail.Discount,
          Product_Id: ProductDetail.Product_Id,
          Partner_Product_Id: ProductDetail.Partner_Product_Id,
          Esi_Rating: ProductDetail.Product_Avg_Rating,
          Review_Count: ProductDetail.Review_Count
        })))
        setProdLoading(false)
        if (auth.isAuthenticated)
          await recentlyVisitedProducts({ User_Email_Id: auth?.user?.Email_Id, Offer_Type: "Product", Offer_Id: response.data[0].Product_Id, Store_Type: response.data[0].Partner_Type, Store_Id: response.data[0].Partner_Details_Id })
      }
      else {
        navigate('/')
      }
    }
    else {
      setProdLoading(false)
      setProductDetails([])
      setNotFoundStatus(true)
    }
  }
  const catergoryNameGetFromProductIDCall = async (formData) => {
    const response = await catergoryNameGetFromProductID(formData)
    if (response.status) {
      setCategoryName(response.data)
    }
  }




  const ImageGroupMemo = useMemo(() => {
    const handleImageClick = (index) => {
      setSelectedImage(index);
    };
    return (
      <Box sx={{
        width: "100%", display: "flex", flexDirection: "row", justifyContent: { md: "center", xs: "center", sm: "center" }, gap: 1, overflowX: "scroll", '&::-webkit-scrollbar': {
          display: 'none'
        },
      }}>
        {productImages?.map((productImage, index) => (
          <ImageCardMedia
            key={index}
            isActive={selectedImage === index}
            image={productImage?.image}
            onClick={() => handleImageClick(index)}
          />
        ))}
      </Box>
    );
  }, [selectedImage, productImages])

  const productDetailsMemo = useMemo(() => {

    return (
      <Box sx={{ width: { md: "80%", xs: "98%" }, display: "flex", flexDirection: "column", gap: "30px", justifyContent: "space-around" }} >
        {prodLoaing ? <Skeleton variant='text' /> : <Box sx={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "flex-end" }} ><Typography sx={{ color: theme.palette.text.primary, fontWeight: "400" }} >Brand: </Typography> <Typography sx={{ fontWeight: "bold", color: theme.palette.text.primary, }}>  {productDetails[0]?.Brand_Name} </Typography> </Box>}
        {prodLoaing ? <Skeleton variant='text' /> : <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold" }} >{productDetails[0]?.Product_Name}</Typography>}
        {prodLoaing ? <Skeleton variant='text' /> : <Typography sx={{ color: theme.palette.text.secondary, }} >{productDetails[0]?.Product_Short_Description}</Typography>}
        {prodLoaing ? <Skeleton variant='text' /> : <Box sx={{ display: "flex", flexDirection: "row", gap: {xs:"4px",sm:"8px"}, alignItems: "flex-end" }} ><Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }} >Default Seller: </Typography> <Typography sx={{ fontWeight: "bold", color: theme.palette.text.primary, }} >{productDetails[0]?.Partner_Name}</Typography> </Box>}
        {prodLoaing ? <Skeleton variant='rectangular' sx={{ width: "200px", height: "30px" }} /> :
          (productDetails[0]?.CashBack_Product_Price > 0 && <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "flex-start", gap: "1px", alignItems: "center", }} >
            <CardMedia image={dollarIcon} sx={{ width: "28px", height: "28px" }} />

            <Typography sx={{ color: "green" }} >{productDetails[0]?.Cashback_Label} ₹ {productDetails[0]?.CashBack_Product_Price} Cashback</Typography>
          </Box>)
        }
        {/* : null} */}
        {prodLoaing ? <Skeleton variant='text' /> : <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", columnGap: "5%" }} >
          <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold" }} >₹{productDetails[0]?.Partner_Selling_Price}</Typography>
          <Typography sx={{ color: theme.palette.text.secondary }} >M.R.P: {productDetails[0]?.Original_Price}</Typography>
          <Typography sx={{ color: "green", width: "40%" }} >{productDetails[0]?.Discount_Label} {productDetails[0]?.Discount}% </Typography>

        </Box>}

        {prodLoaing ? (
          <Skeleton variant='text' />
        ) : (
          <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "8px" }}>
            <Rating
              value={productDetails[0]?.Esi_Rating ? parseFloat(productDetails[0]?.Esi_Rating?.toFixed(1)) : 0}
              readOnly
              precision={0.1}
            />
            <Typography
              sx={{ color: theme.palette.text.primary, fontWeight: "bold" }}
            >
              {(productDetails[0]?.Esi_Rating ? productDetails[0]?.Esi_Rating?.toFixed(1) : 0)} Ratings and {(productDetails[0]?.Review_Count || 0)} reviews
            </Typography>
          </Box>
        )}

        {prodLoaing ? <Skeleton variant='rectangular' sx={{ width: "200px", height: "30px" }} /> : productColors.length > 0 && (<Box sx={{ width: "100%", display: 'flex', flexDirection: "column", gap: "2px" }} >
          <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold" }} >{isRetail ? "Colors" : "Product Variant"}</Typography>
          <ColorCharts productColors={productColors} refresh={refresh} />
        </Box>)}
        {prodLoaing ? <Skeleton variant='rectangular' sx={{ width: "200px", height: "30px" }} /> : (productSize?.length > 0 && < Box sx={{ width: "100%", display: 'flex', flexDirection: "column", gap: "2px" }} >
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
            <Typography sx={{ color: theme.palette.text.primary }} >Select Variant</Typography>
            {["Men's Fashion", "Women's Fashion", "Kids's Fashion"].includes(categoryName) ? <Typography sx={{ color: theme.palette.input.borderColor, cursor: "poinetr" }} onClick={() => { window.scrollTo(0, 1280); setValue(7) }} >Size Chart</Typography> : null}
          </Box>
          <SizeCharts productSize={productSize} selectedChart={selectedChart} setSelectedChart={setSelectedChart} handleSelectedChartSize={handleSelectedChartSize} />
        </Box>)
        }

      </Box >
    )
  }, [productColors, productSize, selectedChart, productDetails, categoryName,])


  return (
    <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-around", paddingTop: 3 }} >
      <Box sx={{ width: { xs: "100%", sm: "100%", md: "30%" }, height: "100%", alignItems: "center", justifyContent: "center" }} >
        <Box sx={{ width: "100%", minHeight: "50vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 3 }} >
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            {loading ?
              <Skeleton
                variant='rectangular'
                sx={{ width: "100%", minHeight: "600px", }}
              />
              :
              <img
                style={{ width: "100%", minHeight: "297px", maxHeight: winsizes.screenSize.width < 900 ? winsizes.getThreeQuarterScreenSize.height : winsizes.getHalfScreenSize.height, maxWidth: winsizes.screenSize.width < 900 ? winsizes.getThreeQuarterScreenSize.width : "auto", objectFit: "contain", }} //<500 ? 400 **
                src={productImages[selectedImage]?.image}
                ref={imageRef}
              />}
          </Box>
          {ImageGroupMemo}
        </Box>
      </Box>
      <Box sx={{ width: { xs: "90%", sm: "90%", md: "50%" }, maxHeight: { xs: (100 * winsizes.screenSize.height) / 100, md: "100%" }, justifyContent: "flex-start", alignItems: { xs: "center", sm: "center", md: "flex-start" }, display: "flex", color: theme.palette.input.borderColor, flexDirection: "column", gap: "10px", "::-webkit-scrollbar ": { display: "none" } }} >
        {productDetailsMemo}
      </Box>
    </Box >
  )
}

export default ProductDetails

export const ProductReviewDetails = ({
  productYourReview,
  Email,
  productDetails,
  editStatus,
  updateImageReview,
  addReview,
  updateReview,
  setReviewError,
  reviewState,
  setReviewState,
  fileHandleRef,
  reviewError,
  productReviewDetails,
  reviewCurrentPage,
  reviewTotalPages,
  setReviewCurrentPage,
  Product_Id,
  reviewLoading
}) => {
  // console.log({productYourReview,
  //   Email,
  //   productDetails,
  //   editStatus,
  //   updateImageReview,
  //   addReview,
  //   updateReview,
  //   setReviewError,
  //   reviewState,
  //   setReviewState,
  //   fileHandleRef,
  //   reviewError,
  //   productReviewDetails,
  //   reviewCurrentPage,
  //   reviewTotalPages,
  //   setReviewCurrentPage,
  //   Product_Id,
  //   reviewLoading})
  const [reviewImage, setReviewImages] = useState([])
  const [reviewEditImage, setEditReviewImages] = useState([])
  const [reviewImageLoading, setReviewImageLoadind] = useState(false)
  const winsizes = useScreenSize()
  const theme = useTheme()
  const auth = useSelector(state => state.auth)
  const validationSchema = Yup.object().shape({
    rating: Yup.number().required('Please Provide Rating'),
    comment: Yup.string().required('Please Provide Comment')
  })
  const { toolTipActive } = useContext(GlobalContext)
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      comment: productYourReview[0]?.Comments || "",
      rating: productYourReview[0]?.Rating || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let imagesArray = []
      for (let i = 0; i < reviewImage.length; i++) {
        if (!(reviewImage[i]?.Product_Review_Image_Id))
          imagesArray.push(reviewImage[i].Image)
      }
      const payload = {
        Comments: values.comment,
        Email_Id: Email,
        Product_Id: Product_Id,
        Rating: values.rating,
        Image: reviewEditImage,
      }
      const updatePayload = {
        Comments: values.comment,
        Email_Id: Email,
        Product_Id: productDetails[0]?.Product_Id,
        Rating: values.rating,
        Product_Review_Id: productYourReview[0]?.Product_Review_Id
      }
      const updateImagePayload = {
        Email_Id: Email,
        Product_Review_Id: productYourReview[0]?.Product_Review_Id,
        Image: imagesArray
      }
      if (values.rating > 0) {
        if (editStatus) {
          if (reviewImage.length > 0) {
            updateImageReview(updateImagePayload)
            setReviewImages([])
          }

          updateReview(updatePayload)

        }
        else {
          addReview(payload)
          // productReviewImagesGetCall({Product_Review_Id:productYourReview[0].Product_Review_Id})
        }
      }
      else {
        formik.setFieldError("rating", "Please Provide Rating")
      }
    }
  })

  const fetchImageFromUri = async (uri) => {
    const blob = await uriToBlob(uri);
    return blob;
  };

  const handlePictureChange = async (event) => {
    const file = event.target.files;
    const fileArray = Object.values(file);
    if (fileArray.length < (5 - reviewImage?.length)) {
      setReviewError('')
      setReviewImageLoadind(true)
      if (fileArray) {
        fileArray.map((file, index) => {
          setTimeout(() => {
            handleImage({ uri: URL.createObjectURL(file) })
          }, 1500)
        })
      }

    }
    else {
      setReviewError("Max Limit Reached")
    }
    setReviewImageLoadind(false)
  };

  const handleImage = async (result) => {
    try {
      const img = await fetchImageFromUri(result.uri);

      let localUri = result.uri;
      let uriParts = result.uri.split('.');
      let fileExtension = uriParts[uriParts.length - 1].toLowerCase().split(';');
      let filformate = fileExtension[0].split(':')
      let imgExtension = img.type.split('/')[1]
      let fileData = {
        fileName: localUri.split('/').pop(),
        ext: imgExtension,
      };
      setReviewImageLoadind(true)
      const response = await uploadProfilePicAction(img, fileData)
      if (response.status) {
        setEditReviewImages(prevState => ([...prevState, response.data.File_URL]));
        setReviewImages(prevState => ([...prevState, { Image: response.data.File_URL }]));
        setReviewImageLoadind(false)
      }
      else {
        setReviewImageLoadind(false)
      }

    } catch (error) {
      setReviewImageLoadind(false)
    }
  };

  const productReviewImagesGetCall = async (formData, Pagination) => {
    const response = await productReviewImagesGet(formData, Pagination)
    if (response.status) {
      setReviewImages(response.data)
    } else {
      setReviewImages([])
    }
  }

  useEffect(() => {
    if (!reviewLoading) {
      if (productYourReview.length > 0) {
        productReviewImagesGetCall({ Product_Review_Id: productYourReview[0].Product_Review_Id })
        formik?.setFieldValue('comment', productYourReview[0]?.Comments)
        formik?.setFieldValue('rating', productYourReview[0]?.Rating)
      }
      else {
        setReviewImages([])
        formik?.setFieldValue('comment', '')
        formik?.setFieldValue('rating', 0)
      }
    }
  }, [productYourReview, reviewLoading])

  return (
    <Box sx={{ width: "90%", height: "100%", display: { xs: (reviewState ? "flex" : "unset"), md: "flex" }, flexDirection: { md: "row", sm: "row", xs: "column" }, gap: { xs: "4%", md: 2 }, overflow: "scroll", justifyContent: { xs: (reviewState ? "space-between" : "unset"), md: "space-between" } }} >
      {(auth.isAuthenticated && ((!reviewState && winsizes.screenSize.width < 900) || (winsizes.screenSize.width > 900)) && !reviewLoading) ?
        <Box sx={{ width: { md: "40%", xs: '100%', sm: "40%" }, height: "50%" }} >
          {!reviewState || winsizes.screenSize.width > 900 ?
            <Box sx={{ width: { xs: "100%", md: "73%" }, display: "flex", flexDirection: "column", alignItems: "center", gap: { xs: 1, md: 1 } }} >
              <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
                <Typography sx={{ color: theme.palette.text.secondary }} >{editStatus ? "Your" : "Add"} Review</Typography>
                {winsizes.screenSize.width < 900 ?
                  <IconButton onClick={() => {
                    setReviewState(!reviewState)
                  }} >
                    <CardMedia
                      image={theme.palette.mode === "dark" ? crosswhite : crossdark}
                      sx={{ width: "24px", height: "24px" }}
                    />
                  </IconButton> : null}
              </Box>
              <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }} >
                <Box sx={{ width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }} >
                  <TextField label="comment" name='comment' sx={{ width: "100%" }} color={(formik.errors.comment?.length > 0 && formik.touched.comment) ? "error" : "primary"} value={formik.values.comment} onChange={(e) => {
                    const value = e.currentTarget.value
                    if (value.length === 1) {
                      formik.setFieldValue("comment", e.currentTarget.value.trim())
                    }
                    else {
                      formik.setFieldValue("comment", e.currentTarget.value)
                    }
                  }} onBlur={formik.handleBlur} />
                  {formik.touched.comment && formik.errors.comment && (
                    <FormHelperText sx={{ color: theme.palette.error.main }} >{formik.errors.comment}</FormHelperText>
                  )}
                </Box>
                <Button
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    fileHandleRef.current.click()
                  }}
                  disabled={reviewImage?.length >= 4 ? true : false}
                >
                </Button>
                <input type='file' accept='image/*' multiple={true} minLength={4 - reviewImage?.length || 0} onChange={handlePictureChange} ref={fileHandleRef} hidden />
              </Box>
              <Box>
                <Rating name='rating' value={parseFloat(formik.values.rating)} onChange={(e, value) => { formik.setFieldValue('rating', value?.toString() || 0) }} precision={.5} />
                {formik.touched.rating && formik.errors.rating && (
                  <FormHelperText sx={{ color: theme.palette.error.main }} >{formik.errors.rating}</FormHelperText>
                )}
                {reviewError && (
                  <FormHelperText sx={{ color: theme.palette.error.main }} >{reviewError}</FormHelperText>
                )}
              </Box>
              <Box sx={{ width: "100%", display: "flex", flexDirection: "row-reverse", justifyContent: "center", alignItems: "center", gap: 1, }} >
                {reviewImage?.length > 0 ?
                  reviewImage.slice(0, 4).map((image, index) => {
                    return (
                      <Box sx={{ position: "relative" }} key={index} >
                        <CardMedia
                          key={index}
                          image={image.Image}
                          sx={{ width: "50px", height: "50px", }}

                        />
                        <IconButton sx={{ position: "absolute", top: "0px", right: "0px", padding: "0px" }}

                          onClick={async () => {
                            if (image?.Product_Review_Image_Id) {
                              const payload = { Product_Review_Image_Id: image.Product_Review_Image_Id }
                              const response = await imageDeleteProductReviewByProductId(payload)
                              if (response.status) {
                                setReviewImages(reviewImage.filter(value => value.Product_Review_Image_Id !== image.Product_Review_Image_Id))
                                toolTipActive("success", 'Image Removed');
                              }

                            }
                            else {
                              setReviewImages(reviewImage.filter(value => value.Image !== image.Image))
                              setEditReviewImages(reviewEditImage.filter(value => value !== image.Image))
                            }
                          }}

                        >
                          <CardMedia
                            image={theme.palette.dark === "dark" ? crosswhite : crossdark}
                            sx={{ width: "15px", height: "15px" }}
                          />
                        </IconButton>
                      </Box>
                    )
                  })

                  : null}

              </Box>
              <Button variant='outlined' sx={{ width: "min-content", alignSelf: "center", borderColor: theme.palette.input.borderColor, color: theme.palette.input.borderColor }} onClick={formik.handleSubmit} disabled={reviewImageLoading} >Submit</Button>
            </Box>
            : null}
        </Box> : null}
      {!reviewLoading && <Box sx={{ width: { md: auth.isAuthenticated ? "60%" : "100%", sm: "60%", xs: "100%" }, maxHeight: { xs: "100%", md: auth.isAuthenticated ? "400px" : "100%" }, display: "flex", flexDirection: "column", alignItems: { xs: "center", md: "flex-start" }, justifyContent: { xs: "flex-start", md: "space-around" }, gap: { xs: 2, md: 5 }, paddingTop: 2, overflowY: "scroll", "::-webkit-scrollbar ": { display: "none" } }} >
        {(reviewState || winsizes.screenSize.width < 900) && auth.isAuthenticated && reviewState ? <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }} >
          {winsizes.screenSize.width < 900 ? <Button variant='contained' onClick={() => {
            setReviewState(!reviewState)
          }} >{editStatus ? "Your" : "Add"} Review</Button> : null}
        </Box> : null}
        {(productReviewDetails?.length > 0 && !reviewLoading) &&
          <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between", rowGap: "3vh" }} >
            {[...productReviewDetails].map((Review, index) => {
              // if (Review.Email_Id !== Email)
              return (
                <ProductDetailReviewCard Review={Review} key={Review.Product_Review_Id} />
              )
            })
            }
          </Box>
        }
        {(productReviewDetails?.length === 0 && productYourReview?.length === 0 && !reviewLoading) &&
          <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }} >
            <Typography variant='h3' >Be The First Review</Typography>
            {!auth?.isAuthenticated && <Button onClick={()=>{navigate('/auth')}} >Login</Button>}
          </Box>

        }
        {!reviewLoading && <Box sx={{ width: auth.isAuthenticated ? "75%" : "100%", display: "flex", justifyContent: "center", alignItems: "center" }} >
          {(reviewCurrentPage !== reviewTotalPages) && <Button variant='contained' onClick={() => {
            setReviewCurrentPage(state => (state + 1))
          }} >Load more</Button>}
        </Box>}
      </Box>}
      {reviewLoading &&
        <Box sx={{ width: "100%", height: "20vh", display: "flex", flexWrap: "wrap", justifyContent: "space-around", rowGap: "10px" }}>
          {
            Array(10).fill(1).map((e, i) => {
              return (
                <Skeleton
                  sx={{
                    width: "28%",
                    height: "150px",
                    borderRadius: "6px"
                  }}
                  variant='rectangular'

                />
              )
            })
          }
        </Box>
      }
    </Box>
  )
}

const ProductDetailReviewCard = ({ Review }) => {
  const auth = useSelector(state => state.auth)
  const [like, setLike] = useState(0)
  const [dislike, setdisLike] = useState(0)
  const [expression, setExpression] = useState("")
  const navigate = useNavigate()
  const theme = useTheme()
  const [reviewImages, setReviewImages] = useState([])
  useEffect(() => {
    productGetReviewExpressionCall({ Email_Id: auth?.user?.Email_Id, Product_Review_Id: Review.Product_Review_Id })
    productReviewImagesGetCall({ Product_Review_Id: Review.Product_Review_Id })
  }, [])

  const productGetReviewExpressionCall = async (formData) => {
    const response = await productGetReviewExpression(formData)
    if (response.status) {
      setLike(response.data.Like_Count)
      setdisLike(response.data.Dislike_Count)
      setExpression(response.data.User_Expression)
    }
  }

  const productCreateReviewExpressionCall = async (formData, Expression_Type) => {
    const response = await productCreateReviewExpression(formData)
    if (response.status) {
      setExpression(Expression_Type)
      productGetReviewExpressionCall({ Email_Id: auth?.user?.Email_Id, Product_Review_Id: Review.Product_Review_Id })
    }
  }
  const productReviewImagesGetCall = async (formData, Pagination) => {
    const response = await productReviewImagesGet(formData, Pagination)
    if (response.status) {
      setReviewImages(response.data)
    } else {
      setReviewImages([])
    }
  }





  return (
    <Box sx={{ width: { xs: "90%", md: "40%" }, display: "flex", flexWrap: "wrap", justifyContent: "center", gap: 3, border: `1px solid ${theme.palette.input.borderColor}`, padding: 2, borderRadius: 5 }} >
      <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }} >
        <img
          src={Review.Profile_Pic_Path || require('../../../assests/images/profileIcon.png')}
          style={{ width: "48px", height: "48px", borderRadius: "100px", objectFit: "contain" }}
        />
      </Box>
      <Box sx={{ width: "60%", display: "flex", flexDirection: "column", gap: 2, alignItems: "flex-start", justifyContent: "flex-start" }}  >
        <Box sx={{ width: "100%", display: "flex", flexWrap: { xs: "nowrap", md: "wrap" }, flexDirection: { xs: "column", md: "unset" }, justifyContent: "space-between" }} >
          <Typography sx={{ color: theme.palette.text.primary }} >{Review.Full_Name}</Typography>
          <Rating value={Review.Rating} readOnly precision={.5} />
        </Box>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", overflowX: "scroll", alignItems: "center", gap: 1, }} >
          {reviewImages.length > 0 ?
            reviewImages?.slice(0, 4).map((image, index) => {
              if (image.Image !== "")
                return (
                  <CardMedia
                    key={index}
                    image={image.Image}
                    sx={{ width: "50px", height: "50px", }}
                  />
                )
            })

            : null}
        </Box>
        <Typography sx={{ color: theme.palette.text.secondary }} >{Review.Comments}</Typography>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }} >
          <Box sx={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center" }} >
            {expression === "Like" ?
              <IconButton onClick={() => {
                if (auth.isAuthenticated)
                  productCreateReviewExpressionCall({ Product_Review_Id: Review.Product_Review_Id, Email_Id: auth?.user?.Email_Id, Expression_Type: "Like" }, "")
                else
                  navigate('/auth/signin')
              }} >
                <ThumbUpAltIcon />
              </IconButton>
              : <IconButton

                onClick={() => {
                  if (auth.isAuthenticated)
                    productCreateReviewExpressionCall({ Product_Review_Id: Review.Product_Review_Id, Email_Id: auth?.user?.Email_Id, Expression_Type: "Like" }, "Like")
                  else
                    navigate('/auth/signin')
                }}

              >
                <ThumbUpOffAltIcon />
              </IconButton>}
            <Typography>{like}</Typography>
          </Box>
          <Box sx={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center" }} >
            {
              expression === "DisLike" ?
                <IconButton

                  onClick={() => {
                    if (auth.isAuthenticated)
                      productCreateReviewExpressionCall({ Product_Review_Id: Review.Product_Review_Id, Email_Id: auth?.user?.Email_Id, Expression_Type: "DisLike" }, "")
                    else
                      navigate('/auth/signin')
                  }}

                >
                  <ThumbDownAltIcon />
                </IconButton>
                :
                <IconButton

                  onClick={() => {
                    if (auth.isAuthenticated)
                      productCreateReviewExpressionCall({ Product_Review_Id: Review.Product_Review_Id, Email_Id: auth?.user?.Email_Id, Expression_Type: "DisLike" }, "DisLike")
                    else
                      navigate('/auth/signin')
                  }}

                >
                  <ThumbDownOffAltIcon />
                </IconButton>}
            <Typography>{dislike}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}


const SizeCharts = ({ productSize, selectedChart, setSelectedChart, handleSelectedChartSize }) => {
  const theme = useTheme()

  const handleChartClick = (title) => {
    setSelectedChart(title);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "row" },
        gap: 1,
        alignItems: { xs: "flex-start", md: "flex-start" },
        overflowY: { xs: "scroll", sm: "hidden", md: "hidden" }
      }}
    >
      {productSize.map((productSz, index) => (
        <SizesButton
          key={index}
          isActive={selectedChart === productSz.index}
          title={productSz.productSize}
          onClick={() => {
            handleSelectedChartSize(productSz.index)
            handleChartClick(productSz.index)
          }}
          ext={productSz.Size_Type}
        />
      ))}
    </Box>
  );
};

const SizesButton = ({ title, onClick, isActive, ext }) => {
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex', flexDirection: "column", alignItems: "center" }} >
      <Button
        onClick={onClick}
        sx={{
          minWidth: "48px",
          height: "48px",
          borderRadius: 3,
          alignSelf: "center",
          border: `1px solid ${isActive ? theme.palette.input.borderColor : "none"}`,
          display: "flex",
          flexDirection: "column"
        }}
      >
        {title.toUpperCase()}
        <Typography sx={{ fontSize: ".6rem" }} >{ext?.substring(0, ext?.indexOf(" ")) || ext}</Typography>
      </Button>
    </Box>
  )
}


const ColorCharts = ({ productColors, refresh }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const pathname = useLocation().pathname
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "row" },
        gap: 1,
        alignItems: { xs: "flex-start", md: "flex-start" },
        overflowY: { xs: "scroll", md: "hidden" },
        '&::-webkit-scrollbar': {
          display: 'none'
        },
      }}
    >
      {productColors.map((productColor, index) => (
        <ColorImageCard
          key={productColor.productId + productColor.default}
          isActive={productColor.default}
          title={productColor.title}
          image={productColor.image}
          onClick={() => {
            navigate(pathname.includes("retail") ? '/retail/products/' + productColor.productId : "/grocery/products/" + productColor.productId)
            refresh(productColor.productId)

          }}
        />
      ))}
    </Box>
  );
}

const ColorImageCard = ({ title, onClick, isActive, image }) => {
  const theme = useTheme()

  return (
    <IconButton onClick={onClick}
      sx={{ padding: 0 }}
    >
      <img
        src={image}
        loading='eager'
        style={{
          minWidth: "48px",
          maxWidth: "58px",
          maxHeight: "58px",
          borderRadius: "10px",
          alignSelf: "flex-start",
          border: `2px solid ${isActive ? theme.palette.input.borderColor : "none"}`,
          backgroundColor: title,

        }}
      />
    </IconButton>
  )
}
const ImageCardMedia = ({ image, onClick, isActive }) => {
  const theme = useTheme()

  return (
    <img
      onClick={onClick}
      style={{ width: "67px", height: "87px", border: isActive ? `2px solid ${theme.palette.input.borderColor}` : "none", borderRadius: "10px", objectFit: "fill", cursor: "pointer" }}
      src={image}

    />
  );
}