import * as React from "react";
import Box from "@mui/material/Box";
import { TextField, Container, Typography, Link, Button, Grid, LinearProgress } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { getUserDetailsAction, signInAction, signInOTPSent, authActions } from "../../store/slices/AuthSlice";
import { useTheme } from "@emotion/react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { GlobalContext } from "../../Context";
import useScreenSize from "../../utils/ScreenWidthHight";



const SignInOTP = () => {

  const winSize = useScreenSize();
  const theme = useTheme();
  const [secondsOTP, setSecondsOTP] = React.useState(-1);
  const [error, setError] = React.useState(null);
  const formikRef = React.useRef();
  const [otpMobileNumberError, setOtpMobileNumberError] = React.useState([]);
  const [otpMobileNumber, setOtpMobileNumber] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [isValidMobileNumber, setIsValidMobileNumber] = React.useState(false);
  const [location, setLocation] = React.useState();
  const [deviceModelName, setDeviceModelName] = React.useState();
  const [seconds, setSeconds] = React.useState(-1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toolTipActive } = React.useContext(GlobalContext);


  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  React.useEffect(() => {
    let timer;
    if (secondsOTP > 0) {
      timer = setTimeout(() => {
        setSecondsOTP((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [secondsOTP]);



  React.useEffect(() => {
    // Check if Geolocation is supported by the browser
    const userAgent = navigator.userAgent;

    let browserName;
    if (userAgent.indexOf('Chrome') > -1) {
      browserName = 'Google Chrome';
    } else if (userAgent.indexOf('Firefox') > -1) {
      browserName = 'Mozilla Firefox';
    } else if (userAgent.indexOf('Edge') > -1) {
      browserName = 'Microsoft Edge';
    } else {
      browserName = 'Unknown Browser';
    }
    setDeviceModelName(browserName);
    if (!navigator.geolocation) {
      setError('Geolocation is not supported by your browser');
      return;
    }

    // Get the user's current position
    navigator.geolocation.getCurrentPosition(
      position => {
        const { coords: { latitude, longitude } } = position
        setLocation({ latitude, longitude });
        axios.get(`https://reverse-geocode.meepaisa.com/reverse?format=json&lat=${latitude}&lon=${longitude}`).then(async (response) => {
          let locationInfo = response.data.display_name;
          setLocation({ latitude, longitude, locationInfo });
        }).catch(async (reason) => {
        });
      },
      error => {
        setError('Unable to retrieve your location');
      }
    );
  }, []);



  const onMobileFieldOnBlur = async (validation = false) => {
    if (!(formikRef.current?.errors.Mobile) && (otpMobileNumber != formikRef.current?.values.Mobile || validation)) {
      setOtpMobileNumber(formikRef.current?.values.Mobile);
      setLoading(true);
      const res = await signInOTPSent(formikRef.current?.values.Mobile);
      if (res.status) {
        setIsValidMobileNumber(true);
        setSecondsOTP(30);
      } else {
        formikRef.current?.setFieldError("Mobile", res.data)
      }
      setLoading(false);

    }
    else if (otpMobileNumber == formikRef.current?.values.Mobile) {
    }
    else {
      setOtpMobileNumber("");
    }
  };

  const signInCall = async (input) => {
    setLoading(true);
    const result = await signInAction(input);
    if (result.status) {
      dispatch(authActions.sessionExpireUpdate({ sessionExpiredTime: result.data.Tokens.exp - (5 * 60), sessionExpiredDate: new Date().setSeconds(new Date().getSeconds() + (result.data.Tokens.exp - (5 * 60))) }))
      const userDetails = await getUserDetailsAction(result.data.User_EmailID);
      if (userDetails.status) {
        const user = JSON.parse(JSON.stringify(userDetails.data));
        user.User_Session_Id = result.data.User_Session_Id;
        dispatch(authActions.signInStateUpdate(user))
        toolTipActive("success", 'You have successfully logged in.');
        navigate("/home");
      } else {
        setError(userDetails.data);
        setSeconds(3);
      }
    } else {
      setError(result.data);
      setSeconds(3);
    }
    setLoading(false);

  }

  return (
    <Container sx={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Grid container sx={{ justifyContent: "space-evenly" }}>
        {/* Left side image */}
        {winSize.screenSize.width > 701 &&
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: "flex", justifyContent: "left" }}>
              <img
                src={require('../../assests/images/mee.png')}
                alt=""
                style={{
                  width: "85%",
                  maxWidth: '544px',
                  marginTop: "60px"
                }}
              />
            </Box>
          </Grid>
        }

        {/* Right side content */}
        <Grid item xs={12} sm={4} textAlign={"center"}>
          <Box
            sx={{
              marginBottom: '30px', display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <img
              src={require('../../assests/images/avatarsignup.jpg')}
              alt=""
              style={{
                width: '44px',
                height: '44px',
                borderRadius: '50%',
              }}
            />
            <Box sx={{ flex: "1", width: "100%", maxWidth: "400px" }}>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ textAlign: "center", marginBottom: "30px" }}
              >
                Sign In
              </Typography>

              {/* Formik form */}
              <Formik
                innerRef={formikRef}

                initialValues={{
                  Mobile: "",
                  Mobile_OTP: "",
                }}
                validationSchema={Yup.object().shape({
                  Mobile: Yup.string()
                    .trim()
                    .matches(/^[^\s]*$/, 'Mobile Number cannot contain spaces.')
                    .matches(/^\d+$/, 'Mobile Number should contain only digits.')
                    .min(10, "Please provide valid Mobile Number.")
                    .max(10, "Please provide valid Mobile Number.")
                    .required("Mobile Number is required."),
                  Mobile_OTP: Yup.string().when('Mobile', (Mobile, schema) => {
                    if (Mobile?.length == 10) {
                      return schema.min(6, 'Please provide valid OTP.')
                        .required('Mobile OTP is required.');
                    } else {
                      return schema.required('Mobile OTP is required.')
                    }
                  })
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  let formdata = {
                    "Device_Identity": deviceModelName,
                    "Mobile": "+91 " + values.Mobile,
                    "Latitude": location?.latitude,
                    "Location": location?.locationInfo,
                    "Longitude": location?.longitude,
                    "Mobile_OTP": values.Mobile_OTP,
                    "Expo_Push_Notification": ""
                  }
                  signInCall(formdata)
                }}
              >
                {({ values, errors, touched, setFieldValue, handleBlur }) => (
                  <Form sx={{ alignItems: "center" }}>
                    <Box
                      sx={{
                        marginBottom: "30px",
                        flex: "1",

                      }}
                    >
                      <Field
                        name="Mobile"
                        as={TextField}
                        label="Mobile Number"
                        size="small"
                        fullWidth
                        autoComplete="off"
                        onBlur={(props) => {
                          handleBlur(props);
                          setIsValidMobileNumber(false);
                        }}
                        onChange={(event) => {
                          const value = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
                          if (value.length < 11) {
                            setFieldValue('Mobile', value);
                          }
                        }}
                        helperText={touched.Mobile && errors.Mobile}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            color: theme.palette.error.main,
                          },
                          "& fieldset": {
                            borderColor:
                              touched.Mobile && errors.Mobile
                                ? theme.palette.error.main
                                : undefined,
                          },
                        }}
                        error={Boolean(touched.Mobile && errors.Mobile)}
                      />
                      {(isValidMobileNumber && !Boolean(touched.Mobile && errors.Mobile)) && (
                        <Typography variant="body2" sx={{ textAlign: 'right' }}>
                          <Link href="#" sx={{ textDecoration: 'none' }} onClick={(e) => {
                            if (!loading && secondsOTP <= 0)
                              onMobileFieldOnBlur(true)
                          }}
                          >
                            {secondsOTP > 0
                              ? `Resend OTP (${secondsOTP})`
                              : `Resend OTP`}
                          </Link>
                        </Typography>
                      )}
                    </Box>

                    {(isValidMobileNumber && !Boolean(touched.Mobile && errors.Mobile)) && (
                      <Box
                        sx={{
                          marginBottom: "30px",
                          flex: "1",

                        }}
                      >
                        <Field
                          name="Mobile_OTP"
                          as={TextField}
                          label="Mobile OTP"
                          size="small"
                          fullWidth
                          autoComplete="off"
                          inputProps={{ maxLength: 6 }}
                          helperText={touched.Mobile_OTP && errors.Mobile_OTP}
                          error={touched.Mobile_OTP && Boolean(errors.Mobile_OTP)}
                          onChange={(e) => {
                            let value = e.currentTarget.value;
                            if (value.includes(" ")) {
                              value = value.slice(0, value?.length - 1)
                            }
                            value = value.replace(/\D/g, "");
                            setFieldValue('Mobile_OTP', value);
                          }}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              color: theme.palette.error.main
                            },
                            "& fieldset": {
                              borderColor:
                                touched.Mobile_OTP && errors.Mobile_OTP
                                  ? theme.palette.error.main
                                  : undefined,
                            },
                          }}
                        />
                      </Box>
                    )}

                    {seconds > 0 &&
                      <Box sx={{ marginBottom: '30px', width: "100%", maxWidth: "412px" }}>
                        <Typography sx={{ color: theme.palette.error.main }} >
                          {error}
                        </Typography>
                        <LinearProgress variant="determinate" color={"error"} value={seconds * 30} />
                      </Box>
                    }

                    {isValidMobileNumber ? <Button
                      type="submit"
                      variant="contained"
                      size="medium"
                      fullWidth
                      disabled={loading}
                      sx={{
                        marginBottom: "30px",
                        height: "36px",
                        borderRadius: "22px",
                        flex: "1",
                        ...(winSize.screenSize.width<600?{
                          '&:hover': {
                          backgroundColor: theme.palette.primary.main,
                        },
                        }:{})

                      }}
                    >
                      Sign In
                    </Button> : <Button
                      onClick={() => {
                        onMobileFieldOnBlur();
                      }}
                      type="button"
                      variant="contained"
                      size="medium"
                      fullWidth
                      disabled={loading}
                      sx={{
                        marginBottom: "30px",
                        height: "36px",
                        borderRadius: "22px",
                        flex: "1",
                        ...(winSize.screenSize.width<600?{
                          '&:hover': {
                          backgroundColor: theme.palette.primary.main,
                        },
                        }:{})

                      }}
                    >
                      Send OTP
                    </Button>}


                    <Typography
                      variant="body2"
                      sx={{ marginBottom: "30px", textAlign: "center" }}
                    >
                      --- Are you new ---
                    </Typography>
                    <Link href="/auth/signup">
                      <Button
                        type="button"
                        variant="contained"
                        size="medium"
                        fullWidth
                        sx={{
                          height: "36px",
                          borderRadius: "22px",
                          flex: "1",
                          marginBottom: "30px",
                        }}
                      >
                        Create your account
                      </Button>
                    </Link>

                    {/* Sign In link */}
                    <Typography
                      variant="body2"
                      sx={{ marginBottom: "30px", textAlign: "center" }}
                    >
                      <Link href="/auth/signin" sx={{ textDecoration: "none" }}>
                        Sign In using Password
                      </Link>
                    </Typography>

                    {/* Copyright */}
                    <Typography
                      variant="body2"
                      sx={{ marginBottom: "30px", textAlign: "center" }}
                    >
                      Copyright @ EasyStepIn 2016 - 2024.
                    </Typography>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SignInOTP