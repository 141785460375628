import { Box, CardMedia, Container, Typography, Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import useScreenSize from '../../../utils/ScreenWidthHight';
import { fetchPrivacyPolicy } from '../../../store/slices/retail/home/PrivacyPolicy';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { useTheme } from '@emotion/react';

const PrivacyPolicy = () => {
    const winSize = useScreenSize();
    const { loading, privacyPolicy } = useSelector(state => state.privacypolicy);
    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        dispatch(fetchPrivacyPolicy({ Name: "Privacy&Cookie Policy", Module_Type: 'USER' }));
    }, [dispatch]);

    return (
        <Box>
            {/* Left Side */}
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ display: "flex", justifyContent: { md: "center", xs: 'left' }, width: "100%", alignItems: "center", gap: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: { md: "flex-end", xs: 'flex-start' }, gap: 1 }}>
                        {loading ? (
                            <Skeleton
                                variant="text"
                                height="90px"
                                sx={{
                                    ml: { md: '0px', sm: '0px', xs: '26px' },
                                    width: { xs: '150px', md:'440px'}
                                }}
                            />
                        ) : (
                            <Typography sx={{ display: 'flex', color: theme.palette.extra.primary, fontSize: { md: '60px', sm: '60px', xs: '34px' }, fontWeight: 'bold', ml: { md: '0px', sm: '0px', xs: '26px' } }}>
                                Privacy Policies
                            </Typography>
                        )}
                    </Box>
                </Box>
                {winSize.screenSize.width > 1050 ? (
                    <Box sx={{ flex: '1' }}>
                        {loading ? (
                            <Skeleton
                                variant="rectangular"
                                width="611px"
                                height="499px"
                                sx={{
                                    borderRadius: "24px",
                                    mr: { md: '10px', sm: '10px', xs: 'unset' }
                                }}
                            />
                        ) : (
                            <CardMedia
                                component="img"
                                image={require("../../../assests/images/tickets.png")}
                                sx={{
                                    width: '611px',
                                    height: '499px',
                                    display: "flex",
                                    flexDirection: "column",
                                    flexWrap: "wrap"
                                }}
                            />
                        )}
                    </Box>
                ) : null}
            </Box>
            {loading ? (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 3
                }}>
                    <Skeleton
                        variant="rectangular"
                        width="85%"
                        height={700}
                        sx={{
                            borderRadius: "24px"
                        }}
                    />
                </Box>
            ) : (
                privacyPolicy?.map((privacy, index) => (
                    <Box key={index} sx={{ width: { md: '100%', sm: '100%', xs: '90%' }, display: 'flex', justifyContent: 'center', alignItems: 'center', ml: { md: '0px', sm: '0px', xs: '20px' } }}>
                        <Container sx={{ border: "0.4px solid", borderColor: theme.palette.text.secondary, borderRadius: "24px", boxShadow: "0 4px 4px 0 grey" }}>
                            {parse(privacy?.Description)}
                        </Container>
                    </Box>
                ))
            )}
        </Box>
    );
};

export default PrivacyPolicy;
