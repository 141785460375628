import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, TextField, IconButton, Card, Grid, Typography, FormControlLabel, RadioGroup, InputAdornment, Radio, Button, FormGroup, Checkbox, CardMedia, FormHelperText, FormControl, InputLabel, Select, MenuItem, useMediaQuery, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { createAddress, createAddressAction, deleteAddressAction, fetchAddress, fetchEditAddressAction } from '../../../store/slices/accounts/Address/Address';
import AddressForm from './AddressForm';
import GoogleMap from './GoogleMap';
import States from '../../components/accounts/Address/States';
import Cities from '../../components/accounts/Address/Cities';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import { GlobalContext } from '../../../Context';
import { isEmpty } from '../../../utils';
import CloseIcon from "@mui/icons-material/Close";
import { useScreenSize } from '../../../utils/ScreenWidthHight'
import SearchIcon from '@mui/icons-material/Search';
import AutoAdjustText from "../../../utils/AutoAdjustText"

export const iconProfiles = {
    home: require("../../../assests/images/HomeIconLocation.png"),
    business: require("../../../assests/images/bussinessBagLocation.png"),
    office: require("../../../assests/images/officeLocation.png")
}

const AddAddress = () => {
    const { id } = useParams();
    const [extractedId, setExatractedId] = useState(id ? id : null)
    const theme = useTheme();
    const authDetails = useSelector(state => state.auth)
    const addressProfiles = useSelector(state => state.addressProfiles.addressProfile)
    const pagination = useSelector(state => state.addressProfiles.pagination)
    const [editAddressData, setEditAddressData] = useState(null)
    const [mapState, setMapState] = useState(false)
    const dispatch = useDispatch()
    const [mapAddress, setMapAddress] = useState({})
    const navigate = useNavigate()
    const isDarkMode = theme.mode === 'dark';
    const { toolTipActive } = React.useContext(GlobalContext);
    const [viewOnMapState, setViewOnMapState] = useState(false)
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [modalAddressId, setModalAddressId] = useState("")
    const { pathname } = useLocation();
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const winSize = useScreenSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (winSize.screenSize.width < 600) {
            setIsMobile(true)
        }
        else {
            setIsMobile(false)
        }
    }, [winSize])


    useEffect(() => {
        if (searchParams.get("PageNo")) {
            if (searchParams.get("status")) {
                fetchAdd(searchParams.get("status"))
            }
            addressFetch(searchParams.get("PageNo"))
            setExatractedId(searchParams.get("status"))
        }
        else {
            {
                let querys = "";
                searchParams.forEach((value, key) => {
                    querys += "&" + key + "=" + value
                });
                navigate('/accounts/address?PageNo=1' + querys, { replace: true });
            }
        }

    }, [searchParams, pathname, isMobile]);


    const addressFetch = (page = 1, pagesize = (winSize.screenSize.width < 600 ? 2 : 5), search = "") => {
        if (authDetails?.user?.Email_Id)
            dispatch(fetchAddress({
                formData: {
                    "User_Email_Id": authDetails?.user?.Email_Id,
                    "Search": search
                },
                pagination: { PageNo: page, PageSize: (winSize.screenSize.width < 600 ? 2 : pagesize) }
            }))
    }


    const fetchAdd = async (id) => {
        const res = await fetchEditAddressAction({
            "User_Address_Id": id?.toString()
        })
        if (res.status) {
            const item = res.data.results[0]
            setEditAddressData({
                id: item?.User_Address_Id,
                type: item?.Address_Type,
                billingType: {
                    default: item?.Is_Default === 1 ? true : false,
                    billing: item?.Is_Billing === 1 ? true : false,
                    shipping: item?.Is_Shipping === 1 ? true : false,
                },
                location: item?.Location,
                DoorNo: item?.Door_No,
                street: item?.Street,
                landMark: item?.Land_Mark,
                city: item?.City,
                state: item?.State,
                pincode: item?.Zip,
                name: item?.Name,
                phone: item?.Mobile,
                alternativePhonenumber: item?.Alternate_Mobile,
                User_Email_Id: item?.User_Email_Id,
                lat: item?.Latitude,
                lag: item?.Longitude
            })
        }
        else {
            navigate("/accounts/address");
        }
    }

    const createAddressActionCall = async (formData, resetForm, setFieldValue) => {
        const response = await createAddressAction(formData)
        if (response.status) {
            toolTipActive("success", "Address Saved");
            navigate(0)
            dispatch(fetchAddress({
                formData: {
                    "User_Email_Id": authDetails?.user?.Email_Id
                },
                pagination: { PageNo: 1, PageSize: 5 }
            }))
        }
    }

    const validationSchema = Yup.object().shape({
        location: Yup.string().min(3, 'Location must be at least 3 characters').required('Location is required'),
        doorNo: Yup.string()
            .trim(" ")
            .min(1, 'Door No cannot be empty') // Ensure it's not empty after trimming
            .max(20, 'Door No must be at most 20 characters') // Restrict the length
            .required('Door No is required'),
        street: Yup.string().trim(" ").min(3, 'Street must be at least 3 characters').required('Street is required'),
        landmark: Yup.string().trim(" ").min(3, 'Landmark must be at least 3 characters').required('Landmark is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        pincode: Yup.string().min(6, 'Pin must be 6 numbers').max(6, 'Street must be at most 6 numbers').required('Pincode is required'),
        name: Yup.string()
            .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9 !@#$%^&*()\-_=+{};:,.<>?~'"|[\]\\/]+$/, 'Please use alphabtes or combination of alphabets, numbers, and specific symbols. Avoid using only numbers or special characters.')
            .min(3, 'Name must be at least 3 characters')
            .required('Name is required'),
        mobileNumber: Yup.string()
            .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits')
            .required('Mobile Number is required'),
        alternativeMobileNumber: Yup.string()
            .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits')
    });
    const formik = useFormik({
        initialValues: {
            location: '',
            doorNo: '',
            street: '',
            landmark: '',
            city: '',
            state: '',
            pincode: '',
            name: '',
            mobileNumber: '',
            alternativeMobileNumber: '',
            default: false,
            billing: false,
            shipping: false,
            home: false,
            business: false,
            office: false,
            type: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm, setFieldValue }) => {
            const payload = {
                User_Email_Id: authDetails?.user?.Email_Id,
                Address_Type: values.type != '' ? values.type : "home",
                Is_Billing: values.billing ? 1 : 0,
                Is_Default: 1,
                Is_Shipping: values.shipping ? 1 : 0,
                Location: values.location,
                Door_No: values.doorNo.trim(),
                Street: values.street,
                Land_Mark: values.landmark,
                City: values.city,
                State: values.state,
                Zip: values.pincode,
                Mobile: "+91 " + values.mobileNumber,
                Alternate_Mobile: "+91 " + values.alternativeMobileNumber || '',
                Name: values.name,
                Latitude: mapAddress?.latitude,
                Longitude: mapAddress?.longitude,
                Country: "IN"
            }
            if (values.mobileNumber !== values.alternativeMobileNumber) {
                await createAddressActionCall(payload, resetForm, setFieldValue)
            }
        },
    });


    const mapAddressChange = (address) => {
        setMapAddress(address)
        formik.setFieldValue("location", address?.locationInfo)
        formik.setFieldValue("pincode", address?.postcode)
        formik.setFieldValue("lat", address?.latitude)
        formik.setFieldValue("lag", address?.longitude)
    }

    const mapStateChange = (state) => {
        setMapState(state)
    }

    const deleteAddressActionCall = async (formData) => {
        const response = await deleteAddressAction(formData, toolTipActive)
        if (response.status) {
            if (modalAddressId === id) {
                setEditAddressData(null)
                navigate("/accounts/address");
            }
            dispatch(fetchAddress({
                formData: {
                    "User_Email_Id": authDetails?.user?.Email_Id
                },
                pagination: { PageNo: 1, PageSize: 5 }
            }))
        }
        else {
        }
    }

    const AddressFormMemo = useMemo(() => {

        if (!isEmpty(editAddressData))
            return <AddressForm editAbleData={editAddressData} edit={true} />
        else {
            if (mapState)
                return (
                    <Box sx={{ width: { xs: "100%", md: "100%" }, minHeight: "50%", }} >
                        <GoogleMap onAddress={mapAddressChange} mapState={mapStateChange} />
                    </Box>
                )
            return (
                <Box sx={{ width: { xs: "100%", md: "100%" }, minHeight: "50%", }}>
                    <Box sx={{ width: "100%", height: 27, display: "flex", justifyContent: "flex-end" }} >
                        <Typography sx={{ fontWeight: "bold", fontSize: 16 }} >Add New Address</Typography>
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", gap: 5, flexWrap: "wrap" }}>
                        <Box sx={{ width: "100%" }}  >
                            <TextField
                                sx={{ width: "100%", height: 44 }}
                                label={"Location"}
                                name="location"
                                onClick={() => {
                                    setMapState(true)
                                }}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={() => {
                                                setMapState(true)
                                            }} >
                                                <CardMedia
                                                    sx={{ width: 36, height: 44 }}
                                                    image={require('../../../assests/images/locationAddress.png')}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                value={formik.values.location}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.location && Boolean(formik.errors.location)}

                            />
                            {formik.touched.location && formik.errors.location && (
                                <Typography mt={1} sx={{ padding: 0, color: theme.palette.error.main }} error>{formik.errors.location}</Typography>
                            )}
                        </Box>
                        <Box sx={{ width: "100%" }} >

                            <TextField
                                sx={{ width: "100%", height: 44 }}
                                label={"Door No"}
                                name="doorNo"
                                value={formik.values.doorNo}
                                // onChange={formik.handleChange}
                                onChange={(e) => {
                                    if (e.currentTarget.value.length < 21) // Apply the length check here
                                        formik.handleChange(e); // Handle change if length condition is met
                                }}
                                onBlur={formik.handleBlur}
                                error={formik.touched.doorNo && Boolean(formik.errors.doorNo)}
                            />
                            {formik.touched.doorNo && formik.errors.doorNo && (
                                <Typography mt={1} sx={{ padding: 0, color: theme.palette.error.main }} error>{formik.errors.doorNo}</Typography>
                            )}
                        </Box>
                        <Box sx={{ width: "100%" }} >

                            <TextField
                                sx={{ width: "100%", height: 44 }}
                                label={"Street"}
                                name="street"
                                value={formik.values.street}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.street && Boolean(formik.errors.street)}
                            />
                            {formik.touched.street && formik.errors.street && (
                                <Typography mt={1} sx={{ padding: 0, color: theme.palette.error.main }} error>{formik.errors.street}</Typography>
                            )}
                        </Box>
                        <Box sx={{ width: "100%" }} >

                            <TextField
                                sx={{ width: "100%", height: 44 }}
                                label={"Land Mark"}
                                name="landmark"
                                value={formik.values.landmark}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.landmark && Boolean(formik.errors.landmark)}
                            />
                            {formik.touched.landmark && formik.errors.landmark && (
                                <Typography mt={1} sx={{ padding: 0, color: theme.palette.error.main }} error>{formik.errors.landmark}</Typography>
                            )}
                        </Box>
                        <Box sx={{ width: "100%", height: 44, display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-between" }}>
                            <Box sx={{ width: "49%" }} >
                                <States

                                    labelId='demo-select-small-label'
                                    id="demo-select-small"
                                    label="Select state"
                                    name="state"
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.state && Boolean(formik.errors.state)}
                                />
                                {formik.touched.state && formik.errors.state && (
                                    <Typography error color={theme.palette.extra.errorMessage} >{formik.errors.state}</Typography>
                                )}
                            </Box>
                            <Box sx={{ width: "49%" }} >
                                <Cities
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    label="Select City"
                                    name="city"
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                    statecode={formik.values.state}
                                />
                                {formik.touched.city && formik.errors.city && (
                                    <Typography error color={theme.palette.extra.errorMessage}>{formik.errors.city}</Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ width: "100%" }} >

                            <TextField
                                sx={{ width: "100%", height: 44 }}
                                label={"Pincode"}
                                name="pincode"
                                value={formik.values.pincode}
                                onChange={(e) => {
                                    const value = e.currentTarget.value
                                    if (value[0] !== "0")
                                        formik.setFieldValue('pincode', value.replace(/\D/g, ""))
                                }}
                                onBlur={formik.handleBlur}
                                error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                            />
                            {formik.touched.pincode && formik.errors.pincode && (
                                <Typography mt={1} sx={{ padding: 0, color: theme.palette.error.main }} error>{formik.errors.pincode}</Typography>
                            )}
                        </Box>
                        <Box sx={{ width: "100%" }} >
                            <TextField
                                sx={{ width: "100%", height: 44 }}
                                label={"Name"}

                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                            // helperText={formik.touched.name && formik.errors.name}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <Typography mt={1} sx={{ padding: 0, color: theme.palette.error.main }} error>{formik.errors.name}</Typography>
                            )}
                        </Box>
                        <Box sx={{ width: "100%", height: {md:44, sm:44, xs:44}, display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-between" }}>
                            <Box sx={{ width: {md:"47%", sm :'47%', xs:'100%'}, height: "100%" }} >
                                <TextField
                                    sx={{ width: "100%" }}
                                    label={"Mobile Number"}
                                    name="mobileNumber"
                                    InputProps={{
                                        maxLength: 10,
                                        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                                    }}
                                    value={formik.values.mobileNumber}
                                    onChange={(e) => {
                                        if (e.currentTarget.value.length < 11)
                                            formik.setFieldValue('mobileNumber', e.currentTarget.value.replace(/\D/g, ""))
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                                // helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                                />
                                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                                    <FormHelperText error>{formik.errors.mobileNumber}</FormHelperText>
                                )}
                            </Box>
                            <Box sx={{ width: { md: "47%", sm: '47%', xs: '100%' }, height: "100%" , mt:{xs:4.5, md:'unset', sm:'unset'}}} >
                                <TextField
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        maxLength: 10,
                                        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                                    }}
                                    label={"Alternate Mobile Number"}
                                    name="alternativeMobileNumber"
                                    value={formik.values.alternativeMobileNumber}
                                    onChange={(e) => {
                                        if (e.currentTarget.value.length < 11)
                                            formik.setFieldValue('alternativeMobileNumber', e.currentTarget.value.replace(/\D/g, ""))
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.alternativeMobileNumber && Boolean(formik.errors.alternativeMobileNumber) || (formik.values.alternativeMobileNumber === formik.values.mobileNumber && formik.values.alternativeMobileNumber !== "" && !(formik.touched.alternativeMobileNumber && formik.errors.alternativeMobileNumber))}
                                // helperText={formik.touched.alternativeMobileNumber && formik.errors.alternativeMobileNumber}
                                />
                                {formik.touched.alternativeMobileNumber && formik.errors.alternativeMobileNumber && (
                                    <FormHelperText error>{formik.errors.alternativeMobileNumber}</FormHelperText>
                                )}
                                {(formik.values.alternativeMobileNumber === formik.values.mobileNumber && formik.values.alternativeMobileNumber !== "" && !(formik.touched.alternativeMobileNumber && formik.errors.alternativeMobileNumber)) && (
                                    <FormHelperText error>{"Mobile number and alternate mobile number should not be same"}</FormHelperText>
                                )}
                            </Box>
                        </Box>

                        <Box sx={{ width: "100%", height: 44, mt: {xs:8, md:'unset', sm:'unset'} }}>
                            <RadioGroup onChange={formik.handleChange} name='type' >
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <FormControlLabel
                                            value="home"
                                            control={<Radio />}
                                            label={<span style={{ fontWeight: 'bold' }}>Home</span>}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControlLabel value="business" control={<Radio />} label={<span style={{ fontWeight: 'bold' }}>Business</span>} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControlLabel value="office" control={<Radio />} label={<span style={{ fontWeight: 'bold' }}>Office</span>} />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </Box>
                        <Button
                            sx={{ width: "100%", borderRadius: 24 }}
                            variant="contained"
                            type="submit"
                            onClick={formik.handleSubmit}
                        >
                            Save Address
                        </Button>
                    </Box>
                </Box >
            )
        }
    }, [editAddressData, formik])

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                // backgroundColor: isDarkMode ? 'black' : 'white',
                gap: '10%',
                alignItems: "start",

                justifyContent: { xs: "space-around" }
            }}
        >
            <Box
                sx={{
                    width: { xs: '90%', sm: '25%', md: '35%' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: { md: 'flex-start', xs: "center" },
                    alignItems: 'center',
                    p: 0,
                    paddingTop: "0.1%"
                }}
            >
                <Box
                    sx={{
                        width: { xs: "100%", sm: "90%" }, // Adjusted width for small screens
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: { md: 'flex-start', xs: "center" },
                        alignItems: 'center',
                        p: { xs: 2, sm: 2 },
                    }}
                >

                    <TextField
                        placeholder="Search Address"
                        size={winSize.screenSize.width < 600 ? "medium" : 'medium'}
                        sx={{
                            width: { xs: '100%', md: '100%' },
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.input.borderColor,
                            },
                            '&:hover .MuiInputLabel-outlined': {
                                color: theme.palette.input.borderColor,
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.input.borderColor,
                            },
                            '& .MuiOutlinedInput-input': {
                                color: theme.palette.text.primary,
                            },
                            padding: 0

                        }}
                        onChange={(e) => {
                            addressFetch(1, 5, e.currentTarget.value)
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: theme.palette.primary.main, }} />
                                </InputAdornment>
                            ),
                            sx: { borderRadius: { xs: "16px", sm: "6px" } },
                        }}
                    />

                </Box>


                <Box
                    sx={{
                        width: { xs: '100%', md: '100%' },
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {addressProfiles?.length >= 1 ? (
                        addressProfiles?.map((address, index) => (
                            <Card
                                key={index}
                                // sx={{ width: { xs: '100%', md: '85%' }, minHeight: 168, display: 'flex', flexWrap: 'wrap', justifyContent: 'center', borderRadius: 5, border: '1px solid gray', boxShadow: 'none' }}
                                sx={{
                                    width: { xs: '95%', md: '85%' },
                                    minHeight: { xs: "100px", sm: '140px' },
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    borderRadius: 5,
                                    border: '1px solid gray',
                                    padding: '8px',
                                    boxShadow: 'none',
                                    backgroundColor: extractedId === address.id ? "#90A4AE55" : 'transparent'
                                }}
                            >
                                <Box
                                    sx={{ width: { xs: '90%', md: '90%' }, height: '100%', display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', flexDirection: 'column', gap: 0 }}
                                >
                                    <Box sx={{ width: { xs: '100%', md: '90%' }, height: { xs: 24, sm: 42 }, display: 'flex', flex: 'wrap', gap: 1 }}>
                                        <CardMedia
                                            sx={{ width: { xs: 22, sm: 24 }, height: { xs: 18, sm: 24 } }}
                                            image={address.type.toUpperCase() === 'HOME' ? iconProfiles.home : address.type.toUpperCase() === 'business'.toUpperCase() ? iconProfiles.business : address.type.toUpperCase() === 'office'.toUpperCase() ? iconProfiles.office : null}
                                        />
                                        <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, fontWeight: 'bold' }}>{address.type.toUpperCase()}</Typography>
                                    </Box>
                                    <Box sx={{ width: { xs: '100%', md: '90%' }, minHeight: { xs: 24, sm: 48 } }}>
                                        {winSize.screenSize.width < 600 ?
                                            <AutoAdjustText style={{ fontWeight: 'bold', fontSize: "12px" }} numLines={1} >
                                                {address.DoorNo}, {address.landMark}, {address.street} {/* ,{address.location} */}, {address.city}, {address.state} - {address.pincode}
                                            </AutoAdjustText>
                                            : <Typography sx={{ fontWeight: 'bold' }}>
                                                {address.DoorNo}, {address.landMark}, {address.street} {/* ,{address.location} */}, {address.city}, {address.state} - {address.pincode}
                                            </Typography>}
                                    </Box>
                                    <Box sx={{ width: { xs: '100%', md: '90%' }, minHeight: { xs: 24, sm: 48 } }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: { xs: "12px", sm: "unset" } }}>{address.phone}</Typography>
                                    </Box>
                                    <Box sx={{ width: { xs: '100%', md: '100%' }, height: 24, display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: "center", justifyContent: "space-between" }}>
                                        <Link target='_blank' to={`https://www.google.com/maps/search/?api=1&query=${address.Latitude},${address.Longitude}`} style={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "17px" }, textDecoration: "none", color: theme.palette.primary.main }}  >
                                            View on Map
                                        </Link>
                                        <Box sx={{ width: { xs: "25%", sm: '35%' }, height: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'flex-start' }}>
                                            <IconButton
                                                sx={{ padding: 0, margin: 0 }}
                                                onClick={() => {
                                                    updateQueryParam([
                                                        { "PageNo": searchParams.get("PageNo") },
                                                        { "status": address?.id }
                                                    ]);
                                                    setSelectedAddressId(address?.id);
                                                }}
                                            >
                                                <CardMedia
                                                    image={require('../../../assests/images/re-edit.png')}
                                                    sx={{ width: '24px', height: '24px', objectFit: 'contain' }}
                                                />
                                            </IconButton>


                                            <IconButton sx={{ padding: 0, margin: 0 }} onClick={() => {
                                                setConfirmationOpen(true)
                                                setModalAddressId(address.id)
                                                // deleteAddressActionCall({ User_Address_Id: address.id })
                                            }}>
                                                <CardMedia
                                                    image={require('../../../assests/images/delete.png')}
                                                    sx={{ width: '20px', height: '20px', objectFit: 'contain', }}
                                                />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </Card>
                        ))
                    ) : (
                        <Typography>No Address</Typography>
                    )}
                    {pagination.TotalPages > 1 ?
                        <Pagination
                            page={pagination.PageNo}
                            count={pagination.TotalPages}
                            onChange={(e, page) => {
                                updateQueryParam([{ "PageNo": page }])
                            }}
                            size={winSize.screenSize.width < 600 ? 'small' : "medium"}
                            color="primary" /> : null
                    }
                    {extractedId ? <Button onClick={() => {
                        navigate("/accounts/address")
                        setEditAddressData(null)
                        addressFetch(1)
                    }} >Add New Address</Button> : null}
                </Box>
            </Box>
            <Box
                sx={{
                    width: { xs: '90%', sm: "40%", md: '45%' },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2
                }}
            >
                {AddressFormMemo}
            </Box>
            {
                !isEmpty(modalAddressId) &&
                <AddressDeleteModal confirmationOpen={confirmationOpen} setConfirmationOpen={setConfirmationOpen} deleteAddressActionCall={deleteAddressActionCall} modalAddressId={modalAddressId} />
            }
        </Box>
    );
}
export default AddAddress


export const AddressDeleteModal = ({ confirmationOpen, setConfirmationOpen, deleteAddressActionCall, modalAddressId }) => {
    return (
        <Dialog
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <IconButton
                aria-label="close"
                onClick={() => setConfirmationOpen(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle >{"Remove Address from Account?"}</DialogTitle>
            <DialogContent>
                <DialogContentText >
                    Are you sure you want to remove this address from your account?
                </DialogContentText>
            </DialogContent>
            <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "30px" }}>
                <DialogActions>
                    <Box>
                        <Button variant="contained" color="primary" autoFocus

                            onClick={() => {
                                deleteAddressActionCall({ User_Address_Id: modalAddressId })
                                setConfirmationOpen(false)
                            }}

                        >
                            delete
                        </Button>
                    </Box>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
