import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBanners } from '../../../store/slices/retail/home/BannerSlice';
import Carousel from '../../components/cards/banner/Carousel';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/material';

const Banner = () => {
    const dispatch = useDispatch();
    const { banners } = useSelector(state => state.homeBanners);
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(fetchBanners({ "Business_Type": "RETAIL" }));
    }, []);

    const handleBannerClick = (banner) => {
        const { Banner_Offer_Type, Banner_Offer_Id } = banner;
        switch (Banner_Offer_Type) {
            case 'Partner':
                navigate(`/retail/partner/${Banner_Offer_Id}`);
                break;
            case 'Coupon':
                navigate(`/retail/offers/coupons/${Banner_Offer_Id}`);
                break;
            case 'Product':
                navigate(`/retail/products/${Banner_Offer_Id}`);
                break;
            case 'Deal':
                navigate(`/retail/offers/deals/${Banner_Offer_Id}`);
                break;
            default:
                navigate(`/pagenotfound`);
                break;
        }
    };
        return (
            <Carousel
                data={banners}
                imgLabel={"Banner_Image"}
                onClick={handleBannerClick}
                imageStyle={{
                    width: '100%',
                    height: '339px',
                    position: 'absolute',
                    borderRadius: '32px',
                    objectFit: 'fill'
                }}
            />
        )
}

export default Banner;
