import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Typography,
    CardActionArea,
    CardMedia,
    TextField,
    IconButton,
    FormHelperText,
    Card,
    InputAdornment,
    Pagination,
    Skeleton
} from "@mui/material";
import { useTheme } from '@emotion/react';
import SearchIcon from '@mui/icons-material/Search';
import useScreenSize from '../../utils/ScreenWidthHight';
import { useSelector, useDispatch } from 'react-redux';
import { getPartners, partnerFetch } from '../../store/slices/retail/home/PartnerSlice'
import { useLocation, useNavigate } from 'react-router-dom';
import noPartnerFound from '../../assests/images/No partner Found.png'
import { GlobalContext } from '../../Context';
import AutoAdjustText from '../../utils/AutoAdjustText';
import { fontSize } from '@mui/system';

const Shop = () => {
    const theme = useTheme();
    const winSize = useScreenSize();
    const navigate = useNavigate();
    const [homePartners, setHomePartners] = useState([])
    const [pageNo, setPageNo] = useState(1)
    const [loading, setLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(1)
    const [totalCards, setTotalCards] = useState(0)
    const [width, setWidth] = useState(230)
    const pathname = useLocation()
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext)
    useEffect(() => {
        if (searchParams.get("PageNo"))
            partnerFetchActionCall({ Business_Type: 'RETAIL', Is_Open: 1 }, { PageNo: searchParams.get("PageNo"), PageSize: totalCards })
        else {
            {
                let querys = "";
                searchParams.forEach((value, key) => {
                    querys += "&" + key + "=" + value
                });
                navigate('/retail/shop?PageNo=1' + querys, { replace: true });
            }
        }
    }, [pathname, searchParams, totalCards]);
    const partnerFetchActionCall = async (formData, Pagination) => {
        setLoading(true)
        const response = await partnerFetch(formData, Pagination)
        if (response.status) {
            setHomePartners(response.data)
            setTotalCount(response.Pagination.TotalPages)
            setPageNo(response.Pagination.PageNo)
            setLoading(false)
        }
        else {
            setHomePartners([])
            setLoading(false)
        }
    }
    const getCards = (width, cardWidth) => {
        let totalCards;
        if (width && cardWidth) {
            totalCards = (width / (cardWidth + 20))
            return totalCards
        }
        else {
            return 0
        }
    }
    const getWidthFromPercentage = (percentage = 0, width) => {
        let totalWidth;
        if (width) {
            totalWidth = width
        } else {
            totalWidth = winSize.screenSize.width
        }
        const getWidth = (percentage * totalWidth) / 100

        return getWidth
    }

    useEffect(() => {
        const cardWidth = getWidthFromPercentage(
            (
                winSize.screenSize.width < 820 ? 45 :
                    winSize.screenSize.width < 900 ? 30 :
                        winSize.screenSize.width < 1000 ? 30 :
                        winSize.screenSize.width < 1100 ? 30 : 
                        winSize.screenSize.width < 1200 ? 30 : 
                        winSize.screenSize.width < 1300 ? 23 : 18
            )
            , getWidthFromPercentage(90))
        const cards = getCards(getWidthFromPercentage(90), cardWidth)
        setWidth(cardWidth)
        setTotalCards(winSize.screenSize.width < 600 ? 10 :cards * 3)
    }, [winSize.screenSize.width])

    return (
        <Box sx={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            gap: winSize.screenSize.width <= 749 ? 3 : 4,
            marginTop: "3%"
        }}>

            <Box sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: { xs: "center", sm: "space-between" },
                gap: 1
            }}>
                <Box sx={winSize.screenSize.width <= 749 ? {
                    width: winSize.screenSize.width,
                    textAlign: "center",
                    marginLeft: 3
                } : { marginLeft: 6 }}>
                    <FormHelperText sx={{ fontWeight: "bold", fontSize: { xs: "1rem", sm: "22px" } }}>Partners</FormHelperText>
                </Box>
                <Box sx={winSize.screenSize.width <= 749 ? {
                    display: 'flex',
                    flexWrap: "wrap",
                    alignItems: 'center',
                    width: { xs: "90%", sm: "90%" },
                    justifyContent: "center"
                } : {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: 419
                }}>
                    <TextField
                        placeholder="Search Partner"
                        size={winSize.screenSize.width < 600 ? "medium" : "small"}
                        onChange={(e) => {
                            partnerFetchActionCall({ Business_Type: 'RETAIL', search: e.target.value }, { PageNo: 1, PageSize: 16 })
                        }}
                        sx={{
                            width: { xs: "100%", sm: '80%' },
                            maxWidth: { xs: "unset", sm: '350px' },
                            borderRadius: "3px 0px 0px 3px",
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                borderColor: theme.palette.input.borderColor
                            },
                            "&:hover .MuiInputLabel-outlined": {
                                color: theme.palette.input.borderColor
                            },
                            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                borderColor: theme.palette.input.borderColor
                            },
                            "& .MuiOutlinedInput-input": {
                                color: theme.palette.text.primary
                            },
                            "& fieldset": {
                                borderRadius: { xs: "15px", sm: "3px 0px 0px 3px" },
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: theme.palette.primary.main }} />
                                </InputAdornment>
                            ),
                        }}
                    />

                </Box>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                <Box sx={{
                    width: { xs: "90%", sm: "96%" },
                    minHeight: "50%",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    cursor: "pointer",
                    gap: { xs: 1, md: 1 }
                }}>
                    {!loading &&
                        (homePartners.length > 0 ?
                            homePartners?.map((partner, index) => (
                                <Card key={index} sx={{
                                    width: { xs: "100%", sm: width },
                                    // (winSize.screenSize.width < 1020 ? "28%" : (winSize.screenSize.width < 1420 ? (winSize.screenSize.width < 1420 ? (winSize.screenSize.width < 1350 ? "22%" : "28%") : "15%") : (winSize.screenSize.width < 1700 ? (winSize.screenSize.width < 1655 ? "17%" : "17%") : "14.5%")))
                                    maxWidth: { md: (winSize.screenSize.width > 1370 ? "auto" : "302px") },
                                    minHeight: { xs: 108, md: 108 },
                                    // maxHeight:{xs:208},
                                    borderRadius: 6,
                                    border: "1px solid grey",
                                    boxShadow: "none",
                                    zIndex: 1,
                                    padding: "0 10px",
                                }} onClick={() => { navigate(`/${pathname.pathname.includes("retail") ? "retail" : "grocery"}/partner/${partner.Partner_Details_Id}`) }}>
                                    <CardActionArea sx={{
                                        width: "100%",
                                        minHeight: 108,
                                        display: "flex",
                                        flexDirection: { xs: "row", md: "row" },
                                        gap: 2,
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Box>
                                            <CardMedia
                                                component="img"
                                                image={partner.Logo_Path}
                                                sx={{
                                                    width: '64px',
                                                    height: '100px',
                                                    borderRadius: '10px',
                                                    objectFit: "fill"
                                                }}
                                                alt={partner.title}
                                            />
                                        </Box>
                                        <Box sx={{ width: { xs: "100%", md: 205 }, display: "flex", flexDirection: "column", minHeight: 100 }}>
                                            <AutoAdjustText sx={{ marginBottom: "0px", color: theme.palette.primary, whiteSpace: { xs: "wrap", md: "wrap" }, fontSize: "24px" }} numLines={1} >{partner.Name}</AutoAdjustText>
                                            <AutoAdjustText style={{ height: "40px", width: "100%", fontSize: '14px', color: theme.palette.text.secondary }} numLines={2}>
                                                {partner.Short_Description}
                                            </AutoAdjustText>
                                        </Box>
                                    </CardActionArea>

                                </Card>
                            ))
                            :
                            <img src={noPartnerFound} style={{ marginTop: '20px', width: '40%', display: 'block', margin: '20px auto' }} />)
                    }
                    {loading &&
                        Array(15).fill(1)?.map((partner, index) => (
                            <Card key={index} sx={{
                                width: { xs: winSize.getHalfScreenSize.width - 30, md: 305 },
                                minHeight: { xs: 108, md: 108 },
                                // maxHeight:{xs:208},
                                borderRadius: 6,
                                border: "1px solid grey",
                                boxShadow: "none",
                                zIndex: 1,
                                padding: "0 10px",
                            }}>
                                <CardActionArea sx={{
                                    width: "100%",
                                    minHeight: 108,
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" },
                                    gap: 2,
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <Box>
                                        <Skeleton
                                            sx={{
                                                width: '64px',
                                                height: '100px',
                                                borderRadius: '10px',
                                                objectFit: "fill"
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ width: { xs: "100%", md: 205 }, display: "flex", flexDirection: "column", minHeight: 100 }}>
                                        <Skeleton variant='text' sx={{ marginBottom: 1, color: theme.palette.primary, whiteSpace: { xs: "wrap", md: "wrap" } }} />
                                        <Skeleton variant='text' sx={{ width: "100%", wordWrap: 'break-word', color: theme.palette.text.secondary, }} />
                                    </Box>
                                </CardActionArea>

                            </Card>
                        ))
                    }

                </Box>
            </Box>
            {(totalCount > 1 && !loading) ? <Box sx={{ display: 'flex', justifyContent: "center", width: "100%", paddingTop: 5 }} >
                <Pagination color="primary" page={pageNo} onChange={(e, page) => {
                    updateQueryParam([{ "PageNo": page }])
                }} count={totalCount} />
            </Box> : null}
        </Box>
    )
}

export default Shop