//header
import React, { useState, useMemo, useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Autocomplete, Button, InputAdornment, Link, Menu, Paper } from '@mui/material';
import { useTheme } from '@emotion/react';
import useScreenSize from '../../utils/ScreenWidthHight';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { fetchSearchResults } from '../../store/slices/retail/home/SearchSlice';
import { fetchAddress } from '../../store/slices/accounts/Address/Address';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material'; // Import the icon you want to use
import { minHeight } from '@mui/system';

const Headerauth = () => {
  const dispatch = useDispatch();
  const locations = useSelector(state => state.addressProfiles.addressProfile);
  const searchResult = useSelector(state => state.searchResults);
  const authDetails = useSelector(state => state.auth)
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated) || false
  const authData = useSelector(state => state.auth.user)
  const auth = useSelector(state => state.auth)
  const navigate = useNavigate()
  const [selectedItem, setSelectedItem] = useState("");
  const [personName, setPersonName] = useState('SELECT');
  const [searchOpen, setSearchOpen] = useState(false);
  const theme = useTheme();
  const winSize = useScreenSize();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const settings = ['Setting1', 'Setting2'];
  const [locationOpen, setLocationOpen] = useState(false);
  const [locationSelected, setLocationSelected] = useState({ title: "Add New Address", link: "true" })
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (authDetails?.isAuthenticated) {
      dispatch(fetchAddress(
        {
          formData: {
            "User_Email_Id": authDetails?.user?.Email_Id
          },
          pagination: { PageNo: 1, PageSize: 5 }
        }
      )
      );
      dispatch(fetchSearchResults(''));
    }
  }, []);


  const handleChange = (event) => {
    const { value } = event.target;
    setPersonName(value);
  };
  const handleNavigate = (value) => {
    navigate(value);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const formik = useFormik({
    initialValues: {
      loc: ''
    }
  });
  const handleOptionSelect = (event, newValue) => {

    if (newValue) {

      // setValue(null);
      if (newValue?.type === 'product') {
        navigate('/retail/products/' + newValue?.id);
      } else if (newValue?.type === 'brand') {
        navigate('/retail/brand/' + newValue?.id);
      } else if (newValue?.type === 'deal') {
        navigate('/retail/offers/Deals/' + newValue?.id);
      } else if (newValue?.type === 'coupon') {
        navigate('/retail/offers/coupons/' + newValue?.id);
      } else if (newValue?.type === 'partner') {
        navigate('/retail/partner/' + newValue?.id);
      }
    }
  };

  const HtmlTooltip = useMemo(() => styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: theme.typography.pxToRem(10),
      border: "1px solid #dadde9",
      width: "130px",
      height: "280px",
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none' /* Hide scrollbar for Chrome, Safari, and Opera */
      },
      scrollbarWidth: 'none',

      [theme.breakpoints.down("xs")]: {
        minWidth: "100px",
        minHeight: "100px",
      },
      [theme.breakpoints.up("sm")]: {
        minWidth: "220px",
        minHeight: "220px",
      },
      [theme.breakpoints.up("md")]: {
        minWidth: "130%",
        minHeight: '130%'
      },
    },
  })), []);

  return (
  
    <AppBar sx={{ maxWidth: winSize.screenSize.width, height: "60px", backgroundColor: theme.palette.header.backgroundcolor,paddingY:{xs:'10px', md:'0px'}, boxShadow: "none" }}>
      <Box>
        <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}  >
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: { xs: "40%" }, alignItems: "center", gap: .5 }} >
            <Link href="/">
              <img src={require('../../assests/images/mee.png')} alt="" style={{ height: "50px", width: "60px", }} />
            </Link>
            {/* Retail dropdown */}
            {
              (!searchOpen && !locationOpen) || winSize.screenSize.width >= 500 ? (
                <Box className="retail"
                  sx={{
                    display: 'flex',
                    marginLeft: { xs: '10px', sm: '30px', md: '20px', lg: '30px', xl: '40px' },
                    flexDirection: 'row',
                    flexWrap: "wrap",
                    // "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    //   borderColor: theme.palette.input.borderColor
                    // },
                    "&:hover .MuiInputLabel-outlined": {
                      color: theme.palette.input.borderColor
                    },
                    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.input.borderColor
                    },
                    "& .MuiOutlinedInput-input": {
                      color: theme.palette.text.primary
                    },
                    position: "relative",
                  }}
                >
                  <Select
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    IconComponent={(props) => <ExpandMore {...props} style={{ color: theme.palette.primary.main, cursor: "pointer" }} />}
                    sx={{ height: 40, width: { md: winSize.getWidthForGiveInput(8), xs: "130px" }, marginLeft: { xs: "0px", md: '36px' } }}
                  >
                    <MenuItem value="SELECT">Select</MenuItem>
                    <MenuItem onClick={() => { navigate("/retail") }} value="Retail" sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box style={{ display: 'flex', alignItems: 'center', }}>
                        <img
                          src={require('../../assests/images/retailHeaderDropDownIcon.png')}
                          alt="Retail Icon"
                          style={{ width: '21px', height: '21px', marginRight: '4px' }}
                        />
                        <Typography style={{ fontSize: '17px', marginTop: '2px', color: theme.palette.text.secondary, }}>Retail</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigate("/grocery/home")} value="Grocery">
                      <Box style={{ display: 'flex', alignItems: 'center', }}>
                        <img
                          src={require('../../assests/images/groceryHeaderDropDownIcon.png')}
                          alt="Retail Icon"
                          style={{ width: '21px', height: '21px', marginRight: '4px' }}
                        />
                        <Typography style={{ fontSize: '17px', marginTop: '2px', color: theme.palette.text.secondary, }}>Grocery</Typography>
                      </Box></MenuItem>
                    <MenuItem value="Services"><Box style={{ display: 'flex', alignItems: 'center', }}>
                      <img
                        src={require('../../assests/images/servicesHeaderDropDownIcon.png')}
                        alt="Retail Icon"
                        style={{ width: '21px', height: '21px', marginRight: '4px' }}
                      />
                      <Typography style={{ fontSize: '17px', marginTop: '2px', color: theme.palette.text.secondary, }}>Services</Typography>
                    </Box></MenuItem>
                    {
                      <MenuItem onClick={
                        isAuthenticated ? () => { navigate("/gifts") } : () => {
                          navigate('/auth/signin')
                        }} value="Gift"  ><Box style={{ display: 'flex', alignItems: 'center', }}>
                          <img
                            src={require('../../assests/images/giftHeaderDropDownIcon.png')}
                            alt="Retail Icon"
                            style={{ width: '21px', height: '21px', marginRight: '4px' }}
                          />
                          <Typography style={{ fontSize: '17px', marginTop: '2px', color: theme.palette.text.secondary, }}>Gift</Typography>
                        </Box></MenuItem>
                    }
                    <MenuItem onClick={() => { navigate("/rides/home") }} value="Rides"><Box style={{ display: 'flex', alignItems: 'center', }}>
                      <img
                        src={require('../../assests/images/ridesHeaderDropDownIcon.png')}
                        alt="Retail Icon"
                        style={{ width: '21px', height: '21px', marginRight: '4px' }}
                      />
                      <Typography style={{ fontSize: '17px', marginTop: '2px', color: theme.palette.text.secondary, }}>Rides</Typography>
                    </Box></MenuItem>
                  </Select>
                </Box>
              ) : null
            }

          </Box>

          <Box sx={{ width: { xs: "60%", md: "70%" }, display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }} >


            {/* search */}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
              {winSize.screenSize.width > 220 && searchOpen ? (
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '20px' }}>

                  <Autocomplete
                    value={value}
                    onChange={handleOptionSelect}

                    size="small"
                    variant="outlined"
                    options={searchResult?.searchResults || []}
                    getOptionLabel={(option) => option.name}
                    sx={{
                      "&:hover .MuiInputLabel-outlined": {
                        color: theme.palette.input.borderColor
                      },
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.input.borderColor
                      },
                      "& .MuiOutlinedInput-input": {
                        color: theme.palette.text.primary
                      },
                      width: { md: "230px", xs: "170px" },
                      maxWidth: "100%",
                      '.MuiAutocomplete-paper::-webkit-scrollbar': {
                        display: 'none',
                      },
                      '.MuiAutocomplete-paper': {
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                      },
                      overflowY: "hidden"
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: '200px',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                          display: 'none !important',
                        },
                        msOverflowStyle: 'none !important',
                        scrollbarWidth: 'none !important',
                      },
                    }}
                    renderOption={(props, option) => {
                      if (option?.business_type === "RETAIL" || option?.business_type === "MFH") {
                        return (
                          <Box
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 }, display: "flex", width: "100%", justifyContent: "space-between", flexWrap: "wrap" }} {...props}
                          >
                            <Typography style={{ width: "80%", fontWeight: "bold" }}>{option?.name}</Typography>
                            <img
                              loading="lazy"
                              width="20"
                              src={option?.image_path}
                              alt=""
                            />
                            <Typography style={{ width: "80%" }}>{option?.type}</Typography>
                          </Box>
                        );
                      }
                      return null;
                    }}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Search for products..."
                        onChange={(e) => {
                          dispatch(fetchSearchResults({ name: e.target.value }));
                        }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end">
                              <img
                                src={require(`../../assests/images/search.png`)}
                                alt="Search"
                                style={{ height: 40, width: 40, marginRight: -38.5, borderTopRightRadius: '4px', borderBottomRightRadius: '4px', cursor: 'pointer' }}
                                onClick={() => setSearchOpen(false)}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                </Box>
              ) :
                (
                  <IconButton onClick={() => {
                    if (locationOpen) {
                      setLocationOpen(false);
                    }
                    setSearchOpen(true);
                  }}>
                    <img src={require(`../../assests/images/search.png`)} alt="Search" style={{ height: 40, width: 40, borderRadius: "50%" }} />
                  </IconButton>
                )
              }

              {/* links */}
              {winSize.screenSize.width > 800 &&
                <Link
                  sx={{
                    marginLeft: '30px',
                    textDecoration: 'none',
                    color: theme.palette.text.secondary,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    marginRight: '20px',
                    '&:hover': {
                      color: theme.palette.primary.main
                    }
                  }}
                  href="/retail/shop"
                >
                  Shops
                </Link>}

              {winSize.screenSize.width > 800 &&
                <Link href="/retail/offers/coupons"
                  sx={{
                    textDecoration: 'none',
                    color: theme.palette.text.secondary,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    marginRight: '20px',
                    '&:hover': {
                      color: theme.palette.primary.main
                    }
                  }}
                >
                  Offers
                </Link>}

              {winSize.screenSize.width > 900 &&
                <Link href='/accounts/contactus'
                  sx={{
                    textDecoration: 'none',
                    color: theme.palette.text.secondary,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    marginRight: '20px',
                    '&:hover': {
                      color: theme.palette.primary.main
                    }
                  }}
                >
                  Contact

                </Link>}

              {winSize.screenSize.width > 260 &&
                <Box>
                  <HtmlTooltip

                    title={
                      <React.Fragment>
                        <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }} >
                          {[{ title: " Support Tickets", link: "/accounts/tickets", visible: auth.isAuthenticated === null ? true : false },
                          { title: "shops", link: "/shop", visible: false },
                          { title: "Notifications", link: "/accounts/Notification", visible: auth.isAuthenticated === null ? true : false },
                          { title: "News&Events", link: "/accounts/newsandevents", visible: false },
                          { title: "Review&Rating", link: "/accounts/reviewsandrating", visible: auth.isAuthenticated === null ? true : false },
                          { title: "Faqs", link: "/accounts/faqs", visible: false },
                          { title: "Terms&condition", link: "/accounts/terms&conditions", visible: false },
                          { title: "PrivacyPolicy", link: "/accounts/privacypolicy", visible: false },
                          { title: "Contactus", link: "/accounts/contactus", visible: false },
                          { title: "DeleteAccount", link: "/accounts/deleteaccount", visible: auth.isAuthenticated === null ? true : false },
                          { title: "Cart", link: "/accounts/cart", visible: auth.isAuthenticated === null ? true : false },
                          { title: "Refer&Earn", link: "/accounts/refer&earn", visible: auth.isAuthenticated === null ? true : false },
                          { title: "Wallet", link: "/accounts/wallet", visible: auth.isAuthenticated === null ? true : false },
                          { title: "Orders", link: "/accounts/orders", visible: auth.isAuthenticated === null ? true : false },
                          { title: "WishList", link: "/accounts/wishlist", visible: auth.isAuthenticated === null ? true : false }
                          ]
                            .map((element, index) => {
                              if (!element.visible)
                                return (
                                  <Typography
                                    key={index}
                                    sx={{
                                      marginBottom: "20px",
                                      cursor: "pointer",
                                      transition: "color 0.3s, margin-left 0.1s, font-weight 0.3s",

                                      '&:hover': {
                                        color: theme.palette.primary.main,
                                        marginLeft: "4px"
                                      }
                                    }}
                                    onClick={() => { navigate(element.link) }}
                                  >
                                    {element.title}
                                  </Typography>
                                )
                            }
                            )
                          }
                        </Box>
                      </React.Fragment>
                    }
                  >
                    {winSize.screenSize.width > 800 &&
                      <Link
                        sx={{
                          textDecoration: 'none',
                          marginRight: "30px",
                          color: theme.palette.text.secondary,
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          '&:hover': {
                            color: theme.palette.primary.main
                          }
                        }}

                      >
                        Pages
                        <img src={require(`../../assests/images/downarrow.png`)} alt="Pages" style={{ width: "13px", }} />

                      </Link>}

                  </HtmlTooltip>
                </Box>

              }
            </Box>

            {/* {winSize.screenSize.width > 120 && (
              <Box className="retail" sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: "wrap",
                // mr: '30px',


                "&:hover .MuiInputLabel-outlined": {
                  color: theme.palette.input.borderColor
                },
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.input.borderColor
                },
                "& .MuiOutlinedInput-input": {
                  color: theme.palette.text.primary
                },
              }}>
                {locationOpen ? (
                  <Autocomplete
                    size="small"
                    options={[...locations, { title: "Add New Address", link: "true" }] || []}
                    // value={locationSelected}
                    onChange={(e, value) => {
                      setLocationSelected(value)
                    }}
                    getOptionLabel={(option) => option?.link ? `${option?.title}` : `${option?.city} - ${option?.street || ""}- ${option?.pincode || ""}`}
                    sx={{
                      width: { md: '210px', xs: "180px" },
                      '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                        color: theme.palette.primary.main,
                        padding: 0
                      },

                    }}
                    value={locationSelected}
                    renderOption={(props, option) => {
                      if (option?.link)
                        return (
                          <Link href="/accounts/address" {...props} sx={{ textDecoration: "none", }} >{option?.title}</Link>
                        )
                      else
                        return (
                          <Box {...props}>
                            {`${option?.city} - ${option?.street || ""}- ${option?.pincode || ""}`}
                          </Box>
                        )
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ padding: 0 }}
                        placeholder="Search Location"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end" sx={{ padding: 0, width: "0px", marginRight: locationSelected.link ? "-25px" : "-25px" }} >
                              <img
                                src={require(`../../assests/images/location.png`)}
                                alt="Search"
                                style={{ height: 40, width: 40, borderTopRightRadius: '4px', borderBottomRightRadius: '4px', cursor: 'pointer' }}
                                onClick={() => setLocationOpen(false)}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                ) : (
                  <IconButton onClick={() => {
                    if (searchOpen) {
                      setSearchOpen(false);
                    }
                    setLocationOpen(true);
                  }}>
                    <img src={require(`../../assests/images/location.png`)} alt="Search" style={{ height: '40px', width: '40px', borderRadius: "50px", objectFit: "contain" }} />
                  </IconButton>
                )}
              </Box>
            )} */}

            {winSize.screenSize.width > 850 && (
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    color: 'white',
                    marginLeft: '20px',
                    '&:hover': {
                      bgcolor: theme.palette.primary.main,
                      color: 'white'
                    }
                  }}
                >
                  Become a Seller
                </Button>
              </Box>)}

            {/* Icons */}
            {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
              <ProfileDropdown isAuthenticated={isAuthenticated} authData={authData} setPageModalOpen={setPageModalOpen} />
              <Menu
                sx={{ mt: '45px' }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box> */}
          </Box>
        </Toolbar>
      </Box >
    </AppBar >
  );
}

export default Headerauth;





