import { Autocomplete, Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPartnersAction } from '../../../../store/slices/retail/home/ProductFiltersSlice'
import { AllProductsActions } from '../../../../store/slices/retail/home/AllProductsSlice'
import { useLocation } from 'react-router-dom'
import { GlobalContext } from '../../../../Context'
const CustomizedSelect = () => {
    const dispatch = useDispatch()
    const partners = useSelector(state => state.productFilter.partners)
    const { loading } = useSelector((state) => state.allProducts);
    const [selected, setSelected] = useState([]);
    const pathname = useLocation().pathname;
    const { searchParams, setSearchParams, updateQueryParam, deleteQueryParam } = useContext(GlobalContext);

    useEffect(() => {
        const Partner_Details_Id = searchParams.get("Partner_Details_Id") ? searchParams.get("Partner_Details_Id").split(",") : []
        if (Partner_Details_Id.length > 0) {
            let notFound = true;
            const selectedPartners = [];
            for (let i = 0; i < partners.length; i++) {
                for (let j = 0; j < Partner_Details_Id.length; j++) {
                    if (partners[i].id == Partner_Details_Id[j]) {
                        selectedPartners.push(partners[i])
                        notFound = false;
                        break;
                    }
                }
            }
            setSelected(selectedPartners)

            if (notFound) {
                setSelected([]);
            }
        } else {
            setSelected([]);
        }

    }, [searchParams, partners]);

    useEffect(() => {
        dispatch(getPartnersAction(
            {
                formData: { "Business_Type": pathname.includes("retail") ? "RETAIL" : "GROCERY" },
                pagination: { PageNo: 1, PageSize: 1000, SortBy: "NAME", SortOrder: "ASC" }
            }
        ))
    }, [])

    return (
        <Box sx={{ width: "100%", margin: "10px 0px" }}>
            <Autocomplete
                disablePortal
                key={"partnerFilter" + selected.length}
                options={partners}
                multiple
                limitTags={1}
                sx={{
                    "& fieldset": {
                        borderRadius: "12px",

                    },
                    '.MuiAutocomplete-inputRoot': {
                        flexWrap: 'nowrap !important', overflow: "hidden"
                    }
                }}
                isOptionEqualToValue={(option, value) => {
                    return option.id === value.id
                }}
                value={selected}
                defaultValue={selected}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => <TextField
                    {...params}
                    label={"Search Partner"} />}
                renderOption={(props, option) => (
                    <FormControlLabel {...props} key={option.id} sx={{ width: "98%", }} control={<Checkbox checked={props["aria-selected"]
                    } />} label={option.title} />
                )}
                onChange={(event, newValue) => {
                    if (newValue.length > 0)
                        updateQueryParam([{ Partner_Details_Id: newValue.map((item) => item.id).toString() }])
                    else
                        deleteQueryParam('Partner_Details_Id')
                }}
            />
        </Box>


    )

}
export default CustomizedSelect 
