import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchCoupons } from "../../../store/slices/retail/home/CouponSlice";
import CouponsPageCard from "../../components/cards/CouponsPageCard";
import PN from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { GlobalContext } from "../../../Context";
import { useTheme } from "@emotion/react";
import noCoupons from '../../../assests/images/NoCoupons.png'
import useScreenSize from "../../../utils/ScreenWidthHight";

const AllCoupons = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { coupons, Pagination, loading } = useSelector((state) => state.coupons || []);
    // const { coupons, Pagination, loading } = useSelector((state) => ({
    //     coupons: [], 
    //     Pagination: {}, 
    //     loading: false,
    // }));
    const theme = useTheme();
    const { pathname } = useLocation();
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const winsize = useScreenSize()

    const getCategoryText = () => {
        if (selectedCategories.length === 0) {
            return "COUPONS";
        }
        return selectedCategories.join(", ");
    };

    useEffect(() => {
        if (searchParams.get("PageNo")) {
            const status = searchParams.get("status");
            const statusArray = status ? status.split(",") : [];
            let formData = { 'Business_Type': 'RETAIL', Verified: 1, Status: 'Active' };
            const categories = [];

            if (statusArray.includes("topCoupon")) {
                formData.Is_Top_Coupon = 1;
                categories.push("Top Coupons");
            }
            if (statusArray.includes("exclusive")) {
                formData.Exclusive = 1;
                categories.push("Exclusive Coupons");
            }
            if (statusArray.includes("hotDeals")) {
                formData.Whats_Hot = 1;
                categories.push("Hot Coupons");
            }
            if (statusArray.includes("upcoming")) {
                formData.Is_Upcoming = 1;
                categories.push("Upcoming Coupons");
            }
            if (statusArray.includes("topBrand")) {
                formData.Is_Top_Brand = 1;
                categories.push("Top Brand Coupons");
            }
            setSelectedCategories(categories);

            dispatch(fetchCoupons({
                formData: formData,
                Pagination: { PageSize: 18, PageNo: searchParams.get("PageNo") }
            }));
        } else {
            {
                let querys = "";
                searchParams.forEach((value, key) => {
                    querys += "&" + key + "=" + value
                });
                navigate('/retail/offers/coupons?PageNo=1' + querys, { replace: true });
            }
        }
    }, [pathname, searchParams]);

    const handleChangePage = (event, value) => {
        updateQueryParam([{ "PageNo": value }])
    };
    if (winsize.screenSize.width > 600)
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <Box>
                        <>
                            <Box sx={{ display: "flex", marginBottom: '10px', marginTop: { md: "30px", sm: '30px', xs: '0px' } }}>
                                <Typography sx={{ color: theme.palette.text.secondary, fontSize: '22px', display: { xs: "none", sm: "contents" } }}>
                                    {getCategoryText()}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: "flex",
                                width: "100%",
                                flexWrap: "wrap",
                                columnGap: '10px',
                                rowGap: '10px',
                                marginTop: "30px",
                                ml: { xs: '0px', sm: '0px', md: '0px' },
                                ...(winsize.screenSize.width < 600 ? { justifyContent: "center", } : {})
                            }}>
                                {coupons.length === 0 && !loading && (
                                    <img src={noCoupons} alt="No_Coupons_Found" style={{ display: "block", width: '50%', height: '50%', margin: 'auto', objectFit: 'contain' }} ></img>
                                )}
                                {!loading && coupons
                                    .filter(coupon => coupon.Status === 'Active' && coupon.Verified === 1)
                                    .map((coupon, index) => (
                                        <CouponsPageCard
                                            key={index}
                                            data={coupon}
                                            discount={coupon.Discount}
                                            discountType={coupon.Discount_Type}
                                            imagePath={coupon.Image_Path}
                                            name={coupon.Name}
                                            validateWithDate={coupon.Valid_Through_Date}
                                            esiCashBack={coupon.ESI_Cashback_Amount}
                                            Discount_Label={coupon.Discount_Label}
                                            onClick={() => { navigate(`/retail/offers/coupons/${coupon.Coupon_Id}`) }}
                                        />
                                    ))}

                                {loading && Array(12).fill(1).map((coupon, index) => (
                                    <CouponsPageCard
                                        key={index}
                                        isSkeleton
                                    />
                                ))}

                            </Box>
                            {(!loading && Pagination) && Pagination.TotalPages > 1 && <Box sx={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
                                <Stack spacing={2}>
                                    <PN
                                        count={Pagination.TotalPages}
                                        page={Pagination.PageNo}
                                        onChange={handleChangePage}
                                        color="primary"
                                    />
                                </Stack>
                            </Box>}
                        </>
                    </Box>
                </Grid>
            </Grid>
        );
    else
        return (
            <Box sx={{width:"100%",display:"flex",justifyContent:"center",flexDirection:"column"}} >
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    rowGap: '10px',
                    marginTop: "30px",
                    ...(winsize.screenSize.width < 600 ? { justifyContent: "center", } : {})
                }}>
                    {coupons.length === 0 && !loading && (
                        <img src={noCoupons} alt="No_Coupons_Found" style={{ display: "block", width: '50%', height: '50%', margin: 'auto', objectFit: 'contain' }} ></img>
                    )}
                    {!loading && coupons
                        .filter(coupon => coupon.Status === 'Active' && coupon.Verified === 1)
                        .map((coupon, index) => (
                            <CouponsPageCard
                                key={index}
                                data={coupon}
                                discount={coupon.Discount}
                                discountType={coupon.Discount_Type}
                                imagePath={coupon.Image_Path}
                                name={coupon.Name}
                                validateWithDate={coupon.Valid_Through_Date}
                                esiCashBack={coupon.ESI_Cashback_Amount}
                                Discount_Label={coupon.Discount_Label}
                                onClick={() => { navigate(`/retail/offers/coupons/${coupon.Coupon_Id}`) }}
                            />
                        ))}

                    {loading && Array(12).fill(1).map((coupon, index) => (
                        <CouponsPageCard
                            key={index}
                            isSkeleton
                        />
                    ))}

                </Box>
                {(!loading && Pagination) && Pagination.TotalPages > 1 && <Box sx={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
                    <Stack spacing={2}>
                        <PN
                            count={Pagination.TotalPages}
                            page={Pagination.PageNo}
                            onChange={handleChangePage}
                            color="primary"
                        />
                    </Stack>
                </Box>}
            </Box>
        );
};

export default AllCoupons;
