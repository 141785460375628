import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFurniture } from "../../../store/slices/retail/home/FurnitureSlice";
import FurnitureCard from "../../components/cards/FurnitureCard";
import { Container, Typography, Link, Box, Skeleton } from "@mui/material";
import { resourcePathWithPaylaodCall } from '../../../store/slices/AuthSlice';
import { isEmpty } from "../../../utils";
import { useTheme } from "@emotion/react";

const FurnitureBar = ({ input }) => {
  const item1 = input[0]; // Assuming input is an array and you need the first item.
  const dispatch = useDispatch();
  const theme = useTheme();
  const [furnitureInput, setFurnitureInput] = useState([]);

  const { furniture, loading, error } = useSelector((state) => state.furniture);

  const apiCall = async (url, input, stateFunction) => {
    const res = await resourcePathWithPaylaodCall(url, input);
    if (res.status) {
      stateFunction(res.data);
    }
  };

  useEffect(() => {
    if (!isEmpty(item1)) apiCall(item1.resourceURL, item1.requestPayload, setFurnitureInput);
  }, [item1]);

  useEffect(() => {
    dispatch(fetchFurniture());
  }, [dispatch]);

  return (
    <>
      {input.length > 0 ? (
        <Box
          sx={{
            width: "100%",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
            marginTop: '20px',
            mb: '3%'
          }}
        >
          <Box
            maxWidth="xl"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "24px",
              paddingLeft: "24px",
              mt: '30px'
            }}
          >
            <Typography sx={{ fontSize: "19px", fontWeight: 'bold' }} >
              {item1.title}
            </Typography>

          </Box>
          <Box
            sx={{
              width: "100%",
              overflowX: "auto",
              scrollbarWidth: "none",
              " &::-webkit-scrollbar": {
                display: "none",
              },
              display: "flex",
              gap: "10px",
              padding: "10px",
            }}
          >
            {error && (
              <Typography>
                Error: {error}
              </Typography>
            )}
            {!loading && !error && (
              <FurnitureCard
                input={item1}
                title={item1.title}
                data={furnitureInput.slice(0, 6)}
              />
            )}
          </Box>
          <Box sx={{width:"100%",display:"flex",justifyContent:"flex-end"}} >
            {!isEmpty(item1.viewMoreLink) && (
              <Link href={item1.viewMoreLink} sx={{ color: theme.palette.primary.main, cursor: 'pointer', fontWeight: 'bold' }} underline="hover">
                View More
              </Link>
            )}
          </Box>
        </Box>
      )
        :
        <Box
          sx={{
            width: "100%",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
            marginTop: '20px',
            mb: '3%'
          }}
        >
          <Box
            maxWidth="xl"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "24px",
              paddingLeft: "24px",
              mt: '30px'
            }}
          >
            <Skeleton variant="text" />

          </Box>
          <Box
            sx={{
              width: "100%",
              overflowX: "auto",
              scrollbarWidth: "none",
              " &::-webkit-scrollbar": {
                display: "none",
              },
              display: "flex",
              gap: "10px",
              padding: "10px",
            }}
          >

            <FurnitureCard
              isSkeleton
            />
          </Box>
        </Box>
      }
    </>
  );
};

export default FurnitureBar;
