import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi } from '../../CommonAxios';

const initialState = {
    homekitchen: [],
    loading: false,
    error: null
}

export const fetchBestSellers = createAsyncThunk("homeKitchen/fetchBestSellers", async () => {
    try {
        const response = await nodeApi.post("/Home/Banner/Get", {});
        const data = response?.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            return [];
        } else {
            return data.results;
        }
    } catch (error) {
        throw error; 
    }
});

const bestSellerSlice = createSlice({
    name: 'bestSellers',
    initialState,
    reducers: {
        
    },
    extraReducers(builder) {
        builder
            .addCase(fetchBestSellers.pending, (state, action) => {
                state.loading = true;
                state.error = null; 
            })
            .addCase(fetchBestSellers.fulfilled, (state, action) => {
                state.loading = false;
                state.homekitchen = action.payload;
            })
            .addCase(fetchBestSellers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; 
            })
    }
});

export default bestSellerSlice.reducer;
