import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from '@mui/material/Pagination';
import { Box, Typography } from "@mui/material";
import OrdersCard from "../components/OrderCard";
import { productSearch } from "../../../../store/slices/accounts/orders/OrdersSlice";
import { useTheme } from "@emotion/react";
import OrdersSideBar from '../components/OrdersSideBar'
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../Context";
import noOrders from '../../../../assests/images/noOrders.png'
import { margin } from "@mui/system";
import useScreenSize from "../../../../utils/ScreenWidthHight";
const Orders = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const userDetails = useSelector(state => state.auth);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const itemsPerPage = 6;
    const [selectedCheckbox, setSelectedCheckbox] = useState('retail');
    const { pathname } = useLocation();
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);
    const navigate = useNavigate()
    const winSize = useScreenSize()
    const handleCheckboxChange = (event) => {
        setSelectedCheckbox(event.target.value);
    };

    useEffect(() => {
        if (searchParams.get("PageNo")) {
            if (userDetails?.user?.Email_Id) {
                productSearchCall({ User_Email_Id: userDetails?.user?.Email_Id, Search: searchQuery, "Business_Type": "RETAIL" }, { PageSize: 6, PageNo: searchParams.get("PageNo"), SortBy: "Ordered_Date", SortOrder: "DESC" })
            }
        }
        else {
            {
                let querys = "";
                searchParams.forEach((value, key) => {
                    querys += "&" + key + "=" + value
                });
                navigate('/accounts/orders?PageNo=1' + querys, { replace: true });
            }
        }
    }, [userDetails, searchQuery, pathname, searchParams]);


    const productSearchCall = async (formData, pagination) => {
        setLoading(true)
        const response = await productSearch(formData, pagination)
        if (response.status) {
            setOrders(response.data)
            setTotalPages(response.pagination.TotalPages)
            setCurrentPage(response.pagination.PageNo);
            setLoading(false)

        }
        else {
            setOrders([])
            setLoading(false)
        }
    }

    const handleChangePage = (event, newPage) => {
        updateQueryParam([{ PageNo: newPage }])
    };


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        updateQueryParam([{ "PageNo": 1 }])
    };


    if (orders.length > 0 || loading)
        return (
            <Box sx={{width:"100%",display:"flex",justifyContent:"center"}} >
            <Box sx={{ width: "95%", height: "100%", display: "flex", flexDirection: { xs: "column", sm: "column", md: "row" }, }} >
                {winSize.screenSize.width > 600 ?
                    <OrdersSideBar
                        theme={theme}
                        selectedCheckbox={selectedCheckbox}
                        handleCheckboxChange={handleCheckboxChange}
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    : <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                        <OrdersSideBar
                            theme={theme}
                            selectedCheckbox={selectedCheckbox}
                            handleCheckboxChange={handleCheckboxChange}
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </Box>}
                {
                    <Box sx={{ width: { md: "70%", xs: "100%" } }} >


                        <Box display="flex" flexWrap="wrap" sx={{ marginTop: { xs: "0px", sm: "40px" }, justifyContent: { xs: "center", sm: "space-between" },rowGap:{xs:"20px",sm:"unset"} }}>
                            {(!loading && orders.length > 0) && orders.map((order, index) => (

                                <OrdersCard
                                    key={index}
                                    Product_Name={order.Product_Name}
                                    Partner_Name={order.Partner_Name}
                                    Order_Id={order.Order_Id}
                                    logoPath={order.Product_Image}
                                    Order_Date={order.Ordered_Date}
                                    Delivered_Date={order.Delivered_Date}
                                    Expected_Delivery_Date_Time={order.Excpected_Delivery_Date_Time}
                                    Order_Status={order.Line_Item_Order_Status}
                                    Item_Master_Id={order.Item_Master_Id}
                                />

                            ))
                            }
                            {
                                loading &&
                                Array(6).fill(1).map((item, index) => (
                                    <OrdersCard
                                        key={index}
                                        isSkeleton
                                    />
                                ))
                            }
                        </Box>

                        {(totalPages > 1 && !loading) && <Box display="flex" justifyContent="center" marginTop={2}>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handleChangePage}
                                color="primary"
                            />
                        </Box>}
                    </Box>


                }
                {(orders.length === 0 && !loading) && (
                    <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' } }}>
                        {/* <img src={noOrders} alt="noOrders" style={{ width: '100%', height: '100%' }} /> */}
                        <img src={noOrders} style={{ marginTop: '20px', width: '100%', display: 'block', margin: '20px auto' }} />
                    </Box>
                )}
                </Box>
                </Box>

        );
    else {
        return (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
            <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" } }} >
                <OrdersSideBar
                    theme={theme}
                    selectedCheckbox={selectedCheckbox}
                    handleCheckboxChange={handleCheckboxChange}
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
                {(orders.length === 0 && !loading) && (
                    <Box sx={{ display: 'flex', height: "100%", width: { md: "70%", sm: "100%", xs: "100%" } }}>
                        {/* <img src={noOrders} alt="noOrders" style={{ width: '100%', height: '100%' }} /> */}
                        <img src={noOrders} style={{ width: '100%', height: "80vh", display: 'block', margin: '20px auto', objectFit: "contain" }} />
                    </Box>
                )}
            </Box >
             </Box >
        )
    }
};

export default Orders;

