import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, TextField, InputAdornment, Stack, Pagination, Skeleton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { subCategoriesGetAction } from '../../../store/slices/retail/home/CategorySlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import noDataFound from '../../../assests/images/NoProductsInSubCategory.png'
import { GlobalContext } from '../../../Context'
import Category from '../../components/retail/cateagories_pages/CategoryMain_page';
import useScreenSize from '../../../utils/ScreenWidthHight';

const SubCategory = () => {
    const { id } = useParams();
    const [subCategories, setSubCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()
    const [loading, setLoading] = useState(false)
    const [error, seterror] = useState('')
    const [pageNo, setPageNo] = useState(1)
    const [totalCount, setTotalCount] = useState(1)
    const [oldPage, setOldPage] = useState(1)
    const { pathname } = useLocation();
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);
    const winsize = useScreenSize()

    useEffect(() => {
        if (searchParams.get("PageNo"))
            subCategoriesGetActionCall({ Category_Id: id.toString(), Search_Sub_Category_Name: searchTerm }, { PageSize: 8, PageNo: searchParams.get("PageNo") });
        else {
            let querys = "";
            searchParams.forEach((value, key) => {
                querys += "&" + key + "=" + value
            });
            navigate('/retail/subcategory/' + id.toString() + '?PageNo=1' + querys, { replace: true });
        }
    }, [id, searchTerm, pathname, searchParams]);

    const handleChangePage = (event, value) => {
        if (searchParams.get("PageNo") !== value.toString())
            updateQueryParam([{ "PageNo": value }])
    };

    const subCategoriesGetActionCall = async (formData, Pagination) => {
        setLoading(true)
        const response = await subCategoriesGetAction(formData, Pagination);
        if (response.status) {
            setSubCategories(response.data.map((retailSubCategory, index) => ({
                label: retailSubCategory.Sub_Category_Name,
                image: retailSubCategory.Sub_Category_Image_Web,
                Sub_Category_Id: retailSubCategory.Sub_Category_Id,
                Category_Id: retailSubCategory.Category_Id
            })));
            setTotalCount(response.Pagination.TotalPages)
            setPageNo(response.Pagination.PageNo)
            seterror('')
            setLoading(false)
        } else {
            setSubCategories([])
            seterror('No Data found')
            setLoading(false)
        }
    }

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: { md: "space-between" }, flexDirection: { xs: 'column', md: 'row' } }}>
                <Typography sx={{ marginTop: "24px", marginLeft: { md: "34px", xs: '25px' }, marginRight: "30px", }}>Sub Categories</Typography>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, marginTop: "20px", marginRight: { md: "43px", xs: '0px' }, marginLeft: { md: "34px", xs: '0px' }, ...(winsize.screenSize.width < 600 ? { justifyContent: "center", alignItems: "center" } : {}) }}>
                    <TextField
                        placeholder="Search Sub Category"
                        size={winsize.screenSize.width < 600 ? "medium" : "small"}
                        value={searchTerm}
                        onFocus={() => {
                            if (!searchTerm) {
                                setOldPage(searchParams.get("PageNo"))
                            }
                        }}
                        onChange={(e) => {
                            const value = e.target.value
                            setSearchTerm(e.target.value);
                            if (value) {
                                handleChangePage(e, 1);
                            } else if (oldPage.toString() !== "1") {
                                navigate(-1)
                            }
                        }}
                        sx={{
                            width: { xs: "90%", md: "345px" },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: theme.palette.primary.main, }} />
                                </InputAdornment>
                            ),
                            sx: { borderRadius: { xs: "16px", sm: "unset" } }
                        }}
                    />
                </Box>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "row" }} >
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: { xs: "90%", sm: "100%" },
                        gap: { xs: 0, sm: 2 },
                        justifyContent: { xs: "space-between", sm: "center" },
                        columnGap: { xs: 0, sm: 2 }
                    }}
                >
                    {!loading &&
                        (error.length > 0 ? (
                            <img src={noDataFound} style={{ marginTop: '20px', width: '40%', display: 'block', margin: '20px auto' }} />
                        )
                            :
                            (
                                winsize.screenSize.width < 600
                                    ?
                                    subCategories.length > 0 && (
                                        <>
                                            {subCategories.map((retailSubCategory, index) => (
                                                <Category
                                                    key={index}
                                                    index={index}
                                                    data={retailSubCategory}
                                                    onClick={() => {
                                                        navigate(`/retail/products?Category_Id=${retailSubCategory.Category_Id}&Sub_Category_Id=${retailSubCategory.Sub_Category_Id}`)
                                                    }}
                                                />
                                            ))}
                                            {
                                                (subCategories.length % 2) !== 0 &&
                                                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px", width: { xs: "156px", }, height: { xs: "150px", sm: "unset" }, }} >
                                                </Box>
                                            }
                                        </>
                                    )
                                    :
                                    subCategories.length > 0 && subCategories.map((retailSubCategory, index) => (
                                        <Category
                                            key={index}
                                            index={index}
                                            data={retailSubCategory}
                                            onClick={() => {
                                                navigate(`/retail/products?Category_Id=${retailSubCategory.Category_Id}&Sub_Category_Id=${retailSubCategory.Sub_Category_Id}`)
                                            }}
                                        />
                                    )))
                        )
                    }
                    {
                        loading &&

                        Array(6).fill(1)?.map((retailSubCategory, index) => (
                            <Box key={index} sx={{
                                display: "flex",
                                flexDirection: "column",
                                border: "0px solid " + theme.palette.extra.primary,
                                width: "300px", height: "297px",
                                borderRadius: "20px",
                                marginTop: "20px",
                                "&:hover": {
                                    cursor: "pointer"
                                }
                            }}
                            >
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "0px solid " + theme.palette.extra.primary,
                                    width: "290px", height: "297px",
                                    borderRadius: "20px",
                                    "&:hover": {
                                        cursor: "pointer"
                                    },
                                    overflow: "hidden"
                                }}>

                                    <Skeleton variant='text' />

                                    <Skeleton
                                        variant='rounded'
                                        sx={{
                                            width: "380px",
                                            height: "200px",
                                            maxHeight: "100%",
                                            maxWidth: "100%",
                                            height: "220px",
                                            objectFit: "contain"
                                        }}
                                    />
                                </Box>
                                <Box>
                                </Box>
                            </Box>
                        ))}
                </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
                {(totalCount > 1 && !loading) && (
                    <Stack spacing={2}>
                        <Pagination
                            count={totalCount}
                            page={pageNo}
                            onChange={handleChangePage}
                            color="primary"
                        />
                    </Stack>
                )}
            </Box>
        </Box>
    )
}
export default SubCategory;
