import React, { useEffect } from 'react';
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchProduct } from '../../../store/slices/retail/home/Productslice';
import { useNavigate } from 'react-router-dom';
import ProductGrid from '../../components/cards/coustomersmoreloved/ProductGrid';
import useScreenSize from '../../../utils/ScreenWidthHight';
import { Box } from '@mui/system';

const CustomerMLoved = ({ data, itemData }) => {
    const dispatch = useDispatch();
    const { Product, loading, error } = useSelector(state => state.product);
    const winsize = useScreenSize()
    if (winsize.screenSize.width > 600)
        return (
            <Grid container flexDirection={"row"} justifyContent={"center"} spacing={2}>
                <ProductGrid
                    title={itemData.title}
                    data={data}
                    imgLabel={itemData.imageField}
                    onViewMore={itemData.SeeMore}
                    itemData={itemData}
                    isSkeleton={data.length !== 6}
                />
            </Grid>
        )
    else {
        return (
            <Box flexDirection={"row"} justifyContent={"center"} sx={{paddingX:0}} >
                <ProductGrid
                    title={itemData.title}
                    data={data}
                    imgLabel={itemData.imageField}
                    onViewMore={itemData.SeeMore}
                    itemData={itemData}
                    isSkeleton={data.length !== 6}
                />
            </Box>
        )
    }
}

export default CustomerMLoved;
