import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {Box, TextField, Checkbox, FormControlLabel, InputAdornment, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useLocation } from 'react-router-dom';
import useScreenSize from '../../../utils/ScreenWidthHight';

const WishlistSideBar = ({ theme, selectedCheckbox, handleCheckboxChange, productSearchCall, wishlistCount = 0 }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchQuery, setSearchQuery] = useState("");
    const [wishlist, setWishlist] = useState([])
    const userDetails = useSelector(state => state.auth);
    const winsize = useScreenSize()

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        productSearchCall(e.target.value)
    };

    useEffect(() => {
        if (location.pathname.includes('/accounts/wishlist')) {
            handleCheckboxChange({ target: { value: 'retail' } });
        } else if (location.pathname.includes('/accounts/grocery/wishlist')) {
            handleCheckboxChange({ target: { value: 'grocery' } });
        }
    }, [location, handleCheckboxChange]);

    const handleChange = (event) => {
        setWishlist(0)
        const value = event.target.value;
        handleCheckboxChange(event);
        if (value === 'retail') {
            navigate('/accounts/wishlist');
        } else if (value === 'grocery') {
            navigate('/accounts/grocery/wishlist');
        }
    };

    useEffect(() => {
        setWishlist(wishlistCount)
    }, [wishlistCount])


    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: { md: "column", sm: "row", xs: "column" }, alignItems: "flex-start" }} >
            <Typography sx={{ fontSize: { md: '24px', sm: '24px', xs: '20px' }, width: "96%", color: theme.palette.text.secondary, display: { xs: "contents", sm: "none" }, }}>
                Wishlist ({wishlist || 0})
            </Typography>
            {winsize.screenSize.width <= 600 &&
                <Box sx={{ display: 'flex', flexDirection: { md: 'column', xs: "row", sm: "row" }, justifyContent: 'space-between', width: { md: "96%", sm: "50%", xs: "90%" } }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedCheckbox === 'retail'}
                                onChange={handleChange}
                                value="retail"
                            />
                        }
                        label="RETAIL"
                        style={{
                            marginTop: { md: '10px', xs: 'unset' },
                            color: selectedCheckbox === 'retail' ? theme.palette.primary.main : 'inherit',
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedCheckbox === 'grocery'}
                                onChange={handleChange}
                                value="grocery"
                            />
                        }
                        label="GROCERY"
                        style={{
                            marginTop: { md: '10px', xs: 'unset' },
                            color: selectedCheckbox === 'grocery' ? theme.palette.primary.main : 'inherit',
                        }}
                    />
                </Box>}
            <Box sx={{ display: 'flex', alignItems: 'center', width: { md: "100%", sm: "50%", xs: "93%" }, marginBottom: '15px', }}>

                <TextField sx={{ width: { md: '96%', sm: '96%', xs: '100%' }, }}
                    fullWidth
                    placeholder="Search Product"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: theme.palette.primary.main, }} />
                            </InputAdornment>
                        ),
                        sx: () => ({ borderRadius: "16px" })
                    }}
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </Box>
            {winsize.screenSize.width > 600 &&
                <Box sx={{ display: 'flex', flexDirection: { md: 'column', xs: "row", sm: "row" }, width: { md: "96%", sm: "50%", xs: "100%" }, }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedCheckbox === 'retail'}
                                onChange={handleChange}
                                value="retail"
                            />
                        }
                        label="RETAIL"
                        style={{
                            marginTop: '10px',
                            color: selectedCheckbox === 'retail' ? theme.palette.primary.main : 'inherit',
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedCheckbox === 'grocery'}
                                onChange={handleChange}
                                value="grocery"
                            />
                        }
                        label="GROCERY"
                        style={{
                            marginTop: '10px',
                            color: selectedCheckbox === 'grocery' ? theme.palette.primary.main : 'inherit',
                        }}
                    />
                </Box>}
        </Box>
    );
};

export default WishlistSideBar;
