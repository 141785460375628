import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi } from '../../CommonAxios'; 

const initialState = {
  customerLovedFashion: [],
  loading: false,
  error: null,
};

export const fetchCustomerLovedFashion = createAsyncThunk(
  'customerLoved/fetchCustomerLovedFashion',
  async () => {
    try {
      const response = await nodeApi.post('/Home/Banner/Get', {}); 
      return response.data.results; 
    } catch (error) {
      throw error;
    }
  }
);

const customerLovedSlice = createSlice({
  name: 'customerLoved',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerLovedFashion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerLovedFashion.fulfilled, (state, action) => {
        state.loading = false;
        state.customerLovedFashion = action.payload; 
      })
      .addCase(fetchCustomerLovedFashion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default customerLovedSlice.reducer;
