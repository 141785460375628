import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { nodeApi, nodeCudApi } from '../../CommonAxios'

const initialState = {
    partnerReviews: [],
    loading: false,
    error: ""
}



export const partnerReviewsFetch = createAsyncThunk("partners/partnerReviews", async (formData) => {
    const response = await nodeApi.post("/Reviews/Partner_Review_Get", formData)
    const data = response?.data
    if (data.Success_Response.Is_Data_Exist == "0") {
        return []
    } else {
        return data.results
    }
})

export const partnerReviewsFetchById = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post('/Reviews/Partner_Review_Get', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}




export const partnerReviewCreate = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post('/Reviews/Partner_Review_Create', formData).then((res) => {
            if (res.data.Response_Status === "Success") {
                resolve({ status: true })
            }
            else
                resolve({ status: false })
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}


export const partnerReviewUpdate = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post('/Reviews/Partner_Review_Update', formData).then((res) => {
            if (res.data.Response_Status === "Success") {
                resolve({ status: true })
            }
            else
                resolve({ status: false })
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}




const partnerReviewSlice = createSlice({
    name: 'parnterReview',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(partnerReviewsFetch.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(partnerReviewsFetch.fulfilled, (state, action) => {
                state.loading = false
                state.partnerReviews = action.payload;
            })
            .addCase(partnerReviewsFetch.rejected, (state, action) => {
                state.loading = false
                state.error = [];
                state.error = action.error;
            })
    }
})

export default partnerReviewSlice.reducer;