import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPartners } from "../../../store/slices/retail/home/PartnerSlice";
import PartnerCard from "../../components/cards/PartnerCard";
import { Typography, Box, Skeleton } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../../../utils/ScreenWidthHight";

const PartnerSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { partners } = useSelector((state) => state.homePartners);
    const winSize = useScreenSize();

    useEffect(() => {
        dispatch(getPartners({ Business_Type: 'RETAIL' }));
    }, [dispatch]);

    return (
        <Box sx={{ overflow: "hidden" }} >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginTop: '10px', rowGap: "5px" }}>
                <Typography variant="body1" sx={{ fontSize: '24px', fontWeight: 'bold', color: theme.palette.text.secondary, /* marginBottom: '1%' */ }}>
                    Partner
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: 'bold',
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        "&:hover": {
                            textDecoration: 'underline',
                        },
                    }}
                    onClick={() => navigate('/retail/shop')}
                >
                    View More
                </Typography>
            </Box>

            <Box sx={{ width: "100%", display: "flex", flexDirection: { xs: "row", sm: "row", }, flexWrap: { xs: "unset", sm: "wrap" }, justifyContent: "space-between", columnGap: { xs: "10px", sm: "unset" }, overflowX: "scroll", overflowY: "hidden" }} >
                {partners?.length > 0 && partners?.slice(0, 6).map((partner, index) => (
                    <PartnerCard
                        key={index}
                        name={partner.Name}
                        logoPath={partner.Logo_Path}
                        Short_description={partner.Short_Description}
                        rating={partner.Esi_Rating}
                        borderColor={theme.palette.text.secondary}
                        onClick={() => { navigate(`/retail/partner/${partner.Partner_Details_Id}`) }}
                    />
                ))}

                {winSize.screenSize.width > 600 && partners?.length === 0 && [0, 1, 2, 3, 4, 5].map((partner, index) => (
                    <PartnerCard
                        key={index}
                        isSkeleton
                    />
                ))}
                {winSize.screenSize.width < 600 && partners?.length === 0 && [0, 1].map((partner, index) => (
                    <PartnerCard
                        key={index}
                        isSkeleton
                    />
                ))}

            </Box>
        </Box>
    );
};

export default PartnerSection;
