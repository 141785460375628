import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AllProductsActions, fetchAllProducts } from "../../../../store/slices/retail/home/AllProductsSlice";
import AllProductsCard from "../../../components/cards/AllProductsCard";
import { Typography, Box, Pagination } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import noProductsFound from '../../../../assests/images/NoProductFound.png'

const AllProducts = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { products, pagination, filters, loading, error } = useSelector((state) => state.allProducts);
  const [reCall, setReCall] = useState();
  const navigate = useNavigate()
  const auth = useSelector(state => state.auth);
  const location = useLocation();
  const address = useSelector(state => state.commonReducer.Address);
  const containerRef = useRef();
  const [pageLoad, setPageLoad] = useState(false);
  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        setPageLoad(state => !state)
      }
    }
  };

  useEffect(() => {
    if (pagination?.TotalPages > pagination?.PageNo)
      callProductsAction((pagination.PageNo || 0) + 1);
  }, [pageLoad])

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);
  useEffect(() => {
    if (!loading) {
      callProductsAction();
    } else {
      setReCall(true);
    }
  }, [filters, address]);

  useEffect(() => {
    if (!loading && reCall) {
      setReCall(false);
      callProductsAction();
    }
  }, [loading, address]);


  const callProductsAction = (pageno = 1) => {

    const callFilter = {
      Search: filters.Search,
      Business_Type: "GROCERY",
      Brand_Id: filters.Brand_Id.map((item) => item.id),
      Partner_Details_Id: filters.Partner_Details_Id.map((item) => item.id),
      Category_Id: filters.Category_Id.map((item) => item.id),
      Sub_Category_Id: filters.Sub_Category_Id.map((item) => item.id),
      Is_Top_Product: filters.Is_Top_Product,
      Is_Popular: filters.Is_Popular,
      Is_Verified: filters.Is_Verified,
      Whats_Hot: filters.Whats_Hot,
      Is_Upcoming: filters.Is_Upcoming,

    };

    if (filters.Min_Discount > 0 || filters.Max_Discount > 0) {
      callFilter.Min_Discount = filters.Min_Discount
      callFilter.Max_Discount = filters.Max_Discount
    }

    if (filters.Min_Price > 0 || filters.Max_Price > 0) {
      callFilter.Min_Price = filters.Min_Price
      callFilter.Max_Price = filters.Max_Price
    }
    if (address?.City_Id) {
      callFilter.City_Id = address?.City_Id
    }

    dispatch(fetchAllProducts({ filters: callFilter, pagination: { PageNo: pageno, PageSize: 30, SortBy: "Product_Id", SortOrder: "DESC" } }));
  }

  return (
    <Box sx={{
      height: '200vh',
      overflowY: 'auto'
    }}
      ref={containerRef}
    >
      <Box sx={{ width: "100%", height: "13px" }} ></Box>
      <Typography sx={{ fontSize: '24px', fontWeight: 'bold', color: theme.palette.text.secondary, }}>All Products</Typography>
      <Box sx={{ width: "100%", height: "20px" }} ></Box>


      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
        gap={1}
      >
        {(products.length > 0 && !loading) &&

          products.map((item, index) => (
            <AllProductsCard
              key={item.Partner_Product_Id + index}
              image={item.Product_Image}
              brandName={item.Brand_Name}
              productName={item.Product_Name.length > 15 ?
                item.Product_Name.charAt(0).toUpperCase() + item.Product_Name.toLowerCase().substring(1, 20) + '..' :
                item.Product_Name.charAt(0).toUpperCase() + item.Product_Name.toLowerCase().substring(1)}
              shortDescription={item.Product_Short_Description}
              originalPrice={item.Original_Price.toString()}
              partnerSellingPrice={item.Partner_Selling_Price.toString()}
              item={item}
              handleNavigation={() => {
                if (auth?.isAuthenticated !== null) {
                  props.handleSetModalProductId(item.Product_Id)
                  props.handleModalOpen()
                }
                else {
                  navigate('/auth/signin')
                }
              }}
            />
          ))

        }
        {
          loading &&
          Array(10).fill(1).map((item, index) => {
            return (
              <AllProductsCard
                key={index}
                isSkeleton
              />
            )
          })
        }
      </Box>

      {(products.length == 0 && !loading) &&
        <Box sx={{ width: "100%", height: "100vh", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", }} >

          <img src={noProductsFound} style={{  width: '60%', objectFit:'contain',display: 'block', margin: '20px auto' }} />
        </Box>
      }


    </Box>
  );
};

export default AllProducts;