import { Box, Slider, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AllProductsActions } from '../../../../store/slices/retail/home/AllProductsSlice';
import { GlobalContext } from '../../../../Context';
import { number } from 'yup';

const DiscountsFilter = ({ name }) => {
    const [value, setValue] = useState([0, 100]);
    const [marks] = useState([
        {
            value: 0,
            label: '0%',
        },
        {
            value: 100,
            label: '100%',
        },
    ]);

    const { searchParams, setSearchParams, updateQueryParam, deleteQueryParam } = useContext(GlobalContext);
    const dispatch = useDispatch();

    useEffect(() => {
        if (searchParams.get('Min_Discount') || searchParams.get('Max_Discount')) {
            if (Number(searchParams.get('Min_Discount')) > 0 || Number(searchParams.get('Max_Discount')) < 100)
                setValue([Number(searchParams.get('Min_Discount')) > 0 ? Number(searchParams.get('Min_Discount')) : 0, Number(searchParams.get('Max_Discount')) < 100 ? Number(searchParams.get('Max_Discount')):100])
            else
                setValue([0, 100])

        } else {
            setValue([0, 100])
        }
    }, [searchParams])

    const valuetext = (value) => {
        return `${value}%`;
    };

    const handleChange = (event, newValue) => {
        if (newValue[0] === 0 && newValue[1] === 100) {
            updateQueryParam([{ Min_Discount: '' }, { Max_Discount: '' }])
        } else {
            updateQueryParam([{ Min_Discount: newValue[0] }, { Max_Discount: newValue[1] }])
        }
    };

    return (
        <Box sx={{ width: "90%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "20px" }}>
                {/* <Box sx={{ width: 48, height: 48, borderRadius: 100 }}>
                    <img src={require('../../../../assests/images/mee.png')} style={{ width: "100%", height: "100%" }} alt="icon" />
                </Box> */}
                <Typography>{name}</Typography>
            </Box>
            <Box sx={{ width: "80%", alignSelf: "flex-start", }}>
                <Slider
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    onChangeCommitted={valuetext}
                    marks={marks}
                    min={0}
                    max={100}
                    sx={{marginLeft:"20px"}}
                />
            </Box>
        </Box>
    );
}

export default DiscountsFilter