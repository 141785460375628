//searchslice

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi, urlGenarator } from '../../CommonAxios';

export const fetchSearchResults = createAsyncThunk("search/fetchSearchResults",async (formData) => {
    try {
        const response = await nodeApi.post(urlGenarator("/Products/Search",{
          PageNo: 1, PageSize:4
        }),formData);
        const data = response?.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            return [];
        } else {
            return data.results;
        }
    } catch (error) {
        throw error;
    }
});


const initialState = {
  searchResult: [],
  loading: false,
  error: null
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSearchResults.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.loading = false;
        state.searchResults = action.payload;
      })
      .addCase(fetchSearchResults.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default searchSlice.reducer;
