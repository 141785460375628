import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { TextField, useMediaQuery } from '@mui/material';
import useScreenSize from '../../utils/ScreenWidthHight';
import { isEmpty } from '../../utils';

// Styled Popper for Autocomplete
const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        fontWeight: 'bold',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
                backgroundColor: theme.palette.action.hover,
            },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));

// Custom Popper Component for Autocomplete
function PopperComponent(props) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
    anchorEl: PropTypes.any,
    disablePortal: PropTypes.bool,
    open: PropTypes.bool.isRequired,
};

// Styled Popper for custom placement and styling
const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
    boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
    borderRadius: 6,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

export default function StyledPopperComponent({
    anchorEl, setAnchorEl, value, setValue, options, placeholder, getOptionLabel, getTitle, getSubTitle
}) {
    const theme = useTheme();
    const win = useScreenSize();
    const isXs = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const [popUpR, setPopUpR] = useState(false);
    useEffect(() => {
        if (popUpR) {
            handleCloseAfterSelect();
            setPopUpR(false);
        }
    }, [popUpR])

    const handleClose = () => {
        if (!isXs) {
            if (anchorEl) {
                anchorEl.focus();
            }

            setAnchorEl(null);
        }
    };

    const handleCloseAfterSelect = () => {
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };



    const open = Boolean(anchorEl);

    return (
        <StyledPopper
            sx={{ position: "absolute", width: { md: (win.getHalfScreenSize.width) * 0.8, sm: (win.screenSize.width) * 0.8, xs: (win.screenSize.width) * 0.8 } }}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            disableRipple
        >
            <ClickAwayListener key={value?.location} onClickAway={handleClose}>
                <Autocomplete

                    open
                    onClose={(event, reason) => {
                        if (reason === 'escape') {
                            setPopUpR(state => !state);
                        }
                    }}
                    value={isEmpty(value) ? "" : value}
                    onChange={(event, newValue, reason) => {
                        if (
                            event.type === 'keydown' &&
                            (event.key === 'Backspace' || event.key === 'Delete') &&
                            reason === 'removeOption'
                        ) {
                            return;
                        }
                        setValue(newValue);
                        setPopUpR(state => !state);
                    }}
                    disableCloseOnSelect
                    PopperComponent={PopperComponent}
                    renderTags={() => null}
                    noOptionsText="No data found."
                    renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.id} >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    '& span': {
                                        color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                                    },
                                }}
                            >
                                {getTitle(option)}
                                <br />
                                <span>{getSubTitle(option)}</span>
                            </Box>
                        </li>
                    )}
                    options={options}
                    isOptionEqualToValue={(option, value) => {
                        if (!value) {
                            return true
                        }
                        return option.location == value?.location
                    }}
                    getOptionLabel={(option) => option[getOptionLabel] || ""}
                    renderInput={(params) => (
                        <TextField {...params} placeholder={placeholder} />
                    )}
                    clearIcon={null}
                    popupIcon={null}
                />
            </ClickAwayListener>
        </StyledPopper>
    );
}
