import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { brandsFetch } from '../../../store/slices/retail/home/BrandSlice';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { brandReviewByBrandId, brandReviews } from '../../../store/slices/retail/home/BrandReviewSlice';
import BrandReviewPage from '../../components/cards/brandPage/BrandReviewPage';
import ReviewForm from '../../components/cards/brandPage/ReviewForm';
import BrandComments from '../../components/cards/brandPage/BrandComments';
import { RenderProducts } from '../partnerPage/PartnerReview';
import { useTheme } from '@emotion/react';
import { RenderCoupons, RenderDeals } from '../../retail/partnerPage/PartnerReview';

const BrandReview = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { brandsReview } = useSelector(state => state.brandReview);
    const [brandName, setBrandName] = useState('');
    const theme = useTheme();
    const userDetails = useSelector((state) => state.auth);
    const isAuthenticated = userDetails.isAuthenticated;
    const [value, setValue] = useState('one');


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(brandReviews());
    }, []);

    useEffect(() => {
        brandsCall({ Is_Grocery: 1, Brand_Id: [id] }, { PageNo: 1, PageSize: 15 }, { id })
    }, [id])
    const [brands, setBrands] = useState([]);

    const brandsCall = async (formData, Pagination, id) => {
        const response = await brandsFetch(formData, Pagination)
        if (response.status) {
            setBrands(response.data)
            if (response.data.length > 0) {
                setBrandName(response.data[0].Brand_Name);
            }
        }
        else {
        }
    }

    const [brandCommentsGet, setBrandCommentsGet] = useState([]);
    const [yourComments, setYourComments] = useState([])
    const brandCommentsCall = async (id) => {
        try {
            const formData = { Brand_Id: [id] };
            const response = await brandReviewByBrandId(formData);
            if (response.status) {
                setYourComments(response.data.filter(brandReview => brandReview.User_Email_Id === EmailID))
                setBrandCommentsGet(response.data.filter(brandReview => brandReview.User_Email_Id !== EmailID));
            } else {
            }
        } catch (error) {
            // console.error('Error fetching brand comments:', error);
        }
    };

    useEffect(() => {
        brandCommentsCall(id);
    }, [id]);
    const EmailID = useSelector((state) => state.auth.user.Email_Id);

    return (
        <Box sx={{ margin: 3 }}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    {brands.map((review, index) => (
                        <BrandReviewPage
                            key={index}
                            imagePath={review.Brand_Image}
                            imageName={review.Brand_Name}
                            brandName={review.Brand_Name}
                            description={review.Brand_Description}
                            rating={review.Brand_Esi_Rating}
                            date={review.Modified_Date}
                            review={review.Brand_Esi_Reviews}
                        />
                    ))}
                </Grid>

                <Grid item xs={12} md={6} sx={{
                    height: "70vh", overflowY: "scroll", "&::-webkit-scrollbar-thumb": {
                        display: "none",
                    },
                    "&::-webkit-scrollbar": {
                        width: "0px",
                        background: "transparent",
                    },
                }}>
                    {isAuthenticated === true && (
                        yourComments.length > 0 ? (
                            yourComments.slice(0, 1).map((review, index) => (
                                <ReviewForm
                                    key={index}
                                    brandId={id}
                                    data={review}
                                    brandsReview={brandsReview}
                                    EmailID={EmailID}
                                    brandName={review.Brand_Name}
                                    BrandReviewId={review.Brand_Review_Id}
                                    comments={review.Comments}
                                    isUpdateForm={true}
                                    brandCommentsCall={brandCommentsCall}
                                />
                            ))
                        ) : (
                            <ReviewForm
                                brandId={id}
                                EmailID={EmailID}
                                brandName={brands[0]?.Brand_Name}
                                isUpdateForm={false}
                                brandCommentsCall={brandCommentsCall}
                            />
                        )
                    )}

                    <Grid
                        sx={{
                            height: "80vh", overflowY: "scroll", "&::-webkit-scrollbar-thumb": {
                                display: "none",
                            },
                            "&::-webkit-scrollbar": {
                                width: "0px",
                                background: "transparent",
                            },
                        }}
                    >
                        {brandCommentsGet.length === 0 ? (
                            <p>No comments available</p>
                        ) : (

                            brandCommentsGet.map((review, index) => (
                                <BrandComments
                                    key={index}
                                    data={review}
                                    rating={review.Rating}
                                    comments={review.Comments}
                                    date={review.Modified_Date}
                                    ProfilePicPath={review.Profile_Pic_Path}
                                />
                            ))

                        )}
                    </Grid>
                </Grid>
            </Grid>

            <Tabs value={value} onChange={handleChange}
                TabIndicatorProps={{
                    style: { display: "none" }
                }}
                sx={{
                    display: 'flex',
                    width: "98%",
                    '& button': { borderTopLeftRadius: 12, borderTopRightRadius: 12, width: { md: "208px", sm: '208px', xs: '121px' }, color: theme.palette.primary.main },
                    '& button.Mui-selected': { backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, fontWeight: 'bold', fontSize: '16px' },
                }}>
                <Tab value="one" label="PRODUCTS" />
                <Tab value="two" label="COUPONS" />
                <Tab value="three" label="DEALS" />
            </Tabs>

            <Box>
                {value === 'one' && <RenderProducts BrandId={id} />}
                {value === 'two' && <RenderCoupons BrandId={id} />}
                {value === 'three' && <RenderDeals BrandId={id} />}
            </Box>
        </Box>
    );
};

export default BrandReview;
