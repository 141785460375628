import { Autocomplete, Checkbox, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPartnersAction } from '../../../../store/slices/retail/home/ProductFiltersSlice'
import { AllProductsActions } from '../../../../store/slices/retail/home/AllProductsSlice'
import { useLocation } from 'react-router-dom'
const CustomizedSelect = () => {
    const dispatch = useDispatch()
    const partners = useSelector(state => state.productFilter.partners)
    const { filters: { Partner_Details_Id }, loading } = useSelector((state) => state.allProducts);
    const [selected, setSelected] = useState(null);
    const pathname = useLocation().pathname

    useEffect(() => {
        dispatch(getPartnersAction(
            {
                formData: { "Business_Type": pathname.includes("retail") ? "RETAIL" : "GROCERY" },
                pagination: { PageNo: 1, PageSize: 1000, SortBy: "NAME", SortOrder: "ASC" }
            }
        ))
    }, [])

    return (
        <Box sx={{ width: "100%", margin: "10px 0px" }}>
            <Autocomplete
                disablePortal
                options={partners}
                multiple
                limitTags={1}
                sx={{
                    "& fieldset": {
                        borderRadius: "12px",

                    },
                    '.MuiAutocomplete-inputRoot': {
                        flexWrap: 'nowrap !important', overflow: "hidden"
                    }
                }}
                isOptionEqualToValue={(option, value) => {
                    return option.id === value.id
                }}
                value={Partner_Details_Id}
                defaultValue={Partner_Details_Id}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => <TextField  {...params} label={"Search Partner"}InputLabelProps={{
                    style: {
                      fontWeight: 'bold'
                    }
                  }} />}
                renderOption={(props, option) => (
                
                    <FormControlLabel
                    {...props}
                    sx={{ width: "98%" }}
                    control={<Checkbox checked={props["aria-selected"]} />}
                    label={
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {option.title}
                      </Typography>
                    }
                  />
                )}
                onChange={(event, newValue) => {
                    dispatch(AllProductsActions.updateFilter({ Partner_Details_Id: newValue }))

                }}
            />
        </Box>


    )

}
export default CustomizedSelect 
