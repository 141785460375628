import { Box, CardMedia, Container, Typography, Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import useScreenSize from '../../../utils/ScreenWidthHight';
import { fetchShippingAndDelivery } from '../../../store/slices/retail/home/PrivacyPolicy';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { useTheme } from '@emotion/react';

const ShippingAndDelivery = () => {
    const winSize = useScreenSize();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { loading, shippinganddelivery } = useSelector(state => state.privacypolicy);

    useEffect(() => {
        dispatch(fetchShippingAndDelivery({ Name: "Shipping and Delivery", Module_Type: "OPIYUM" }));
    }, [dispatch]);

    return (
        <Box>
            {/* Left Side */}
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: { md: "center", xs: 'left' }, width: "100%", alignItems: "center", gap: 1 }}>
                    {winSize.screenSize.width > 601 && (
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            {loading ? (
                                <>
                                    <Skeleton variant="text" width="407px" height="144px" sx={{ mb: 1 }} />
                                    <Skeleton variant="text" width="177px" height="144px" sx={{ mb: 1 }} />
                                    <Skeleton variant="text" width="373px" height="144px" />
                                </>
                            ) : (
                                <>
                                    <Typography sx={{ display: 'flex', color: theme.palette.extra.primary, fontSize: '96px', fontWeight: 'bold' }}>
                                        Shipping
                                    </Typography>
                                    <Typography variant="h2" sx={{ display: 'flex', color: theme.palette.extra.primary, fontSize: '96px', fontWeight: 'bold' }}>
                                        and
                                    </Typography>
                                    <Typography variant="h2" sx={{ display: 'flex', color: theme.palette.extra.primary, fontSize: '96px', fontWeight: 'bold' }}>
                                        Delivery
                                    </Typography>
                                </>
                            )}
                        </Box>
                    )}
                    {winSize.screenSize.width < 600 && (
                        <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: 'center', width: "100%" }}>
                            {loading ? (
                                <Skeleton variant="text" width="90%" height="60px" sx={{ ml: '26px' }} />
                            ) : (
                                <Typography sx={{ color: theme.palette.extra.primary, fontSize: '34px', fontWeight: 'bold', textAlign: "left", width: "90%", ml: '26px' }}>
                                    Shipping & Delivery
                                </Typography>
                            )}
                        </Box>
                    )}
                </Box>
                {winSize.screenSize.width > 1050 && (
                    <Box sx={{ flex: '1' }}>
                        {loading ? (
                            <Skeleton
                                variant="rectangular"
                                width="611px"
                                height="499px"
                                sx={{
                                    borderRadius: "24px",
                                    mr: { md: '10px', sm: '10px', xs: 'unset' }
                                }}
                            />
                        ) : (
                            <CardMedia
                                component="img"
                                image={require("../../../assests/images/tickets.png")}
                                sx={{
                                    width: '611px',
                                    height: '499px',
                                    display: "flex",
                                    flexDirection: "column",
                                    flexWrap: "wrap"
                                }}
                            />
                        )}
                    </Box>
                )}
            </Box>
            {loading ? (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 3
                }}>
                    <Skeleton
                        variant="rectangular"
                        width="85%"
                        height={700}
                        sx={{
                            borderRadius: "24px"
                        }}
                    />
                </Box>
            ) : (
                shippinganddelivery?.map((item, index) => (
                    <Box key={index} sx={{ width: { md: '100%', sm: '100%', xs: '90%' }, display: 'flex', justifyContent: 'center', alignItems: 'center', ml: { md: '0px', sm: '0px', xs: '20px' } }}>
                        <Container sx={{ border: "0.4px solid", borderColor: theme.palette.text.secondary, borderRadius: "24px", boxShadow: "0 4px 4px 0 grey" }}>
                            {parse(item?.Description)}
                        </Container>
                    </Box>
                ))
            )}
        </Box>
    );
};

export default ShippingAndDelivery;
