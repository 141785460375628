import { Box, Typography } from '@mui/material';
import React from 'react';
import meepaisaBag from '../../../assests/images/meepaisaBag.png';
import meepaisaVan from '../../../assests/images/meepaisaVan.png';
import meepaisaConnectedAllCities from '../../../assests/images/meepaisaConnectedAllCities.png';
import meepaisaPlaceholder from '../../../assests/images/meepaisaPlaceholder.png';
import { useTheme } from '@emotion/react';
import useScreenSize from '../../../utils/ScreenWidthHight';

const AboutUs = () => {
    const theme = useTheme();
    const winSize = useScreenSize();

    return (
        <>
            <Box>
                {winSize.screenSize.width < 600 &&
                    <Typography sx={{ display: 'flex', color: theme.palette.extra.primary, fontSize: { md: '60px', sm: '60px', xs: '34px' }, fontWeight: 'bold', ml: { md: '0px', sm: '0px', xs: '26px' } }}>
                   About Us
                </Typography>}
            </Box>
            <Box
                sx={{
                    width: '90%',
                    height: { md: '100%', xs: "100vh", sm: '86vh' },
                    margin: '0 auto',
                    border: '1px solid',
                    borderRadius: '44px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    overflow: 'hidden',


                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '106px' }}>
                    <Box
                        component="img"
                        src={meepaisaPlaceholder}
                        alt="meepaisaPlaceholder"
                        sx={{
                            width: { md: '100%', xs: '180px' },
                            height: '100%',
                            marginRight: 2,
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: { xs: '30px', md: '20px' },
                        flexWrap: 'wrap',
                        height: '166px',
                    }}
                >
                    <Box
                        component="img"
                        src={meepaisaBag}
                        alt="Meepaisa Bag"
                        sx={{
                            width: { md: '222px', xs: '180px' },
                            height: '166px',
                            marginRight: 2,
                        }}
                    />
                    <Typography variant="body1" sx={{ flex: 1, display: 'flex', justifyContent: 'center', fontSize: { md: '18px', xs: '12px', sm: '18px' } }}>
                        Meepaisa is India’s leading e-commerce marketplace with over 10k+ Partners and 80 million products across 80+ categories.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: 2,
                        flexWrap: 'wrap',
                        height: '166px',
                    }}
                >
                    <Box
                        component="img"
                        src={meepaisaVan}
                        alt="Meepaisa Van"
                        sx={{
                            width: { md: '222px', xs: '180px' },
                            height: '100%',
                            marginRight: 2,
                        }}
                    />
                    <Typography variant="body1" sx={{ flex: 1, display: 'flex', justifyContent: 'center', fontSize: { md: '18px', xs: '12px', sm: '18px' } }}>
                        Meepaisa started with the team of 15 members.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        flexWrap: 'wrap',
                        height: '166px',
                    }}
                >
                    <Box
                        component="img"
                        src={meepaisaConnectedAllCities}
                        alt="Meepaisa Connected All Cities"
                        sx={{
                            width: { md: '222px', xs: '180px' },
                            height: '100%',
                            marginRight: 2,

                        }}
                    />
                    <Typography variant="body1" sx={{ flex: 1, display: 'flex', justifyContent: 'center', fontSize: { md: '18px', xs: '12px', sm: '18px' } }}>
                        We are now connected with all the cities.
                    </Typography>
                </Box>
            </Box>
        </>
    );
}

export default AboutUs;
