// FurnitureCard.js
import React from "react";
import { Grid, Card, CardActionArea, CardMedia, Link, Box, Typography, Skeleton } from "@mui/material";
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from "../../../utils";
import useScreenSize from "../../../utils/ScreenWidthHight";

const FurnitureCard = ({ input = {}, data = {}, title = "", isSkeleton = false }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const winsize = useScreenSize()
  if (!isSkeleton)
    return (
      <>
        {
          winsize.screenSize.width < 600 ?
            (
              <Box sx={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",overflowX:"scroll",columnGap:"20px"}} >
                {
                data.map((item, index) => (
                  <Box key={index} sx={{ }} >
                    <Card sx={{
                      width:"100%",
                      overflow: "hidden",
                      borderRadius: '0px'

                    }}>
                      <CardActionArea onClick={() => {
                        navigate(input.onClickLink + item[input.idField])
                      }} sx={{ width: "100%", height: "100%" }}>
                        <Box sx={{
                          width: "100%",
                          height: "100%",
                          overflow: "hidden"
                        }}>
                          <CardMedia
                            component="img"
                            alt={`Image ${index + 1}`}
                            image={item[input.imageField]}
                            sx={{
                              width: "120px",
                              height: "150px",
                              objectFit: "contain"
                            }}
                          />
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Box>
                ))
              }

              </Box>
            )

            :

            (
              data.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Card sx={{
                    marginTop: "40px",
                    overflow: "hidden",
                    marginBottom: "5px",
                    marginRight: "24px",
                    marginLeft: "24px",
                    borderRadius: '0px'

                  }}>
                    <CardActionArea onClick={() => {
                      navigate(input.onClickLink + item[input.idField])
                    }} sx={{ width: "394px", height: "100%" }}>
                      <Box sx={{
                        width: "100%",
                        paddingTop: "56.25%",
                        position: "relative",
                        overflow: "hidden"
                      }}>
                        <CardMedia
                          component="img"
                          alt={`Image ${index + 1}`}
                          image={item[input.imageField]}
                          style={{
                            position: "absolute",
                            top: "0",
                            width: "100%",
                            height: "100%",
                            objectFit: "contain"
                          }}
                        />
                      </Box>
                    </CardActionArea>
                  </Card>
                </Grid>
              )))
        }
      </>
    )
  return (
    <>
      {Array(4).fill(1).map((item, index) => (
        <Grid item xs={12} key={index}>
          <Card sx={{
            marginTop: "40px",
            overflow: "hidden",
            marginBottom: "5px",
            marginRight: "24px",
            marginLeft: "24px",
            borderRadius: '0px'

          }}>
            <CardActionArea sx={{ width: "394px", height: "100%" }}>
              <Box sx={{
                width: "100%",
                paddingTop: "56.25%",
                position: "relative",
                overflow: "hidden"
              }}>
                <Skeleton
                  variant="rectangular"
                  sx={{
                    position: "absolute",
                    top: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "fill"
                  }}
                />
              </Box>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </>
  );
};

export default FurnitureCard;
