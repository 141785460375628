import { Box, Rating, Typography } from "@mui/material";
import profileIcon from '../../../../assests/images/profileIcon.png';
import { useTheme } from "@emotion/react";


const BrandComments = ({ data, rating, comments, date, ProfilePicPath, brandId }) => {
    const theme = useTheme();

    return (<>

        <Box sx={{ width: "100%" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 3 }}>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", mb: {xs:0,sm:2}, marginTop: "50px" }}>
                    <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", position: "relative", mb: { xs: 2, sm: 0 } }}>
                        <img
                            src={ProfilePicPath ? ProfilePicPath : profileIcon}
                            alt="Profile"
                            style={{ width: "60px", height: "60px", borderRadius: "24px", objectFit: 'fill', marginRight: '10px', border: '1px solid ', borderColor: theme.palette.text.secondary, }}
                        />
                        <Box sx={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}>
                            <Typography variant="h6" sx={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.6)" }}>{data.Full_Name}</Typography>
                            <Rating name="rating" value={rating} readOnly />
                        </Box>

                    </Box>

                </Box>

            </Box>
            <Typography sx={{color: theme.palette.text.secondary}}>{comments}</Typography>
        </Box>

    </>)
}

export default BrandComments;