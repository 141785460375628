import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Link, Skeleton, useTheme } from '@mui/material';
import { Box, margin } from '@mui/system';
import useScreenSize from '../../../utils/ScreenWidthHight';
import AutoAdjustText from '../../../utils/AutoAdjustText'

const TodayDealsCard = ({ card, onClick, isSkeleton = false, cardWidth }) => {
  const theme = useTheme();
  const winSize=useScreenSize()
  if (!isSkeleton) {
    return (
      <Card
        onClick={onClick}
        sx={{
          border: '0.4px solid ' + theme.palette.input.borderColor,
          height: {xs:"254px",sm:334},
          minWidth:{xs:(winSize.screenSize.width<450?"40%":"38%"),sm:"unset"},
          width: {xs:"44%",sm:cardWidth},
          borderRadius: 3,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '20px',
          // justifyContent: "space-between",
        }}
      >
        <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', cursor: 'pointer', position: "relative",justifyContent:"space-around",overflow:"hidden" }}>
          <Typography variant="body2" sx={{ textAlign: 'end', color: theme.palette.primary.main, fontWeight: 'bold',...(winSize.screenSize.width< 600?{position:"absolute",top:"5px",right:"3%"}:{}) }}>
            {card.Discount_Label} {card.Discount_Type === 'A' && "₹"}{card.Discount}
            {card.Discount_Type === 'P' ? '%' : '/- '} Off
          </Typography>
          <CardMedia component="img" image={card.Image} alt={card.Title} sx={{ objectFit: 'fill', height: {xs:"45%",sm:200}, alignContent: 'center' }} />
          {winSize.screenSize.width<600?
          <AutoAdjustText style={{marginTop:"8px",marginBottom:"auto",fontWeight:"bold",fontSize:"1rem",textAlign:"start",width:"100%",height:"10%"}} scrollofText numLines={1} >
            {card.Title}
          </AutoAdjustText>
          :<Typography variant="body1" align="center" sx={{ mt: '8px', mb: 'auto', fontWeight: 'bold' }}>
            {card.Title}
          </Typography>}
          <Typography variant="body1" align="right" sx={{ mt: 'auto', cursor: 'pointer', paddingTop: 1, color: theme.palette.text.secondary, fontWeight: 'bold', position: "absolute", bottom: 5, right: 10 }}>
            Grab Now
          </Typography>
        </CardContent>
      </Card>
    );
  } else {
    return (
      <Card
        sx={{
          height: 344,
          width: cardWidth,
          borderRadius: 3,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '20px',
        }}
      >
        <Skeleton variant="rectangular" sx={{ height: 344 }} />
        <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', cursor: 'pointer', p: 2 }}>
          <Skeleton />
          <Skeleton width="220px" />
        </CardContent>
      </Card>
    );
  }
};
export default TodayDealsCard;
