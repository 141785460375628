import React, { useEffect, useMemo, useState } from 'react';
import { Box, TextField, IconButton, Card, Grid, Typography, FormControlLabel, RadioGroup, InputAdornment, Radio, Button, FormGroup, Checkbox, Link, CardMedia, FormHelperText, FormControl, InputLabel, Select, MenuItem, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import { fetchAddress, updateEditAddressAction } from '../../../store/slices/accounts/Address/Address'
import GoogleMap from './GoogleMap';
import Cities from '../../components/accounts/Address/Cities';
import States from '../../components/accounts/Address/States';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../Context';
import { useTheme } from '@emotion/react';


const AddressForm = ({ editAbleData, viewOnMapState, }) => {
    const [mapState, setMapState] = useState(false)
    const dispatch = useDispatch()
    const theme = useTheme()
    const [mapAddress, setMapAddress] = useState({})
    const [data, setData] = useState(editAbleData)
    const authDetails = useSelector(state => state.auth)
    const navigate = useNavigate()

    const { toolTipActive, searchParams } = React.useContext(GlobalContext);

    const validationSchema = Yup.object().shape({
        location: Yup.string().min(3, 'Location must be at least 3 characters').required('Location is required'), doorNo: Yup.string().trim(" ").min(1, 'Door No cannot be empty').max(20, 'Door No must be at most 20 characters').required('Door No is required'),
        street: Yup.string().trim(" ").min(3, 'Street must be at least 3 characters').required('Street is required'),
        landmark: Yup.string().trim(" ").min(3, 'Landmark must be at least 3 characters').required('Landmark is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        pincode: Yup.string().min(6, 'Pin must be 6 numbers').max(6, 'Street must be at most 6 numbers').required('Pincode is required'),
        name: Yup.string().min(3, 'Name must be at least 3 characters').required('Name is required'),
        mobileNumber: Yup.string().min(10, 'Moible must be at least 10 numbers').required('Mobile Number is required'),
        alternativeMobileNumber: Yup.string()
            .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits')
    });

    const mapAddressChange = (address) => {
        setMapAddress(address)
        formik.setFieldValue("location", address?.locationInfo)
        formik.setFieldValue("pincode", address?.postcode)
        formik.setFieldValue("lat", address?.latitude)
        formik.setFieldValue("lag", address?.longitude)
    }

    const mapStateChange = (state) => {
        setMapState(state)
    }

    useEffect(() => {
        setValuesOfFormik(editAbleData)
    }, [editAbleData])

    const setValuesOfFormik = (editAbleData) => {
        setMapState(false)
        formik.setFieldValue("location", editAbleData?.location)
        formik.setFieldValue("doorNo", editAbleData?.DoorNo)
        formik.setFieldValue("street", editAbleData?.street)
        formik.setFieldValue("landmark", editAbleData?.landMark)
        formik.setFieldValue("city", editAbleData?.city)
        formik.setFieldValue("state", editAbleData?.state)
        formik.setFieldValue("pincode", editAbleData?.pincode)
        formik.setFieldValue("name", editAbleData?.name)
        formik.setFieldValue("mobileNumber", editAbleData?.phone.includes("+91 ") ? editAbleData?.phone.slice(4, editAbleData?.phone.length) : editAbleData?.phone)
        formik.setFieldValue("alternativeMobileNumber", editAbleData?.alternativePhonenumber?.includes("+91 ") || false ? editAbleData?.alternativePhonenumber.slice(4, editAbleData?.alternativePhonenumber.length) : editAbleData?.alternativePhonenumber)
        formik.setFieldValue("default", editAbleData?.billingType?.default)
        formik.setFieldValue("billing", editAbleData?.billingType?.billing)
        formik.setFieldValue("shipping", editAbleData?.billingType?.shipping)
        formik.setFieldValue("type", editAbleData?.type.toLowerCase())
        formik.setFieldValue("id", editAbleData?.id)
        formik.setFieldValue("email", editAbleData?.email)
        formik.setFieldValue("lat", editAbleData?.lat)
        formik.setFieldValue("lag", editAbleData?.lag)
    }

    const formik = useFormik({
        initialValues: {
            location: "",
            doorNo: "",
            street: "",
            landmark: "",
            city: "",
            state: "",
            pincode: "",
            name: "",
            mobileNumber: "",
            alternativeMobileNumber: "",
            default: false,
            billing: false,
            shipping: false,
            type: "",
            id: "",
            email: "",
            lat: "",
            lag: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const payload = {
                User_Address_Id: values.id,
                User_Email_Id: authDetails.user.Email_Id,
                Address_Type: values.type !== '' ? values.type : "home",
                Is_Billing: values.billing ? 1 : 0,
                Is_Default: values.default ? 1 : 0,
                Is_Shipping: values.shipping ? 1 : 0,
                Is_Active: 1,
                Location: values.location,
                Door_No: values.doorNo,
                Street: values.street,
                Land_Mark: values.landmark,
                City: values.city,
                State: values.state,
                Zip: values.pincode,
                Mobile: "+91 " + values.mobileNumber,
                Alternate_Mobile: "+91 " + values.alternativeMobileNumber || '',
                Name: values.name,
                Latitude: mapAddress?.latitude || values.lat,
                Longitude: mapAddress?.longitude || values.lag,
                Country: "IN",
                Modified_By: authDetails?.user?.Email_Id,
                Shipping_Email_Id: values.email,
                Google_Map_Location: values.location,
            }
            if (values.mobileNumber !== values.alternativeMobileNumber) {
                const res = await updateEditAddressAction(payload);
                if (res.status) {
                    toolTipActive("success", "Address Updated");
                    dispatch(fetchAddress({
                        formData: {
                            "User_Email_Id": authDetails?.user?.Email_Id
                        },
                        pagination: { PageNo: searchParams.get("PageNo"), PageSize: 5 }
                    }))
                }
            }
        },
    });
    return (
        <Box sx={{ width: { xs: "100%", md: "100%" }, minHeight: "100%", }}>
            <Box sx={{ width: "100%", height: 30, display: "flex", justifyContent: "flex-end", alignItems: "center" }} >
                <Typography sx={{ fontWeight: "bold", fontSize: 16 }} >Update Address</Typography>
            </Box>
            {mapState ?
                <GoogleMap onAddress={mapAddressChange} mapState={mapStateChange} latitude={parseFloat(formik.values.lat)} editStatus={true} longitude={parseFloat(formik.values.lag)} />
                :
                <Box sx={{ width: { xs: "100%", md: "100%" }, minHeight: "50%", }}>
                    {/* <Box sx={{ width: "100%", height: 27, display: "flex", justifyContent: "flex-end" }} >
                        <Typography sx={{ fontWeight: "bold", fontSize: 16 }} ></Typography>
                    </Box> */}
                    <Box sx={{ display: "flex", width: "100%", gap: 5, flexWrap: "wrap" }}>
                        <Box sx={{ width: "100%" }}  >
                            <TextField
                                sx={{ width: "100%", height: 44 }}
                                label='Location'
                                name="location"
                                onClick={() => {
                                    setMapState(true)
                                }}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={() => {
                                                setMapState(true)
                                            }} >
                                                <CardMedia
                                                    sx={{ width: 36, height: 44 }}
                                                    image={require('../../../assests/images/locationAddress.png')}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                value={formik.values.location}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.location && Boolean(formik.errors.location)}

                            />
                            {formik.touched.location && formik.errors.location && (
                                <Typography mt={1} sx={{ padding: 0, color: theme.palette.error.main }} error>{formik.errors.location}</Typography>
                            )}
                        </Box>
                        <Box sx={{ width: "100%" }} >

                            <TextField
                                sx={{ width: "100%", height: 44 }}
                                label='Door No'
                                name="doorNo"
                                value={formik.values.doorNo}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.doorNo && Boolean(formik.errors.doorNo)}
                            />
                            {formik.touched.doorNo && formik.errors.doorNo && (
                                <Typography mt={1} sx={{ padding: 0, color: theme.palette.error.main }} error>{formik.errors.doorNo}</Typography>
                            )}
                        </Box>
                        <Box sx={{ width: "100%" }} >

                            <TextField
                                sx={{ width: "100%", height: 44 }}
                                label='Street'
                                name="street"
                                value={formik.values.street}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.street && Boolean(formik.errors.street)}
                            />
                            {formik.touched.street && formik.errors.street && (
                                <Typography mt={1} sx={{ padding: 0, color: theme.palette.error.main }} error>{formik.errors.street}</Typography>
                            )}
                        </Box>
                        <Box sx={{ width: "100%" }} >

                            <TextField
                                sx={{ width: "100%", height: 44 }}
                                label='Land Mark'
                                name="landmark"
                                value={formik.values.landmark}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.landmark && Boolean(formik.errors.landmark)}
                            />
                            {formik.touched.landmark && formik.errors.landmark && (
                                <Typography mt={1} sx={{ padding: 0, color: theme.palette.error.main }} error>{formik.errors.landmark}</Typography>
                            )}
                        </Box>
                        <Box sx={{ width: "100%", height: 44, display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-between" }}>
                            <Box sx={{ width: "49%" }} >
                                <States

                                    labelId='demo-select-small-label'
                                    id="demo-select-small"
                                    label="Select state"
                                    name="state"
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.state && Boolean(formik.errors.state)}

                                />
                                {formik.touched.state && formik.errors.state && (
                                    <Typography error color={theme.palette.extra.errorMessage} >{formik.errors.state}</Typography>
                                )}
                            </Box>
                            <Box sx={{ width: "49%" }} >
                                <Cities
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    label="Select City"
                                    name="city"
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                    statecode={formik.values.state}
                                />
                                {formik.touched.city && formik.errors.city && (
                                    <Typography error color={theme.palette.extra.errorMessage}>{formik.errors.city}</Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ width: "100%" }} >

                            <TextField
                                sx={{ width: "100%", height: 44 }}
                                label='Pincode'
                                name="pincode"
                                value={formik.values.pincode}
                                onChange={(e) => {
                                    const value = e.currentTarget.value
                                    if (value[0] !== "0")
                                        formik.setFieldValue('pincode', value.replace(/\D/g, ""))
                                }}
                                onBlur={formik.handleBlur}
                                error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                            />
                            {formik.touched.pincode && formik.errors.pincode && (
                                <Typography mt={1} sx={{ padding: 0, color: theme.palette.error.main }} error>{formik.errors.pincode}</Typography>
                            )}
                        </Box>
                        <Box sx={{ width: "100%" }} >
                            <TextField
                                sx={{ width: "100%", height: 44 }}
                                label='Name'
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <Typography mt={1} sx={{ padding: 0, color: theme.palette.error.main }} error>{formik.errors.name}</Typography>
                            )}
                        </Box>
                        <Box sx={{ width: "100%", height: 44, display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-between" }}>
                            <Box sx={{ width: "47%", height: "100%" }} >
                                <TextField
                                    sx={{ width: "100%",  }}
                                    label='Mobile Number'
                                    name="mobileNumber"
                                    // inputProps={{ maxLength: 10,startAdornment: <InputAdornment position="start">kg</InputAdornment>, }}
                                    InputProps={{
                                        maxLength: 10,
                                        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                                    }}
                                    value={formik.values.mobileNumber}
                                    onChange={(e) => {
                                        formik.setFieldValue('mobileNumber', e.currentTarget.value.replace(/\D/g, ""))
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                                />
                                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                                    <FormHelperText error>{formik.errors.mobileNumber}</FormHelperText>
                                )}
                            </Box>
                            <Box sx={{ width: "47%", height: "100%" }} >
                                <TextField
                                    sx={{ width: "100%", }}
                                    InputProps={{
                                        maxLength: 10,
                                        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                                    }}
                                    label='Alternate Mobile Number'
                                    name="alternativeMobileNumber"
                                    value={formik.values.alternativeMobileNumber}
                                    onChange={(e) => {
                                        if (e.currentTarget.value.length < 11)
                                            formik.setFieldValue('alternativeMobileNumber', e.currentTarget.value.replace(/\D/g, ""))
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.alternativeMobileNumber && Boolean(formik.errors.alternativeMobileNumber) || (formik.values.alternativeMobileNumber === formik.values.mobileNumber && formik.values.alternativeMobileNumber !== "" && !(formik.touched.alternativeMobileNumber && formik.errors.alternativeMobileNumber))}
                                // helperText={formik.touched.alternativeMobileNumber && formik.errors.alternativeMobileNumber}
                                />
                                {formik.touched.alternativeMobileNumber && formik.errors.alternativeMobileNumber && (
                                    <FormHelperText error>{formik.errors.alternativeMobileNumber}</FormHelperText>
                                )}
                                {(formik.values.alternativeMobileNumber === formik.values.mobileNumber && formik.values.alternativeMobileNumber !== "" && !(formik.touched.alternativeMobileNumber && formik.errors.alternativeMobileNumber)) && (
                                    <FormHelperText error>{"Mobile number and alternate mobile number should not be same"}</FormHelperText>
                                )}
                            </Box>
                        </Box>
                        {/* <Box sx={{ width: "100%", height: 44, }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox name="default" checked={formik.values.default} onChange={formik.handleChange} />} label="Default" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox name="billing" checked={formik.values.billing} onChange={formik.handleChange} />} label="Billing" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox name="shipping" checked={formik.values.shipping} onChange={formik.handleChange} />} label="Shipping" />
                                </Grid>
                            </Grid>
                        </Box> */}

                        <Box sx={{ width: "100%", height: 44 }}>
                            <RadioGroup onChange={formik.handleChange} name='type' value={formik.values.type.toLowerCase()}  >
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <FormControlLabel value="home" control={<Radio />} label="Home" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControlLabel value="business" control={<Radio />} label="Business" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControlLabel value="office" control={<Radio />} label="Office" />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </Box>
                        <Button
                            sx={{ width: "100%", borderRadius: 24 }}
                            variant="contained"
                            type="submit"
                            onClick={formik.handleSubmit}
                        >
                            Save Address
                        </Button>
                    </Box>
                </Box >
            }
        </Box>
    )
}
export default AddressForm
