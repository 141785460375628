import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    FormControl,
    FormHelperText,
    IconButton,
    CardMedia,
    InputAdornment
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { changepasswordAction } from '../../store/slices/Profile/Profile';
import { GlobalContext } from '../../Context';
import { logout } from '../../store/slices/AuthSlice';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { signOutStateUpdate } from '../../store/slices/AuthSlice';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ChangePassword = ({ onClose }) => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const theme = useTheme();
    const { toolTipActive } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [isNavigate, setIsNavigate] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    
    const validationSchema = Yup.object({
        currentPassword: Yup.string()
            .required('Current Password is required'),
        newPassword: Yup.string()
            .required('New Password is required')
            .min(8, 'Password should be of minimum 8 characters length')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*])/,
                "Password must contain a mix of uppercase letters, lowercase letters, numbers, and special characters."
            ),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            .required('Confirm New Password is required'),
    });

    useEffect(() => {
        if (isNavigate) {
            navigate('/auth/signin');
        }
    }, [isNavigate, navigate]);

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setErrors }) => {
            const formData = {
                Email_Id: auth.user.Email_Id,
                Old_Password: values.currentPassword,
                New_Password: values.confirmPassword
            };
            const response = await changepasswordAction(formData);
            if (response.status) {
                toolTipActive("success", response.data.UI_Display_Message);
                window.localStorage.removeItem("Token");
                dispatch(signOutStateUpdate())
                setIsNavigate(true);
                onClose();
            } else {
                if (response.error.UI_Display_Message === "Password already exist. Please try other password") {
                    setErrors({ newPassword: response.error.UI_Display_Message, confirmPassword: response.error.UI_Display_Message });
                } else {
                    setErrors({ currentPassword: response.error.UI_Display_Message });
                }
            }
        },
    });

    const handleClickShowPassword = (fieldName) => {
        if (fieldName === 'Password') {
          setShowPassword((prevShowPassword) => !prevShowPassword);
        } else if (fieldName === 'ConfirmPassword') {
          setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
        } else if (fieldName === 'NewPassword') {
          setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
        }
      };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
                display: 'grid',
                flexDirection: 'column',
                width: { md: '40%', sm: "60%", xs: "80%" },
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: theme.palette.primary.contrastText,
                borderRadius: '20px',
                position: "relative",
                margin: 'auto',
                marginTop: { md: '10%', sm: '20%', xs: '20%' },
            }}
        >
            <Typography variant="h6">
                CHANGE PASSWORD
            </Typography>
            <FormControl margin="normal" error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}>
                <TextField
                    label="Current Password"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    id="currentPassword"
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('Password')}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              sx={{ color: theme.palette.extra.primary }}
                            >
                              {!showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    {...formik.getFieldProps('currentPassword')}
                    error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                />
                {formik.touched.currentPassword && formik.errors.currentPassword ? (
                    <FormHelperText>{formik.errors.currentPassword}</FormHelperText>
                ) : null}
            </FormControl>
            <FormControl margin="normal" error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}>
                <TextField
                    label="New Password"
                    type={showNewPassword ? 'text' : 'password'}
                    fullWidth
                    id="newPassword"
                    // InputProps={{
                    //     endAdornment: (
                    //       <InputAdornment position="end">
                    //         <IconButton
                    //           aria-label="toggle new password visibility"
                    //           onClick={() => handleClickShowPassword('NewPassword')}
                    //           onMouseDown={handleMouseDownPassword}
                    //           edge="end"
                    //           sx={{ color: theme.palette.extra.primary }}
                    //         >
                    //           {!showNewPassword ? <VisibilityOff /> : <Visibility />}
                    //         </IconButton>
                    //       </InputAdornment>
                    //     ),
                    //   }}
                    {...formik.getFieldProps('newPassword')}
                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                />
                {formik.touched.newPassword && formik.errors.newPassword ? (
                    <FormHelperText>{formik.errors.newPassword}</FormHelperText>
                ) : null}
            </FormControl>
            <FormControl margin="normal" error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}>
                <TextField
                    label="Confirm New Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    fullWidth
                    id="confirmPassword"
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle confirm password visibility"
                              onClick={() => handleClickShowPassword('ConfirmPassword')}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              sx={{ color: theme.palette.extra.primary }}
                            >
                              {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    {...formik.getFieldProps('confirmPassword')}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <FormHelperText>{formik.errors.confirmPassword}</FormHelperText>
                ) : null}
            </FormControl>
            <Button
                type="submit"
                variant="contained"
                sx={{ marginTop: '20px', borderRadius: '20px' }}
            >
                Change Password
            </Button>
            <IconButton
                onClick={onClose}
                sx={{
                    position: "absolute", top: 1, right: 1
                }}
            >
                <CardMedia
                    image={require('../../assests/images/closeIcon.png')}
                    sx={{ width: 20, height: 20 }}
                />
            </IconButton>
        </Box>
    );
}

export default ChangePassword;
