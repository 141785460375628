import React from 'react';
import { Box, TextField } from '@mui/material';
import AllProducts from './AllProducts';
import PartnerFilter from '../../../components/retail/Filters/PartnerFilter';
import PopularFilter from '../../../components/retail/Filters/PopularFilter';
import SearchIcon from '@mui/icons-material/Search';
import BrandsFilter from '../../../components/retail/Filters/BrandsFilter';
import CategoreyFilter from '../../../components/retail/Filters/CategoryFilter';
import DiscountsFilter from '../../../components/retail/Filters/DiscountsFilter';
import PriceFilter from '../../../components/retail/Filters/PriceFilter';
import { useDispatch, useSelector } from 'react-redux';
import { AllProductsActions } from '../../../../store/slices/retail/home/AllProductsSlice';

const AllProductsCompact = ({ handleSetModalProductId, handleModalOpen }) => {
    const { filters, loading } = useSelector((state) => state.allProducts);
    const dispatch = useDispatch();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: "row", md: 'row' },
                gap: "2%",
            }}
        >

            <Box
                sx={{
                    width: { xs: '100%', sm: "28%", md: '28%' },
                    // height: 700,
                    overflow: 'hidden',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                        width: '0px',
                        background: 'transparent',
                    },

                }}
            >
                {/* Search TextField */}
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={filters.Search}

                    onChange={(e) => {
                        dispatch(AllProductsActions.updateFilter({ Search: e.target.value }))
                    }}
                    name="Search Product"
                    placeholder="Search Product"
                    InputProps={{
                        startAdornment: (
                            <SearchIcon color='primary' />
                        ),
                    }}
                    sx={{
                        '& fieldset': { borderRadius: '12px' },
                        '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                        },
                    }}
                />

                {/* CustomizedSelect Components */}
                <PartnerFilter />
                <BrandsFilter />
                <CategoreyFilter />
                {/* CustomizedCheckBox Components */}
                <PopularFilter name="Popular" checkboxes={[{
                    id: 'Is_Popular', value: filters.Is_Popular == 0 ? false : true, label: 'popular Products',
                    onChange: ((e) => {
                        dispatch(AllProductsActions.updateFilter({ Is_Popular: e.target.checked ? 1 : 0 }))

                    })
                },
                {
                    id: 'Is_Top_Product', value: filters.Is_Top_Product == 0 ? false : true, label: 'Top Products',
                    onChange: ((e) => {

                        dispatch(AllProductsActions.updateFilter({ Is_Top_Product: e.target.checked ? 1 : 0 }))

                    })
                },
                {
                    id: 'Whats_Hot', value: filters.Whats_Hot == 0 ? false : true, label: "Today's Hot Products",
                    onChange: ((e) => {

                        dispatch(AllProductsActions.updateFilter({ Whats_Hot: e.target.checked ? 1 : 0 }))

                    })
                },
                {
                    id: 'Is_Upcoming', value: filters.Is_Upcoming == 0 ? false : true, label: "Upcoming Products",
                    onChange: ((e) => {

                        dispatch(AllProductsActions.updateFilter({ Is_Upcoming: e.target.checked ? 1 : 0 }))

                    })
                },
                {
                    id: 'Is_Verified', value: filters.Is_Verified == 0 ? false : true, label: "verified Products",
                    onChange: ((e) => {

                        dispatch(AllProductsActions.updateFilter({ Is_Verified: e.target.checked ? 1 : 0 }))

                    })
                }]} />
                {/* <ExtraCashBackFilter name="Extra CashBack" checkboxes={['Extra Cashback']} /> */}
                <DiscountsFilter name="Discount's" />
                <PriceFilter name="Price" />
            </Box>

            <Box
                sx={{
                    width: { xs: '100%', sm: "70%", md: '70%' },
                    // height: 700,
                    overflow: 'hidden',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                        width: '0px',
                        background: 'transparent',
                    },

                }}
            >
                <AllProducts handleSetModalProductId={handleSetModalProductId} handleModalOpen={handleModalOpen} />
            </Box>
        </Box>
    );
}

export default AllProductsCompact
