import { useTheme } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AllProductsCard from '../../components/cards/AllProductsCard'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllProducts } from "../../../store/slices/retail/home/AllProductsSlice";
import { useNavigate, useParams } from 'react-router-dom'
import { productSimilarProducts } from '../../../store/slices/retail/ProductDetails/ProductDetailsActions'


const SimilarProducts = ({ handleModalOpen, handleModalProductId, Product_Id }) => {
  const theme = useTheme()
  const { id } = useParams()
  const [products, setProducts] = useState([])
  useEffect(() => {
    if (id !== "")
      productSimilarProductsCall({ Product_Id: id })
    else {
      setProducts([])
    }
  }, [id])

  const productSimilarProductsCall = async (formData) => {
    const response = await productSimilarProducts(formData)
    if (response.status) {
      setProducts(response.data)
    }
    else{
      setProducts([])
    }
  }
  if (products.length > 0)
    return (
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }} >
        <Typography sx={{ color: theme.palette.text.secondary, width: "100%" }} >Similar Products</Typography>


        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            // gap: "30px",
            justifyContent:{xs:"space-between",sm:"flex-start"}
          }}
        >
          {
            products.length > 0 ?
              products.map((item, index) => (
                <AllProductsCard
                  key={item.Product_Id + index}
                  image={item.Product_Image}
                  brandName={item.Brand_Name}
                  shortDescription={item.Product_Short_Description}
                  originalPrice={item.Original_Price}
                  partnerSellingPrice={item.Partner_Selling_Price}
                  item={item}
                  handleNavigation={() => {
                    handleModalProductId(item.Product_Id)
                    handleModalOpen(true)
                  }}

                />
              ))
              :
              <Typography>No Similar Products Found</Typography>
          }
        </Box>
      </Box>
    )
}

export default SimilarProducts