import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Typography, Grid, ButtonBase, Card, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ReviewPurchase = ({ data, itemData }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleItemClick = (reviewId) => {
        navigate(itemData.onClickLink + reviewId);
      };
    return (
        <>
            {data.length > 0 && (
                <Card
                    sx={{
                        height:
                            data.length === 0
                                ? "0"
                                : data.length <= 2
                                    ? "250px"
                                    : "462px",
                        minWidth: { xs: "100%", sm: "323px" },
                        maxWidth: { xs: "100%", sm: "323px" },
                        marginBottom: "10px",
                        position: "relative",
                        borderRadius: 0,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            // height: "50px",
                            marginLeft: "8px",
                        }}
                    >
                        <Typography gutterBottom sx={{ fontSize: "19px", fontWeight: "bold" }}>
                            {itemData.title}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            gap: "10px",
                            padding: "10px",
                        }}
                    >
                        {data.map((review, index) => (
                            <Box key={index} sx={{ textAlign: "center" }}>
                                <Button
                                    onClick={() => handleItemClick(review[itemData.idField])}
                                    sx={{
                                        padding: 0,
                                        "&:hover": { backgroundColor: "transparent" },
                                    }}
                                >
                                    <img
                                        src={review[itemData.imageField]}
                                        alt={`Review ${index + 1}`}
                                        style={{
                                            height: "174px",
                                            width: "145px",
                                            objectFit: "contain",
                                        }}
                                    />
                                </Button>
                                <Typography variant="body2" align="center" sx={{ fontWeight: "bold" }}>
                                    {review.Brand_Name}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Card>
            )}
        </>
    );
};

export default ReviewPurchase;
