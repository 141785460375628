import React from 'react';
import { Box, CardMedia, Typography, IconButton, Rating, Link, Card } from '@mui/material';
import { Container } from '@mui/material';
import { useTheme } from '@emotion/react';

const ReviewComponent = ({ imageLabel, rating, onClick, seemore, title }) => {
  const theme = useTheme();


  return (
    <Card sx={{ width: {xs:"100%",sm:"343px"},position:"relative" }}  >
      <Container onClick={onClick} sx={{ boxShadow: "0px 9px 30px " + theme.palette.extra.cardShadowColorOne, height: 410 }}>
        <Typography variant="body1" sx={{ paddingBottom: {xs:"10px",sm:'0px'}, fontSize: '19px', fontWeight: 'bold' }}>
          {title}
        </Typography>
        <CardMedia
          component="img"
          image={imageLabel}
          alt="First Image"
          sx={{
            width: '100%',
            height: '280px',
            objectFit: 'contain',
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 4 }}>

          <Rating name="rating" precision={0.5} value={rating} readOnly />
        </Box>
      </Container>
      <Typography variant="body2" sx={{ textAlign: "end", height: 50, width: "90%" }}>
        <Link href={seemore} sx={{
          textDecoration: 'none',
          "&:hover": {
            textDecoration: 'underline'
          },
          fontWeight: 'bold',
          position:"absolute",
          bottom:5,
          right:10
        }}>See more products to review</Link>
      </Typography>
    </Card>
  );
};

export default ReviewComponent;
