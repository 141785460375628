import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi } from '../../CommonAxios';

export const fetchPrivacyPolicy = createAsyncThunk(
    'privacypolicy/fetchPrivacyPolicy',
    async (formData) => {
        try {
            const response = await nodeApi.post('/ContentManagement/Static_Get_Admin',formData);
            const data = response.data;

            if (data.Success_Response.Is_Data_Exist === "0") {
                return [];
            } else {
                return data.results;
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const fetchReturnPolicy = createAsyncThunk(
    'returnPolicy/fetchReturnPolicy',
    async (formData) => {
        try {
            const response = await nodeApi.post('/ContentManagement/Static_Get_Admin',formData);
            const data = response.data;

            if (data.Success_Response.Is_Data_Exist === "0") {
                return [];
            } else {
                return data.results;
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const fetchTermsandConditions = createAsyncThunk(
    'termsandConditions/fetchTermsandConditions',
    async (formData) => {
        try {
            const response = await nodeApi.post('/ContentManagement/Static_Get_Admin',formData);
            const data = response.data;

            if (data.Success_Response.Is_Data_Exist === "0") {
                return [];
            } else {
                return data.results;
            }
        } catch (error) {
            throw error.message;
        }
    }
);

export const fetchMarketingStrategies = createAsyncThunk(
    'marketingStrategies/fetchMarketingStrategies',
    async (formData) => {
        try {
            const response = await nodeApi.post('/ContentManagement/Static_Get_Admin', formData);
            const data = response.data;

            if (data.Success_Response.Is_Data_Exist === "0") {
                return [];
            } else {
                return data.results;
            }
        } catch (error) {
            throw error.message;
        }
    }
);

export const fetchShippingAndDelivery = createAsyncThunk(
    'shippinganddelivery/fetchShippingAndDelivery',
    async (formData) => {
        try {
            const response = await nodeApi.post('/ContentManagement/Static_Get_Admin', formData);
            const data = response.data;

            if (data.Success_Response.Is_Data_Exist === "0") {
                return [];
            } else {
                return data.results;
            }
        } catch (error) {
            throw error.message;
        }
    }
);

export const fetchReturnAndExchange = createAsyncThunk(
    'returnandexchange/fetchReturnAndExchange',
    async (formData) => {
        try {
            const response = await nodeApi.post('/ContentManagement/Static_Get_Admin', formData);
            const data = response.data;

            if (data.Success_Response.Is_Data_Exist === "0") {
                return [];
            } else {
                return data.results;
            }
        } catch (error) {
            throw error.message;
        }
    }
);

export const fetchCopyrights = createAsyncThunk(
    'copyrights/fetchCopyrights',
    async (formData) => {
        try {
            const response = await nodeApi.post('/ContentManagement/Static_Get_Admin', formData);
            const data = response.data;

            if (data.Success_Response.Is_Data_Exist === "0") {
                return [];
            } else {
                return data.results;
            }
        } catch (error) {
            throw error.message;
        }
    }
);

const PrivacyPolicySlice = createSlice({
    name: 'privacypolicy',
    initialState: {
        privacyPolicy: [],
        termsAndConditions: [],
        marketingStrategy: [],
        shippinganddelivery: [],
        returnandexchange: [],
        copyrights:[],
        returnPolicy:[],
        loading: false,
        error: null,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchPrivacyPolicy.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPrivacyPolicy.fulfilled, (state, action) => {
                state.loading = false;
                state.privacyPolicy = action.payload;
            })
            .addCase(fetchPrivacyPolicy.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            }).addCase(fetchTermsandConditions.pending,(state,action)=>{
                state.loading = true;
                state.error = null;
            }).addCase(fetchTermsandConditions.fulfilled, (state, action) => {
                state.loading = false;
                state.termsAndConditions = action.payload;
            }).addCase(fetchTermsandConditions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchMarketingStrategies.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            }).addCase(fetchMarketingStrategies.fulfilled, (state, action) => {
                state.loading = false;
                state.marketingStrategy = action.payload;
            }).addCase(fetchMarketingStrategies.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchShippingAndDelivery.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            }).addCase(fetchShippingAndDelivery.fulfilled, (state, action) => {
                state.loading = false;
                state.shippinganddelivery = action.payload;
            }).addCase(fetchShippingAndDelivery.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchReturnAndExchange.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            }).addCase(fetchReturnAndExchange.fulfilled, (state, action) => {
                state.loading = false;
                state.returnandexchange = action.payload;
            }).addCase(fetchReturnAndExchange.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchCopyrights.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            }).addCase(fetchCopyrights.fulfilled, (state, action) => {
                state.loading = false;
                state.copyrights = action.payload;
            }).addCase(fetchCopyrights.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchReturnPolicy.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            }).addCase(fetchReturnPolicy.fulfilled, (state, action) => {
                state.loading = false;
                state.returnPolicy = action.payload;
            }).addCase(fetchReturnPolicy.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    },
});

export default PrivacyPolicySlice.reducer;
