import React, { useEffect, useMemo } from 'react';
import { Box, Grid, Link, Typography } from "@mui/material";
import { useTheme } from '@emotion/react';
import instagramIcon from '../../assests/images/instagram.png';
import twitterIcon from '../../assests/images/twitterx.png';
import facebookIcon from '../../assests/images/facebook.png';
import youtubeIcon from '../../assests/images/youtube.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../store/slices/retail/home/CategorySlice';
import BHIM_SVG_Logo from '../../assests/images/BHIM_SVG_Logo.png';
import sectigo from '../../assests/images/sectigo.png';
import mclogo from '../../assests/images/masterCardLogo.png';
import Razorpay_logo from '../../assests/images/Razorpay_logo.png';
import VisaLogo from '../../assests/images/VisaLogo.png';
import useScreenSize from '../../utils/ScreenWidthHight';

const Footer = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const winSize = useScreenSize();
    const categories = useSelector((state) => state.category.categories);

    useEffect(() => {
        dispatch(fetchCategories({ "Search_Category_Name": "", "Category_Type": "Grocery" }));
    }, [dispatch]);

    const topCategories = useMemo(() => categories.slice(0, 4), [categories]);

    const sections = [
        {
            title: "Social",
            links: [
                { label: "Instagram", url: "https://www.instagram.com/meepaisa/", image: instagramIcon },
                { label: "Twitter", url: "https://x.com/meepaisa", image: twitterIcon },
                { label: "Facebook", url: "https://www.facebook.com/meepaisa/", image: facebookIcon },
                { label: "YouTube", url: "https://www.youtube.com/@meepaisa", image: youtubeIcon }
            ]
        },
        {
            title: "Contact",
            links: [
                { label: "Contact Us", url: "/accounts/contactus" },
                { label: "info@easystepin.com", url: "mailto:info@easystepin.com" },
                { label: "Support@meepaisa.com", url: "mailto:Support@meepaisa.com" },
                { label: "+91 9108424077", url: "tel:+91 9108424077" }
            ]
        },
        {
            title: "About",
            links: [
                { label: "Customer Support", url: "/accounts/tickets" },
                { label: "About Us", url: "/accounts/aboutus" },
                { label: "Copyright", url: "/accounts/copyrights" }
            ]
        },
        {
            title: "Customer Care",
            links: [
                { label: "FAQ’s & Help", url: "/accounts/faqs" },
                { label: "Shipping & Delivery", url: "/accounts/shippinganddelivery" },
                { label: "Return & Exchange", url: "/accounts/returnandexchange" },
                { label: "Track Your Order", url: "https://meepaisa.odrtrk.live/" },
            ]
        },
        {
            title: "Our Information",
            links: [
                { label: "Privacy Policy", url: "/accounts/privacypolicy" },
                { label: "Terms & Conditions", url: "/accounts/terms&conditions" },
                { label: "Return Policy", url: "/accounts/returnpolicy" },
            ]
        },
        {
            title: "Top Categories",
            links: topCategories.map((category) => ({
                label: category.Category_Name,
                url: `/grocery/SubCategory/${category.Category_Id}`,
                component: (
                    <Link key={category.Category_Id} href={`/grocery/SubCategory/${category.Category_Id}`} sx={{
                        color: theme.palette.headerbutton.textcolorone,
                        textDecoration: "none",
                        "&:hover": { color: theme.palette.text.hover },
                    }}>
                        {category.Category_Name}
                    </Link>
                )
            }))
        }
    ];

    return (
        <>
            <Box
                sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    padding: 1,
                    mt: 5
                }}>
                <Box
                    display={"flex"}
                    minWidth={"100%"}
                    flexWrap={"wrap"}
                    flexDirection={"row"}
                    justifyContent={"space-around"}
                    sx={{
                        mt: 4,
                    }}>
                    {sections.map((section, index) => (
                        <Box
                            key={index}
                            sx={{
                                flexDirection: "column",
                                justifyContent: "start",
                                width: "150px",
                            }}>
                            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: "bold" }}>{section.title}</Typography>
                            {section.links.map((link, linkIndex) => (
                                <Box
                                    key={linkIndex}
                                    sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    {link.image && <img src={link.image} alt={link.label} style={{ marginRight: '8px', width: '24px', height: '24px' }} />}
                                    <Link href={link.url} sx={{
                                        color: theme.palette.headerbutton.textcolorone,
                                        textDecoration: "none",
                                        "&:hover": { color: theme.palette.text.hover },
                                    }}>
                                        <Typography sx={{fontWeight:"600",fontSize:"14px"}}> {link.label}</Typography>
                                    </Link>
                                </Box>
                            ))}
                        </Box>
                    ))}
                </Box>
                {winSize.screenSize.width > 601 &&
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'flex-end', xs: 'center' }, mt: 3, flexDirection: { xs: 'column', md: 'row', sm: 'row' } }}>
                        <Typography variant="body2" sx={{ fontWeight: "bold", ml: { md: '40px', xs: '10px' }, fontSize: { xs: "10px", md: '16px' }, }}>Copyright &copy; 2016 - {new Date().getFullYear()} EasyStepIn. All Rights Reserved.</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'flex-end', xs: 'center' }, marginLeft: { md: 'auto', xs: '1px' }, mr: { md: '40px', xs: '20px' }, }}>
                            <img src={BHIM_SVG_Logo} alt="BHIM" style={{ width: '44px', height: '44px', marginRight: { md: '8px', xs: '2px' }, objectFit: 'contain' }} />
                            <img src={sectigo} alt="Sectigo" style={{ width: '44px', height: '44px', marginRight: { md: '8px', xs: '2px' }, objectFit: 'contain' }} />
                            <img src={mclogo} alt="MasterCard" style={{ width: '44px', height: '44px', marginRight: { md: '8px', xs: '2px' }, objectFit: 'contain' }} />
                            <img src={Razorpay_logo} alt="Razorpay" style={{ width: '44px', height: '44px', marginRight: { md: '8px', xs: '2px' }, objectFit: 'contain' }} />
                            <img src={VisaLogo} alt="Visa" style={{ width: '44px', height: '44px', objectFit: 'contain' }} />
                        </Box>
                    </Box>
                }
                {winSize.screenSize.width < 600 &&
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'flex-end', xs: 'center' }, mt: 3, flexDirection: { xs: 'column', md: 'row', sm: 'row' } }}>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'flex-end', xs: 'center' }, marginLeft: { md: 'auto', xs: '0px' }, mr: { md: '40px', xs: '0px' }, }}>
                            <img src={BHIM_SVG_Logo} alt="BHIM" style={{ width: '44px', height: '44px', marginRight: { md: '8px', xs: '2px' }, objectFit: 'contain' }} />
                            <img src={sectigo} alt="Sectigo" style={{ width: '44px', height: '44px', marginRight: { md: '8px', xs: '2px' }, objectFit: 'contain' }} />
                            <img src={mclogo} alt="MasterCard" style={{ width: '44px', height: '44px', marginRight: { md: '8px', xs: '2px' }, objectFit: 'contain' }} />
                            <img src={Razorpay_logo} alt="Razorpay" style={{ width: '44px', height: '44px', marginRight: { md: '8px', xs: '2px' }, objectFit: 'contain' }} />
                            <img src={VisaLogo} alt="Visa" style={{ width: '44px', height: '44px', objectFit: 'contain' }} />
                        </Box>
                        <Typography variant="body2" sx={{ fontWeight: "bold", ml: { md: '40px', xs: '0px' }, fontSize: { xs: "10px", md: '16px' }, }}>Copyright &copy; 2016 - {new Date().getFullYear()} EasyStepIn. All Rights Reserved.</Typography>
                    </Box>
                }
            </Box>
            <Box height={20}></Box>
        </>
    );
}

export default Footer;
