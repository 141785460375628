import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { nodeApi, nodeCudApi, urlGenarator } from "../CommonAxios";

export const getTickesByTicketId = createAsyncThunk(
  'tickets/getTicket',
  async (formData, thunkAPI) => {
    try {
      const response = await nodeApi.post('/User_Ticket/Comment_Get', formData);
      if (response.data.Success_Response.Is_Data_Exist === "1") {
        return response.data.results;
      } else {
        return [];
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const ticketSlice = createSlice({
  name: "ticket",
  initialState: {
    comments: [],
    isLoading: false,
    isError: null
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTickesByTicketId.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(getTickesByTicketId.fulfilled, (state, action) => {
        state.comments = action.payload;
        state.isLoading = false;
      })
      .addCase(getTickesByTicketId.rejected, (state, action) => {
        state.isError = action.payload.error || "An error occurred";
        state.isLoading = false;
      });
  }
});



export const getUserTicketsAction = (formData, pagiantion) => {
  return new Promise(async (resolve, reject) => {
    await nodeApi.post(urlGenarator('/User_Ticket/Get', pagiantion), formData).then((res) => {
      if (res.data.Success_Response.Is_Data_Exist === "1") {
        resolve({ status: true, data: res.data.results, Pagination: res.data.Pagination })
      }
      else
        resolve({ status: false, data: [] })
    }).catch((err) => {
      resolve({ status: false, error: err.message })
    })
  })
}

export const createCommentForTicketId = (formData) => {
  return new Promise(async (resolve, reject) => {
    await nodeCudApi.post('/User_Ticket_Comment/Create', formData).then((res) => {
      if (res.data.Response_Status === "Success")
        resolve({ status: true })
    }).catch((err) => {
      resolve({ status: false, error: err.message })
    })
  })
}

export const ticketSearch = (formData) => {
  return new Promise(async (resolve, reject) => {
    await nodeApi.post("/User_Ticket/Get", formData).then((res) => {
      if (res.data.Success_Response.Is_Data_Exist === "1") {
        resolve({ status: true, data: res.data.results, Pagination: res.data.Pagination })
      }
      else
        resolve({ status: false, data: [] })
    }).catch((err) => {
      resolve({ status: false, error: err.message })
    })
  })
}