import React from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import useScreenSize from '../../../../utils/ScreenWidthHight';
import { Box } from '@mui/system';

const OrderStepper = ({ status, Line_Item_Order_Status }) => {
    // Define all possible statuses
    Line_Item_Order_Status = Line_Item_Order_Status === "ORDERED" ? "NEW" : Line_Item_Order_Status
    const allStatuses = ['NEW', 'CANCELLED', 'RETURN', 'Settled'];
    const winsize = useScreenSize()
    // If status is CANCELLED or RETURN, use the custom set of statuses
    let statusesToShow = ['NEW',"ACCEPTED", 'SHIPPED', 'OUT-FOR-DELIVERY', 'DELIVERED'];

    if (status === 'CANCELLED' || Line_Item_Order_Status ==="Settled".toUpperCase()) {
        statusesToShow = ['NEW', 'CANCELLED', 'Settled'.toUpperCase()];
    } else if (Line_Item_Order_Status === 'RETURN' && status === "Settled".toUpperCase() ) {
        statusesToShow = ['NEW', 'RETURN', 'Settled'];
    }

    // Get index of the current status
    const currentStatusIndex = statusesToShow.indexOf(Line_Item_Order_Status);
    // Determine completed steps based on current status index
    const completedSteps = statusesToShow.slice(0, currentStatusIndex + 1);
    
    // Map statuses to step labels
    const stepLabels = statusesToShow.map((Line_Item_Order_Status, index) => (
        <Step key={index} completed={completedSteps.includes(Line_Item_Order_Status)}>
            <StepLabel>{Line_Item_Order_Status}</StepLabel>
        </Step>
    ));
    if(winsize.screenSize.width>600)
    return (
        <Stepper   activeStep={currentStatusIndex} alternativeLabel orientation={winsize.screenSize.width < 600 ? "horizontal" : "horizontal"}
        >
            {stepLabels}
        </Stepper>
    )
    else {
        return (
            <Box sx={{width:"100%",height:"50px",}} >
            <Stepper
                    sx={{ position: "absolute",left:"0",transform:"scale(90%)" }}
                activeStep={currentStatusIndex} alternativeLabel orientation={winsize.screenSize.width < 600 ? "horizontal" : "horizontal"}
            >
                {stepLabels}
                </Stepper>
            </Box>
        )
    }
};

export default OrderStepper;
