import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi } from '../../CommonAxios';

const initialState = {
  subcategories: [],
  loading: false,
  error: null,
};

export const fetchSubcategories = createAsyncThunk(
  'subcategory/fetchSubcategories',
  async () => {
    try {
      const response = await nodeApi.post('/Home/SubCategories/Get', {});
      return response.data.results; 
    } catch (error) {
      throw error;
    }
  }
);

const SubcategorySlice = createSlice({
  name: 'subcategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubcategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubcategories.fulfilled, (state, action) => {
        state.loading = false;
        state.subcategories = action.payload; 
      })
      .addCase(fetchSubcategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default SubcategorySlice.reducer;


export const subcategoriesGetAction=(formData)=>{
  return new Promise(async (resolve,reject)=>{
      await nodeApi.post('/Home/SubCategories/Get',formData).then((res)=>{
          if(res.data.Success_Response.Is_Data_Exist==="1"){
              resolve({status:true,data:res.data.results})
          }else{
              resolve({status:false,data:[]})
          }
      }).catch((err)=>{
          resolve({status:false,error:err.message})
      })
  })
}