import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groceryFetchCards, grocerySelectAllCards } from '../../../store/slices/retail/home/TodayDealsSlice';
import { Box, Typography, Link } from '@mui/material';
import TodayDealsCard from '../../components/cards/TodayDealsCard';
import { useNavigate } from "react-router-dom";
import { useTheme } from '@emotion/react';
import useScreenSize from '../../../utils/ScreenWidthHight';

const TodayDeals = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useDispatch();
  const Deals = useSelector(grocerySelectAllCards);

  const [cardWidth, setCardWidth] = useState('220px');
  const [screensEachLineCards, setScreensEachLineCards] = useState(12);

  const winSize = useScreenSize();
  useEffect(() => {
    dispatch(groceryFetchCards({
      formData: { Business_Type: "GROCERY", Expired_Status: 1 },
      Pagination: { PageNo: 1, PageSize: (screensEachLineCards * 2) }
    }));
  }, [screensEachLineCards]);
  useEffect(() => {

    const screenWidth = winSize.screenSize.width;
    let calculatedWidth = Math.floor(screenWidth / 2) - 20;
    if (calculatedWidth > 220) {
      calculatedWidth = 220;
    }
    setCardWidth(calculatedWidth + 'px');
    setScreensEachLineCards(Math.floor(screenWidth / calculatedWidth))

  }, [winSize.screenSize]);

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',alignItems:"center" }}>
        <Typography sx={{ fontSize: '24px', fontWeight: 'bold', color: theme.palette.text.secondary, }}>
          Today Deals
        </Typography>
        <Link href="#" underline="hover" sx={{ fontWeight: 'bold', }} onClick={() => navigate('/grocery/offers/deals')}>
          View More
        </Link>
      </Box>

      <Box sx={{ display: 'flex', width: '100%', flexDirection: {xs:"row",sm:'row'}, margin: 'auto', flexWrap: {xs:"unset",sm:'wrap'}, columnGap: "10px",overflowX:"scroll" }}>
        {Deals?.length > 0 ? (
          Deals.slice(0, (screensEachLineCards * 2)).map((item, index) => (
            <TodayDealsCard
              key={index}
              card={item}
              onClick={() => navigate(`/grocery/offers/Deals/${item.Deal_Id}`)}
              cardWidth={cardWidth}
            />
          ))
        ) : (
          Array.from({ length: 10 }).map((_, index) => (
            <TodayDealsCard key={index} isSkeleton


            />
          ))
        )}
      </Box>
    </>
  );
};

export default TodayDeals;
