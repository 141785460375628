import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from './routes';
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { envConfig } from './store/slices/CommonAxios';
import CryptoJS from 'crypto-js';
import { authActions, authTokenGenerate } from './store/slices/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';


function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [sessionLoading, setSessionLoading] = useState(true);
  const [sessionExLoading, setSessionExLoading] = useState(false);
  const dispatch = useDispatch();
  const [sessionRecal, setSessionRecal] = useState(false);
  const isAuthenticated = useSelector(state => state.auth?.isAuthenticated || false);
  const authInterverl = useSelector(state => state.auth?.sessionExpiredTime);
  const sessionExpiredDate = useSelector(state => state.auth?.sessionExpiredDate);
  const [secondsToReload, setSecondsToReload] = useState(-1);
  const [refreshCall, setRefreshCall] = useState(false);
  const userAgent = navigator.userAgent;
  const getToken = async () => {
    let token;
    const encript = localStorage.getItem('Token')
    const bytesData = CryptoJS.AES.decrypt(encript, envConfig.CRYPTO_KEY);
    token = bytesData.toString(CryptoJS.enc.Utf8);

    return JSON.parse(token)
  }

  const setToken = async (token) => {
    const encryptedToken = CryptoJS.AES.encrypt(JSON.stringify(token), envConfig.CRYPTO_KEY).toString();
    localStorage.setItem('Token', encryptedToken)
  }

  const refreshAccessToken = async () => {
    try {
      if (!sessionExLoading) {
        const token = await getToken();
        if (token?.refreshToken) {
          setSessionExLoading(true);
          const res = await authTokenGenerate({ refreshToken: token.refreshToken });
          if (res.data.Tokens) {
            setSessionLoading(false);
            setToken(res.data.Tokens);
            dispatch(authActions.sessionExpireUpdate({ sessionExpiredTime: res.data.Tokens.exp - (5 * 60), sessionExpiredDate: new Date().setSeconds(new Date().getSeconds() + (res.data.Tokens.exp - (5 * 60))) }))
          }
          else if (res.data.message) {
            setSessionLoading(false);
            dispatch(authActions.signOutStateUpdate());
            localStorage.removeItem('Token');
          }
          else {
            setSessionRecal(true)
          }
          setSessionExLoading(false);
        }
      }
    } catch (error) {
      setSessionRecal(true)
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (secondsToReload == 0) {
        setRefreshCall(true)
      } else if (secondsToReload > 0)
        setSecondsToReload(secondsToReload - 1)
    }, 1000);
    return () => clearTimeout(timer);
  }, [secondsToReload]);



  useEffect(() => {

    (async () => {
      if (isAuthenticated) {
        try {
          const current = moment(new Date());
          const sED = moment(sessionExpiredDate);
          const difDates = sED.diff(current, 'seconds')
          const token = await getToken();
          if (token.refreshToken) {
            if ((difDates <= 0) || sessionRecal) {
              if (sessionRecal) {
                setSessionRecal(false)
              }
              if (difDates <= 0) {
                setSessionLoading(true);
              }
              refreshAccessToken()
            } else {
              setSessionLoading(false);
              setSecondsToReload((difDates < 0) ? authInterverl : difDates)
            }
          }
          else {
            setSessionLoading(false)
            dispatch(authActions.signOutStateUpdate())
            localStorage.removeItem('Token');
          }
        } catch (error) {
        }
      } else {
        setSessionLoading(false)
      }
    })()

  }, [sessionExpiredDate, sessionRecal, isAuthenticated]);

  useEffect(() => {
    if (refreshCall && !sessionLoading) {
      refreshAccessToken()
    }
    else
      setRefreshCall(false)
  }, [refreshCall])




  const router = createBrowserRouter(routes(isAuthenticated));

  return <><RouterProvider router={router} />
    <style>
      {
        userAgent.indexOf('Firefox')
        &&
        `
   * {
      scrollbar-width: none;
     }
    `
      }
    </style>
  </>

}

export default App;
