import React, { useMemo } from "react";
import { Card, CardActionArea, CardMedia, Typography, Box, Skeleton } from "@mui/material";
import { useTheme } from "@emotion/react";
import useScreenSize from "../../../utils/ScreenWidthHight";

const CouponCard = ({ data, onClick, discount, name, discountType, validateWithDate, imagePath, Discount_Label, isSkeleton = false, esiCashBackText, esiCashBack }) => {
    const theme = useTheme();
    const winSize = useScreenSize();

    const cardWidth = useMemo(() => {
        let cardWidth = (winSize.getWidthForGiveInput(10) - 85);
        if (cardWidth <= 423) {
            cardWidth = 423
        }
        if (winSize.screenSize.width <= cardWidth) {
            cardWidth = winSize.screenSize.width - 85
        }
        return cardWidth + "px"
    }, [
        winSize.screenSize.width
    ])

    const discountLabel = discountType === 'A' ? '/-' : '%';

    if (!isSkeleton)
        return (
            <Card style={{ border: "0.4px solid" + theme.palette.primary.main, borderRadius: "12px", maxWidth: cardWidth, minWidth: cardWidth, marginRight: '3%' }}>
                <CardActionArea onClick={() => onClick(data)} sx={{ maxWidth: cardWidth, minWidth: cardWidth, maxHeight: { xs: "160px", md: "182px" }, minHeight: { xs: "160px", md: "182px" }, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>                {(discount || esiCashBack) && (
                    <Box sx={{ backgroundColor: theme.palette.primary.main, color: "#fff", padding: "5px", height: { xs: "160px", md: "182px" }, width: '27px', display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography sx={{
                            fontSize: '22px',
                            writingMode: 'vertical-rl',
                            transform: 'rotate(180deg)',
                            textOrientation: 'mixed',
                            fontWeight: 'bold'
                        }}>
                            {Discount_Label} {discount ? `${discount}${discountLabel} Off` : `${esiCashBack}${data.ESI_Cashback_Type === "A" ? "/-" : data.ESI_Cashback_Type === "R" ? "Rewards" : '% Off'}`}
                        </Typography>
                    </Box>
                )}
                    <Box sx={{ width: "40%", ml: '10px' }}>
                        <CardMedia
                            component="img"
                            image={imagePath}
                            alt="Coupon Image"
                            style={{ height: "182px", width: "100%", objectFit: "contain" }}
                        />
                    </Box>
                    <Box sx={{ width: "55%", ml: '10px' }}>
                        <Typography sx={{ whiteSpace: "nowrap", fontSize: "22px", marginTop: '-10px', fontWeight: "bold" }}>{name?.slice(0, 12)}</Typography>
                        <Typography sx={{ color: theme.palette.text.secondary, fontSize: '11px', whiteSpace: "nowrap" }}>Coupon will Expire on</Typography>
                        {esiCashBack && (
                            <Typography sx={{
                                color: theme.palette.text.secondary, whiteSpace: "nowrap", fontSize: '11px', fontWeight: 'bold'
                            }}>
                                CASHBACK  {data.ESI_Cashback_Type === "A" && "₹"} {esiCashBack} {data.ESI_Cashback_Type === "A" ? "/-" : data.ESI_Cashback_Type === "R" ? "Rewards" : '% Off'}
                            </Typography>
                        )}
                        <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: "nowrap", fontSize: '11px', }}>{validateWithDate?.slice(0, 10)}</Typography>
                    </Box>
                </CardActionArea>
            </Card>

        );
    else {
        return (
            <Card style={{ borderRadius: "12px", maxWidth: cardWidth, minWidth: cardWidth, marginRight: '3%' }}>
                <Skeleton
                    variant="rectangular"
                    sx={{ width: cardWidth, height: "182px" }}
                />
            </Card>
        )
    }
};

export default CouponCard;