
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi } from '../../CommonAxios';
export const fetchCard = createAsyncThunk(
  'cards/fetchCard',
  async (formData) => {
    try {
      const response = await nodeApi.post('/Products/GetDeals', formData);
      return response.data

    } catch (error) {
      // console.error('Error fetching cards:', error);
      throw new Error('Failed to fetch cards');
    }
  }
);

export const dealCardsFetch = (formData) => {
  return new Promise(async (resolve, reject) => {
    await nodeApi.post('/Products/GetDeals', formData).then((res) => {
      if (res.data.Success_Response.Response_Code == "200") {
        resolve({ status: true, data: res.data.results })
      }
      else {
        resolve({ status: false, data: [] })
      }
    }).catch((err) => {
      resolve({ status: false, error: err.message })
    })
  })
}

const DealsSlice = createSlice({
  name: 'Deals',
  initialState: {
    cards: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCard.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCard.fulfilled, (state, action) => {
        state.loading = false
        state.cards = action.payload;
      })
      .addCase(fetchCard.rejected, (state, action) => {
        state.loading = false
        state.error = [];
        state.error = action.error;
      })
  }
});



export default DealsSlice.reducer;
