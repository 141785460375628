import React, { useEffect, useState } from 'react';
import { Typography, Grid, Box, Button, CardMedia, Table, TableBody, TableRow, TableCell, Skeleton } from '@mui/material'
import { useNavigate, useParams } from "react-router-dom";
import useScreenSize from '../../../../utils/ScreenWidthHight';
import { useSelector, useDispatch } from 'react-redux';
import { dealCardsFetch} from '../../../../store/slices/retail/home/Dealslice';
import parser from 'html-react-parser'
import { useTheme } from '@emotion/react';
import { GlobalContext } from '../../../../Context';

const DealsDetails = () => {
    const navigate = useNavigate();
    const winSize = useScreenSize();
    const dispatch = useDispatch();
    const { toolTipActive } = React.useContext(GlobalContext);
    const path = window.location.pathname
    const [deals, setDeals] = useState([])
    const auth = useSelector(state => state.auth)
    const [loading, setLoading] = useState(true);
    const theme = useTheme()
    const { id } = useParams()

    useEffect(() => {
        dealCardsFetchActionCall(id);
    }, [id]);

    const dealCardsFetchActionCall = async (id) => {
        setLoading(true)
        const formData = { Deal_Id: [id] }
        const response = await dealCardsFetch(formData)
        if (response.status) {
            setDeals(response.data)
            setLoading(false)
        }
        else {
            setLoading(false)
        }
    }

    if ((!deals || deals.length === 0) && !loading) {
        return <Box sx={{ height: '100vh', justifyContent: "center", display: 'flex', width: "100%", alignItems: "center", alignContent: 'center' }}>No data found</Box>;
    }

    return (
        <Box sx={{ width: "100%", minHeight: { sm: "100vh", xs: 'auto' }, display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            {loading ? (
                <Box sx={{ width: '97%', minHeight: { sm: "100vh", xs: 'auto' }, display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    <Box sx={{ width: { md: "60%", xs: "100%" }, height: "100%", display: { xs: "none", md: "flex" }, justifyContent: "center", alignItems: 'center' }}>
                        <Skeleton variant="rectangular" width="50%" height="50%" />
                    </Box>
                    <Box sx={{ width: { md: "40%", xs: "90%" }, height: "100%" }}>
                        <Skeleton variant="rectangular" width="100%" height={172} />
                        <Skeleton variant="text" width="60%" height={40} />
                        <Skeleton variant="text" width="80%" height={30} />
                        <Table sx={{ borderCollapse: 'collapse' }}>
                            <TableBody>
                                {Array(6).fill(0).map((_, index) => (
                                    <TableRow key={index} sx={{ borderBottom: 'none' }}>
                                        <TableCell sx={{ borderBottom: 'none' }}>
                                            <Skeleton variant="text" width="100px" />
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }}>
                                            <Skeleton variant="text" width="200px" />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Skeleton variant="rectangular" width="100%" height={30} sx={{ mt: 2 }} />
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mt: 2 }}>
                            <Skeleton variant="text" width="45%" height={40} />
                            <Skeleton variant="text" width="45%" height={40} />
                        </Box>
                    </Box>
                </Box>
            ) : !deals || deals.length === 0 ? (
                <Box sx={{ height: '70vh', justifyContent: "center", display: 'flex', width: "100%", alignItems: "center" }}>
                    No data found
                </Box>
            ) : (
                <Box sx={{ width: "100%", minHeight: { sm: "100vh", xs: 'auto' }, display: "flex", flexWrap: "wrap", justifyContent: "center" }} >
                    <Box sx={{ width: { md: "60%", xs: "100%" }, height: "100%", display: { xs: "none", md: "flex" }, justifyContent: "flex-end", }} >
                        <img src={require(`../../..../../../../assests/images/couponretail.png`)} style={{ width: "100%", height: "100%", scale: "60%", objectFit: "contain" }} />
                    </Box>
                    <Box sx={{ width: { md: "40%", xs: "90%" }, height: "100%", }} >

                        {!loading &&
                            deals?.map((deal, index) => {
                                return (
                                 <Box key={index} sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", gap: 1 }} >
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                                    <img src={deal?.Image} style={{ width: "100%", height: 172, objectFit: "contain" }} />
                                </Box>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                                    <Typography sx={{ color: theme.palette.text.primary, textWrap: 'nowrap', fontSize: { md: '34px', xs: '20px', sm: '34px' } }} >{deal.Partner_Name?.toUpperCase()}</Typography>
                                </Box>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                                    <Typography sx={{ color: theme.palette.text.primary, textWrap: 'nowrap' }} >{deal.Title}</Typography>
                                </Box>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                                    <Typography sx={{ fontSize: { md: '34px', sm: '34px', xs: '20px' }, color: theme.palette.text.primary }} >{deal?.Code?.toUpperCase()}</Typography>
                                </Box>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                                    <Typography sx={{ color: theme.palette.text.primary }} >{deal?.Name}</Typography>
                                </Box>

                                <Table sx={{ border: 'none' }}>
                                    <TableBody>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <TableRow sx={{ borderBottom: 'none' }}>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' }, mr: '25px' }}>Partner Name</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{deal.Partner_Name?.toUpperCase()}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableRow sx={{ borderBottom: 'none' }}>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' }, mr: '68px' }}>Brand</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <img
                                                            src={deal?.Logo_Path}
                                                            alt="Brand"
                                                            style={{
                                                                width: '54px',
                                                                height: '44px',
                                                                borderRadius: '4px',
                                                                objectFit: 'contain'
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableRow sx={{ borderBottom: 'none' }}>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' }, mr: '53px' }}>Category</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{deal?.Category_Name}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableRow sx={{ borderBottom: 'none' }}>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' }, mr: '56px' }}>Discount</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>
                                                            {deal?.Discount_Label?.toUpperCase()} {deal?.Discount_Type === "A" ? "₹" : null} {deal?.Discount}{deal?.Discount_Type === "P" ? "%" : null} off
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableRow sx={{ borderBottom: 'none' }}>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' }, mr: '50px' }}>Cashback</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>
                                                            {deal.Cashback_Label} {deal.Esi_Cashback_Type === "A" && "₹ "}{deal.Esi_Cashback_Amount} {deal.Esi_Cashback_Type === "P" && "%"} {deal.Esi_Cashback_Type === "A" && " /-"}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </Grid>
                                        </Grid>
                                    </TableBody>
                                </Table>

                                <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 3.6, alignItems: "center", mt: '20px' }} >
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }} >
                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' } }}> Start Date : </Typography>
                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: { md: '16px', sm: '16px', xs: '12px' } }} > {deal?.End_Date}</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }} >
                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Expires on : </Typography>
                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: { md: '16px', sm: '16px', xs: '12px' } }} > {deal?.End_Date}</Typography>
                                    </Box>

                                </Box>
                                <Box sx={{ maxWidth: { xs: "100%", md: "80%" }, border: `1px solid ${theme.palette.text.secondary}`, padding: "5px 7px", borderRadius: 3 }} >
                                    <Box sx={{ width: "100%" }} >
                                        {parser(deal?.Deal_Description ? deal?.Deal_Description : " ")}
                                    </Box>
                                </Box>
                                <Box sx={{ width: { xs: "100%", md: "80%" }, display: "flex", flexWrap: "wrap", justifyContent: "space-between" }} >
                                    <Button variant="contained" fullWidth
                                        sx={{ width: '100%', borderRadius: '12px' }}

                                        onClick={() => {
                                            if (path.includes("retail"))
                                                navigate(`/retail/products?Partner_Details_Id=${deal?.Partner_Details_Id}&Category_Id=${deal.Category_Id}&Brand_Id=${deal.Brand_Id}`)
                                            else
                                                navigate(`/grocery/products?Partner_Details_Id=${deal?.Partner_Details_Id}&Category_Id=${deal.Category_Id}&Brand_Id=${deal.Brand_Id}`)

                                        }}
                                    >
                                        Grab Offer
                                    </Button>
                                </Box>
                            </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
            )}
        </Box>
       
    );
};

export default DealsDetails;
