import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Box, Card, CardContent, CardMedia, TextField, Button, Link } from '@mui/material';
import { useTheme } from '@emotion/react';
import useScreenSize from '../../../utils/ScreenWidthHight';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { contactSupport, contactusAction } from '../../../store/slices/retail/home/ContactUslice';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalContext } from '../../../Context';

const Contactus = () => {

    const contact = useSelector(state => state.contact.contactStatus);
    const dispatch = useDispatch();
    const { toolTipActive } = React.useContext(GlobalContext);
    const auth = useSelector(state => state.auth)

    const theme = useTheme();
    const winSize = useScreenSize();
    const [error, setError] = useState(false);

    const validationSchema = Yup.object({
        name: Yup.string()
            .min(2, 'Name must be at least 2 characters')
            .max(50, 'Name cannot exceed 50 characters')
            .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9 !@#$%^&*()\-_=+{};:,.<>?~'"|[\]\\/]+$/, 'Please use a combination of letters, numbers, and specific symbols. Avoid using only numbers or special characters.')

            .required('Name is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        contact: Yup.string()
            .matches(/^[0-9]{10}$/, 'Enter Contact Number correctly')
            .required('Contact number is required'),
        message: Yup.string()
            .min(10, 'Message must be at least 10 characters')
            .max(500, 'Message cannot exceed 500 characters')
            .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9 !@#$%^&*()\-_=+{};:,.<>?~'"|[\]\\/]+$/, 'Please use a combination of letters, numbers, and specific symbols. Avoid using only numbers or special characters.')

            .required('Message is required'),
    });
    const contactusActionCall = async (formData) => {
        const response = await contactusAction(formData)
        if (response.status)
            toolTipActive("success", "Your Form Has Been Submitted Successfully");
    }
    return (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", minHeight: "100vh" }} >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: {md:10, sm:10, xs:4}, width: "85%" }}>
                {winSize.screenSize.width < 600 &&
                    <Box sx={{ display: "flex", width: '100%', alignItems: "flex-start", justifyContent: 'flex-start' }}>
                <Typography sx={{ display: 'flex', width: '100%', alignItems: "flex-start", justifyContent: 'flex-start',color: theme.palette.text.secondary,  }}>
                    Contact Us
                    </Typography>
                </Box>}
                
               {winSize.screenSize.width>600 && <Typography variant="h5" sx={{ textAlign: 'center', marginTop: "2%", fontSize: '24px', fontWeight: 'bold', color: theme.palette.text.secondary }}>
                    Contact Us
                </Typography>}
                <Box sx={{ display: 'flex', width: "100%", flexDirection: "row", justifyContent: "center" }}>
                    {winSize.screenSize.width > 600 && <img src={require(`../../../assests/images/grouppic.png`)} style={{ width: "100%", height: 'auto' }} alt="Group Pic" />}
                    {winSize.screenSize.width < 600 && <img src={require(`../../../assests/images/grouppic.png`)} style={{ width: "328px", height: '168px' }} alt="Group Pic" />}
                </Box>

                {winSize.screenSize.width < 600 &&
                    <Box sx={{ display: "flex", width: '100%', alignItems: "flex-start", justifyContent: 'flex-start' }}>
                        <Typography sx={{ display: 'flex', width: '100%', alignItems: "flex-start", justifyContent: 'flex-start', color: theme.palette.text.secondary, }}>
                            EasyStepIn IT Services Private Limited
                        </Typography>
                    </Box>}
                {winSize.screenSize.width>600 &&<Typography sx={{ display: "flex", justifyContent: "center", fontWeight: 'bold', color: theme.palette.text.secondary, fontSize:{md:'30px', sm:'30px', xs:'16px'} }}>
                    EasyStepIn IT Services Private Limited
                </Typography>}

                <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: { xs: "center", sm: "flex-start", md: "flex-start" }, gap: { xs: 5, md: 0, sm: 2.5 }, }} >
                    {[{ title: "Address", description: "Regent Gateway, Plot No.5B, ITPL Main Road, Hoodi, Whitefield, Bengalore, KA- 521102.", isMap: true, map: { link: "https://maps.app.goo.gl/7FrxegB1nmLLhtnS7", title: "View on Map" }, icon: "contactPageLocation.png" }, { title: "Contact", description: "+91 9108424077", icon: "contactPagePhone.png" }, { title: "Email", description: "Support@meepaisa.com", email: "Support@meepaisa.com", icon: "contactPageMessage.png" }].map((contact, index) => {
                        return (
                            <Box sx={{ position: "relative", width: { md: (winSize.screenSize.width / 3) - 100, sm: (winSize.screenSize.width / 2) - 10, xs: "90%" }, minHeight: 200, backgroundColor: theme.palette.input.borderColor, borderRadius: 10, display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: 'center' }} key={index} >
                                <CardMedia
                                    component="img"
                                    image={require("../../../assests/images/" + contact.icon)}
                                    alt="Card Image"
                                    sx={{ width: 54, maxWidth: 54, position: "absolute", top: -(54 / 2), left: { md: (((winSize.screenSize.width / 3) - 120) - (54 / 2)) / 2, sm: "45%", xs: "43%" } }}
                                />
                                <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: (54 / 2) / 8 }} >
                                    <Typography variant='h5' sx={{ color: theme.palette.primary.contrastText, textAlign: "center", fontSize: "20px" }} >
                                        {contact.title}
                                    </Typography>
                                </Box>
                                {contact.title === 'Contact' ? (
                                    <Typography sx={{ color: theme.palette.primary.contrastText }}>
                                        <Link href={`tel:${contact.description}`} sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold' }}>
                                            {contact.description}
                                        </Link>
                                    </Typography>
                                ) : contact.title === 'Email' ? (
                                    <Box sx={{ width: "80%", height: "100px", display: 'flex', flexDirection: "column", justifyContent: "space-around", alignItems: "center" }} >
                                        <Link href={`mailTo:${contact.description}`} sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold' }}>
                                            {contact.description}
                                        </Link>
                                        <Link href={`mailTo:${"info@meepaisa.com"}`} sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold' }}>
                                            {"info@meepaisa.com"}
                                        </Link>
                                    </Box>
                                ) : (
                                    <Box sx={{ width: "80%", display: 'flex', flexWrap: "wrap", justifyContent: "center" }} >
                                        <Typography sx={{ color: theme.palette.primary.contrastText, textAlign: "center", fontSize: "14px" }} >
                                            {contact.description}
                                        </Typography>
                                    </Box>
                                )}
                                <Box sx={{ width: "80%", display: "flex", flexDirection: "row", justifyContent: "center" }} >
                                    {contact.isMap ? (
                                        <Link sx={{
                                            mb: 1,
                                            textDecoration: 'none',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                            },
                                            color: "white"
                                        }}
                                            href={contact.map.link} target="_blank" rel="noopener">
                                            {contact.map.title}
                                        </Link>
                                    ) : null}
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
                <Box>
                    <Formik
                        initialValues={{ name: '', email: '', contact: '', message: '' }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { resetForm }) => {
                            contactusActionCall({
                                NAME: values.name,
                                EMAIL: values.email,
                                CONTACT: values.contact,
                                MESSAGE: values.message,
                                CREATED_BY: auth.user.Email_Id
                            })

                            if (!validationSchema.isValidSync(values)) {

                                setError(true);
                                return;
                            }
                            resetForm();
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Grid container spacing={3} >
                                    {/* Forms */}
                                    <Grid item xs={12} sm={6} marginBottom={'20px'}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: {md:"5%", sm:'5%',xs:'unset'} }}>
                                            {winSize.screenSize.width < 600 &&
                                                <Box sx={{ display: "flex", width: '100%', alignItems: "flex-start", justifyContent: 'flex-start' }}>
                                                    <Typography sx={{ display: 'flex', width: '100%', alignItems: "flex-start", justifyContent: 'flex-start', fontSize:'20px', mb:'4px' }}>
                                                        Enter your information to send a message
                                                    </Typography>
                                                </Box>}
                                            
                                            {winSize.screenSize.width > 600 &&
                                                <Box sx={{ width: "100%", height: "50px", display: 'flex', alignItems: "flex-start", mb: '20px' }} >
                                                <Typography sx={{fontSize:'20px',}} >  Enter your information to send a message</Typography>
                                            </Box>}
                                            <Field as={TextField} fullWidth name="name" label="Name" variant="outlined" sx={{ marginBottom: '1rem' }} error={errors.name && touched.name} helperText={errors.name && touched.name ? errors.name : null} />
                                            <Field as={TextField} fullWidth name="email" label="Email" variant="outlined" sx={{ marginBottom: '1rem' }} error={errors.email && touched.email} helperText={errors.email && touched.email ? errors.email : null} />
                                            <Field as={TextField} fullWidth name="contact" label="Contact" variant="outlined" sx={{ marginBottom: '1rem' }} error={errors.contact && touched.contact} helperText={errors.contact && touched.contact ? errors.contact : null} />
                                            <Field as={TextField} fullWidth multiline rows={4} name="message" label="Message" variant="outlined" sx={{ marginBottom: '1rem' }} error={errors.message && touched.message} helperText={errors.message && touched.message ? errors.message : null} />
                                            {error && (
                                                <Typography variant="body2" color="error" >
                                                    Please fill out all required fields.
                                                </Typography>
                                            )}
                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                                                <Button type="submit" variant="contained" color="primary">
                                                    Send
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {/* Right Image */}
                                    {
                                        winSize.screenSize.width > 1050 &&
                                        <Grid item xs={12} sm={6}>
                                        <Box >
                                            <img src={require(`../../../assests/images/tickets.png`)} alt="Right Image" style={{ width: '100%', maxWidth: '778px', height: '447px' }} /></Box>
                                    </Grid>}
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Box>
    );
};

export default Contactus;

