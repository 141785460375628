import { Box,Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { brandsFetch } from "../../../store/slices/retail/home/BrandSlice";
import BrandCard from "../../components/cards/BrandCard";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import useScreenSize from "../../../utils/ScreenWidthHight";

const Brand = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate()
    const [brands, setBrands] = useState([])
    const winSize = useScreenSize();
    const componetRef = useRef();
    const [cardWidth, setCardWidth] = useState('220px');
    const [screensEachLineCards, setScreensEachLineCards] = useState(5);

    useEffect(() => {
        if (winSize.screenSize.width < 600) {
            
            brandsCall({ Is_Retail: 1 }, { PageNo: 1, PageSize:10})
        }
        else if (winSize.screenSize.width > 701 && winSize.screenSize.width < 900){
            
            brandsCall({ Is_Retail: 1 }, { PageNo: 1, PageSize: (screensEachLineCards * 3) })
        }
        else
    brandsCall({ Is_Retail: 1 }, { PageNo: 1, PageSize: (screensEachLineCards * 3) })
    }, [screensEachLineCards])

    useEffect(() => {
        const screenWidth = componetRef.current.offsetWidth;
        let calculatedWidth = Math.floor(screenWidth / 2) - 20;
        if (calculatedWidth > 200) {
            calculatedWidth = 200;
        }
        setCardWidth(calculatedWidth + 'px');
        setScreensEachLineCards(Math.floor(screenWidth / calculatedWidth))
    }, [winSize.screenSize]);

    const brandsCall = async (formData, Pagination) => {
        const response = await brandsFetch(formData, Pagination)
        if (response.status) {
            setBrands(response.data)
        }
        else {
        }
    }

    return (
        <Box ref={componetRef} sx={{ display: "flex", flexDirection: "column", mt: "1%",width:"100%"  }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" ,alignItems:'center'}}>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: theme.palette.text.secondary,
                    }}
                >
                    Brands
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                      
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        "&:hover": {
                            textDecoration: "underline"
                        },
                        fontWeight:'bold'
                    }}
                    onClick={() => navigate("/retail/brandpage")}
                >
                    View More
                </Typography>
            </Box>

            <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", columnGap: { md: brands.length < (screensEachLineCards * 2) ? '28px' : 0, xs: "3px" }, rowGap: '20px', justifyContent: { md: "space-between", sm: "space-evenly", xs: "space-between", } }}>
                {brands.length > 0 && brands.slice(0, (winSize.screenSize.width < 600 ? (screensEachLineCards * 5) : (winSize.screenSize.width > 701 && winSize.screenSize.width < 900) ? (screensEachLineCards * 2) : (screensEachLineCards * 2))).map((brand, index) => {
                    return (
                        <Box key={index} >
                            <BrandCard
                                cardWidth={cardWidth}
                                image={brand.Brand_Image}
                                borderColor={theme.palette.text.secondary}
                                onClick={() => navigate(`/retail/brand/${brand.Brand_Id}`
                                
                                )}
                            />
                        </Box>
                    );
                })}
                {winSize.screenSize.width > 600 && brands.length === 0 &&
                    Array(12).fill(1).map((brand, index) => (
                        <BrandCard
                            cardWidth={cardWidth}
                            key={index}
                            isSkeleton
                        />
                    ))
                }

                {winSize.screenSize.width < 600 && brands.length === 0 &&
                    Array(10).fill(1).map((brand, index) => (
                        <BrandCard
                            cardWidth={cardWidth}
                            key={index}
                            isSkeleton
                        />
                    ))
                }

            </Box>
        </Box>
    );
};

export default Brand;