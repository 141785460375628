import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi } from '../../CommonAxios';

export const fetchFAQ = createAsyncThunk(
    'faq/fetchFAQ',
    async () => {
        try {
            const response = await nodeApi.post('/FAQ/Get');
            const data = response.data;

            if (data.Success_Response.Is_Data_Exist === "0") {
                return [];
            } else {
                return data.results;
            }
        } catch (error) {
            throw error.message;
        }
    }
);

const initialState = {
    faqData: [],
    loading: false,
    error: null,
};

const faqSlice = createSlice({
    name: 'faq',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchFAQ.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFAQ.fulfilled, (state, action) => {
                state.loading = false;
                state.faqData = action.payload;
            })
            .addCase(fetchFAQ.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default faqSlice.reducer;