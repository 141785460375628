import { envConfig } from "../store/slices/CommonAxios";


const razorpayPay = (Razorpay, input, responseFunction) => {

    var options = {
        name: "MeePaisa",
        description: "Order Transaction",
        image: "https://www.meepaisa.com/favicon.ico",
        currency: 'INR',
        key: envConfig.API_KEY, // Your api key
        amount: String(Number(input.amount) * 100).split('.')[0],
        prefill: {
            name: input.user.User_Name,
            email: input.user.Email_Id,
            contact: input.user.Mobile,
        },
        theme: { color: '#27B6CC' },
        handler: function (response) {
            responseFunction(true, response);
        },
        "modal": {
            "ondismiss": function (response) {
                responseFunction(false, response);
            }
        }
    }


    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
        responseFunction(false, response);
    });

    rzp1.open();

};

export const razorpayPayPromise = (Razorpay, input) => {
    return new Promise(async (resolve, reject) => {
        var options = {
            name: "MeePaisa",
            description: "Order Transaction",
            image: "https://www.meepaisa.com/favicon.ico",
            currency: 'INR',
            key: envConfig.API_KEY, // Your api key
            amount: String(Number(input.amount) * 100),
            prefill: {
                name: input.user.User_Name,
                email: input.user.Email_Id,
                contact: input.user.Mobile,
            },
            theme: { color: '#27B6CC' },
            handler: function (response) {
                resolve({ status: true, response });
            },
            "modal": {
                "ondismiss": function (response) {
                    resolve({ status: false, response });
                }
            }
        }


        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
            resolve({ status: false, response });
        });

        rzp1.open();
    })
}

export default razorpayPay;