import { Box, Button, Typography, } from '@mui/material'
import React from 'react'
import cart from '../../../assests/images/paymentSuccess.png';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
    const navigate = useNavigate();
    
    return (
        <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', width: "100%", height: { xs: "80vh", md: '100vh', sm: '100vh' } }}>
            <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: 1 }} >
                <img src={cart} alt='cart' />
                <Typography sx={{ fontWeight: "400", fontSize: { sm: '34px', md: '34px', xs: '16px' }, display: 'flex', justifyContent: 'center', textAlign: 'center', width: '100%' }}>
                    Thank you for your order!  </Typography>
                    <Typography sx={{  fontWeight: "400", fontSize: { sm: '34px', md: '34px', xs: '16px' }, display: 'flex', justifyContent: 'center', textAlign: 'center', width: '100%' }}> Your order has been placed successfully.   </Typography>
                <Box sx={{ width: { sm: "45%", md: '45%', xs: '100%' }, display: "flex", justifyContent: "center", alignItems: "center" }} >
                    <Typography sx={{ color: "grey", width: "100%", textAlign: "center", fontSize: { sm: '34px', md: '34px', xs: '16px' } }}>
                        We look forward to serving you again soon.
                    </Typography>
                </Box>
                <Box sx={{ borderRadius: '10px', display: 'flex', flexDirection: 'row', gap: { md: 60, sm: 60, xs: '30px' } , mt:'30px'}}>
                    <Button onClick={() => {
                        navigate("/accounts/orders")
                    }} sx={{ color: "black", border: "1px solid grey", minWidth: { md: "200px", sm: '200px', xs: '100px' }, borderRadius: 2 }}  >View Orders</Button>
                    <Button
                        onClick={() => {
                            navigate("/retail")
                        }}
                        variant="contained" sx={{ minWidth: { md: "200px", sm: '200px', xs: '100px' }, borderRadius: 2 }}  >Continue Shopping</Button>
                </Box>
            </Box>
        </Box>

    );
}

export default PaymentSuccess;