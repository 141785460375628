import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BrandReviewPage from '../../components/cards/brandPage/BrandReviewPage';
// import ReviewForm from '../../components/retail/brandPage/ReviewForm';
import * as Yup from 'yup';

import { Box, Container, Grid, Typography } from '@mui/material';
// import { brandReviews } from '../../../store/slices/retail/home/BrandReviewSlice';
import { getPartners, partnerFetch } from '../../../store/slices/retail/home/PartnerSlice';
import { partnerReviewsFetch, partnerReviewsFetchById } from '../../../store/slices/retail/home/partnerReviewSlice';
import BrandComments from '../../components/cards/brandPage/BrandComments';
import PartnerReviewForm from '../../components/cards/partnerPage/PartnerReviewForm';

const PartnerPage = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const auth = useSelector(state => state.auth);
    const [partners, setPartners] = useState([]);
    const [yourComments, setYourComments] = useState([]);
    const [partnersReviewsById, setReviewsPartnersById] = useState([]);
    const EmailID = useSelector((state) => state.auth.user.Email_Id);
    const userDetails = useSelector((state) => state.auth);
    const isAuthenticated = userDetails.isAuthenticated;


    useEffect(() => {
        // dispatch(getPartners())
        dispatch(partnerReviewsFetch());
    }, []);


    useEffect(() => {
        partnersCall(id);
        partnersReviewCall(id);
    }, [id]);

    const partnersCall = async (id) => {
        const formData = { Partner_Details_Id: [id] };
        const response = await partnerFetch(formData);
        if (response.status) {

            setPartners(response.data);
        } else {
        }
    };

    const partnersReviewCall = async (id) => {
        const formData = { Partner_Details_Id: [id] };
        const response = await partnerReviewsFetchById(formData);
        if (response.status) {

            setYourComments(response.data.filter(partnerReview => partnerReview.Reviewer_Email_Id === EmailID));
            setReviewsPartnersById(response.data.filter(partnerReview => partnerReview.Reviewer_Email_Id !== EmailID));
        } else {
        }
    };




    return (
        <Box sx={{ margin: 3 }}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    {partners.map((partner, index) => (
                        <BrandReviewPage
                            key={index}
                            imagePath={partner.Logo_Path}
                            imageName={partner.Name}
                            brandName={partner.Name}
                            description={partner.Description}
                            rating={partner.Esi_Rating}
                            date={partner.Updated_Date}
                            review={partner.Esi_Review}
                        />
                    ))}
                </Grid>
                <Grid item xs={12} md={4} >
                    {isAuthenticated === true && (
                        yourComments.length > 0 ? (
                            yourComments.slice(0, 1).map((review, index) => (
                                <PartnerReviewForm
                                    key={index}
                                    partnerId={id}
                                    data={review}
                                    partners={partners}
                                    EmailID={EmailID}
                                    // partnerEmailId={partners?.Partner_Email_Id}
                                    ReviewerName={review.Reviewer_Name}
                                    partnerReviewId={review.Partner_Review_Id}
                                    partnerReviews={partnersReviewsById}
                                    isUpdateForm={true}
                                    partnersReviewsCall={partnersReviewCall}
                                />
                            ))
                        ) : (
                            <PartnerReviewForm
                                partnerId={id}
                                EmailID={EmailID}
                                ReviewerName={auth?.user?.Full_Name}
                                partners={partners}
                                partnerEmailId={partners?.Partner_Email_Id}
                                isUpdateForm={false}
                                partnerReviews={partnersReviewsById}
                                partnersReviewsCall={partnersReviewCall}
                            />
                        )
                    )}

                    <Grid sx={{
                        height: "40vh", overflowY: "scroll", "&::-webkit-scrollbar-thumb": {
                            display: "none",
                        },
                        "&::-webkit-scrollbar": {
                            width: "0px",
                            background: "transparent",
                        },
                    }}>
                        {partnersReviewsById.length === 0 ? (
                            <p>No comments available</p>
                        ) : (
                            partnersReviewsById.map((review, index) => (
                                <BrandComments
                                    key={index}
                                    data={review}
                                    rating={review.Rating}
                                    comments={review.Comments}
                                    date={review.Modified_Date}
                                    ProfilePicPath={review.Profile_Pic_Path}
                                />
                            ))
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PartnerPage;
