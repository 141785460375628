
import { useTheme } from "@emotion/react";
import { CheckBox } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useState } from "react";
import info from '../../../assests/images/info.png'
import useScreenSize from "../../../utils/ScreenWidthHight";
 
const PriceDetails = ({ totalMrp, discountMrp, partnerCoupon, corporateCode, giftCode, meepaisaCoupon, shippingPrice, walletAmount, totalAmount, useWallet, setUseWallet, placeOrder,giftWrapAmount }) => {
    const theme = useTheme();
    const winsize = useScreenSize()
 
 
 
    return (<>
    
       { winsize.screenSize.width> 900 && <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',  }}>
            <Typography sx={{ marginRight: '7px', }}>Don't reload this page</Typography>
            <img src={info} alt="info" style={{ width: '24px', height: '24px',marginRight:'10px' , }}></img>
        </Box>}
        <Box sx={{ margin: { md: 3, sm: 3, xs: 2 }, marginTop: {md:"50px",xs:'0px',sm:'50px'} }}>
           
 
            <Box sx={{   padding: "10px", marginTop: "10px", maxWidth: "538px" }}>
                <Box sx={{ marginBottom: "30px" }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold",color: theme.palette.text.secondary, }}>Price Details</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                    <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary, }}>Total MRP</Typography>
                    <Typography variant="subtitle1" sx={{fontWeight:'bold'}}>{totalMrp}</Typography>
 
                </Box>
 
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                    <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary, }}>Discount on MRP</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>-{discountMrp}</Typography>
 
                </Box>
                {Number(partnerCoupon) != 0 &&
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary, }}>Partner Coupon</Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>-{partnerCoupon}</Typography>
 
                    </Box>
                }
                {Number(corporateCode) != 0 &&
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary, }}>Corporate Code</Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>-{corporateCode}</Typography>
 
                    </Box>
                }
                {Number(giftCode) != 0 &&
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary, }}>Gift Code</Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>-{giftCode}</Typography>
 
                    </Box>
                }
                {Number(giftWrapAmount) != 0 &&
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary, }}>Gift Wrap</Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>+{giftWrapAmount}</Typography>
 
                    </Box>
                }
                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                    <Typography variant="subtitle1" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Meepaisa Coupon</Typography>
                    <Typography variant="subtitle1">{meepaisaCoupon}</Typography>
 
                </Box> */}
                {Number(shippingPrice) != 0 &&
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary, }}>Shipping Price</Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>+{shippingPrice}</Typography>
 
                    </Box>
                }
                {Number(walletAmount) != 0 &&
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1px' }}>
                        <FormControlLabel sx={{fontWeight:'bold'}}
                            control={<Checkbox checked={useWallet} onClick={() => {
                                setUseWallet(state => !state)
                            }} disabled={walletAmount === 0} />}
                        // label={useWallet ? `Used Wallet Balance` : `Available Wallet Balance`}
                            label={<Typography  sx={{ fontWeight: 'bold' }}>{useWallet ? `Used Wallet Balance` : `Available Wallet Balance`}</Typography>}
                            // label={useWallet ? `${walletAmount.toFixed(2)}` : 0}
                            style={{ marginTop: '10px', }}
                        />
                        <Typography variant="subtitle1" sx={{fontWeight:'bold'}}>{useWallet && "-"}{walletAmount}</Typography>
                    </Box>
                }
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' ,mt:'15px'}}>
                    <Typography sx={{fontSize:'24px', color:theme.palette.text.secondary}}>Total Amount</Typography>
                    <Typography sx={{color:theme.palette.text.secondary, fontSize:'24px', fontWeight:'bold'}}>{totalAmount}</Typography>
                </Box>
 
                <Box>
                    <Button onClick={placeOrder} variant="contained" fullWidth size="large">Pay Now</Button>
                </Box>
            </Box>
        </Box>
 
    </>)
}
export default PriceDetails;