import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { nodeApi, nodeCudApi, urlGenarator } from '../CommonAxios'
import axios from 'axios'

const initialState = {
    referNEarn: [],
    loading: false,
    error: "",
    Pagination: {},
    message: ""
}

export const referAndEarn = createAsyncThunk("referAndEarn/referAndEarn", async (formData) => {
    const response = await nodeApi.post(urlGenarator("/Refer_Earn/Get", formData.Pagination), formData.formData)
    if (response.data.Success_Response.Is_Data_Exist === "0") {
        return []
    } else {

        return response.data
    }
})
// export const referAndEarn = createAsyncThunk("referAndEarn/referAndEarn", async (formData) => {
//     const response = await nodeApi.post("/Refer_Earn/Get?PageNo=1&PageSize=800&SortBy=Created_Date&SortOrder=DESC", formData)
//     const data = response?.data
//     if (data.Success_Response.Is_Data_Exist == "0") {
//         return []
//     } else {
//         return data.results || [];
//     }
// })




export const referAndEarnCreate = createAsyncThunk(
    'referAndEarn/referAndEarnCreate',
    async (input, { rejectWithValue, }) => {
        try {
            const response = await nodeCudApi.post("/Refer_Earn/Create", input.payload);
            if (response.data.Response_Status === 'Success') {
                input.toolTipActive("success", response.data.UI_Display_Message)
                return response.data.UI_Display_Message;
            } else {
                input.toolTipActive("error", response.data.UI_Display_Message)
                return rejectWithValue(response.data.UI_Display_Message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);



const referandEarnSlice = createSlice({
    name: 'referAndEarn',
    initialState,
    reducers: {

    },

    extraReducers(builder) {
        builder
            .addCase(referAndEarn.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(referAndEarn.fulfilled, (state, action) => {
                state.loading = false
                state.referNEarn = action.payload.results;
                state.error = "";
                state.Pagination = action.payload.Pagination;
            })
            .addCase(referAndEarn.rejected, (state, action) => {
                state.loading = false
                state.error = [];
                state.error = action.error.message;
            })
            .addCase(referAndEarnCreate.fulfilled, (state, action) => {
                state.loading = false;
                const payload = [action.payload];
                state.message = action.payload;
                state.referNEarn = [...state.referNEarn, action.payload];
                state.error = '';
            })

            .addCase(referAndEarnCreate.rejected, (state, action) => {
                state.loading = false;
                state.message = action.payload.toString();
                state.error = action.payload;
            });



    }
})

export default referandEarnSlice.reducer;
