import React from 'react';
import { Box, Typography } from '@mui/material';
import phone from '../../../assests/images/ridessPhone.png';
import crd from '../../../assests/images/crd.png'
import useScreenSize from '../../../utils/ScreenWidthHight';

const RideWorks = () => {
    const winSize = useScreenSize()

    return (
        <Box>
            <Box sx={{ width: "100%", minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} >
                <Box sx={{ width: { xs: "90%", md: "100%" }, display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "space-around", gap: { md: "190px", xs: 0 }, alignItems: "center" }} >
                    <First />
                    <First indexNumber='2' type='left' title='Create an Account' description='Often available 24/7, providing transportation at any time of day or night.' />
                </Box>
                {
                    winSize.screenSize.width >= 350 && <Box sx={{ width: "100%", height: { xs: "600px", sm: "300", md: "110px" }, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                        <MobileComponent />
                    </Box>}
                <Box sx={{ width: { xs: "90%", md: "100%" }, display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "space-around", gap: { md: "190px", xs: 0 }, alignItems: "center" }} >
                    <First indexNumber='3' title='TRANSPARENCY' description='Clear pricing, driver information, and ride details enhance trust and reliability.' />
                    <First indexNumber='4' type='left' title='REDUCED NEED FOR CAR OWNERSHIP' description='Enables urban dwellers to rely less on personal vehicles, saving on maintenance and parking costs.' />
                </Box>
            </Box>
        </Box>
    );
}

export default RideWorks;


export const First = ({ type = "right", title = "Install the App", description = "Go to your smartphone's app store Search for the Meepaisa Download and install the app", indexNumber = "1" }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '90%', md: "25%" }, alignItems: 'center', justifyContent: type, position: "relative" }} >
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: type === "right" ? "end" : "start", width: "100%" }}  >
                <img style={{
                    position: 'absolute', objectFit: "contain", width: '48px', height: '48px'
                }} src={crd} alt='img' />
                <Typography sx={{ position: 'relative', top: "0px", left: type === "right" ? "-14px" : "14px", fontSize: "34px", fontWeight: "bold", color: "#27b6cc", }}>
                    {indexNumber}
                </Typography>
            </Box>
            <Box sx={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end' }} >
                <Typography sx={{ fontSize: "24px", fonntweight: 'bold', width: "100%", textAlign: type === "right" ? "end" : "start" }}>
                    {title}
                </Typography>
                <Typography sx={{ width: '100%', textAlign: type === "right" ? "end" : "start" }}>
                    {description}
                </Typography>
            </Box>

        </Box >
    )
}



export const MobileComponent = () => {
    const winSize = useScreenSize()
    if (winSize.screenSize.width >= 350)
        return (
            <Box sx={{ width: { md: "400px", xs: "350px" }, height: { md: "400px", xs: "350px" }, }} >
                <Box sx={{ width: "100%", height: "100%", borderRadius: "400px", backgroundColor: "#FDD835" }} >
                    <img src={phone} alt='phone' style={{ display: 'flex', alignItems: 'center', position: 'relative', bottom: winSize.screenSize.width < 600 ? "25%" : '15%', left: winSize.screenSize.width < 600 ? "15%" : '15%' }} />
                </Box>
            </Box>
        )
}
