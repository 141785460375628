import React, { useEffect, useMemo, useState } from 'react'
import { productDetailByProductId } from '../../../store/slices/retail/ProductDetails/ProductDetailsActions';
import ProductModal from '../ProductDetail.js/ProductModal'
const HomeProductModal = (props) => {

    const [cart, setCart] = useState(1);
    const [cartProduct, setCartProduct] = useState({})


    useEffect(() => {
        if (props.modalProductId) {
          productSMDetailByProductIdCall({ Product_Id: [props.modalProductId] })
        }
      }, [props.modalProductId])

    const handleSetCartProduct = (product) => {
        setCartProduct(product)
      }


      const productSMDetailByProductIdCall = async (formData) => {
        const response = await productDetailByProductId(formData)
        if (response.status) {
          setCart(response.data[0])
        }
        else {
          setCart([])
        }
      }

      const productmodal=useMemo(()=>{
        if(props.modalOpen)
        return <ProductModal modalOpen={props.modalOpen} handleModalClose={props.handleModalClose} productDetails={cart} handleSetCartProduct={handleSetCartProduct} handleModalProductId={props.handleSetModalProductId} modalProductId={props.modalProductId} />
      },[cart,props.modalProductId,props.modalOpen])

    return productmodal

}

export default HomeProductModal