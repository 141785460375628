
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi, nodeCudApi } from '../../CommonAxios';

const initialState = {
  tickets: [],
  loading: false,
  error: null,
};



export const createTicket = createAsyncThunk(
  'ticket/createTicket',
  async (ticketData) => {
    try {
      const response = await nodeApi.post('/User_Ticket/Create', ticketData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const CreateTicketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.tickets = action.payload;
      })
      .addCase(createTicket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

  },
});

export default CreateTicketSlice.reducer;


export const tickets = async (formData) => {
  return new Promise(async (resolve, reject) => {
    const response = await nodeCudApi.post('/User_Ticket/Create', formData).then((res) => {
      if (res.data?.Response_Status === "Success") {
        resolve({ status: true, data: res.data })
      }
      else {
        resolve({ status: false, error: res.data })
      }
    }).catch((err) => {
      resolve({ status: false, error: err.message })
    })
  })
}
