import React, { useEffect, useState } from 'react';
import { CardMedia, Grid, Skeleton } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import Fade from '@mui/material/Fade';
import { useTheme } from '@emotion/react';
import useScreenSize from '../../../../utils/ScreenWidthHight';

const Carousel = ({ data, imgLabel, loading, onClick, imageStyle }) => {
    const [autoplay, setAutoplay] = useState(true);
    const [count, setCount] = useState(0);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const theme = useTheme();
    const winSize = useScreenSize();

    useEffect(() => {
        const interval = setInterval(() => {
            if (autoplay) {
                setCount(prevIndex => (prevIndex + 1) % data.length);
            }
        }, 2400);
        return () => clearInterval(interval);
    }, [autoplay, data]);

    const handleMouseEnterLocal = () => {
        setAutoplay(false);
    };

    const handleMouseLeaveLocal = () => {
        setAutoplay(true);
    };

    const handleBannerClick = (data) => {
        onClick(data);
    };

    useEffect(() => {
        const dots = document.querySelectorAll('.MuiMobileStepper-dot');
        dots.forEach((dot, index) => {
            dot.addEventListener('click', () => handleDotClick(index));
        });

        return () => {
            dots.forEach((dot, index) => {
                dot.removeEventListener('click', () => handleDotClick(index));
            });
        };
    }, [data]);

    const handleDotClick = (index) => {
        setCount(index);
    };

    const handleTouchStart = (e) => {
        setTouchStart(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const diff = touchStart - touchEnd;
        if (diff > 50) {
            // Swipe left
            setCount(prevIndex => (prevIndex + 1) % data.length);
        } else if (diff < -50) {
            // Swipe right
            setCount(prevIndex => (prevIndex - 1 + data.length) % data.length);
        }
        setTouchStart(null);
        setTouchEnd(null);
    };

    return (
        <CardMedia sx={{
            position: 'relative',
            height: { xs: "160px", sm: '339px' },
            flexGrow: 1,
            paddingY: "5px",
            paddingTop: "10px",
            marginLeft: { xs: 0, sm: "80px" },
            marginRight: { xs: 0, sm: "80px" },
            cursor: "pointer"
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        >

            {data.length > 0 && data.map((item, idx) => (
                <Fade in={idx === count} key={idx} timeout={0}>
                    <Grid item key={idx}>
                        <CardMedia
                            component="img"
                            alt={`Banner ${idx + 1}`}
                            image={item[imgLabel]}
                            style={{ ...imageStyle, ...(winSize.screenSize.width < 600 ? { height: "160px" } : {}) }}
                            onMouseEnter={handleMouseEnterLocal}
                            onMouseLeave={handleMouseLeaveLocal}
                            onClick={() => handleBannerClick(item)}
                        />
                    </Grid>
                </Fade>
            ))}
            {data.length > 0 &&
                <MobileStepper
                    steps={data.length}
                    position="static"
                    activeStep={count}
                    sx={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        bgcolor: 'transparent',
                        '& .MuiMobileStepper-dots': {
                            justifyContent: 'center',
                        },
                        '& .MuiMobileStepper-dot': {
                            width: 11,
                            height: 11,
                        },
                        '& .MuiMobileStepper-dotActive': {
                            backgroundColor: theme.palette.primary.main,
                        },
                    }}
                    dots={data.length > 1 ? "true" : "false"}
                    variant="dots"
                />}
            {data.length === 0 && <Skeleton
                sx={{
                    width: "100%",
                    height: {md:"339px", xs:'160px'},
                    position: 'absolute',
                    borderRadius: '32px',
                    objectFit: 'fill'
                }}
                variant="rectangular"
            />}
        </CardMedia>
    );
};

export default Carousel;
