import React, { useEffect } from 'react'
import { FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material'
import { nodeApi } from '../../../../store/slices/CommonAxios';
import { useTheme } from '@emotion/react';

const Cities = (props) => {
    const { statecode, CountryCode = "IN" } = props
    const [city, setCity] = React.useState([]);
    const theme = useTheme();
    useEffect(() => {
        if (CountryCode && statecode) {
            getCitys(CountryCode, statecode)
        }
    }, [CountryCode, statecode])

    const getCitys = (CountryCode, statecode) => {
        nodeApi.post(`/Products/country-state-by-city`, { "CountryCode": CountryCode, 'StateCode': statecode })
            .then(response => {
                setCity(response.data);
            })
            .catch(error => {
            })
    }

    return (
        <FormControl sx={{ width: "100%" }}  >
            <InputLabel>Select City</InputLabel>
            <Select
                {...props}

            >
                <MenuItem value="">
                    None
                </MenuItem>
                {
                    city.map((city, index) => {
                        return (
                            <MenuItem key={index} value={city.name}>{city.name}</MenuItem>
                        )
                    })
                }
            </Select>

        </FormControl>
    )
}
export default Cities
