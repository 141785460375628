// exploreMoreSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi } from '../../CommonAxios'; 

const initialState = {
  brands: [],
  loading: false,
  error: null,
};

export const fetchBrands = createAsyncThunk(
  'exploreMore/fetchBrands',
  async () => {
    try {
      const response = await nodeApi.post('/Home/Brand/Get', {});
      return response.data.results; 
    } catch (error) {
      throw error;
    }
  }
);

const exploreMoreSlice = createSlice({
  name: 'exploreMore',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrands.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.loading = false;
        state.brands = action.payload; 
      })
      .addCase(fetchBrands.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default exploreMoreSlice.reducer;
