import { usePlacesWidget } from "react-google-autocomplete";
import React, { useEffect, useState } from 'react'
import { envConfig, nodeApi } from "../../../store/slices/CommonAxios";
import { Autocomplete, CardMedia, IconButton, Input, TextField } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
const GoogleAutoComplete = ({ onOptionSelect }) => {
    const [placesOptions, setPlacesOptions] = useState([])
    const theme=useTheme()
    const { ref } = usePlacesWidget({
        apiKey: envConfig.GOOGLE_MAP_API_KEY,
        onPlaceSelected: (place) => {
        },
        options: {
            types: ["(regions)"],
            componentRestrictions: { country: "ru" },
        },
        inputAutocompleteValue: "hy"
    });

    const googleOptionsAction = (searchValue) => {
        return new Promise(async (resolve, reject) => {
            await nodeApi.get(`/Home/proxy/https://maps.googleapis.com/maps/api/place/textsearch/json?query=${searchValue}&key=${envConfig.GOOGLE_MAP_API_KEY}`).then(res => {
                if (res.data.status === "OK")
                    resolve({ status: true, data: res.data.results })
            }).catch((err) => {
                reject({ status: false, error: err.message })
            })

        })
    }
    const actionCall = async (value) => {
        const response = await googleOptionsAction(value)
        let options
        if (response.status) {
            options = response.data.map((googleData, index) => ({ label: googleData.formatted_address, longitude: googleData.geometry.location.lat, latitude: googleData.geometry.location.lng }))
            setPlacesOptions(options)
        }
    }
    return (
        <Autocomplete
            onInputChange={(e, v) => {
                actionCall(v);
            }}
            onChange={(e, value) => {
                if (value?.latitude) {
                    onOptionSelect(value.latitude, value.longitude);
                }
            }}
            options={placesOptions}
            getOptionLabel={(option) => option?.label}
            isOptionEqualToValue={(option, value) => {
                return true
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search Location"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        endAdornment: (<Box sx={{  position:"absolute",right:0.3, backgroundColor: theme.palette.primary.main, width:"55px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center",borderRadius:"1px 3px 3px 1px" }} >
                            <LocationOnIcon sx={{ color: "white", }} />
                        </Box>)
                    }}
                />
            )}
        />
    )
}


export default GoogleAutoComplete