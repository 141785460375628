import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Checkbox, FormControlLabel, Radio, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { getCategoreyAction, getSubCategoriesAction, subToCat } from '../../../../store/slices/retail/home/ProductFiltersSlice';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../../../Context';

const CategoreyFilter = () => {
    const dispatch = useDispatch();
    const category = useSelector(state => state.productFilter.category);
    const subcategory = useSelector(state => state.productFilter.subcategory);
    const [state, setState] = useState([])
    const [Substate, setSubState] = useState([])
    const pathname = useLocation().pathname;
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);


    const { loading } = useSelector((state) => state.allProducts);
    useEffect(() => {
        const Category_Id = searchParams.get("Category_Id") ? searchParams.get("Category_Id").split(",") : []
        let notFound = true;
        if (Category_Id.length > 0) {
            const selectedCategories = [];
            for (let i = 0; i < category.length; i++) {
                for (let j = 0; j < Category_Id.length; j++) {
                    if (category[i].id == Category_Id[j]) {
                        selectedCategories.push(category[i])
                        notFound = false;
                        break;
                    }
                }
            }
            setState(selectedCategories);
            if (selectedCategories.length) {
                dispatch(getSubCategoriesAction({
                    formData: {
                        Business_Type: pathname.includes("retail") ? "RETAIL" : "GROCERY",
                        "Categories": selectedCategories.map((item) => item.id)
                    },
                    pagination: { PageNo: 1, PageSize: 1000, SortBy: "SUB_CATEGORY_NAME", SortOrder: "ASC" }
                }));
            }
        }
        else {
            setState([]);
            dispatch(getSubCategoriesAction({
                formData: {
                    Business_Type: pathname.includes("retail") ? "RETAIL" : "GROCERY"
                },
                pagination: { PageNo: 1, PageSize: 1000, SortBy: "SUB_CATEGORY_NAME", SortOrder: "ASC" }
            }));
        }
    }, [searchParams, category]);

    useEffect(() => {
        setSubState([]);
        const Sub_Category_Id = searchParams.get("Sub_Category_Id") ? searchParams.get("Sub_Category_Id").split(",") : []
        if (Sub_Category_Id?.length > 0) {
            const selectedSubCategories = [];

            for (let i = 0; i < subcategory.length; i++) {
                for (let j = 0; j < Sub_Category_Id.length; j++) {
                    if (subcategory[i].id == Sub_Category_Id[j]) {
                        selectedSubCategories.push(subcategory[i]);
                        break;
                    }
                }
            }
            setSubState(selectedSubCategories);
        }
    }, [searchParams, subcategory]);

    useEffect(() => {
        dispatch(getCategoreyAction(
            {
                formData: { "Category_Type": pathname.includes("retail") ? "RETAIL" : "GROCERY" }, pagination: { PageNo: 1, PageSize: 1000, SortBy: "CATEGORY_NAME", SortOrder: "ASC" }
            }
        ));
        dispatch(getSubCategoriesAction({
            formData: {
                Business_Type: pathname.includes("retail") ? "RETAIL" : "GROCERY"
            },
            pagination: { PageNo: 1, PageSize: 1000, SortBy: "SUB_CATEGORY_NAME", SortOrder: "ASC" }
        }));
    }, []);

    const handleChange = (selectedOption) => {

        if (selectedOption) {
            updateQueryParam([{ Category_Id: selectedOption.map((item) => item.id).toString() },
            { Sub_Category_Id: [].toString() }

            ])
        }
        else {
            updateQueryParam([{ Category_Id: [].toString() },
            { Sub_Category_Id: [].toString() }
            ])
        }

    }

    const subhandleChange = (selectedOption) => {
        if (selectedOption.length > 0) {
            if (state.map((item) => item.id).includes(selectedOption[0].cid)) {
                updateQueryParam([{ Category_Id: state.map((item) => item.id).toString() },
                { Sub_Category_Id: selectedOption.map((item) => item.id).toString() }
                ])
            } else {
                updateQueryParam([{ Category_Id: selectedOption[0].cid },
                { Sub_Category_Id: selectedOption.map((item) => item.id).toString() }
                ])
            }
        }
        else {
            updateQueryParam([
                { Sub_Category_Id: selectedOption.map((item) => item.id).toString() }
            ])
        }
    }


    return (
        <Box sx={{ width: "100%", }}>
            <Autocomplete
                disablePortal
                key={"CI" + state.length}
                options={category || []}
                multiple
                limitTags={1}
                sx={{
                    margin: "10px 0px",
                    "& fieldset": {
                        borderRadius: "12px",

                    },
                    '.MuiAutocomplete-inputRoot': {
                        flexWrap: 'nowrap !important', overflow: "hidden"
                    }
                }}

                isOptionEqualToValue={(option, value) => {

                    return option.title === value.title
                }}
                value={state}
                defaultValue={state}
                getOptionLabel={(option) => option.title}
                onChange={(event, newValue) => handleChange(newValue)}
                renderInput={(params) => <TextField {...params} label={"Search Category"} />}
                renderOption={(props, option) => (
                    <FormControlLabel
                        {...props}
                        sx={{ width: "98%" }}
                        control={<Checkbox checked={props["aria-selected"]} />}
                        label={option.title}
                    />
                )}
            />


            <Autocomplete
                disablePortal
                options={subcategory || []}
                key={"SCI" + Substate.length}
                multiple
                limitTags={1}
                sx={{
                    margin: "10px 0px",
                    "& fieldset": {
                        borderRadius: "12px",

                    },
                    '.MuiAutocomplete-inputRoot': {
                        flexWrap: 'nowrap !important', overflow: "hidden"
                    }
                }}
                isOptionEqualToValue={(option, value) => {
                    return option.title === value.title
                }}
                value={Substate}
                getOptionLabel={(option) => option.title}
                onChange={(event, newValue) => {
                    subhandleChange(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={"Search Sub Category"} />}
                renderOption={(props, option) => (
                    <FormControlLabel
                        {...props}
                        sx={{ width: "98%" }}
                        control={<Checkbox checked={props["aria-selected"]} />}
                        label={option.title}
                    />
                )}
            />
        </Box>
    );
}

export default CategoreyFilter
