import React, { useEffect, useState } from 'react';
import { Box, Button, Pagination, Tab, Tabs, Typography } from "@mui/material";
import AllProductsCard from "../../components/cards/AllProductsCard";
import TodayDealsCard from '../../components/cards/TodayDealsCard';
import { useDispatch } from "react-redux";
import { fetchAllProducts } from "../../../store/slices/retail/home/AllProductsSlice";
import { fetchCoupons } from '../../../store/slices/retail/home/CouponSlice';
import { fetchCards } from '../../../store/slices/retail/home/TodayDealsSlice';
import CouponCard from '../../components/cards/CouponCard';
import PartnerPage from './ParnterPage';
import { useTheme } from '@emotion/react';
import { useNavigate, useParams } from 'react-router-dom';
import HomeProductModal from '../home/HomeProductModal';
import { getCoupons, getDeals, getProducts } from '../../../store/slices/retail/home/PartnerSlice';
import useScreenSize from '../../../utils/ScreenWidthHight';


export const RenderProducts = ({ BrandId = "" }) => {
    const { id } = useParams();
    const [modalProductId, setModalProductId] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [partnerProducts, setPartnerProducts] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [cardWidth, setCardWidth] = useState('220px');
    const [screensEachLineCards, setScreensEachLineCards] = useState(10);
    const [slicedCards, setSlicedCards] = useState(5)
    const winSize = useScreenSize();
    const navigate = useNavigate()


    useEffect(() => {

        if (BrandId !== "") {
            PartnerProductsCall({
                Brand_Id: [BrandId]
            },
                {
                    PageNo: currentPage,
                    PageSize: screensEachLineCards,
                    SortOrder: "DESC",
                    SortBy: "Product_Id"
                }
            )
        }
        else if (id !== "")
            PartnerProductsCall({
                Partner_Details_Id: [id]
            },
                {
                    PageNo: currentPage,
                    PageSize: screensEachLineCards,
                    SortOrder: "DESC",
                    SortBy: "Product_Id"
                }
            )

    }, [id, currentPage, screensEachLineCards])
    const PartnerProductsCall = async (formData, Pagination) => {
        const response = await getProducts(formData, Pagination)
        if (response.status) {
            setPartnerProducts(response.data)
            setTotalPages(Number(response.Pagination.TotalPages))
        }
        else {

        }
    }


    const handleSetModalProductId = (value) => {
        setModalProductId(value)
    }

    const handleModalClose = () => {
        setModalOpen(false)
    }
    const handleModalOpen = () => {
        setModalOpen(true)
    }


    useEffect(() => {

        const screenWidth = (winSize.screenSize.width - 30);
        let calculatedWidth = Math.floor(screenWidth / 2) - 20;
        if (calculatedWidth > 220) {
            calculatedWidth = 220;
        }
        setCardWidth(calculatedWidth + 'px');
        setScreensEachLineCards(Math.floor(screenWidth / calculatedWidth))

    }, [winSize.screenSize]);


    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", marginTop: "20px", ...(partnerProducts.length >= screensEachLineCards ? { alignItems: "center" } : {alignItems: "center"}) }} >
            <Box sx={{ width: (partnerProducts.length >= screensEachLineCards ? "98%" : "98%"), display: "flex", flexDirection: "row", flexWrap: "wrap", rowGap: { xs: 0, sm: 3 }, justifyContent: { xs: "space-around", sm: (partnerProducts.length >= screensEachLineCards ? "space-between" : "flex-start") }, columnGap: (partnerProducts.length >= screensEachLineCards  ? 0 : "40px"), overflowX: "scroll", ml: { xs: 0, md: 'unset', sm: 'unset' } }}>
                {partnerProducts.length > 0 ? (
                    <>
                        {
                            partnerProducts.slice(0, (screensEachLineCards * 1)).map((partnerProduct, index) => (
                                <AllProductsCard key={index}
                                    cardWidth={cardWidth}
                                    image={partnerProduct.Product_Image}
                                    brandName={partnerProduct.Brand_Name}
                                    shortDescription={partnerProduct.Product_Short_Description}
                                    originalPrice={partnerProduct.Original_Price.toString()}
                                    item={partnerProduct}
                                    productName={partnerProduct.Product_Name}
                                    partnerSellingPrice={partnerProduct.Partner_Selling_Price.toString()}
                                    handleNavigation={() => {
                                        handleSetModalProductId(partnerProduct.Product_Id)
                                        handleModalOpen(true)
                                    }}
                                />
                            ))
                        }
                        {(partnerProducts.length % 2 !== 0 && winSize.screenSize.width < 600) && <Box sx={{ width: "44%" }} ></Box>}
                    </>


                ) : (

                    <Typography sx={{ display: "flex", justifyContent: "center" }}>No products found for this {BrandId !== "" ? "Brand" : "partner"}.</Typography>
                )}
                <HomeProductModal modalProductId={modalProductId} handleModalClose={handleModalClose} modalOpen={modalOpen} handleSetModalProductId={handleSetModalProductId} />


            </Box>
            {partnerProducts.length >= screensEachLineCards &&
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                    <Button onClick={() => { navigate("/grocery/products?PageNo=1" + (BrandId ? ("&Brand_Id=" + (BrandId || "")) : ("&Partner_Details_Id=" + (id || "")))) }} >View All</Button>
                </Box>
            }
        </Box>
    );
};

const RenderCoupons = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [partnerCoupons, setPartnerCoupons] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    useEffect(() => {
        PartnerProductsCall({
            Partner_Details_Id: [id]
        },
            {
                PageNo: currentPage,
                PageSize: 20
            }
        )
    }, [id, currentPage])

    const PartnerProductsCall = async (formData, Pagination) => {
        const response = await getCoupons(formData, Pagination)
        if (response.status) {
            setPartnerCoupons(response.data)
            setTotalPages(response.Pagination.TotalPages)
        }
        else {

        }
    }

    const winSize = useScreenSize()

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }} >
            <Box sx={{ width: "98%", display: "flex", flexDirection: "row", flexWrap: "wrap", rowGap: 3, justifyContent: { xs: "space-evenly", sm: "flex-start" }, marginTop: 3 }}>
                {partnerCoupons.length > 0 ? (
                    [...partnerCoupons].map((item, index) => (
                        <CouponCard
                            key={index}
                            data={item}
                            name={item.Name}
                            discount={item.Discount}
                            validateWithDate={item.Valid_Through_Date}
                            Discount_Label={item.Discount_Label}
                            imagePath={item.Image_Path}
                            esiCashBackText={item?.ESI_Cashback_Type === 'R'
                                ? `upto ${item?.ESI_Cashback_Amount} rewards`
                                : item?.ESI_Cashback_Type === 'A'
                                    ? `upto ${item?.ESI_Cashback_Amount} /- off`
                                    : ''}
                            onClick={() => { navigate(`/grocery/offers/coupons/${item.Coupon_Id}`) }} />

                    ))
                ) : (
                    <Typography>No coupons found for this partner.</Typography>
                )}

            </Box>
            {totalPages > 1 && (
                <Pagination color='primary' page={currentPage} count={totalPages} onChange={(e, page) => {
                    setCurrentPage(page)
                }} />
            )}
        </Box>
    );
};

const RenderDeals = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [partnerDeals, setPartnerDeals] = useState([])

    const [totalPages, setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    useEffect(() => {
        PartnerProductsCall({
            Partner_Details_Id: [id]
        },
            {
                PageNo: currentPage,
                PageSize: 20
            }
        )
    }, [id, currentPage])

    const PartnerProductsCall = async (formData, Pagination) => {
        const response = await getDeals(formData, Pagination)
        if (response.status) {
            setPartnerDeals(response.data)
            setTotalPages(response.Pagination.TotalPages)
        }
        else {

        }
    }
    const [cardWidth, setCardWidth] = useState('220px');
    const [screensEachLineCards, setScreensEachLineCards] = useState(12);

    const winSize = useScreenSize();

    useEffect(() => {

        const screenWidth = winSize.screenSize.width;
        let calculatedWidth = Math.floor(screenWidth / 2) - 20;
        if (calculatedWidth > 220) {
            calculatedWidth = 220;
        }
        setCardWidth(calculatedWidth + 'px');
        setScreensEachLineCards(Math.floor(screenWidth / calculatedWidth))

    }, [winSize.screenSize]);



    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }} >
            <Box sx={{ width: "98%", display: "flex", flexDirection: "row", flexWrap: "wrap", columnGap: 1, justifyContent: { xs: "space-evenly", sm: "flex-start" }, marginTop: 3, }}>
                {partnerDeals.length > 0 ? (
                    partnerDeals.slice(0, (screensEachLineCards * 1)).map((item, index) => (
                        <TodayDealsCard
                            key={index}
                            cardWidth={cardWidth}
                            card={item}
                            onClick={() => { navigate(`/grocery/offers/Deals/${item.Deal_Id}`) }} />

                    ))
                ) : (
                    <Typography>No deals found for this partner.</Typography>
                )}
            </Box>
            {totalPages > 1 && (
                <Pagination color='primary' page={currentPage} count={totalPages} onChange={(e, page) => {
                    setCurrentPage(page)
                }} />
            )}
        </Box>
    );
};


const PartnerReview = () => {
    const dispatch = useDispatch();
    const [value, setValue] = useState('one');

    useEffect(() => {
        dispatch(fetchAllProducts());
        dispatch(fetchCoupons({}));
        dispatch(fetchCards({}));
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const theme = useTheme()
    const winSize = useScreenSize();


    return (
        <>
            <Box sx={{ width: winSize.screenSize.width, minHeight: "auto" }} >

                <PartnerPage />
                <Box>
                    <Tabs value={value} onChange={handleChange}
                        variant='scrollable'
                        TabIndicatorProps={{
                            style: { display: "none" }
                        }}
                        sx={{
                            marginLeft: { xs: "4%", sm: 3 },
                            display: 'flex',
                            '& button': { borderTopLeftRadius: 12, borderTopRightRadius: 12, width: { xs: "108px", sm: "208px" }, color: theme.palette.primary.main, ...(winSize.screenSize.width < 600 ? { fontSize: "12px" } : {}) },
                            '& button.Mui-selected': { backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary },


                        }}>
                        <Tab value="one" label="PRODUCTS" />
                        <Tab value="two" label="COUPONS" />
                        <Tab value="three" label="DEALS" />
                    </Tabs>

                    {value === 'one' && <RenderProducts />}
                    {value === 'two' && <RenderCoupons />}
                    {value === 'three' && <RenderDeals />}
                </Box>
            </Box>
        </>
    );
}

export default PartnerReview;
