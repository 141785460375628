// Retail.js
import React, { useEffect, useMemo, useState } from 'react';
import { Container } from '@mui/material';
import Banner from './Banner';
import Category from './Category';
import FurnitureBar from './FurnitureBar';
import TodayDeals from './TodayDeals';
import TableLayout from './TableLayout';
import ExploreReviewCustomer from "./ExploreReviewCustomer";
import BestSellers from './BestSellers';
import Coupon from './Coupon';
import RecentlyView from './RecentlyView';
import Promotion from './Promotions';
import PartnerSection from './PartnerSection';
import Brand from './Brand';
import AllProductsCompact from './products/AllProductsCompact';
import HomeProductModal from './HomeProductModal';
import { getUserSettings } from '../../../store/slices/AuthSlice';
import { Button, Modal } from '@mui/material';
import { Box } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';




const RetailHome = (props) => {

    const [modalProductId, setModalProductId] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [indexingData, setIndexingData] = useState([
        { index: 1, type: "CATEGORY", inputs: {} },
        { index: 2, type: "BANNER", inputs: {} },
        { index: 3, type: "COUPONS", inputs: {} },
        { index: 4, type: "BRANDS", inputs: {} },
        { index: 5, type: "PARTNERS", inputs: {} },
        { index: 6, type: "TODAY_DEALS", inputs: {} },
        { index: 7, type: "RECENTLY_VIEW", inputs: {} },
        { index: 8, type: "PRODUCTS", inputs: {} },
        { index: 9, type: "PROMOTION", inputs: {} }
    ])

    const getHomeIndex = async () => {
        const res = await getUserSettings("RETAIL_WEB_HOME", false);
        if (res.status) {
            setIndexingData(JSON.parse(res.data))
        }
    }

    useEffect(() => {
        getHomeIndex()
    }, [])

    const handleSetModalProductId = (value) => {
        setModalProductId(value)
    }

    const handleModalClose = () => {
        setModalOpen(false)
    }
    const handleModalOpen = () => {
        setModalOpen(true)
    }


    const homePage = useMemo(() => {
        return indexingData.map((item, index) => {
            let res = <></>
            switch (item.type) {
                case "CATEGORY":
                    res = <Category key={index} />
                    break;
                case "BANNER":
                    res = <Banner key={index} />
                    break;
                case "COUPONS":
                    res = <Coupon key={index} />
                    break;
                case "BRANDS":
                    res = <Brand key={index} />
                    break;
                case "PARTNERS":
                    res = <PartnerSection key={index} />
                    break;
                case "TODAY_DEALS":
                    res = <TodayDeals key={index} />
                    break;
                case "RECENTLY_VIEW":
                    res = <RecentlyView handleSetModalProductId={(value) => { handleSetModalProductId(value) }} handleModalOpen={handleModalOpen} key={index} />
                    break;
                case "PRODUCTS":
                    res = <AllProductsCompact handleSetModalProductId={(value) => { handleSetModalProductId(value) }} handleModalOpen={handleModalOpen} key={index} />
                    break;
                case "PROMOTION":
                    res = <Promotion key={index} />
                    break;
                case "BESTSELLERS":
                    res = <BestSellers input={item.inputs?.data} key={index} />
                    break;
                case "TABLE_LAYOUT":
                    res = <TableLayout input={item.inputs?.data} key={index} />
                    break;
                case "FURNITURE_BAR":
                    res = <FurnitureBar input={item.inputs?.data} key={index} />
                    break;
                case "EXPLORE_REVIEW_CUSTOMER":
                    res = <ExploreReviewCustomer input={item.inputs?.data} key={index} />
                    break;
                default: <></>
            }
            return res
        })

    }, [indexingData])

    return (
        <Container maxWidth="xl">
            {homePage}
            <HomeProductModal modalProductId={modalProductId} handleModalClose={handleModalClose} modalOpen={modalOpen} handleSetModalProductId={handleSetModalProductId} />
        </Container>
    );
};

export default RetailHome;

export const HomePagesModal = ({ setPageModalOpen, pageModalOpen, theme }) => {
    const navigate = useNavigate()
    const pathname = useLocation().pathname
    const isGrocery = pathname.includes("grocery")
    const pages = [
        { title: "Wishlist", link: isGrocery ? "/accounts/grocery/wishlist" : "/accounts/wishlist" },
        { title: "Cart", link: "/accounts/cart" },
        { title: "Orders", link: isGrocery ? "/accounts/grocery/orders" : "/accounts/orders" },
        { title: "Address", link: "/accounts/address" },
        { title: "Wallet", link: "/accounts/wallet" },
        { title: "Refer & Earn", link: "/accounts/refer&earn" },
        { title: " Support Tickets", link: "/accounts/tickets" },
        { title: "Notifications", link: "/accounts/Notification" },{ title: "Contact Us", link: "/accounts/contactus" },
        { title: "Delete Account", link: "/accounts/deleteaccount" },
        { title: "News & Events", link: "/accounts/newsandevents" },
        { title: "About Us", link: "/accounts/aboutus" },
        { title: "Faq's", link: "/accounts/faqs" },
        { title: "Shipping & Delivery", link: "/accounts/shippinganddelivery" },
        { title: "Return & Exchange", link: "/accounts/returnandexchange" },
        { title: "Privacy Policy", link: "/accounts/privacypolicy" },
        { title: "Terms & Conditions", link: "/accounts/terms&conditions" },
    ]
    return (
        <Modal open={pageModalOpen}
            onClose={() => setPageModalOpen(false)}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
            <Box sx={{
                width: "auto", height: "400px", backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "10px", padding: "4px 8px", '& button': {
                    color: theme.palette.primary.main,
                    '&:hover': {
                        color: "black",
                        backgroundColor: "#27b6cc",
                    }
                },
                zIndex: 999,
                overflowY: "scroll"
            }} >
                {pages.map((element, index) => {
                    return (

                        <Button key={index} onClick={() => {
                            navigate(element.link)
                            setPageModalOpen(false)
                        }} style={{
                            fontSize: "16px", textDecoration: "none",
                        }} > {element.title} </Button>
                    )
                })

                }

            </Box>
        </Modal>
    )
}
