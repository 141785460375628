import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import { Typography, CardMedia, Link, Button, Modal } from '@mui/material';
import AppStoreImage from '../../../assests/images/appstore.png';
import PlayStoreImage from '../../../assests/images/playstore.png';
import MapImage from '../../../assests/images/meePaisaRideMap.png';
import RideBenefits1 from '../../../assests/images/ridebenefits1.png';
import RideBenefits2 from '../../../assests/images/ridebenefits2.png';
import RideBenefits3 from '../../../assests/images/ridebenefits3.png';
import RideBenefits4 from '../../../assests/images/ridebenefits2.png';
import Meepaisaimage from '../../../assests/images/meepaisa frame.png';
import client1image from '../../../assests/images/client1.jpeg';
import client2image from '../../../assests/images/client2.jpeg';
import client3image from '../../../assests/images/client3.jpg'
import Clientvectorimage from '../../../assests/images/cilentvector.png';
import RideWorks from './RideWorks.js';
import { useNavigate } from 'react-router-dom';


const rideImages = [
  { image: RideBenefits1, alt: 'ride1image', index: '01.', title: 'CONVEINECE', subtitle: 'Easy to book rides from anywhere using a smartphone.' },
  { image: RideBenefits2, alt: 'ride2image', index: '02.', title: 'AVAILABILITY', subtitle: 'Often available 24/7, providing transportation at any time of day or night.' },
  { image: RideBenefits3, alt: 'ride1image', index: '03.', title: 'TRANSPARENCY', subtitle: 'Clear pricing, driver information, and ride details enhance trust and reliability.' },
  { image: RideBenefits4, alt: 'ride4image', index: '04.', title: 'REDUCED NEED FOR CAR OWNERSHIP', subtitle: 'Enables urban dwellers to rely less on personal vehicles, saving on maintenance and parking costs.' },
];
export default function RidesHome() {

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          gap: '20px',
          justifyContent: { xs: 'center', md: 'flex-start' },
          marginTop: '50px',
          marginBottom: '140px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: { md: '90px' }
          }}>
          <Typography variant='h2' sx={{ fontWeight: 'bold', color: '#000000' }}>
            DOWNLOAD APP,
          </Typography>
          <Typography variant='h2' sx={{ fontWeight: 'bold', marginBottom: '90px' }}>
            <Typography variant='h2' component='span' sx={{ color: '#039BE5', fontWeight: 'bold' }}>Enjoy</Typography> The <Typography variant='h3' component='span' sx={{ color: ' #FBC02D', fontWeight: 'bold' }}>Rides</Typography>!
          </Typography>
          <Typography variant='h5' paragraph sx={{ maxWidth: '580px', lineHeight: '1.6', marginBottom: '155px', color: '#9E9DA2' }}>
            It's simple and it's free. Play your part in reducing Carbon Footprint and help Mother Nature to sustain its beauty. So what are you waiting for? Let's ride together. It's simple and it's free. Play your part in reducing Carbon Footprint and help Mother Nature to sustain. So what are you waiting for?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'space-between' },
              maxWidth: '400px',
              flexWrap: 'wrap',
              gap: '20px'
            }}>
            <Link href="https://apps.apple.com/in/app/meepaisa/id1629144663">
              <CardMedia component='img' image={AppStoreImage} alt='Appstore' sx={{ width: '190px', maxWidth: '200%', height: 'auto' }} />
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=com.user.meepaisa">
              <CardMedia component='img' image={PlayStoreImage} alt='Playstore' sx={{ width: '190px', maxWidth: '200%', height: 'auto' }} />
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <CardMedia component='img' image={MapImage} alt='mapimage' sx={{ width: '100%', maxWidth: '600px', height: 'auto' }} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '90px',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Typography variant='h3' sx={{ fontWeight: 'bold', marginBottom: "30px" }}>
          HOW <Typography variant='h3' component='span' sx={{ color: '#27B6CC', fontWeight: 'bold' }}>Meepaisa</Typography> <Typography variant='h3' component='span' sx={{ color: ' #FDD835', fontWeight: 'bold' }}>Ride</Typography> WORKS
        </Typography>
        <Typography paragraph variant="h6" sx={{ textAlign: 'center', marginBottom: '60px', color: '#9E9DA2', width: '370px', height: '116px' }}>
          Download and install the Meepaisa app.Enter your phone number and make your user account.When approved, you may start taking rides.
        </Typography>
        <  RideWorks />
        <Typography variant='h2' sx={{ marginTop: '80px', fontWeight: 'bold' }}>
          <Typography variant='h2' component='span' sx={{ color: ' #FBC02D', fontWeight: 'bold' }}>Ride</Typography> BENEFITS
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: "1%", marginTop: '80px', width: '100%' }}>
          {rideImages.map((ride, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: index % 2 === 0 ? 'flex-end' : 'flex-start',
                width: '100%',
                padding: "5% 0"
                // transform: 'translateX(-20px)',

              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row-reverse', borderRadius: { xs: index % 2 === 0 ? '5px 0px 0px 5px' : '0px 5px 5px 0px', md: index % 2 === 0 ? '90px 0px 0px 90px' : '0px 90px 90px 0px' }, backgroundColor: '#FFFDE7', justifyContent: 'space-around', alignItems: "center", width: { xs: "100%", md: '70%' } }}>
                {index % 2 === 0 && <CardMedia component='img' image={ride.image} alt='rideimage' sx={{ objectFit: 'contain', height: { xs: "100px", md: "254px" }, width: { xs: "100px", md: "256px" } }} />}
                <Box sx={{ marginTop: { xs: "0", md: '0px' }, }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center", padding: { xs: "2% 0", md: "0" } }}>
                    <Typography fontWeight='bold' sx={{ color: '#FDD835', fontSize: { xs: "34px", md: "65px" } }}>{ride.index} </Typography>
                    <Typography fontWeight='bold' sx={{ marginTop: { md: '0px', xs: 0 }, fontSize: { xs: "18px", md: "34px" }, textAlign: { xs: "center", md: "none" } }}>{ride.title}</Typography>
                  </Box>
                  <Box sx={{ width: { md: '100%', xs: "95%" }, padding: { xs: "10% 0", md: "0" } }} >
                    <Typography sx={{ width: "100%", color: '#9E9DA2', fontSize: { md: "18px", xs: "14px" }, textAlign: index % 2 === 0 ? { xs: "center", md: "none" } : { xs: "end", md: "end" }, }}>{ride.subtitle}</Typography>

                  </Box>
                </Box>

                {index % 2 !== 0 && <CardMedia component='img' image={ride.image} alt='rideimage' sx={{ objectFit: 'contain', height: { xs: "100px", md: "254px" }, width: { xs: "100px", md: "256px" } }} />}


              </Box>

            </Box>
          ))}
        </Box>
        <CardMedia component='img' image={Meepaisaimage} alt='meepaisaimage' sx={{ width: '98%', height: 'auto', marginTop: '55px' }} />
        <Typography variant='h3' sx={{ fontWeight: 'bold', marginTop: '30px', textAlign: 'center' }}>Our Happy Client's Review</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: { md: '20px', xs: "40px" }, marginTop: '30px', flexWrap: 'wrap' }}>
          {[{ image: client1image, alt: 'ridecilent', description: 'This Meepaisa Rides is very Good and it is very Easy to use giving useful options.I loved this app.', name: 'Shaik Yakhoob', company: 'works at EasyStepIn' },
          { image: client2image, alt: 'ridecilent', description: 'This Meepaisa Rides is very Good and it is very Easy to use giving useful options.I loved this app.', name: 'Roop Sagar', company: 'works at EasyStepIn' },
          { image: client3image, alt: 'ridecilent', description: 'This Meepaisa Rides is very Good and it is very Easy to use giving useful options.I loved this app.', name: 'Haresh Kiran', company: 'works at EasyStepIn' }].map((client, index) => (
            <Box key={index} sx={{ position: 'relative', width: { xs: '339px', md: '410px' }, height: { xs: '365px', md: '395px' }, border: '1px solid #FDD835', borderRadius: '30px',mt:2 }}>
              <CardMedia
                component='img'
                image={client.image}
                alt={`rideclient${index + 1}`}
                sx={{ position: 'absolute', top: '-32px', left: '-10px', width: '155px', height: { xs: '167px', md: '155px' }, borderRadius: '50%', border: '2px solid #fff' }}
              />

              <Typography variant='h5' sx={{ marginTop: '140px', width: { md: '290px' }, marginLeft: '35px' }}>
                {client.description}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-around' }}>
                <Typography variant='h6' sx={{ marginLeft: { xs: '-6px', md: '-42px' }, marginTop: { xs: '20px', md: '45px' } }}>{client.name}</Typography>
                <CardMedia component='img' image={Clientvectorimage} alt='clientvector' sx={{ height: '38px', width: '44px', marginBottom: '3px' }}></CardMedia>
              </Box>
              <Typography variant='h6' sx={{ marginLeft: '35px' }}>{client.company}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export const HomePagesModal = ({ setPageModalOpen, pageModalOpen }) => {
  const navigate = useNavigate()
  const pages = [
    { title: "Tickets", link: "/accounts/tickets" },
    { title: "Shops", link: "/shop" },
    { title: "Notifications", link: "/accounts/Notification" },
    { title: "News&Events", link: "/accounts/newsandevents" },
    { title: "Review&Ratng", link: "/accounts/reviewsandrating" },
    { title: "Faqs", link: "/accounts/faqs" },
    { title: "Terms&Conditions", link: "/accounts/terms&conditions" },
    { title: "PrivacyPolicy", link: "/accounts/privacypolicy" },
    { title: "ContactUs", link: "/accounts/contactus" },
    { title: "DeleteAccount", link: "/accounts/deleteaccount" },
    { title: "Cart", link: "/accounts/cart" },
    { title: "Refer&Earn", link: "/accounts/refer&earn" },
    { title: "Wallet", link: "/accounts/wallet" },
    { title: "Orders", link: "/accounts/orders" },
    { title: "Wishlist", link: "/accounts/wishlist" },

  ]
  return (
    <Modal open={pageModalOpen}
      onClose={() => setPageModalOpen(false)}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
      <Box sx={{
        width: "auto", height: "auto", backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "10px", padding: "4px 8px", '& button': {
          color: "#27b6cc",
          '&:hover': {
            color: "black",
            backgroundColor: "#27b6cc",
          }
        }
      }} >
        {pages.map((element, index) => {
          return (
            <Button key={index} onClick={() => {
              navigate(element.link)
              setPageModalOpen(false)
            }} style={{
              fontSize: "16px", textDecoration: "none",
            }} > {element.title} </Button>
          )
        })

        }

      </Box>
    </Modal>
  )
}