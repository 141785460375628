import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { nodeApi } from '../../CommonAxios'

const initialState = {
    imgeUri: [],
    loading: false,
    error: ""
}

export const review = createAsyncThunk("product/review", async () => {
    const response = await nodeApi.post("/Home/Brand/Get", {})
    const data = response?.data
    if (data.Success_Response.Is_Data_Exist == "0") {
        return []
    } else {
        return data.results
    }
})


const reviewSlice = createSlice({
    name: 'review',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(review.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(review.fulfilled, (state, action) => {
                state.loading = false
                state.imgeUri = action.payload;
            })
            .addCase(review.rejected, (state, action) => {
                state.loading = false
                state.error = [];
                state.error = action.error;
            })
    }
})

export default reviewSlice.reducer;