import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { nodeApi, nodeCudApi } from '../../CommonAxios'

const initialState = {
  brandsReview: [],
  loading: false,
  error: ""
}


// export const brandReviews = createAsyncThunk(
//   'brands/brandReviews',
//   async (formData) => {
//     try {
//       const response = await nodeApi.post('/Reviews/Brand_Reviews_Get', formData);
//       return response.data

//     } catch (error) {
//       console.error('Error fetching cards:', error);
//       throw new Error('Failed to fetch cards');
//     }
//   }
// );

// export const brandReviewFetch=(formData)=>{
//   return new Promise(async (resolve,reject)=>{
//     await nodeApi.post('/Reviews/Brand_Reviews_Get',formData).then((res)=>{
//       if(res.data.Success_Response.Response_Code=="200"){
//         resolve({status:true,data:res.data.results})
//       }
//       else{
//         resolve({status:false,data:[]})
//       }
//     }).catch((err)=>{
//       resolve({status:false,error:err.message})
//     })
//   })
// }

export const brandReviewByBrandId = (formData) => {
  return new Promise(async (resolve, rejcet) => {
    await nodeApi.post('/Reviews/Brand_Reviews_Get', formData).then((res) => {
      if (res.data.Success_Response.Is_Data_Exist === "1") {
        resolve({ status: true, data: res.data.results })
      }
      else {
        resolve({ status: false, data: [] })
      }
    }).catch((err) => {
      resolve({ status: false, error: err.message })
    })
  })
}



export const brandReviews = createAsyncThunk("brands/brandReviews", async (formData) => {
  try {
    const response = await nodeApi.post("/Reviews/Brand_Reviews_Get?PageNo=1&PageSize=200", formData)
    const data = response?.data
    if (data.Success_Response.Is_Data_Exist === "0") {
      return []
    } else {
      return data.results
    }
  } catch (error) {
    console.error('Error fetching brand reviews:', error);
    throw error; // Make sure to re-throw the error to be handled by the rejection case
  }
});




export const brandReviewCreate = (formData) => {
  return new Promise(async (resolve, reject) => {
    await nodeCudApi.post('/Reviews/Brand_Review_Create', formData).then((res) => {
      if (res.data.Response_Status === "Success") {
        resolve({ status: true })
      }
      else
        resolve({ status: false })
    }).catch((err) => {
      resolve({ status: false, error: err.message })
    })
  })
}


export const brandReviewUpdate = (formData) => {
  return new Promise(async (resolve, reject) => {
    await nodeCudApi.post('/Reviews/Brand_Review_Update', formData).then((res) => {
      if (res.data.Response_Status === "Success") {
        resolve({ status: true })
      }
      else
        resolve({ status: false })
    }).catch((err) => {
      resolve({ status: false, error: err.message })
    })
  })
}


const brandReviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {

    setBrandName(state, action) {
      state.brandName = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(brandReviews.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(brandReviews.fulfilled, (state, action) => {
        state.loading = false
        state.brandsReview = action.payload;
      })
      .addCase(brandReviews.rejected, (state, action) => {
        state.loading = false
        state.error = [];
        state.error = action.error;
      })
  }
})

export const { setBrandName } = brandReviewSlice.actions;

export default brandReviewSlice.reducer;