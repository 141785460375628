import { useTheme } from '@emotion/react'
import { Autocomplete, Box, Button, CardMedia, IconButton, Pagination, Skeleton, Tab, Tabs, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import useScreenSize from '../../../../utils/ScreenWidthHight';
import { createCommentForTicketId, getTickesByTicketId, getUserTicketsAction, ticketSearch } from '../../../../store/slices/tickets/TicketsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser'
import { GlobalContext } from '../../../../Context';
import ticketsNotFound from '../../../../assests/images/ticketsNotFound.png'

const Tickets = () => {
    const navigate = useNavigate()
    const theme = useTheme()
    const [value, setValue] = useState(0)
    const [newTabValue, setNewTabValue] = useState(0)
    const [tickets, setTickets] = useState([])
    const [createComment, setCreateComment] = useState('')
    const [refreshCount, setRefreshCount] = useState(0)
    const [totalPage, setTotalPage] = useState(1)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")
    const chatRef = useRef()
    const auth = useSelector(state => state.auth)
    const comments = useSelector(state => state.tickets)
    const dispatch = useDispatch()
    const [errorMessage, setErrorMessage] = useState("")
    const { pathname } = useLocation();
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);
    const screenSize = useScreenSize();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (auth.isAuthenticated) {
            if (chatRef.current) {
                chatRef.current.scrollTop = chatRef.current.scrollHeight;
                setIsLoading(true);
            }
        }
        else
            window.location.href = '../auth/signin'

    }, []);



    useEffect(() => {
        if (tickets[newTabValue]?.Ticket_Id) {
            dispatch(getTickesByTicketId({ Ticket_Id: tickets[newTabValue]?.Ticket_Id }))
            setCreateComment("")
        }
    }, [newTabValue, tickets, refreshCount])

    const handleChange = (e, value) => {
        setValue(value)
        if (value === 0)
            updateQueryParam([{ "status": "New" }, { "PageNo": 1 }])
        else if (value === 1)
            updateQueryParam([{ "status": "In Progress" }, { "PageNo": 1 }])
        else if (value === 2)
            updateQueryParam([{ "status": "Hold" }, { "PageNo": 1 }])
        else if (value === 3)
            updateQueryParam([{ "status": "Closed" }, { "PageNo": 1 }])

    }

    useEffect(() => {
        if (searchParams.get("PageNo")) {
            getUserTicketsActionCall({ Ticket_Owner: auth?.user?.Email_Id, Status: searchParams.get("status") }, { PageSize: 4, PageNo: searchParams.get("PageNo"), SortBy: "Ticket_Id", SortOrder: "DESC" }, searchParams.get("status")?.toLowerCase())
            setValue(() => {
                let newValue = 0
                if (searchParams.get("status") === "New") {
                    newValue = 0
                }
                else if (searchParams.get("status") === "Hold")
                    newValue = 2
                else if (searchParams.get("status") === "Closed")
                    newValue = 3
                else
                    newValue = 1
                return newValue
            })
        }
        else {
            {
                let querys = "";
                searchParams.forEach((value, key) => {
                    querys += "&" + key + "=" + value
                });
                navigate('/accounts/tickets?status=New&PageNo=1' + querys, { replace: true });
            }
        }
    }, [pathname, searchParams])




    const getUserTicketsActionCall = async (formData, pagiantion, filterType) => {
        const response = await getUserTicketsAction(formData, pagiantion)
        if (response.status) {
            setTickets(response?.data)
            setTotalPage(response?.Pagination?.TotalPages)
            setPage(response?.Pagination?.PageNo)
            setIsLoading(false);
        }
        else {
            setErrorMessage("No Tickets")
            setTickets([])
            setTotalPage(1)
            setPage(1)
            setIsLoading(false);
        }
    }

    const ticketSearchActionCall = async (formData, pagiantion) => {
        const response = await ticketSearch(formData, pagiantion)
        if (response.status) {
            setTickets(response?.data)
            setTotalPage(response?.Pagination?.TotalPages)
            setPage(response?.Pagination?.PageNo)
        }
        else {
            setErrorMessage("No Tickets")
            setTickets([])
            setTotalPage(1)
        }
    }

    return (
        <Box sx={{ width: "100%", minHeight: { md: "110vh" }, display: "flex", flexDirection: "column", alignItems: "center", gap: 5 }} >
            <Box sx={{ width: "90%", display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: { md: "space-between", sm: 'space-between', xs: 'left ' }, alignItems: 'center' }} >
                <Box sx={{ display: 'flex', justifyContent: { md: "flex-start", sm: "flex-start", xs: 'left' }, width: { xs: "100%", sm: "unset" } }} >
                    <Typography sx={{ color: theme.palette.text.secondary, fontSize: { md: '34px', sm: '34px', xs: '16px' }, textAlign: { xs: 'left' } }} >Support Tickets</Typography>
                </Box>
                <Box sx={{ width: { md: "50%", xs: "100%" }, display: 'flex', flexDirection: { md: "row", xs: "row" }, justifyContent: { md: 'end', sm: 'center', xs: 'center' }, alignItems: 'center', gap: "10px" }} >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        name="Search Ticket"
                        placeholder="Search Ticket"
                        InputProps={{
                            startAdornment: (
                                <SearchIcon color='primary' />
                            ),
                        }}
                        sx={{
                            '& fieldset': { borderRadius: 2 },
                        }}
                        onChange={(e) => {
                            setSearch(e.target.value)
                            if (e.target.value.length > 0)
                                ticketSearchActionCall({ Ticket_Owner: auth?.user?.Email_Id, Search: e.target.value }, { PageNo: 1, PageSize: 4 })
                            else
                                getUserTicketsActionCall({ Ticket_Owner: auth?.user?.Email_Id, Status: searchParams.get("status") }, { PageSize: 3, PageNo: searchParams.get("PageNo") }, searchParams.get("status")?.toLowerCase())
                        }}
                    />
                    <Button variant='contained' sx={{ height: 55, borderRadius: 2, transform: "translateY(4px)", fontSize: { xs: "13px" }, whiteSpace: 'nowrap', textWrap: 'nowrap' }}
                        onClick={() => {
                            // window.location.href = "../accounts/createticket"
                            navigate('/accounts/createticket')
                        }}

                    > + Create Ticket</Button>
                </Box>
            </Box>
            <Box sx={{ width: "90%", maxHeight: { md: "90vh" }, display: "flex", flexWrap: 'wrap', flexDirection: "row", justifyContent: "space-between", gap: { xs: 2 } }} >
                <Box sx={{ width: { md: "40%", sm: "48%", xs: "100%" }, }} >
                    <Box sx={{ width: "100%", minHeight: { md: "60vh" }, padding: "5px 0px", borderRadius: 4, border: `1px solid ${theme.palette.input.borderColor}`, display: 'flex', flexDirection: "column", alignItems: "center", gap: 4 }} >
                        <Box sx={{ width: { xs: "95%", sm: "90%", md: "100%" }, display: "flex", justifyContent: "center" }} >
                            {
                                search.length == 0 ?

                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor='transparent'
                                        variant="scrollable"
                                        TabIndicatorProps={{
                                            style: { display: "none" }
                                        }}
                                        sx={{

                                            '& button': { color: "black", width: { xs: "auto", md: "100px", xl: screenSize.screenSize.width < 1600 ? "118px" : "150px" } },
                                            '& button.Mui-selected': { color: "white", backgroundColor: "#27B6CC" },
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                        scrollButtons={true}

                                    >
                                        {[{ title: "new", }, { title: "in progress" }, { title: "hold" }, { title: "closed" }].map((headings, index) => {
                                            const bgcolor = index === value ? theme.palette.input.borderColor : "none"
                                            return (
                                                <Tab
                                                    key={index}
                                                    sx={{
                                                        backgroundColor: bgcolor,
                                                        borderRadius: 2,
                                                        fontSize: { md: "10.5px", xl: "10px", xs: '13px' },
                                                        fontWeight: 'bold',
                                                    }}
                                                    label={headings.title.toUpperCase()}
                                                />

                                            );
                                        })}
                                    </Tabs>
                                    :
                                    <Tabs
                                        value={0}
                                        // onChange={handleChange}
                                        indicatorColor='transparent'
                                        variant="fullWidth"
                                        TabIndicatorProps={{
                                            style: { display: "none" }
                                        }}
                                        sx={{

                                            '& button': { color: "black", width: "200px" },
                                            '& button.Mui-selected': { color: "white", backgroundColor: "#27B6CC" },
                                        }}
                                        scrollButtons={true}
                                    >
                                        {[{ title: "tickets", }].map((headings, index) => {
                                            const bgcolor = index === value ? theme.palette.input.borderColor : "none"
                                            return (
                                                <Tab
                                                    disableRipple
                                                    key={index}
                                                    sx={{
                                                        backgroundColor: bgcolor,
                                                        borderRadius: 2, cursor: "default"
                                                    }}
                                                    label={headings.title.toUpperCase()}
                                                />

                                            );
                                        })}
                                    </Tabs>
                            }

                        </Box>
                        <Box sx={{ width: { xs: "100%", sm: "90%", md: '90%' }, maxHeight: { md: "80vh", sm: "90vh" }, overflow: "scroll" }} >
                            {isLoading ? (
                                <TicketsSkeleton />
                            ) : (
                                <>
                                    {value === 0 && <NewTicketsMemo value={value} tickets={tickets} setNewTabValue={setNewTabValue} newTabValue={newTabValue} errorMessage={errorMessage} />}
                                    {value === 1 && <InProgressTicketsMemo value={value} tickets={tickets} setNewTabValue={setNewTabValue} newTabValue={newTabValue} errorMessage={errorMessage} />}
                                    {value === 2 && <HoldTicketsMemo value={value} tickets={tickets} setNewTabValue={setNewTabValue} newTabValue={newTabValue} errorMessage={errorMessage} />}
                                    {value === 3 && <ClosedTicketsMemo value={value} tickets={tickets} setNewTabValue={setNewTabValue} newTabValue={newTabValue} errorMessage={errorMessage} />}
                                </>
                            )}
                            {totalPage > 1 ? <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                                <Pagination color="primary" count={totalPage || 1}
                                    page={Number(searchParams?.get("PageNo"))}
                                    onChange={(e, value) => {
                                        setPage(value)
                                        updateQueryParam([{ "status": searchParams.get("status") }, { "PageNo": value }])
                                    }} />
                            </Box> : null}
                        </Box>
                    </Box>
                </Box>

                {tickets.length > 0 &&
                    <Box sx={{ width: { md: "56%", sm: "48%", xs: "100%" }, maxHeight: { md: "80vh", sm: "115vh" }, display: 'flex', flexDirection: 'column', alignItems: "center" }} >

                        <Box sx={{ width: "97%", padding: "0px 8px", display: 'flex', flexDirection: 'column', justifyContent: 'space-around', backgroundColor: "rgba(225,225,225,.6)", borderRadius: 2 }} >
                            <Typography sx={{ fontSize: { md: '24px', sm: '24px', xs: '12px' }, fontWeight: { xs: 'bold', sm: 'unset', md: 'unset' } }} >Ticket Subject</Typography>
                            <Typography sx={{ whiteSpace: "wrap", fontSize: { md: '16px', sm: '16px', xs: '10px' }, maxHeight: { md: "70%" }, overflowY: { md: "scroll" }, '&::-webkit-scrollbar': { display: 'none' }, }}  >
                                {parse(tickets[newTabValue]?.Subject || "<h1>No Subjet</h1>")}
                            </Typography>
                        </Box>
                        <Box sx={{ width: "97%", padding: "0px 8px", display: 'flex', flexDirection: 'column', justifyContent: 'space-around', backgroundColor: "rgba(225,225,225,.6)", borderRadius: 2, marginTop: "5px" }} >
                            <Typography sx={{ fontSize: { md: '24px', sm: '24px', xs: '12px' }, fontWeight: { xs: 'bold', sm: 'unset', md: 'unset' } }} >Description</Typography>
                            <Typography sx={{ whiteSpace: "wrap", fontSize: { md: '16px', sm: '16px', xs: '10px' }, maxHeight: { md: "70%" }, overflowY: { md: "scroll" }, '&::-webkit-scrollbar': { display: 'none' }, }}  >
                                {parse(tickets[newTabValue]?.Description || "<h1>No Description</h1>")}
                            </Typography>
                        </Box>

                        <Box sx={{ width: "97%", height: "70%", display: 'flex', flexDirection: "column", }} >
                            <Typography sx={{ fontSize: { md: '24px', sm: '24px', xs: '12px' }, mt: { xs: '10px', md: 'unset', sm: 'unset' } }}  >Comments</Typography>
                            <Box sx={{ width: "100%", minHeight: "45vh", display: "flex", flexDirection: "column", gap: 3, overflowY: "scroll", '&::-webkit-scrollbar': { display: 'none' }, }} ref={chatRef} >
                                {
                                    comments?.comments?.map((element, index) => {
                                        return (
                                            <Box key={index} sx={{ width: '100%', display: 'flex', justifyContent: element?.Created_By !== auth?.user?.Email_Id ? "flex-start" : "flex-end", }} >
                                                <Box sx={{ width: { xs: "90%", md: "70%" }, display: "flex", flexDirection: "column", border: `1px solid ${theme.palette.input.borderColor}`, alignItems: "center", borderRadius: element?.Created_By !== auth?.user?.Email_Id ? "2px 10px 10px 10px" : "10px 2px 10px 10px", backgroundColor: element?.Created_By !== auth?.user?.Email_Id ? "transparent" : theme.palette.primary.main, color: element?.Created_By !== auth?.user?.Email_Id ? theme.palette.headerbutton.textcolorone : theme.palette.primary.contrastText }} >
                                                    <Box sx={{ width: "95%", display: "flex", justifyContent: "flex-start", }} >
                                                        <Typography sx={{ fontWeight: "bold", fontSize: { md: '16px', sm: '16px', xs: '12px' }, }} >{element?.Full_Name}</Typography>
                                                    </Box>
                                                    <Box sx={{ width: "95%", display: "flex", justifyContent: "flex-start", overflowY: "hidden", }} >
                                                        <Typography sx={{ width: "100%", fontSize: { md: '16px', sm: '16px', xs: '12px' }, }} > {element?.Created_By !== auth?.user?.Email_Id && (
                                                            <Typography sx={{ fontWeight: 'bold' }}></Typography>
                                                        )}
                                                            {element?.Comment}</Typography>
                                                    </Box>
                                                    <Box sx={{ width: "95%", display: "flex", justifyContent: "flex-end", }} >
                                                        <Typography sx={{ fontSize: { md: '12px', sm: '12px', xs: '8px' } }}>{element?.Created_Date}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                            <Box sx={{ width: "100%", marginTop: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
                                <Box sx={{ width: "90%" }} >
                                    <TextField
                                        fullWidth
                                        placeholder='Enter Message'
                                        value={createComment}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            if (value[0] !== " ")
                                                setCreateComment(value)
                                        }}
                                        disabled={searchParams.get("status") === "Closed"}

                                    />
                                </Box>
                                <Button variant='contained' sx={{ width: '84px', height: '56px', ml: '6px' }}
                                    disabled={createComment === ""}
                                    onClick={async () => {
                                        if (createComment !== "") {
                                            const response = await createCommentForTicketId({
                                                "Comment": createComment,
                                                "Created_By": auth?.user?.Email_Id,
                                                "Status": "Active",
                                                "Ticket_Id": tickets[newTabValue]?.Ticket_Id
                                            })

                                            if (response.status) {
                                                if (chatRef.current) {
                                                    chatRef.current.scrollTop = chatRef.current.scrollHeight + 15;
                                                }
                                                setRefreshCount(refreshCount + 1)
                                            }
                                            else {

                                            }

                                        }
                                    }}

                                >Send</Button>
                            </Box>
                        </Box>
                    </Box>}
            </Box>
        </Box>
    )
}

export default Tickets




const NewTicketsMemo = ({ value, tickets, newTabValue, setNewTabValue, errorMessage }) => {

    const theme = useTheme()

    if (value === 0)
        if (tickets.length > 0)
            return (
                <Tabs

                    value={newTabValue}

                    orientation='vertical'
                    indicatorColor='transparent'
                    TabIndicatorProps={{
                        style: { display: "none" }
                    }}
                    variant="fullWidth"
                    sx={{
                        '& button': { color: "black", borderRadius: 2, border: `1px solid ${theme.palette.input.borderColor}` },
                        '& button.Mui-selected': { color: "white", backgroundColor: "#27B6CC", borderRadius: 2, border: `1px solid ${theme.palette.input.borderColor}` },
                    }}
                >
                    {
                        tickets.map((element, index) => {
                            return (
                                <Tab
                                    key={index}
                                    sx={{ margin: 2, minHeight: 100, }}

                                    label={
                                        <Box sx={{ width: "100%", minHeight: 100, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'space-around' }}
                                            onClick={() => {
                                                setNewTabValue(index)
                                            }}
                                        >
                                            <Box sx={{ width: { xs: "100%", sm: "100%", md: "95%" }, display: "flex", flexDirection: 'row', alignItems: 'center', gap: { xs: 0, md: "2%", sm: "2%", } }}


                                            >
                                                <Box sx={{ width: { xs: "50%", sm: "48%", md: "48%" }, display: 'flex', flexDirection: "row", justifyContent: "flex-start", columnGap: { xs: "6%", sm: "unset" } }} >
                                                    <Typography sx={{ width: "40%", textAlign: "left", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} >Ticket ID</Typography>
                                                    <Typography sx={{ width: "50%", textAlign: "left", fontWeight: "bold", fontSize: { md: "10.5px", xl: "16px", xs: '12px' } }} >{element?.Ticket_Id}</Typography>
                                                </Box>
                                                <Box sx={{ width: { xs: "50%", sm: "48%", md: "48%" }, display: 'flex', flexDirection: "row", justifyContent: { sm: 'flex-start', xs: 'space-between' }, }} >
                                                    <Box sx={{ width: "10%", }} ></Box>
                                                    <Typography sx={{ width: "40%", textAlign: "left", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} > Status</Typography>
                                                    <Box sx={{ width: "12%", }} ></Box>
                                                    <Typography sx={{ width: "38%", textAlign: "left", fontWeight: "bold", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.primary.main, }} >{element?.Status}</Typography>
                                                </Box>

                                            </Box>
                                            <Box sx={{ width: { xs: "100%", sm: "100%", md: "95%" }, display: "flex", flexDirection: 'row', alignItems: 'center',justifyContent:"space-between" }} >
                                                <Box sx={{ width: { xs: "50%", md: "48%", sm: "48%" }, display: 'flex', flexDirection: "row", justifyContent: "flex-start", columnGap: { xs: "6%", sm: "unset" } }}
                                                    onClick={() => {
                                                        setNewTabValue(index)
                                                    }}
                                                >
                                                    <Typography sx={{ width: "40%", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textAlign: "left", textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} >Type</Typography>
                                                    <Typography sx={{ width: "50%", fontWeight: "bold", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textAlign: "left" }} >{element?.Issue_Related_To}</Typography>
                                                </Box>
                                                <Box sx={{ width: { xs: "50%", md: "48%", sm: "48%" }, display: 'flex', flexDirection: "row", justifyContent: { sm: 'flex-start', xs: "flex-start" }, columnGap: { xs: "0", sm: "unset" } }} >
                                                    <Box sx={{ width: "40%", display: "flex", justifyContent: "center" }} >
                                                        <Typography sx={{ width: "100%", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} >Attachment</Typography>
                                                    </Box>
                                                    <Box sx={{ width: "50%", display: "flex", justifyContent: "center" }} >
                                                        {element?.Attachment ? <CardMedia
                                                            image={require('../../../../assests/images/pdfAttachementIcon.png')}
                                                            sx={{ width: { md: 18, xl: 24, xs: '12px' }, height: { md: 18, xl: 24 }, }}
                                                            onClick={() => {
                                                                window.open(element?.Attachment, "_blank")
                                                            }}
                                                        /> : <Typography> 0 </Typography>}
                                                    </Box>
                                                </Box>

                                            </Box>

                                        </Box>
                                    }

                                />
                            )
                        })
                    }
                </Tabs>
            )
        else
            return (
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                    {
                        errorMessage.length > 0 &&
                        (
                            <img
                                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                src={ticketsNotFound}
                            />
                        )

                    }
                </Box>
            )
}
const InProgressTicketsMemo = ({ value, tickets, newTabValue, setNewTabValue, errorMessage }) => {
    const theme = useTheme()

    if (value === 1)
        if (tickets.length > 0)
            return (
                <Tabs

                    value={newTabValue}

                    orientation='vertical'
                    indicatorColor='transparent'
                    TabIndicatorProps={{
                        style: { display: "none" }
                    }}
                    variant="fullWidth"
                    sx={{
                        '& button': { color: "black", borderRadius: 2, border: `1px solid ${theme.palette.input.borderColor}` },
                        '& button.Mui-selected': { color: "white", backgroundColor: "#27B6CC", borderRadius: 2, border: `1px solid ${theme.palette.input.borderColor}` },

                    }}
                >
                    {
                        tickets.map((element, index) => {
                            return (
                                <Tab
                                    key={index}
                                    sx={{ margin: 2, minHeight: 100, }}

                                    label={
                                        <Box sx={{ width: "100%", minHeight: 100, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'space-around' }}
                                            onClick={() => {
                                                setNewTabValue(index)
                                            }}
                                        >
                                            <Box sx={{ width: { xs: "100%", sm: "100%", md: "95%" }, display: "flex", flexDirection: 'row', alignItems: 'center', gap: { xs: 0, md: "2%", sm: "2%", } }}


                                            >
                                                <Box sx={{ width: { xs: "50%", sm: "48%", md: "48%" }, display: 'flex', flexDirection: "row", justifyContent: "flex-start", columnGap: { xs: "6%", sm: "unset" } }} >
                                                    <Typography sx={{ width: "40%", textAlign: "left", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} >Ticket ID</Typography>
                                                    <Typography sx={{ width: "50%", textAlign: "left", fontWeight: "bold", fontSize: { md: "10.5px", xl: "16px", xs: '12px' } }} >{element?.Ticket_Id}</Typography>
                                                </Box>
                                                <Box sx={{ width: { xs: "50%", sm: "48%", md: "48%" }, display: 'flex', flexDirection: "row", justifyContent: { sm: 'flex-start', xs: 'space-between' }, }} >
                                                    <Box sx={{ width: "10%", }} ></Box>
                                                    <Typography sx={{ width: "40%", textAlign: "left", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} > Status</Typography>
                                                    <Box sx={{ width: "12%", }} ></Box>
                                                    <Typography sx={{ width: "38%", textAlign: "left", fontWeight: "bold", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }  ,color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.primary.main, }} >{element?.Status}</Typography>
                                                </Box>

                                            </Box>
                                            <Box sx={{ width: { xs: "100%", sm: "100%", md: "95%" }, display: "flex", flexDirection: 'row', alignItems: 'center',justifyContent:"space-between" }} >
                                                <Box sx={{ width: { xs: "50%", md: "48%", sm: "48%" }, display: 'flex', flexDirection: "row", justifyContent: "flex-start", columnGap: { xs: "6%", sm: "unset" } }}
                                                    onClick={() => {
                                                        setNewTabValue(index)
                                                    }}
                                                >
                                                    <Typography sx={{ width: "40%", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textAlign: "left", textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} >Type</Typography>
                                                    <Typography sx={{ width: "50%", fontWeight: "bold", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textAlign: "left" }} >{element?.Issue_Related_To}</Typography>
                                                </Box>
                                                <Box sx={{ width: { xs: "50%", md: "48%", sm: "48%" }, display: 'flex', flexDirection: "row", justifyContent: { sm: 'flex-start', xs: "flex-start" }, columnGap: { xs: "0", sm: "unset" } }} >
                                                    <Box sx={{ width: "40%", display: "flex", justifyContent: "center" }} >
                                                        <Typography sx={{ width: "100%", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} >Attachment</Typography>
                                                    </Box>
                                                    <Box sx={{ width: "50%", display: "flex", justifyContent: "center" }} >
                                                        {element?.Attachment ? <CardMedia
                                                            image={require('../../../../assests/images/pdfAttachementIcon.png')}
                                                            sx={{ width: { md: 18, xl: 24, xs: '12px' }, height: { md: 18, xl: 24 }, }}
                                                            onClick={() => {
                                                                window.open(element?.Attachment, "_blank")
                                                            }}
                                                        /> : <Typography> 0 </Typography>}
                                                    </Box>
                                                </Box>

                                            </Box>

                                        </Box>
                                    }

                                />
                            )
                        })
                    }
                </Tabs>
            )
        else
            return (
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                    {
                        errorMessage.length > 0 &&
                        (
                            <img
                                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                src={ticketsNotFound}
                            />
                        )

                    }
                </Box>
            )
}
const HoldTicketsMemo = ({ value, tickets, newTabValue, setNewTabValue, errorMessage }) => {
    const theme = useTheme()

    if (value === 2)
        if (tickets.length > 0)
            return (
                <Tabs

                    value={newTabValue}

                    orientation='vertical'
                    indicatorColor='transparent'
                    TabIndicatorProps={{
                        style: { display: "none" }
                    }}
                    variant="fullWidth"
                    sx={{
                        '& button': { color: "black", borderRadius: 2, border: `1px solid ${theme.palette.input.borderColor}` },
                        '& button.Mui-selected': { color: "white", backgroundColor: "#27B6CC", borderRadius: 2, border: `1px solid ${theme.palette.input.borderColor}` },

                    }}
                >
                    {
                        tickets.map((ticket, index) => {
                            return (
                                <Tab
                                    key={index}
                                    sx={{ margin: 2, minHeight: 100, }}

                                    label={
                                        <Box sx={{ width: "100%", minHeight: 100, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'space-around' }}
                                            onClick={() => {
                                                setNewTabValue(index)
                                            }}
                                        >
                                            <Box sx={{ width: { xs: "100%", sm: "100%", md: "95%" }, display: "flex", flexDirection: 'row', alignItems: 'center', gap: { xs: 0, md: "2%", sm: "2%", } }}


                                            >
                                                <Box sx={{ width: { xs: "50%", sm: "48%", md: "48%" }, display: 'flex', flexDirection: "row", justifyContent: "flex-start", columnGap: { xs: "6%", sm: "unset" } }} >
                                                    <Typography sx={{ width: "40%", textAlign: "left", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} >Ticket ID</Typography>
                                                    <Typography sx={{ width: "50%", textAlign: "left", fontWeight: "bold", fontSize: { md: "10.5px", xl: "16px", xs: '12px' } }} >{ticket?.Ticket_Id}</Typography>
                                                </Box>
                                                <Box sx={{ width: { xs: "50%", sm: "48%", md: "48%" }, display: 'flex', flexDirection: "row", justifyContent: { sm: 'flex-start', xs: 'space-between' }, }} >
                                                    <Box sx={{ width: "10%", }} ></Box>
                                                    <Typography sx={{ width: "40%", textAlign: "left", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} > Status</Typography>
                                                    <Box sx={{ width: "12%", }} ></Box>
                                                    <Typography sx={{ width: "38%", textAlign: "left", fontWeight: "bold", fontSize: { md: "10.5px", xl: "16px", xs: '12px' } }} >{ticket?.Status}</Typography>
                                                </Box>

                                            </Box>
                                            <Box sx={{ width: { xs: "100%", sm: "100%", md: "95%" }, display: "flex", flexDirection: 'row', alignItems: 'center',justifyContent:"space-between" }} >
                                                <Box sx={{ width: { xs: "50%", md: "48%", sm: "48%" }, display: 'flex', flexDirection: "row", justifyContent: "flex-start", columnGap: { xs: "6%", sm: "unset" } }}
                                                    onClick={() => {
                                                        setNewTabValue(index)
                                                    }}
                                                >
                                                    <Typography sx={{ width: "40%", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textAlign: "left", textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} >Type</Typography>
                                                    <Typography sx={{ width: "50%", fontWeight: "bold", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textAlign: "left" }} >{ticket?.Issue_Related_To}</Typography>
                                                </Box>
                                                <Box sx={{ width: { xs: "50%", md: "48%", sm: "48%" }, display: 'flex', flexDirection: "row", justifyContent: { sm: 'flex-start', xs: "flex-start" }, columnGap: { xs: "0", sm: "unset" } }} >
                                                    <Box sx={{ width: "40%", display: "flex", justifyContent: "center" }} >
                                                        <Typography sx={{ width: "100%", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} >Attachment</Typography>
                                                    </Box>
                                                    <Box sx={{ width: "50%", display: "flex", justifyContent: "center" }} >
                                                        {ticket?.Attachment ? <CardMedia
                                                            image={require('../../../../assests/images/pdfAttachementIcon.png')}
                                                            sx={{ width: { md: 18, xl: 24, xs: '12px' }, height: { md: 18, xl: 24 }, }}
                                                            onClick={() => {
                                                                window.open(ticket?.Attachment, "_blank")
                                                            }}
                                                        /> : <Typography> 0 </Typography>}
                                                    </Box>
                                                </Box>

                                            </Box>

                                        </Box>
                                    }

                                />
                            )
                        })
                    }
                </Tabs>
            )
        else
            return (
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                    {
                        errorMessage.length > 0 &&
                        (
                            <img
                                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                src={ticketsNotFound}
                            />
                        )

                    }
                </Box>
            )
}
const ClosedTicketsMemo = ({ value, tickets, newTabValue, setNewTabValue, errorMessage }) => {
    const theme = useTheme()

    if (tickets.length > 0)
        return (
            <Tabs

                value={newTabValue}

                orientation='vertical'
                indicatorColor='transparent'
                TabIndicatorProps={{
                    style: { display: "none" }
                }}
                variant="fullWidth"
                sx={{
                    '& button': { color: "black", borderRadius: 2, border: `1px solid ${theme.palette.input.borderColor}` },
                    '& button.Mui-selected': { color: "white", backgroundColor: "#27B6CC", borderRadius: 2, border: `1px solid ${theme.palette.input.borderColor}` },

                }}
            >
                {
                    tickets.map((element, index) => {
                        return (
                            <Tab
                                key={index}
                                sx={{ margin: 2, minHeight: 100, }}

                                label={
                                    <Box sx={{ width: "100%", minHeight: 100, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'space-around' }}
                                        onClick={() => {
                                            setNewTabValue(index)
                                        }}
                                    >
                                        <Box sx={{ width: { xs: "100%", sm: "100%", md: "95%" }, display: "flex", flexDirection: 'row', alignItems: 'center', gap: { xs: 0, md: "2%", sm: "2%", } }}


                                        >
                                            <Box sx={{ width: { xs: "50%", sm: "48%", md: "48%" }, display: 'flex', flexDirection: "row", justifyContent: "flex-start", columnGap: { xs: "6%", sm: "unset" } }} >
                                                <Typography sx={{ width: "40%", textAlign: "left", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} >Ticket ID</Typography>
                                                <Typography sx={{ width: "50%", textAlign: "left", fontWeight: "bold", fontSize: { md: "10.5px", xl: "16px", xs: '12px' } }} >{element?.Ticket_Id}</Typography>
                                            </Box>
                                            <Box sx={{ width: { xs: "50%", sm: "48%", md: "48%" }, display: 'flex', flexDirection: "row", justifyContent: { sm: 'flex-start', xs: 'space-between' }, }} >
                                                <Box sx={{ width: "10%", }} ></Box>
                                                <Typography sx={{ width: "40%", textAlign: "left", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} > Status</Typography>
                                                <Box sx={{ width: "12%", }} ></Box>
                                                <Typography sx={{ width: "38%", textAlign: "left", fontWeight: "bold", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.primary.main, }} >{element?.Status}</Typography>
                                            </Box>

                                        </Box>
                                        <Box sx={{ width: { xs: "100%", sm: "100%", md: "95%" }, display: "flex", flexDirection: 'row', alignItems: 'center',justifyContent:"space-between" }} >
                                                <Box sx={{ width: { xs: "50%", md: "48%", sm: "48%" }, display: 'flex', flexDirection: "row", justifyContent: "flex-start", columnGap: { xs: "6%", sm: "unset" } }}
                                                    onClick={() => {
                                                        setNewTabValue(index)
                                                    }}
                                                >
                                                    <Typography sx={{ width: "40%", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textAlign: "left", textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} >Type</Typography>
                                                    <Typography sx={{ width: "50%", fontWeight: "bold", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textAlign: "left" }} >{element?.Issue_Related_To}</Typography>
                                                </Box>
                                                <Box sx={{ width: { xs: "50%", md: "48%", sm: "48%" }, display: 'flex', flexDirection: "row", justifyContent: { sm: 'flex-start', xs: "flex-start" }, columnGap: { xs: "0", sm: "unset" } }} >
                                                    <Box sx={{ width: "40%", display: "flex", justifyContent: "center" }} >
                                                        <Typography sx={{ width: "100%", fontSize: { md: "10.5px", xl: "16px", xs: '12px' }, textTransform: 'capitalize', color: newTabValue === index ? theme.palette.primary.contrastText : theme.palette.text.secondary }} >Attachment</Typography>
                                                    </Box>
                                                    <Box sx={{ width: "50%", display: "flex", justifyContent: "center" }} >
                                                        {element?.Attachment ? <CardMedia
                                                            image={require('../../../../assests/images/pdfAttachementIcon.png')}
                                                            sx={{ width: { md: 18, xl: 24, xs: '12px' }, height: { md: 18, xl: 24 }, }}
                                                            onClick={() => {
                                                                window.open(element?.Attachment, "_blank")
                                                            }}
                                                        /> : <Typography> 0 </Typography>}
                                                    </Box>
                                                </Box>

                                            </Box>

                                    </Box>
                                }

                            />
                        )
                    })
                }
            </Tabs>
        )
    else
        return (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                {
                    errorMessage.length > 0 &&
                    (
                        <img
                            style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            src={ticketsNotFound}
                        />
                    )

                }
            </Box>
        )
}


const TicketSkeleton = () => {

    return (
        <Box sx={{ width: "92%", padding: "5px 0px", display: 'flex', flexDirection: 'column', gap: 2, border: '1px solid lightgrey', borderRadius: 4, padding: 2 }}>
            <Box sx={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Skeleton variant="text" width="100%" height={20} />
                    <Skeleton variant="text" width="100%" height={20} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Skeleton variant="text" width="100%" height={20} />
                    <Skeleton variant="text" width="100%" height={20} />
                </Box>
            </Box>
        </Box>
    );
};

const TicketsSkeleton = () => {
    return (
        <Box sx={{ width: "100%", display: 'flex', flexDirection: 'column', gap: 2 }}>
            {Array.from(new Array(4)).map((_, index) => (
                <TicketSkeleton key={index} />
            ))}
        </Box>
    );
};