import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi, urlGenarator } from '../../CommonAxios'

const initialState = {
    coupons: [],
    loading: false,
    error: null,
    Pagination: {}
};

// Define an async thunk to fetch coupons using nodeApi
export const fetchCoupons = createAsyncThunk(
    'coupons/fetchCoupons',
    async (formData) => {
        try {
            const response = await nodeApi.post(urlGenarator('/Products/GetCoupons', formData?.Pagination || {}), formData?.formData || {}); // Using nodeApi for the API call
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const homeCouponsGet = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post(urlGenarator('/Products/GetCoupons', { SortBy: "Created_Date", SortOrder: "DESC" }), formData).then((res) => {
            resolve({ status: true, data: res.data?.results || [] })
        }).catch((err) => {
            resolve({ status: false, data: [] })
        })
    })
}

export const fetchCouponsFilter = async (formData = {}, businessType, Pagination = {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await nodeApi.post(urlGenarator('/Products/GetCoupons', Pagination), formData);
            if (response.data?.Success_Response?.Is_Data_Exist === "1") {
                const filteredData = response.data
                resolve({ status: true, data: filteredData, Pagination: response.data.Pagination });
            } else {
                resolve({ status: false, data: response.data?.results });
            }
        } catch (err) {
            resolve({ status: false, error: err.message });
        }
    });
};


const CouponSlice = createSlice({
    name: 'coupons',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCoupons.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCoupons.fulfilled, (state, action) => {
                state.loading = false;
                state.coupons = action.payload.results || [];
                state.Pagination = action.payload.Pagination;
            })
            .addCase(fetchCoupons.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default CouponSlice.reducer;
