import { Box, Button, Grid, Rating, TextField, Typography } from "@mui/material";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { deliveryAgentReviewCreate} from "../../../../store/slices/accounts/orders/DeliveryAgentReviews";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GlobalContext } from "../../../../Context";
import React from "react";

const DeliveryAgentReview = ({ data, EmailID, setDeliveryReviewsGet, deliveryCommentsCall }) => {
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); 
    const { toolTipActive } = React.useContext(GlobalContext);
    const initialValues = {
        comments: data && data?.User_Email_Id === EmailID ? data?.Comments : '',
        rating: data && data?.User_Email_Id === EmailID ? data?.Rating : 0
    };

    const validationSchema = Yup.object().shape({
        comments: Yup.string().max(255).required('Please enter comment'),
        rating: Yup.number().required('Please provide a rating').min(1, 'Rating must be at least 1').max(5, 'Rating cannot be more than 5'),
    });

    const handleSubmit = async (values, actions) => {
        if (isAuthenticated) {
                const formData = {
                    Comments: values.comments,
                    Rating: values.rating,
                    User_Email_Id: EmailID,
                    Order_Line_Item_Id: data.Item_Master_Id,
                    Order_Id: data?.Order_Id
                };
                const response = await deliveryAgentReviewCreate(formData);
                if (response.status) {
                    toolTipActive("success", "Review created successfully.");
                    deliveryCommentsCall(data.Item_Master_Id)
                    
                } else {
                    toolTipActive("error", "The Reviewer has already given a review. Please try with another.");
                }
        } else {
            navigate("/auth/signin");
        }
    };

    return (
        <>
            {(data?.Line_Item_Order_Status === "DELIVERED" || data?.Order_Status === "DELIVERED") && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, errors, touched,handleSubmit }) => (
                        <Form>
                            <Box sx={{ width: "100%" , mt:'10px'}}>
                                <Box sx={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}>
                                    <Typography variant="h6" sx={{ fontWeight: "bold",  }}>ADD REVIEW</Typography>
                                    <Field
                                        name="comments"
                                        as={TextField}
                                        label="Comment"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        error={Boolean(touched.comments && errors.comments)}
                                        helperText={touched.comments && errors.comments ? errors.comments : ''}
                                    />
                                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1 }}>

                                        <Rating
                                            name="rating"
                                            value={values.rating}
                                            onChange={(event, newValue) => setFieldValue('rating', newValue)}
                                        />
                                        {touched.rating && errors.rating && (
                                            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                                {errors.rating}
                                            </Typography>
                                        )}
                                    </Grid>

                                </Box>
                                <Box sx={{ textAlign: "center", marginTop: 2 }}>
                                    <Button type="submit" variant="contained" onClick={handleSubmit} >Submit</Button>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
}

export default DeliveryAgentReview;
