import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi, urlGenarator } from '../../CommonAxios';


export const fetchDeals = createAsyncThunk("deals/fetchDeals", async (formData) => {
    try {
        const response = await nodeApi.post("/Products/GetDeals", formData);
        const data = response?.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            return [];
        } else {
            return data.results;
        }
    } catch (error) {
        throw error;
    }
});


export const fetchDealsFilter = async (formData, businessType, Pagination) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await nodeApi.post(urlGenarator('/Products/GetDeals', Pagination), formData);

            if (response.data?.Success_Response?.Is_Data_Exist === "1") {
                const filteredData = response.data?.results
                resolve({ status: true, data: filteredData, Pagination: response.data.Pagination });
            } else {
                resolve({ status: false, data: response.data?.results });
            }
        } catch (err) {
            reject(err.message);
        }
    });
};




const DealsSlice = createSlice({
    name: 'homeDeals',
    initialState: {
        availableDeals: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchDeals.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchDeals.fulfilled, (state, action) => {
                state.loading = false
                state.availableDeals = action.payload;
            })
            .addCase(fetchDeals.rejected, (state, action) => {
                state.loading = false
                state.error = [];
                state.error = action.error;
            })
    }
});



export default DealsSlice.reducer;

