import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'

import menShirtChartSize from '../../../assests/images/MenShirtSizechartImage.png'
import menShirtChartTable from '../../../assests/images/MenShirtSizechartTable.png'
import menTouserChartTable from '../../../assests/images/MenTrouserSizechartTable.png'
import menTouserChartSize from '../../../assests/images/MenTrouserSizeChartImage.png'
import womenTopSizechartTable from '../../../assests/images/WomenTopSizechartTable.png'
import womenTopSizechartImage from '../../../assests/images/WomenTopSizeChartImage.png'
import KidsTrousersSizeChartTable from '../../../assests/images/KidsTrousersSizeChartTable.png'
import KidsSizeChartTrousersImage from '../../../assests/images/KidsSizeChartTrousersImage.png'
import KidsSizeChartTshirtImage from '../../../assests/images/KidsSizeChartT-shirtImage.png'
import KidsTshirtSizeChartTable from '../../../assests/images/KidsT-shirtSizeChartTable.png'
import { Link, useNavigate } from 'react-router-dom'


const SizeChartComponent = ({ Category_Name }) => {

    const mens = [menShirtChartTable, menShirtChartSize, menTouserChartTable, menTouserChartSize]
    const women = [womenTopSizechartTable, womenTopSizechartImage]
    const kids = [KidsTrousersSizeChartTable, KidsSizeChartTrousersImage, KidsTshirtSizeChartTable, KidsSizeChartTshirtImage]
    const ImageRender = ({ image }) => {
        return (
            <Link target='_blank' to={image} style={{ width: {xs:"100%",sm:"30%",md:"30%"}, }}  >
                <img
                    src={image}
                    style={{ width: "90%", height: "auto" }}
                />
            </Link>
        )
    }
    return (
        <Box sx={{
            width: "100%", height: "100%", overflowY: "scroll", '&::-webkit-scrollbar': {
                width: '0px',
                background: 'transparent',
            },
            display:"flex",
            flexDirection:{md:"row",sm:"row",xs:"column"},
        }}

        >
            {
                Category_Name === "Men's Fashion" && mens.map((image, index) => {
                    return (
                        <ImageRender image={image} key={index} />
                    )
                })
            }
            {
                Category_Name === "Women's Fashion" && women.map((image, index) => {
                    return (
                        <ImageRender image={image} key={index} />
                    )
                })
            }
            {
                Category_Name === "Kids's Fashion" && kids.map((image, index) => {
                    return (
                        <ImageRender image={image} key={index} />
                    )
                })
            }
        </Box>
    )
}

export default SizeChartComponent