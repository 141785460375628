import React from 'react';
import { Box, Card, CardActionArea, CardMedia, Grid, Link, Skeleton, Typography } from "@mui/material";
import { useTheme } from '@emotion/react';
import { isEmpty } from '../../../../utils';
import { useNavigate } from 'react-router-dom';

const ProductGrid = ({ input={}, title="", data={}, imgLabel="", isSkeleton=false }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    if (!isSkeleton)
        return (

            <Card sx={{
                height: {xs:"320px",sm:"100%"},
                marginTop: {xs:2,sm:2},
                width: "100%",
                // boxShadow: "0px 9px 30px " + theme.palette.extra.shadowColorOne,
                position: "relative",
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    rowGap:"5px",
                    padding: {xs:1,sm:3}
                }}>
                    <Typography gutterBottom sx={{ marginRight: "auto", fontSize: "19px", fontWeight: 'bold' }}>
                        {title}
                    </Typography>
                    <Grid container spacing={2}>
                        {data.map((item, index) => (
                            <Grid item xs={6} key={index}>
                                <CardActionArea onClick={() => {
                                    navigate(input.onClickLink + item[input.idField])
                                }} sx={{ width: "100%" }}>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            paddingTop: "56.25%",
                                            position: "relative",
                                            overflow: "hidden"
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            alt={`Image ${index + 1}`}
                                            image={item[input?.imageField]}
                                            style={{
                                                position: "absolute",
                                                top: "0",
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain"
                                            }}
                                        />
                                    </Box>
                                </CardActionArea>
                            </Grid>
                        ))}
                    </Grid>

                    {!isEmpty(input?.viewMoreLink) &&
                        <Box sx={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            margin: "12px",

                        }}>
                            <Link href={input?.viewMoreLink} sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }} underline="hover" >
                                View More
                            </Link>
                        </Box>}
                </Box>
            </Card>

        )
    else {
        return (
            <Card sx={{
                height: "100%",
                marginTop: 2,
                width: "100%",
                // boxShadow: "0px 9px 30px " + theme.palette.extra.shadowColorOne,
                position: "relative",
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 3
                }}>
                    {false ? <Typography gutterBottom sx={{ marginRight: "auto", fontSize: "20px" }}>
                        {title}
                    </Typography>
                        :
                        <Skeleton variant='text' />
                    }
                    <Grid container spacing={2}>
                        {Array(4).fill(1).map((item, index) => (
                            <Grid item xs={6} key={index}>
                                <CardActionArea onClick={() => {
                                    navigate(input.onClickLink + item[input.idField])
                                }} sx={{ width: "100%" }}>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            paddingTop: "56.25%",
                                            position: "relative",
                                            overflow: "hidden"
                                        }}
                                    >
                                        {false?
                                        <CardMedia
                                            component="img"
                                            alt={`Image ${index + 1}`}
                                            image={item[input?.imageField]}
                                            style={{
                                                position: "absolute",
                                                top: "0",
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "fill"
                                            }}
                                        />
                                            :
                                            <Skeleton
                                            variant='rectangular'
                                            sx={{
                                                position: "absolute",
                                                top: "0",
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "fill"
                                            }}
                                            />
                                    }
                                    </Box>
                                </CardActionArea>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Card>
        )
    }
}

export default ProductGrid;
