import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { nodeApi, urlGenarator } from '../../CommonAxios'

const initialState = {
    partners: [],
    loading: false,
    error: "",
    Pagination: {}
}

export const getPartners = createAsyncThunk("homePartners/getPartners", async (formData) => {
    const response = await nodeApi.post("/Partner/Get_partner", formData)
    const data = response?.data
    if (data.Success_Response.Is_Data_Exist == "0") {
        return []
    } else {
        return data
    }
})
export const getPartnersGrocery = createAsyncThunk("homePartners/getPartnersGrocery", async (formData) => {
    const response = await nodeApi.post(urlGenarator("/Home/Grocery_Partner_Get", formData.Pagination), formData.formData)
    const data = response?.data
    if (data.Success_Response.Is_Data_Exist == "0") {
        return []
    } else {
        return data
    }
})


export const partnerFetch = (formData, Pagination = {}) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post(urlGenarator('/Partner/Get_partner', Pagination), formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results, Pagination: res.data.Pagination })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const partnerFetchGrocery = (formData, Pagination = {}) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post(urlGenarator('/Home/Grocery_Partner_Get', Pagination), formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results, Pagination: res.data.Pagination })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const getProducts = (formData, Pagination = {}) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post(urlGenarator('/Products/GetProducts', Pagination), formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results, Pagination: res.data.Pagination })
            }
        }).catch((error) => {
            resolve({ status: false, error: error.message })
        })
    })
}


export const getCoupons = (formData, Pagination = {}) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post(urlGenarator('/Products/GetCoupons', Pagination), formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results, Pagination: res.data.Pagination })
            }
        }).catch((error) => {
            resolve({ status: false, error: error.message })
        })
    })
}
export const getDeals = (formData, Pagination = {}) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post(urlGenarator('/Products/GetDeals', Pagination), formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results, Pagination: res.data.Pagination })
            }
        }).catch((error) => {
            resolve({ status: false, error: error.message })
        })
    })
}

const homePartnerSlice = createSlice({
    name: 'homePartners',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(getPartners.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getPartners.fulfilled, (state, action) => {
                state.loading = false
                state.partners = action.payload.results;
                state.Pagination = action.payload.Pagination
            })
            .addCase(getPartners.rejected, (state, action) => {
                state.loading = false
                state.error = [];
                state.error = action.error;
            })
            .addCase(getPartnersGrocery.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getPartnersGrocery.fulfilled, (state, action) => {
                state.loading = false
                state.partners = action.payload.results?.length > 0 ? action.payload.results : [];
                state.Pagination = action.payload.Pagination
            })
            .addCase(getPartnersGrocery.rejected, (state, action) => {
                state.loading = false
                state.error = [];
                state.error = action.error;
            })

    }
})

export default homePartnerSlice.reducer;