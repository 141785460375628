
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi, urlGenarator } from '../../CommonAxios';
export const fetchCards = createAsyncThunk(
  'cards/fetchCards',
  async (formData) => {
    if (Boolean(formData))
      try {
        const response = await nodeApi.post(urlGenarator('/Products/GetDeals', formData?.Pagination || {}), formData.formData || {});
        return response.data

      } catch (error) {
        // console.error('Error fetching cards:', error);
      }
  }
);
export const groceryFetchCards = createAsyncThunk(
  'cards/groceryFetchCards',
  async (formData) => {
    try {
      const response = await nodeApi.post(urlGenarator('/Products/GetDeals', formData?.Pagination || {}), formData.formData || {});
      return response.data

    } catch (error) {
      // console.error('Error fetching cards:', error);
    }
  }
);



const todayDealsSlice = createSlice({
  name: 'todayDeals',
  initialState: {
    allDealsRetail: [],
    status: 'idle',
    error: null,
    allDeals: [],
    Pagination: {}
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCards.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCards.fulfilled, (state, action) => {
        state.loading = false
        state.allDealsRetail = action.payload?.results;
        state.Pagination = action.payload?.Pagination
      })
      .addCase(fetchCards.rejected, (state, action) => {
        state.loading = false
        state.error = [];
        state.error = action.error;
      }).addCase(groceryFetchCards.pending, (state, action) => {
        state.loading = true;
      }).addCase(groceryFetchCards.fulfilled, (state, action) => {
        state.loading = false
        state.allDeals = action.payload?.results
        state.Pagination = action.payload?.Pagination
      }).addCase(groceryFetchCards.rejected, (state, action) => {
        state.loading = false
        state.error = [];
        state.error = action.error
      })
  }
});

export const todayDealsReducer = todayDealsSlice.reducer;

export const selectAllCards = (state) => state.todayDeals.allDealsRetail;
export const grocerySelectAllCards = (state) => state.todayDeals.allDeals;

export default todayDealsSlice.reducer;
