import React, { useEffect, useState } from 'react';
import {Box, FormControl,Typography, AccordionSummary, AccordionDetails, Accordion, Skeleton } from '@mui/material';
import { fetchFAQ } from '../../../store/slices/retail/home/Faqlice';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser'
import useScreenSize from '../../../utils/ScreenWidthHight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@emotion/react';

const Faqs = () => {
    const faqs = useSelector(state => state.faq.faqData);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(fetchFAQ());
    }, []);

    const winSize = useScreenSize();
    const theme = useTheme()


    return (
        <Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", marginLeft: { md: "10%", sm: '10%', xs: '4%' }, marginTop: "2%" }}>
                <Typography sx={{ fontSize: { md: '24px', sm: '24px', xs: '34px' }, fontWeight: 'bold', color: theme.palette.primary.main, mb: '10px' }}>FAQ's</Typography></Box>
            <Box sx={{ display: 'flex' }}>

                {winSize.screenSize.width > 1050 &&
                    <Box sx={{ flex: '1', textAlign: 'center' }}>
                        <img
                            src={require(`../../../assests/images/tickets.png`)}
                            alt="Your Image"
                            sx={{ maxWidth: '611px', maxHeight: '499px', width: '100%', height: '100%' }}
                        />
                    </Box>}
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 3, alignItems: "center" }} >
                    {!loading ? (
                        Array(5).fill(0).map((_, index) => (
                            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={index}>
                                <Accordion sx={{ width: { md: "70%", sm: '70%', xs: '92%' }, border: "1px solid " + theme.palette.primary.main, }}>
                                    <AccordionSummary
                                        aria-controls="panel1a-content"
                                        expandIcon={<ExpandMoreIcon />}
                                        sx={{ display: "flex", flexDirection: "row", alignItems: "center", height: "auto", justifyContent: "space-between", '@media (max-width: 600px)': { maxWidth: "100%" } }}
                                    >
                                        <Skeleton variant="text" width="60%" />
                                    </AccordionSummary>

                                    <AccordionDetails sx={{ border: "1px solid " + theme.palette.primary.main, }}>
                                        <FormControl sx={{ width: "100%" }}>
                                            <Box sx={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                flexDirection: "row",
                                            }}>
                                                <Box sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    '@media (max-width: 600px)': {
                                                        width: "100%",
                                                        height: 'auto'
                                                    },
                                                    color: theme.palette.text.secondary,
                                                }}>
                                                    <Skeleton variant="text" width="100%" />
                                                </Box>
                                            </Box>
                                        </FormControl>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        ))
                    ) : (faqs?.map((faq, index) => (
                        <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={index}>
                            <Accordion sx={{ width: { md: "70%", sm: '70%', xs: '92%' }, border: "1px solid" + theme.palette.primary.main, }}>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    expandIcon={<ExpandMoreIcon />}

                                    sx={{ display: "flex", flexDirection: "row", alignItems: "center", height: "auto", justifyContent: "space-between", '@media (max-width: 600px)': { maxWidth: "100%" } }}
                                >
                                    <Typography sx={{ color: theme.palette.text.secondary, letterSpacing: "-.5px", overflow: 'hidden', flexGrow: 1 }}>{faq?.Question}</Typography>
                                </AccordionSummary>

                                <AccordionDetails sx={{ border: "1px solid" + theme.palette.primary.main, }}>
                                    <FormControl sx={{ width: "100%" }}>
                                        <Box sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            flexDirection: "row",
                                        }}>
                                            <Box value="Order" sx={{
                                                width: "100%",
                                                display: "flex",
                                                flexWrap: "wrap",
                                                '@media (max-width: 600px)': {
                                                    width: "100%",
                                                    height: 'auto'
                                                },
                                                color: theme.palette.text.secondary,
                                            }}>
                                                <Typography sx={{ color: theme.palette.text.secondary, letterSpacing: "-.5px", overflow: 'hidden', width: '100%' }}>{parse(faq?.Answer)}</Typography>

                                            </Box>
                                        </Box>
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    ))
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Faqs;
