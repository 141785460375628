import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi, urlGenarator } from '../../CommonAxios';

const initialState = {
  products: [],
  pagination: { PageNo: 1, PageSize: 20, SortBy: "PRODUCT_ID", SortOrder: "DESC" },
  filters: {
    Search: "",
    Business_Type: "",
    Status: "Active",
    Brand_Id: [],
    Partner_Details_Id: [],
    Category_Id: [],
    Sub_Category_Id: [],
    Is_Top_Product: 0,
    Is_Popular: 0,
    Is_Verified: 0,
    Whats_Hot: 0,
    Is_Upcoming: 0,
    Min_Discount: 0,
    Max_Discount: 0,
    Min_Price: 0,
    Max_Price: 0
  },
  loading: false,
  error: null,
  retailProducts: [],
  rLoading: false,
  retailPagination: { PageNo: 1, PageSize: 20, SortBy: "PRODUCT_ID", SortOrder: "DESC" },
};

export const fetchAllProducts = createAsyncThunk(
  'allProducts/fetchAllProducts',
  async ({ filters = {}, pagination = {} }) => {
    try {
      const response = await nodeApi.post(urlGenarator('/Products/GetProducts', pagination), filters);
      if (response.data?.results) {
        return response.data;
      } else {
        throw new Error("data not found.")
      }
    } catch (error) {
      throw error;
    }
  }
);

export const retailProducts = createAsyncThunk(
  'allProducts/retailProducts',
  async ({ filters = {}, pagination = {} }) => {
    try {
      const response = await nodeApi.post(urlGenarator('/Products/GetProducts', pagination), filters);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const AllProductsSlice = createSlice({
  name: 'allProducts',
  initialState,
  reducers: {
    updateFilter(state, action) {
      return { ...state, filters: { ...state.filters, ...action.payload } }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.results.length > 0){
          if (action.payload.Pagination?.PageNo != 1) {
            state.products = state.products.concat(action.payload.results || []);
          } else if (action.payload.Pagination?.PageNo == state.pagination?.PageNo && state.pagination?.PageNo != 1) {

          } else {
            state.products = action.payload.results;
          }
        }
        else{
          state.products = []
        }
        state.pagination = action.payload.Pagination
      })
      .addCase(fetchAllProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }).addCase(retailProducts.pending, (state) => {
        state.rLoading = true;
      })
      .addCase(retailProducts.fulfilled, (state, action) => {
        state.retailProducts = action.payload.results;
        state.retailPagination = action.payload.Pagination;
        state.rLoading = false;

      }).addCase(retailProducts.rejected, (state, action) => {
        state.rLoading = false;
      });
  },
});

export const AllProductsActions = AllProductsSlice.actions;

export default AllProductsSlice.reducer;