export const isEmpty = (value) => {
    // Check if value is null or undefined
    if (value === null || value === undefined) {
        return true;
    }

    // Check if value is an empty string
    if (typeof value === 'string' && value.trim() === '') {
        return true;
    }

    // Check if value is an empty array
    if (Array.isArray(value) && value.length === 0) {
        return true;
    }

    // Check if value is an empty object
    if (typeof value === 'object' && Object.keys(value).length === 0) {
        return true;
    }

    // For other types, return false
    return false;
}

export const isNumber = (value) => {
    return !isNaN(Number(value));
}