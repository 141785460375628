import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPartnersGrocery } from "../../../store/slices/retail/home/PartnerSlice";
import PartnerCard from "../../components/cards/PartnerCard";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import noPartnerFound from '../../../assests/images/partnerNotAvailableLocation.png'
import useScreenSize from "../../../utils/ScreenWidthHight";

const PartnerSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const winsize = useScreenSize();
    const dispatch = useDispatch();
    const { partners = [], loading } = useSelector((state) => state.homePartners);
    const address = useSelector(state => state.commonReducer.Address)
    useEffect(() => {
        dispatch(getPartnersGrocery({
            formData: {
                Business_Type: 'GROCERY', City_ID: address.City_Id
            },
            Pagination: {
                PageNo: 1,
                PageSize: 6
            }
        }));
    }, [address]);

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'end', marginTop: '10px' }}>
                <Typography variant="body1" sx={{ fontSize: '24px', fontWeight: 'bold', color: theme.palette.text.secondary, }}>
                    Partner
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        "&:hover": {
                            textDecoration: 'underline',
                        },
                    }}
                    onClick={() => navigate('/grocery/shop')}
                >
                    View More
                </Typography>
            </Box>

            <Box sx={{ width: "100%", display: "flex", flexDirection: { xs: "row", sm: "row", }, flexWrap: { xs: "unset", sm: "wrap" }, justifyContent: "space-between", columnGap: { xs: "10px", sm: "unset" }, overflowX: "scroll", overflowY: "hidden" }} >
                {(partners?.length > 0 && !loading) && partners?.slice(0, 6).map((partner, index) => (
                    <PartnerCard
                        key={index}
                        name={partner.Name}
                        logoPath={partner.Logo_Path}
                        Short_description={
                            partner.Short_Description.length > 129
                                ? `${partner.Short_Description.slice(0, 89)}...`
                                : partner.Short_Description
                        }
                        rating={partner.Esi_Rating}
                        borderColor={theme.palette.primary.main}
                        onClick={() => { navigate(`/grocery/partner/${partner.Partner_Details_Id}`) }}
                    />
                ))}

                {winsize.screenSize.width > 600 && loading && [0, 1, 2, 3, 4, 5].map((partner, index) => (
                    <PartnerCard
                        key={index}
                        isSkeleton
                    />
                ))}
                {winsize.screenSize.width < 600 && loading && [0, 1,].map((partner, index) => (
                    <PartnerCard
                        key={index}
                        isSkeleton
                    />
                ))}

                {
                    !(partners?.length > 0 && !loading) &&
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} >
                        <Typography sx={{ fontWeight: "bold", fontSize: "30px", textAlign: "center", color: theme.palette.text.secondary }} >No Partner Found</Typography>
                    </Box>

                }
            </Box>
        </Box>
    );
};

export default PartnerSection;
