import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { getPickUp } from '../../../store/slices/retail/home/TableLayoutslice';
import PickUp from '../../components/cards/PickUP';
import Reviews from './Review';
import CustomerMLoved from './CustomerMLoved';
import { resourcePathWithPaylaodCall } from '../../../store/slices/AuthSlice';
import { isEmpty } from '../../../utils';
import useScreenSize from '../../../utils/ScreenWidthHight';

const TableLayout = ({ input }) => {
  const dispatch = useDispatch();
  const { pickUp, loading, error } = useSelector((state) => state.pickUpWhereLeft);
  const [pickUpData, setPickUpData] = useState([])
  const [reviewData, setReviewData] = useState([])
  const [mostLoved, setMostLoved] = useState([])
  const [item1, item2, item3] = input
  const winsize = useScreenSize()
  const apiCall = async (url, input, stateFunction) => {
    const res = await resourcePathWithPaylaodCall(url, input);
    if (res.status) {
      stateFunction(res.data)
    }
  }

  useEffect(() => {
    if (!isEmpty(item1))
      apiCall(item1.resourceURL, item1.requestPayload, setPickUpData)
  }, [item1])
  useEffect(() => {
    if (!isEmpty(item2))
      apiCall(item2.resourceURL, item2.requestPayload, setReviewData)
  }, [item2])
  useEffect(() => {
    if (!isEmpty(item3))
      apiCall(item3.resourceURL, item3.requestPayload, setMostLoved)
  }, [item3])

  if (winsize.screenSize.width > 600)
    return (
      <Grid container spacing={3} justifyContent="center" marginTop={5} rowGap={2} columnGap={3}>
        <Box>
          <PickUp data={pickUpData.slice(0, 4)} itemData={item1} />
        </Box>
        <Box >
          <Reviews data={reviewData[0]} itemData={item2} />
        </Box>
        <Box >
          <CustomerMLoved data={mostLoved.slice(0, 6)} itemData={item3} />
        </Box>
      </Grid>
    )
  else {
    return (
      <Box sx={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",rowGap:"20px",marginTop:"20px"}}>
        <Box sx={{width:"100%"}} >
          <PickUp data={pickUpData.slice(0, 4)} itemData={item1} />
        </Box>
        <Box sx={{width:"100%"}} >
          <Reviews data={reviewData[0]} itemData={item2} />
        </Box>
        <Box sx={{width:"100%"}} >
          <CustomerMLoved data={mostLoved.slice(0, 6)} itemData={item3} />
        </Box>
      </Box>
    )
  }
};

export default TableLayout;
