import React, { useContext, useState } from 'react';
import { Box, TextField } from '@mui/material';
import AllProducts from './AllProducts';
import PartnerFilter from '../../components/retail/QueryFilters/PartnerFilter';
import PopularFilter from '../../components/retail/Filters/PopularFilter';
import SearchIcon from '@mui/icons-material/Search';
import BrandsFilter from '../../components/retail/QueryFilters/BrandsFilter';
import CategoreyFilter from '../../components/retail/QueryFilters/CategoryFilter';
import DiscountsFilter from '../../components/retail/QueryFilters/DiscountsFilter';
import PriceFilter from '../../components/retail/QueryFilters/PriceFilter';
import { useDispatch, useSelector } from 'react-redux';
import HomeProductModal from '../home/HomeProductModal';
import { GlobalContext } from '../../../Context';
const Products = () => {
    const dispatch = useDispatch();
    const [modalProductId, setModalProductId] = useState()
    const [modalOpen, setModalOpen] = useState(false);
    const { searchParams, setSearchParams, updateQueryParam,deleteQueryParam } = useContext(GlobalContext);

    const handleSetModalProductId = (value) => {
        setModalProductId(value)
    }

    const handleModalClose = () => {
        setModalOpen(false)
    }
    const handleModalOpen = () => {
        setModalOpen(true)
    }


    return (
        <Box
            sx={{
                width: "100%",
                display: 'flex',
                justifyContent: "center"
            }}
        >
            <Box sx={{ display: "flex", width: { xs: "90%", sm: "90%", md: "95%" }, flexDirection: { xs: 'column', sm: "row", md: 'row' }, gap: "2%", }} >

                <Box
                    sx={{
                        width: { xs: '100%', sm: "28%", md: '28%' },
                        // height: 700,
                        overflow: 'hidden',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                            width: '0px',
                            background: 'transparent',
                        },

                    }}
                >
                    {/* Search TextField */}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={searchParams.get('Search')||''}

                        onChange={(e) => {
                            updateQueryParam([{Search:e.target.value}])
                        }}
                        name="Search Product"
                        placeholder="Search Product"
                        InputProps={{
                            startAdornment: (
                                <SearchIcon color='primary' />
                            ),
                        }}
                        sx={{
                            '& fieldset': { borderRadius: '12px' },
                        }}
                    />

                    {/* CustomizedSelect Components */}
                    <PartnerFilter />
                    <BrandsFilter />
                    <CategoreyFilter />
                    {/* CustomizedCheckBox Components */}
                    <PopularFilter name="Popular" checkboxes={[{
                        id: 'Is_Popular', value: searchParams.get('Is_Popular') == 1 ? true : false, label: 'popular Products',
                        onChange: ((e) => {
                            
                            if(e.target.checked){
                                updateQueryParam([{Is_Popular:1}])
                            }else{
                                deleteQueryParam('Is_Popular')
                            }

                        })
                    },
                    {
                        id: 'Is_Top_Product', value: searchParams.get('Is_Top_Product') == 1 ? true : false, label: 'Top Products',
                        onChange: ((e) => {

                            if(e.target.checked){
                                updateQueryParam([{Is_Top_Product:1}])
                            }else{
                                deleteQueryParam('Is_Top_Product')
                            }

                        })
                    },
                    {
                        id: 'Whats_Hot', value: searchParams.get('Whats_Hot') == 1 ? true : false, label: "Today's Hot Products",
                        onChange: ((e) => {

                            if(e.target.checked){
                                updateQueryParam([{Whats_Hot:1}])
                            }else{
                                deleteQueryParam('Whats_Hot')
                            }

                        })
                    },
                    {
                        id: 'Is_Upcoming', value: searchParams.get('Is_Upcoming') == 1 ? true : false, label: "Upcoming Products",
                        onChange: ((e) => {

                            if(e.target.checked){
                                updateQueryParam([{Is_Upcoming:1}])
                            }else{
                                deleteQueryParam('Is_Upcoming')
                            }

                        })
                    },
                    {
                        id: 'Is_Verified', value: searchParams.get('Is_Verified') == 1 ? true : false, label: "verified Products",
                        onChange: ((e) => {

                            if(e.target.checked){
                                updateQueryParam([{Is_Verified:1}])
                            }else{
                                deleteQueryParam('Is_Verified')
                            }

                        })
                    }]} />
                    {/* <ExtraCashBackFilter name="Extra CashBack" checkboxes={['Extra Cashback']} /> */}
                    <DiscountsFilter name="Discount's" />
                    <PriceFilter name="Price" />
                </Box>

                <Box
                    sx={{
                        width: { xs: '100%', sm: "70%", md: '70%' },
                        // height: 700,
                        overflow: 'hidden',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                            width: '0px',
                            background: 'transparent',
                        },

                    }}
                >
                    <AllProducts handleSetModalProductId={handleSetModalProductId} handleModalOpen={handleModalOpen} />
                    <HomeProductModal modalProductId={modalProductId} handleModalClose={handleModalClose} modalOpen={modalOpen} handleSetModalProductId={handleSetModalProductId} />
                </Box>
            </Box>
        </Box>
    );
}
export default Products