import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { envConfig, nodeApi, nodeCudApi } from './CommonAxios';
import CryptoJS from 'crypto-js';

const initialState = {
    user: {},
    error: '',
    isAuthenticated: null,
    sessionExpiredTime: 60 * 55,
    sessionExpiredDate: new Date().setSeconds(new Date().getSeconds() + (60 * 55)),
    isLoading: false,
}


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signInStateUpdate(state, action) {
            return { ...state, user: action.payload, isAuthenticated: true }
        },
        signOutStateUpdate(state, action) {
            return { ...state, user: {}, isAuthenticated: false }
        },
        sessionExpireUpdate(state, action) {
            return { ...state, sessionExpiredTime: action.payload.sessionExpiredTime, sessionExpiredDate: action.payload.sessionExpiredDate }
        }
    },
    extraReducers(builder) {

    }
})

export const authActions = authSlice.actions;

export const signInAction = (input) => {
    return new Promise((resolve, reject) => {
        nodeCudApi.post(`/User_Signup_Flow_New/User_Signin`, input)
            .then(async response => {
                let data = response.data;
                if (data.Tokens) {
                    const encryptedToken = CryptoJS.AES.encrypt(JSON.stringify(data.Tokens), envConfig.CRYPTO_KEY).toString();

                    localStorage.setItem('Token', encryptedToken);
                }
                if (data.Response_Status == "Success") {
                    resolve({ status: true, data: data, input });
                }
                else {
                    resolve({ status: false, data: data.UI_Display_Message, input });
                }
            })
            .catch(error => {
                resolve({ status: false, data: error.toString(), input });

            })
    });
};

export const signUpAction = (input) => {
    return new Promise((resolve, reject) => {
        nodeCudApi.post(`/User_Signup_Flow_New/User_Signup`, input)
            .then(async response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    resolve({ status: true, data: data });
                }
                else {
                    resolve({ status: false, data: data.UI_Display_Message });
                }
            })
            .catch(error => {
                resolve({ status: false, data: error.toString() });

            })
    });
};

export const forgotPassWordCreate = (input) => {
    return new Promise((resolve, reject) => {
        nodeCudApi.post(`/User_Signup_Flow_New/Web_Forgot_Password_Create`, input)
            .then(async response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    resolve({ status: true, data: data });
                }
                else {
                    resolve({ status: false, data: data.UI_Display_Message });
                }
            })
            .catch(error => {
                resolve({ status: false, data: error.toString() });

            })
    });
};

export const signUpEmailExist = (input) => {
    return new Promise((resolve, reject) => {
        nodeCudApi.post(`/User_Signup_Flow_New/Email_Check`, { Email_ID: input })
            .then(async response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    resolve({ status: true, data: data, input });
                }
                else {
                    resolve({ status: false, data: data.UI_Display_Message, input });
                }
            })
            .catch(error => {
                resolve({ status: false, data: error.toString(), input });

            })
    });
};

export const signUpMobileExist = (input) => {
    return new Promise((resolve, reject) => {
        nodeCudApi.post(`/User_Signup_Flow_New/Mobile_OTP_Check`, { Mobile: "+91 " + input })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    resolve({ status: true, data: data, input });
                }
                else {
                    resolve({ status: false, data: data.UI_Display_Message, input });
                }
            })
            .catch(error => {
                resolve({ status: false, data: error.toString(), input });

            })
    });
};

export const getUserDetailsAction = (email) => {
    return new Promise((resolve, reject) => {
        nodeCudApi.post(`/User/Get`, {
            "Records_Filter": "FILTER",
            "Email_Id": email,
            "Mobile_Number": ""
        })
            .then(response => {
                let successResponse = response.data.Success_Response ? response.data.Success_Response : response.data;
                let user = response.data.Success_Response ? JSON.parse(JSON.stringify(response.data.User_Signup[0])) : false;
                if (successResponse.Response_Status == "Success" && user) {
                    resolve({ status: true, data: user });
                } else {
                    resolve({ status: false, data: successResponse.UI_Display_Message });

                }
            })
            .catch(error => {
                resolve({ status: false, data: "Currently server is not working. Please try again later." });
            })
    });
};

export const signInOTPSent = (input) => {
    return new Promise((resolve, reject) => {
        nodeCudApi.post(`/User_Signup_Flow_New/Login_Mobile_OTP`, { Mobile: "+91 " + input })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    resolve({ status: true, data: data, input });
                }
                else {
                    resolve({ status: false, data: data.UI_Display_Message, input });
                }
            })
            .catch(error => {
                resolve({ status: false, data: error.toString(), input });

            })
    });
};

export const forgotPasswordOTPSent = (input) => {
    return new Promise((resolve, reject) => {
        nodeCudApi.post(`/User_Signup_Flow_New/Forgot_Password_OTP`, { Mobile: "+91 " + input })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    resolve({ status: true, data: data, input });
                }
                else {
                    resolve({ status: false, data: data.UI_Display_Message, input });
                }
            })
            .catch(error => {
                resolve({ status: false, data: error.toString(), input });

            })
    });
};

export const authTokenGenerate = (input) => {
    return new Promise((resolve, reject) => {
        nodeCudApi.post(`/User_Signup_Flow_New/access-token`, input)
            .then(response => {
                let data = response.data;
                resolve({ status: true, data: data });
            })
            .catch(error => {
                if (error.response)
                    resolve({ status: false, data: error.response.data });
                else {
                    resolve({ status: false, data: error.toString() });
                }
            })
    });
};

export const logout = (input) => {
    return new Promise((resolve, reject) => {
        nodeCudApi.post('/User_Signup_Flow_New/Log_Out', input).then((res) => {
            resolve({ status: true, data: res.data })
        }).catch((err) => {
            resolve({ status: false, data: err.toString() })
        })
    })
}


export const getUserSettings = (key, isNumber = true) => {
    return new Promise((resolve, reject) => {
        nodeApi.post(`/Setting/GET`, {
            "Field_Name": key
        })
            .then(async response => {
                const data = response.data;
                if (data?.Success_Response) {
                    if (data?.Success_Response?.Response_Status == "Success") {
                        resolve({ status: true, data: isNumber ? Number(data.setting[0].Field_Value) : data.setting[0].Field_Value });
                    } else {
                        resolve({ status: false, data: "" });
                    }
                }
                else {
                    resolve({ status: false, data: "" });
                }
            })
            .catch(error => {
                resolve({ status: false, data: error.toString() });

            })
    });
};

export const resourcePathWithPaylaodCall = (path, payload) => {
    return new Promise((resolve, reject) => {
        nodeApi.post(path, payload)
            .then(async response => {
                const data = response.data;
                if (data?.Success_Response) {
                    if (data?.Success_Response?.Response_Status == "Success") {
                        resolve({ status: true, data: data.results });
                    } else {
                        resolve({ status: false, data: [] });
                    }
                }
                else {
                    resolve({ status: false, data: [] });
                }
            })
            .catch(error => {
                resolve({ status: false, data: error.toString() });

            })
    });
};

export default authSlice.reducer;

export const { signOutStateUpdate } = authSlice.actions