import { Rating, Typography } from "@mui/material";
import { Box } from "@mui/system";

const DeliveryReviews = ({ rating, comments }) => {
    return (
        <>
            <Box sx={{ width: "100%", marginTop: "10px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", position: "relative", mb: { xs: 2, sm: 0 } }}>
                    <Box sx={{ marginRight: 2, marginBottom: "10px" }}>
                        Your Review
                    </Box>
                    <Rating name="rating" value={rating} readOnly />
                </Box>
                <Typography sx={{ border: "1px solid black", borderRadius: "5px", padding: 2 }}>
                    {comments}
                </Typography>

            </Box>
        </>
    );
}

export default DeliveryReviews;
