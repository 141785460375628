import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, TextField, InputAdornment, Stack, Pagination } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from "react-router-dom";
import Category from '../../components/retail/cateagories_pages/CategoryMain_page';
import { categoriesGetAction } from '../../../store/slices/retail/home/CategorySlice';
import { useTheme } from '@emotion/react';
import noDataFound from '../../../assests/images/noDataFound.png'
import { GlobalContext } from '../../../Context'
import useScreenSize from '../../../utils/ScreenWidthHight';

const CategoryPage = () => {
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const navigate = useNavigate();
    const [error, seterror] = useState('')
    const [pageNo, setPageNo] = useState(1)
    const [oldPage, setOldPage] = useState(1)
    const [totalCount, setTotalCount] = useState(1)
    const { pathname } = useLocation();
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);
    const winsize = useScreenSize();

    async function fetchCategories(formData, Pagination) {
        const response = await categoriesGetAction(formData, Pagination);
        if (response.status) {
            setCategories(response.data.map((retailCategory, index) => ({
                label: retailCategory.Category_Name,
                image: retailCategory.Category_Image_Web,
                Category_Id: retailCategory.Category_Id
            })));
            setTotalCount(response.Pagination.TotalPages)
            setPageNo(response.Pagination.PageNo)
            seterror('')
        } else {
            seterror('No Data found')
            setCategories([])
        }
    }

    useEffect(() => {
        if (searchParams.get("PageNo"))
            fetchCategories({ Search_Category_Name: searchTerm, "Category_Type": "Grocery" }, { PageSize: 12, PageNo: searchParams.get("PageNo") });
        else {
            {
                let querys = "";
                searchParams.forEach((value, key) => {
                    querys += "&" + key + "=" + value
                });
                navigate('/grocery/category?PageNo=1' + querys, { replace: true });
            }
        }
    }, [searchTerm, searchParams, pathname]);

    useEffect(() => {
        setFilteredCategories(categories);
    }, [categories]);

    const handleChangePage = (event, value) => {
        if (searchParams.get("PageNo") !== value.toString())
            updateQueryParam([{ "PageNo": value }])
    };


    return (
        <Box sx={{ width: "100%" }} >
            <Box sx={{ display: "flex", justifyContent: { md: "space-between" }, flexDirection: { xs: 'column', md: 'row' } }}>
                <Typography sx={{ marginTop: "24px", marginLeft: { md: "34px", xs: '25px' }, marginRight: "30px", }}>Categories</Typography>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, marginTop: "20px", marginRight: { md: "43px", xs: '0px' }, marginLeft: { md: "34px", xs: '0px' }, ...(winsize.screenSize.width < 600 ? { justifyContent: "center", alignItems: "center" } : {}) }}>
                    <TextField
                        placeholder="Search Category"
                        size={winsize.screenSize.width < 600 ? "medium" : "small"}
                        value={searchTerm}
                        onFocus={() => {
                            if (!searchTerm) {
                                setOldPage(searchParams.get("PageNo"))
                            }
                        }}
                        onChange={(e) => {
                            const value = e.target.value
                            setSearchTerm(e.target.value);
                            if (value) {
                                handleChangePage(e, 1);
                            } else if (oldPage.toString() !== "1") {
                                navigate(-1)
                            }
                        }}
                        sx={{
                            width: { xs: "90%", md: "345px" },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            sx: { borderRadius: { xs: "16px", sm: "unset" } }
                        }}
                    />
                </Box>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "row" }} >
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: { xs: "90%", sm: "100%" },
                        gap: { xs: 0, sm: 2 },
                        justifyContent: { xs: "space-between", sm: "center" },
                        columnGap: { xs: 0, sm: 2 }
                    }}
                >
                    {
                        (categories.length > 0 || error.length > 0)
                        &&
                        (error.length > 0 ? (
                            <img src={noDataFound} style={{ marginTop: '20px', width: '40%', display: 'block', margin: '20px auto' }} />
                        )
                            :

                            categories.length > 0 && categories.map((category, index) => (
                                <Category
                                    key={index}
                                    index={index}
                                    data={category}
                                    onClick={() => { navigate(`/grocery/subcategory/${category.Category_Id}`) }}
                                />
                            )))
                    }

                    {(categories.length == 0 && error.length == 0) && Array(12).fill(1).map((category, index) => (
                        <Category
                            key={index}
                            index={index}
                            isSkeleton
                        />
                    ))}




                </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
                {totalCount > 1 && (
                    <Stack spacing={2}>
                        <Pagination
                            count={totalCount}
                            page={pageNo}
                            onChange={handleChangePage}
                            color="primary"
                        />
                    </Stack>
                )}
            </Box>
        </Box>
    );
}
export default CategoryPage
