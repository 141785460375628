import { Box, CardMedia, Container, Skeleton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import useScreenSize from '../../../utils/ScreenWidthHight';
import { fetchTermsandConditions } from '../../../store/slices/retail/home/PrivacyPolicy';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser'
import { useTheme } from '@emotion/react';

const Termscondition = () => {
    const winSize = useScreenSize();
    const { loading, termsAndConditions } = useSelector(state => state.privacypolicy);
    const dispatch = useDispatch();
    const theme = useTheme()

    useEffect(() => {
        dispatch(fetchTermsandConditions({ Name:'Partner_Terms and conditions'}));
    }, []);
    return (
        <Box>
            {/* Left Side */}
            <Box sx={{ display: "flex", flexDirection: "row" }}>

                <Box sx={{ display: "flex", flexDirection: { sm: "column", md: "column" ,xs:'row'}, justifyContent: "center", width: "100%", alignItems: "center", gap: 1 }} >
                    {winSize.screenSize.width > 600 ? (
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            {loading ? (
                                <>
                                    <Skeleton variant="text" width="277px" height="144px" />
                                    <Skeleton variant="text" width="177px" height="144px" />
                                    <Skeleton variant="text" width="486px" height="144px" />
                                </>
                            ) : (
                                <>
                                    <Typography style={{ display: 'flex', color: theme.palette.extra.primary, fontSize: '96px', fontWeight: 'bold' }}>
                                        Terms
                                    </Typography>
                                    <Typography variant="h2" style={{ display: 'flex', color: theme.palette.extra.primary, fontSize: '96px', fontWeight: 'bold' }}>
                                        and
                                    </Typography>
                                    <Typography variant="h2" style={{ display: 'flex', color: theme.palette.extra.primary, fontSize: '96px', fontWeight: 'bold' }}>
                                        Conditions
                                    </Typography>
                                </>
                            )}
                        </Box>
                    ) : (
                        <Box sx={{ display: "flex", width: '100%', alignItems: "flex-start", justifyContent: 'flex-start' }}>
                            {loading ? (
                                <Skeleton variant="text" width="150px" height="60px" />
                            ) : (
                                <Typography sx={{ display: 'flex', width: '100%', alignItems: "flex-start", justifyContent: 'flex-start', color: theme.palette.extra.primary, fontSize: '34px', fontWeight: 'bold', ml: { md: '0px', sm: '0px', xs: '26px' } }}>
                                    Terms & Conditions
                                </Typography>
                            )}
                        </Box>
                    )}

                </Box>
                {winSize.screenSize.width > 1050 &&
                    <Box sx={{ flex: '1' }}>
                        {loading ? (
                            <Skeleton variant="rectangular" width="611px" height="499px" sx={{ borderRadius: "24px" , mr:'10px'}} />
                        ) : (
                            <CardMedia
                                component="img"
                                image={require("../../../assests/images/tickets.png")}
                                sx={{
                                    width: '611px',
                                    height: '499px',
                                    display: "flex",
                                    flexDirection: "column",
                                    flexWrap: "wrap"
                                }}
                            />
                        )}
                    </Box>}
            </Box>
            {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 3 }}>
                    <Skeleton variant="rectangular" width="85%" height={700} sx={{ borderRadius: "24px" }} />
                </Box>
            ) : (
                termsAndConditions?.map((termsConditions, index) => (
                    <Box key={index} sx={{ width: { md: '100%', sm: '100%', xs: '90%' }, display: 'flex', justifyContent: 'center', alignItems: 'center', ml: { md: '0px', sm: '0px', xs: '20px' } }}>
                        <Container sx={{ border: "0.4px solid", borderColor: theme.palette.text.secondary, borderRadius: "24px", boxShadow: "0 4px 4px 0 grey" }} key={index}>
                            {parse(termsConditions?.Description)}
                        </Container>
                    </Box>
                ))
            )}
        </Box >
    );
};

export default Termscondition;
