import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, CardMedia, Card, Rating, Skeleton } from "@mui/material";
import { Pagination as PN } from "@mui/material";
import { useTheme } from '@emotion/react';
import useScreenSize from '../../../utils/ScreenWidthHight';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBrandReview } from '../../../store/slices/retail/home/AppReviewlice'
import parser from 'html-react-parser'
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../../Context';
import defaultProfilePic from '../../../assests/images/profileIcon.png';


const ReviewRating = () => {
    const theme = useTheme();
    const winSize = useScreenSize();
    const reviewrating = useSelector(state => state.brandreview.brandReviewData);
    const { Pagination, loading } = useSelector(state => state.brandreview);
    const dispatch = useDispatch();
    const [pageNo, setPageNo] = useState(1)
    const [totalCount, setTotalCount] = useState(1)
    const auth = useSelector(state => state.auth)
    const { pathname } = useLocation();
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);


    useEffect(() => {
        if (searchParams.get("PageNo"))
            dispatch(fetchBrandReview({
                formData: { Email_Id: auth.user.Email_Id, Testimonial_Id: "" },
                Pagination: { PageNo: searchParams.get("PageNo"), PageSize: 12 }
            }));
        else
            updateQueryParam([{ "PageNo": 1 }])
    }, [searchParams, pathname]);

    return (
        <Box sx={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            gap: winSize.screenSize.width <= 749 ? 0 : 4,
            backgroundColor: true ? theme.palette.header.backgroundcolor : theme.palette.background.default,
        }}>
            <Box sx={{ marginTop: "2%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <Typography variant='h4'>Reviews & Rating</Typography>
            </Box>
            <Box sx={{
                width: "100%",
                minHeight: "50%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                alignItems: "center",
                cursor: "pointer",
                gap: 2
            }}>
                {(!loading && reviewrating.length > 0) && reviewrating?.map((review, index) => (
                    <Card
                        key={index}
                        sx={{
                            width: "625px",
                            minHeight: "80px",
                            borderRadius: 6,
                            border: "1px solid " + theme.palette.input.borderColor,
                            boxShadow: "none",
                            zIndex: 1,

                        }}>
                        <Box sx={{ width: "90%", display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "15px" }}>
                            <Box sx={{ display: "flex", flexDirection: "row", marginLeft: "15px" }} >
                                <CardMedia
                                    component="img"
                                    image={review.Profile_Pic_Path || defaultProfilePic}
                                    sx={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: 3,
                                        objectFit: "contain",
                                        borderRadius: "50%"
                                    }}
                                />
                                <Typography variant="h6" sx={{ color: theme.palette.primary, marginLeft: "15px" }}>{review.Full_Name}</Typography>

                            </Box>
                            <Box sx={{ display: "flex" }}>
                                <Rating name="rating" value={review.Rating} precision={.5} readOnly /></Box>
                        </Box>
                        <Box sx={{ height: 'auto', marginLeft: "15px" }}>

                            {parser(review?.Comments ? review?.Comments : " ")}
                        </Box>
                    </Card>
                ))}
                {
                    loading &&

                    Array(8).fill(1)?.map((notification, index) => (
                        <Skeleton
                            key={index}
                            variant='rectangular'
                            sx={{
                                width: "625px",
                                minHeight: "80px",
                                borderRadius: 6,
                                boxShadow: "none",
                                zIndex: 1,

                            }} />



                    ))}
            </Box>
            {(!loading && Pagination?.TotalPages > 1) ? <Box sx={{ display: 'flex', justifyContent: "center", width: "100%", }} >
                <PN page={Pagination?.PageNo} onChange={(e, page) => {
                    updateQueryParam([{ "PageNo": page }])
                }} count={Pagination?.TotalPages} color="primary" />
            </Box> : null}
        </Box>
    )
}
export default ReviewRating
