import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import WishlistCard from "../../components//cards/WishlistCard";
import { fetchWishlistHistory, deleteWishlistItem, } from '../../../store/slices/retail/home/wishlistSlice';
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination as PN } from "@mui/material";
import WishlistSideBar from "./WishlistSideBar";
import ProductModal from "../ProductDetail.js/ProductModal";
import { productDetailByProductId, productWishDeleteAction } from "../../../store/slices/retail/ProductDetails/ProductDetailsActions";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../Context";
import useScreenSize from "../../../utils/ScreenWidthHight";
import noWishlistItems from '../../../assests/images/wishlistIsEmpty.png'

const GroceryWishlist = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const userDetails = useSelector((state) => state.auth);
    const [selectedCheckbox, setSelectedCheckbox] = useState('grocery');
    const { wishlistHistory = [], Pagination, loading } = useSelector(state => state.wishlist);
    const auth = useSelector(state => state.auth);
    const [searchQuery, setSearchQuery] = useState("");
    const [modalProductId, setModalProductId] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [cart, setCart] = useState(1);
    const { pathname } = useLocation();
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);
    const navigate = useNavigate()
    const [cardWidth, setCardWidth] = useState('220px');
    const [screensEachLineCards, setScreensEachLineCards] = useState();

    const winSize = useScreenSize();

    useEffect(() => {
        const screenWidth = winSize.screenSize.width;
        let calculatedWidth = Math.floor(screenWidth / (screenWidth < 900 ? screenWidth > 600 ? 3.5 : 4.5 : (screenWidth < 1200) ? 4.5 : 5.5)) - 20;
        if (calculatedWidth > 250) {
            calculatedWidth = 250;
        }
        setCardWidth(calculatedWidth + 'px');
        setScreensEachLineCards(Math.floor(screenWidth / calculatedWidth))
    }, [winSize.screenSize]);

    useEffect(() => {
        if (screensEachLineCards) {
            if (searchParams.get("PageNo"))
                dispatch(fetchWishlistHistory({
                    formData: { User_Email_Id: userDetails?.user?.Email_Id, Business_Type: "GROCERY", Search: searchQuery, Available_Status: "Active" },
                    Pagination: { PageNo: searchParams.get("PageNo"), PageSize: (screensEachLineCards * (winSize.screenSize.width < 1536 ? 4 : 5)), SortBy: "Created_Date", SortOrder: "DESC" }
                }));
            else {
                {
                    let querys = "";
                    searchParams.forEach((value, key) => {
                        querys += "&" + key + "=" + value
                    });
                    navigate('/accounts/grocery/wishlist?PageNo=1' + querys, { replace: true });
                }
            }
        }
    }, [searchParams, pathname, auth.user.Email_Id, searchQuery, screensEachLineCards]);


    useEffect(() => {
        if (modalProductId) {
            productSMDetailByProductIdCall({ Product_Id: [modalProductId] })
        }
    }, [modalProductId])


    const productSearchCall = async (formData) => {
        setSearchQuery(formData)
        updateQueryParam([{ PageNo: 1 }])
    }

    const handleCheckboxChange = (event) => {
        setSelectedCheckbox(event.target.value);
    };

    const handleDelete = (wishId) => {
        dispatch(deleteWishlistItem(wishId));
    };

    if (!auth?.isAuthenticated) {
        return null;
    }

    const handleModalClose = () => {
        setModalOpen(false)
    }
    const handleModalOpen = () => {
        setModalOpen(true)
    }

    const productSMDetailByProductIdCall = async (formData) => {
        const response = await productDetailByProductId(formData)
        if (response.status) {
            setCart(response.data[0])
        }
        else {
            setCart([])
        }
    }
    const handleSetModalProductId = (value) => {
        setModalProductId(value)
    }

    return (
        <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", flexDirection: { md: "row", sm: "row", xs: "column" }, justifyContent: "space-around", paddingTop: { md: "20px", xs: '20px', sm: '20px' } }} >
            <Box sx={{ width: { md: "25%", sm: "100%", xs: "100%" }, display: "flex", flexDirection: "column", alignItems: "flex-end" }} >
                <Box sx={{ width: { md: "93%", sm: '93%', xs: '95%' } }} >
                    <WishlistSideBar
                        theme={theme}
                        selectedCheckbox={selectedCheckbox}
                        handleCheckboxChange={handleCheckboxChange}
                        productSearchCall={productSearchCall}
                        wishlistCount={Pagination?.TotalCount || 0}
                    />
                </Box>
            </Box>
            <Box sx={{ width: { md: "72%", xs: "100%" }, display: "flex", flexDirection: "column", alignItems: "flex-start" }} >
                <Typography sx={{ fontSize: '24px', width: "96%", color: theme.palette.text.secondary, display: { xs: "none", sm: "contents" }, textAlign: 'left', justifyContent: 'left', alignItems: "flex-start" }}>
                    Wishlist ({Pagination?.TotalCount || 0})
                </Typography>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>

                    <Box display="flex" flexWrap="wrap" sx={{ justifyContent: { md: "flex-start", sm: ((wishlistHistory.length % 3) === 0 ? "space-around" : "flex-start") }, columnGap: { md: "0px", xs: "2%" }, rowGap: "10px", marginLeft: { md: "0", sm: ((wishlistHistory.length % 3) !== 0) ? "5%" : "0" } }}>
                        {
                            (wishlistHistory.length > 0 && !loading) ? (
                                wishlistHistory.map((wishlist, index) => (
                                    <WishlistCard
                                        key={index}
                                        data={wishlist}
                                        // name={wishlist.Product_Name}
                                        name={wishlist.Brand_Name}
                                        productname={wishlist.Product_Name}
                                        logoPath={wishlist.Product_Image}
                                        originalPrice={wishlist.Partner_Price}
                                        sellingPrice={wishlist.Partner_Selling_Price}
                                        onDelete={handleDelete}
                                        formData={{
                                            User_Email_Id: user?.Email_Id,
                                            Business_Type: selectedCheckbox
                                        }}
                                        productSearchCall={productSearchCall}
                                        handleSetModalProductId={handleSetModalProductId}
                                        handleModalOpen={handleModalOpen}
                                        cardWidth={cardWidth}
                                        isActive={wishlist.Available_Status !== "In-Active"}
                                    />
                                ))
                            ) : (
                                (
                                    loading ? Array(8).fill(1).map((item, index) => {
                                        return (
                                            <WishlistCard
                                                key={index}
                                                isSkeleton
                                                cardWidth={cardWidth}
                                            />
                                        )
                                    })
                                        :
                                        ((!loading && wishlistHistory.length === 0) && <img src={noWishlistItems} alt="noWishlistItems" style={{ width: '100%', height: '80%', objectFit: 'contain' }} ></img>)
                                )
                            )
                        }
                    </Box>

                    {(!loading && Pagination?.TotalPages > 1) && (
                        <Box sx={{ display: 'flex', justifyContent: "center", width: "100%" }}>
                            <PN
                                page={Pagination?.PageNo}
                                onChange={(e, page) => {
                                    updateQueryParam([{ PageNo: page }])
                                }}
                                count={Pagination?.TotalPages}
                                color="primary" />
                        </Box>
                    )}
                </Box>
            </Box>
            <ProductModal modalOpen={modalOpen} handleModalClose={handleModalClose} productDetails={cart} handleModalProductId={handleSetModalProductId} modalProductId={modalProductId} removeItemWishList={async (wishIdHandle) => {
                const response = await productWishDeleteAction({ Wish_Id: wishIdHandle })
                if (response.status) {
                    dispatch(fetchWishlistHistory({
                        formData: { User_Email_Id: userDetails?.user?.Email_Id, Business_Type: "GROCERY" },
                        Pagination: { PageNo: 1, PageSize: (screensEachLineCards * 6), SortBy: "Created_Date", SortOrder: "DESC" }
                    }));
                }
            }} />
        </Box>
    );
};
export default GroceryWishlist;
