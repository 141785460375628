import { Button, Grid, IconButton, Modal, TextField, Typography } from "@mui/material";
import CheckOut from "./Address&Coupons";
import PriceDetails from "./PriceDetails";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchWallet } from "../../../store/slices/accounts/WalletSlice";
import { corpVoucherCheck, corpVoucherCheckVerification, couponCodeCheckAction, getCart, giftVoucherCheck, giftVoucherCheckVerification, placeNewOrderAction, removeAll, removePartnerInCart, updateOrderAfterPayment } from "../../../store/slices/accounts/cart/CartSlice";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../../utils";
import { Box } from "@mui/system";
import { Formik } from "formik";
import * as Yup from 'yup';
import useRazorpay from "react-razorpay";
import razorpayPay from "../../../utils/Payment";
import { GlobalContext } from "../../../Context";
import { getUserSettings } from "../../../store/slices/AuthSlice";
import useScreenSize from "../../../utils/ScreenWidthHight";

const CheckOutDetails = () => {
    const dispatch = useDispatch();
    const winsize = useScreenSize()
    const { carts } = useSelector(state => state.cartSlice || []);
    const userDetails = useSelector(state => state.auth);
    const user = useSelector(state => state.auth.user);
    const [partnerCartState, setPartnerCartState] = useState([]);
    const { partnerId } = useParams();
    const navigate = useNavigate();
    const [fgPartnerOrderRequiredAmount, setFGPartnerOrderRequiredAmount] = React.useState(150);
    const [corpVoucher, setCorpVoucher] = React.useState({
        text: '', validate: {}, loading: false, errorMessage: '',
        result: {}
    });

    const [giftVoucher, setGiftVoucher] = React.useState({
        text: '', giftValidate: {}, loading: false, errorMessage: '',
        result: {}
    });
    const { wallet } = useSelector(state => state.wallet);
    const [useWallet, setUseWallet] = useState(false);
    const [maxWalletUsage, setMaxWalletUsage] = React.useState(50);



    const [visibleC, setVisibleC] = React.useState(false);
    const [secondsC, setSecondsC] = React.useState(-1);

    const [visible, setVisible] = React.useState(false);
    const [seconds, setSeconds] = React.useState(-1);

    const [cashBackCoupon, setCashBackCoupon] = useState({});
    const [Razorpay, isLoaded] = useRazorpay();
    const [billingAddress, setBillingAddress] = useState({});

    const { toolTipActive } = React.useContext(GlobalContext);
    const [orderPlaced, setOrderPlaced] = useState(false);
    const [addressError, setAddressError] = useState("");
    const [isNeedToNavigate, setIsNeedToNavigate] = useState(false);


    useEffect(() => {
        if (isNeedToNavigate) {
            navigate('/accounts/paymentsuccess', { replace: true });
            setIsNeedToNavigate(false)
        }
    }, [isNeedToNavigate]);

    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    React.useEffect(() => {
        const timer = setTimeout(() => secondsC > 0 ? setSecondsC(secondsC - 1) : setSecondsC(-1), 1000)
        return () => clearTimeout(timer)
    }, [secondsC]);

    // Fetch wallet history
    useEffect(() => {
        dispatch(fetchWallet({ User_Details_Id: userDetails?.user?.User_Details_Id }));
    }, [userDetails]);

    useEffect(() => {
        (async () => {
            const res = await getUserSettings("CART_DELIVERY_CHARGE_BALANCER", false);
            if (res.status)
                setFGPartnerOrderRequiredAmount(res.data);

            const res1 = await getUserSettings("ORDER_MAX_WALLET_USAGE_P", false);
            if (res.status)
                setMaxWalletUsage(res1.data);
        })()
    }, [])

    // Calculate wallet amount


    // Find partnerCart based on partnerId
    useEffect(() => {

        let isVerified = false;
        let partnerCart = [];
        if (carts.length > 0) {
            partnerCart = JSON.parse(JSON.stringify(carts)).filter(cart => {
                return (cart.Partner_Details_Id === partnerId || partnerId == "ALL") && cart.Verified
            }).map((item => {
                item.coupon = {
                    text: '', isLoading: false, result: {}, errorMessage: '', partner: { id: item.Partner_Details_Id, Name: item.Partner_Name }
                }
                return item
            }));
            if (partnerId == "ALL") {
                if (partnerCart.length === carts.length) {
                    isVerified = true;
                }
            } else {
                if (partnerCart.length === 1) {
                    isVerified = true;
                }
            }
        }
        if (isVerified && partnerCart) {
            setPartnerCartState(partnerCart);
        } else {
            navigate("/accounts/cart");
        }

    }, [carts, partnerId]);


    const getPartnerOrderOriginalPrice = (items) => {
        let amount = 0;
        items.Products.map(product => {
            amount = amount + (Number(product.Partner_Price) * Number(product.Quantity))
        });
        return amount.toFixed(2);
    };

    const getAllPartnerOriginalPrice = useMemo(() => {
        let amount = 0;
        partnerCartState.map((partner) => {
            amount = amount + Number(getPartnerOrderOriginalPrice(partner));
        })
        return amount.toFixed(2)
    }, [partnerCartState]);

    const getPartnerOrderPrice = (items) => {
        let amount = 0;
        items.Products.map(product => (
            amount = amount + (Number(product.Partner_Selling_Price) * Number(product.Quantity))
        ));
        return amount.toFixed(2);
    };

    const getAllPartnerOrderPrice = useMemo(() => {
        let amount = 0;
        partnerCartState.map((partner) => {
            amount = amount + Number(getPartnerOrderPrice(partner));
        })
        return amount
    }, [partnerCartState]);

    const getDiscountPrice = useMemo(() => {
        return (Number(getAllPartnerOriginalPrice) - Number(getAllPartnerOrderPrice)).toFixed(2);
    }, [partnerCartState]);

    const couponDiscountAmount = (items) => {
        let coupon = items.coupon;
        let amount = 0;
        let productsAvailable = false;
        let products = items.products.filter(pro => {
            let found = false;
            if (coupon.Category_Id && coupon.Brand_Id) {
                if (coupon.Category_Id === pro.Category_Id && coupon.Brand_Id === pro.Brand_Id) {
                    found = true;
                }
            } else if (coupon.Brand_Id) {
                if (coupon.Brand_Id === pro.Brand_Id) {
                    found = true;
                }
            } else if (coupon.Category_Id === pro.Category_Id) {
                found = true;
            }
            return found;
        });
        products.map(product => (
            amount = amount + (Number(product.Partner_Selling_Price) * Number(product.Quantity))
        ));
        if (products.length > 0) {
            productsAvailable = true;
        }
        if (productsAvailable) {
            let response = {
                discountValid: coupon.Discount_Type ? true : false,
                discount: coupon.Discount_Type === "P" ? true : false,
                amount: 0,
            };
            let discountAmount = 0;
            if (response.discountValid) {
                if (response.discount) {
                    discountAmount = (amount / 100) * Number(coupon.Discount_Amount);
                } else {
                    if (Number(coupon.Discount_Amount) > amount) {
                        discountAmount = amount;
                    } else {
                        discountAmount = coupon.Discount_Amount;
                    }
                }
            }

            return discountAmount.toFixed(2);
        } else {
            return (0).toFixed(2);
        }
    }

    const allCouponDiscount = useMemo(() => {
        let amount = 0;
        partnerCartState.map((partner) => {
            amount = amount + (partner?.coupon?.result ? Number(couponDiscountAmount({ "coupon": partner.coupon.result, "products": partner.Products })) : 0);
        })

        return amount.toFixed(2)
    }, [partnerCartState])


    const deliveryValueCaluculation = (orderValue, arrayData) => {
        let response = { charge: 0, message: "" };
        function sortResults(input, prop, asc) {
            input.sort(function (a, b) {
                if (a[prop]) {
                    if (asc) {
                        return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
                    } else {
                        return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
                    }
                } else {
                    throw new Error(prop + ' is not a exist!');
                }
            });
            return input;
        }
        try {
            let data = sortResults(JSON.parse(arrayData), "value", true);
            for (let i = 0; i < data.length; i++) {
                if (orderValue < data[i].value || (i == data.length - 1 && orderValue >= data[i].value)) {
                    let message = "";
                    let charge = 0;
                    if (i == 0 || i == data.length - 1) {
                        charge = data[i].charge
                    }
                    else {
                        charge = data[i - 1].charge
                    }
                    if (orderValue < data[i].value && i < data.length - 1) {
                        if (data[data.length - 1].charge == 0) {
                            message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + " more, To get free delivery please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + " more.";
                        } else {
                            if (i + 1 == data.length - 1)
                                message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + "more.";
                            else
                                message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + " more, and reduce ₹" + (charge - data[data.length - 1].charge) + " delivery charges please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + "more.";
                        }
                    } else if (orderValue < data[i].value) {
                        if (data[data.length - 1].charge == 0) {
                            message = "To get free delivery please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + " more.";
                        } else {
                            if (i + 1 == data.length - 1)
                                message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + "more.";
                        }
                    }
                    response = { charge: charge, message: message };
                    break;
                }
            }
        } catch (error) {
        }
        return response
    }


    const getDeliveryPrice = (items) => {
        let extraCharge = 0;
        let amount = 0;
        items.Products.map(product => {
            amount = amount + (Number(product.Shipping_Amount ? product.Shipping_Amount : 0) * Number(product.Quantity))
        });
        if (items?.Business_Type == "FOOD" || items?.Business_Type == "GROCERY") {
            extraCharge = deliveryValueCaluculation(Number(getPartnerOrderPrice(items)), fgPartnerOrderRequiredAmount).charge;
        }
        return (amount + extraCharge).toFixed(2);
    };


    const getAllDeliveryPrice = useMemo(() => {
        let amount = 0;
        partnerCartState.map((partner) => {
            amount = amount + Number(getDeliveryPrice(partner));
        })
        return amount.toFixed(2)
    }, [partnerCartState, fgPartnerOrderRequiredAmount]);


    const getGiftPackPrice = (items) => {
        let amount = 0;
        items.Products.map(product => {
            if (Boolean(product?.Gift_Message)) {
                let amount2 = product?.Is_Gift_Wrap_Available === 1 ? Number(product.Gift_Wrap_Amount ? product.Gift_Wrap_Amount : 0) : 0;
                amount = amount + amount2;
            }
        });
        return amount.toFixed(2);
    };

    const getAllGiftPackPrice = useMemo(() => {
        let amount = 0;
        partnerCartState.map((partner, index) => {
            amount = amount + Number(getGiftPackPrice(partner));
        })
        return amount.toFixed(2)
    }, [partnerCartState]);


    const corpDiscountAmount = useMemo(() => {
        let amount = 0;
        if (!isEmpty(corpVoucher.result)) {
            let coupon = Number(allCouponDiscount);
            let delivery = Number(getAllDeliveryPrice);
            let giftPackAmount = Number(getAllGiftPackPrice);
            let totalBeforeCrop = Number(getAllPartnerOrderPrice) + delivery + giftPackAmount - coupon;
            amount = (totalBeforeCrop < Number(corpVoucher.result.Discount_Amount) ? totalBeforeCrop : Number(corpVoucher.result.Discount_Amount))
        }
        return amount.toFixed(2)
    }, [corpVoucher, partnerCartState])


    const getGiftDiscountPrice = useMemo(() => {
        let amount = 0;
        if (!isEmpty(giftVoucher.result)) {
            let coupon = Number(allCouponDiscount);
            let delivery = Number(getAllDeliveryPrice);
            let giftPackAmount = Number(getAllGiftPackPrice);
            let getCorporateCoupon = Number(corpDiscountAmount);
            let totalBeforeGift = Number(getAllPartnerOrderPrice) + delivery + giftPackAmount - coupon - getCorporateCoupon;
            amount = (totalBeforeGift < Number(giftVoucher.result.Gift_Remaining_Balance) ? totalBeforeGift : Number(giftVoucher.result.Gift_Remaining_Balance))
        }
        return amount.toFixed(2);
    }, [giftVoucher, partnerCartState]);

    const getWalletDiscountPrice = useMemo(() => {
        let amount = 0;
        let giftUseAmont = Number(getGiftDiscountPrice);
        let coupon = Number(allCouponDiscount);
        let delivery = Number(getAllDeliveryPrice);
        let giftPackAmount = Number(getAllGiftPackPrice);
        let getCorporateCoupon = Number(corpDiscountAmount);
        let totalBeforeWallet = Number(getAllPartnerOrderPrice) + delivery + giftPackAmount - coupon - getCorporateCoupon - giftUseAmont;
        if (Number(wallet?.Remaining_Balance))
            amount = (totalBeforeWallet * (maxWalletUsage / 100)) < Number(wallet?.Remaining_Balance) ? (totalBeforeWallet * (maxWalletUsage / 100)) : Number(wallet?.Remaining_Balance);
        return amount.toFixed(2)
    }, [partnerCartState, getGiftDiscountPrice, allCouponDiscount, getAllDeliveryPrice, getAllGiftPackPrice, corpDiscountAmount, getAllPartnerOrderPrice, maxWalletUsage]);

    const getWalletUsedAmount = () => {
        return useWallet ? getWalletDiscountPrice : "0.00"
    };

    const orderFinalAmount = useMemo(() => {
        let amount = 0;
        let giftUseAmont = Number(getGiftDiscountPrice);
        let coupon = Number(allCouponDiscount);
        let delivery = Number(getAllDeliveryPrice);
        let giftPackAmount = Number(getAllGiftPackPrice);
        let getCorporateCoupon = Number(corpDiscountAmount);
        let getWallet = Number(getWalletUsedAmount());
        amount = Number(getAllPartnerOrderPrice) + delivery + giftPackAmount - coupon - getCorporateCoupon - giftUseAmont - getWallet;
        return amount.toFixed(2)
    }, [partnerCartState, getGiftDiscountPrice, allCouponDiscount, getAllDeliveryPrice, getAllGiftPackPrice, corpDiscountAmount, useWallet])

    const couponCheck = async (index) => {
        let dummyCoupon = JSON.parse(JSON.stringify(partnerCartState));
        let coupon = dummyCoupon[index].coupon;
        coupon.errorMessage = '';
        coupon.isLoading = true;
        // setPartnerCartState(dummyCoupon);
        const res = await couponCodeCheckAction(coupon.text, userDetails.user.Email_Id, coupon.partner.id);
        if (res.status) {
            coupon.result = res.data;
            coupon.errorMessage = "*COUPON APPLIED SUCCESFULLY"
        } else {
            coupon.errorMessage = res.data;
        }
        coupon.isLoading = false;
        setPartnerCartState(dummyCoupon);
    };

    const corpVoucherCheckLocal = async () => {
        setCorpVoucher({ ...corpVoucher, loading: true, errorMessage: "" });
        const res = await corpVoucherCheck(corpVoucher.text);
        if (res.status) {
            setCorpVoucher({ ...corpVoucher, validate: JSON.parse(JSON.stringify(res.data)), loading: false, errorMessage: '' });
            setVisibleC(true);
            setSecondsC(30);
        }
        else {
            setCorpVoucher({ ...corpVoucher, validate: {}, loading: false, errorMessage: res.data });
        }

    };

    const giftVoucherCheckLocal = async () => {
        setGiftVoucher({ ...giftVoucher, loading: true, errorMessage: "" });
        if (giftVoucher.text === giftVoucher.text.toUpperCase()) {
            const res = await giftVoucherCheck(giftVoucher.text);
            if (res.status) {
                setGiftVoucher({ ...giftVoucher, validate: JSON.parse(JSON.stringify(res.data)), loading: false, errorMessage: '' });
                setVisible(true);
                setSeconds(30);
            }
            else {
                setGiftVoucher({ ...giftVoucher, validate: {}, loading: false, errorMessage: res.data });
            }
        }
        else
            setGiftVoucher({ ...giftVoucher, validate: {}, loading: false, errorMessage: "Invalid Gift Voucher Id" });
    };

    const getPartnerOrderPriceById = (partnerId) => {
        let amount = 0;
        let partner = partnerCartState.filter((item) => item.Partner_Details_Id === partnerId);
        if (!isEmpty(partner)) {
            amount = amount + Number(getPartnerOrderPrice(partner[0]));
        }
        return amount.toFixed(2)
    };

    const placeOrderAfterPayment = async (type, transactionId, amount) => {
        let Partner_Orders = [];
        partnerCartState.map((partner) => {
            if (!isEmpty(partner)) {

                let orderProducts = [
                ];

                partner.Products.map((masterProduct) => {
                    let newOrderProduct = {
                        "Is_Gift": masterProduct.Gift_Message ? 1 : 0,
                        "Gift_Message": masterProduct.Gift_Message,
                        "Line_Item_Product_ID": masterProduct.Product_Id,
                        "Line_Item_Partner_Product_ID": masterProduct.Partner_Product_Id,
                        "Line_Item_Qty": masterProduct.Quantity,
                        "Selling_Price": masterProduct.Partner_Selling_Price,
                        "Size": masterProduct.Product_Size_Id,
                        "Shipping_Address": {
                            "User_Address_Id": masterProduct.User_Shipping_Address_Id
                        },
                    }
                    orderProducts.push(newOrderProduct);
                });


                Partner_Orders.push(
                    ["RETAIL", "FARMER", "MFH"].includes(partner.Business_Type) ?
                        {
                            "Coupon_Code": partner.coupon.result?.Code,
                            "Coupon_Discount_Amount": couponDiscountAmount({ "coupon": partner.coupon.result, "products": partner.Products }),
                            "Coupon_ID": partner.coupon.result?.Coupon_Id,
                            "Partner_Id": partner.Partner_Details_Id,
                            "Product_Line_Items": orderProducts,
                            "Partner_Payments": {
                                "Coupon_Discount_Amount": couponDiscountAmount({ "coupon": partner.coupon.result, "products": partner.Products }),
                                "Coupon_ID": partner.coupon.result?.Coupon_Id,
                                "Selling_Total_Before_Coupon_Discount": getPartnerOrderPriceById(partner.Partner_Details_Id),
                                "Final_Selling_Total": Number(getPartnerOrderPriceById(partner.Partner_Details_Id)) - Number(couponDiscountAmount({ "coupon": partner.coupon.result, "products": partner.Products }))
                            }
                        } : {
                            'Esi_Shipping_Fee': getDeliveryPrice(partner),
                            "Coupon_Code": partner.coupon.result?.Code,
                            "Coupon_Discount_Amount": couponDiscountAmount({ "coupon": partner.coupon.result, "products": partner.Products }),
                            "Coupon_ID": partner.coupon.result?.Coupon_Id,
                            "Partner_Id": partner.Partner_Details_Id,
                            "Shipping_Address": orderProducts[0].Shipping_Address,
                            "Product_Line_Items": orderProducts,
                            "Partner_Payments": {
                                "Coupon_Discount_Amount": couponDiscountAmount({ "coupon": partner.coupon.result, "products": partner.Products }),
                                "Coupon_ID": partner.coupon.result?.Coupon_Id,
                                "Selling_Total_Before_Coupon_Discount": getPartnerOrderPriceById(partner.Partner_Details_Id),
                                "Final_Selling_Total": Number(getPartnerOrderPriceById(partner.Partner_Details_Id)) - Number(couponDiscountAmount({ "coupon": partner.coupon.result, "products": partner.Products }))
                            }
                        }
                )
            }
        })

        let OrdersNew = {
            "Multi_Partner_Flag": Partner_Orders.length > 1 ? 1 : 0,
            "User_Detals": {
                "User_Email_ID": user.Email_Id
            },
            "Billing_Address": {
                "User_Address_Id": billingAddress?.id
            },
            "Corporate": {
                "Corporate_Discount_Amount": corpDiscountAmount,
                "Corporate_Discont_Coupon": corpVoucher.validate?.Corp_User_Coupon_Id ? corpVoucher.validate?.Corp_User_Coupon_Id : "",
            },
            "Gift_Voucher": {
                "Gift_ID": giftVoucher.result?.Gift_Voucher_Id ? giftVoucher.result?.Gift_Voucher_Id : "",
                "Gift_ID_Amount": getGiftDiscountPrice
            },
            "Wallet": {
                "Wallet_Amount": Number(getWalletUsedAmount()),
                "Wallet_Id": wallet?.Wallet_Id ? wallet?.Wallet_Id : ""
            },
            "Order_Payments": {
                "Currency": "INR",
                "Payment_Mode": type,
                "Response_Code": "200",
                "Transaction_Actual_Amount": amount,
                "Transaction_Id": transactionId,
                "Transcation_Status": "Success"
            },
            "Order_Totals": {
                "Order_Totals": {
                    "Gift_Amount": getAllGiftPackPrice,
                    "Order_Actual_Total": getAllPartnerOriginalPrice,
                    "Order_Amount_Collected_Using_Payment_Gateway": type,
                    "Order_Discount_Amount": getDiscountPrice,
                    "Order_Total_After_Discount": orderFinalAmount,
                    "Wallet_Amount": useWallet ? Number(getWalletUsedAmount()) : 0,
                    "Order_Selling_Price_Total": getAllPartnerOrderPrice
                },
                "Shipment_Total": {
                    "Shipping_Total": getAllDeliveryPrice
                }
            },
            "Partner_Orders": Partner_Orders,
            "Meepaisa_Coupon": {
                "Meepaisa_Coupon_ID": cashBackCoupon?.Code ? ((Number(getAllPartnerOrderPrice) >= Number(cashBackCoupon?.Min_Order_Value || "0")) ? cashBackCoupon?.Code : "") : ""
            }
        };
        setOrderPlaced(true);
        const response = await placeNewOrderAction({ Orders: OrdersNew });

        if (response.status) {
            let partners = [];
            partnerCartState.map((coupon) => {
                partners.push(coupon.Partner_Details_Id);

            })

            if (!isEmpty(partners)) {
                if (partners.length > 1) {
                    await removeAll(user.Email_Id);
                } else {
                    await removePartnerInCart(partners[0], user.Email_Id);
                }
            }
            toolTipActive("success", 'Order created successfully.');
            setIsNeedToNavigate(true);
        }
        else {
            toolTipActive("error", 'Unable to create order, please try again.');
        }

        setOrderPlaced(false);

    };

    // const placeOrder = () => {

    //     if (billingAddress?.id) {
    //         if (orderFinalAmount != 0) {
    //             razorpayPay(Razorpay, { user: userDetails.user, amount: orderFinalAmount }, (status, response) => {
    //                 if (status) {
    //                     placeOrderAfterPayment("RAZORPAY", response.razorpay_payment_id, orderFinalAmount);
    //                 } else {

    //                 }
    //             });
    //         } else {
    //             placeOrderAfterPayment("WALLET", "", orderFinalAmount);
    //         }
    //     } else {
    //         setAddressError("Please select Billing Address.")
    //     }
    // }

    // const placeOrderAfterPayment = async (type, transactionId, amount) => {
    //     let OrdersNew = {
    //         "Order_Payments": {
    //             "Currency": "INR",
    //             "Payment_Mode": type,
    //             "Response_Code": "200",
    //             "Transaction_Actual_Amount": amount,
    //             "Transaction_Id": transactionId,
    //             "Transcation_Status": "Success"
    //         }
    //     };

    //     const response = await updateOrderPaymentStatus({ Orders: OrdersNew });

    //     if (response.status) {
    //         toolTipActive("success", 'Order and payment processed successfully.');
    //         setIsNeedToNavigate(true);
    //     } else {
    //         toolTipActive("error", 'Unable to process payment, please try again.');
    //     }
    // };

    const createOrder = async (type) => {
        let Partner_Orders = [];
        partnerCartState.map((partner) => {
            if (!isEmpty(partner)) {

                let orderProducts = [];

                partner.Products.map((masterProduct) => {
                    let newOrderProduct = {
                        "Is_Gift": masterProduct.Gift_Message ? 1 : 0,
                        "Gift_Message": masterProduct.Gift_Message,
                        "Line_Item_Product_ID": masterProduct.Product_Id,
                        "Line_Item_Partner_Product_ID": masterProduct.Partner_Product_Id,
                        "Line_Item_Qty": masterProduct.Quantity,
                        "Selling_Price": masterProduct.Partner_Selling_Price,
                        "Size": masterProduct.Product_Size_Id,
                        "Shipping_Address": {
                            "User_Address_Id": masterProduct.User_Shipping_Address_Id
                        },
                    }
                    orderProducts.push(newOrderProduct);
                });

                Partner_Orders.push(
                    ["RETAIL", "FARMER", "MFH"].includes(partner.Business_Type) ?
                        {
                            "Coupon_Code": partner.coupon.result?.Code,
                            "Coupon_Discount_Amount": couponDiscountAmount({ "coupon": partner.coupon.result, "products": partner.Products }),
                            "Coupon_ID": partner.coupon.result?.Coupon_Id,
                            "Partner_Id": partner.Partner_Details_Id,
                            "Product_Line_Items": orderProducts,
                            "Partner_Payments": {
                                "Coupon_Discount_Amount": couponDiscountAmount({ "coupon": partner.coupon.result, "products": partner.Products }),
                                "Coupon_ID": partner.coupon.result?.Coupon_Id,
                                "Selling_Total_Before_Coupon_Discount": getPartnerOrderPriceById(partner.Partner_Details_Id),
                                "Final_Selling_Total": Number(getPartnerOrderPriceById(partner.Partner_Details_Id)) - Number(couponDiscountAmount({ "coupon": partner.coupon.result, "products": partner.Products }))
                            }
                        } : {
                            'Esi_Shipping_Fee': getDeliveryPrice(partner),
                            "Coupon_Code": partner.coupon.result?.Code,
                            "Coupon_Discount_Amount": couponDiscountAmount({ "coupon": partner.coupon.result, "products": partner.Products }),
                            "Coupon_ID": partner.coupon.result?.Coupon_Id,
                            "Partner_Id": partner.Partner_Details_Id,
                            "Shipping_Address": orderProducts[0].Shipping_Address,
                            "Product_Line_Items": orderProducts,
                            "Partner_Payments": {
                                "Coupon_Discount_Amount": couponDiscountAmount({ "coupon": partner.coupon.result, "products": partner.Products }),
                                "Coupon_ID": partner.coupon.result?.Coupon_Id,
                                "Selling_Total_Before_Coupon_Discount": getPartnerOrderPriceById(partner.Partner_Details_Id),
                                "Final_Selling_Total": Number(getPartnerOrderPriceById(partner.Partner_Details_Id)) - Number(couponDiscountAmount({ "coupon": partner.coupon.result, "products": partner.Products }))
                            }
                        }
                )
            }
        });

        let OrdersNew = {
            "Multi_Partner_Flag": Partner_Orders.length > 1 ? 1 : 0,
            "User_Detals": {
                "User_Email_ID": user.Email_Id
            },
            "Billing_Address": {
                "User_Address_Id": billingAddress?.id
            },
            "Corporate": {
                "Corporate_Discount_Amount": corpDiscountAmount,
                "Corporate_Discont_Coupon": corpVoucher.validate?.Corp_User_Coupon_Id ? corpVoucher.validate?.Corp_User_Coupon_Id : "",
            },
            "Gift_Voucher": {
                "Gift_ID": giftVoucher.result?.Gift_Voucher_Id ? giftVoucher.result?.Gift_Voucher_Id : "",
                "Gift_ID_Amount": getGiftDiscountPrice
            },
            "Wallet": {
                "Wallet_Amount": Number(getWalletUsedAmount()),
                "Wallet_Id": wallet?.Wallet_Id ? wallet?.Wallet_Id : ""
            },
            "Order_Payments": {
                "Currency": "INR",
                "Payment_Mode": type,
                "Response_Code": "200",
                "Transaction_Actual_Amount": orderFinalAmount,
                "Transaction_Id": "",
                "Transcation_Status": "Pending"
            },
            "Order_Totals": {
                "Order_Totals": {
                    "Gift_Amount": getAllGiftPackPrice,
                    "Order_Actual_Total": getAllPartnerOriginalPrice,
                    "Order_Amount_Collected_Using_Payment_Gateway": type,
                    "Order_Discount_Amount": getDiscountPrice,
                    "Order_Total_After_Discount": orderFinalAmount,
                    "Wallet_Amount": useWallet ? Number(getWalletUsedAmount()) : 0,
                    "Order_Selling_Price_Total": getAllPartnerOrderPrice
                },
                "Shipment_Total": {
                    "Shipping_Total": getAllDeliveryPrice
                }
            },
            "Partner_Orders": Partner_Orders,
            "Meepaisa_Coupon": {
                "Meepaisa_Coupon_ID": cashBackCoupon?.Code ? ((Number(getAllPartnerOrderPrice) >= Number(cashBackCoupon?.Min_Order_Value || "0")) ? cashBackCoupon?.Code : "") : ""
            }
        };

        //     setOrderPlaced(true);
        //     const response = await placeNewOrderAction({ Orders: OrdersNew });

        //     setOrderPlaced(false);

        //     return response;
        // };
        setOrderPlaced(true);
        const response = await placeNewOrderAction({ Orders: OrdersNew });
        if (response.status) {
            let partners = [];
            partnerCartState.map((coupon) => {
                partners.push(coupon.Partner_Details_Id);

            })

            if (!isEmpty(partners)) {
                if (partners.length > 1) {
                    await removeAll(user.Email_Id);
                } else {
                    await removePartnerInCart(partners[0], user.Email_Id);
                }
            }
            toolTipActive("success", 'Order created successfully.');
            setIsNeedToNavigate(true);
        }
        else {
            toolTipActive("error", 'Unable to create order, please try again.');
        }

        setOrderPlaced(false);

        if (response.status) {
            // Extract Order_Id from the response
            const orderId = response.data[0]?.Order_Id;
            return { status: true, orderId };
        } else {
            return { status: false, orderId: null };
        }
    };

    // const placeOrder = async () => {
    //     if (billingAddress?.id) {
    //         if (orderFinalAmount != 0) {
    //             // Create the order first
    //             const orderResponse = await createOrder();

    //             if (orderResponse.status) {
    //                 // Proceed with payment only if the order was successfully created
    //                 razorpayPay(Razorpay, { user: userDetails.user, amount: orderFinalAmount }, (status, response) => {
    //                     if (status) {
    //                         placeOrderAfterPayment("RAZORPAY", response.razorpay_payment_id, orderFinalAmount);
    //                     } else {
    //                         toolTipActive("error", 'Payment failed, please try again.');
    //                     }
    //                 });
    //             } else {
    //                 toolTipActive("error", 'Unable to create order, please try again.');
    //             }
    //         } else {
    //             // No payment required, just finalize the order
    //             const orderResponse = await createOrder();
    //             if (orderResponse.status) {
    //                 placeOrderAfterPayment("WALLET", "", orderFinalAmount);
    //             } else {
    //                 toolTipActive("error", 'Unable to create order, please try again.');
    //             }
    //         }
    //     } else {
    //         setAddressError("Please select Billing Address.");
    //     }
    // };

    const placeOrder = async () => {
        if (billingAddress?.id) {
            if (orderFinalAmount !== 0) {
                // Create the order first
                const orderResponse = await createOrder("RAZORPAY");

                if (orderResponse.status) {
                    // Proceed with payment only if the order was successfully created
                    razorpayPay(Razorpay, { user: userDetails.user, amount: orderFinalAmount }, (status, response) => {
                        if (status) {
                            // Update order status after payment is successful
                            updateOrderAfterPayment(orderResponse.orderId, response.razorpay_payment_id).then(result => {
                                if (result.status) {
                                    setIsNeedToNavigate(true);
                                } else {
                                    toolTipActive("error", result.data);
                                }
                            });
                        } else {
                            toolTipActive("error", 'Payment failed, please try again.');
                        }
                    });
                } else {
                    toolTipActive("error", 'Unable to create order, please try again.');
                }
            } else {
                // No payment required, just finalize the order
                const orderResponse = await createOrder("WALLET");
                if (orderResponse.status) {
                    // Update order status
                    updateOrderAfterPayment(orderResponse.orderId, "").then(result => {
                        if (result.status) {
                            setIsNeedToNavigate(true);
                        } else {
                            toolTipActive("error", result.data);
                        }
                    });
                } else {
                    toolTipActive("error", 'Unable to create order, please try again.');
                }
            }
        } else {
            setAddressError("Please select Billing Address.");
        }
    };







    return (
        <>
            <Grid container spacing={1} sx={{
                opacity: orderPlaced ? 0.5 : 1,
                pointerEvents: orderPlaced ? 'none' : 'auto',
            }}  >
                <Grid item xs={12} md={6} sx={{
                    opacity: orderPlaced ? 0.5 : 1,
                    pointerEvents: orderPlaced ? 'none' : 'auto',
                }}>
                    <CheckOut
                        partnerCartState={partnerCartState}
                        setPartnerCartState={setPartnerCartState}
                        couponCheck={couponCheck}
                        corpVoucher={corpVoucher}
                        setCorpVoucher={setCorpVoucher}
                        corpVoucherCheckLocal={corpVoucherCheckLocal}
                        setGiftVoucher={setGiftVoucher}
                        giftVoucher={giftVoucher}
                        giftVoucherCheckLocal={giftVoucherCheckLocal}
                        cashBackCoupon={cashBackCoupon}
                        setCashBackCoupon={setCashBackCoupon}
                        billingAddress={billingAddress} setBillingAddress={setBillingAddress}
                        orderPlaced={orderPlaced}
                        addressError={addressError}
                        totalMrp={getAllPartnerOriginalPrice}
                        allCouponDiscount={allCouponDiscount}
                        couponDiscountAmount={couponDiscountAmount}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{
                    opacity: orderPlaced ? 0.5 : 1,
                    pointerEvents: orderPlaced ? 'none' : 'auto',
                }}>
                    <PriceDetails
                        totalMrp={getAllPartnerOriginalPrice}
                        discountMrp={getDiscountPrice}
                        partnerCoupon={allCouponDiscount}
                        walletAmount={getWalletDiscountPrice || "0.00"}
                        shippingPrice={getAllDeliveryPrice}
                        totalAmount={orderFinalAmount}
                        corporateCode={corpDiscountAmount}
                        giftCode={getGiftDiscountPrice}
                        useWallet={useWallet}
                        setUseWallet={setUseWallet}
                        placeOrder={placeOrder}
                        cashBackCoupon={cashBackCoupon}
                        giftWrapAmount={getAllGiftPackPrice}
                    />
                </Grid>

                <Modal
                    open={visibleC}
                    onClose={() => {
                        setVisibleC(false);
                    }}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        p: 4,
                        borderRadius: "8px"
                    }} >
                        <IconButton
                            aria-label="close"
                            onClick={() => {
                                setVisibleC(false);
                            }}
                            style={{ position: 'absolute', top: 5, right: 5 }}
                        >
                            x
                            {/* <CloseIcon /> */}
                        </IconButton>
                        <Typography variant="h6" component="h4">Corprate Voucher OTP</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >

                            {!isEmpty(corpVoucher?.validate) &&
                                <Typography >OTP has been sent to email({corpVoucher.validate ? (corpVoucher.validate?.Corp_User_Email_Id.split("@"))[0].substring(0, 4) + "***@" + (corpVoucher.validate?.Corp_User_Email_Id.split("@"))[1] : ""})</Typography>
                            }
                            <Formik
                                initialValues={{ otp: "" }}

                                validationSchema={Yup.object().shape({
                                    otp: Yup.string()
                                        .required('Voucher OTP is required.')
                                })}

                                onSubmit={async (values, { setSubmitting, setErrors }) => {
                                    setSubmitting(false);
                                    const res = await corpVoucherCheckVerification(corpVoucher.validate.Corp_User_Coupon_Id, corpVoucher.validate?.Corp_User_Email_Id, values.otp);

                                    if (res.status) {

                                        setCorpVoucher({ ...corpVoucher, result: JSON.parse(JSON.stringify(res.data)), loading: false, errorMessage: '' });
                                        setVisibleC(false);
                                    } else {
                                        setErrors({ otp: res.data })
                                        setCorpVoucher({ ...corpVoucher, loading: false });

                                    }

                                }}
                            >
                                {({ handleChange, handleBlur, handleSubmit, values, errors, touched, resetForm }) => (
                                    <>
                                        <TextField
                                            placeholder="Voucher OTP"
                                            label="Voucher OTP"
                                            variant="outlined"
                                            name={"otp"}
                                            value={values.otp}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            color={Boolean(touched.otp && errors.otp) ? "error" : "primary"}
                                            helperText={(touched.otp && errors.otp) || corpVoucher.errorMessage || ""}
                                        />
                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", marginTop: "10px" }}>
                                            <Button
                                                disabled={corpVoucher.loading} onClick={handleSubmit}
                                                variant="contained"
                                                color="primary"
                                            // onClick={handleSubmit}
                                            >
                                                Submit
                                            </Button>

                                            <Button variant="contained" disabled={corpVoucher.loading || secondsC >= 0} onClick={() => {
                                                if (!(secondsC >= 0)) {
                                                    corpVoucherCheckLocal();
                                                    resetForm();
                                                }

                                            }}>
                                                Resend OTP {secondsC > 0 ? secondsC : ""}
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Formik>
                        </Box>

                    </Box>
                </Modal>

                <Modal
                    open={visible}
                    onClose={() => {
                        setVisible(false);
                    }}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        p: 4,
                        borderRadius: "8px"
                    }} >
                        <IconButton
                            aria-label="close"
                            onClick={() => {
                                setVisible(false);
                            }}
                            style={{ position: 'absolute', top: 5, right: 5 }}
                        >
                            x
                        </IconButton>
                        <Typography variant="h6" component="h4" >Gift Card OTP</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >

                            {!isEmpty(giftVoucher?.validate) &&
                                <Typography >OTP has been sent to email({giftVoucher.validate?.Email_Id ? (giftVoucher.validate?.Email_Id.split("@"))[0].substring(0, 4) + "***@" + (giftVoucher.validate?.Email_Id.split("@"))[1] : ""})and mobile number( ****
                                    {giftVoucher.validate ? giftVoucher.validate?.Mobile.substring(giftVoucher.validate?.Mobile.length - 4) : ''}
                                    )</Typography>
                            }
                            <Formik
                                initialValues={{ otp: "" }}

                                validationSchema={Yup.object().shape({
                                    otp: Yup.string().min(6, "OTP Length Should Contain 6 Letters").max(6, "OTP Length Should Contain 6 Letters")
                                        .required('Voucher OTP is required.')
                                })}

                                onSubmit={async (values, { setSubmitting, setErrors }) => {
                                    setSubmitting(false);
                                    setGiftVoucher({ ...giftVoucher, loading: true, errorMessage: '' });

                                    const res = await giftVoucherCheckVerification(giftVoucher.validate.Gift_Voucher_Id, giftVoucher.validate?.Email_Id, values.otp);

                                    if (res.status) {

                                        setGiftVoucher({ ...giftVoucher, result: JSON.parse(JSON.stringify(res.data)), loading: false, errorMessage: '' });
                                        setVisible(false);
                                    } else {
                                        setErrors({ otp: res.data })
                                        setGiftVoucher({ ...giftVoucher, loading: false });

                                    }

                                }}
                            >
                                {({ handleChange, handleBlur, handleSubmit, values, errors, touched, resetForm, setFieldValue }) => (
                                    <>
                                        <TextField
                                            placeholder="Voucher OTP"
                                            label="Voucher OTP"
                                            variant="outlined"
                                            name={"otp"}
                                            value={values.otp}
                                            onChange={(e) => {
                                                let value = e.currentTarget.value;
                                                if (value.includes(" ")) {
                                                    value = value.slice(0, value?.length - 1)
                                                }
                                                value = value.replace(/\D/g, "");
                                                setFieldValue('otp', value);
                                            }}
                                            onBlur={handleBlur}
                                            inputProps={{ maxLength: 6 }}
                                            fullWidth
                                            color={Boolean(touched.otp && errors.otp) ? "error" : "primary"}
                                            helperText={(touched.otp && errors.otp) || giftVoucher.errorMessage || ""}
                                        />
                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", marginTop: "10px" }}>
                                            <Button
                                                disabled={giftVoucher.loading} onClick={handleSubmit}
                                                variant="contained"
                                                color="primary"
                                            // onClick={handleSubmit}
                                            >
                                                Submit
                                            </Button>

                                            <Button variant="contained" disabled={giftVoucher.loading || seconds >= 0} onClick={() => {
                                                if (!(seconds >= 0)) {
                                                    giftVoucherCheckLocal();
                                                    resetForm();
                                                }

                                            }}>
                                                Resend OTP {seconds > 0 ? seconds : ""}
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Formik>
                        </Box>

                    </Box>
                </Modal>
            </Grid>
        </>
    );
}
export default CheckOutDetails;
