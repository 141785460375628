import React, { useState, useEffect, useContext } from "react";
import { Typography, Box, Checkbox, Grid, Card } from "@mui/material";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import couponNormalImage from "../../../assests/images/couponSideBarInActive.png";
import dealNormalImage from "../../../assests/images/delasSideBarInActive.png";
import dealActiveImage from "../../../assests/images/delasSideBarActive.png";
import couponActiveImage from "../../../assests/images/couponSideBarActive.png";
import GdealActiveImage from "../../../assests/images/dealsSideBarActive.png";
import GcouponActiveImage from "../../../assests/images/CouponGroceryActive.png";
import { GlobalContext } from "../../../Context";
import { useTheme } from "@emotion/react";
import useScreenSize from "../../../utils/ScreenWidthHight";
const SideBar = ({ type = "retail", }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const winSize = useScreenSize();
  const [selectedCheckBox, setSelectedCheckBox] = useState([]);
  const [couponImage, setCouponImage] = useState(couponNormalImage);
  const [dealImage, setDealImage] = useState(dealNormalImage);
  const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);

  useEffect(() => {
    if (location.pathname === "/" + type + "/offers/coupons") {
      if (type === "grocery") {
        setCouponImage(GcouponActiveImage);
      }
      else
        setCouponImage(couponActiveImage);
      setDealImage(dealNormalImage);
    } else if (location.pathname === "/" + type + "/offers/deals") {
      setDealImage(type === "grocery" ? GdealActiveImage : dealActiveImage);
      setCouponImage(couponNormalImage);
    }

    const params = new URLSearchParams(location.search);
    const status = params.get("status");
    setSelectedCheckBox(status ? status.split(",") : []);
  }, [location], type);

  const handleLinkClick = (path) => {
    navigate(path);
  };

  const handleCheckBoxClick = (value) => {
    const selectedIndex = selectedCheckBox.indexOf(value);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedCheckBox, value];
    } else {
      newSelected = selectedCheckBox.filter((item) => item !== value);
    }
    setSelectedCheckBox(newSelected);
    updateQueryParam([{ 'status': newSelected.join(",") }, { "PageNo": 1 }]);
  };

  return (
    <Box sx={{width:"100%",display:"flex",justifyContent:"center"}} >
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        marginTop="30px"
        gap={2}
        sx={{ height: "100%",width:{xs:"90%",sm:"100%"}, marginLeft: { xs: "unset", sm: "20px" } }}
      >
        {/* <Grid item>
        <Box sx={{ marginTop: "5px" }}>
          <img
            src={require("../../../../assests/images/left-arrow.png")}
            alt="Left Arrow"
            onClick={() => navigate(-1)}
          />
        </Box>
      </Grid> */}
        <Grid >
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.secondary }}>
            Shop By
          </Typography>
        </Grid>
        <Grid item container sx={{ display: "flex", justifyContent: { xs: "space-between", sm: "left" }, gap: { xs: "unset", sm: 2 } }} >
          <Grid item>
            <img
              src={couponImage}
              alt="Coupon"
              style={{
                width: (winSize.screenSize.width < 600 ? "150px" : "100px"),
                height: (winSize.screenSize.width < 600 ? "150px" : "100px"),
                padding: (winSize.screenSize.width < 600 ? "0px" : "10px"),
                borderRadius: "27px",
                cursor: "pointer",
              }}
              onClick={() => handleLinkClick("/" + type + "/offers/coupons")}
            />
          </Grid>
          <Grid item>
            <img
              src={dealImage}
              alt="Deal"
              style={{
                width: (winSize.screenSize.width < 600 ? "150px" : "100px"),
                height: (winSize.screenSize.width < 600 ? "150px" : "100px"),
                padding: (winSize.screenSize.width < 600 ? "0px" : "10px"),
                borderRadius: "27px",
                cursor: "pointer",
              }}
              onClick={() => handleLinkClick("/" + type + "/offers/deals")}
            />
          </Grid>
        </Grid>

        <Grid container direction="column" alignItems="flex-start" gap={1}>
          {location.pathname === "/" + type + "/offers/coupons" ? (
            <>
              {winSize.screenSize.width >= 600 ? (
                <>
                  <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("topBrand")}
                        onChange={() => handleCheckBoxClick("topBrand")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Top Brand</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("exclusive")}
                        onChange={() => handleCheckBoxClick("exclusive")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Exclusive</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("topCoupon")}
                        onChange={() => handleCheckBoxClick("topCoupon")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Top Coupon</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("upcoming")}
                        onChange={() => handleCheckBoxClick("upcoming")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Upcoming</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("hotDeals")}
                        onChange={() => handleCheckBoxClick("hotDeals")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Hot Coupons</Typography>
                    </Box>
                  </Grid>
                </>
              ) : (
                <Grid container sx={{ display: "flex", justifyContent: { xs: "space-between", sm: "unset" }, gap: { xs: "unset", sm: 1 } }} >
                  <Grid item xs={5}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("topBrand")}
                        onChange={() => handleCheckBoxClick("topBrand")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Top Brand</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("exclusive")}
                        onChange={() => handleCheckBoxClick("exclusive")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Exclusive</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("topCoupon")}
                        onChange={() => handleCheckBoxClick("topCoupon")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Top Coupon</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("upcoming")}
                        onChange={() => handleCheckBoxClick("upcoming")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Upcoming</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("hotDeals")}
                        onChange={() => handleCheckBoxClick("hotDeals")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Hot Coupons</Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <>
              {winSize.screenSize.width >= 600 ? (
                <>
                  <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("topBrand")}
                        onChange={() => handleCheckBoxClick("topBrand")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Top Brand</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("exclusive")}
                        onChange={() => handleCheckBoxClick("exclusive")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Exclusive</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("topDeals")}
                        onChange={() => handleCheckBoxClick("topDeals")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Top Deals</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("hotDeals")}
                        onChange={() => handleCheckBoxClick("hotDeals")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Hot Deals</Typography>
                    </Box>
                  </Grid>
                </>
              ) : (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("topBrand")}
                        onChange={() => handleCheckBoxClick("topBrand")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Top Brand</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("exclusive")}
                        onChange={() => handleCheckBoxClick("exclusive")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Exclusive</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("topDeals")}
                        onChange={() => handleCheckBoxClick("topDeals")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Top Deals</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedCheckBox.includes("hotDeals")}
                        onChange={() => handleCheckBoxClick("hotDeals")}
                      />
                      <Typography sx={{ fontWeight: 'bold' }}>Hot Deals</Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </>

          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SideBar;
