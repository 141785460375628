

import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi } from '../../CommonAxios';

const initialState = {
    Product: [],
    loading: false,
    error: ""
}

export const fetchProduct = createAsyncThunk("Productslice/fetchProduct", async () => {
    try {
        const response = await nodeApi.post("/Home/Brand/Get", {});
        const data = response?.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            return [];
        } else {
            return data.results;
        }
    } catch (error) {
        throw error;
    }
});

const Productslice = createSlice({
    name: 'product',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchProduct.pending, (state, action) => {
                state.loading = true;
                state.error = ""; 
            })
            .addCase(fetchProduct.fulfilled, (state, action) => {
                state.loading = false;
                state.Product = action.payload;
            })
            .addCase(fetchProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; 
            });
    }
});

export default Productslice.reducer;
