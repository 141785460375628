import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography, Box, Button, TextField, FormHelperText } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Pagination as PN } from "@mui/material";
import { fetchWalletHistory, fetchWallet, walletWithdrawlAction } from "../../store/slices/accounts/WalletSlice";
import { useTheme } from "@emotion/react";
import * as Yup from "yup";
import { getUserSettings } from "../../store/slices/AuthSlice";
import { GlobalContext } from "../../Context";
import { useLocation, useNavigate } from "react-router-dom";
import AutoAdjustText from "../../utils/AutoAdjustText";
import noWalletTransactions from '../../assests/images/noWalletTransactions.png'
import useScreenSize from "../../utils/ScreenWidthHight";

const Wallet = () => {
    const dispatch = useDispatch();
    const winSize = useScreenSize();
    const { walletHistory, wallet, Pagination, loading } = useSelector(state => state.wallet);
    const auth = useSelector(state => state.auth);
    const userDetails = useSelector(state => state.auth);
    const isAuthenticated = userDetails.isAuthenticated;
    const [currentPage, setCurrentPage] = useState(1);
    const historyPerPage = 10;
    const theme = useTheme();
    const { toolTipActive } = useContext(GlobalContext)
    const [withdrowLimit, setWithdrowLimit] = React.useState(250);
    const [disbleWithdrow, setdisbleWithdrow] = React.useState(true);
    const [errorMessage, setErrorMessage] = useState("Currently Withdraw Has been Disabled")
    const [errorMessageAct, setErrorMessageAct] = useState("")
    const [upiError, setUpiError] = useState('')
    const upiErrorCheck = ['@.', '..', '@', '.@']
    const { pathname } = useLocation();
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);
    const navigate = useNavigate()

    useEffect(() => {
        if (searchParams.get("PageNo")) {
            dispatch(fetchWalletHistory({
                formData: { User_Details_Id: userDetails?.user?.User_Details_Id },
                Pagination: { PageNo: searchParams.get("PageNo"), PageSize: historyPerPage, SortBy: "Transaction_Date", SortOrder: "DESC" }
            }));
            dispatch(fetchWallet({ User_Details_Id: userDetails?.user?.User_Details_Id }));
        }
        else {
            {
                let querys = "";
                searchParams.forEach((value, key) => {
                    querys += "&" + key + "=" + value
                });
                navigate('/accounts/wallet?PageNo=1' + querys, { replace: true });
            }
        }
    }, [searchParams, pathname, isAuthenticated, userDetails]);


    useEffect(() => {
        (async () => {
            const res = await getUserSettings("User_Wallet_IS_ELIGIBLE_FOR_WITHDRAW");
            if (res.status) {
                setWithdrowLimit(res.data);

                const res1 = await getUserSettings("USER_WALLET_WITHDROW_STATUS", false);
                if (res1.status)
                    setdisbleWithdrow(!("true" == res1.data))
            }
        })()
    }, [])

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
        updateQueryParam([{ "PageNo": value }])
    };

    const renderTransactions = (transaction, index) => (
        <React.Fragment key={index}>
            <Grid item xs={3}>
                <Typography variant="body2" sx={{ whiteSpace: "nowrap", display: { xs: "block", md: "none", sm: "none" }, color: theme.palette.primary.main }} >
                    {(transaction.Credit_Reason ?? transaction.Debit_Reason)?.length > 8 ? (transaction.Credit_Reason ?? transaction.Debit_Reason).substring(0, 8) + "..." : transaction.Credit_Reason ?? transaction.Debit_Reason}
                </Typography>
                <Typography variant="body2" sx={{ whiteSpace: "nowrap", display: { xs: "none", md: "block", sm: "block" }, color: theme.palette.primary.main }} >
                    {(transaction.Credit_Reason ?? transaction.Debit_Reason)?.length > 15 ? (transaction.Credit_Reason ?? transaction.Debit_Reason).substring(0, 14) + "..." : transaction.Credit_Reason ?? transaction.Debit_Reason}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={{ color: theme.palette.primary.main , whiteSpace:'nowrap', textWrap:'nowrap'}} >    &#8377; {transaction.Credited_Amount ? transaction.Credited_Amount : transaction.Debited_Amount}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={{ color: theme.palette.primary.main }} >{transaction.Transaction_Type}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography color={ 
                    transaction.Status && transaction.Status.toLowerCase() === 'settled' ? theme.palette.success.light :
                        transaction.Status && transaction.Status.toLowerCase() === 'pending' ? theme.palette.warning.light : theme.palette.text.secondary
                }>
                    {transaction.Status && transaction.Status.charAt(0).toUpperCase() + transaction.Status.slice(1).toLowerCase()}
                </Typography>
            </Grid>
        </React.Fragment>
    );
    return (
        <Box sx={{ minHeight: "100vh", width: "100%" }} >
            {winSize.screenSize.width > 900 ? (<Grid container spacing={2} sx={{ mt: "10px", minHeight: "80vh" }}>
                <Grid item xs={12} md={5.5} sx={{ display: "flex", flexDirection: "column", marginLeft: '20px', }}>
                    <Typography gutterBottom sx={{ fontWeight: "bold", fontSize: '24px', color: theme.palette.text.secondary }}>
                        Transactions
                    </Typography>
                    <Grid container spacing={2} sx={{ flex: 1, overflowY: "auto" }}>
                        <Grid item xs={3}>
                            <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>Reason</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>Amount</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>Type</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>Status</Typography>
                        </Grid>
                        {walletHistory.length === 0 && !loading && (
                            <img src={noWalletTransactions} alt="No_Coupons_Found" style={{ display: "block", width: '75%', height: '75%', margin: 'auto', objectFit: 'contain' }} ></img>
                        )}
                        {walletHistory.map(renderTransactions)}
                    </Grid>
                    {(Pagination.TotalPages > 1) && (
                        <Box sx={{ display: 'flex', justifyContent: "center", width: "100%", mt: 4 }} >
                            <PN page={Pagination.PageNo} onChange={handleChangePage} count={Pagination.TotalPages} color="primary" />
                        </Box>
                    )}
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", fontSize: '24px', textAlign: "end", color: theme.palette.text.secondary }}>
                        My Wallet
                    </Typography>
                    <Box sx={{ backgroundColor: theme.palette.primary.main, padding: '30px', textAlign: 'center', borderRadius: '24px', marginTop: '11px' }}>
                        <Typography variant="h6" gutterBottom sx={{ fontSize: '28px', color: theme.palette.primary.contrastText }}>
                            Wallet Balance
                        </Typography>
                        <Typography variant="h6" gutterBottom sx={{ fontSize: '28px', color: theme.palette.primary.contrastText }}>
                            &#8377; {wallet.Remaining_Balance || 0}
                        </Typography>
                    </Box>
                    <Formik
                        initialValues={{ upiId: "", reason: "", withdrawAmount: 0 }}
                        validationSchema={Yup.object().shape({
                            upiId: Yup.string()
                                .required("UPI ID is required")
                                .matches(/^[a-zA-Z0-9@_.]+$/, "Only @, alphabets, and numbers are allowed")
                                .test('no-spaces', 'Spaces are not allowed in UPI ID', value => {
                                    return !/\s/.test(value);
                                })
                                .test('no-multiple-at', 'Only one "@" allowed in UPI ID', value => {
                                    return value && value.indexOf('@') === value.lastIndexOf('@');
                                })
                                .test('ascii-characters-only', 'UPI ID must contain ASCII characters only', value => {
                                    return /^[\x00-\x7F]*$/.test(value);
                                })
                                .test('no-consecutive-periods', 'Consecutive periods are not allowed in UPI ID', value => {
                                    return !/\.\./.test(value);
                                })
                                .test('no-leading-trailing-periods', 'Leading or trailing periods are not allowed in UPI ID', value => {
                                    return !/^\./.test(value) && !/\.$/.test(value) && !/\.[^.]*\./.test(value);
                                })
                                .max(64, 'UPI ID must be less than 64 characters'),
                            reason: Yup.string()
                                .required("Reason is required")
                                .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9 !@#$%^&*()-_=+{};:',.<>?[\]`~|\\]+$/, 'Invalid reason format')
                                .trim()
                                .min(10, "Min. 10 characters required"),
                            withdrawAmount: Yup.number().min(withdrowLimit, "Withdrawal Amount should be greater than or equal to Rs. " + withdrowLimit + " / - ").required("Withdraw Amount is required")
                        })}
                        onSubmit={async (values, { resetForm }) => {
                            const formData = {
                                Reason: values.reason,
                                Upi_Id: values.upiId,
                                User_Id: auth.user.Email_Id,
                                Wallet_Id: wallet.Wallet_Id,
                                Withdraw_Amount: values.withdrawAmount
                            };

                            if (upiError.length === 0) {
                                if (wallet.Remaining_Balance >= 400) {
                                    const response = await walletWithdrawlAction(formData);
                                    if (response.status) {
                                        toolTipActive("success", response.data.UI_Display_Message);
                                        dispatch(fetchWallet({ User_Details_Id: userDetails?.user?.User_Details_Id }));
                                        dispatch(fetchWalletHistory({
                                            formData: { User_Details_Id: userDetails?.user?.User_Details_Id },
                                            Pagination: { PageNo: currentPage, PageSize: historyPerPage, SortBy: "Transaction_Date", SortOrder: "DESC" }
                                        }));
                                        setTimeout(() => {
                                            dispatch(fetchWallet({ User_Details_Id: userDetails?.user?.User_Details_Id }));
                                            dispatch(fetchWalletHistory({
                                                formData: { User_Details_Id: userDetails?.user?.User_Details_Id },
                                                Pagination: { PageNo: currentPage, PageSize: historyPerPage, SortBy: "Transaction_Date", SortOrder: "DESC" }
                                            }));
                                        }, 5000);
                                        resetForm();
                                    } else {
                                        setErrorMessageAct(response.data.Response_Message);
                                    }
                                } else {
                                    setErrorMessageAct("Your Wallet Amount Should Be Rs. " + withdrowLimit + " / - to Withdraw");
                                }
                            }
                        }}
                    >
                        {({ errors, touched, onSubmit, setFieldValue }) => (
                            <Form>
                                <Field
                                    as={TextField}
                                    name="upiId"
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    label="UPI ID"
                                    onChange={(e) => {
                                        const value = e.currentTarget.value;
                                        if (value.includes('@') && value.length === 1) {
                                            setFieldValue('upiId', "");
                                        }
                                        else if (/^[a-zA-Z0-9@_.]+$/.test(value)) {
                                            setFieldValue('upiId', value);
                                            if (!value.includes('@')) {
                                                setFieldValue('upiId', value);
                                                setUpiError("UPI ID must contain '@' symbol ")

                                            }
                                            else if (upiErrorCheck.includes(value.slice(value.length - 3, value.length - 1))) {
                                                setFieldValue('upiId', value.slice(0, value.length - 3))
                                            }
                                            else if (upiErrorCheck.includes(value[value.length - 1])) {
                                                setFieldValue('upiId', value)
                                                setUpiError("after '@' must contain some extension eg: 'ybl','apl' ...etc ")
                                            }
                                            else {
                                                setUpiError('')
                                            }
                                        }

                                        else if (e.nativeEvent.inputType === 'deleteContentBackward') {
                                            setFieldValue('upiId', '');

                                        }

                                    }}
                                    error={touched.upiId && Boolean(errors.upiId)}
                                    helperText={touched.upiId && errors.upiId}
                                />
                                {!(touched.upiId && errors.upiId) && <FormHelperText sx={{ color: "red", paddingLeft: "10px", marginTop: "-10px" }} >{upiError}</FormHelperText>}
                                <Field
                                    as={TextField}
                                    name="reason"
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    label="Reason"
                                    onChange={(e) => {
                                        const value = e.currentTarget.value
                                        if (value[0] === " ") {
                                            setFieldValue('reason', value.slice(0, value.length - 2));
                                        }
                                        else if (value.includes("  ")) {
                                            setFieldValue('reason', value.slice(0, value.length - 1));
                                        }
                                        else {
                                            setFieldValue('reason', value)
                                        }
                                    }}
                                    error={touched.reason && Boolean(errors.reason)}
                                    helperText={touched.reason && errors.reason}
                                />
                                <Field
                                    as={TextField}
                                    name="withdrawAmount"
                                    fullWidth
                                    type="number"
                                    variant="outlined"
                                    margin="normal"
                                    label="Withdraw Amount"
                                    error={touched.withdrawAmount && Boolean(errors.withdrawAmount)}
                                    helperText={touched.withdrawAmount && errors.withdrawAmount}
                                />
                                {disbleWithdrow && <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Typography sx={{ color: theme.palette.extra.errorMessage }}>{errorMessage}</Typography>
                                </Box>}
                                {errorMessageAct?.length > 0 && <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Typography sx={{ color: theme.palette.extra.errorMessage }}>{errorMessageAct}</Typography>
                                </Box>}
                                <Box style={{ display: "flex", justifyContent: "center", margin: '10px' }}>
                                    <Button type="submit" onClick={onSubmit} variant="contained" color="primary" sx={{ width: "43%", padding: '10px' }} disabled={disbleWithdrow}>
                                        Withdraw
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>) :
                <Grid container spacing={2} sx={{ minHeight: "80vh", padding: '15px' }}>
                    <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", fontSize: '24px', textAlign: "left", color: theme.palette.text.secondary }}>
                            My Wallet
                        </Typography>
                        <Box sx={{ backgroundColor: theme.palette.primary.main, padding: '30px', textAlign: 'center', borderRadius: '24px', marginTop: '11px' }}>
                            <Typography variant="h6" gutterBottom sx={{ fontSize: '28px', color: theme.palette.primary.contrastText }}>
                                Wallet Balance
                            </Typography>
                            <Typography variant="h6" gutterBottom sx={{ fontSize: '28px', color: theme.palette.primary.contrastText }}>
                                &#8377; {wallet.Remaining_Balance || 0}
                            </Typography>
                        </Box>
                        <Formik
                            initialValues={{ upiId: "", reason: "", withdrawAmount: 0 }}
                            validationSchema={Yup.object().shape({
                                upiId: Yup.string()
                                    .required("UPI ID is required")
                                    .matches(/^[a-zA-Z0-9@_.]+$/, "Only @, alphabets, and numbers are allowed")
                                    .test('no-spaces', 'Spaces are not allowed in UPI ID', value => {
                                        return !/\s/.test(value);
                                    })
                                    .test('no-multiple-at', 'Only one "@" allowed in UPI ID', value => {
                                        return value && value.indexOf('@') === value.lastIndexOf('@');
                                    })
                                    .test('ascii-characters-only', 'UPI ID must contain ASCII characters only', value => {
                                        return /^[\x00-\x7F]*$/.test(value);
                                    })
                                    .test('no-consecutive-periods', 'Consecutive periods are not allowed in UPI ID', value => {
                                        return !/\.\./.test(value);
                                    })
                                    .test('no-leading-trailing-periods', 'Leading or trailing periods are not allowed in UPI ID', value => {
                                        return !/^\./.test(value) && !/\.$/.test(value) && !/\.[^.]*\./.test(value);
                                    })
                                    .max(64, 'UPI ID must be less than 64 characters'),
                                reason: Yup.string()
                                    .required("Reason is required")
                                    .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9 !@#$%^&*()-_=+{};:',.<>?[\]`~|\\]+$/, 'Invalid reason format')
                                    .trim()
                                    .min(10, "Min. 10 characters required"),
                                withdrawAmount: Yup.number().min(withdrowLimit, "Withdrawal Amount should be greater than or equal to Rs. " + withdrowLimit + " / - ").required("Withdraw Amount is required")
                            })}
                            onSubmit={async (values, { resetForm }) => {
                                const formData = {
                                    Reason: values.reason,
                                    Upi_Id: values.upiId,
                                    User_Id: auth.user.Email_Id,
                                    Wallet_Id: wallet.Wallet_Id,
                                    Withdraw_Amount: values.withdrawAmount
                                };

                                if (upiError.length === 0) {
                                    if (wallet.Remaining_Balance >= 400) {
                                        const response = await walletWithdrawlAction(formData);
                                        if (response.status) {
                                            toolTipActive("success", response.data.UI_Display_Message);
                                            dispatch(fetchWallet({ User_Details_Id: userDetails?.user?.User_Details_Id }));
                                            dispatch(fetchWalletHistory({
                                                formData: { User_Details_Id: userDetails?.user?.User_Details_Id },
                                                Pagination: { PageNo: currentPage, PageSize: historyPerPage, SortBy: "Transaction_Date", SortOrder: "DESC" }
                                            }));
                                            setTimeout(() => {
                                                dispatch(fetchWallet({ User_Details_Id: userDetails?.user?.User_Details_Id }));
                                                dispatch(fetchWalletHistory({
                                                    formData: { User_Details_Id: userDetails?.user?.User_Details_Id },
                                                    Pagination: { PageNo: currentPage, PageSize: historyPerPage, SortBy: "Transaction_Date", SortOrder: "DESC" }
                                                }));
                                            }, 5000);
                                            resetForm();
                                        } else {
                                            setErrorMessageAct(response.data.Response_Message);
                                        }
                                    } else {
                                        setErrorMessageAct("Your Wallet Amount Should Be Rs. " + withdrowLimit + " / - to Withdraw");
                                    }
                                }
                            }}
                        >
                            {({ errors, touched, onSubmit, setFieldValue }) => (
                                <Form>
                                    <Field
                                        as={TextField}
                                        name="upiId"
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        label="UPI ID"
                                        onChange={(e) => {
                                            const value = e.currentTarget.value;
                                            if (value.includes('@') && value.length === 1) {
                                                setFieldValue('upiId', "");
                                            }
                                            else if (/^[a-zA-Z0-9@_.]+$/.test(value)) {
                                                setFieldValue('upiId', value);
                                                if (!value.includes('@')) {
                                                    setFieldValue('upiId', value);
                                                    setUpiError("UPI ID must contain '@' symbol ")

                                                }
                                                else if (upiErrorCheck.includes(value.slice(value.length - 3, value.length - 1))) {
                                                    setFieldValue('upiId', value.slice(0, value.length - 3))
                                                }
                                                else if (upiErrorCheck.includes(value[value.length - 1])) {
                                                    setFieldValue('upiId', value)
                                                    setUpiError("after '@' must contain some extension eg: 'ybl','apl' ...etc ")
                                                }
                                                else {
                                                    setUpiError('')
                                                }
                                            }

                                            else if (e.nativeEvent.inputType === 'deleteContentBackward') {
                                                setFieldValue('upiId', '');

                                            }

                                        }}
                                        error={touched.upiId && Boolean(errors.upiId)}
                                        helperText={touched.upiId && errors.upiId}
                                    />
                                    {!(touched.upiId && errors.upiId) && <FormHelperText sx={{ color: "red", paddingLeft: "10px", marginTop: "-10px" }} >{upiError}</FormHelperText>}
                                    <Field
                                        as={TextField}
                                        name="reason"
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        label="Reason"
                                        onChange={(e) => {
                                            const value = e.currentTarget.value
                                            if (value[0] === " ") {
                                                setFieldValue('reason', value.slice(0, value.length - 2));
                                            }
                                            else if (value.includes("  ")) {
                                                setFieldValue('reason', value.slice(0, value.length - 1));
                                            }
                                            else {
                                                setFieldValue('reason', value)
                                            }
                                        }}
                                        error={touched.reason && Boolean(errors.reason)}
                                        helperText={touched.reason && errors.reason}
                                    />
                                    <Field
                                        as={TextField}
                                        name="withdrawAmount"
                                        fullWidth
                                        type="number"
                                        variant="outlined"
                                        margin="normal"
                                        label="Withdraw Amount"
                                        error={touched.withdrawAmount && Boolean(errors.withdrawAmount)}
                                        helperText={touched.withdrawAmount && errors.withdrawAmount}
                                    />
                                    {disbleWithdrow && <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography sx={{ color: theme.palette.extra.errorMessage }}>{errorMessage}</Typography>
                                    </Box>}
                                    {errorMessageAct?.length > 0 && <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography sx={{ color: theme.palette.extra.errorMessage }}>{errorMessageAct}</Typography>
                                    </Box>}
                                    <Box style={{ display: "flex", justifyContent: "center", margin: '10px' }}>
                                        <Button type="submit" onClick={onSubmit} variant="contained" color="primary" sx={{ width: "43%", padding: '10px' }} disabled={disbleWithdrow}>
                                            Withdraw
                                        </Button>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                    <Grid item xs={12} md={5.5} sx={{ display: "flex", flexDirection: "column", }}>
                        <Typography gutterBottom sx={{ fontWeight: "bold", fontSize: '24px', color: theme.palette.text.secondary }}>
                            Transactions
                        </Typography>
                        <Grid container spacing={2} sx={{ flex: 1, overflowY: "auto" }}>
                            <Grid item xs={3}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>Reason</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>Amount</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>Type</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>Status</Typography>
                            </Grid>
                            {walletHistory.length === 0 && !loading && (
                                <img src={noWalletTransactions} alt="No_Coupons_Found" style={{ display: "block", width: '75%', height: '75%', margin: 'auto', objectFit: 'contain' }} ></img>
                            )}
                            {walletHistory.map(renderTransactions)}
                        </Grid>
                        {(Pagination.TotalPages > 1) && (
                            <Box sx={{ display: 'flex', justifyContent: "center", width: "100%", mt: 4 }} >
                                <PN page={Pagination.PageNo} onChange={handleChangePage} count={Pagination.TotalPages} color="primary" />
                            </Box>
                        )}
                    </Grid>


                </Grid>}
        </Box>
    );
};

export default Wallet;
