import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';
import React from 'react';

const AutoAdjustText = ({ style, children, isHeading, color, numLines, scrollofText = false }) => {
    const theme=useTheme()
    const baseStyle = {
        ...style,
        margin: '1px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        whiteSpace: 'normal', // Allow wrapping within the width
        // "scrollbar-width": "none"
    };

    if (numLines) {
        baseStyle.WebkitLineClamp = numLines;
        baseStyle.lineClamp = numLines; // Not widely supported, but added for completeness
    }

    if (style?.height) {
        baseStyle.overflowY = scrollofText ? 'auto':"hidden";
    }

    return isHeading ? (
        <Typography sx={baseStyle}>
            {children}
        </Typography>
    ) : (
        <Typography sx={baseStyle}>
            {children}
            </Typography>
    );
};

export default AutoAdjustText;
