import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, MenuItem, Select, TextField, Typography, FormHelperText } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Stack from '@mui/material/Stack';
import useScreenSize from '../../../../utils/ScreenWidthHight';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import CreateTicketSlice, { tickets } from '../../../../store/slices/retail/home/CreateTicketSlice';
import { useNavigate } from 'react-router-dom';
import { uriToBlob } from "../../../../utils/uriBlob";
import { uploadProfilePicAction } from '../../../../store/slices/Profile/Profile';
import { useSelector } from 'react-redux';
import { GlobalContext } from '../../../../Context';
import { useTheme } from '@emotion/react';

const validationSchema = Yup.object().shape({
    ticketSubject: Yup.string()
        .required('Ticket subject is required')
        .min(3, 'Minimum  3 characters is required ')
        .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9 !@#$%^&*()\-_=+{};:,.<>?~'"|[\]\\/]+$/, 'Please use alphabtes or combination of letters, numbers, and specific symbols. Avoid using only numbers or special characters.'),
    description: Yup.string()
        .required('Description is required')
        .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9 !@#$%^&*()\-_=+{};:,.<>?~'"|[\]\\/]+$/, 'Please use a combination of letters, numbers, and specific symbols. Avoid using only numbers or special characters.'),
    category: Yup.string().required('Type is required'),
    file: Yup.string().required('File is required'),
});

const CreateTicket = () => {
    const [image, setImage] = React.useState({ preview: '', raw: '' });
    const [apiImageUrl, setApiImageUrl] = React.useState('');
    const fileInputRef = React.useRef(null);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [category, setCategory] = React.useState('');
    const winSize = useScreenSize();
    const navigate = useNavigate();
    const [picture, setPicture] = React.useState(null);
    const formikRef = useRef();
    const auth = useSelector(state => state.auth);
    const { toolTipActive } = React.useContext(GlobalContext);
    const [fileError, setFileError] = React.useState('')
    const [isNeedToNavigate, setIsNeedToNavigate] = useState(false);
    const [uploadLoding, setUploadLoading] = useState(false)
    const theme = useTheme();

    useEffect(() => {
        if (isNeedToNavigate) {
            navigate("/accounts/tickets", { replace: true });
            setIsNeedToNavigate(false)
        }
    }, [isNeedToNavigate]);

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    const ticketsActionCall = async (formData) => {
        const response = await tickets(formData, navigate);
        if (response.status) {
            toolTipActive(response.data.Response_Status.toLowerCase(), response.data.UI_Display_Message);
            setIsNeedToNavigate(true);
        }
    };

    const handlePicture = (event) => {
        setUploadLoading(true)
        const file = event.target.files && event.target.files[0];
        if (!file) {
            setFileError('File is required.');
            setUploadLoading(!true)
            return;
        }

        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (fileExtension === 'apk' || fileExtension === 'exe') {
            setFileError('Invalid file type. .apk and .exe files are not allowed.');
            setSelectedFile(null);
            formikRef.current.setFieldValue('file', '');
            setApiImageUrl('');
            setPicture(null);
            setImage({ preview: '', raw: '' });
            setUploadLoading(!true)
            return;
        }

        setFileError('');
        setSelectedFile(file.name);

        if (file) {
            handleImage({ uri: URL.createObjectURL(file), name: file.name });
            setPicture(URL.createObjectURL(file));
        }
        else {
            setUploadLoading(!true)
        }
    };


    const handleImage = async (result) => {
        try {
            const img = await fetchImageFromUri(result.uri);
            setImage({
                preview: result.uri,
                raw: img
            });
            let fileData = {
                fileName: (result.name.split('.'))[0],
                ext: (result.name.split('.'))[(result.name.split('.').length || 1) - 1],
            };

            const response = await uploadProfilePicAction(img, fileData);
            if (response.status) {
                setFileError("")
                setApiImageUrl(response.data.File_URL);
                formikRef.current.setFieldValue('file', response.data.File_URL);
                setUploadLoading(!true)
            } else {
                setApiImageUrl("");
                toolTipActive("error",response.error)
                setSelectedFile("")
                setUploadLoading(!true)
            }
        } catch (error) {
            setUploadLoading(!true)
        }
    };

    const fetchImageFromUri = async (uri) => {
        const blob = await uriToBlob(uri);
        return blob;
    };

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                ticketSubject: '',
                description: '',
                category: '',
                file: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                if (fileError) {

                    setSubmitting(false);

                    return;

                }
                if(apiImageUrl)
                ticketsActionCall({
                    Ticket_Subject: values.ticketSubject,
                    Ticket_Description: values.description,
                    Type: values.category,
                    Attachment: apiImageUrl,
                    Support_Owner: auth.user?.Email_Id,
                    Ticket_Status: "New",
                    Created_By: auth.user?.Email_Id,
                });
                else{
                    setFileError("Please add the file")
                }

            }}
        >
            {({ errors, submitForm, touched, isSubmitting, setFieldValue }) => (
                <Box>
                    <Typography variant="h5" sx={{ color: theme.palette.text.secondary, marginLeft: { md: '1%', sm: '1%', xs: '12px' }, marginTop: '2%' }}>Add Support Tickets</Typography>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} style={{ padding: '20px', textAlign: 'center', borderRadius: '5px' }}>
                                <Field name="ticketSubject">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Ticket Subject"
                                            variant="outlined"
                                            fullWidth
                                            helperText={touched.ticketSubject && errors.ticketSubject ? errors.ticketSubject : ''}
                                            error={touched.ticketSubject && errors.ticketSubject ? true : false}
                                            onChange={(e) => {
                                                const value = e.currentTarget.value
                                                if (value[0] === " ")
                                                    setFieldValue("ticketSubject", value.slice(0, value.length - 1))
                                                else if (value.slice(value.length - 3, value.length - 1) === "  ")
                                                    setFieldValue("ticketSubject", value.slice(0, value.length - 2))
                                                else {
                                                    setFieldValue("ticketSubject", value)
                                                }
                                            }}
                                            sx={{
                                                '& label': {
                                                    color: theme.palette.headerbutton.textcolorone // or any other pink shade you prefer
                                                },
                                            }}
                                        />
                                    )}
                                </Field>
                                <Field name="description">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Description"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            style={{ marginTop: '10px' }}
                                            helperText={touched.description && errors.description ? errors.description : ''}
                                            error={touched.description && errors.description ? true : false}
                                            onChange={(e) => {
                                                const value = e.currentTarget.value
                                                if (value[0] === " ")
                                                    setFieldValue("description", value.slice(0, value.length - 1))
                                                else {
                                                    setFieldValue("description", value)
                                                }
                                            }}
                                            sx={{
                                                '& label': {
                                                    color: theme.palette.headerbutton.textcolorone // or any other pink shade you prefer
                                                },
                                            }}
                                        />
                                    )}
                                </Field>
                                <Box sx={{ marginTop: '10px' }}>
                                    <Field name="category">
                                        {({ field }) => (
                                            <Box>
                                                <Select
                                                    {...field}
                                                    displayEmpty
                                                    fullWidth
                                                    variant="outlined"
                                                    sx={{ textAlign: 'left' }}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    error={touched.category && errors.category ? true : false}
                                                >
                                                    <MenuItem value="" disabled>Select Type</MenuItem>
                                                    <MenuItem value="Order" sx={{ color: theme.palette.text.secondary }}>Order</MenuItem>
                                                    <MenuItem value="Payment" sx={{ color: theme.palette.text.secondary }}>Payment</MenuItem>
                                                    <MenuItem value="Delivery" sx={{ color: theme.palette.text.secondary }}>Delivery</MenuItem>
                                                    <MenuItem value="Product" sx={{ color: theme.palette.text.secondary }}>Product</MenuItem>
                                                    <MenuItem value="Others" sx={{ color: theme.palette.text.secondary }}>Others</MenuItem>
                                                </Select>
                                                <FormHelperText error={touched.category && errors.category ? true : false}>
                                                    {touched.category && errors.category ? errors.category : ''}
                                                </FormHelperText>
                                            </Box>
                                        )}
                                    </Field>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={(e) => { handlePicture(e) }}
                                        hidden
                                    />
                                    <Stack direction="column" alignItems="center" spacing={2}>
                                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                                            <Typography variant="body1">
                                                {selectedFile ? selectedFile : (touched.file && errors.file) ? "No file selected" : ""}
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<CloudUploadIcon />}
                                                onClick={handleClick}
                                                style={{ margin: '10px' }}
                                            >
                                                Choose a file
                                            </Button>
                                            <Box sx={{ marginLeft: "27%" }}>
                                                <FormHelperText error={touched.file && errors.file ? true : false}>
                                                    {touched.file && errors.file ? errors.file : ''}
                                                </FormHelperText>
                                                {fileError && <FormHelperText error>{fileError}</FormHelperText>}
                                            </Box>
                                        </Box>
                                    </Stack>
                                    <Box sx={{ justifyContent: "center" }}>
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            sx={{ width: '83px' }}
                                            disabled={uploadLoding}
                                            onClick={submitForm}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                            {winSize.screenSize.width > 600 &&
                                <Grid item xs={12} sm={6}>
                                    <img src={require(`../../../../assests/images/tickets.png`)} alt="Image" style={{ maxWidth: '100%', height: 'auto' }} />
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Box>
            )}
        </Formik>
    );
};

export default CreateTicket;
