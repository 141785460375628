import React, { useMemo } from "react";
import { CardActionArea, CardMedia, Typography, Box, Rating, Skeleton } from "@mui/material";
import { useTheme } from "@emotion/react";
import useScreenSize from "../../../utils/ScreenWidthHight";
import AutoAdjustText from "../../../utils/AutoAdjustText";

const PartnerCard = ({ data, onClick, name, logoPath, Short_description, rating, isSkeleton = false, borderColor }) => {

    const theme = useTheme();
    const winSize = useScreenSize();
    const cardWidth = useMemo(() => {
        let cardWidth = (winSize.getWidthForGiveInput(3) - 30);
        if (cardWidth <= 380) {
            cardWidth = 350
        }
        if (winSize.screenSize.width <= cardWidth) {
            cardWidth = winSize.screenSize.width - 30
        }
        return cardWidth + "px"
    }, [
        winSize.screenSize.width
    ])

    if (!isSkeleton)
        return (
            <Box sx={{ borderRadius: '24px', marginBottom: '10px', minWidth: { xs: "80%", sm: "unset" }, width: { md: (winSize.screenSize.width < 1150 ? "30%" : cardWidth), xs: "90%", sm: (winSize.screenSize.width < 800 ? "48%" : cardWidth), xl: "470px" }, height: { xs: "160px", sm: '222px' }, border: `0.4px solid ${borderColor}`, }}
            >
                <CardActionArea style={{ width: '100%', height: '100%' }} onClick={() => onClick()}>
                    <Box display="flex" height="100%" alignItems="center" sx={{ width: "100%" }} >
                        <Box sx={{ width: { xs: "40%", sm: "50%" } }} >
                            <CardMedia
                                component="img"
                                height="100%"
                                width="100%"
                                image={logoPath}
                                alt="partner_Image"
                                sx={{ objectFit: "contain", ml: 1, borderRadius: "12px" }}
                            />
                        </Box>
                        <Box sx={{ width: { xs: "55%", sm: "50%" } }} p={4} display="flex" flexDirection="column">
                            <Box flex="1">
                                <Typography variant="body1" style={{ fontSize: winSize.screenSize.width < 600 && ".8rem", maxWidth: "100%", overflow: "hidden", textWrap: "nowrap" }} numLines={1}  ><strong>{
                                    name.length > 15 ? name?.substring(0, 15) + "..." : name
                                }</strong></Typography>
                                <AutoAdjustText style={{ color: theme.palette.text.secondary, fontSize: winSize.screenSize.width < 600 && ".8rem" }} numLines={4} >{Short_description}</AutoAdjustText>
                                <Rating name="rating" value={rating} readOnly sx={winSize.screenSize.width < 600 && { transform: "scale(70%) translateX(-27px)" }} />
                            </Box>
                            <Box position="absolute" bottom="0" right="0" p={1}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 'bold',
                                        cursor: "pointer",
                                        color: theme.palette.primary.main,
                                        "&:hover": {
                                            textDecoration: 'underline'
                                        },
                                        fontSize: winSize.screenSize.width < 600 && ".8rem"
                                    }}
                                >
                                    View More
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </CardActionArea>
            </Box>
        );

    else {
        return (<Box sx={{ borderRadius: '24px', marginBottom: '10px', minWidth: { xs: "80%", sm: "unset" }, width: { md: (winSize.screenSize.width < 1150 ? "30%" : cardWidth), xs: "90%", sm: (winSize.screenSize.width < 800 ? "48%" : cardWidth), xl: "470px" }, height: { xs: "160px", sm: '222px' }, border: '0px solid', borderColor: theme.palette.text.secondary, overflow: "hidden" }}>
            <Skeleton
                variant="rectangular"
                sx={{ width: cardWidth, height: "100%" }}

            />
        </Box>)
    }
};

export default PartnerCard;