import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCoupons, homeCouponsGet } from "../../../store/slices/retail/home/CouponSlice";
import CouponCard from "../../components/cards/CouponCard";
import { Typography, Box, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const Coupon = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    // const { coupons, loading, error } = useSelector((state) => state.coupons || []);
    const navigate = useNavigate()
    const [coupons, setCoupons] = useState([])
    useEffect(() => {
        homeCouponsGetActionCall()
    }, []);

    const homeCouponsGetActionCall = async () => {
        const response = await homeCouponsGet({ 'Business_Type': 'RETAIL', Verified:1 })
        if (response.status) {
            setCoupons(response.data)
        }
        else {
            setCoupons([])
        }
    }

    return (
        <Box>
            <>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center', paddingY: '5px' }}>
                    <Typography variant="body1" sx={{ fontSize: '24px', fontWeight: 'bold', color: theme.palette.text.secondary,  }}>
                        Coupons
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight:'bold',
                            cursor: "pointer",
                            color: theme.palette.primary.main,
                            "&:hover": {
                                textDecoration: 'underline'
                            },
                            textAlign:"center"
                        }}
                        onClick={() => {
                            navigate('/retail/offers/coupons')
                        }}
                    >
                        View More
                    </Typography>
                </Box>

                <Box sx={{
                    display: "flex", width: "100%", flexDirection: "row", margin: "auto", /* justifyContent: "space-evenly", */ columnGap: "10px",
                    '&::-webkit-scrollbar': {
                        width: '0px',
                        background: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        display: 'none',
                    }
                }} overflow={"auto"} >

                    {coupons.length>0 && coupons.map((coupon, index) => (
                        <CouponCard
                            key={index}
                            data={coupon}
                            discount={coupon.Discount}
                            discountType={coupon.Discount_Type}
                            imagePath={coupon.Image_Path}
                            name={coupon.Name}
                            validateWithDate={coupon.Valid_Through_Date}
                            Discount_Label={coupon.Discount_Label}
                            esiCashBack={coupon.ESI_Cashback_Amount}
                            onClick={() => {
                                navigate(`/retail/offers/coupons/${coupon.Coupon_Id}`)
                            }}
                        />
                    ))}

                    {coupons.length===0 && [0,1,2].map((coupon, index) => (
                        <CouponCard
                            key={index}                           
                            isSkeleton
                        />
                    ))}
                </Box>
            </>
        </Box>
    );
};

export default Coupon;