import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi } from '../../CommonAxios';

const initialState = {
    banners: [],
    loading: false,
    error: null,
    grocerybanner:[]
}

export const fetchBanners = createAsyncThunk("homeBanners/fetchBanners", async (formData) => {
    try {
        const response = await nodeApi.post("/Home/Banner/Get", formData);
        const data = response?.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            return [];
        } else {
            return data.results;
        }
    } catch (error) {
        throw error;
    }
});


export const groceryfetchBanners = createAsyncThunk("homeBanners/groceryfetchBanners", async (formData) => {
    try {
        const response = await nodeApi.post("/Home/Banner/Get", formData);
        const data = response?.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            return [];
        } else {
            return data.results;
        }
    } catch (error) {
        throw error;
    }
});

const homeBannersSlice = createSlice({
    name: 'homeBanners',
    initialState,
    
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(fetchBanners.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchBanners.fulfilled, (state, action) => {
                state.loading = false;
                state.banners = action.payload;
            })
            .addCase(fetchBanners.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(groceryfetchBanners.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(groceryfetchBanners.fulfilled, (state, action) => {
                state.loading = false;
                state.banners = action.payload;
            })
            .addCase(groceryfetchBanners.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
    }
});

export default homeBannersSlice.reducer;
