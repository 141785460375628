import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi, urlGenarator } from '../../CommonAxios';

const initialState = {
  gifts: [],
  loading: false,
  error: null,
  Pagination: {}
};



export const fetchGifts = createAsyncThunk("gift/fetchGifts", async (formData) => {
  const response = await nodeApi.post(urlGenarator("/Gift_Voucher/Ordered_Gifts", formData.Pagination), formData.formData)
  if (response.data.Success_Response.Is_Data_Exist === "0") {
    return []
  } else {

    return response.data
  }
})
// export const fetchGifts = createAsyncThunk("gift/fetchGifts", async (formData) => {
//   try {
//     const response = await nodeApi.post("/Gift_Voucher/Ordered_Gifts?PageSize=80&SortBy=Created_Date&SortOrder=DESC", formData);
//     const data = response?.data;
//     if (data.Success_Response.Is_Data_Exist === "0") {
//       return [];
//     } else {
//       return data.results;
//     }
//   } catch (error) {
//     throw error;
//   }
// });

export const fetchRevGifts = createAsyncThunk("gift/revGifts", async (formData) => {
  const response = await nodeApi.post(urlGenarator("/Gift_Voucher/Received_Gifts", formData.Pagination), formData.formData)
  if (response.data.Success_Response.Is_Data_Exist === "0") {
    return []
  } else {
    return response.data
  }
})

// export const fetchRevGifts = createAsyncThunk('gift/revGifts', async (formData) => {
//   try {
//     const response = await nodeApi.post("/Gift_Voucher/Received_Gifts?PageSize=80&SortBy=Created_Date&SortOrder=DESC", formData);
//     const data = response?.data;
//     if (data.Success_Response.Is_Data_Exist === "0") {
//       return [];
//     } else {
//       return data.results;
//     }
//   } catch (error) {
//     throw error;
//   }
// })


const giftSlice = createSlice({
  name: 'gift',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGifts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGifts.fulfilled, (state, action) => {
        state.loading = false;
        state.gifts = action.payload.results;
        state.Pagination = action.payload.Pagination;
      })
      .addCase(fetchGifts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchRevGifts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRevGifts.fulfilled, (state, action) => {
        state.loading = false;
        state.gifts = action.payload.results;
        state.Pagination = action.payload.Pagination;
      })
      .addCase(fetchRevGifts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default giftSlice.reducer;

// export const categoriesGetAction = (formData) => {
//   return new Promise(async (resolve, reject) => {
//     await nodeApi.post('/Home/Categories/Get', formData).then((res) => {
//       if (res.data.Success_Response.Is_Data_Exist === "1") {
//         resolve({ status: true, data: res.data.results })
//       } else {
//         resolve({ status: false, data: [] })
//       }
//     }).catch((err) => {
//       resolve({ status: false, error: err.message })
//     })
//   })
// }
// export const subCategoriesGetAction = (formData) => {
//   return new Promise(async (resolve, reject) => {
//     await nodeApi.post('/Home/SubCategories/Get', formData).then((res) => {
//       if (res.data.Success_Response.Is_Data_Exist === "1") {
//         resolve({ status: true, data: res.data.results })
//       } else {
//         resolve({ status: false, data: [] })
//       }
//     }).catch((err) => {
//       resolve({ status: false, error: err.message })
//     })
//   })
// }