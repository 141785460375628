import { createSlice } from "@reduxjs/toolkit";



export const CommonSlice = createSlice({
    name: "commonReducer",
    initialState: {
        Address: {},
        id: "",
        Gift_Vendor_Id: "",
        Refresh_Id: { id: "", count: 0 },
        Cart_Refresh:0
    },
    reducers: {
        addressSelection: (state, action) => {
            if (action.payload) {
                state.Address = action.payload
            }
        },
        giftSelection: (state, action) => {
            if (action.payload) {
                state.id = action.payload
                state.Gift_Vendor_Id = action.payload
            }
        },
        refreshIdUpdate: (state, action) => {
            if (action.payload) {
                state.Refresh_Id = { id: action.payload, count: state.Refresh_Id.count + 1 }
            }
        },
        cartRefresh: (state, action) => {
                state.Cart_Refresh = state.Cart_Refresh+1
        },
    }
})

export const { addressSelection, giftSelection, refreshIdUpdate, cartRefresh } = CommonSlice.actions