import React, { useEffect, useState } from 'react';
import {Box,Typography,CardMedia,TextField,Card,Pagination} from "@mui/material";
import { useTheme } from '@emotion/react';
import useScreenSize from '../../../utils/ScreenWidthHight';
import { useSelector, useDispatch } from 'react-redux';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { fetchGiftVendorData, getVendorNameFromVendorId } from '../../../store/slices/retail/home/GiftVendorsSlice';
import { useNavigate, useParams } from 'react-router-dom';

const Giftvendors = () => {
    const navigate = useNavigate();
    const giftvendor = useSelector(state => state.giftvendor.giftVendorData);
    const pagination = useSelector(state => state.giftvendor.Pagination);
    const dispatch = useDispatch();
    const theme = useTheme();
    const winSize = useScreenSize();
    const { id } = useParams();
    const [searchTerm, setSearchTerm] = useState('');
    const [pageNo, setPageNo] = useState(1);

    useEffect(() => {
        dispatch(fetchGiftVendorData({
            formData: { Occassion: id || "" },
            Pagination: { PageNo: pageNo, PageSize: 12 }
        }));
    }, [id, pageNo]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleChangePage = (event, value) => {
        setPageNo(value);
    };

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: {md:"space-between", sm:'space-between', xs:'flex-start'} , flexDirection:{xs:'column', md:'row', sm:'row'} }}>
                <Typography sx={{ marginTop: "24px", fontSize:'24px',color: theme.palette.text.secondary, marginLeft: {md:"70px", sm:'70px', xs:'20px'}, marginRight: "30px", }}>
                    Choose Vendor
                </Typography>
                <Box sx={{ display: "flex",flexDirection: { xs: "column", md: "row" }, marginTop: "20px", marginRight: "43px" }}>
                    <TextField
                        placeholder="Search Vendor"
                        size="small"
                        sx={{
                            width: { xs: "100%", md: "345px" },
                            ml: { xs: '20px', sm: 'unset', md: 'unset' },
                            maxWidth: "100%",
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "12px",
                            },
                        mb:'8px'
                        }}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Box sx={{
                width: "100%",
                minHeight: "50%",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                cursor: "pointer",
                gap: 1,
                justifyContent: "center"
            }}>
                {giftvendor?.map((element, index) => (
                    <GiftCard Gift_Vendor_Id={element.Gift_Vendor_Id} id={id}key={index} winSize={winSize} theme={theme} element={element} searchTerm={searchTerm} />
                ))}
            </Box>
            {pagination?.TotalPages > 1 && (
                <Box sx={{ display: 'flex', justifyContent: "center", width: "100%" }}>
                    <Pagination color='primary' page={pageNo} onChange={handleChangePage} count={pagination?.TotalPages} />
                </Box>
            )}
        </Box>
    );
}

const GiftCard = ({ Gift_Vendor_Id, element, winSize, theme, searchTerm,id }) => {

    const [vendorName, setVendorName] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const getVendorNameFromVendorIdCall = async (formData) => {
            const response = await getVendorNameFromVendorId(formData, {});
            if (response.status) {
                setVendorName(response.data[0]?.Vendor_Name);
            }
        };
        getVendorNameFromVendorIdCall({ Gift_Vendor_Id: Gift_Vendor_Id,Occassion:id, Search: searchTerm,}, {});
    }, [Gift_Vendor_Id, searchTerm]);

    if (vendorName)
        return (
            <Card sx={{
                display: "flex",
                width: { xs: '90%', md: 300 },
                flexDirection: "column",
                border: "1px solid " + theme.palette.extra.primary,
                height: {md:"297px", sm:'297px', xs:'171px'},
                borderRadius: "20px",
                marginTop: "15px",
                "&:hover": { cursor: "pointer" }
            }}
                onClick={() => navigate('/gifts/create', { state: { navigateData: element } })}>
                <Box sx={{ width: "100%" }}>
                    <Typography variant="h6" sx={{
                        color: theme.palette.primary,
                        fontSize: 20,
                        width: "100%",
                        maxWidth: "100%",
                        marginLeft: "10px",
                        marginTop: {md:"15px", sm:'15px', xs:'8px'}
                    }}>{vendorName}</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: { md: "240px", sm: "240px", xs: "120px" }
                    }}
                >
                    <CardMedia
                        component="img"
                        image={element.Gift_Vendor_Image}
                        style={{
                            width: "95%",
                            maxWidth: "100%",
                            display:'flex',
                            justifyContent: 'center',
                            alignItems:'center',
                            height: (winSize.screenSize.width>600 ?"240px": "120px"),
                            objectFit: "contain"
                        }}
                    />
                </Box>
            </Card>
        );
}

export default Giftvendors;