import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Container, Link, Button, IconButton, InputAdornment, Radio, Grid, LinearProgress } from '@mui/material';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTheme } from '@emotion/react';
import useScreenSize from '../../utils/ScreenWidthHight';
import { useNavigate } from 'react-router-dom';
import { signUpAction, signUpEmailExist, signUpMobileExist } from '../../store/slices/AuthSlice';
import { GlobalContext } from '../../Context'
const SignUp = (props) => {
  const winSize = useScreenSize();
  const theme = useTheme()
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const navigate = useNavigate();
  const { searchParams, setSearchParams, updateQueryParam } = React.useContext(GlobalContext);

  const [signupInit, setSignupInit] = React.useState({
    Email_ID: '',
    Mobile: '',
    // Name: '',
    First_Name: '',
    Last_Name: '',
    Mobile_OTP: '',
    Terms: false,
    Marketing: false,
    Reference_Id: searchParams.get("referral"),
    Password: '',
    cpassword: ''
  });

  const [otpMobileNumber, setOtpMobileNumber] = React.useState("");
  const [otpMobileNumberError, setOtpMobileNumberError] = React.useState([]);
  const [emailExist, setEmailExist] = React.useState("");
  const [emailExistError, setEmailExistError] = React.useState([]);
  const [secondsOTP, setSecondsOTP] = React.useState(-1);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSubmited, setIsSubmited] = React.useState(false);

  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  React.useEffect(() => {
    let timer;
    if (secondsOTP > 0) {
      timer = setTimeout(() => {
        setSecondsOTP((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [secondsOTP]);

  const handleClickShowPassword = (fieldName) => {
    if (fieldName === 'Password') {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    } else if (fieldName === 'ConfirmPassword') {
      setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onMobileFieldOnBlur = async (validation = false) => {
    if (!(formikRef.current?.errors.Mobile) && (otpMobileNumber != formikRef.current?.values.Mobile || validation)) {
      setOtpMobileNumber(formikRef.current?.values.Mobile);
      setIsSubmited(true);
      const res = await signUpMobileExist(formikRef.current?.values.Mobile);
      if (res.status) {
        setSecondsOTP(30);
      } else {
        let newOtpMobileNumberError = JSON.parse(JSON.stringify(otpMobileNumberError));
        newOtpMobileNumberError.push(res.input);
        setOtpMobileNumberError(newOtpMobileNumberError);
        formikRef.current?.setFieldError("Mobile", 'Mobile Number is already exist.')
      }
      setIsSubmited(false);

    }
    else if (otpMobileNumber == formikRef.current?.values.Mobile) {
    }
    else {
      setOtpMobileNumber("");
    }
  };

  const onEmailFieldOnBlur = async () => {
    if (!(formikRef.current?.errors.Email_ID) && emailExist != formikRef.current?.values.Email_ID) {
      setEmailExist(formikRef.current?.values.Email_ID);
      const res = await signUpEmailExist(formikRef.current?.values.Email_ID);
      if (!res.status) {
        let emailExistErrorN = JSON.parse(JSON.stringify(emailExistError));
        emailExistErrorN.push(res.input);
        setEmailExistError(emailExistErrorN);
        formikRef.current?.setFieldError("Email_ID", 'Email Address is already exist.');
      }
    }
    else if (emailExist == formikRef.current?.values.Email_ID) {
    }
    else {
      setEmailExist("");
    }
  };

  const signUpCall = async (data) => {
    setErrorMessage("");
    setIsSubmited(true);
    const res = await signUpAction(data);
    if (res.status) {
      navigate("../signin");
    } else {
      setErrorMessage(res.data);
      setSeconds(3);
    }
    setIsSubmited(false);
  }

  const formikRef = React.useRef();

  React.useEffect(() => {
    if (!formikRef.current) return;
    const refCodeField = formikRef.current.values.Reference_Id;
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('referral') && refCodeField === '') {
      urlParams.delete('referral');
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState({}, '', newUrl);
    }
  }, [formikRef.current?.values.Reference_Id]);

  return (
    <Container sx={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center", mt: '10px' }}>
      <Grid container sx={{ justifyContent: 'space-evenly' }}>
        {winSize.screenSize.width > 701 &&
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: "flex", justifyContent: "left" }}>
              <img
                src={require('../../assests/images/mee.png')}
                alt=""
                style={{
                  width: "85%",
                  maxWidth: '544px',
                  marginTop: "60px"
                }}
              />
            </Box>
          </Grid>
        }
        <Grid item xs={12} sm={4} textAlign={"center"}>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}>
            {winSize.screenSize.width > 600 &&
              < img
                src={require('../../assests/images/avatarsignup.jpg')}
                alt="Profile Picture"
                style={{
                  width: '44px',
                  height: '44px',
                  borderRadius: '50%'
                }}
              />
            }
          </Box>
          <Box sx={{}}>
            <Box 
            sx={{marginY:{xs:"10px",sm:"unset"}}}
            >
              {winSize.screenSize.width < 600 && <img
                src={require('../../assests/images/avatarsignup.jpg')}
                alt="Profile Picture"
                style={{
                  width: '44px',
                  height: '44px',
                  borderRadius: '50%'
                }}
              />}
              <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: {xs:'20px',md:"30px"} }}>
                Sign Up
              </Typography>
            </Box>
            <Formik
              innerRef={formikRef}

              initialValues={signupInit}
              validationSchema={Yup.object().shape({
                Email_ID: Yup.string()
                  .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, 'Invalid email format')
                  .email('Please provide valid Email Address.').notOneOf(emailExistError, 'Email Address is already exist.')
                  .max(255).required("Email Id is Required"),
                Mobile: Yup.string()
                  .trim()
                  .matches(/^[^\s]*$/, 'Mobile Number cannot contain spaces.')
                  .matches(/^\d+$/, 'Mobile Number should contain only digits.')
                  .notOneOf(otpMobileNumberError, 'Mobile Number is already exist.')
                  .min(10, "Please provide valid Mobile Number.")
                  .max(10, "Please provide valid Mobile Number.")
                  .required("Mobile Number is required."),
                // Name: Yup.string()
                //   .trim(" ")
                //   .matches(/^[a-zA-Z0-9\s'-.]+$/, 'Name should only contain alphabetic characters, numbers, spaces, and appropriate symbols (e.g., hyphens or apostrophes).')
                //   .required("Name is required.")
                //   .test('contains-alphabet', 'Name must contain at least one alphabetic character.', value => {
                //     return /[a-zA-Z]/.test(value);
                //   })
                //   .test('no-consecutive-periods', 'Consecutive dots are not allowed in Name', value => {
                //     return !/\.\./.test(value);
                //   }),
                First_Name: Yup.string()
                  .trim(" ")
                  .required("First_Name is required.")
                  .matches(/^[a-zA-Z0-9\s'-.]+$/, 'First_Name should only contain alphabetic characters, numbers, spaces, and appropriate symbols (e.g., hyphens or apostrophes).')
                  .test('contains-alphabet', 'First_Name must contain at least one alphabetic character.', value => {
                    return /[a-zA-Z]/.test(value);
                  })
                  .test('no-consecutive-periods', 'Consecutive dots are not allowed in First_Name', value => {
                    return !/\.\./.test(value);
                  }),
                Last_Name: Yup.string()
                  .trim(" ")
                  .matches(/^[a-zA-Z0-9\s'-.]+$/, 'Last_Name should only contain alphabetic characters, numbers, spaces, and appropriate symbols (e.g., hyphens or apostrophes).')
                  .required("Last_Name is required.")
                  .test('contains-alphabet', 'Last_Name must contain at least one alphabetic character.', value => {
                    return /[a-zA-Z]/.test(value);
                  })
                  .test('no-consecutive-periods', 'Consecutive dots are not allowed in Last_Name', value => {
                    return !/\.\./.test(value);
                  }),
                Password: Yup.string()
                  .min(7, "Password must be at least 7 characters long.")
                  .max(32, "Password must be at most 32 characters long.")
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*])(?!.*\s).+$/,
                    "Password must contain a mix of letters, numbers, and/or special characters. Passwords containing only letters or only numbers are not accepted."
                  )
                  .matches(
                    /^[^'"\s&]+$/,
                    "Password must not contain single quotes, double quotes, ampersands, or spaces."
                  )
                  .required('Password is required.'),
                cpassword: Yup.string()
                  .max(255).oneOf([Yup.ref('Password'), null], 'Passwords must match.')
                  .required('Confirm Password is required.'),
                Mobile_OTP: Yup.string().when('Mobile', (Mobile, schema) => {
                  if (Mobile?.length == 10) {
                    return schema.min(6, 'Please provide valid OTP.')
                      .required('Mobile OTP is required.')
                      .min(6, "OTP Length Should Contain 6 Letters")
                      .max(6, "OTP Length Should Contain 6 Letters");
                  } else {
                    return schema
                  }
                })
                ,
                Terms: Yup.boolean().oneOf([true], "You must accept the terms and conditions."),
                Marketing: Yup.boolean().oneOf([true], "You must accept the marketing terms.")
              })}
              onSubmit={(values, { setSubmitting }) => {
                setErrorMessage("");
                signUpCall({
                  ...values,
                  Mobile: "+91 " + values.Mobile,
                  Terms: values.Terms ? 1 : 0,
                  Marketing: values.Marketing ? 1 : 0
                });
              }}

            >
              {({ values, submitForm, errors, touched, setFieldValue, isSubmitting, handleBlur, handleChange }) => (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}  >
                  {/* <Grid sx={{ marginBottom: "30px", flex: '1' }}>
                    <Field
                      name="Name"
                      as={TextField}
                      label="Name*"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      error={Boolean(touched.Name && errors.Name)}
                      helperText={touched.Name && errors.Name ? errors.Name : ''}
                    />
                  </Grid> */}
                  <Grid sx={{ marginBottom: "30px", flex: '1' }}>
                    <Field
                      name="First_Name"
                      as={TextField}
                      label="First Name*"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      error={Boolean(touched.First_Name && errors.First_Name)}
                      helperText={touched.First_Name && errors.First_Name ? errors.First_Name : ''}
                    />
                  </Grid>
                  <Grid sx={{ marginBottom: "30px", flex: '1' }}>
                    <Field
                      name="Last_Name"
                      as={TextField}
                      label=" Last Name*"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      error={Boolean(touched.Last_Name && errors.Last_Name)}
                      helperText={touched.Last_Name && errors.Last_Name ? errors.Last_Name : ''}
                    />
                  </Grid>
                  <Grid sx={{ marginBottom: "30px", flex: '1' }}>
                    <Field
                      name="Email_ID"
                      as={TextField}
                      label="Email ID*"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      onBlur={(props) => {
                        handleBlur(props);
                        onEmailFieldOnBlur();
                      }}
                      error={Boolean(touched.Email_ID && errors.Email_ID)}
                      helperText={touched.Email_ID && errors.Email_ID ? errors.Email_ID : ''}
                    />
                  </Grid>

                  {/* Remaining form fields */}
                  <Grid sx={{ marginBottom: "30px", flex: '1' }}>
                    <Field
                      name="Mobile"
                      as={TextField}
                      label="Mobile Number*"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      onBlur={(props) => {
                        handleBlur(props);
                        onMobileFieldOnBlur();
                      }}
                      // onChange={(event) => {
                      //   const value = event.target.value.replace(/\D/g, '');
                      //   setFieldValue('Mobile', value);
                      // }}
                      onChange={(event) => {
                        const value = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
                        if (value.length < 11) {
                          setFieldValue('Mobile', value);
                        }
                      }}
                      error={Boolean(touched.Mobile && errors.Mobile)}
                      helperText={touched.Mobile && errors.Mobile ? errors.Mobile : ''}

                    />
                    {(values.Mobile.length === 10 && !Boolean(touched.Mobile && errors.Mobile)) && (
                      <Typography variant="body2" sx={{ textAlign: 'right' }}>
                        <Link href="#" sx={{ textDecoration: 'none' }} onClick={(e) => {
                          if (!isSubmited && secondsOTP <= 0)
                            onMobileFieldOnBlur(true)
                        }}
                        >
                          {secondsOTP > 0
                            ? `Resend OTP (${secondsOTP})`
                            : `Resend OTP`}
                        </Link>
                      </Typography>
                    )}
                  </Grid>
                  {values.Mobile && values.Mobile.length === 10 && (
                    <Grid sx={{ marginBottom: "30px", flex: '1' }}>
                      <Field
                        name="Mobile_OTP"
                        as={TextField}
                        label="Verify OTP*"
                        size="small"
                        fullWidth
                        error={Boolean(touched.Mobile_OTP && errors.Mobile_OTP)}
                        helperText={touched.Mobile_OTP && errors.Mobile_OTP ? errors.Mobile_OTP : ''}
                        autoComplete="off"
                        inputProps={{ maxLength: 6 }}
                        onChange={(e) => {
                          let value = e.currentTarget.value;
                          if (value.includes(" ")) {
                            value = value.slice(0, value?.length - 1)
                          }
                          value = value.replace(/\D/g, "");
                          setFieldValue('Mobile_OTP', value);
                        }}
                      />
                    </Grid>
                  )}
                  <Grid sx={{ marginBottom: "30px", flex: '1' }}>
                    <Field
                      name="Password"
                      as={TextField}
                      label="Password*"
                      size="small"
                      fullWidth
                      type={showPassword ? 'text' : 'password'}
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end">
                      //       <IconButton
                      //         aria-label="toggle password visibility"
                      //         onClick={() => handleClickShowPassword('Password')}
                      //         onMouseDown={handleMouseDownPassword}
                      //         edge="end"
                      //         sx={{ color: theme.palette.extra.primary }}
                      //       >
                      //         {!showPassword ? <VisibilityOff /> : <Visibility />}
                      //       </IconButton>
                      //     </InputAdornment>
                      //   ),
                      // }}
                      error={Boolean(touched.Password && errors.Password)}
                      helperText={touched.Password && errors.Password ? errors.Password : ''}
                      autoComplete="new-password"
                    />
                  </Grid>

                  {/* ...Remaining form fields */}
                  <Grid sx={{ marginBottom: "30px", flex: '1' }}>
                    <Field
                      name="cpassword"
                      as={TextField}
                      label="Confirm Password*"
                      size="small"
                      fullWidth
                      type={showConfirmPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle confirm password visibility"
                              onClick={() => handleClickShowPassword('ConfirmPassword')}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              sx={{ color: theme.palette.extra.primary }}
                            >
                              {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(touched.cpassword && errors.cpassword)}
                      helperText={touched.cpassword && errors.cpassword ? errors.cpassword : ''}
                      autoComplete="off"
                    />
                  </Grid>

                  {/* ...Remaining form fields */}
                  <Grid sx={{ marginBottom: "30px", flex: '1' }}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="Reference_Id"
                      label="Referral Code"
                      name="Reference_Id"
                      autoComplete="referral"
                      value={values.Reference_Id}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        if (values.Reference_Id !== e.target.value) {
                          const urlParams = new URLSearchParams(window.location.search);
                          if (urlParams.has('referral')) {
                            urlParams.delete('referral');
                            const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
                            window.history.replaceState({}, '', newUrl);
                          }
                        }
                      }}
                      error={Boolean(errors.Reference_Id && touched.Reference_Id)}
                      helperText={errors.Reference_Id && touched.Reference_Id && errors.Reference_Id}
                    />
                  </Grid>

                  {/* ...Remaining form fields */}
                  <Grid sx={{ marginBottom: '8px', display: 'flex' }}>
                    <Radio
                      name="Terms"
                      checked={values.Terms}
                      onClick={(e) => setFieldValue('Terms', !values.Terms)}
                    />
                    <Typography variant="body2" sx={{ color: Boolean(touched.Terms && errors.Terms) ? theme.palette.error.main : 'inherit', marginTop: 1 }}>
                      I agree to the{' '}
                      <Link href="/accounts/terms&conditions" >
                         Terms & Conditions
                      </Link>
                    </Typography>
                  </Grid>

                  {/* ...Remaining form fields */}
                  <Grid sx={{ marginBottom: '8px', display: "flex", justifyContent: 'left' }}>
                    <Radio
                      name="Marketing"
                      checked={values.Marketing}
                      onClick={(e) => setFieldValue('Marketing', !values.Marketing)}
                    />
                    <Typography variant="body2" sx={{ color: Boolean(touched.Marketing && errors.Marketing) ? theme.palette.error.main : 'inherit', marginTop: 1 }}>
                      I agree to the{' '}
                      <Link href="/accounts/marketingstrategy">
                         Marketing Terms
                      </Link>
                    </Typography>
                  </Grid>

                  {seconds > 0 &&
                    <Grid sx={{ marginBottom: '30px', width: "100%", maxWidth: "412px" }}>
                      <Typography sx={{ color: theme.palette.error.main }} >
                        {errorMessage}
                      </Typography>
                      <LinearProgress variant="determinate" color={"error"} value={seconds * 30} />
                    </Grid>
                  }

                  {/* Submit button */}
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={submitForm}
                    size="medium"
                    fullWidth
                    disabled={isSubmited}
                    sx={{ height: '36px', borderRadius: '22px', flex: '1', marginBottom: "30px" }}
                  >
                    Sign Up
                  </Button>

                  {/* Sign In link */}
                  <Typography variant="body2" sx={{ marginTop: 3 }}>
                    <Link href="/auth/signin" sx={{ textDecoration: 'none' }}>
                      Already have an account?{' '}
                    </Link>
                    <Link href="/auth/signin">
                      Sign In
                    </Link>
                  </Typography>

                  {/* Copyright */}
                  <Typography variant="body2" sx={{ marginTop: 5 }}>
                    Copyright @ EasyStepIn 2016 - 2024.
                  </Typography>
                </Box>
              )}
            </Formik>
          </Box>
        </Grid>

      </Grid>
    </Container>
  );
}


export default SignUp

