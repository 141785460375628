import React, { useEffect, useMemo, useState } from "react";
import { Box, IconButton, Button, Typography, ListItemIcon } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddress } from "../../store/slices/accounts/Address/Address";
import { useNavigate } from 'react-router-dom'
import { useTheme } from "@emotion/react";
import { styled } from '@mui/material/styles';
import { logout, signOutStateUpdate } from "../../store/slices/AuthSlice";
import { GlobalContext } from '../../Context';
import useScreenSize from "../../utils/ScreenWidthHight";
import ClickAwayListener from '@mui/material/ClickAwayListener';

export const ProfileDropdown = ({ isAuthenticated, authData, setPageModalOpen }) => {
  const dispatch = useDispatch();
  const authDetails = useSelector(state => state.auth);
  const navigate = useNavigate();
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false)
  const theme = useTheme();
  const { toolTipActive, setLogLoading } = React.useContext(GlobalContext);
  const winsizes = useScreenSize()
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchAddress({
        formData: {
          "User_Email_Id": authDetails?.user?.Email_Id
        },
      }));
    }
  }, []);


  useEffect(() => {
    if (isNavigate) {
      navigate('/auth/signin')
    } else {
      setIsNavigate(false);
    }
  }, [isNavigate])

  const handleLogout = async () => {
    setLogLoading(true)
    const formData = {
      "User_Session_Id": authDetails.user?.User_Session_Id,
      "User_Email_Id": authDetails.user?.Email_Id,
      "Device_Token": window.localStorage.getItem("Token")
    }
    const response = await logout(formData);

    if (response.status) {
      window.localStorage.removeItem("Token");
      window.localStorage.removeItem("persist:auth");
      toolTipActive("success", 'You have successfully logged out.');
      dispatch(signOutStateUpdate())
      setLogLoading(false)
      setIsNavigate(true)
    }
    else {
      setLogLoading(false)
    }
  }

  const HtmlTooltip = useMemo(() => styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.header.backgroundcolor,
      border: "1px solid "+theme.palette.action.selected,
      borderRadius:"10px",
      fontSize: theme.typography.pxToRem(12),
      [theme.breakpoints.down("xs")]: {
        minWidth: 100,
      },
      [theme.breakpoints.up("sm")]: {
        minWidth: 200,
      },
      [theme.breakpoints.up("md")]: {
        minWidth: 300,
        marginRight:"55px"
      },
    },
  })), []);

  const handleClick = (e) => {
    e.stopPropagation();
    if (!isAuthenticated)
      navigate('/auth/signin')

  };
  const handleMouseEnter = () => {
    setTooltipIsOpen(true);
  };

  const handleMouseLeave = (event) => {
    try {
      if (event?.relatedTarget)
        if (event?.currentTarget) {
          if (event?.relatedTarget && !event?.currentTarget.contains(event?.relatedTarget)) {
            setTooltipIsOpen(false);
          }
        }
    } catch (error) {
    }
  };

  

  const handleTooltipClick = (event) => {
    event.stopPropagation(); 
  };

  

  return (<Box onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    onClick={handleClick}
    style={{ display: 'inline-block' }}>
    <HtmlTooltip
      open={tooltipIsOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={
        isAuthenticated ?
          <Box onClick={handleTooltipClick} sx={{ display: "flex", flexDirection: "column", gap: 3, minHeight: "200px" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <ListItemIcon>
                <img
                  src={isAuthenticated ? authData?.Profile_Pic_Path || require(`../../assests/images/userIcon.png`) : require(`../../assests/images/avatar.jfif`)}
                  alt=""
                  style={{ height: "60px", width: "60px", borderRadius: '50%', marginRight: 10 }}
                />
              </ListItemIcon>
              <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold",color:theme.palette.text.primary }} >{authData?.Full_Name}</Typography>
                <Typography variant="body2" sx={{ fontWeight: "bold",color:theme.palette.text.primary }} >{authData?.Email_Id}</Typography>
                <Typography variant="body2" sx={{ fontWeight: "bold",color:theme.palette.text.primary }} >{authData?.Mobile}</Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: "space-around" }}>
              <Button
                variant="contained"
                onClick={() => navigate('/Accounts/profile')}
                sx={{
                  borderRadius: "20px",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main
                  },
                  backgroundColor: theme.palette.headerbutton.backgroundcolorone,
                  color: theme.palette.headerbutton.textcolorone,
                  ':hover': {
                    color: theme.palette.extra.contrastText,
                  },
                  textTransform: 'capitalize',
                  fontSize: '0.9rem',
                  flexGrow: 1,
                  marginRight: '50px',
                }}
              >
                Profile
              </Button>
              <Button
                variant="contained"
                onClick={handleLogout}
                onMouseEnter={() => { setTooltipIsOpen(true); }}

                sx={{
                  borderRadius: "20px",
                  textTransform: 'capitalize',
                  fontSize: '0.9rem',
                  flexGrow: 1,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main
                  },
                }}
              >
                Logout
              </Button>
            </Box>
            {winsizes.screenSize.width < 800 && <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
              <Button sx={{ textTransform: 'capitalize', fontSize: "16px" }} onClick={() => navigate('/retail/offers/coupons')}>Offers</Button>
              <Button sx={{ textTransform: 'capitalize', fontSize: "16px" }} onClick={() => navigate('/accounts/contactus')}>Contact</Button>
              <Button sx={{ textTransform: 'capitalize', fontSize: "16px" }} onClick={() => navigate('/retail/shop')}>Shops</Button>
              <Button sx={{ textTransform: 'capitalize', fontSize: "16px" }} onClick={() => { setPageModalOpen(true) }}>Pages</Button>
            </Box>}
          </Box>

          :
          null
      }
    >
      <IconButton
        aria-label="account of current user"
        aria-haspopup="true"
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        size="large"
      >
        <img
          src={isAuthenticated ? authData?.Profile_Pic_Path || require(`../../assests/images/userIcon.png`) : require(`../../assests/images/userIcon.png`)}
          alt=""
          style={{ height: 44, width: 44, borderRadius: '50%' }}
        />
      </IconButton>
    </HtmlTooltip>
  </Box>
  );
};

export default ProfileDropdown;
