import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, Rating, TextField, Typography } from "@mui/material";
import { fetchOrderDetails, fetchProductDetails, groceryProductFetch, invoiceGet, productFetch } from "../../../../store/slices/accounts/orders/OrdersSlice";
import PaymentSummary from "../components/PaymentSummary";
import DeliveryDetails from "../components/DeliveryDetails";
import OrderStepper from "../components/OrderStepper";
import OrdersCard from "../components/OrderCard";
import OrderDetailsCard, { GroceryShippingAddressComponent } from '../components/OrderDetailsCard'
import { useLocation, useNavigate, useParams, } from "react-router-dom";
import DeliveryAgentReview from "../components/DeliveryAgentReview";
import { deliveryAgentReviewGet } from "../../../../store/slices/accounts/orders/DeliveryAgentReviews";
import DeliveryReviews from "../components/DeliveryReviews";
import { CancelModalComp } from "../components/CancelModal";
import { useTheme } from "@emotion/react";
import { isEmpty } from "../../../../utils";

const OrdersDetailsPage = () => {
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.auth);
    const { orders, products } = useSelector(state => state.orders);
    const { deliveryReviews } = useSelector(state => state.deliveryReviews);
    const navigate = useNavigate()
    const { id } = useParams();
    const EmailID = userDetails?.user?.Email_Id;
    const [product, setProduct] = useState([]);
    const [deliveryReviewsGet, setDeliveryReviewsGet] = useState([]);
    const [yourComments, setYourComments] = useState([]);
    const [productNewdata, setProductNewData] = useState({})
    const [groceryProducts, setNewGroceryProducts] = useState([])
    const [pageNotFoundStatus, setPageNotFoundStatus] = useState(false)
    const auth = useSelector(state => state.auth)
    const theme = useTheme()

    useEffect(() => {
        if (id && auth.isAuthenticated) {
            productsCall(id);
            deliveryCommentsCall(id);
        }
        else {
            navigate('/auth/signin')
        }
    }, [id]);

    const [cancelModal, setCancelModal] = useState(false)

    const productsCall = async (id) => {
        const formData = { Order_Id: id, User_Email_Id: userDetails.user.Email_Id, };
        const response = await groceryProductFetch(formData);
        if (response.status) {
            setProduct(response.data);
            setNewGroceryProducts(response.data.Item_Product_Details)
            setProductNewData([response.data].map((Product) => {
                deliveryCommentsCall(Product.Item_Product_Details[0]?.Item_Master_Id);
                return ({
                    Order_Date: Product?.Item_Product_Details[0]?.Order_Date || "",
                    Order_Id: Product.Orderdetails.Order_Id,
                    Customer_Name: Product.Shipping_Address?.Name,
                    Customer_Address: Product.Shipping_Address?.Location,
                    Customer_PinCode: Product.Shipping_Address?.Zip,
                    Customer_Mobile: Product.Shipping_Address?.Mobile,
                    Order_Status: Product.Orderdetails.Order_Status,
                    Billing_Name: Product.Billing_Address?.Name,
                    Billing_Address: Product.Billing_Address?.Location,
                    Billing_Pincode: Product.Billing_Address?.Zip,
                    Billing_Mobile: Product.Billing_Address?.Mobile,
                    Agent_Mobile: Product.Del_User_Details?.Mobile,
                    Agent_Name: Product.Del_User_Details?.Full_Name,
                    Agent_Photo: Product.Del_User_Details?.Photo,
                    Igst: Product.Payment_Summary?.Igstpercentage,
                    VatAmount: Product.Payment_Summary.Vat,
                    IgstAmount: Product.Payment_Summary.Igst,
                    Vat: Product.Payment_Summary.Vatpercentage,
                    Total_Tax: Product.Payment_Summary?.Total_Tax,
                    Total_Net_Price: Product.Payment_Summary.Total_Net_Price,
                    Coupon_Discount: Product.Payment_Summary?.Meepaisa_Coupon_Amount,
                    Gift_Wrap_Amount: Product.Payment_Summary?.Gift_Amount,
                    Delivery_Person_Fee: Product.Payment_Summary?.Delivery_Fee,
                    Gross_Price: Product.Payment_Summary?.Order_Total_After_Discount,
                    Item_Master_Id: Product.Item_Product_Details[0]?.Item_Master_Id
                })
            })[0]
            )
        } else {
            setPageNotFoundStatus(true)
        }
    };

    const deliveryCommentsCall = async (id) => {
        try {
            const formData = { Order_Line_Item_Id: [id], User_Email_Id: EmailID };
            const response = await deliveryAgentReviewGet(formData);
            if (response.status && response.data) {
                const filteredData = response.data.results.filter(filtr => filtr.User_Email_Id === EmailID);
                setYourComments(filteredData);
                setDeliveryReviewsGet(filteredData);
            } else {
            }
        } catch (error) {
        }
    };

    const handelCancelModal = () => {
        setCancelModal(false)
    }

    const handelCancelModalOpen = () => {
        setCancelModal(true)
    }

    if (!pageNotFoundStatus)
        return (
          <Grid container p={{md:5,xs:2}}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: "10px" }} >
                        <Box>
                            <Typography sx={{ color: theme.palette.text.secondary, fontSize: '24px', fontWeight:'bold' }}>Order Details</Typography>
                        </Box>
                        {groceryProducts.length > 0 &&
                            groceryProducts.map((groceryProduct, index) => {
                                return <OrderDetailsCard key={index}
                                    Product_Image={groceryProduct?.Product_Image}
                                    Product_Id={groceryProduct?.Product_Id}
                                    Product_Name={groceryProduct?.Product_Name}
                                    Partner_Selling_Price={groceryProduct?.Partner_Selling_Price}
                                    Order_Id={productNewdata?.Order_Id}
                                    Order_Date={groceryProduct?.Ordered_Date}
                                    Quantity={groceryProduct?.Quantity}
                                    Partner_Name={groceryProduct?.Partner_Name}
                                    customerName={productNewdata?.Customer_Name}
                                    cAddress={productNewdata?.Customer_Address}
                                    cPinCode={productNewdata?.Customer_PinCode}
                                    cPhoneNumber={productNewdata?.Customer_Mobile}
                                    Order_Status={productNewdata?.Order_Status}

                                    billingName={productNewdata?.Billing_Name}
                                    billingAddress={productNewdata?.Billing_Address}
                                    billingPinCode={productNewdata?.Billing_Pincode}
                                    billingPhoneNumber={productNewdata?.Billing_Mobile}

                                    agentName={productNewdata?.Agent_Name}
                                    agentPhoneNumber={productNewdata?.Agent_Mobile}
                                    agentProfileImage={productNewdata?.Agent_Photo}

                                    item={productNewdata}
                                    id={id}
                                    EmailID={EmailID}
                                    type={"grocery"}
                                />
                            })
                        }

                        <Box sx={{ textAlign: "right", width: "60%", display: "flex", justifyContent: "flex-end" }}>
                            {
                                !["DELIVERED", "CANCELLED", "RETURN"].includes(productNewdata?.Order_Status?.toUpperCase()) &&
                                <Button type="button" variant="contained" size="medium" onClick={handelCancelModalOpen} >Cancel</Button>
                            }
                        </Box>

                    </Box>


                    <GroceryShippingAddressComponent
                        Order_Id={productNewdata?.Order_Id}
                        Order_Date={productNewdata?.Order_Date}

                        customerName={productNewdata?.Customer_Name}
                        cAddress={productNewdata?.Customer_Address}
                        cPinCode={productNewdata?.Customer_PinCode}
                        cPhoneNumber={productNewdata?.Customer_Mobile}
                        Order_Status={productNewdata?.Order_Status}
                        Coupon_Discount={productNewdata?.Coupon_Discount}
                        billingName={productNewdata?.Billing_Name}
                        billingAddress={productNewdata?.Billing_Address}
                        billingPinCode={productNewdata?.Billing_Pincode}
                        billingPhoneNumber={productNewdata?.Billing_Mobile}
                        Partner_Coupon_Amount={productNewdata?.Partner_Coupon_Amount}

                        agentName={productNewdata?.Agent_Name}
                        agentPhoneNumber={productNewdata?.Agent_Mobile}
                        agentProfileImage={productNewdata?.Agent_Photo}

                        item={productNewdata}
                        id={id}
                        EmailID={EmailID}
                    />
                </Grid>


                {/* Right Column: OrderStatus and Payment Summary */}
                <Grid item xs={12} md={6} sx={{ mt: { md: '50px', sm: '50px', xs: '0px' } }}>
                    <OrderStepper status={productNewdata?.Order_Status || ""} Line_Item_Order_Status={productNewdata?.Line_Item_Order_Status} />
                    <Typography sx={{ fontSize: { md: '30px', xs: '16px' }, marginTop: { md: '40px', sm: '40px', xs: '50px' } }}>
                        <strong>Payment Summary</strong>
                    </Typography>
                    <PaymentSummary
                        payment={{}}
                        Order_Id={productNewdata?.Order_Id}
                        Partner_Selling_Price={productNewdata?.Partner_Selling_Price}
                        Quantity={productNewdata?.Quantity}
                        id={id}
                        EmailID={EmailID}
                        Igst={productNewdata?.Igst}
                        Total_Tax={productNewdata?.Total_Tax}
                        Gift_Wrap_Amount={productNewdata?.Gift_Wrap_Amount}
                        Delivery_Person_Fee={productNewdata?.Delivery_Person_Fee}
                        Coupon_Discount={productNewdata?.Coupon_Discount}
                        Gross_Price={productNewdata?.Gross_Price}
                        Order_Status={productNewdata?.Order_Status}
                        Partner_Coupon_Amount={productNewdata?.Partner_Coupon_Amount}
                        groceryProducts={groceryProducts}
                        Vat={productNewdata?.Vat}
                        VatAmount={productNewdata?.VatAmount}
                        IgstAmount={productNewdata?.IgstAmount}
                        Total_Net_Price={productNewdata?.Total_Net_Price}
                        isGrocery={true}
                    />
                    {(deliveryReviewsGet.length === 0 && !isEmpty(productNewdata?.Agent_Mobile)) && (
                        <Grid>
                            <DeliveryAgentReview data={productNewdata} EmailID={EmailID} setDeliveryReviewsGet={setDeliveryReviewsGet} deliveryCommentsCall={deliveryCommentsCall} />
                        </Grid>
                    )}

                    <Grid>
                        {Boolean(yourComments[0]) &&
                            <DeliveryReviews
                                rating={yourComments[0]?.Rating}
                                comments={yourComments[0]?.Comments}
                            />}
                    </Grid>
                </Grid>

                <CancelModalComp cancelModal={cancelModal} handelCancelModal={handelCancelModal} id={id} Order_Id={productNewdata?.Order_Id} productsCall={productsCall} />

            </Grid>
        );
    else
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                <Typography sx={{ fontSize: "30px", fontWeight: "bold" }} >Page Not Found</Typography>
            </Box>
        )
};

export default OrdersDetailsPage;

