//header
import React, { useState, useMemo, useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Autocomplete, InputAdornment, Link, Menu } from '@mui/material';
import { useTheme } from '@emotion/react';
import useScreenSize from '../../utils/ScreenWidthHight';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { fetchSearchResults } from '../../store/slices/retail/home/SearchSlice';
import { fetchAddress, fetchHeaderAddress } from '../../store/slices/accounts/Address/Address';
import { ProfileDropdown } from './HeaderAccount';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import appleIcon from '../../assests/images/appleiconinheader.png'
import { isEmpty } from '../../utils';
import { addressSelection } from '../../store/slices/CommonSlice';


const Header = ({ pageModalOpen, setPageModalOpen, type = "grocery" }) => {
  const dispatch = useDispatch();
  const locations = useSelector(state => state.addressProfiles.addressProfile);
  const searchResult = useSelector(state => state.searchResults);
  const authDetails = useSelector(state => state.auth)
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated) || false
  const authData = useSelector(state => state.auth.user)
  const [locationOpen, setLocationOpen] = useState(false);
  const navigate = useNavigate()
  const auth = useSelector(state => state.auth)
  const [personName, setPersonName] = useState(type === "grocery" ? "Grocery" : type !== "retail" ? type : "Retail");
  const [searchOpen, setSearchOpen] = useState(false);
  const theme = useTheme();
  const winSize = useScreenSize();
  const [settings] = useState(['Setting1', 'Setting2']);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [value, setValue] = useState(null);
  const [locationSelected, setLocationSelected] = useState({ title: "", link: "true" })
  const [headerAddress, setHeaderAddress] = useState([]);
  const { pathname } = useLocation()
  const address = useSelector(state => state.commonReducer.Address)



  useEffect(() => {
    if (authDetails?.isAuthenticated) {
      dispatch(fetchSearchResults({ name: "", Business_Type: type == "grocery" ? ["GROCERY"] : ["RETAIL", "MFH"] }));
    }
  }, [authDetails?.isAuthenticated, pathname]);

  useEffect(() => {
    if (authDetails?.isAuthenticated) {
      fetchHeaderAddressCall();
    }
  }, [authDetails?.isAuthenticated]);

  const fetchHeaderAddressCall = async () => {
    try {
      const response = await fetchHeaderAddress({
        formData: { "User_Email_Id": authDetails?.user?.Email_Id },
        pagination: { PageNo: 1, PageSize: 50 }
      });
      if (response.status) {
        setHeaderAddress(response.data);
      } else {
        console.error('Failed to fetch header address:', response.error);
      }
    } catch (error) {
      console.error('Error fetching header address:', error);
    }
  };
  const handleChange = (event) => {
    const { value } = event.target;
    setPersonName(value);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const formik = useFormik({
    initialValues: {
      loc: ''
    }
  });

  const HtmlTooltip = useMemo(() => styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: theme.typography.pxToRem(10),
      border: "1px solid #dadde9",
      width: "130px",
      height: "280px",

      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none' /* Hide scrollbar for Chrome, Safari, and Opera */
      },
      scrollbarWidth: 'none',

      [theme.breakpoints.down("xs")]: {
        minWidth: "100px",
        minHeight: "100px"

      },
      [theme.breakpoints.up("sm")]: {
        minWidth: "220px",
        minHeight: "220px"

      },
      [theme.breakpoints.up("md")]: {
        minWidth: "130%",
        minHeight: '130%'
      },
    },
  })), [isAuthenticated]);

  const handleNavigate = (value) => {
    navigate(value);
  };




  const handleOptionSelect = (event, newValue) => {

    if (newValue) {

      // setValue(null);
      if (newValue?.type === 'product') {
        navigate(`/${type.toLowerCase()}/products/` + newValue?.id);
      } else if (newValue?.type === 'brand') {
        navigate(`/${type.toLowerCase()}/brand/` + newValue?.id);
      } else if (newValue?.type === 'deal') {
        navigate(`/${type.toLowerCase()}/offers/Deals/` + newValue?.id);
      } else if (newValue?.type === 'coupon') {
        navigate(`/${type.toLowerCase()}/offers/coupons/` + newValue?.id);
      } else if (newValue?.type === 'partner') {
        navigate(`/${type.toLowerCase()}/partner/` + newValue?.id);
      }
    }
  };

  const headerRoute = (routable) => {
    if (validPath.includes(pathname.replace(`/${type.toLocaleLowerCase()}`, ""))) {
      navigate(pathname.replace(type.toLocaleLowerCase(), routable))
    }
    else {
      navigate("/" + routable + "/")
    }
  }

  return (
    <AppBar sx={{ maxWidth: winSize.screenSize.width, height: "60px", backgroundColor: theme.palette.header.backgroundcolor, boxShadow: "none" }}>
      <Box >
        <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: { xs: ((searchOpen || locationOpen) ? "flex-start" : "space-between"), sm: "space-between" }, alignItems: "center" }}  >
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: { xs: ((searchOpen || locationOpen) ? "auto" : "40%"), sm: 'unset' }, height: "60px", alignItems: "center", gap: .5 }} >
            <Link href={"/" + (type==="Gift" ? (type+"s").toLowerCase() : type.toLocaleLowerCase())}>
              <img src={require('../../assests/images/mee.png')} alt="" style={{ height: winSize.screenSize.width < 500 ? "42px" : "50px", width: winSize.screenSize.width < 500 ? "50px" : "66px", transform: winSize.screenSize.width < 500 && "translateY(3px)" }} />
            </Link>
            {/* Retail dropdown */}
            {
              (!searchOpen && !locationOpen) || winSize.screenSize.width >= theme.breakpoints.values.md ? (
                <Box className="retail"
                  sx={{
                    display: 'flex',
                    marginLeft: { xs: '10px', sm: '30px', md: '20px', lg: '30px', xl: '40px' },
                    flexDirection: 'row',
                    flexWrap: "wrap",
                    // "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    //   borderColor: theme.palette.input.borderColor
                    // },
                    "&:hover .MuiInputLabel-outlined": {
                      color: theme.palette.input.borderColor
                    },
                    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.input.borderColor
                    },
                    "& .MuiOutlinedInput-input": {
                      color: theme.palette.text.primary
                    },
                    position: "relative",
                  }}
                >
                  <Select
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    // IconComponent={(props) => <ExpandMore {...props} style={{ color: theme.palette.primary.main, cursor: "pointer" ,display:"flex",flexWrap:"wrap"}} />}
                    sx={{ height: 40, width: { md: winSize.screenSize.width < 1000 ? "auto" : winSize.getWidthForGiveInput(8), sm: "auto", xs: "auto" }, marginLeft: { xs: "0px", md: '36px' }, borderRadius: "8px" }}
                  >
                    <MenuItem onClick={() => { headerRoute("retail") }} value="Retail" sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
                        <img
                          src={require('../../assests/images/retailHeaderDropDownIcon.png')}
                          alt="Retail Icon"
                          style={{ width: '21px', height: '21px', marginRight: '4px' }}
                        />
                        <Typography style={{ fontSize: '17px', marginTop: '2px', color: theme.palette.text.secondary, }}>Retail</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem onClick={() => headerRoute("grocery")} value="Grocery">
                      <Box style={{ display: 'flex', alignItems: 'center', }}>
                        <img
                          src={require('../../assests/images/groceryHeaderDropDownIcon.png')}
                          alt="Retail Icon"
                          style={{ width: '21px', height: '21px', marginRight: '4px' }}
                        />
                        <Typography style={{ fontSize: '17px', marginTop: '2px', color: theme.palette.text.secondary, }}>Grocery</Typography>
                      </Box></MenuItem>
                    <MenuItem value="Services"><Box style={{ display: 'flex', alignItems: 'center', }}>
                      <img
                        src={require('../../assests/images/servicesHeaderDropDownIcon.png')}
                        alt="Retail Icon"
                        style={{ width: '21px', height: '21px', marginRight: '4px' }}
                      />
                      <Typography style={{ fontSize: '17px', marginTop: '2px', color: theme.palette.text.secondary, }}>Services</Typography>
                    </Box></MenuItem>
                    {
                      <MenuItem onClick={
                        isAuthenticated ? () => { navigate("/gifts") } : () => {
                          navigate('/auth/signin')
                        }} value="Gift"  ><Box style={{ display: 'flex', alignItems: 'center', }}>
                          <img
                            src={require('../../assests/images/giftHeaderDropDownIcon.png')}
                            alt="Retail Icon"
                            style={{ width: '21px', height: '21px', marginRight: '4px' }}
                          />
                          <Typography style={{ fontSize: '17px', marginTop: '2px', color: theme.palette.text.secondary, }}>Gift</Typography>
                        </Box></MenuItem>
                    }
                    <MenuItem onClick={() => { navigate("/rides/home") }} value="Rides"><Box style={{ display: 'flex', alignItems: 'center', }}>
                      <img
                        src={require('../../assests/images/ridesHeaderDropDownIcon.png')}
                        alt="Retail Icon"
                        style={{ width: '21px', height: '21px', marginRight: '4px' }}
                      />
                      <Typography style={{ fontSize: '17px', marginTop: '2px', color: theme.palette.text.secondary, }}>Rides</Typography>
                    </Box></MenuItem>
                  </Select>
                </Box>
              ) : null
            }

          </Box>

          <Box sx={{ width: { xs: ((searchOpen || locationOpen) ? "85%" : "50%"), md: "70%" }, height: "60px", display: "flex", flexDirection: "row", justifyContent: { xs: (winSize.screenSize.width < 400 ? "flex-end" : "flex-end"), md: "flex-end" }, alignItems: "center", columnGap: { xs: (winSize.screenSize.width < 400 ? "10px" : "10px") } }} >


            {/* search */}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
              {winSize.screenSize.width > 220 && searchOpen ? (
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '20px' }}>

                  <Autocomplete
                    value={value}
                    onChange={handleOptionSelect}

                    size="small"
                    variant="outlined"
                    options={searchResult?.searchResults || []}
                    getOptionLabel={(option) => option.name}
                    sx={{
                      "&:hover .MuiInputLabel-outlined": {
                        color: theme.palette.input.borderColor
                      },
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.input.borderColor
                      },
                      "& .MuiOutlinedInput-input": {
                        color: theme.palette.text.primary
                      },
                      width: { md: (winSize.screenSize.width < 1200 ? (winSize.screenSize.width < 1100 ? (winSize.screenSize.width < 1000 ? "110px" : "200px") : "300px") : "400px"), sm: "200px", xs: "170px" },
                      maxWidth: "100%",
                      '.MuiAutocomplete-paper::-webkit-scrollbar': {
                        display: 'none',
                      },
                      '.MuiAutocomplete-paper': {
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                      },
                      overflowY: "hidden"
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: '200px',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                          display: 'none !important',
                        },
                        msOverflowStyle: 'none !important',
                        scrollbarWidth: 'none !important',
                      },
                    }}
                    renderOption={(props, option) => {

                      return (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 }, display: "flex", width: "100%", justifyContent: "space-between", flexWrap: "wrap" }} {...props}
                        >
                          <Typography style={{ width: "80%", fontWeight: "bold" }}>{option?.name}</Typography>
                          <img
                            loading="lazy"
                            width="20"
                            src={option?.image_path}
                            alt=""
                          />
                          <Typography style={{ width: "80%" }}>{option?.type}</Typography>
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Search for products..."
                        onChange={(e) => {
                          dispatch(fetchSearchResults({ name: e.target.value, Business_Type: type === "grocery" ? ["GROCERY"] : ["RETAIL", "MFH"] }));
                        }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end">
                              <img
                                src={type === "grocery" ? require(`../../assests/images/grocerySearchActivepic.png`) : require(`../../assests/images/retailSearchActivepic.png`)}
                                alt="Search"
                                style={{ height: 40, width: 40, marginRight: -38.5, borderTopRightRadius: '4px', borderBottomRightRadius: '4px', cursor: 'pointer' }}
                                onClick={() => setSearchOpen(false)}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                </Box>
              ) :
                (
                  <IconButton onClick={() => {
                    if (locationOpen) {
                      setLocationOpen(false);
                    }
                    setSearchOpen(true);
                  }}
                    sx={{ padding: 0 }}
                  >
                    <img src={type === "grocery" ? require(`../../assests/images/grocerySearchInactivePic.png`) : require(`../../assests/images/retailSearchInactivePic.png`)} alt="Search" style={{ height: winSize.screenSize.width < 500 ? '35px' : "40px", width: winSize.screenSize.width < 500 ? '35px' : "40px", borderRadius: "50%" }} />
                  </IconButton>
                )
              }

              {/* links */}
              {winSize.screenSize.width > 800 &&
                <Link
                  sx={{
                    marginLeft: '30px',
                    textDecoration: 'none',
                    color: theme.palette.text.secondary,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    marginRight: '20px',
                    '&:hover': {
                      color: theme.palette.primary.main
                    }
                  }}
                  href={type === "grocery" ? "/grocery/shop" : "/retail/shop"}
                >
                  Shops
                </Link>}

              {winSize.screenSize.width > 800 &&
                <Link href={type === "grocery" ? "/grocery/offers/coupons" : "/retail/offers/coupons"}
                  sx={{
                    textDecoration: 'none',
                    color: theme.palette.text.secondary,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    marginRight: '20px',
                    '&:hover': {
                      color: theme.palette.primary.main
                    }
                  }}
                >
                  Offers
                </Link>}

              {winSize.screenSize.width > 900 &&
                <Link href='/accounts/contactus'
                  sx={{
                    textDecoration: 'none',
                    color: theme.palette.text.secondary,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    marginRight: '20px',
                    '&:hover': {
                      color: theme.palette.primary.main
                    }
                  }}
                >
                  Contact

                </Link>}

              {(winSize.screenSize.width > 260 && winSize.screenSize.width > 800) &&
                <Box>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }} >
                          {[{ title: "Tickets", link: "/accounts/tickets", visible: auth.isAuthenticated === null ? true : false },
                          { title: "Address", link: "/accounts/address", visible: auth.isAuthenticated === null ? true : false },
                          { title: "Notifications", link: "/accounts/Notification", visible: auth.isAuthenticated === null ? true : false },
                          { title: "News & Events", link: "/accounts/newsandevents", visible: false },
                          // { title: "Reviews&Rating", link: "/accounts/reviewsandrating", visible: auth.isAuthenticated === null ? true : false },
                          { title: "Faqs", link: "/accounts/faqs", visible: false },
                          { title: "Terms & condition", link: "/accounts/terms&conditions", visible: false },
                          { title: "Privacy Policy", link: "/accounts/privacypolicy", visible: false },
                          // { title: "Contactus", link: "/accounts/contactus", visible: false },
                          { title: "Delete Account", link: "/accounts/deleteaccount", visible: auth.isAuthenticated === null ? true : false },
                          { title: "Cart", link: "/accounts/cart", visible: auth.isAuthenticated === null ? true : false },
                          { title: "Refer & Earn", link: "/accounts/refer&earn", visible: auth.isAuthenticated === null ? true : false },
                          { title: "Wallet", link: "/accounts/wallet", visible: auth.isAuthenticated === null ? true : false },
                          { title: "Orders", link: type === "grocery" ? "/accounts/grocery/orders" : "/accounts/orders", visible: auth.isAuthenticated === null ? true : false },
                            { title: "WishList", link: type === "grocery" ? "/accounts/grocery/wishlist" : "/accounts/wishlist", visible: auth.isAuthenticated === null ? true : false }
                          ]
                            .map((element, index) => {
                              if (!element.visible)
                                return (
                                  <Typography
                                    key={index}
                                    sx={{
                                      marginTop: "15px",
                                      marginBottom: "11px",
                                      cursor: "pointer",
                                      transition: "color 0.3s, margin-left 0.1s, font-weight 0.3s",

                                      '&:hover': {
                                        color: theme.palette.primary.main,
                                        marginLeft: "4px"
                                      }
                                    }}
                                    onClick={() => { navigate(element.link) }}
                                  >
                                    {element.title}
                                  </Typography>
                                )
                            }
                            )
                          }
                        </Box>
                      </React.Fragment>
                    }
                  >
                    {winSize.screenSize.width > 800 &&

                      <Link
                        sx={{
                          textDecoration: 'none',
                          marginRight: '30px',
                          color: theme.palette.text.secondary,
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': {
                            color: theme.palette.primary.main,
                          },
                        }}
                      >
                        Pages
                        <ExpandMore sx={{ marginLeft: '8px' }} />
                      </Link>
                    }

                  </HtmlTooltip>
                </Box>

              }
            </Box>

            {winSize.screenSize.width > 120 && (
              <Box className="retail" sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: "wrap",
                // mr: '30px',


                "&:hover .MuiInputLabel-outlined": {
                  color: theme.palette.input.borderColor
                },
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.input.borderColor
                },
                "& .MuiOutlinedInput-input": {
                  color: theme.palette.text.primary
                },
              }}>
                {locationOpen ? (
                  <Autocomplete
                    size="small"
                    options={[...headerAddress, { title: "Add New Address", link: true }] || []}
                    // value={locationSelected}
                    onChange={(e, value) => {
                      if (!isEmpty(value)) {
                        dispatch(addressSelection(value))
                      }
                    }}
                    getOptionLabel={(option) => option?.link ? `${option?.title}` : `${option?.City} - ${option?.Street || ""}- ${option?.Zip || ""}`}
                    sx={{
                      width: { md: '210px', xs: "180px" },
                      '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                        color: theme.palette.primary.main,
                        padding: 0
                      },

                    }}

                    value={!isEmpty(address) ? address : locationSelected}
                    renderOption={(props, option) => {
                      if (option?.link) {
                        return (
                          <Link href="/accounts/address" {...props} sx={{ textDecoration: "none", }} >{option?.title}</Link>
                        )
                      }
                      else
                        return (
                          <Box {...props}>
                            {`${option?.City} - ${option?.Street || ""}- ${option?.Zip || ""}`}
                          </Box>
                        )
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ padding: 0 }}
                        placeholder="Search Location"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end" sx={{ padding: 0, width: "0px", marginRight: locationSelected.link ? "-28px" : "-28px" }} >
                              <img
                                src={type === "grocery" ? require(`../../assests/images/groceryLocationActivePic.png`) : require(`../../assests/images/retailLocationActivePic.png`)}
                                alt="Search"
                                style={{ height: 40, width: 40, borderTopRightRadius: '4px', borderBottomRightRadius: '4px', cursor: 'pointer' }}
                                onClick={() => setLocationOpen(false)}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                ) : (

                  <IconButton onClick={() => {
                    if (searchOpen) {
                      setSearchOpen(false);
                    }
                    setLocationOpen(true);
                  }}
                    sx={{ padding: 0 }}
                  >
                    <img src={type === "grocery" ? require(`../../assests/images/groceryLocationInactivePic.png`) : require(`../../assests/images/retailLocationInactiveImage.png`)} alt="Search" style={{ height: winSize.screenSize.width < 500 ? '35px' : "40px", width: winSize.screenSize.width < 500 ? '35px' : "40px", borderRadius: "50px", objectFit: "contain" }} />
                  </IconButton>
                )}
              </Box>
            )}

            {/* Icons */}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
              <ProfileDropdown isAuthenticated={isAuthenticated} authData={authData} setPageModalOpen={setPageModalOpen} />
              <Menu
                sx={{ mt: '45px' }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Box >
    </AppBar >
  );
}

export default Header;

export const validPath = [
  '/offers/coupons',
  '/offers/deals',
  '/brand',
  "/partner",
  "/category",
  "/subCategory",
  "/products",
  "/brandpage",
  "/shop",
  "/wishlist"
]