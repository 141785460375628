import React, { useEffect, useRef, useState } from 'react'
import { Box, CardMedia, IconButton, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import ProductPartnerCard from '../../components/cards/ProductPartnerCard'
import { useLocation, useNavigate } from 'react-router-dom'
import { productCheckCartAction } from '../../../store/slices/retail/ProductDetails/ProductDetailsActions'
import { isEmpty } from '../../../utils'
import useScreenSize from '../../../utils/ScreenWidthHight'
const ProductPartners = ({ partnerDetails, size, email }) => {
    const theme = useTheme()
    const arrowIcon = require('../../../assests/images/right-arrow.png')
    const partnerRef = useRef()
    const navigate = useNavigate()
    const winsizes=useScreenSize()
    const pathname = useLocation().pathname
    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "flex-start" }} >
                <Typography sx={{ color: theme.palette.text.primary, width: "100%",...(winsizes.screenSize.width<600 ? {fontSize:"12px",alignSelf:"center"}:{}) }} >Grab this offer from Meepaisa preferred partners</Typography>
                <IconButton onClick={() => {
                    if (partnerRef.current) {
                        const container = partnerRef.current
                        const scrollWidth = container.scrollWidth - container.clientWidth;
                        container.scrollTo({
                            left: scrollWidth,
                            behavior: 'smooth'
                        });

                    }
                }} >
                    <img
                        src={arrowIcon}
                        style={{ width: 24, height: 24 }}
                    />
                </IconButton>
            </Box>
            <Box sx={{
                width: "100%", display: "flex", flexDirection: "row", gap: { xs: .5, md: 3 }, overflowX: "scroll",
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
            }}
                ref={partnerRef}
            >
                {
                    partnerDetails?.length > 0 ?
                        partnerDetails.map((partner, index) => (
                            <ProductPartnerCard
                                key={partner.partnerId + partner.Partner_Product_Id + size?.productSize}
                                image={partner.image}
                                brandName={partner.partnerName}
                                partnerSellingPrice={partner.partnerSellingPrice}
                                onClick={() => { navigate((pathname.includes('retail')?'/retail/partner/':'/grocery/partner/') + partner.partnerId) }}
                                formData={{
                                    Partner_Details_Id: partner.partnerId,
                                    Partner_Product_Id: partner.Partner_Product_Id,
                                    Quantity: 1,
                                    Size: size?.productSize,
                                    User_Email_Id: email
                                }}
                                Availability_Stock={partner?.Availability_Stock}
                                details={partner}
                                handleAddCart={() => {

                                }}
                            />
                        ))
                        :

                        (isEmpty(size) ? <Typography sx={{ alignSelf: "center" }} >No Stock Available</Typography> : <Typography sx={{ alignSelf: "center" }} >No Stock Available</Typography>)
                }
            </Box>
        </Box>
    )
}

export default ProductPartners