import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { nodeApi } from '../../CommonAxios'

const initialState = {
    pickUp: [],
    loading: false,
    error: ""
}

export const getPickUp = createAsyncThunk("pickUp/getPickUp", async () => {
    const response = await nodeApi.post("/Home/Brand/Get", {})
    const data = response?.data
    if (data.Success_Response.Is_Data_Exist == "0") {
        return []
    } else {
        return data.results
    }
})


const pickUpSlice = createSlice({
    name: 'pickUp',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(getPickUp.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getPickUp.fulfilled, (state, action) => {
                state.loading = false
                state.pickUp = action.payload;
            })
            .addCase(getPickUp.rejected, (state, action) => {
                state.loading = false
                state.error = [];
                state.error = action.error;
            })
    }
})

export default pickUpSlice.reducer;