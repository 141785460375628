import { Box, CardMedia, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { fetchReturnPolicy } from '../../../store/slices/retail/home/PrivacyPolicy';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser'
import { useTheme } from '@emotion/react';
import useScreenSize from '../../../utils/ScreenWidthHight';
import { Container } from '@mui/system';

const ReturnPolicy = () => {
    const winSize = useScreenSize();
    const copyrights = useSelector(state => state.privacypolicy.returnPolicy);
    const dispatch = useDispatch();
    const theme = useTheme()

    useEffect(() => {
        dispatch(fetchReturnPolicy({ Name: 'ReturnPolicy' }));
    }, []);
    return (
        <Box>
            {/* Left Side */}
            <Box sx={{ display: "flex", flexDirection: "row" }}>

                <Box sx={{ display: "flex", justifyContent: { md: "center", xs: 'left' }, width: "100%", alignItems: "center", gap: 1 }} >
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: { md: "flex-end", xs: 'flex-start' }, gap: 1 }} >
                        <Typography sx={{ display: 'flex', color: theme.palette.extra.primary, fontSize: { md: '96px', sm: '96px', xs: "34px" }, fontWeight: 'bold', ml: { md: '0px', sm: '0px', xs: '26px' } }}>
                        Return Policy
                        </Typography>
                    </Box>

                </Box>
                {winSize.screenSize.width > 1050 &&
                    <Box sx={{ flex: '1' }}>
                        <CardMedia
                            component="img"
                            image={require("../../../assests/images/tickets.png")}
                            sx={{
                                width: '611px',
                                height: '499px',
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "wrap"
                            }}>
                        </CardMedia>
                    </Box>}
            </Box>
            {
                copyrights?.map((strategy, index) => {
                    return (
                    <Box key={index} sx={{ width: { md: '100%', sm: '100%', xs: '90%' }, display: 'flex', justifyContent: 'center', alignItems: 'center', ml: { md: '0px', sm: '0px', xs: '20px' } }} >
                    <Container sx={{ border: "0.4px solid", borderColor: theme.palette.text.secondary, borderRadius: "24px", boxShadow: "0 4px 4px 0 grey" }} key={index}>
                        {parse(strategy?.Description)}
                    </Container>
                    </Box>
                )})
            }
        </Box >
    );
};

export default ReturnPolicy;
