import { Box, Slider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AllProductsActions } from '../../../../store/slices/retail/home/AllProductsSlice';

const DiscountsFilter = ({ name }) => {
    const [value, setValue] = useState([0, 100]);
    const [marks] = useState([
        {
            value: 0,
            label: '0%',
        },
        {
            value: 100,
            label: '100%',
        },
    ]);

    const { filters: { Min_Discount, Max_Discount }, loading } = useSelector((state) => state.allProducts);
    const dispatch = useDispatch();

    useEffect(() => {
        if (Min_Discount == 0, Max_Discount == 0) {
            setValue([0, 100])
        } else {
            setValue([Min_Discount, Max_Discount])
        }
    }, [Min_Discount, Max_Discount])

    const valuetext = (value) => {
        return `${value}%`;
    };

    const handleChange = (event, newValue) => {
        dispatch(AllProductsActions.updateFilter({ Min_Discount: newValue[0], Max_Discount: newValue[1] }))
    };

    return (
        <Box sx={{ width: "90%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "20px" }}>
                {/* <Box sx={{ width: 48, height: 48, borderRadius: 100 }}>
                    <img src={require('../../../../assests/images/mee.png')} style={{ width: "100%", height: "100%" }} alt="icon" />
                </Box> */}
                <Typography sx={{fontWeight:"700"}} >{name}</Typography>
            </Box>
            <Box sx={{ width: "90%", alignSelf: "flex-end" }}>
                <Slider
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    onChangeCommitted={valuetext}
                    marks={marks}
                    min={0}
                    max={100}
                />
            </Box>
        </Box>
    );
}

export default DiscountsFilter