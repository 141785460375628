import { useState, useEffect, useMemo, useCallback } from 'react';

export const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setScreenSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getHalfScreenSize = useMemo(() => {
        return {
            width: screenSize.width / 2,
            height: screenSize.height / 2,
        };
    }, [screenSize]);

    const getThreeQuarterScreenSize = useMemo(() => {
        return {
            width: screenSize.width * 3 / 4,
            height: screenSize.height * 3 / 4,
        };
    }, [screenSize]);

    const getHalfScreenWidthSize = useMemo(() => {
        return screenSize.width / 2
    }, [screenSize]);

    const getThreeQuarterScreenWidthSize = useMemo(() => {
        return screenSize.width * 3 / 4
    }, [screenSize]);

    const getQuarterScreenWidthSize = useMemo(() => {
        return screenSize.width * 1 / 4
    }, [screenSize]);

    const getWidthForGiveInput = useCallback((input) => {
        return screenSize.width / input
    }, [screenSize]);

    // Add more custom functions as needed

    return {
        screenSize,
        getHalfScreenSize,
        getThreeQuarterScreenSize,
        getQuarterScreenWidthSize,
        getHalfScreenWidthSize,
        getThreeQuarterScreenWidthSize,
        getWidthForGiveInput
    };
};

export default useScreenSize;
