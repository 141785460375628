import React from 'react';
import { Box, CardMedia, Typography } from "@mui/material";
import Rating from '@mui/material/Rating';
import logo from '../../../../assests/images/mee.png'
import parse from 'html-react-parser';
import { useTheme } from '@emotion/react';
import useScreenSize from '../../../../utils/ScreenWidthHight';

const BrandReviewPage = ({ imagePath, imageName, brandName, description, rating, date, review }) => {
    const theme = useTheme();
    const winsize = useScreenSize()
    return (
        <>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%"

            }}>

                <CardMedia
                    component="img"
                    image={imagePath}
                    alt={imageName}
                    sx={{ maxWidth: "295px", height: "222px", display: "flex", justifyContent: "center", marginTop: "2px", objectFit: "fill" }}
                />
            </Box>
            <Typography variant="h4" sx={{ display: "flex", justifyContent: "center", fontWeight: "bold", color: "rgba(0, 0, 0, 0.6)" }} >{brandName}</Typography>
            <Box sx={{ color: theme.palette.text.secondary, fontSize: '20px' }}>
                {parse(description)}
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", mb: {xs:1,sm:2}, marginTop: "50px" }}>
                <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", position: "relative", mb: { xs: 0, sm: 0 },...(winsize.screenSize.width<600?{columnGap:"10px"}:{}) }}>
                    <img src={logo} alt="Logo" style={winsize.screenSize.width < 600 ? { width: "41px", height: "41px", borderRadius: "10px", objectFit: 'fill', marginRight: 1 } : { width: "81px", height: "61px", borderRadius: "10px", objectFit: 'fill', marginRight: 1 }} />
                    <Box sx={winsize.screenSize.width < 600 ?{display:"flex",flexDirection:"column",alignItems:"flex-start"} :{ display: "flex", flexDirection: "column", flex: "1 1 auto", }}>
                        <Typography variant="h6" sx={
                            winsize.screenSize.width < 600 ? {
                                fontWeight: "bold", color: theme.palette.text.secondary,
                                fontSize:"1rem"
                            }
                                :
                                {
                                    fontWeight: "bold", color: theme.palette.text.secondary
                                }
                        }>MEEPAISA REVIEW</Typography>
                        <Rating name="rating" value={rating} readOnly sx={ winsize.screenSize.width < 600 ? {transform:"scale(80%) translateX(-15px)"} : {}} />
                    </Box>
                </Box>
                {/* <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.6)", flex: { xs: "auto", sm: "1 1 auto" }, textAlign: { xs: "left", md: "right" } }}>{date}</Typography> */}

            </Box>
            <Typography  sx={{ fontSize:'20px',color: theme.palette.text.secondary }}>
                {parse(review)}
            </Typography>
        </>

    );
};

export default BrandReviewPage;
