import React, { useContext, useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Box,
    Button,
    TextField,
    FormHelperText,
    Snackbar,
    InputAdornment,
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SearchIcon from "../../../src/assests/images/search.png";
import { Pagination as PN } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { referAndEarn, referAndEarnCreate } from "../../store/slices/accounts/ReferAndEarnSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { envConfig } from "../../store/slices/CommonAxios";
import { RWebShare } from "react-web-share";
import { useTheme } from "@emotion/react";
import noReferalls from '../../assests/images/noReferrals.png'
import { GlobalContext } from "../../Context";
import useScreenSize from "../../utils/ScreenWidthHight";


const ReferAndEarn = () => {
    const dispatch = useDispatch();
    const winSize = useScreenSize();
    const theme = useTheme();
    const { referNEarn , loading} = useSelector((state) => state.referAndEarn);
    const userDetails = useSelector((state) => state.auth);
    const isAuthenticated = userDetails.isAuthenticated;
    const [pageNo, setPageNo] = useState(1)
    const { Pagination } = useSelector(state => state.referAndEarn);
    const [searchTerm, setSearchTerm] = useState(""); // State to store search term
    const BASEURL = envConfig.BASEURL;
    const { toolTipActive } = useContext(GlobalContext)
    const EmailID = useSelector((state) => state.auth.user?.Email_Id);
    const user = useSelector((state) => state.auth.user);
    const { pathname } = useLocation();
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);
    const navigate=useNavigate()
    useEffect(() => {
        if (searchParams.get("PageNo")) {
            dispatch(referAndEarn({
                formData: { User_Email_Id: userDetails?.user?.Email_Id, Search: searchTerm },
                Pagination: { PageNo: searchParams.get("PageNo"), PageSize: 10, SortBy: "Created_Date", SortOrder: "DESC" }
            }));
        }
        else {
            {
                let querys = "";
                searchParams.forEach((value, key) => {
                    querys += "&" + key + "=" + value
                });
                navigate('/accounts/refer&earn?PageNo=1' + querys, { replace: true });
            }
        }
    }, [userDetails, searchTerm, searchParams, pathname]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        updateQueryParam([{ "PageNo": 1 }])
    };

    const initialValues = {
        Amount: '',
        Email_Id: '',
        Message: '',
        Mobile: '',
        Name: '',
        Status: '',
        URL: `${BASEURL}/auth/signup?referral=${user.User_Details_Id}`,
        User_Email_Id: EmailID,
        Reference_Id: user.User_Details_Id,
    };

    const FormValidationSchema = Yup.object().shape({
        Name: Yup.string()
        .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9 !@#$%^&*()\-_=+{};:,.<>?~'"|[\]\\/]+$/, 'Please use a combination of letters, numbers, and specific symbols. Avoid using only numbers or special characters.')
            .required("Friend's Name is required."),
        Message: Yup.string()
            .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9 !@#$%^&*()\-_=+{};:,.<>?~'"|[\]\\/]+$/, 'Please use a combination of letters, numbers, and specific symbols. Avoid using only numbers or special characters.')
            .required('Referral Message is required.'),

        Email_Id: Yup.string()
            .email('Please enter a valid Email.')
            .test(
                'oneOfRequired',
                "Friend's Email ID is required.",
                function (item) {
                    return this.parent.Email_Id || this.parent.Mobile;
                }
            ),
        Mobile: Yup.string().test(
            'oneOfRequired',
            "Friend's Mobile is required.",
            function (item) {
                return this.parent.Email_Id || this.parent.Mobile;
            }
        ).min(10, "Please enter valid mobile number.")
            .matches(/^[0-9]+$/, 'Mobile number must only contain numerics')
            .max(10, "Please enter valid mobile number."),
    });

    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            setSubmitting(true);
            values.User_Email_Id = EmailID;
            if (values.Email_Id !== "") {
                await dispatch(referAndEarnCreate({
                    payload: {
                        Email_Id: values.Email_Id,
                        Amount: values.Amount,
                        Message: values.Message,
                        Name: values.Name,
                        Status: values.Status,
                        URL: values.URL,
                        User_Email_Id: values.User_Email_Id,
                        Reference_Id: values.Reference_Id
                    },
                    toolTipActive: toolTipActive
                })).unwrap();
            } else {
                values.Email_Id = "";
                await dispatch(referAndEarnCreate(
                    {
                        payload: {
                            Amount: values.Amount,
                            Message: values.Message,
                            Mobile: "+91 " + values.Mobile,
                            Name: values.Name,
                            Status: values.Status,
                            URL: values.URL,
                            User_Email_Id: values.User_Email_Id,
                            Reference_Id: values.Reference_Id
                        },
                        toolTipActive: toolTipActive
                    }
                )).unwrap();
            }
            resetForm();
            dispatch(referAndEarn({
                formData: { User_Email_Id: userDetails?.user?.Email_Id },
                Pagination: { PageNo: pageNo, PageSize: 10, SortBy: "Created_Date", SortOrder: "DESC" }
            }));
        } catch (error) {
            console.error("Error submitting form:", error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ minHeight: "100vh", width: "100%" }} >

            {winSize.screenSize.width > 900 ?
                (<Grid container spacing={2} sx={{ mt: '10px' }}>
            <Grid item xs={12} md={5.5} style={{ display: "flex", flexDirection: "column", marginLeft: '20px' }}>
                <Typography variant="h5" gutterBottom style={{ fontWeight: "bold", color: theme.palette.text.secondary }}>
                    History
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        size="medium"
                        placeholder="Search Name"
                        value={searchTerm}
                        onChange={handleSearch}
                        sx={{ width: '70%' }}
                    />
                    <img src={SearchIcon} alt="Search Icon" style={{ width: '55px', height: '55px', marginTop: '7px', borderRadius: '4px' }} />
                </Box>
                <Box sx={{ flex: 1, justifyContent: "flex-start", display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", }} >
                        <Grid item xs={2}>
                            <Typography variant="body1" style={{ fontWeight: "bold", color: theme.palette.text.secondary }}>Name</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" style={{ fontWeight: "bold", color: theme.palette.text.secondary }}>Mobile/E-Mail</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" style={{ textAlign: 'center', fontWeight: "bold", color: theme.palette.text.secondary }}>Amount</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" style={{ textAlign: 'center', fontWeight: "bold", color: theme.palette.text.secondary ,}}>Sign-Up Status</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" style={{ textAlign: 'center', fontWeight: "bold", color: theme.palette.text.secondary }}>Payment Status</Typography>
                        </Grid>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around", rowGap: 3, alignItems: "flex-start" }} >
                        {
                            referNEarn?.length > 0 ?
                                referNEarn?.map((history, index) => (
                                    <Box key={index} sx={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-around" }} >
                                        <Box sx={{ width: "25%", }}>
                                            <Typography variant="body2" sx={{ whiteSpace: "nowrap", display: { xs: "block", md: "none", sm: "none" } }} >{history?.Name?.length > 8 ? history.Name.substring(0, 8) + "..." : history.Name}</Typography>
                                            <Typography variant="body2"  sx={{ whiteSpace: "nowrap", display: { xs: "none", md: "block", sm: "block" } }} >{history?.Name?.length > 15 ? history.Name.substring(0, 15) + "..." : history.Name}</Typography>
                                        </Box>
                                        <Box sx={{ width: "25%" }}  >

                                            <Typography variant="body2"  sx={{ whiteSpace: "nowrap", display: { xs: "block", md: "none", sm: "none" } }} >
                                                {history?.Mobile ?
                                                    (history.Mobile.length > 8 ? history.Mobile.substring(0, 8) + "..." : history.Mobile) :
                                                    (history?.Email_Id ?
                                                        (history.Email_Id.length > 8 ? history.Email_Id.substring(0, 8) + "..." : history.Email_Id) :
                                                        "")
                                                }
                                            </Typography>
                                            <Typography variant="body2"  sx={{ whiteSpace: "nowrap", display: { xs: "none", md: "block", sm: "block" } }} >
                                                {history?.Mobile ?
                                                    (history.Mobile.length > 15 ? history.Mobile.substring(0, 15) + "..." : history.Mobile) :
                                                    (history?.Email_Id ?
                                                        (history.Email_Id.length > 15 ? history.Email_Id.substring(0, 15) + "..." : history.Email_Id) :
                                                        "")
                                                }
                                            </Typography>
                                        </Box>
                                        <Box sx={{ width: "25%", textAlign: 'center' }}  >
                                            <Typography variant="body2" >  &#8377; {history.Amount}</Typography>
                                        </Box>
                                        <Box sx={{ width: "25%", textAlign: 'center' }}  >
                                            <Typography variant="body2" >{history.Sign_Up_Status}</Typography>
                                        </Box>
                                        <Box sx={{ width: "25%", textAlign: 'center' }}  >
                                            <Typography  variant="body2" color={
                                                history.Payment_Status === 'Settled' ? theme.palette.success.light :
                                                    history.Payment_Status === 'Pending' ? theme.palette.warning.light : theme.palette.error.main
                                            }>
                                                {history.Payment_Status}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))
                                :
                                <img src={noReferalls} style={{ marginTop: '20px', width: '40%', display: 'block', margin: '20px auto' }} />

                        }
                    </Box>
                </Box>
                {Pagination?.TotalPages > 1 ? <Box sx={{ display: 'flex', justifyContent: "center", width: "100%", }} >
                    <PN page={Pagination?.PageNo} onChange={(e, page) => {
                        updateQueryParam([{ PageNo: page }])
                    }} count={Pagination?.TotalPages} color="primary" />
                </Box> : null}

            </Grid>
            <Grid item xs={12} md={6} style={{ display: "flex", flexDirection: "column", }}>
                <Typography variant="h6" gutterBottom style={{ textAlign: "end", fontWeight: "bold", color: theme.palette.text.secondary }}>
                    My Referrals
                </Typography>
                <Typography variant="h6" gutterBottom style={{ fontWeight: "bold", color: theme.palette.text.secondary }}>
                    Refer & Earn
                </Typography>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={FormValidationSchema}
                >
                    {(props) => (
                        <Box >
                        <Form>
                            {/* Friend's Name */}
                            <Field
                                as={TextField}
                                name="Name"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="Friend's Name"
                                value={props.values.Name}
                                onChange={props.handleChange}
                                error={Boolean(props.touched.Name && props.errors.Name)}
                            />
                            <FormHelperText error={Boolean(props.touched.Name && props.errors.Name)}>
                                {Boolean(props.touched.Name && props.errors.Name) && <ErrorMessage name="Name" />}
                            </FormHelperText>


                            {/* Referral Message */}
                            <Field
                                as={TextField}
                                name="Message"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="Referral Message"
                                value={props.values.Message}
                                onChange={props.handleChange}
                                error={Boolean(props.touched.Message && props.errors.Message)}
                            />
                            <FormHelperText error={Boolean(props.touched.Message && props.errors.Message)}>
                                {Boolean(props.touched.Message && props.errors.Message) && <ErrorMessage name="Message" />}
                            </FormHelperText>


                            {/* Friend's Mobile */}
                            <Field
                                as={TextField}
                                name="Mobile"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="Friend's Mobile"
                                value={props.values.Mobile}
                                onChange={props.handleChange}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+91 </InputAdornment>,
                                }}
                                error={Boolean(props.touched.Mobile && props.errors.Mobile)}

                            />
                            <FormHelperText error={Boolean(props.touched.Mobile && props.errors.Mobile)}>
                                {Boolean(props.touched.Mobile && props.errors.Mobile) && <ErrorMessage name="Mobile" />}
                            </FormHelperText>


                            <Box sx={{ textAlign: "center" }}>
                                <Typography>--------OR ----------</Typography>
                            </Box>
                            {/* Friend's Email */}
                            <Field
                                as={TextField}
                                name="Email_Id"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="Friend's Email ID"
                                value={props.values.Email_Id}
                                onChange={props.handleChange}
                                error={Boolean(props.touched.Email_Id && props.errors.Email_Id)}
                            />
                            <FormHelperText error={Boolean(props.touched.Email_Id && props.errors.Email_Id)}>
                                {Boolean(props.touched.Email_Id && props.errors.Email_Id) && <ErrorMessage name="Email_Id" />}
                            </FormHelperText>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Button
                                    type="submit"
                                    variant="contained"

                                >
                                    Save
                                </Button>
                                <RWebShare
                                    data={{
                                        text: "Hi, I recommend meepaisa for online orders.",
                                        url: `${BASEURL}/auth/signup?referral=${user.User_Details_Id}`,
                                        title: "Meepaisa Referral",
                                    }}
                                     sites={['facebook', 'linkedin','mail', 'twitter', 'whatsapp', 'reddit', 'telegram','copy']}
                                     >
                                    <Button
                                        variant="contained"
                                    >Share invite</Button>
                                </RWebShare>
                            </Box>
                        </Form>
                        </Box>
                    )}
                </Formik>
            </Grid>
                </Grid>)
            :
                (<Grid container spacing={2} sx={{ padding:'13px'}}>
                    <Grid item xs={12} md={6} style={{ display: "flex", flexDirection: "column", }}>
                        {/* <Typography variant="h6" gutterBottom style={{ textAlign: "end", fontWeight: "bold", color: theme.palette.text.secondary }}>
                            My Referrals
                        </Typography> */}
                        <Typography variant="h6" gutterBottom style={{ fontWeight: "bold", color: theme.palette.text.secondary }}>
                            Refer & Earn
                        </Typography>

                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={FormValidationSchema}
                        >
                            {(props) => (
                                <Box >
                                    <Form>
                                        {/* Friend's Name */}
                                        <Field
                                            as={TextField}
                                            name="Name"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            label="Friend's Name"
                                            value={props.values.Name}
                                            onChange={props.handleChange}
                                            error={Boolean(props.touched.Name && props.errors.Name)}
                                        />
                                        <FormHelperText error={Boolean(props.touched.Name && props.errors.Name)}>
                                            {Boolean(props.touched.Name && props.errors.Name) && <ErrorMessage name="Name" />}
                                        </FormHelperText>


                                        {/* Referral Message */}
                                        <Field
                                            as={TextField}
                                            name="Message"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            label="Referral Message"
                                            value={props.values.Message}
                                            onChange={props.handleChange}
                                            error={Boolean(props.touched.Message && props.errors.Message)}
                                        />
                                        <FormHelperText error={Boolean(props.touched.Message && props.errors.Message)}>
                                            {Boolean(props.touched.Message && props.errors.Message) && <ErrorMessage name="Message" />}
                                        </FormHelperText>


                                        {/* Friend's Mobile */}
                                        <Field
                                            as={TextField}
                                            name="Mobile"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            label="Friend's Mobile"
                                            value={props.values.Mobile}
                                            onChange={props.handleChange}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">+91 </InputAdornment>,
                                            }}
                                            error={Boolean(props.touched.Mobile && props.errors.Mobile)}

                                        />
                                        <FormHelperText error={Boolean(props.touched.Mobile && props.errors.Mobile)}>
                                            {Boolean(props.touched.Mobile && props.errors.Mobile) && <ErrorMessage name="Mobile" />}
                                        </FormHelperText>


                                        <Box sx={{ textAlign: "center" }}>
                                            <Typography>--------OR ----------</Typography>
                                        </Box>
                                        {/* Friend's Email */}
                                        <Field
                                            as={TextField}
                                            name="Email_Id"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            label="Friend's Email ID"
                                            value={props.values.Email_Id}
                                            onChange={props.handleChange}
                                            error={Boolean(props.touched.Email_Id && props.errors.Email_Id)}
                                        />
                                        <FormHelperText error={Boolean(props.touched.Email_Id && props.errors.Email_Id)}>
                                            {Boolean(props.touched.Email_Id && props.errors.Email_Id) && <ErrorMessage name="Email_Id" />}
                                        </FormHelperText>
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Button
                                                type="submit"
                                                variant="contained"

                                            >
                                                Save
                                            </Button>
                                            <RWebShare
                                                data={{
                                                    text: "Hi, I recommend meepaisa for online orders.",
                                                    url: `${BASEURL}/auth/signup?referral=${user.User_Details_Id}`,
                                                    title: "Meepaisa Referral",
                                                }}
                                                sites={['facebook', 'linkedin', 'mail', 'twitter', 'whatsapp', 'reddit', 'telegram', 'copy']}
                                            >
                                                <Button
                                                    variant="contained"
                                                >Share invite</Button>
                                            </RWebShare>
                                        </Box>
                                    </Form>
                                </Box>
                            )}
                        </Formik>
                    </Grid>
                <Grid item xs={12} md={5.5} style={{ display: "flex", flexDirection: "column",}}>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold", color: theme.palette.text.secondary }}>
                        History
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            size="medium"
                            placeholder="Search Name"
                            value={searchTerm}
                            onChange={handleSearch}
                            sx={{ width: '70%' }}
                        />
                        <img src={SearchIcon} alt="Search Icon" style={{ width: '55px', height: '55px', marginTop: '7px', borderRadius: '4px' }} />
                    </Box>
                    <Box sx={{ flex: 1, justifyContent: "flex-start", display: "flex", flexDirection: "column", gap: 1 }}>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", }} >
                            <Grid item xs={2}>
                                <Typography variant="body1" style={{ fontWeight: "bold", color: theme.palette.text.secondary }}>Name</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1" style={{ fontWeight: "bold", color: theme.palette.text.secondary }}>Mobile/E-Mail</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1" style={{ textAlign: 'center', fontWeight: "bold", color: theme.palette.text.secondary }}>Amount</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1" style={{ textAlign: 'center', fontWeight: "bold", color: theme.palette.text.secondary, }}>Sign-Up Status</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1" style={{ textAlign: 'center', fontWeight: "bold", color: theme.palette.text.secondary }}>Payment Status</Typography>
                            </Grid>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around", rowGap: 3, alignItems: "flex-start" }} >
                            {
                                referNEarn?.length > 0 ?
                                    referNEarn?.map((history, index) => (
                                        <Box key={index} sx={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-around" }} >
                                            <Box sx={{ width: "25%", }}>
                                                <Typography variant="body2" sx={{ whiteSpace: "nowrap", display: { xs: "block", md: "none", sm: "none" } }} >{history?.Name?.length > 8 ? history.Name.substring(0, 8) + "..." : history.Name}</Typography>
                                                <Typography variant="body2" sx={{ whiteSpace: "nowrap", display: { xs: "none", md: "block", sm: "block" } }} >{history?.Name?.length > 15 ? history.Name.substring(0, 15) + "..." : history.Name}</Typography>
                                            </Box>
                                            <Box sx={{ width: "25%" }}  >

                                                <Typography variant="body2" sx={{ whiteSpace: "nowrap", display: { xs: "block", md: "none", sm: "none" } }} >
                                                    {history?.Mobile ?
                                                        (history.Mobile.length > 8 ? history.Mobile.substring(0, 8) + "..." : history.Mobile) :
                                                        (history?.Email_Id ?
                                                            (history.Email_Id.length > 8 ? history.Email_Id.substring(0, 8) + "..." : history.Email_Id) :
                                                            "")
                                                    }
                                                </Typography>
                                                <Typography variant="body2" sx={{ whiteSpace: "nowrap", display: { xs: "none", md: "block", sm: "block" } }} >
                                                    {history?.Mobile ?
                                                        (history.Mobile.length > 15 ? history.Mobile.substring(0, 15) + "..." : history.Mobile) :
                                                        (history?.Email_Id ?
                                                            (history.Email_Id.length > 15 ? history.Email_Id.substring(0, 15) + "..." : history.Email_Id) :
                                                            "")
                                                    }
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: "25%", textAlign: 'center' }}  >
                                                <Typography variant="body2" > &#8377;{history.Amount}</Typography>
                                            </Box>
                                            <Box sx={{ width: "25%", textAlign: 'center' }}  >
                                                <Typography variant="body2" >{history.Sign_Up_Status}</Typography>
                                            </Box>
                                            <Box sx={{ width: "25%", textAlign: 'center' }}  >
                                                <Typography variant="body2" color={
                                                    history.Payment_Status === 'Settled' ? theme.palette.success.light :
                                                        history.Payment_Status === 'Pending' ? theme.palette.warning.light : theme.palette.error.main
                                                }>
                                                    {history.Payment_Status}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ))
                                    :
                                    <img src={noReferalls} style={{ marginTop: '20px', width: '40%', display: 'block', margin: '20px auto' }} />

                            }
                        </Box>
                    </Box>
                    {Pagination?.TotalPages > 1 ? <Box sx={{ display: 'flex', justifyContent: "center", width: "100%", mt:'10px'}} >
                        <PN page={Pagination?.PageNo} onChange={(e, page) => {
                            updateQueryParam([{ PageNo: page }])
                        }} count={Pagination?.TotalPages} color="primary" />
                    </Box> : null}

                </Grid>
                
            </Grid>)
            }

            </Box>
    );
};

export default ReferAndEarn;
