import { Box, CardMedia, Skeleton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { fetchMarketingStrategies } from '../../../store/slices/retail/home/PrivacyPolicy';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser'
import { useTheme } from '@emotion/react';
import useScreenSize from '../../../utils/ScreenWidthHight';
import { Container } from '@mui/system';

const MarketingStrategies = () => {
    const winSize = useScreenSize();
    const { loading, marketingStrategy } = useSelector(state => state.privacypolicy);
    const dispatch = useDispatch();
    const theme = useTheme()

    useEffect(() => {
        dispatch(fetchMarketingStrategies({  Name: 'MarketingTerms' }));
    }, []);

    return (
        <Box>
            {/* Left Side */}
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ display: "flex", justifyContent: { md: "center", xs: 'left' }, width: "100%", alignItems: "center", gap: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: { md: "flex-end", xs: 'flex-start' }, gap: 1 }}>
                        {loading ? (
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }} >
                                <Skeleton variant='text' width="278px" height="72px" />
                                <Skeleton variant='text' width="168px" height="72px" />
                            </Box>
                        ) : (
                                                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }} >
                                <Typography sx={{ display: 'flex', color: theme.palette.extra.primary, fontSize: { md: '60px', sm: '60px', xs: '34px' }, fontWeight: 'bold', ml: { md: '0px', sm: '0px', xs: '26px' } }}>
                                    Marketing
                                </Typography>
                                <Typography sx={{ display: 'flex', color: theme.palette.extra.primary, fontSize: { md: '60px', sm: '60px', xs: '34px' }, fontWeight: 'bold', ml: { md: '0px', sm: '0px', xs: '26px' } }}>
                                    Terms
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
                {winSize.screenSize.width > 1050 ? (
                    <Box sx={{ flex: '1' }}>
                        {loading ? (
                            <Skeleton variant="rectangular" width="611px" height="499px" sx={{ borderRadius: "24px", mr: { md: '10px', sm: '10px', xs: 'unset' } }} />
                        ) : (
                            <CardMedia
                                component="img"
                                image={require("../../../assests/images/tickets.png")}
                                sx={{
                                    width: '611px',
                                    height: '499px',
                                    display: "flex",
                                    flexDirection: "column",
                                    flexWrap: "wrap"
                                }}
                            />
                        )}
                    </Box>
                ) : null}
            </Box>
            {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 3 }}>
                    <Skeleton variant="rectangular" width="85%" sx={{
                        borderRadius: "24px", height: { md: 289, xs: 349 },
                    }} />
                </Box>
            ) : (
                marketingStrategy?.map((strategy, index) => (
                    <Box key={index} sx={{ width: { md: '100%', sm: '100%', xs: '90%' }, display: 'flex', justifyContent: 'center', alignItems: 'center', ml: { md: '0px', sm: '0px', xs: '20px' } }}>
                        <Container sx={{ border: "0.4px solid", borderColor: theme.palette.text.secondary, borderRadius: "24px", boxShadow: "0 4px 4px 0 grey" }} key={index}>
                            {parse(strategy?.Description)}
                        </Container>
                    </Box>
                ))
            )}
        </Box>
    );
};

export default MarketingStrategies;
