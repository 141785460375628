import React, { useEffect, useState } from "react";
import { Card, CardActionArea, CardMedia, Typography, Box, useTheme, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { orderStatusUpdate } from "../../../../store/slices/accounts/orders/OrdersSlice";
import { productImageGet } from "../../../../store/slices/accounts/orders/DeliveryAgentReviews";
import AutoAdjustText from "../../../../utils/AutoAdjustText";

const OrderDetailsCard = ({ Partner_Name, productImage, Order_Id, Product_Name, Order_Date, Delivered_Date,
    Quantity, customerName, cAddress, cPinCode, cDoorNo, cPhoneNumber, billingName, billingAddress, billingPinCode, billingPhoneNumber, billingDoorNo,
    agentName, agentPhoneNumber, agentProfileImage, Order_Status, item, id, EmailID, type, Product_Id, Product_Image, handelCancelModalOpen, setIsReturn, Line_Item_Order_Status }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <>
            {type !== "grocery" && <Box>
                <Typography sx={{ color: theme.palette.text.secondary, fontSize: '24px', fontWeight:'bold' }}>Order Details</Typography>
            </Box>}
            <Card sx={{ width: { md: '90%', xs: "100%" }, borderRadius: '24px', border: '1px solid', borderColor: theme.palette.primary.main, height: {md:'258px', sm:'258px', xs:'140px'} }}>
                <Box display="flex" height="100%">
                    <CardActionArea style={{ width: '50%' }} >
                        <CardMedia
                            component="img"
                            height="100%"
                            image={Product_Image}
                            alt="product_Image"
                            sx={{ objectFit: "fill" }}
                            onClick={() => navigate(`/${type || "retail"}/products/${Product_Id}`)}

                        />
                    </CardActionArea>
                    <Box width="50%" p={2} display="flex" flexDirection="column" justifyContent="space-between" >
                        <Box>
                            <AutoAdjustText style={{ width: "100%", fontSize: { md: "17px", sm: '17px', xs: '10px' }  }} numLines={1} > {Product_Name} </AutoAdjustText>
                            <Typography variant="body1" sx={{ fontSize: {md:"17px", sm:'17px',xs:'10px'} }}>Seller: <strong>{Partner_Name}</strong></Typography>
                            <Typography variant="body1" sx={{ fontSize: {md:"17px", sm:'17px',xs:'10px'}}}>Order ID: <strong>{Order_Id}</strong></Typography>
                            <Typography variant="body1" sx={{ fontSize: {md:"17px", sm:'17px',xs:'10px'}}}>Product ID: <strong>{Product_Id}</strong></Typography>
                            <Typography variant="body1" sx={{ fontSize: { md: "17px", sm: '17px', xs: '10px' }, whiteSpace: 'nowrap' }}>Ordered Date: <strong>{Order_Date?.slice(0,16)}</strong></Typography>
                            <Typography variant="body1" sx={{ fontSize: {md:"17px", sm:'17px',xs:'10px'}}}>Quantity: <strong>{Quantity}</strong></Typography>
                        </Box>
                        {<Box sx={{ textAlign: "right", width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            {
                                !["CANCELLED", "RETURN", "DELIVERED", "SETTLED"].includes(Order_Status?.toUpperCase()) ?
                                    (type !== "grocery" && item.Is_Item_Cancel_Flag && <Button type="button" variant="contained" sx={{ width:{xs:'45px', md:'97px', sm:'97px'}, height:{xs:'18px', sm:'36px', md:'36px'}, padding:{xs:'9px'}}} onClick={handelCancelModalOpen} >Cancel</Button>)
                                    :
                                    (
                                        type !== "grocery" && Order_Status === "DELIVERED" && item.Is_Item_Retutn_Flag && (new Date()) < (new Date(item.Order_Date).setDate(item.Max_Days_To_Return)) ?
                                            <Button type="button" variant="contained" size="medium" onClick={() => {
                                                setIsReturn(true)
                                                handelCancelModalOpen()
                                            }} >Return</Button>
                                            :

                                            (
                                                ['CANCELLED', 'RETURN', "SETTLED"].includes(Order_Status) &&
                                                <Typography

                                                    color={
                                                        Order_Status === "RETURN" ? theme.palette.warning.main :
                                                            Order_Status === "CANCELLED" ? theme.palette.error.main :
                                                                Order_Status === "SETTLED" ? theme.palette.success.main : theme.palette.grey[500]
                                                    }
                                                    sx={{ fontWeight: "bold" }}

                                                >{Order_Status}</Typography>))
                            }
                        </Box>}

                    </Box>
                </Box>
            </Card>

            {type !== "grocery" &&
                <Box>
                    <Box sx={{ marginY: 2 }}>
                        <Box>
                            <Typography  sx={{ fontSize:{md:'24px', sm:'24px', xs:'16px'},fontWeight: "bold" }}>Delivery Address</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontWeight: 'bold'  , fontSize:{md:'16px', sm:'16px', xs:'14px'}}}>{customerName}</Typography>

                            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>D-No: </Typography>
                                <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }} >{cDoorNo}</Typography>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Address:</Typography>
                                <Typography sx={{ ml: '5px', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{cAddress}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                <Typography sx={{ color: theme.palette.text.secondary, fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Pin Code: </Typography>
                                <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }} >{cPinCode}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                <Typography sx={{ color: theme.palette.text.secondary, fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Phone No: </Typography>
                                <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{cPhoneNumber}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ marginY: 2 }}>
                        <Box>
                            <Typography sx={{ fontSize: { md: '24px', sm: '24px', xs: '16px' },fontWeight: "bold" }}>Billing Address</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontWeight: 'bold', fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>{billingName}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>D-No: </Typography>
                                <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }} >{billingDoorNo}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Address:</Typography>
                                <Typography sx={{ ml: '5px', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{billingAddress}</Typography>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography sx={{ color: theme.palette.text.secondary, fontSize: { md: '16px', sm: '16px', xs: '12px' } }}> Pin Code: </Typography>
                                <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{billingPinCode}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography sx={{ color: theme.palette.text.secondary, mr: '2px', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Phone No: </Typography>
                                <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{billingPhoneNumber}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    {Boolean(agentPhoneNumber) && (
                        <Box sx={{ marginY: 2, display: "flex", alignItems: "center" }}>
                            <Box>

                            </Box>
                            <Box
                                component="img"
                                src={agentProfileImage}
                                alt="Agent Profile"
                                sx={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50px",
                                    marginRight: "10px",
                                }}
                            />

                            <Box>
                                <Typography sx={{ fontWeight: "bold" , fontSize:{xs:'16px', sm:'24px', md:'24px'} }}>Delivery Agent </Typography>
                                <Typography sx={{ fontWeight: "bold", fontSize: '16px' }}>{agentName}</Typography>
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <Typography sx={{ color: theme.palette.text.secondary, }}>+91&nbsp;</Typography>
                            
                                    <Typography>{agentPhoneNumber}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            }
        </>
    );
};

export default OrderDetailsCard;


export const GroceryShippingAddressComponent = (
    { Partner_Name, productImage, Product_Id, Order_Id, Product_Name, Order_Date, Delivered_Date,
        Quantity, customerName, cAddress, cPinCode, cPhoneNumber, billingName, billingAddress, billingPinCode, billingPhoneNumber,
        agentName, agentPhoneNumber, Order_Status, item, id, EmailID, type, agentProfileImage }
) => {
    const theme = useTheme()
    return (
        <Box>
            <Box sx={{ marginY: 2 }}>
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: "bold", fontSize: { md: '24px', sm: '24px', xs: '16px' }, }}>Delivery Address</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: 'bold', fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>{customerName}</Typography>



                    <Box sx={{ display: 'flex', flexDirection: "row" }}>
                        <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Address:</Typography>
                        <Typography sx={{ ml: '5px', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{cAddress}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: "row" }}>
                        <Typography sx={{ color: theme.palette.text.secondary, fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Pin Code:</Typography>
                        <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }} >{cPinCode}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: "row" }}>
                        <Typography sx={{ color: theme.palette.text.secondary, fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Phone No: </Typography>
                        <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{cPhoneNumber}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ marginY: 2 }}>
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: "bold", fontSize: { md: '24px', sm: '24px', xs: '16px' }, }}>Billing Address</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: 'bold', fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>{billingName}</Typography>

                    <Box sx={{ display: 'flex', flexDirection: "row" }}>
                        <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Address:</Typography>
                        <Typography sx={{ ml: '5px', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{billingAddress}</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography sx={{ color: theme.palette.text.secondary, fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Pin Code: </Typography>
                        <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{billingPinCode}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography sx={{ color: theme.palette.text.secondary, mr: '2px', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Phone No: </Typography>
                        <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{billingPhoneNumber}</Typography>
                    </Box>
                </Box>
            </Box>
            {Boolean(agentPhoneNumber) &&
                <Box sx={{ marginY: 2, display: "flex", alignItems: "center" }}>
                    <Box>

                    </Box>
                    <Box
                        component="img"
                        src={agentProfileImage}
                        alt="Agent Profile"
                        sx={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50px",
                            marginRight: "10px",
                        }}
                    />

                    <Box>
                        <Typography sx={{ fontWeight: "bold", fontSize: { xs: '16px', sm: '24px', md: '24px' } }}>
                            Delivery Agent
                        </Typography>
                        <Typography sx={{ fontWeight: "bold", fontSize: '16px' }}>{agentName},</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography sx={{ color: theme.palette.text.secondary, }}>Phone No : </Typography>
                            <Typography>  {agentPhoneNumber}</Typography>
                        </Box>
                    </Box>
                </Box>}

        </Box>
    )
}