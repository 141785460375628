import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { nodeApi, nodeCudApi } from "../../CommonAxios";


const initialState = {
    deliveryReviews: [],
    loading: false,
    error: "",
}



export const deliveryReviewsGet = createAsyncThunk("review/deliveryReviewsGet", async (formData) => {
    const response = await nodeApi.post("/Delivery_Reviews/Get", formData);

    const data = response?.data;
    if (data.Success_Response.Is_Data_Exist === "0") {
        return [];
    } else {
        return data.results || [];
    }
});


export const deliveryAgentReviewGet = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post('/Delivery_Reviews/Get', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data });
            } else {
                resolve({ status: false, error: res.data.Error_Message });
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message });
        });
    });
};

export const deliveryAgentReviewCreate = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post('/Reviews/Order_Review_Create', formData).then((res) => {
            if (res.data.Response_Status === "Success") {
                resolve({ status: true })
            }
            else
                resolve({ status: false })
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
const deliveryReviewsSlice = createSlice({
    name: 'deliveryReviews',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(deliveryReviewsGet.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deliveryReviewsGet.fulfilled, (state, action) => {
                state.loading = false;
                state.deliveryReviews = action.payload;
                state.error = "";
            })
            .addCase(deliveryReviewsGet.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    }
});

export default deliveryReviewsSlice.reducer;