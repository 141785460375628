import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { nodeApi, nodeCudApi, urlGenarator } from '../../CommonAxios';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';

export const handleFailedNavigation = () => (dispatch) => {
    const navigate = useNavigate()
    // Call your navigation utility function here
    navigate('/accounts/address');
};
export const fetchAddress = createAsyncThunk('address/address', async (formData, thunkApi) => {
    try {
        const response = await nodeApi.post(urlGenarator('/User/Address_Get', formData.pagination), formData.formData);
        return response.data;
    }
    catch (err) {
        return thunkApi.rejectWithValue({ error: err.message })
    }
})

export const fetchHeaderAddress = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await nodeApi.post(urlGenarator('/User/Address_Get', formData.pagination), formData.formData);
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results });
            } else {
                resolve({ status: false, error: res.data.Response_Message });
            }
        } catch (err) {
            resolve({ status: false, error: err.message });
        }
    });
};

export const fetchEditAddressAction = (input) => {
    return new Promise((resolve, reject) => {
        nodeApi.post('/User/Address_Get', input).then((async res => {
            let data = res.data
            if (data?.Success_Response?.Is_Data_Exist === "1") {
                resolve({ status: true, data: data })
            }
            else {
                resolve({ status: false, data: data.UI_Display_Message })
            }
        })).catch(err => {
            resolve({ status: false, data: err.toString() })
        })
    })
}

export const createAddress = createAsyncThunk('createAddress/address', async (formData, thunkApi) => {
    try {
        const response = await nodeCudApi.post('/User/Address_Create', formData)
        if (response.data?.Success_Response?.Is_Data_Exist === "1")
            return response.data.results;
        else
            return []
    } catch (err) {
        return thunkApi.rejectWithValue({ error: err.message })
    }
})

export const createAddressAction = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post('/User/Address_Create', formData).then((res) => {
            if (res.data) {
                resolve({ status: true })
            }
            else {
                resolve({ status: false })
            }
        }).catch((err) => {
            resolve({ status: false })
        })
    })
}

export const updateEditAddressAction = (input) => {
    return new Promise((resolve, reject) => {
        nodeCudApi.put('/User/Address_Update', input).then((async res => {
            let data = res.data
            if (data.Response_Code == "200") {
                resolve({ status: true, data: data })
            }
            else {
                resolve({ status: false, data: data.UI_Display_Message })
            }
        })).catch(err => {
            resolve({ status: false, data: err.toString() })
        })
    })
}


export const deleteAddressAction = (formData, toolTipActive) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post('/User/Address_Delete', formData).then((res) => {
            if (res.data?.Response_Code === "200")
            {
                if (res.data.Response_Status === "Failure") {
                    toolTipActive("error", res.data.UI_Display_Message)
                }
                else {
                    toolTipActive("success", res.data.UI_Display_Message)
                resolve({ status: true, data: res.data })}
            }

            else
                resolve({ status: false })
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const Address = createSlice({
    name: "Address",
    initialState: {
        addressProfile: [],
        loading: false,
        pagination: {}
    },
    reducers: {
        addAdd: (state, action) => {
            const newAddress = {
                id: state.addressProfile.length + 1, type: action.payload.type,
                billingType: {
                    default: action.payload.billingType.default,
                    billing: action.payload.billingType.billing,
                    shipping: action.payload.billingType.shipping
                },
                location: action.payload.location,
                DoorNo: action.payload.DoorNo,
                street: action.payload.street,
                landMark: action.payload.landMark,
                city: action.payload.city,
                state: action.payload.state,
                pincode: action.payload.pincode,
                alternativePhonenumber: action.payload.alternativeMobileNumber,
                phone: action.payload.phone,
                
            };
            state.addressProfile.unshift(newAddress);
        },
        remove: (state, action) => {
            state.addressProfile = state.addressProfile.filter(item => item.id !== action.payload.id);
        },
        editAddress: (state, action) => {
            state.editAddressLoading = true
            const { id } = action.payload;
            state.addressProfile.map((e, i) => {
                if (e.id == id)
                    return (
                        state.editAddress = e
                    )
            })
            state.editAddressLoading = false

        },
        updateAddress: (state, action) => {
            const { id } = action.payload;

            const newAddress = {
                id: id, type: action.payload.type,
                billingType: {
                    default: action.payload.billingType.default,
                    billing: action.payload.billingType.billing,
                    shipping: action.payload.billingType.shipping
                },
                location: action.payload.location,
                DoorNo: action.payload.DoorNo,
                street: action.payload.street,
                landMark: action.payload.landMark,
                city: action.payload.city,
                state: action.payload.state,
                pincode: action.payload.pincode,
                name: action.payload.name,
                alternativePhonenumber: action.payload.alternativeMobileNumber,
                phone: action.payload.phone
            };
            state.addressProfile = state.addressProfile.map((e, i) => {
                if (e.id === id) {
                    e.DoorNo = newAddress.DoorNo
                    e.billingType = newAddress.billingType
                    e.alternativePhonenumber = newAddress.alternativePhonenumber
                    e.city = newAddress.city
                    e.id = newAddress.id
                    e.landMark = newAddress.landMark
                    e.location = newAddress.location
                    e.name = newAddress.name
                    e.phone = newAddress.phone
                    e.pincode = newAddress.pincode
                    e.state = newAddress.state
                    e.street = newAddress.street
                    e.type = newAddress.type
                }
            })
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAddress.pending, (state, action) => {
            state.loading = true
            state.addressProfile = []
        }).addCase(fetchAddress.fulfilled, (state, action) => {
            state.loading = false
            state.addressProfile =
                action.payload?.results?.length > 0 ?
                    action.payload.results?.map((item, index) => ({
                        id: item?.User_Address_Id,
                        type: item?.Address_Type,
                        billingType: {
                            default: item?.Is_Default === 1 ? true : false,
                            billing: item?.Is_Billing === 1 ? true : false,
                            shipping: item?.Is_Shipping === 1 ? true : false,
                        },
                        location: item?.Location,
                        DoorNo: item?.Door_No,
                        street: item?.Street,
                        landMark: item?.Land_Mark,
                        city: item?.City,
                        state: item?.State,
                        pincode: item?.Zip,
                        name: item?.Name,
                        phone: item?.Mobile,
                        alternativePhonenumber: item?.Alternate_Mobile,
                        User_Email_Id: item?.User_Email_Id,
                        City_Id: item.City_Id,
                        Latitude: item.Latitude,
                        Longitude: item.Longitude
                    }
                    )
                    )
                    : [] 
            state.pagination = action.payload.Pagination || {}
        }).addCase(fetchAddress.rejected, (state, action) => {
            state.loading = false
        })
            .addCase(createAddress.pending, (state, action) => {
                state.loading = true
            }).addCase(createAddress.fulfilled, (state, action) => {
                state.loading = false

                window.alert("Address Added")
                window.location.reload()

            }).addCase(createAddress.rejected, (state, action) => {
                state.loading = false
            })

    }
});

export const { addAdd, remove, editAddress, updateAddress, } = Address.actions;
