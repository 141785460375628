import axios from "axios"
import { boomiApi, nodeApi, nodeCudApi } from "../../CommonAxios"



export const giftCreateAction=(formData)=>{
    return new Promise(async (resolve,reject)=>{
        await boomiApi.post('/Gift_Voucher/Create',formData).then((res)=>{
            if(res.data){
                resolve({status:true,data:res.data})
            }
            else{
                resolve({status:false,data:[]})
            }
        }).catch((err)=>{
            resolve({status:false,error:err.message})
        })
    })
}

export const giftCardImageFetch=(formData)=>{
    return new Promise(async (resolve,reject)=>{
        await nodeApi.post('/Gift_Voucher/Image_Get',formData).then((res)=>{
            if(res.data.Success_Response.Is_Data_Exist==="1"){
                resolve({status:true,data:res.data.results})
            }
            else{
                resolve({status:false,data:[]})
            }
        }).catch((err)=>{
            resolve({status:false,error:err.message})
        })
    })
}