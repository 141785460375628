import { persistStore, persistReducer } from 'redux-persist';
import formReducer from "./formSlice";
import postsReducer from "./sampleSlice";
import homeBrandReducer from "./retail/home/BrandSlice";
import homePartnesReducer from "./retail/home/PartnerSlice";
import homeBannersReducer from "./retail/home/BannerSlice";
import categoryReducer from "../../store/slices/retail/home/CategorySlice";
import furnitureReducer from "../../store/slices/retail/home/FurnitureSlice";
import todayDealsReducer from './retail/home/TodayDealsSlice'
import pickUpReducer from "./retail/home/TableLayoutslice"
import Reviewslice from "./retail/home/ReviewSlice"
import ProductsliceReducer from "./retail/home/Productslice"
import bestSellerReducer from "./retail/home/BestSellerSlice"
import exploreMoreReducer from '../../store/slices/retail/home/exploreMoreSlice';
import reviewPurchaseReducer from '../../store/slices/retail/home/reviewPurchaseSlice';
import customerLovedReducer from '../../store/slices/retail/home/customerLovedSlice';
import storage from 'redux-persist/lib/storage';
import CouponReducer from './retail/home/CouponSlice';
import fetchAllProducts from './retail/home/AllProductsSlice'
import recentlyViewedReducer from '../../store/slices/retail/home/RecentlyViewedSlice';
import partnersReducer from '../../store/slices/retail/home/PartnersSlice';
import referAndEarn from './accounts/ReferAndEarnSlice';
import authReducer from "./AuthSlice";
import { combineReducers } from '@reduxjs/toolkit';
import SearchSlice from './retail/home/SearchSlice';
import { Address } from './accounts/Address/Address';
import { ProductFilterSlice } from './retail/home/ProductFiltersSlice';
import { ProfileDetails } from './Profile/Profile';
import UpdateProfileSlice from './retail/home/UpdateProfile';
import Dealslice from './retail/home/Dealslice';
import BrandReviewSlice from './retail/home/BrandReviewSlice';
import WalletSlice from './accounts/WalletSlice';
import SubcategorySlice from './retail/home/SubcategorySlice';
import CartSlice from './accounts/cart/CartSlice';
import GiftSlice from './accounts/gift/GiftSlice';
import ordersSlice from './accounts/orders/OrdersSlice';
import CreateTicketSlice from './retail/home/CreateTicketSlice';
import NotificationSlice from './retail/home/NotificationSlice';
import PrivacyPolicySlice from './retail/home/PrivacyPolicy';
import faqSlice from './retail/home/Faqlice'
import NewsEventSlice from './retail/home/NewsEventSlice';
import DeleteAccount from './retail/home/DeleteAccount';
import BrandReviewlSlice from './retail/home/AppReviewlice';
import GiftVendorsSlice from './retail/home/GiftVendorsSlice';
import ContactUslice from './retail/home/ContactUslice';
import wishlistSlice from './retail/home/wishlistSlice';
import { ticketSlice } from './tickets/TicketsSlice';
import DealsSlice from './retail/home/DealsSlice';
import partnerReviewSlice from './retail/home/partnerReviewSlice';
import deliveryReviewsSlice from "./accounts/orders/DeliveryAgentReviews"
import { CommonSlice } from './CommonSlice';

const persistAuthConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['user',
    'isAuthenticated',
    'sessionExpiredTime',
    'sessionExpiredDate'],
};

const rootReducer = combineReducers({
  auth: persistReducer(persistAuthConfig, authReducer),
  posts: postsReducer,
  forms: formReducer,
  homeBrands: homeBrandReducer,
  homePartners: homePartnesReducer,
  homeBanners: homeBannersReducer,
  category: categoryReducer,
  furniture: furnitureReducer,
  todayDeals: todayDealsReducer,
  pickUpWhereLeft: pickUpReducer,
  review: Reviewslice,
  product: ProductsliceReducer,
  bestSeller: bestSellerReducer,
  exploreMore: exploreMoreReducer,
  reviewPurchase: reviewPurchaseReducer,
  customerLoved: customerLovedReducer,
  coupons: CouponReducer,
  allProducts: fetchAllProducts,
  recentlyViewed: recentlyViewedReducer,
  partners: partnersReducer,
  referAndEarn: referAndEarn,
  searchResults: SearchSlice,
  productFilter: ProductFilterSlice.reducer,
  addressProfiles: Address.reducer,
  UpdateProfile: UpdateProfileSlice,
  ProfileDetails: ProfileDetails.reducer,
  deals: Dealslice,
  wallet: WalletSlice,
  brandReview: BrandReviewSlice,
  subcategory: SubcategorySlice,
  cartSlice: CartSlice,
  orders: ordersSlice,
  gift: GiftSlice,
  ticket: CreateTicketSlice,
  notifications: NotificationSlice,
  privacypolicy: PrivacyPolicySlice,
  faq: faqSlice,
  newsevent: NewsEventSlice,
  deleteaccount: DeleteAccount,
  brandreview: BrandReviewlSlice,
  giftvendor: GiftVendorsSlice,
  contact: ContactUslice,
  wishlist: wishlistSlice,
  tickets: ticketSlice.reducer,
  dealsHome: DealsSlice,
  partnerReviews: partnerReviewSlice,
  deliveryReviews: deliveryReviewsSlice,
  commonReducer:CommonSlice.reducer

});

export default rootReducer;
