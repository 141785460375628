import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import ExploreMore from "./ExploreMore";
import ReviewPurchase from "./ReviewPurchase";
import CustomerMostLoved from "./CustomerMostLoved";
import { resourcePathWithPaylaodCall } from "../../../store/slices/AuthSlice";
import { isEmpty } from "../../../utils";
import SKExplore from '../../retail/home/ExploreMore'

const ExploreReviewCustomer = ({ input }) => {
  const [item1, item2, item3] = input
  const [exploreMoreData, setExploreMoreData] = useState([])
  const [reviewPurchaseData, setReviewPurchaseData] = useState([])
  const [customerMostLovedData, setCustomerMostLovedData] = useState([])

  const apiCall = async (url, input, stateFunction) => {
    const res = await resourcePathWithPaylaodCall(url, input);

    if (res.status) {
      stateFunction(res.data)
    }
  }
  useEffect(() => {
    if (!isEmpty(item1))
      apiCall(item1.resourceURL, item1.requestPayload, setExploreMoreData)
  }, [item1]);
  useEffect(() => {
    if (!isEmpty(item2))
      apiCall(item2.resourceURL, item2.requestPayload, setReviewPurchaseData)
  }, [item2]);
  useEffect(() => {
    if (!isEmpty(item3))
      apiCall(item3.resourceURL, item3.requestPayload, setCustomerMostLovedData)
  }, [item3]);

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      flexWrap={"wrap"}
      justifyContent={"space-around"}
      marginTop={3}
      rowGap={1}
      columnGap={3}
    >
      <Box>
        {exploreMoreData.length > 4 ? <ExploreMore data={exploreMoreData.slice(0, 4)} itemData={item1} />  : <SKExplore isSkeleton /> }
      </Box>
      <Box>
        {reviewPurchaseData.length > 4 ? <ReviewPurchase data={reviewPurchaseData.slice(0, 4)} itemData={item2} /> : <SKExplore isSkeleton /> } 
      </Box>
      <Box>
        {customerMostLovedData.length > 4 ? <CustomerMostLoved data={customerMostLovedData.slice(0, 4)} itemData={item3} /> : <SKExplore isSkeleton /> } 
      </Box>
    </Box>
  );
};

export default ExploreReviewCustomer;
