import React, { createContext, useEffect, useState } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/system';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AllProductsActions } from './store/slices/retail/home/AllProductsSlice';

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarMessageType, setSnackbarMessageType] = useState('success');
  const [searchParams, setSearchParams] = useSearchParams();
  const [logLoading, setLogLoading] = useState(false)


  // Function to update a specific query parameter
  const updateQueryParam = (data = []) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (item[key]) {
          newSearchParams.set(key, item[key]);
        } else {
          newSearchParams.delete(key);
        }
      })

    })
    setSearchParams(newSearchParams);
  };

  // Function to delete a specific query parameter
  const deleteQueryParam = (key) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete(key);
    setSearchParams(newSearchParams);
  };


  const toolTipActive = (type, message) => {
    setSnackbarMessageType(type)

    setSnackbarMessage(message);
    setOpenSnackbar(true);
  }

  return (
    <GlobalContext.Provider value={{ toolTipActive, searchParams, setSearchParams, updateQueryParam, deleteQueryParam, setLogLoading, logLoading }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={snackbarMessageType}
          onClose={() => setOpenSnackbar(false)}
          iconMapping={{
            success: <CheckCircleIcon fontSize="inherit" sx={{ color: 'darkgreen' }} />,
            error: <ErrorIcon fontSize="inherit" sx={{ color: 'red' }} />,
            warning: <WarningIcon fontSize="inherit" sx={{ color: 'orange' }} />,
            info: <InfoIcon fontSize="inherit" sx={{ color: 'blue' }} />,
          }}
          sx={{ marginTop: "45px", color: "white", padding: "0px 6px", borderRadius: "12px" }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {snackbarMessage}
          </Box>
        </MuiAlert>
      </Snackbar>
    </GlobalContext.Provider>
  );
};


const ContextIndex = (props) => {


  const location = useLocation();
  const [previousPath, setPreviousPath] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    // Function to call when navigating from Home to About
    const handleHomeToAboutNavigation = () => {
      dispatch(AllProductsActions.updateFilter({
        Search: "",
        Business_Type: "",
        Status: "Active",
        Brand_Id: [],
        Partner_Details_Id: [],
        Category_Id: [],
        Sub_Category_Id: [],
        Is_Top_Product: 0,
        Is_Popular: 0,
        Is_Verified: 0,
        Whats_Hot: 0,
        Is_Upcoming: 0,
        Min_Discount: 0,
        Max_Discount: 0,
        Min_Price: 0,
        Max_Price: 0,

      }));
    };

    // Check if navigation is from Home to About
    if (previousPath === '/grocery/home' || location.pathname === '/grocery/home' || previousPath === '/retail/home' || location.pathname === '/retail/home') {
      handleHomeToAboutNavigation();
    }

    // Update previousPath with the current location
    setPreviousPath(location.pathname);
  }, [location]);

  return (
    <GlobalContextProvider><Outlet /></GlobalContextProvider>
  );
};

export default ContextIndex;