import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { boomiApi, nodeApi, nodeCudApi } from '../../CommonAxios'
import axios from 'axios';

const initialState = {
    carts: [],
    loading: false,
    error: ""
}

export const getCart = createAsyncThunk("cart/getCart", async (formData) => {
    const response = await nodeApi.post("/Cart/Get", formData);
    const data = response?.data;
    if (data.Success_Response.Is_Data_Exist === "0") {
        return [];
    } else {
        return data.results || [];
    }
});

export const getInactiveCart = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post('/Cart/Get_New', formData).then((res) => {
            if (res.data.Is_Data_Exist === 1) {
                resolve({ status: true, data: res.data.results })
            } else {
                resolve({ status: false, data: [] })
            }
        }).catch(err => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const deleteCartItem = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post('Cart/Delete', formData).then((res) => {
            if (res.data.Response_Status === "Success") {
                resolve({ status: true })
            }
            else
                resolve({ status: false })
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}



export const cartAddressUpdateAction = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post('/Cart/Update', formData).then((res) => {
            if (res.data.Response_Status === "Success") {
                resolve({ status: true })
            }
            else
                resolve({ status: false })
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const couponCodeCheckAction = (code, user, partnerID) => {
    return new Promise(async (resolve, reject) => {
        let payload = {
            "Code": code,
            "User_Email_Id": user,
            "Partner_Details_Id": partnerID
        };
        await nodeApi.post('/Coupons/User_Get', payload).then((res) => {
            if (res.data.Response_Status === "Success") {
                resolve({ status: true, data: res.data.Coupon_Details[0] })
            }
            else
                resolve({ status: false, data: res.data.UI_Display_Message })
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const corpVoucherCheck = (voucherId) => {
    return new Promise(async (resolve, reject) => {
        let payload = {
            Corp_Coupon_Id: voucherId
        };
        await nodeCudApi.post('Order/Corp_coupon_OTP_send', payload).then((res) => {
            if (res.data.Response_Status === "Success") {
                resolve({ status: true, data: res.data })
            }
            else
                resolve({ status: false, data: res.data.UI_Display_Message })
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const corpVoucherCheckVerification = (coupon_id, emailId, OTP) => {
    return new Promise(async (resolve, reject) => {
        let payload = {
            Corp_User_Coupon_Id: coupon_id,
            Corp_User_Email_Id: emailId,
            Email_OTP: OTP
        };
        await nodeCudApi.post('/Order/Corp_coupon_OTP_Verify', payload).then((res) => {
            if (res.data.Response_Status === "Success") {
                resolve({ status: true, data: res.data })
            }
            else
                resolve({ status: false, data: res.data.UI_Display_Message })
        }).catch((err) => {
            resolve({ status: false, data: err.message })
        })
    })
}

export const giftVoucherCheck = (voucherId) => {
    return new Promise(async (resolve, reject) => {
        let payload = {
            Gift_Voucher_Id: voucherId
        };
        await nodeCudApi.post('/Gift_Voucher/Gift_Voucher_Send_OTP_To_Email_&_Mobile', payload).then((res) => {
            if (res.data.Response_Status === "Success") {
                resolve({ status: true, data: res.data })
            }
            else
                resolve({ status: false, data: res.data.UI_Display_Message })
        }).catch((err) => {
            if (err.code === "ECONNABORTED") {
                resolve({ status: false, data: "Network error, Please try again!" })
            }
            else
                resolve({ status: false, data: err.message })
        })
    })
}

export const giftVoucherCheckVerification = (voucherId, emailId, OTP) => {
    return new Promise(async (resolve, reject) => {
        let payload = {
            Gift_Voucher_Id: voucherId,
            User_Email_Id: emailId,
            OTP: OTP
        };
        await nodeCudApi.post('/Gift_Voucher/Verification', payload).then((res) => {
            if (res.data.Response_Status === "Success") {
                resolve({ status: true, data: res.data })
            }
            else
                resolve({ status: false, data: res.data.UI_Display_Message })
        }).catch((err) => {
            resolve({ status: false, data: err.message })
        })
    })
}

export const getMeepaisaCashBackCoupon = (User_Email_Id) => {
    return new Promise(async (resolve, reject) => {
        let payload = {
            User_Email_Id: User_Email_Id
        };
        await nodeApi.post('/Coupon/Meepaisa_Coupons', payload).then((res) => {
            if (res.data.Success_Response.Response_Status === "Success") {
                resolve({ status: true, data: res.data.results })
            }
            else
                resolve({ status: false })
        }).catch((err) => {
            resolve({ status: false, data: err.message })
        })
    })
}

export const placeNewOrderAction = (request) => {
    return new Promise(async (resolve, reject) => {
        await boomiApi.post(`/Order/Create`, request).then((res) => {
            if (res.data[0]?.Response_Status === "Success") {
                resolve({ status: true, data: res.data })
            }
            else
                resolve({ status: false, data: res.data })
        }).catch((err) => {
            resolve({ status: false, data: err.message })
        })
    })
}
export const updateOrderAfterPayment = (orderId, transactionId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await boomiApi.post('/Order/UpdateNew', {
                Order_Id: orderId,
                Transaction_Id: transactionId
            });

            if (response.data.Response_Status === "Success") {
                resolve({ status: true, data: response.data });
            } else {
                // resolve({ status: false, data: response.data.UI_Display_Message || 'Order update failed' });
                // resolve({ status: false, data: response.data.UI_Display_Message });

            }
        } catch (error) {
            resolve({ status: false, data: error.message });
        }
    });
};


export const removePartnerInCart = (Partner_Detail_Id, emailId) => {
    return new Promise(async (resolve, reject) => {
        let payload = {
            "Partner_Detail_Id": Partner_Detail_Id,
            "User_Email_Id": emailId
        }
        await boomiApi.post(`/User_Cart/Delete`, payload).then((res) => {
            if (res.data.Response_Status == "Success") {
                resolve({ status: true, data: res.data })
            }
            else {
                resolve({ status: true, data: res.data })
            }

        }).catch((err) => {
            resolve({ status: false, data: err.message })
        })
    })
}


export const removeAll = (emailId) => {
    return new Promise(async (resolve, reject) => {
        let payload = {
            "User_Email_Id": emailId
        }
        await boomiApi.post(`/User_Cart/Delete`, payload).then((res) => {
            if (res.data.Response_Status == "Success") {
                resolve({ status: true, data: res.data })
            }
            else {
                resolve({ status: true, data: res.data })
            }

        }).catch((err) => {
            resolve({ status: false, data: err.message })
        })
    })
}


const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        placeOrderErrorShow: (state, action) => {
            state.carts = state.carts.map(item => {
                if (item.Partner_Details_Id === action.payload) {
                    item.ShowError = true;
                }
                return item;
            });
        },
        placeOrderPartnerReady: (state, action) => {
            state.carts = state.carts.map(item => {
                if (item.Partner_Details_Id === action.payload) {
                    item.ShowError = false;
                    item.Verified = true;
                }
                return item;
            });
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getCart.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getCart.fulfilled, (state, action) => {
                state.loading = false

                const partnerMap = {};

                action.payload.forEach(item => {
                    const partnerId = item.Partner_Details_Id;

                    // If the partner details are not in the map, add them
                    if (!partnerMap[partnerId]) {
                        partnerMap[partnerId] = {
                            Partner_Details_Id: partnerId,
                            Partner_Name: item.Partner_Name,
                            User_Shipping_Address_Id: item.User_Shipping_Address_Id,
                            Banner_Path: item.Banner_Path,
                            Business_Type: item.Business_Type,
                            Quantity: item.Quantity,
                            ShowError: false,
                            Verified: false,
                            Products: []
                        };
                    }


                    if (item.Business_Type === "GROCERY" && item.User_Shipping_Address_Id) {
                        partnerMap[partnerId].User_Shipping_Address_Id = item.User_Shipping_Address_Id;
                    }

                    partnerMap[partnerId].Products.push(item);
                });

                state.carts = Object.values(partnerMap);
                state.error = "";
            })
            .addCase(getCart.rejected, (state, action) => {
                state.loading = false
                state.error = [];
                state.error = action.error.message;
            })

    }
})

export const { placeOrderErrorShow, placeOrderPartnerReady } = cartSlice.actions

export default cartSlice.reducer;


