import React, { useEffect, useState } from 'react';
import { Box, CardMedia, Grid, Typography, Link, CardActionArea, Card, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { useTheme } from '@emotion/react';
import useScreenSize from '../../../utils/ScreenWidthHight';
import { useNavigate } from 'react-router-dom';

const PickUp = ({ data, itemData }) => {

  const reduxData = useSelector(state => state.pickUpWhereLeft)
  const theme = useTheme();
  const winSize = useScreenSize();
  const navigate = useNavigate()
  const [selectedProduct, setSelectedProduct] = useState(0)
  if (data?.length === 4)
    return (
      <Card sx={{ maxWidth: {xs:"100%",sm:"343px"},position:"relative" }}>
        <Container sx={{ boxShadow: "0px 9px 30px " + theme.palette.extra.cardShadowColorOne, height: 462 }}>

          {itemData?.title ?
            <Typography variant="body1" sx={{ position: 'relative', top: 0, right: 0,  fontSize: '19px', fontWeight: 'bold',marginBottom:{xs:"10px",sm:"unset"} }}>
              {itemData?.title}
            </Typography> :
            <Skeleton variant='text' sx={{ position: 'relative', top: 0, right: 0, padding: '8px', fontSize: '19px' }} />
          }


          <Box style={{ display: 'flex', flexDirection: "row" }}>

            <Grid container spacing={2} style={{ flexGrow: 1, paddingTop: 8 }}>
              <Grid item xs={6}>
                {data[selectedProduct][itemData?.imageField] ?
                  <CardMedia
                    component="img"
                    image={data[selectedProduct][itemData?.imageField] || "path_to_your_image"}
                    alt="Image"
                    sx={{ width: 211, height: 295, objectFit: 'contain', cursor: 'pointer' }}
                    onClick={() => {
                      navigate(itemData.onClickLink + data[selectedProduct][itemData.idField])
                    }}
                  />
                  :
                  <Skeleton
                    variant='rectangular'
                    sx={{ width: 211, height: 295, objectFit: 'contain', cursor: 'pointer' }}
                  />
                }

              </Grid>
            </Grid>


            <Grid container spacing={6} style={{ flexGrow: 1, position: 'relative', paddingLeft: 12 }}>
              <Grid item xs={6} container justifycontent="flex-start" cols={2} gap={1} variant="woven">
                {data.map((product, index) => (
                  <Grid item xs={12} key={index}>

                    {product[itemData.imageField] ? <CardMedia
                      component="img"
                      image={product[itemData.imageField] || "path_to_your_image"}
                      alt="Image"
                      onClick={() => {
                        setSelectedProduct(index)
                      }}
                      sx={{ width: 64, height: 64, margin: '0px 3px 1px 1px', marginRight: '1px', objectFit: 'fill', cursor: 'pointer' }} // Adjusted margin here
                    />
                      :
                      <Skeleton
                        variant='rectangular'
                        sx={{ width: 64, height: 64, margin: '0px 3px 1px 1px', marginRight: '1px', objectFit: 'fill', cursor: 'pointer' }}
                      />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>

          {data[selectedProduct][itemData.descriptionField]
            ? <Typography
              variant="p"

              sx={{ fontWeight: "bold" }}
            >
              {data[selectedProduct][itemData.descriptionField].length > 65
                ? data[selectedProduct][itemData.descriptionField].substring(0, 65) + '...'
                : data[selectedProduct][itemData.descriptionField]}
            </Typography>
            :
            <Skeleton
              variant='text'
              sx={{ padding: '10px' }}
            />
          }

          <Box style={{ display: 'flex', flexDirection: 'row', position: 'relative', top: 20 }}>
            {data[selectedProduct][itemData.OriginalPriceField] ?
              <Typography
                variant="body2"

                sx={{ textDecoration: 'line-through', color: theme.palette.text.secondary, paddingLeft: 1,fontWeight:"bold",fontSize:"16px" }}
              >
                Rs. {data[selectedProduct][itemData.OriginalPriceField]}
              </Typography>
              :
              null
            }
            {data[selectedProduct][itemData.OriginalPriceField] ?
              <Typography variant="body2" fontWeight="bold" sx={{ paddingLeft: 8,fontWeight:"bold",fontSize:"16px" }}>
                Rs. {data[selectedProduct][itemData.DiscountedPriceField]}
              </Typography>
              :
              null
            }
          </Box>
          {data[selectedProduct][itemData.OriginalPriceField] ?
            <Typography variant="body2" sx={{
              textAlign: "end", paddingTop: 4, position: "absolute",
              bottom: 5,
              right: 10
            }}>
              <Link href={itemData.SeeMore} sx={{
                textDecoration: 'none',
                "&:hover": {
                  textDecoration: 'underline'
                },
                fontWeight: 'bold',

              }}>See more products to review</Link>
            </Typography>
            :
            <Skeleton
              variant='text'
              sx={{ textAlign: "end", paddingTop: 4 }}
            />
          }
        </Container>
      </Card>
    );
  else
    return (
      <Card sx={{ maxWidth: "343px" }}>
        <Container sx={{ boxShadow: "0px 9px 30px " + theme.palette.extra.cardShadowColorOne, height: 462 }}>

          {
            <Skeleton variant='text' sx={{ position: 'relative', top: 0, right: 0, padding: '8px', fontSize: '19px' }} />
          }


          <Box style={{ display: 'flex', flexDirection: "row" }}>

            <Grid container spacing={2} style={{ flexGrow: 1, paddingTop: 8 }}>
              <Grid item xs={6}>
                {
                  <Skeleton
                    variant='rectangular'
                    sx={{ width: 211, height: 295, objectFit: 'fill', cursor: 'pointer' }}
                  />
                }

              </Grid>
            </Grid>


            <Grid container spacing={6} style={{ flexGrow: 1, position: 'relative', paddingLeft: 12 }}>
              <Grid item xs={6} container justifycontent="flex-start" cols={2} gap={1} variant="woven">
                {data.map((product, index) => (
                  <Grid item xs={12} key={index}>

                    {
                      <Skeleton
                        variant='rectangular'
                        sx={{ width: 64, height: 64, margin: '0px 3px 1px 1px', marginRight: '1px', objectFit: 'fill', cursor: 'pointer' }}
                      />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>

          {
            <Skeleton
              variant='text'
              sx={{ padding: '10px' }}
            />
          }

          <Box style={{ display: 'flex', flexDirection: 'row', position: 'relative', top: 20 }}>

          </Box>
          {
            <Skeleton
              variant='text'
              sx={{ textAlign: "end", paddingTop: 4 }}
            />
          }
        </Container>
      </Card>)
};

export default PickUp;
