import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi, nodeCudApi, urlGenarator } from '../../CommonAxios';
import { act } from 'react';

export const fetchNotifications = createAsyncThunk("notifications/fetchNotifications", async (formData) => {
    const response = await nodeApi.post(urlGenarator("/Home/Notification_Get_User", formData.Pagination), formData.formData)
    if (response.data.Success_Response.Is_Data_Exist === "0") {
        return []
    } else {

        return response.data
    }
})


// export const fetchNotifications = createAsyncThunk(
//     'notifications/fetchNotifications',
//     async () => {
//         try {
//             const response = await nodeApi.post('/Home/Notification_Get_User');
//             const data = response.data;

//             if (data.Success_Response.Is_Data_Exist === "0") {
//                 return [];
//             } else {
//                 return data.results;
//             }
//         } catch (error) {
//             throw error.message;
//         }
//     }
// );

const NotificationSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: [],
        loading: false,
        error: null,
        Pagination: {}
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.loading = false;
                state.notifications = action.payload.results;
                state.Pagination = action.payload.Pagination;
            })
            .addCase(fetchNotifications.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const readNotification = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.put('/Notifications/Notification_Update_Flow', formData).then((res) => {
            if (res.data) {
                resolve({ status: true, data: res.data })
            }
            else {
                resolve({ status: false, error: res.data })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export default NotificationSlice.reducer;
