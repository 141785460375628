import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Container, Button, Grid, Radio, RadioGroup, FormControlLabel, Modal, FormHelperText } from "@mui/material";
import { Formik, Form, Field, replace } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData, updateProfileDataAction, uploadProfilePicAction } from "../../store/slices/Profile/Profile";
import { uriToBlob } from "../../utils/uriBlob";
import { GlobalContext } from "../../Context";
import ChangePassword from "./Changepassword";
import { useTheme } from "@emotion/react";
import { authActions, getUserDetailsAction, logout, signOutStateUpdate } from "../../store/slices/AuthSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useScreenSize } from '../../utils/ScreenWidthHight'
const defaultAvatar = require('../../assests/images/profileIcon.png');

const UpdateProfile = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const userDetails = useSelector(state => state.ProfileDetails);
    const authDetails = useSelector(state => state.auth);
    const [image, setImage] = React.useState({ preview: '', raw: '' });
    const [profilePicture, setProfilePicture] = React.useState(null);
    const fileInputRef = React.useRef(null);
    const formikRef = React.useRef(null);
    const [apiImageUrl, setApiImageUrl] = React.useState('');
    const profileDetails = userDetails?.profileData[0] || null;
    const { toolTipActive } = React.useContext(GlobalContext);
    const [modalOpen, setIsModalOpen] = React.useState(false);
    const { setLogLoading } = React.useContext(GlobalContext);
    const [isNavigate, setIsNavigate] = React.useState(false)
    const winsize = useScreenSize()
    const navigate = useNavigate()
    const pathName = useLocation().pathname

    React.useEffect(() => {
        if (authDetails?.isAuthenticated) {
            dispatch(getProfileData({ Email_Id: authDetails.user.Email_Id }));
        } else {
            window.location.href = "../auth/signin";
        }
    }, []);

    const updateProfileDataActionCall = async (formData) => {
        const response = await updateProfileDataAction(formData);
        if (response.status) {
            toolTipActive(response.data?.Response_Status.toLowerCase(), response.data?.UI_Display_Message);
            const pres = await getUserDetailsAction(authDetails.user.Email_Id);
            if (pres.status) {
                const user = JSON.parse(JSON.stringify(pres.data));
                user.User_Session_Id = authDetails.user.User_Session_Id;
                dispatch(authActions.signInStateUpdate(user));
                dispatch(getProfileData({ Email_Id: authDetails.user.Email_Id }));
            }
        }
    };

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const todayDate = getTodayDate();

    const handleLogout = async () => {
        setLogLoading(true)
        const formData = {
            "User_Session_Id": authDetails.user?.User_Session_Id,
            "User_Email_Id": authDetails.user?.Email_Id,
            "Device_Token": window.localStorage.getItem("Token")
        }
        const response = await logout(formData);

        if (response.status) {
            window.localStorage.removeItem("Token");
            window.localStorage.removeItem("persist:auth");
            toolTipActive("success", 'You have successfully logged out.');
            dispatch(signOutStateUpdate())
            setLogLoading(false)
            setIsNavigate(true)
        }
        else {
            setLogLoading(false)
        }
    }


    const handlePictureChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
            if (validImageTypes.includes(file.type)) {
                const imageUrl = URL.createObjectURL(file);
                handleImage({ uri: imageUrl });
                setProfilePicture(imageUrl);
            } else {
                alert('Please upload a valid image file (JPEG, PNG, GIF).');
            }
        }
    };


    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleImage = async (result) => {
        try {
            const img = await fetchImageFromUri(result.uri);
            setImage({
                preview: result.uri,
                raw: img
            });
            let localUri = result.uri;
            let uriParts = result.uri.split('.');
            let fileExtension = uriParts[uriParts.length - 1].toLowerCase().split(';');
            let filformate = fileExtension[0].split(':');
            let imgExtension = img.type.split('/')[1];
            let fileData = {
                fileName: localUri.split('/').pop(),
                ext: imgExtension,
            };
            const response = await uploadProfilePicAction(img, fileData);
            if (response.status) {
                setApiImageUrl(response.data.File_URL);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchImageFromUri = async (uri) => {
        const blob = await uriToBlob(uri);
        return blob;
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .matches(/^[a-zA-Z\s'-.]+$/, 'First name should only contain alphabetic characters, spaces, and appropriate symbols (e.g., hyphens or apostrophes).')
            .required("First Name is required"),
        lastName: Yup.string()
            .matches(/^[a-zA-Z\s'-.]+$/, 'Last name should only contain alphabetic characters, spaces, and appropriate symbols (e.g., hyphens or apostrophes).')
            .required("Last Name is required"),
        mobileNumber: Yup.string().required("Mobile Number is required"),
        gender: Yup.string().required("Gender is required"),
        dob: Yup.string()
            .required("Date of Birth is required")
            .test("is-not-future-date", "Date of Birth cannot be in the future", function (value) {
                const today = new Date();
                const dob = new Date(value);
                return dob <= today;
            }),
    });

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const userProfile = React.useMemo(() => {
        if (profileDetails !== null)
            return (
                <Container>
                    <Typography variant="h5" sx={{ textAlign: "left", marginBottom: "30px", marginTop: '19px', fontWeight: { xs: "unset", sm: "bold" },color:theme.palette.text.secondary }}>
                        Profile
                    </Typography>
                    <Grid container sx={winsize.screenSize.width < 600 ? { flexDirection: "column-reverse", rowGap: "30px" } : {}} >
                        <Grid item xs={12} sm={7} textAlign={"center"}>
                            <Box sx={{ marginBottom: "30px", display: "flow", flexDirection: "column", alignItems: "center" }}>
                                <Formik
                                    innerRef={formikRef}
                                    initialValues={{
                                        firstName: profileDetails?.First_Name || "",
                                        lastName: profileDetails?.Last_Name || "",
                                        email: profileDetails?.Email_Id || "",
                                        mobileNumber: profileDetails?.Mobile || "",
                                        gender: profileDetails?.Gender || "",
                                        dob: profileDetails?.Dob || "",
                                        anniversary: profileDetails?.Anniversary_Dob?.slice(0, 10) || "",
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={async (values, { setSubmitting, setFieldError }) => {
                                        try {
                                            const formData = {
                                                "User_Details_Id": profileDetails?.User_Details_Id,
                                                "Anniversary_Dob": values.anniversary,
                                                "Dob": values.dob,
                                                "Email_Id": profileDetails.Email_Id,
                                                "First_Name": values.firstName,
                                                "Last_Name": values.lastName,
                                                "Gender": values.gender,
                                                "Status": "active",
                                                "Profile_Pic_Path": apiImageUrl
                                            };

                                            setSubmitting(true);
                                            await updateProfileDataActionCall(formData);
                                            setSubmitting(false);
                                        } catch (error) {
                                            if (error.inner) {
                                                error.inner.forEach(err => {

                                                    setFieldError(err.path, err.message);
                                                });
                                            } else {
                                                console.error(error);
                                            }
                                            setSubmitting(false);
                                        }
                                    }}
                                >
                                    {({ values, errors, touched, handleChange, isSubmitting, setFieldValue }) => (
                                        <Form sx={{ alignItems: "center" }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6} >
                                                    <Field
                                                        name="firstName"
                                                        as={TextField}
                                                        label="First Name"
                                                        fullWidth
                                                        autoComplete="off"
                                                        value={values.firstName}
                                                        onChange={(e) => {
                                                            if (e.target.value.length > 1)
                                                                setFieldValue("firstName", e.target.value);
                                                            else {
                                                                setFieldValue("firstName", e.target.value.trim());
                                                            }
                                                        }}
                                                        error={touched.firstName && Boolean(errors.firstName)}
                                                        helperText={touched.firstName && errors.firstName}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Field
                                                        name="lastName"
                                                        as={TextField}
                                                        label="Last Name"
                                                        fullWidth
                                                        autoComplete="off"
                                                        value={values.lastName}
                                                        onChange={(e) => {
                                                            if (e.target.value.length > 1)
                                                                setFieldValue("lastName", e.target.value);
                                                            else {
                                                                setFieldValue("lastName", e.target.value.trim());
                                                            }
                                                        }}
                                                        error={touched.lastName && Boolean(errors.lastName)}
                                                        helperText={touched.lastName && errors.lastName}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <RadioGroup
                                                        name="gender"
                                                        value={values.gender}
                                                        onChange={handleChange}
                                                        sx={{ flexDirection: "row", justifyContent: { xs: "space-around", sm: "unset" } }}
                                                    >
                                                        <FormControlLabel value="male" checked={values?.gender?.toUpperCase() === "male".toUpperCase()} control={<Radio />} label="Male" />
                                                        <FormControlLabel value="female" checked={values?.gender?.toUpperCase() === "female".toUpperCase()} control={<Radio />} label="Female" />
                                                        <FormControlLabel value="other" checked={values?.gender?.toUpperCase() === "other".toUpperCase()} control={<Radio />} label="Other" />
                                                    </RadioGroup>
                                                    {(touched.gender && errors.gender) && <FormHelperText error >{errors.gender}</FormHelperText>}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Field
                                                        name="mobileNumber"
                                                        as={TextField}
                                                        label="Mobile Number"
                                                        fullWidth
                                                        autoComplete="off"
                                                        error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                                                        helperText={touched.mobileNumber && errors.mobileNumber}
                                                        disabled />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Field
                                                        name="email"
                                                        as={TextField}
                                                        label="E-mail"
                                                        fullWidth
                                                        autoComplete="off"
                                                        error={touched.email && Boolean(errors.email)}
                                                        helperText={touched.email && errors.email}
                                                        disabled />
                                                </Grid>


                                                <Grid item xs={12}>
                                                    <Field
                                                        name="dob"
                                                        as={TextField}
                                                        type="date"
                                                        label="Date of Birth"
                                                        fullWidth
                                                        value={values.dob}
                                                        error={touched.dob && Boolean(errors.dob)}
                                                        helperText={touched.dob && errors.dob}
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={(e) => {
                                                            const value = e.currentTarget.value
                                                            setFieldValue("dob", value)
                                                        }}
                                                        onBlur={() => {
                                                            formikRef.current?.validateField("dob");
                                                        }}
                                                        inputProps={{
                                                            max: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Field
                                                        name="anniversary"
                                                        as={TextField}
                                                        type="date"
                                                        label="Anniversary Date"
                                                        value={values.anniversary || ""}
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true, }}
                                                    />
                                                    <Box sx={winsize.screenSize.width > 600 ? { display: "none" } : { display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                        <Button
                                                            disabled={formikRef.current?.isSubmitting}
                                                            variant="contained"
                                                            fullWidth
                                                            sx={{ width: '100%',  mt: 3 }}
                                                            onClick={async () => {
                                                                if (formikRef.current) {
                                                                    formikRef.current.handleSubmit();
                                                                }
                                                            }}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={5} textAlign={"center"} sx={{ display: 'flex', justifyContent: { md: 'right', xs: 'center' }, alignItems: 'center' }}>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <img src={apiImageUrl === "" ? profileDetails?.Profile_Pic_Path : apiImageUrl || defaultAvatar} alt="Profile" style={{ height: "144px", objectFit: "contain", width: "144px", borderRadius: '100px' }} />
                                <label htmlFor="upload-picture" style={{ textDecoration: 'none', cursor: 'pointer', color: theme.palette.primary.main, fontSize: (winsize.screenSize.width < 600 && "12px"),marginTop:(winsize.screenSize.width < 600 && "15px") }} onClick={handleUploadClick}>
                                    {"Upload Picture".toUpperCase()}
                                </label>
                                <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={handlePictureChange} accept="Image/*" />
                                <Typography variant="body1" sx={{ fontWeight: 'bold', margin: '3px' }}>{profileDetails?.Full_Name}</Typography>
                                <Typography variant="body1" sx={{ fontWeight: 'bold', margin: '3px' }}>{profileDetails?.Email_Id}</Typography>
                                <Typography variant="body1" sx={{ fontWeight: 'bold', margin: '3px' }}>{profileDetails?.Mobile}</Typography>
                                <Button variant="outlined" sx={{ marginTop: '20px', margin: '3px', borderRadius: '50px', width: '100%', textTransform: "capitalize", color: theme.palette.headerbutton.textcolorone, border: ' 1px solid', borderColor: theme.palette.text.secondary }} onClick={() => setIsModalOpen(true)}>
                                    Change Password
                                </Button>
                                <Button variant="outlined" sx={{ marginTop: '20px', margin: '3px', borderRadius: '50px', width: '100%', textTransform: "capitalize", color: theme.palette.headerbutton.textcolorone, border: ' 1px solid', borderColor: theme.palette.text.secondary }} href="/accounts/deleteaccount">
                                    Delete Account
                                </Button>
                                <Button variant="contained" sx={{ marginTop: '20px', margin: '3px', borderRadius: '50px', width: '100%', textTransform: "capitalize" }} onClick={handleLogout} >
                                    Logout
                                </Button>

                            </Box>
                        </Grid>
                        <Box sx={winsize.screenSize.width < 600 ? { display: "none" } : { display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Button
                                disabled={formikRef.current?.isSubmitting}
                                variant="contained"
                                sx={{ width: '50%', borderRadius: '50px', mt: 8 }}
                                onClick={async () => {
                                    if (formikRef.current) {
                                        formikRef.current.handleSubmit();
                                    }
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Grid>
                </Container>
            );
    }, [profileDetails, apiImageUrl]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    return (
        <Box sx={{ minHeight: '80vh' }}>
            {userProfile}
            <Modal open={modalOpen} onClose={handleModalClose}>
                <Box>
                    <ChangePassword onClose={handleModalClose} />
                </Box>
            </Modal>
        </Box>
    );
};

export default UpdateProfile;
