import * as React from "react";
import { Box, Button, Grid, Skeleton, Tab, Tabs, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCart, placeOrderErrorShow, placeOrderPartnerReady } from "../../../store/slices/accounts/cart/CartSlice";
import CartPartner from "./CartPartner";
import { getUserSettings } from "../../../store/slices/AuthSlice";
import { fetchAddress } from "../../../store/slices/accounts/Address/Address";
import cartIsEmpty from '../../../assests/images/cartIsEmpty.png'
import cartInactive from '../../../../src/assests/images/cartInactive.png';
import cartActive from '../../../../src/assests/images/CartActive.png';
import useScreenSize from "../../../utils/ScreenWidthHight";

const Cart = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const { carts } = useSelector(state => state.cartSlice);
    const userDetails = useSelector(state => state.auth)
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const winSize = useScreenSize()
    const [fgPartnerOrderRequiredAmount, setFGPartnerOrderRequiredAmount] = React.useState(150);
    const isAuthenticated = userDetails.isAuthenticated;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getCart({ User_Email_Id: userDetails?.user?.Email_Id }));
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (userDetails?.isAuthenticated)
            addressFetch()
        else
            navigate("/auth/signin")
    }, [])

    useEffect(() => {
        (async () => {
            const res = await getUserSettings("CART_DELIVERY_CHARGE_BALANCER", false);
            if (res.status)
                setFGPartnerOrderRequiredAmount(res.data);
            setLoading(false);
        })()
    }, [])

    const addressFetch = (page = 1, pagesize = 50) => {
        dispatch(fetchAddress({
            formData: {
                "User_Email_Id": userDetails?.user?.Email_Id
            },
            pagination: { PageNo: page, PageSize: pagesize }
        }))
    }

    const varifyCart = (partnerId) => {
        let error = false;
        const partner = carts.filter((data) => data.Partner_Details_Id == partnerId)[0];
        const partnerAddressCheck = partner.Business_Type == "GROCERY" ? true : false;
        if (partnerAddressCheck) {
            if (!partner.User_Shipping_Address_Id) {
                error = true
            }
        }
        if (!error)
            for (let i = 0; i < partner.Products.length; i++) {
                let product = partner.Products[i];
                if (!(product.Quantity <= product.Availability_Stock)) {
                    error = true;
                    break;
                }

                if (!(product.User_Shipping_Address_Id) && partner.Business_Type != "GROCERY") {
                    error = true;
                    break;
                }
            }

        if (error) {
            dispatch(placeOrderErrorShow(partner.Partner_Details_Id))
        } else {
            dispatch(placeOrderPartnerReady(partner.Partner_Details_Id))
        }
        return error
    }

    const placeOrder = (partner) => {
        let noError = true
        if (partner == "ALL") {
            for (let i = 0; i < carts.length; i++) {
                let error = varifyCart(carts[i].Partner_Details_Id);
                if (noError) {
                    noError = !error
                }

            }
        } else {
            noError = !varifyCart(partner);
        }

        if (noError) {
            navigate(`/accounts/checkoutdetails/${partner}`)
        }
    }

    if (carts.length > 0) {
        return (
            <Grid container sx={{ minHeight: "100vh" }}>
                <Grid item xs={12} md={3.5} >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: 3, }}>
                        <Typography sx={{ fontSize: '24px', color: theme.palette.text.secondary }}>Cart</Typography>
                        {winSize.screenSize.width < 600 &&
                            <Box sx={{ display: 'flex', }}>
                                {loading ? (
                                    <Skeleton variant="rectangular" width={150} height={36} />
                                ) : (
                                    carts.length > 0 && (
                                        <Button onClick={() => placeOrder("ALL")} variant="contained">PLACE ALL ORDERS</Button>
                                    )
                                )}
                            </Box>
                        }
                    </Box>
                    {loading ? (
                        <>
                            {winSize.screenSize.width > 600 &&
                                <>
                                    <Skeleton variant="rectangular" width="90%" height={114} sx={{ margin: '0px 5px 5px 15px', borderRadius: '12px', border: `2px solid ${theme.palette.primary.main}` }} />
                                    <Skeleton variant="rectangular" width="90%" height={114} sx={{ margin: '0px 5px 5px 15px', borderRadius: '12px', border: `2px solid ${theme.palette.primary.main}` }} />
                                    <Skeleton variant="rectangular" width="90%" height={114} sx={{ margin: '0px 5px 5px 15px', borderRadius: '12px', border: `2px solid ${theme.palette.primary.main}` }} />
                                </>
                            }
                            {winSize.screenSize.width < 600 &&
                                <Skeleton variant="rectangular" width="270px" height={114} sx={{ margin: 3, borderRadius: '12px', border: `2px solid ${theme.palette.primary.main}` }} />
                            }
                        </>
                    ) : (
                        carts.length === 0 ? (
                            <Box sx={{ margin: 3, }}>
                                <img src={cartIsEmpty} alt="cartIsEmpty" style={{ display: "block", width: '40%', height: '37%', margin: 'auto', objectFit: 'contain', justifyContent: 'center', alignItems: 'center' }}></img>
                            </Box>
                        ) : (
                            <>
                                <Tabs
                                    value={value}
                                    orientation={winSize.screenSize.width < 600 ? "horizontal" : "vertical"}
                                    TabIndicatorProps={{
                                        style: { display: "none" }
                                    }}
                                    variant="scrollable"
                                    sx={{
                                        margin: 3,
                                        '& button': { borderRadius: "24px", border: "2px solid " + theme.palette.primary.main, maxWidth: { xs: '270px', sm: "unset" } },
                                        '& button.Mui-selected': { backgroundColor: theme.palette.primary.main, maxWidth: { xs: '270px', sm: "unset" }, },
                                    }}
                                    onChange={(e, newValue) => {
                                        setValue(newValue)
                                    }}
                                >
                                    {carts.map((data, index) => (
                                        <Tab
                                            key={index}
                                            style={data.ShowError ?
                                                { border: "2px solid " + theme.palette.error.main } : {}}
                                            label={
                                                loading ? (
                                                    <Skeleton variant="rectangular" width="100%" height={80} />
                                                ) : (
                                                    <Grid item xs={14} key={index} sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-evenly',
                                                        columnGap: { xs: "20px", sm: "20px" },
                                                        cursor: 'pointer',
                                                        margin: '3px',
                                                    }}>
                                                        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <img
                                                                src={data.Banner_Path}
                                                                alt="logo"
                                                                style={{
                                                                    width: '80px',
                                                                    height: '80px',
                                                                    borderRadius: '15px',
                                                                    objectFit: 'fill',
                                                                    ...(winSize.screenSize.width < 600 ? {
                                                                        marginLeft: "10px",
                                                                    } : {})
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', width: "130px" }}>
                                                            <Typography color={"WindowText"} fontSize={"14px"} >{data?.Partner_Name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={(winSize.screenSize.width < 600 ? 4 : 0)} sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            position: "relative",
                                                        }}>
                                                            <img
                                                                src={value === index ? cartActive : cartInactive}
                                                                alt="Cart Icon"
                                                                style={{ width: '34px', height: '34px', cursor: 'pointer' }}
                                                            />
                                                            <Typography sx={{ width: "24px", height: "24px", position: "absolute", top: -10, right: -5, color: value === index ? theme.palette.text.primary : "white", backgroundColor: value === index ? "white" : "#2196F3", borderRadius: "50px", ...(winSize.screenSize.width < 600 ? { right: 8 } : {}) }} >{data?.Products?.length}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                            sx={{ margin: "0 5px 5px 0" }}
                                            onClick={() => {
                                                setValue(index);
                                            }}
                                        />
                                    ))}
                                </Tabs>
                            </>
                        )
                    )}
                </Grid>
                <Grid item xs={12} md={8.5} sx={{ minHeight: "100vh" }}>
                    {winSize.screenSize.width > 600 &&
                        <Box sx={{ display: 'flex', justifyContent: 'right', margin: 3 }}>
                            {loading ? (
                                <Skeleton variant="rectangular" width={150} height={36} />
                            ) :

                                (carts.length > 0 && (
                                    <Button onClick={() => placeOrder("ALL")} variant="contained">PLACE ALL ORDERS</Button>
                                ))

                            }
                        </Box>
                    }
                    {carts[value] &&
                        <CartPartner partner={carts[value]} placeOrder={placeOrder} fgPartnerOrderRequiredAmount={fgPartnerOrderRequiredAmount} />}
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Box sx={{ width: "100%", height: "100%" }} >
                <Typography sx={{ fontSize: '24px', color: theme.palette.text.secondary, ml: '30px' }}>Cart</Typography>
                {carts.length === 0 &&
                    <Box sx={{ margin: 3, }}>
                        <img
                            src={cartIsEmpty}
                            alt="cartIsEmpty" style={{ display: "block", width: '40%', height: '37%', margin: 'auto', objectFit: 'contain', justifyContent: 'center', alignItems: 'center' }} ></img>
                    </Box>
                }
            </Box>
        )
    }
}
export default Cart

