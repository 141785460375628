import { Box, Grid, Paper, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../retail/Footer';
import HeaderRides from './Header';
import RidesHome, { HomePagesModal } from './home/RidesHome';

const Rides = (props) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [pageModalOpen, setPageModalOpen] = useState(false)

  const customTheme = useMemo(() => {
    return createTheme({
      palette: {
        mode: prefersDarkMode ? 'dark' : 'light',
        primary: {
          main: prefersDarkMode ? '#ecce49' : '#FDD835', // Adjust according to the chosen theme
          light: prefersDarkMode ? '#63a4ff' : '#64b5f6',
          dark: prefersDarkMode ? '#004ba0' : '#1976d2',
          contrastText: '#ffffff', // White text
        },
        secondary: {
          main: prefersDarkMode ? '#dc004e' : '#f50057', // Adjust according to the chosen theme
          light: prefersDarkMode ? '#ff5c8d' : '#ff4081',
          dark: prefersDarkMode ? '#9a0036' : '#c51162',
          contrastText: '#ffffff', // White text
        },
        error: {
          main: '#f44336',
          light: '#e57373',
          dark: '#d32f2f',
          contrastText: '#ffffff', // White text
        },
        warning: {
          main: '#ff9800',
          light: '#ffb74d',
          dark: '#f57c00',
          contrastText: '#ffffff', // White text
        },
        info: {
          main: '#2196f3',
          light: '#64b5f6',
          dark: '#1976d2',
          contrastText: '#ffffff', // White text
        },
        success: {
          main: '#4caf50',
          light: '#81c784',
          dark: '#388e3c',
          contrastText: '#ffffff', // White text
        },
        text: {
          primary: prefersDarkMode ? '#ffffff' : '#212121', // Adjust according to the chosen theme
          secondary: '#757575', // Grey text
          disabled: '#bdbdbd', // Light grey text
          hint: '#bdbdbd', // Light grey text
          hover: "#FDD835"
        },
        background: {
          default: prefersDarkMode ? '#121212' : '#ffffff', // Adjust according to the chosen theme
          paper: prefersDarkMode ? '#212121' : '#fafafa', // Adjust according to the chosen theme
        },
        input: {
          borderColor: '#FDD835',
        },
        divider: '#757575', // Divider color
        action: {
          active: prefersDarkMode ? '#ffffff' : '#FDD835', // Adjust according to the chosen theme
          hover: prefersDarkMode ? '#424242' : '#eeeeee', // Adjust according to the chosen theme
          selected: '#C0C0C0', // Grey selected color
          disabled: '#616161', // Grey disabled color
          disabledBackground: prefersDarkMode ? '#424242' : '#f5f5f5', // Adjust according to the chosen theme
        },
        extra: {
          shadowColorOne: prefersDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.20)',
          linkcolor: '#000000',
          primary: "#27B6CC",
          contrastText:'#FFFFFF'
        },
        headerbutton: {
          textcolorone: '#000000',
          backgroundcolorone: '#ffffff',

        },
        header: {
          backgroundcolor: prefersDarkMode ? '#121212' : '#fafafa'
        },

        grocery: {
          main: '#149C4E', // Adjust according to the chosen theme
        },


      },
      typography: {
        fontFamily: [
          'Mulish'
        ].join(','),
      },
    })
  }, [prefersDarkMode]);

  return (
    <ThemeProvider theme={customTheme}>
      <Grid container style={{
        height: '100vh',
        overflowY: 'auto',
        overflowY: 'scroll',
        '&::WebkitScrollbar': {
          display: 'none'
        },
        scrollbarWidth: 'none'
      }}>
        <Grid item xs={12}>
          <Paper style={{}}>
            <HeaderRides pageModalOpen={pageModalOpen} setPageModalOpen={setPageModalOpen} />

          </Paper>
          {/* <RidesHome/> */}
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ paddingTop: 60 }}>
            <Outlet />
            <Footer />
            <HomePagesModal setPageModalOpen={setPageModalOpen} pageModalOpen={pageModalOpen} />
          </Paper>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
export default Rides