import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, TextField, Checkbox, FormControlLabel, InputAdornment, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useLocation } from 'react-router-dom';
import useScreenSize from '../../../../utils/ScreenWidthHight';
// import { productSearch } from "../../../store/slices/retail/home/wishlistSlice";

const OrdersSideBar = ({ theme, selectedCheckbox, handleCheckboxChange, productSearchCall, value, onChange }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchQuery, setSearchQuery] = useState("");
    const [wishlist, setWishlist] = useState([])
    const userDetails = useSelector(state => state.auth);
    const winsize = useScreenSize()


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        productSearchCall(e.target.value)
    };



    // useEffect(() => {
    //     if (location.pathname.includes('/accounts/wishlist')) {
    //         handleCheckboxChange({ target: { value: 'retail' } });
    //     } else if (location.pathname.includes('/grocery/wishlist')) {
    //         handleCheckboxChange({ target: { value: 'grocery' } });
    //     }
    // }, [location, handleCheckboxChange]);

    const handleChange = (event) => {
        const value = event.target.value;
        handleCheckboxChange(event);
        if (value === 'retail') {
            navigate('/accounts/orders');
        } else if (value === 'grocery') {
            navigate('/accounts/grocery/orders');
        }
    };


    return (
        <Grid item xs={10.5} md={3} style={{ paddingLeft: {xs:"0px",sm:'20px'}, display: "flex", flexDirection: "column", }}>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row", md: "column" }, justifyContent: "space-between" }} >
                <Typography sx={{ fontSize: '24px', color: theme.palette.text.secondary, marginY: '10px' }}>
                    Orders
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                    <TextField sx={{ width: { md: '300px', sm: "300px", xs: "100%" } }}
                        fullWidth
                        placeholder="Search Order"
                        size={winsize.screenSize.width < 600 ? "medium" : "small"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: theme.palette.primary.main, }} />
                                </InputAdornment>
                            ),
                            sx: { borderRadius: {xs:"16px",sm:"6px"} }
                        }}
                        value={value}
                        onChange={onChange}
                    />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: "row", sm: 'column' },justifyContent:{xs:"center",sm:"flex-start"} }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedCheckbox === 'retail'}
                            onChange={handleChange}
                            value="retail"
                        />
                    }
                    label={"Retail"}
                    sx={{
                        marginTop: '10px',
                        color: selectedCheckbox === 'retail' ? theme.palette.primary.main : 'inherit',
                        width:{xs:"45%",sm:"auto"},
                        fontWeight:"bold"
                    }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedCheckbox === 'grocery'}
                            onChange={handleChange}
                            value="grocery"
                        />
                    }
                    label="Grocery"
                    sx={{
                        marginTop: '10px',
                        color: selectedCheckbox === 'grocery' ? theme.palette.primary.main : 'inherit',
                        width:{xs:"45%",sm:"auto"}
                    }}
                />
            </Box>
        </Grid>
    );
};

export default OrdersSideBar;
