import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Container, Typography, Button, Input, TextField } from '@mui/material'
import GoogleMapReact from 'google-map-react';
import { envConfig } from '../../../store/slices/CommonAxios';
import axios from 'axios';
import { useTheme } from '@emotion/react';
import GoogleAutoComplete from './GoogleAutoComplete';
import { isEmpty } from '../../../utils';
const GoogleMap = (props) => {
  const { latitude = 12.956028, longitude = 77.694848 } = props;
  const [userCurrentGeoLocation, setUserCurrentGeoLocation] = useState({ latitude: latitude, longitude: longitude })
  const [pinCenter, setPinCenter] = useState([userCurrentGeoLocation.latitude, userCurrentGeoLocation.longitude]);
  const [marker, setMarker] = useState();
  const [seconds, setSeconds] = useState(-1);
  const [gioLocations, setGioLocations] = useState({ latitude: userCurrentGeoLocation.latitude, longitude: userCurrentGeoLocation.longitude });
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({});
  const [oldLatLon, setOldLanLon] = useState();
  const [searchSelected, setSearchSelected] = useState(false);
  const [currentMap, setCurrentMap] = useState(true);
  const [markerMap, setMarkerMap] = useState()
  const locationSearch = useRef();
  const theme = useTheme()

  useEffect(() => {
    if (props?.editStatus) {
      setUserCurrentGeoLocation({ latitude: latitude, longitude: longitude })
      setPinCenter([latitude, longitude]);
      setCurrentMap(!currentMap);
    }
    else {
      callGeoLoaction()
    }
  }, [])

  useEffect(() => {
    getLocation(gioLocations);
  }, [gioLocations]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 0) { setSeconds(seconds - 1) }
      else {
        getMapPin();
        try {
          if (searchSelected) {
            locationSearch.current?.clear();
            locationSearch.current?.setAddressText("");
            setSearchSelected(false);
          }
        } catch (error) {
        }
        setSeconds(2);
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  useEffect(() => {
    if (marker) {
      setSeconds(2);
    }
  }, [marker]);




  const loadMap = (map, maps) => {
    const marker = new maps.Marker({
      position: { lat: userCurrentGeoLocation.latitude, lng: userCurrentGeoLocation.longitude },
      map,
      // draggable: true
      center: true
    })

    setMarker(marker);
    map.addListener('center_changed', function () {
      marker.setPosition(map.getCenter());
    });
  };

  const getMapPin = () => {
    let latChange = false;
    let lngChange = false;
    try {
      if (marker.getPosition().lat() != gioLocations.latitude) {
        latChange = true;
      }
      if (marker.getPosition().lng() != gioLocations.longitude) {
        lngChange = true;
      }
      if (latChange && lngChange) {
        setGioLocations({ latitude: marker.getPosition().lat(), longitude: marker.getPosition().lng() })
      } else if (latChange && !lngChange) {
        setGioLocations({ ...gioLocations, latitude: marker.getPosition().lat() })
      }
      else if (!latChange && lngChange) {
        setGioLocations({ ...gioLocations, longitude: marker.getPosition().lng() })
      }
    } catch (error) {

    }
  }



  const getLocation = (data) => {
    setLoading(true);
    if (JSON.stringify(oldLatLon) != JSON.stringify({ latitude: data.latitude, longitude: data.longitude })) {
      setOldLanLon({ latitude: data.latitude, longitude: data.longitude });
      setLocation({ latitude: data.latitude, longitude: data.longitude });
      axios.get(`https://reverse-geocode.meepaisa.com/reverse?format=json&lat=${data.latitude}&lon=${data.longitude}`).then(async (response) => {
        if (isEmpty(response.data?.error)) {
          let locationInfo = response.data?.display_name || "";
          setLocation({ latitude: data.latitude, longitude: data.longitude, zipcode: response.data?.address?.postcode || false, locationInfo, address: response.data?.address });
          setPinCenter([data.latitude, data.longitude])
          props.onAddress({ locationInfo: locationInfo, latitude: data.latitude, longitude: data.longitude, postcode: response.data.address.postcode })
          // setZipcode(location.zipcode);
          setLoading(false)
        }
        else {

        }
      }
    ).catch(async (reason) => {
          setLoading(false);
          getLocation(data);
        });
    } else {
      setLoading(false);
    }
  }




  const getGeoLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          resolve({ status: true, data: position.coords })
        }, (err) => {
          resolve({ status: false, error: err.message })
        }
        )
      }
      else {
        reject({ status: false, error: "can't get location" })
      }

    })
  };

  const callGeoLoaction = async () => {
    const response = await getGeoLocation()
    if (response.status) {
      setUserCurrentGeoLocation({ latitude: response.data.latitude, longitude: response.data.longitude })
    }
  }

  const setOptionLatLng = (lat, lng) => {
    setUserCurrentGeoLocation({ latitude: lat, longitude: lng })
    setPinCenter([lng, lat]);
    setCurrentMap(!currentMap);
  }



  return (
    <Container sx={{ width: "100%", display: "flex", flexDirection: "column", gap: .5 }} >
      <Box sx={{ width: "100%", }}>
        <GoogleAutoComplete onOptionSelect={setOptionLatLng} />
      </Box>
      <Box sx={{ minHeight: '70vh', height: "50vh", width: '100%', borderRadius: "0 0px 12px 12px", overflow: "hidden" }}    >
        {currentMap ?
          <GoogleMapReact
            bootstrapURLKeys={{ key: envConfig.GOOGLE_MAP_WEB_API_KEY }}
            defaultZoom={15}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
            center={pinCenter}
          /> : <GoogleMapReact
            bootstrapURLKeys={{ key: envConfig.GOOGLE_MAP_WEB_API_KEY }}
            defaultZoom={15}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
            center={pinCenter}
          />
        }
      </Box>
      <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }} >
        <Typography sx={{ width: "80%", color: theme.palette.text.secondary }} >{location?.locationInfo || "Currently We are not Serving in Your Selected Location"}</Typography>
        <Button sx={{ width: "max-content", maxHeight: 30, minHeight: 30 }} variant="contained" onClick={() => {
          props.mapState(false)
        }}
          disabled={isEmpty(location?.locationInfo)}
        > okay </Button>
      </Box>
    </Container >
  )
}

export default GoogleMap