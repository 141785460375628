import React, { useEffect, useState } from "react";
import {Box,Typography,Button,useTheme,CardActionArea,IconButton,CardMedia,Skeleton,} from "@mui/material";
import cartIcon from "../../../assests/images/shareIcons.png";
import minus from "../../../assests/images/minus.png";
import plus from "../../../assests/images/plus.png";
import { useNavigate } from "react-router-dom";
import { productWishCheckAction, productWishCreateAction, productWishDeleteAction } from "../../../store/slices/retail/ProductDetails/ProductDetailsActions";
import { useDispatch, useSelector } from "react-redux";
import heartunfill from '../../../assests/images/wishlistInActive.png'
import heartfill from '../../../assests/images/wishlistActive.png'
import { GlobalContext } from "../../../Context";
import { RWebShare } from "react-web-share";
import { envConfig } from "../../../store/slices/CommonAxios";
import AutoAdjustText from "../../../utils/AutoAdjustText";
import { refreshIdUpdate } from "../../../store/slices/CommonSlice";

const RecentlyViewCard = ({
  image = "",
  brandName = "",
  shortDescription = "",
  originalPrice = "",
  partnerSellingPrice = "",
  item = "",
  productName = "",
  handleNavigation = (() => { }),
  isSkeleton = false,
  isActive = true,

}) => {
  const theme = useTheme();
  const BASEURL = envConfig.BASEURL;
  const [cart, setCart] = useState(0);
  const [showCartAdjustment, setShowCartAdjustment] = useState(false);
  const [wishState, setWishState] = useState(false)
  const [wishIdHandle, setWishIdHandle] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const Refresh_Id = useSelector(state => state.commonReducer.Refresh_Id)
  const path = window.location.pathname
  const formData = {
    User_Email_Id: auth?.user?.Email_Id,
    Product_Id: item.Product_Id,
    Partner_Details_Id: item.Partner_Details_Id
  }
  const { toolTipActive } = React.useContext(GlobalContext);


  const originalPriceFontSize = originalPrice.length > 5 ? "14px" : "16px";
  const partnerSellingPriceFontSize = partnerSellingPrice.length > 5 ? "14px" : "18px"



  useEffect(() => {
    if (auth?.isAuthenticated && !isSkeleton) {
      wishCheckActionCall(formData)
    }

  }, [auth?.isAuthenticated])

  useEffect(() => {
    if (Refresh_Id?.id === item?.Product_Id) {
      if (auth?.isAuthenticated) {
        setWishState(false)
        wishCheckActionCall(formData)
      }
    }
  }, [Refresh_Id, auth?.isAuthenticated])


  const wishCheckActionCall = async (formData) => {
    const response = await productWishCheckAction(formData)
    if (response.status) {
      if (response.data) {
        setWishState(response.data?.length > 0 ? true : false)
        if (response.data?.length > 0)
          setWishIdHandle(response.data[0]?.Wish_Id)
      }
    }
    else
      setWishState(false)
  }


  const handleDecrementSize = () => {
    if (cart > 1) {
      setCart((prevSize) => prevSize - 1);
    } else {
      setShowCartAdjustment(false);
    }
  };

  const handleIncrementSize = () => {
    setCart((prevSize) => prevSize + 1);
  };
  if (!isSkeleton)
    return (
      <Box
        sx={{
          minWidth: '169px',
          maxWidth: "169px",
          height: "434px",
          marginBottom: "5px",
          marginRight: "10px",
          borderRadius: "12px",
          overflow: "hidden",
          cursor: isActive ? "pointer" : "default",
          opacity: isActive ? 1 : 0.5,
          pointerEvents: isActive ? "auto" : "none",
          transition: "opacity 0.3s ease-in-out",
        }}
      >
        <Box
          sx={{
            border: `0.5px solid`,
            borderColor: theme.palette.input.borderColor,
            borderRadius: "inherit",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >

          <IconButton

            sx={{
              position: "absolute",
              top: '0px',
              right: '0px',
              zIndex: 1,
            }}
            onClick={
              auth?.isAuthenticated ?
                async () => {
                  const formData = {
                    User_Email_Id: auth?.user?.Email_Id,
                    Product_Id: item.Product_Id,
                    Partner_Details_Id: item.Partner_Details_Id
                  }
                  if (wishState) {
                    const response = await productWishDeleteAction({ Wish_Id: wishIdHandle })
                    if (response.status) {
                      setWishState(false)
                      setWishIdHandle("")
                      toolTipActive("success", " Product removed from wishlist...");
                      dispatch(refreshIdUpdate(item.Product_Id))
                    }
                  }
                  else {
                    const response = await productWishCreateAction(formData)
                    if (response.status) {
                      setWishState(response.status)
                      toolTipActive("success", "Product Added to Wishlist...");
                      dispatch(refreshIdUpdate(item.Product_Id))
                    }
                  }
                }
                :
                () => {
                  navigate('/auth/signin')
                }
            }
          >
            <CardMedia
              image={wishState ? heartfill : heartunfill}
              alt="Heart Icon"
              sx={{
                width: 19,
                height: 19,
              }}
            />
          </IconButton>
          <CardActionArea onClick={() => {
            if (path.includes("retail"))
              navigate('/retail/products/' + item.Product_Id)
            else
              navigate('/grocery/products/' + item.Product_Id)
          }}
          >

            <img
              src={image}
              alt={brandName}
              style={{ width: "100%", height: "202px", objectFit: "contain" }}
            />
          </CardActionArea>

          <Typography
            sx={{
              backgroundColor: theme.palette.extra.todaypricebgcolor,
              borderTopRightRadius: "25px",
              color: 'Window',
              paddingLeft: "8%"
            }}
          >
            {item.Discount_Type === "P" ? item.Discount_Label : item.Cashback_Label} {item.Discount_Type === "A" && "₹"} {item.Discount} {item.Discount_Type === "P" ? "%" : "/-"} OFF
          </Typography>
        </Box>


        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <AutoAdjustText style={{ height: "auto", width: "100%", fontWeight: '500', }} numLines={1}>
                {brandName}
              </AutoAdjustText>

              <RWebShare
                data={{
                  text: shortDescription,
                  url: `${BASEURL}/retail/products/${item.Product_Id}`,
                  title: brandName,
                }}
              >
                <IconButton>
                  <img
                    src={cartIcon}
                    alt="Cart Icon"
                    style={{ width: "20px", height: "auto", cursor: "pointer" }}
                  />
                </IconButton>
              </RWebShare>
            </Box>

            <AutoAdjustText style={{ fontWeight: "bold", width: '100%', height: 'auto' }} numLines={1}>
              {productName}
            </AutoAdjustText>
          </Box>
          <AutoAdjustText style={{ color: theme.palette.text.secondary, width: '100%', height: 'auto', minHeight: "50px" }} numLines={2}>
            {shortDescription}
          </AutoAdjustText>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }} >
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", gap: 0.5 }} >
              <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontWeight: "700" }}>
                M.R.P
              </Typography>
              <Typography
                style={{
                  textDecoration: "line-through",
                  fontSize: originalPriceFontSize,
                  color:theme.palette.text.secondary
                }}
              >
                {originalPrice}
              </Typography>
            </Box>
            <Typography sx={{ fontSize: partnerSellingPriceFontSize, fontWeight: "700" }} >
              &#8377; {partnerSellingPrice}
            </Typography>
          </Box>

          {showCartAdjustment ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={minus}
                alt="Minus Icon"
                style={{
                  width: "30px",
                  height: "auto",
                  marginRight: "27px",
                  cursor: "pointer",
                }}
                onClick={handleDecrementSize}
              />
              <Typography
                variant="body1"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  padding: "5px 10px",
                  borderRadius: "5px",
                  border: "1px solid",
                  borderColor: theme.palette.extra.cartBorderColor,
                  margin: "0 5px",
                }}
              >
                {cart}
              </Typography>
              <img
                src={plus}
                alt="Plus Icon"
                style={{
                  width: "30px",
                  height: "auto",
                  marginLeft: "27px",
                  cursor: "pointer",
                }}
                onClick={handleIncrementSize}
              />
            </Box>
          ) : (
            <Button
                onClick={handleNavigation}
                disabled={!isActive}
              style={{
                backgroundColor: theme.palette.extra.primary,
                color: theme.palette.primary.contrastText,
                width: "169px",
              }}
            >
              Add to Cart
            </Button>
          )}
        </Box>
      </Box>
    );
  else {
    return (

      <Box
        sx={{
          height: "434px",
          marginBottom: "5px",
          marginRight: "10px",
          borderRadius: "12px",
          overflow: "hidden",
          cursor: "pointer",
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{ width: "250px", height: "100%" }}
        />
      </Box>
    )
  }
};

export default RecentlyViewCard;
