import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retailProducts as retailProductsAction } from "../../../store/slices/retail/home/AllProductsSlice";
import AllProductsCard from "../../components/cards/AllProductsCard";
import { Typography, Box, Pagination, Skeleton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { GlobalContext } from "../../../Context";
import useScreenSize from "../../../utils/ScreenWidthHight";
import noProductFound from '../../../assests/images/NoProductFound.png'



const AllProducts = (props) => {
  const dispatch = useDispatch();
  const { retailProducts, retailPagination, rLoading } = useSelector((state) => state.allProducts);
  const { searchParams, updateQueryParam } = useContext(GlobalContext);

  const [reCall, setReCall] = useState();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth);
  const theme = useTheme();
  const location = useLocation();

  const winSize = useScreenSize();
  const cardWidth = useMemo(() => {
    let cardWidth = winSize.getWidthForGiveInput(8);
    if (cardWidth <= 170) {
      cardWidth = 170;
    }
    if (winSize.screenSize.width / 2 < cardWidth + 50) {
      cardWidth = winSize.screenSize.width / 2 - 20;
    }
    return cardWidth + "px";
  }, [winSize.screenSize.width]);

  useEffect(() => {
    if (searchParams.get("PageNo")) {

    }
    else {
      let querys = "";
      searchParams.forEach((value, key) => {
        querys += "&" + key + "=" + value
      });
      navigate('/retail/products?PageNo=1' + querys, { replace: true });
    }

  }, [searchParams]);


  useEffect(() => {
    if (!rLoading) {
      callProductsAction();
    } else {
      setReCall(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!rLoading && reCall) {
      setReCall(false);
      callProductsAction();
    }
  }, [rLoading]);

  const callProductsAction = (pageno = 1, totalPages = 10) => {

    const callFilter = {
      Search: searchParams.get('Search') || '',
      Business_Type: "RETAIL",
      Status: 'Active',
      Brand_Id: searchParams.get('Brand_Id') ? searchParams.get('Brand_Id').split(',') : [],
      Partner_Details_Id: searchParams.get('Partner_Details_Id') ? searchParams.get('Partner_Details_Id').split(',') : [],
      Category_Id: searchParams.get('Category_Id') ? searchParams.get('Category_Id').split(',') : [],
      Sub_Category_Id: searchParams.get('Sub_Category_Id') ? searchParams.get('Sub_Category_Id').split(',') : [],
      Is_Top_Product: searchParams.get('Is_Top_Product') || '',
      Is_Popular: searchParams.get('Is_Popular') || '',
      Is_Verified: searchParams.get('Is_Verified') || '',
      Whats_Hot: searchParams.get('Whats_Hot') || '',
      Is_Upcoming: searchParams.get('Is_Upcoming') || ''
    };

    if (searchParams.get('Min_Discount') > 0 || searchParams.get('Max_Discount') > 0) {
      callFilter.Min_Discount = searchParams.get('Min_Discount') || 0
      callFilter.Max_Discount = searchParams.get('Max_Discount') || 100
    }

    if (searchParams.get('Min_Price') > 0 || searchParams.get('Max_Price') > 0) {
      callFilter.Min_Price = searchParams.get('Min_Price') || 0
      callFilter.Max_Price = searchParams.get('Max_Price')
    }

    dispatch(retailProductsAction({ filters: callFilter, pagination: { PageNo: searchParams.get('PageNo'), PageSize: 30, SortBy: "Product_Id", SortOrder: "DESC" } }));
  }

  return (
    <Box>
      <Typography sx={{ fontSize: '24px', fontWeight: 'bold', color: theme.palette.text.secondary, }}>All Products</Typography>


      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
        gap={1}
      >
        {(retailProducts.length > 0 && !rLoading) &&

          retailProducts.map((item, index) => (
            <AllProductsCard
              key={item.Partner_Product_Id}
              image={item.Product_Image}
              brandName={item.Brand_Name}
              productName={item.Product_Name.length > 15 ?
                item.Product_Name.charAt(0).toUpperCase() + item.Product_Name.toLowerCase().substring(1, 20) + '..' :
                item.Product_Name.charAt(0).toUpperCase() + item.Product_Name.toLowerCase().substring(1)}
              shortDescription={item.Product_Short_Description}
              originalPrice={item.Original_Price.toString()}
              partnerSellingPrice={item.Partner_Selling_Price.toString()}
              item={item}
              handleNavigation={() => {
                if (auth?.isAuthenticated !== null) {
                  props.handleSetModalProductId(item.Product_Id)
                  props.handleModalOpen()
                }
                else {
                  navigate('/auth/signin')
                }
              }}
            />
          ))

        }
        {
          rLoading &&
          Array(10).fill(1).map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  maxWidth: "169px",
                  width: cardWidth,
                  height: "434px",
                  borderRadius: "12px",
                  overflow: "hidden",

                }}
              >
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: "100%",
                    height: "100%"
                  }}
                />
              </Box>
            )
          })
        }
        {(retailProducts.length !== 0) && <Box sx={{ width: "100%", height: "10vh", display: "flex", justifyContent: "center", alignItems: "center" }} >
          <Pagination page={Number(searchParams.get('PageNo')) || 1} color="primary" count={retailPagination.TotalPages}
            onChange={(e, page) => {
              updateQueryParam([{ PageNo: page }])
            }}

          />
        </Box>}
      </Box>

      {(retailProducts.length == 0 && !rLoading) &&
        <Box sx={{ width: "100%", height: "100vh", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", }} >

          <img src={noProductFound} style={{  width: '70%', display: 'block', margin: '20px auto' }} />
        </Box>
      }


    </Box>
  );
};

export default AllProducts;