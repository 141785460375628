import { Box, CardMedia, Typography, Button, Card } from '@mui/material';
import React, { useEffect } from 'react';
import useScreenSize from '../../../utils/ScreenWidthHight';
import { fetchPrivacyPolicy } from '../../../store/slices/retail/home/PrivacyPolicy';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { fetchNewsEvents } from '../../../store/slices/retail/home/NewsEventSlice';
import parse from 'html-react-parser'
import AutoAdjustText from '../../../utils/AutoAdjustText';
import { height } from '@mui/system';
 
 
const NewsEvents = () => {
  const winSize = useScreenSize();
  const theme = useTheme()
  const newsEvents = useSelector(state => state.newsevent.newsEventsData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchNewsEvents({ News_Events_Id: "", Status: "Active" }));
 
  }, []);
 
 
  return (
 
    <Box>
      {/* Left Side */}
     
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }}>
          {winSize.screenSize.width > 600 && <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Box sx={{display:'flex', flexDirection:'row'}}>
            <Typography sx={{ display: 'flex', color: theme.palette.extra.primary, fontSize:'95px' , fontWeight:'bold'}}>
              News
            </Typography>
            <Typography  sx={{ display: 'flex', color: theme.palette.extra.primary ,fontSize:'95px' , fontWeight:'bold',ml:'20px'}}>
              &
              </Typography>
            </Box>
            <Typography style={{ display: 'flex', fontSize: '95px', fontWeight: 'bold',color: theme.palette.extra.primary ,}}>
              Events
            </Typography>
          </Box>}
          {winSize.screenSize.width < 600 && (
            <Box sx={{ display: "flex", width: '100%', alignItems: "flex-start", justifyContent: 'left',}}>
              <Typography sx={{ fontSize: '34px', color: theme.palette.extra.primary, fontWeight: 'bold', mr: 1, ml: { md: '0px', sm: '0px', xs: '26px' } }}>
                News
              </Typography>
              <Typography sx={{ fontSize: '34px', color: theme.palette.extra.primary, fontWeight:"bold" }}>
                &
              </Typography>
              <Typography sx={{ fontSize: '34px', color: theme.palette.extra.primary, fontWeight: 'bold', ml: 1 }}>
                Events
              </Typography>
            </Box>
          )}
         
        </Box>
        {winSize.screenSize.width > 1050 &&
          <Box sx={{ flex: '1' }}>
            <CardMedia
              component="img"
              image={require("../../../assests/images/tickets.png")}
              sx={{
                width: '611px',
                height: '499px',
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
 
 
              }}
            />
          </Box>}
 
      </Box>
      
     { winSize.screenSize.width >600 && <Box sx={{
        width: "100%", display: "flex", flexDirection: "column", alignItems: "center", maxHeight: "600px", overflowY: "auto"}} >
        {newsEvents?.map((newsAndEvents, index) => (
          <Box
            key={index}
            sx={{
              width: '1400px',
              maxWidth: "98%",
              border: '1px solid' + theme.palette.extra.primary,
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              maxHeight: "auto",
              borderRadius: "20px",
              flexWrap: "wrap",
              flexDirection: "row",
 
 
            }}
          >
         
            <Box sx={{ flex: "1 1 50%" }}>
              <Typography sx={{
                marginBottom: "10px", marginLeft: "3%", fontSize: '24px', fontWeight: 'bold', display: "flex", flexWrap: "wrap",
                flexDirection: "row"
              }}>{newsAndEvents.Title}</Typography>
              <Box sx={{
                alignItems: 'center', marginBottom: '10px', marginLeft: "3%", display: "flex", flexWrap: "wrap",
                flexDirection: "row"
              }}>
                <Typography sx={{
                  padding: '6px', borderRadius: '3px', fontWeight: 'bold',
                  backgroundColor: newsAndEvents?.Type === 'NEWS' ? theme.palette.extra.newsAndEventNewsType :
                    newsAndEvents?.Type === 'EVENT' ? theme.palette.primary.main :
                      theme.palette.primary.main,
                  marginRight: '10px',
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row"
                }} >{newsAndEvents?.Type}</Typography>
                <Typography variant="body1">{newsAndEvents.Modified_Date}</Typography>
              </Box>
              <Box sx={{
                flex: "1 1 50%", marginLeft: "2%", display: "flex", flexWrap: "wrap",
                flexDirection: "row"
              }}>
 
                {parse(newsAndEvents.Description)}
 
                {parse(newsAndEvents.Short_Description)}
              </Box>
 
 
              </Box>
           
            {winSize.screenSize.width > 110 &&
              <img src={newsAndEvents?.Image} alt="Your Image" style={{ width: '313px', padding: '20px', height: '262px' }} />
            }
          </Box>
        ))}
      </Box>}
      {winSize.screenSize.width < 600 && (
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // maxHeight: "600px", 
          overflowY: "auto",
          rowGap:"10px"
        }} >
    
          {newsEvents?.map((newsAndEvents, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                border: '1px solid' + theme.palette.extra.primary,
                borderRadius: "5px",
                flexDirection: 'row',
                width: '90%',
                height: '100px',
               
              }}
            >
              <Box
                sx={{
                  flex: "3 1 75%",
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                }}
              >
                <Typography sx={{
                 marginLeft: "0%", fontSize: '12px', fontWeight: 'bold', display: "flex", flexWrap: "wrap",
                  flexDirection: "row"
                }}>{newsAndEvents.Title}</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '5px',
                  }}
                >
                  <Typography
                    sx={{
                      padding: '1px',
                      paddingX:"4px",
                      fontSize:'8px',
                      borderRadius: '3px',
                      fontWeight: 'bold',
                      backgroundColor:
                        newsAndEvents?.Type === 'NEWS'
                          ? theme.palette.extra.newsAndEventNewsType
                          : newsAndEvents?.Type === 'EVENT'
                            ? theme.palette.primary.main
                            : theme.palette.primary.main,
                      marginRight: '5px',
                      color:
                      newsAndEvents?.Type === 'NEWS'
                          ? "black"
                          : newsAndEvents?.Type === 'EVENT'
                            ? "white"
                            : theme.palette.primary.main,
                    }}
                  >
                    {newsAndEvents?.Type}
                  </Typography>
                  <Typography sx={{fontSize:'8px'}}>
                    {newsAndEvents?.Modified_Date?.slice(0,10)}
                  </Typography>
                </Box>
                {/* <Box
                  sx={{
                    marginTop: '5px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                   
                  }}
                >
                  <Typography sx={{ fontSize: '8px', }}>{parse(newsAndEvents.Description)}</Typography>
                  <Typography sx={{ fontSize: '8px', }}> {parse(newsAndEvents.Short_Description)}</Typography>
                </Box> */}

                <Box sx={{
                  flex: "1 1 50%", marginLeft: "0%", display: "flex", flexWrap: "wrap",
                  flexDirection: "row", fontSize:'8px'
                }}>

                  <AutoAdjustText style={{ width: "100%", fontSize: "8px", height:'30px'}} numLines={2} >{parse(newsAndEvents.Description)}</AutoAdjustText> 
                  {parse(newsAndEvents.Short_Description)}

                </Box>
              </Box>
              <Box
                sx={{
                  flex: "1 1 25%",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={newsAndEvents?.Image}
                  alt="News/Event Image"
                  style={{
                    width: '80px',
                    height:'60px',
                    objectFit: 'contain', 
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      )}

    
    </Box>
   
  );
};
 
export default NewsEvents;
 