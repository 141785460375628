import React from 'react';
import { Box, Card, CardActionArea, CardMedia, Grid, Skeleton, Typography } from "@mui/material";

import { Container, Link } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import useScreenSize from '../../../../utils/ScreenWidthHight';

const ProductGrid = ({ title, data, imgLabel, onViewMore, itemData, isSkeleton = false }) => {
    const theme = useTheme();
    const slicedData = data.slice(0, 6);
    const naviagate = useNavigate()
    const winSize = useScreenSize()
    if (!isSkeleton)
        return (
            <Card sx={{position: "relative" }}>
                <Container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',

                    boxShadow: '0px 9px 30px  ' + theme.palette.extra.cardShadowColorOne,
                    width: '100%',
                    maxWidth: { xs: "100%", sm: 600 },
                    minHeight: { xs: "310px", sm: 475 },

                    '& .MuiGrid-item': {
                        marginBottom: 1,
                    },
                    '@media (max-width: 600px)': {
                        maxWidth: '100%',
                        maxHeight: '100%',
                    },
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: { xs: "10px", sm: "unset" },
                    }}>
                        {<Typography sx={{ textAlign: 'start', fontSize: "19px", fontWeight: 'bold' }}>
                            {title}
                        </Typography>
                        }
                        {
                            (winSize.screenSize.width > 600) ?
                                <Grid container spacing={2}>
                                    {slicedData.map((item, index) => {

                                        return (
                                            <Grid item xs={5} sm={4} key={index}  >
                                                <Card sx={{
                                                    height: { xs: "100px", sm: 130 },
                                                    width: { xs: "100px", sm: 130 },
                                                    boxShadow: "none",
                                                    objectFit: "fill",
                                                }}
                                                    onClick={() => {
                                                        naviagate(itemData.onClickLink + data[index][itemData.idField])
                                                    }}
                                                >
                                                    <CardActionArea sx={{ height: "100%" }}>

                                                        <Box
                                                            sx={{
                                                                width: "100%",
                                                                paddingTop: "100%",
                                                                position: "relative",
                                                                overflow: "hidden",
                                                            }}
                                                        >
                                                            {
                                                                <CardMedia
                                                                    component="img"
                                                                    alt={`Image ${index + 1}`}
                                                                    image={item[imgLabel]}
                                                                    sx={{
                                                                        position: "absolute",
                                                                        top: { xs: "0%", sm: "0" },
                                                                        left: { xs: "30%", sm: "0" },
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        objectFit: "contain",
                                                                    }}
                                                                />

                                                            }
                                                        </Box>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        )
                                    }
                                    )}
                                </Grid>
                                :
                                <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", rowGap: "10px", alignItems: "center", }} >
                                    {slicedData.map((item, index) => {

                                        return (
                                            <Box sx={{ display: "flex", flexWrap: "wrap", width: "30%", }} key={index}  >
                                                <Card sx={{
                                                    height: { xs: "90px", sm: 130 },
                                                    width: { xs: "90px", sm: 130 },
                                                    boxShadow: "none",
                                                    objectFit: "contain",
                                                }}
                                                    onClick={() => {
                                                        naviagate(itemData.onClickLink + data[index][itemData.idField])
                                                    }}
                                                >
                                                    <CardActionArea sx={{ height: "100%" }}>

                                                        <Box
                                                            sx={{
                                                                width: "100%",
                                                                overflow: "hidden",
                                                            }}
                                                        >
                                                            {
                                                                <CardMedia
                                                                    component="img"
                                                                    alt={`Image ${index + 1}`}
                                                                    image={item[imgLabel]}
                                                                    sx={{
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        objectFit: "contain",
                                                                    }}
                                                                />

                                                            }
                                                        </Box>
                                                    </CardActionArea>
                                                </Card>
                                            </Box>
                                        )
                                    }
                                    )}
                                </Box>
                        }
                        {<Link href={onViewMore} sx={{
                            color: theme.palette.primary.main, textAlign: 'end', textDecoration: 'none', mt: 5,
                            "&:hover": {
                                textDecoration: 'underline'
                            },
                            fontWeight: 'bold',
                            position: "absolute",
                            bottom: 5,
                            right: 10
                        }}>
                            Explore More
                        </Link>

                        }
                    </Box>
                </Container>
            </Card>
        )
    else {
        return (
            <Card sx={{ maxWidth: "530px", marginTop: 2, marginLeft: 3 }}>
                <Container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',

                    boxShadow: '0px 9px 30px  ' + theme.palette.extra.cardShadowColorOne,
                    width: '100%',
                    maxWidth: 600,
                    height: 462,

                    '& .MuiGrid-item': {
                        marginBottom: 1,
                    },
                    '@media (max-width: 600px)': {
                        maxWidth: '100%',
                        maxHeight: '100%',
                    },
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: "space-around",
                        height: "100%"
                    }}>
                        {
                            <Skeleton
                                variant='text'
                            />
                        }
                        <Grid container spacing={2}>
                            {Array(6).fill(1).map((item, index) => (
                                <Grid item xs={6} sm={4} key={index}>
                                    <Card sx={{
                                        height: 130,
                                        width: 130,
                                        boxShadow: "none",
                                        objectFit: "fill",
                                    }}

                                    >
                                        {

                                            <Skeleton
                                                variant='rectangular'
                                                sx={{ width: "100%", height: "100%" }}
                                            />
                                        }
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                        {
                            <Skeleton
                                variant='text'
                            />
                        }
                    </Box>
                </Container>
            </Card>
        )
    }
}

export default ProductGrid;
