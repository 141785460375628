import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import ProductDetails from './ProductDetails'
import ProductPartners from './ProductPartners'
import { Button, CardMedia, IconButton, Modal, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import SimilarProducts from './SimilarProducts'
import { useLocation, useParams } from 'react-router-dom'
import { productDetailByProductId, } from '../../../store/slices/retail/ProductDetails/ProductDetailsActions'
import { useSelector } from 'react-redux'
import ProductModal from './ProductModal'
import ProductDetailsNew from './ProductDetailsNew'
import { GlobalContext } from '../../../Context'
import NoProductFound from '.././../../assests/images/NoProductFound.png'

const ProductDetail = () => {
  const { id } = useParams()
  const path = useLocation()
  const [partnerDetails, setPartnerDetails] = useState([])
  const [warranty, setWarranty] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [modalProductId, setModalProductId] = useState(null)
  const [selectedChart, setSelectedChart] = useState(0);
  const [cart, setCart] = useState(1);
  const [productSize, setProductSize] = useState([])
  const auth = useSelector(state => state.auth)
  const [productId, setProductId] = useState("")
  const { searchParams, updateQueryParam } = useContext(GlobalContext);
  const [value, setValue] = useState(0);
  const [rating,setRating]=useState(0)
  const [notFoundStatus, setNotFoundStatus] = useState(false)

  useEffect(() => {
    if (modalProductId !== null) {
      productSMDetailByProductIdCall({ Product_Id: [modalProductId] })
    }
  }, [modalProductId])

  const productSMDetailByProductIdCall = async (formData) => {
    const response = await productDetailByProductId(formData)
    if (response.status) {
      setCart(response.data[0])
    }
    else {
      setCart([])
    }
  }




  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const handleModalProductId = (id) => {
    setModalProductId(id)
  }

  if (!notFoundStatus)
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "center" }}  >
        <ProductDetails Email={auth?.user?.Email_Id || ""} id={id} setValue={setValue} setProductSize={setProductSize} setSelectedChartSize={setSelectedChart} productSize={productSize} setPartnerDetails={setPartnerDetails} setProductId={setProductId} setWarranty={setWarranty} setNotFoundStatus={setNotFoundStatus} />
        <Box sx={{ width: "90%", display: "flex", justifyContent: "center" }} >
          <ProductPartners partnerDetails={partnerDetails} email={auth?.user?.Email_Id} size={selectedChart} />
        </Box>
        <Box sx={{ width: "90%", display: "flex", justifyContent: "center" }} >
          <ProductDetailsNew value={value} setValue={setValue} Email={auth?.user?.Email_Id || ""} id={productId} setProductSize={setProductSize} setSelectedChartSize={setSelectedChart} productSize={productSize} setPartnerDetails={setPartnerDetails} Warranty_Details={partnerDetails} />
        </Box>
        <Box sx={{ width: "90%", display: "flex", justifyContent: "center" }} >
          <SimilarProducts handleModalOpen={handleModalOpen} handleModalProductId={handleModalProductId} Product_Id={productId} />
        </Box>
        <ProductModal modalOpen={modalOpen} handleModalClose={handleModalClose} productDetails={cart} handleModalProductId={handleModalProductId} modalProductId={modalProductId} />
      </Box>
    )
  else {
    return (
      // <Typography sx={{textAlign:"center",fontSize:"40px"}} >404 Page Not Found</Typography>
      <img src={NoProductFound} style={{ width: '50%', height: '50%', display: "block", margin:'auto'}} alt="No_Product_Found" ></img>
    )
  }

  // return <Typography>Testing.....</Typography>
}

export default ProductDetail