import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nodeApi, urlGenarator } from '../../CommonAxios'; 

const initialState = {
  categories: [],
  loading: false,
  error: null,
};

export const fetchCategories = createAsyncThunk(
  'category/fetchCategories',
  async (payload) => {
    try {
      const response = await nodeApi.post('/Home/Categories/Get', payload);
      return response.data.results; 
    } catch (error) {
      throw error;
    }
  }
);

const CategorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload; 
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default CategorySlice.reducer;



export const categoriesGetAction=(formData,Pagination)=>{
  return new Promise(async (resolve,reject)=>{
      await nodeApi.post(urlGenarator('/Home/Categories/Get',Pagination),formData).then((res)=>{
          if(res.data.Success_Response.Is_Data_Exist==="1"){
              resolve({status:true,data:res.data.results,Pagination:res.data.Pagination })
          }else{
              resolve({status:false,data:[]})
          }
      }).catch((err)=>{
          resolve({status:false,error:err.message})
      })
  })
}
export const subCategoriesGetAction=(formData,Pagination)=>{
  return new Promise(async (resolve,reject)=>{
      await nodeApi.post(urlGenarator('/Home/SubCategories/Get',Pagination),formData).then((res)=>{
          if(res.data.Success_Response.Is_Data_Exist==="1"){
              resolve({status:true,data:res.data.results,Pagination:res.data.Pagination})
          }else{
              resolve({status:false,data:[]})
          }
      }).catch((err)=>{
          resolve({status:false,error:err.message})
      })
  })
}