
import { Button, Container, Modal } from "@mui/material";
import Giftpage from "./Gift_Page";
import { Box } from "@mui/system";
import { useNavigate, } from 'react-router-dom';
import { useTheme } from "@emotion/react";

const Gifts = (props) => {
    return (
        <Container maxWidth="xl">
            < Giftpage />
        </Container>
    );
};
export default Gifts

export const HomePagesModal = ({ setPageModalOpen, pageModalOpen }) => {
    const navigate = useNavigate()
    const theme=useTheme()
    const pages = [
        { title: "Tickets", link: "/accounts/tickets" },
        { title: "Shops", link: "/shop" },
        { title: "Notifications", link: "/accounts/Notification" },
        { title: "News&Events", link: "/accounts/newsandevents" },
        { title: "Review&Ratng", link: "/accounts/reviewsandrating" },
        { title: "Faqs", link: "/accounts/faqs" },
        { title: "Terms&Conditions", link: "/accounts/terms&conditions" },
        { title: "PrivacyPolicy", link: "/accounts/privacypolicy" },
        { title: "ContactUs", link: "/accounts/contactus" },
        { title: "DeleteAccount", link: "/accounts/deleteaccount" },
        { title: "Cart", link: "/accounts/cart" },
        { title: "Refer&Earn", link: "/accounts/refer&earn" },
        { title: "Wallet", link: "/accounts/wallet" },
        { title: "Orders", link: "/accounts/orders" },
        { title: "Wishlist", link: "/accounts/wishlist" },

    ]
    return (
        <Modal open={pageModalOpen}
            onClose={() => setPageModalOpen(false)}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
            <Box sx={{
                width: "auto", height: "400px", backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "10px", padding: "4px 8px", '& button': {
                    color: theme.palette.primary.main,
                    '&:hover': {
                        color: "black",
                        backgroundColor: "#27b6cc",
                    }
                },
                zIndex: 999,
                overflowY: "scroll"
            }} >
                {pages.map((element, index) => {
                    return (
                        <Button key={index} onClick={() => {
                            navigate(element.link)
                            setPageModalOpen(false)
                        }} style={{
                            fontSize: "16px", textDecoration: "none",
                        }} > {element.title} </Button>
                    )
                })

                }

            </Box>
        </Modal>
    )
}
