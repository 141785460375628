import React from "react";
import { Card, CardActionArea, CardMedia, Typography,Box, useTheme, Skeleton } from "@mui/material";
import AutoAdjustText from "../../../utils/AutoAdjustText";
import useScreenSize from "../../../utils/ScreenWidthHight";


const BrandPageCard = ({ data, onClick, name, logoPath, Short_description,isSkeleton = false }) => {
    const theme = useTheme();
    const winSize = useScreenSize();

if(!isSkeleton)
    return (
        <Box sx={{ width: {md:'45%',sm:"45%",xs:"90%"} }}>
            <Card sx={{ borderRadius: '24px', marginBottom: '0px', width: '100%', maxWidth: "100%", height: {md:'200px',sm:'200px', xs:'140px'}, border: '1px solid', borderColor: theme.palette.primary.main}}>
                <CardActionArea style={{ width: '100%', height: '100%' }} onClick={onClick}>
                    <Box display="flex" flexDirection="column" height="100%">
                        <Box height="90%" display="flex">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <CardMedia
                                    component="img"
                                    image={logoPath}
                                    alt="partner_Image"
                                    sx={{ objectFit: "contain", height: '80%', marginTop: 3, marginLeft: 2, maxWidth: '100%', width: '130px', borderRadius: '24px' }}
                                />
                            </Box>
                            <Box width="70%" display="flex" flexDirection="column" justifyContent="center" padding="20px">
                                <Typography variant="body1" sx={{  fontSize: {md:'20px', xs:'12px'}, lineHeight: '160%' }}>{name}</Typography>
                                {winSize.screenSize.width > 600 &&
                                    <AutoAdjustText style={{ color: theme.palette.text.secondary, width: "100%", height: "auto", }} numLines={3} > {Short_description}</AutoAdjustText>}
                                { winSize.screenSize.width<600 &&
                                    <Typography sx={{ color: theme.palette.text.secondary, fontSize: { md: '20px', xs: '10px' }, mt: '10px' }}>{Short_description}</Typography>}

                               <Typography sx={{ color: theme.palette.primary.main,mt:1,fontWeight:"bold" , fontSize:{xs:'10px', md:'unset', sm:'unset'} }}> View Details</Typography>
                               
                            </Box>
                        </Box>

                    </Box>
                </CardActionArea>
            </Card>
        </Box>
    );
else {
    return (
        <Box sx={{ width: { md: '45%', sm: "45%", xs: "90%" } }}>
            <Skeleton
                variant="rectangular"
                width="100%"
                height= {winSize.screenSize.width>600? 200: 140}
                sx={{ borderRadius: '24px', marginBottom: '0px' }}
            />
        </Box>
    );
    }

};

export default BrandPageCard;