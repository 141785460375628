import { Box, Button, Container, Modal } from "@mui/material";
import Category from "./Categories";
import Banner from "./Banner";
import Coupon from "./coupon";
import Brand from "./Brand";
import PartnerSection from "./PatnerSection";
import BestSellers from "./BestSellers";
import TableLayout from "./TableLayout";
import FurnitureBar from "./FuntrineBar";
import ExploreReviewCustomer from "./ExploreReviewCustomer";
import RecentlyView from "./RecentlyVeiw";
import AllProductsCompact from "./products/AllProductsCompact";
import Promotion from "./Promation";
import { useEffect, useMemo, useState } from "react";
import HomeProductModal from "../../retail/home/HomeProductModal";
import TodayDeals from "./TodayDeals";
import { getUserSettings } from "../../../store/slices/AuthSlice";
import { useNavigate, } from 'react-router-dom';
import { useTheme } from "@emotion/react";

const Groceryhome = (props) => {

    const [modalProductId, setModalProductId] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [indexingData, setIndexingData] = useState([
        { index: 1, type: "CATEGORY", inputs: {} },
        { index: 2, type: "BANNER", inputs: {} },
        { index: 3, type: "COUPONS", inputs: {} },
        { index: 4, type: "BRANDS", inputs: {} },
        { index: 5, type: "PARTNERS", inputs: {} },
        { index: 6, type: "TODAY_DEALS", inputs: {} },
        { index: 7, type: "RECENTLY_VIEW", inputs: {} },
        { index: 8, type: "PRODUCTS", inputs: {} },
        { index: 9, type: "PROMOTION", inputs: {} }
    ])

    const getHomeIndex = async () => {
        const res = await getUserSettings("GROCERY_WEB_HOME", false);
        if (res.status) {
            setIndexingData(JSON.parse(res.data))
        }
    }

    useEffect(() => {
        getHomeIndex()
    }, [])

    const handleSetModalProductId = (value) => {
        setModalProductId(value)
    }

    const handleModalClose = () => {
        setModalOpen(false)
    }
    const handleModalOpen = () => {
        setModalOpen(true)
    }

    const homePage = useMemo(() => {
        return indexingData.map((item, index) => {
            let res = ""
            switch (item.type) {
                case "CATEGORY":
                    res = <Category key={index} />
                    break;
                case "BANNER":
                    res = <Banner key={index} />
                    break;
                case "COUPONS":
                    res = <Coupon key={index} />
                    break;
                case "BRANDS":
                    res = <Brand key={index} />
                    break;
                case "PARTNERS":
                    res = <PartnerSection key={index} />
                    break;
                case "TODAY_DEALS":
                    res = <TodayDeals key={index} />
                    break;
                case "RECENTLY_VIEW":
                    res = <RecentlyView handleSetModalProductId={(value) => { handleSetModalProductId(value) }} handleModalOpen={handleModalOpen} key={index} />
                    break;
                case "PRODUCTS":
                    res = <AllProductsCompact handleSetModalProductId={(value) => { handleSetModalProductId(value) }} handleModalOpen={handleModalOpen} key={index} />
                    break;
                case "PROMOTION":
                    res = <Promotion key={index} />
                    break;
                case "BESTSELLERS":
                    res = <BestSellers input={item.inputs?.data} key={index} />
                    break;
                case "TABLE_LAYOUT":
                    res = <TableLayout input={item.inputs?.data} key={index} />
                    break;
                case "FURNITURE_BAR":
                    res = <FurnitureBar input={item.inputs?.data} key={index} />
                    break;
                case "EXPLORE_REVIEW_CUSTOMER":
                    res = <ExploreReviewCustomer input={item.inputs?.data} key={index} />
                    break;
                default: <></>
            }
            return res ? res : ""
        })

    }, [indexingData])



    return (
        <Container maxWidth="xl">
            {homePage}
            <HomeProductModal modalProductId={modalProductId} handleModalClose={handleModalClose} modalOpen={modalOpen} handleSetModalProductId={handleSetModalProductId} />

        </Container>
    );
};
export default Groceryhome

export const HomePagesModal = ({ setPageModalOpen, pageModalOpen }) => {
    const navigate = useNavigate()
    const pages = [
        { title: "Tickets", link: "/accounts/tickets" },
        { title: "Shops", link: "/grocery/shop" },
        { title: "Notifications", link: "/accounts/Notification" },
        { title: "News&Events", link: "/accounts/newsandevents" },
        { title: "Review&Ratng", link: "/accounts/reviewsandrating" },
        { title: "Faqs", link: "/accounts/faqs" },
        { title: "AboutUs", link: "/accounts/aboutus" },
        { title: "Terms&Conditions", link: "/accounts/terms&conditions" },
        { title: "PrivacyPolicy", link: "/accounts/privacypolicy" },
        { title: "ContactUs", link: "/accounts/contactus" },
        { title: "DeleteAccount", link: "/accounts/deleteaccount" },
        { title: "Cart", link: "/accounts/cart" },
        { title: "Refer&Earn", link: "/accounts/refer&earn" },
        { title: "Wallet", link: "/accounts/wallet" },
        { title: "Orders", link: "/accounts/grocery/orders" },
        { title: "Wishlist", link: "/accounts/grocery/wishlist" },

    ]
    return (
        <Modal open={pageModalOpen}
            onClose={() => setPageModalOpen(false)}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
            <Box sx={{
                width: "auto", height: "auto", backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "10px", padding: "4px 8px", '& button': {
                    color: "#27b6cc",
                    '&:hover': {
                        color: "black",
                        backgroundColor: "#27b6cc",
                    }
                }
            }} >
                {pages.map((element, index) => {
                    return (
                        <Button key={index} onClick={() => {
                            navigate(element.link)
                            setPageModalOpen(false)
                        }} style={{
                            fontSize: "16px", textDecoration: "none",
                        }} > {element.title} </Button>
                    )
                })

                }

            </Box>
        </Modal>
    )
}